import React from "react";
import { Context } from ".";
import { GroundAuthContextStore } from "../../lib/ground-aws-cognito-auth-core";
import { EnumBackOfficeUserRole } from "../../lib/ground-aws-graphql-core/api/graphql/types";
import { getOperatorConfiguration } from "../../lib/operator-configuration";

interface Props {
  children: React.ReactNode;
}

export const CustomizationProvider = ({ children }: Props): JSX.Element => {
  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );
  const role = me?.role as EnumBackOfficeUserRole | undefined;
  const configuration = getOperatorConfiguration(me?.operator_id);

  return (
    <Context.Provider value={{ configuration, role }}>
      {children}
    </Context.Provider>
  );
};
