import React, { useState } from "react";
import { useIntl } from "react-intl";
import { FieldAttributes } from "formik";
import ReactModal from "react-modal";
import { getVisitorInitials } from "../../../utils/visits";
import overrideClasses from "../../../utils/overrideClasses";
import DefaultForm, { AdditionalFieldAttributes } from "../../Form";
import Footer from "../Block/Footer";
import Header from "../Block/Header";
import images from "../../../images";
import { validateEmail } from "../../../utils/config";

interface Props {
  visitor: VisitorInfo;
  onValidate?: (visitor: VisitorInfo) => void;
  onRemove?: (visitor: VisitorInfo) => void;
}

export interface IVisitor {
  firstname: string;
  lastname: string;
  email: string;
  company: string;
}

export interface VisitorInfo {
  visitorInfo?: IVisitor;
  deletable?: boolean;
  index?: number;
}

const VisitorItem = ({ visitor, onValidate, onRemove }: Props): JSX.Element => {
  const { visitorInfo, index, deletable } = visitor;

  const intl = useIntl();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClose = () => setIsModalOpen(false);

  const modalFields: FieldAttributes<AdditionalFieldAttributes>[] = [
    {
      name: "firstname",
      label: "page.visit.create.visit.visitor.first_name",
      placeholder: "page.visit.create.visit.visitor.first_name",
      initialValue: visitorInfo?.firstname ?? "",
      required: true,
    },
    {
      name: "lastname",
      label: "page.visit.create.visit.visitor.last_name",
      placeholder: "page.visit.create.visit.visitor.last_name",
      initialValue: visitorInfo?.lastname ?? "",
      required: true,
    },
    {
      name: "email",
      label: "page.visit.create.visit.visitor.email",
      placeholder: "page.visit.create.visit.visitor.email",
      initialValue: visitorInfo?.email ?? "",
      required: true,
      validate: validateEmail,
    },
    {
      name: "company",
      label: "page.visit.create.visit.visitor.company_name",
      placeholder: "page.visit.create.visit.visitor.company_name",
      initialValue: visitorInfo?.company ?? "",
      required: true,
    },
  ];

  const visitorInitial = getVisitorInitials(
    visitorInfo?.firstname,
    visitorInfo?.lastname
  );

  const visitorName = visitorInfo
    ? `${visitorInfo?.firstname} ${visitorInfo?.lastname}`
    : intl.formatMessage({ id: "page.visit.create.visit.new.visitor" });

  const initialContainerClassName =
    "rounded-full w-6 h-6 p-4 mr-5 flex flex-col items-center justify-center text-white font-bold";

  return (
    <div>
      <button
        key={`visitor_${index}`}
        className="relative bg-white rounded-xl h-full py-3"
        disabled={visitorInfo && !deletable}
        type="button"
        onClick={() => setIsModalOpen(true)}
      >
        <div className="flex items-center px-6">
          {visitorInfo ? (
            <div
              className={overrideClasses(
                initialContainerClassName,
                "bg-ground-blue-100 uppercase text-l text-white"
              )}
            >
              {visitorInitial}
            </div>
          ) : (
            <div
              className={overrideClasses(
                initialContainerClassName,
                "bg-ground-green-100 select-none text-xl text-white"
              )}
            >
              +
            </div>
          )}

          <div className="flex flex-col items-start">
            <div className="text-sm">{visitorName}</div>

            {visitorInfo && (
              <>
                <div className="text-11px">{visitorInfo.email}</div>
                <div className="text-11px">{visitorInfo.company}</div>
              </>
            )}
          </div>
        </div>

        {visitorInfo && deletable && onRemove && (
          <img
            id={`visitor_delete_${index}`}
            src={images.trash}
            alt="trash"
            className="absolute cursor-pointer right-2 top-2 bg-white border border-red-500 opacity-100 p-1 rounded-5"
            style={{ top: "8px", right: "20px" }}
            onClick={() => onRemove(visitor)}
          />
        )}
      </button>

      {onValidate && (
        <ReactModal
          ariaHideApp={false}
          isOpen={isModalOpen}
          portalClassName="modal-center"
          style={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
          shouldCloseOnOverlayClick
          onRequestClose={onClose}
          className="modal-dialog-lg modal-content"
        >
          <Header
            item={null}
            title="page.visit.create.visit.new.visitor"
            className="border-b border-gray-200"
          />
          <DefaultForm
            fields={modalFields}
            onSubmit={values =>
              onValidate({ ...visitor, visitorInfo: { ...values } })
            }
          >
            <Footer
              item={null}
              index={modalFields.length + 1}
              labels={["general.cancel", "general.submit"]}
              onCancel={onClose}
            />
          </DefaultForm>
        </ReactModal>
      )}
    </div>
  );
};

export default VisitorItem;
