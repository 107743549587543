import React, { Fragment } from "react";
import classnames from "classnames";
import _ from "lodash";
import SingleProduct from "../../../../components/Tailwind/SingleProduct";
import { ActionTypes } from "../../../../utils/types";
import { Product } from "../../../../lib/ground-aws-graphql-core/models/Product";

interface Props {
  index: number;
  items: { id: string; singleProduct: Product; action: ActionTypes }[];
  onRemoveSingleProduct: (o: Product) => void;
  alternate?: boolean;
}

const ListSingleProducts = (props: Props): JSX.Element => {
  const { items, index, onRemoveSingleProduct } = props;
  let { alternate } = props;
  alternate = alternate || true;

  const singleProducts = items.filter(e => e.action !== ActionTypes.TO_DELETE);

  const noSingleProducts = _.isEmpty(singleProducts);

  const className = (id, value) =>
    classnames(value, {
      "bg-ground-white-200": alternate && id % 2 === 0,
    });

  if (noSingleProducts) {
    return null;
  }

  return (
    <div
      className={className(
        index,
        "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:py-6 sm:px-6 px-2 py-2"
      )}
    >
      {singleProducts.map(i => {
        return (
          <Fragment key={i.singleProduct.id}>
            <SingleProduct
              product={i.singleProduct}
              onRemoveSingleProduct={onRemoveSingleProduct}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default ListSingleProducts;
