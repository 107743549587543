import { CreateAdminUserOpts } from "../../models/Account";
import { EnumPermissionEntity } from "../../../lib/ground-aws-graphql-core/api/graphql/types";

export const createUser = async (payload: CreateAdminUserOpts) => {
  const response = await payload.callbacks.createUser(payload.values);

  const { status, data } = response;
  if (status === 201 && payload.center && data && data.id) {
    // create authorization on this center
    await payload.callbacks.createAuthorization({
      items: [
        {
          entityId: payload.center.id,
          entityType: EnumPermissionEntity.CENTER,
          authorizationUserId: data.id,
        },
      ],
    });
  }

  return response;
};
