import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import ReactModal from "react-modal";
import PriceUnitVariationForm from "../form";
import { PriceUnitVariation } from "../../../../../lib/ground-aws-graphql-core/models/PriceUnitVariation";

interface Props {
  isOpen: boolean;
  toggle: (e: PriceUnitVariation | null | undefined) => void;
  priceUnitVariation: PriceUnitVariation | null | undefined;
  match: Match;
  intl: IntlShape;
  onRequestClose: (e) => void;
}

const ModalCreatePriceUnitVariation = (props: Props): JSX.Element => {
  const { isOpen, onRequestClose } = props;

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      portalClassName="modal-center"
      style={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
      shouldCloseOnOverlayClick
      onRequestClose={onRequestClose}
      className="modal-dialog-lg modal-content"
    >
      <PriceUnitVariationForm {...props} />
    </ReactModal>
  );
};

export default injectIntl(ModalCreatePriceUnitVariation);
