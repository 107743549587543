import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import Block from "../../../../components/Tailwind/Block";
import IntlMessages from "../../../../utils/messages";
import { getFormattedPrice } from "../../../../utils/price-unit";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import HeaderGlobalOrder from "../../../../components/Tailwind/Block/HeaderGlobalOrder";
import { retrieveUserFromOrder } from "../../../../utils/user";
import GridOrderItemsAndRefunds from "../../../../components/GridOrderItemsAndRefunds";
import { isFreeGlobalOrder } from "../../../../utils/order";

interface Props {
  match: Match<{ cid: string; id: string }>;
  intl: IntlShape;
}

const GlobalOrderForm = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { intl, match } = props;

  const getGlobalOrder = GroundGraphqlContextStore.useStoreActions(
    actions => actions.globalOrder.getGlobalOrder
  );

  const globalOrder = GroundGraphqlContextStore.useStoreState(
    state => state.globalOrder.globalOrder
  );

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (refresh) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  const fetchData = () => {
    setLoading(true);
    getGlobalOrder({ id: match.params.id }).finally(() => setLoading(false));
  };

  const { client, email } = retrieveUserFromOrder(
    globalOrder?.user,
    globalOrder?.metadata,
    globalOrder?.channel
  );

  return loading || !globalOrder ? (
    <div className="loading" />
  ) : (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "page.order.document.title" })}</title>
      </Helmet>
      <Block className="border border-neutral-100 shadow-ground-1">
        <HeaderGlobalOrder globalOrder={globalOrder} />
        <div className="py-3 sm:py-5 border-b border-gray-200 px-2 sm:px-6 flex flex-col bg-gray-100 rounded-b-10">
          <div className="flex flex-col sm:flex-row justify-between sm:items-start">
            <div className="flex flex-col text-14px leading-relaxed">
              <div className="flex flex-col">
                <p className="text-neutral-900 font-semibold">
                  <IntlMessages id="general.client" />
                </p>
                <p className=" text-ground-gray-100">{client}</p>
                <p className="text-ground-blue-100">{email}</p>
                <p className="text-ground-gray-100">
                  {intl.formatMessage({
                    id: `page.order.channel.${globalOrder?.channel}`,
                  })}
                </p>
                {globalOrder?.touchPoint && (
                  <p className="text-ground-gray-100">
                    {intl.formatMessage(
                      { id: "page.order.touch.point" },
                      { touchPoint: globalOrder.touchPoint }
                    )}
                  </p>
                )}
              </div>
              <div className="flex flex-col mt-4">
                <p className="text-neutral-900 font-semibold">
                  <IntlMessages id="general.comment" />
                </p>
                <p className=" text-ground-gray-100">{globalOrder.comment}</p>
              </div>
            </div>
            {globalOrder && !isFreeGlobalOrder(globalOrder) && (
              <>
                <div className="flex flex-col text-14px leading-relaxed text-ground-gray-100" />
                <div className="flex flex-col text-14px leading-relaxed text-ground-gray-100">
                  <p className="text-neutral-900 font-semibold">
                    <IntlMessages id="page.order.summary" />
                  </p>
                  <p>
                    <IntlMessages id="general.total.ht" /> :{" "}
                    {getFormattedPrice(globalOrder.price, globalOrder.currency)}
                  </p>
                  <p>
                    <IntlMessages id="general.vat" /> :{" "}
                    {getFormattedPrice(
                      globalOrder.totalTax,
                      globalOrder.currency
                    )}
                  </p>
                  <p>
                    <IntlMessages id="general.total.ttc" /> :{" "}
                    {getFormattedPrice(
                      globalOrder.price +
                        globalOrder.totalTax -
                        globalOrder.totalAdjustment,
                      globalOrder.currency
                    )}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </Block>

      <GridOrderItemsAndRefunds
        setLoading={setLoading}
        setRefresh={setRefresh}
        globalOrder={globalOrder}
        orders={globalOrder.orders.items}
        showOrderHeader
        match={match}
      />
    </>
  );
};

export default GlobalOrderForm;
