import { ErrorGraphQL } from "./../../services/coreUtils";
import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel } from "../";

import {
  CreateEventTypeInput as CreateEventType,
  UpdateEventTypeInput as UpdateEventType,
  SearchableEventTypeFilterInput,
} from "../../api/graphql/types";

export interface EventType {
  id: string;
  type: any;
  name: string;
  enabled: boolean;
  markForDelete: boolean;
}

interface ListEventTypesOpts {
  filter?: SearchableEventTypeFilterInput;
  gql?: string | null;
}

export interface ListEventTypeOutput {
  items: [EventType] | null;
  total?: number | null;
}

// Interface declaration
export interface EventTypeModel {
  eventTypes: ListEventTypeOutput;
  eventType: EventType | null;
  setEventTypes: Action<EventTypeModel, { data: ListEventTypeOutput }>;
  setEventType: Action<EventTypeModel, EventType | null>;
  addEventType: Action<EventTypeModel, EventType>;
  replaceEventType: Action<EventTypeModel, EventType>;
  listEventTypes: Thunk<
    EventTypeModel,
    ListEventTypesOpts,
    Injections,
    StoreModel
  >;
  createEventType: Thunk<EventTypeModel, CreateEventType, Injections>;
  updateEventType: Thunk<EventTypeModel, UpdateEventType, Injections>;
}

export const eventTypeModel: EventTypeModel = {
  eventTypes: { items: null, total: null },
  eventType: null,
  setEventTypes: action((state, payload: { data: ListEventTypeOutput }) => {
    const { data } = payload;
    state.eventTypes = data;
  }),
  setEventType: action((state, payload) => {
    state.eventType = payload;
  }),
  addEventType: action((state, payload) => {
    if (state.eventTypes.items) {
      state.eventTypes.items.push(payload);
    } else {
      state.eventTypes["items"] = [payload];
    }
  }),
  replaceEventType: action((state, payload) => {
    state.eventType = payload;
    if (state.eventTypes.items) {
      const index = state.eventTypes.items!.findIndex(e => e.id === payload.id);
      if (index >= 0) state.eventTypes.items!.splice(index, 1, payload);
    }
  }),
  listEventTypes: thunk(async (actions, payload, { injections }) => {
    const { eventTypeService } = injections;
    const response = await eventTypeService.listEventTypes(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const data = response.data.listEventTypes as ListEventTypeOutput;
    actions.setEventTypes({ data });

    return data;
  }),
  createEventType: thunk(async (actions, payload, { injections }) => {
    const { eventTypeService } = injections;
    const response = await eventTypeService.createEventType(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.createEventType;
    actions.setEventType(item as EventType);
    actions.addEventType(item as EventType);

    return item;
  }),
  updateEventType: thunk(async (actions, payload, { injections }) => {
    const { eventTypeService } = injections;
    const response = await eventTypeService.updateEventType(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.updateEventType;
    actions.setEventType(item as EventType);
    actions.replaceEventType(item as EventType);

    return item;
  }),
};
