import { useOperatorDisplayConfiguration } from "./useOperatorDisplayConfiguration";
import { EnumBackOfficeUserRole } from "../../lib/ground-aws-graphql-core/api/graphql/types";
import { DisplayConfiguration } from "../../lib/operator-configuration/backoffice/display";

interface Props {
  role?: EnumBackOfficeUserRole;
  paths: [keyof DisplayConfiguration];
  children?: React.ReactNode;
}

export const Display = ({ paths, children }: Props): JSX.Element => {
  const displayed = useOperatorDisplayConfiguration(paths);

  return (displayed ? children : null) as JSX.Element;
};
