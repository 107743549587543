import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { ThunkCreator } from "easy-peasy";
import { injectIntl, IntlShape } from "react-intl";
import contextStore from "../../../redux/store";
import { getMenuClasses } from "../../../utils/menu";
import history from "../../../history";
import { EnumPaths } from "../../../utils/navigation";
import IntlMessages from "../../../utils/messages";
import {
  EnumPermissionEntity,
  SearchableBackOfficeUserFilterInput,
} from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import { GroundAuthContextStore } from "../../../lib/ground-aws-cognito-auth-core";
import PageSubheader from "../../../components/PageSubheader";
import Table, { ALL_FILTER, IFilterField } from "../../../components/Table";
import Badge from "../../../components/Tailwind/Badge";
import {
  EnumUserStatus,
  getRoleBadgeElements,
  getRoleLabel,
  UserStatus,
} from "../../../utils/user";
import Dropdown, {
  DropdownValues,
} from "../../../components/Tailwind/Dropdown";
import {
  BackOfficeUser,
  SearchBackOfficeUsersOpts,
} from "../../../lib/ground-aws-graphql-core/models/BackOfficeUser";
import ConfirmModal from "../../../utils/modal/confirm";
import { EnumFilterFields } from "../../../utils/filter";
import { BodyElement, BodyRow } from "../../../components/Table/types";
import images from "../../../images";
import { displayDayYYYYMMDDHHMMSS } from "../../../utils/config";

interface Props {
  intl: IntlShape;
}

const LIMIT = 20;

const ListAdministrators = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [userId, setUserId] = useState<string>("");
  const [selectedFilters, setSelectedFilters] =
    useState<SearchableBackOfficeUserFilterInput>({});

  const { intl } = props;

  const setContainerClassnames = contextStore.useStoreActions(
    actions => actions.menu.setContainerClassnames
  );

  const menuHiddenBreakpoint = contextStore.useStoreState(
    state => state.menu.menuHiddenBreakpoint
  );

  const subHiddenBreakpoint = contextStore.useStoreState(
    state => state.menu.subHiddenBreakpoint
  );

  const searchUsers = GroundGraphqlContextStore.useStoreActions(
    actions => actions.backOfficeUser.searchBackOfficeUsers
  );

  const exportAdministratorsAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.backOfficeUser.exportAdministrators
  );

  const forgotPassword = GroundGraphqlContextStore.useStoreActions(
    actions => actions.account.forgotPasswordAdminUser
  );

  const deleteAdminUser = GroundGraphqlContextStore.useStoreActions(
    actions => actions.backOfficeUser.deleteAdminUser
  );

  const backOfficeUsers = GroundGraphqlContextStore.useStoreState(
    state => state.backOfficeUser.backOfficeUsers.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.backOfficeUser.backOfficeUsers.total
  );

  const setBackOfficeUser = GroundGraphqlContextStore.useStoreActions(
    actions => actions.backOfficeUser.setBackOfficeUser
  );

  const meDetails = GroundAuthContextStore.useStoreState(
    state => state.authentication.meDetails
  );

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  useEffect(() => {
    fetchAdministrators();

    const nextClasses = getMenuClasses(
      "menu-hidden",
      menuHiddenBreakpoint,
      subHiddenBreakpoint
    );
    setContainerClassnames({ strCurrentClasses: nextClasses.join(" ") });
  }, []);

  const fetchAdministrators = (
    pageIndex = 0,
    loader = true,
    filters?: IFilterField<any>[]
  ) => {
    if (loader) {
      setLoading(true);
    }
    let filter;
    if (filters?.length) {
      filters.forEach(f => {
        if (f.type === EnumFilterFields[EnumFilterFields.STATUS]) {
          if (f.value?.value !== ALL_FILTER) {
            if (f.value?.value === EnumUserStatus.CONFIRMED) {
              filter = {
                ...filter,
                or: [
                  { status: { eq: UserStatus.CONFIRMED } },
                  { status: { eq: UserStatus.EXTERNAL_PROVIDER } },
                ],
              };
            } else if (f.value?.value === EnumUserStatus.UNCONFIRMED) {
              filter = {
                ...filter,
                or: [
                  { status: { eq: UserStatus.ARCHIVED } },
                  { status: { eq: UserStatus.COMPROMISED } },
                  { status: { eq: UserStatus.FORCE_CHANGE_PASSWORD } },
                  { status: { eq: UserStatus.RESET_REQUIRED } },
                  { status: { eq: UserStatus.UNCONFIRMED } },
                  { status: { eq: UserStatus.UNKNOWN } },
                ],
              };
            }
          }
        } else if (f.value) {
          filter = {
            ...filter,
            [f.type.toLocaleLowerCase()]: {
              matchPhrasePrefix: `${f.value.toLowerCase()}`,
            },
          };
        }
      });
    }

    setSelectedFilters(filter);

    searchUsers({
      filter,
      limit: LIMIT,
      from: pageIndex * LIMIT,
    }).finally(() => {
      setLoading(false);
    });
  };

  const exportAdministrators = (): ThunkCreator<
    SearchBackOfficeUsersOpts,
    any
  > => {
    return exportAdministratorsAction({
      filter: selectedFilters,
      limit: 100,
      from: 0,
    });
  };

  const handleAddNew = () => {
    setBackOfficeUser(null);
    history.push(
      `/${EnumPaths.ROOT}/${EnumPaths.ADMINISTRATORS}/${EnumPaths.CREATION_MODE}`
    );
  };

  const handleEdit = (editUserLink: string) => {
    setBackOfficeUser(null);
    history.push(editUserLink);
  };

  const handleForgotPassword = () => {
    // reset password
    setLoading(true);
    setShowDeleteModal(false);
    forgotPassword({ id: userId })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.list.users.forgot.password.success",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.list.users.forgot.password.error",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    setShowDeleteUserModal(false);
    deleteAdminUser({ id: userId })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.list.users.delete.user.success",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.list.users.delete.user.error",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const key = "administrators";
  const suffix = "administrator";

  const tableHead = [
    `page.list.${key}.table.head.name`,
    "general.email",
    "page.list.users.table.head.role",
    "page.list.users.table.head.account",
    "general.actions",
  ];

  const tableBody: BodyRow[] = backOfficeUsers?.map((user: BackOfficeUser) => {
    const { id, lastname, firstname, email, role, status } = user;

    const editUserLink = `/${EnumPaths.ROOT}/${EnumPaths.ADMINISTRATORS}/${id}/${EnumPaths.EDIT_MODE}`;

    const dropdownValues: DropdownValues[] = [
      {
        id: "edit_user",
        label: "general.edit",
        icon: images.edit,
        link: editUserLink,
      },
    ];

    dropdownValues.push({
      id: "forgot_password",
      label:
        status === UserStatus.FORCE_CHANGE_PASSWORD
          ? "general.resend.temporary.password"
          : "general.reinitialize.password",
      icon: images.mail,
      onClick: () => {
        setShowDeleteModal(!showDeleteModal);
        setUserId(id);
      },
    });

    dropdownValues.push({
      id: "delete",
      label: "general.delete",
      icon: images.deleteIcon,
      onClick: () => {
        setShowDeleteUserModal(!showDeleteUserModal);
        setUserId(id);
      },
    });

    const userElements: BodyElement[] = [
      {
        element: (
          <span
            data-cy={`${suffix}-name-${id}`}
          >{`${lastname} ${firstname}`}</span>
        ),
        onCellClick: () => handleEdit(editUserLink),
      },
      {
        element: <span data-cy={`${suffix}-email-${id}`}>{email}</span>,
        onCellClick: () => handleEdit(editUserLink),
      },
      {
        element: (
          <Badge
            bgClassName={getRoleBadgeElements(role).backgroundColor}
            textClassName={getRoleBadgeElements(role).textColor}
            dataCy={`${suffix}-role-${id}`}
          >
            {getRoleLabel(intl, role)}
          </Badge>
        ),
        onCellClick: () => handleEdit(editUserLink),
      },
      {
        element:
          status &&
          (status === UserStatus.CONFIRMED ||
            status === UserStatus.EXTERNAL_PROVIDER) ? (
            <IntlMessages id="general.CONFIRMED" />
          ) : (
            <IntlMessages id="general.UNCONFIRMED" />
          ),
        onCellClick: () => handleEdit(editUserLink),
      },
      {
        element: (
          <Dropdown
            values={dropdownValues}
            width="w-80"
            dataCy={`${suffix}-dropdown-${id}`}
          />
        ),
      },
    ];

    return {
      rowElements: userElements,
    };
  });

  return (
    <div>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: `page.list.${key}.document.title`,
          })}
        </title>
      </Helmet>

      <PageSubheader
        title={`page.list.${key}.title`}
        nbOfResults={total}
        buttonRightTitle={`page.list.${key}.add.${suffix}`}
        buttonRightAction={handleAddNew}
        buttonRightId={`btn-add-${suffix}`}
      />

      <Table
        head={tableHead}
        body={tableBody}
        noDataText="page.center.administrators.empty"
        permissionEntity={EnumPermissionEntity.BACK_OFFICE_USER}
        onChange={({ pageIndex, filters }) =>
          fetchAdministrators(pageIndex, false, filters)
        }
        exportData={{
          onExport: exportAdministrators,
          filename: `administrators-${
            meDetails?.operator.short_name
          }-${displayDayYYYYMMDDHHMMSS(new Date(), centerTimezone)}.csv`,
          headers: [
            { label: "Firstname", key: "firstname" },
            { label: "Lastname", key: "lastname" },
            { label: "Email", key: "email" },
            { label: "Role", key: "role" },
            { label: "Locale", key: "locale" },
          ],
        }}
        paginationTotal={total}
        paginationLimit={LIMIT}
        useFilterBlock
        filterBlockTitle={`page.list.${key}.filter.title`}
        loading={loading}
        setLoading={setLoading}
        className="px-8"
      />
      <ConfirmModal
        isOpen={showDeleteModal}
        onRequestClose={() => setShowDeleteModal(!showDeleteModal)}
        toggle={() => setShowDeleteModal(!showDeleteModal)}
        handleConfirm={handleForgotPassword}
        content={<IntlMessages id="page.list.users.forgot.password" />}
        description={
          <IntlMessages id="page.list.users.forgot.password.description" />
        }
      />
      <ConfirmModal
        isOpen={showDeleteUserModal}
        onRequestClose={() => setShowDeleteUserModal(!showDeleteUserModal)}
        toggle={() => setShowDeleteUserModal(!showDeleteUserModal)}
        handleConfirm={handleDelete}
        content={<IntlMessages id="page.list.users.delete.user" />}
      />
    </div>
  );
};

export default injectIntl(ListAdministrators);
