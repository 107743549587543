import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Location } from "history";
import { toast } from "react-toastify";
import { injectIntl, IntlShape } from "react-intl";
import { useParams } from "react-router-dom";
import { FieldAttributes, FormikProps } from "formik";
import PageSubheader from "../../../../../components/PageSubheader";
import overrideClasses from "../../../../../utils/overrideClasses";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import { getTranslation } from "../../../../../utils/translation";
import IntlMessages from "../../../../../utils/messages";
import Block from "../../../../../components/Tailwind/Block";
import Button from "../../../../../components/Tailwind/Button";
import history from "../../../../../history";
import { EnumBookingFrom, EnumPaths } from "../../../../../utils/navigation";
import ConsumablesTable from "./consumables-table";
import DefaultForm, {
  AdditionalFieldAttributes,
} from "../../../../../components/Form";
import Event from "../../../../../utils/tracking-event";
import { CartItem } from "../../../../../lib/ground-aws-graphql-core/models/Api/Cart";
import { getFormattedPrice } from "../../../../../utils/price-unit";
import { getPaymentMethodsOptions } from "../../../../../utils/types";
import ConfirmModal from "../../../../../utils/modal/confirm";

interface Props {
  location: Location;
  intl: IntlShape;
}

const BookingSummary = (props: Props) => {
  const {
    location: { state },
    intl,
  } = props;

  const {
    selectedPriceUnit,
    selectedPaymentMethod,
    userId,
    comment: initialComment,
    from,
    date,
  } = state;

  const { cid, cartId } = useParams<{ cid: string; cartId: string }>();

  const formRef = useRef<FormikProps<any>>(null);

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCartItemId, setSelectedCartItemId] = useState<string | null>(
    null
  );

  const getCart = GroundGraphqlContextStore.useStoreActions(
    a => a.cart.getCart
  );

  const cart = GroundGraphqlContextStore.useStoreState(s => s.cart.cart);

  const deleteCartItem = GroundGraphqlContextStore.useStoreActions(
    a => a.cart.deleteCartItem
  );

  const updateCartItem = GroundGraphqlContextStore.useStoreActions(
    a => a.cart.updateCartItem
  );

  const finalizeCart = GroundGraphqlContextStore.useStoreActions(
    a => a.cart.finalizeCart
  );

  const listUserPaymentMethods = GroundGraphqlContextStore.useStoreActions(
    actions => actions.paymentMethods.listUserPaymentMethods
  );

  const listPaymentMethods = GroundGraphqlContextStore.useStoreActions(
    actions => actions.paymentMethods.listPaymentMethods
  );

  const userPaymentMethods = GroundGraphqlContextStore.useStoreState(
    s => s.paymentMethods.userPaymentMethods.items
  );

  const paymentMethods = GroundGraphqlContextStore.useStoreState(
    s => s.paymentMethods.paymentMethods.items
  );

  useEffect(() => {
    setLoading(true);

    getCart({ id: cartId }).finally(() => setLoading(false));

    if (userId)
      listUserPaymentMethods({ userId, excludedFromBackOffice: false });
    else listPaymentMethods({ excludedFromBackOffice: false });
  }, []);

  const formFields: FieldAttributes<AdditionalFieldAttributes>[] = [
    {
      name: "paymentMethod",
      label: "general.payment_method",
      placeholder: "general.payment_method",
      initialValue: selectedPaymentMethod,
      as: "select",
      required: true,
      options: getPaymentMethodsOptions(
        userPaymentMethods.length > 0 ? userPaymentMethods : paymentMethods
      ),
    },
    {
      name: "comment",
      label: "general.comment",
      placeholder: "general.comment",
      initialValue: initialComment ?? "",
      as: "textarea",
    },
  ];

  const handleConfirm = async () => {
    if (cart && selectedCartItemId) {
      setLoading(true);
      setIsModalOpen(false);

      try {
        const deleteCartItemResponse = await deleteCartItem({
          cartId: cart.id,
          cartItemId: selectedCartItemId,
        });

        if (!deleteCartItemResponse.success)
          throw new Error(deleteCartItemResponse.error_code);
      } catch (error: any) {
        toast(
          intl.formatMessage({
            id: intl.messages[
              `page.booking.summary.delete.option.${error.message}`
            ]
              ? `page.booking.summary.delete.option.${error.message}`
              : "page.booking.summary.delete.option.error",
          }),
          {
            type: "error",
          }
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const removeOptionFromCart = (cartItemId: string) => {
    setSelectedCartItemId(cartItemId);
    setIsModalOpen(true);
  };

  const updateOptionInCart = async (cartItemId: string, quantity: number) => {
    if (cart) {
      setLoading(true);

      try {
        const updateCartItemResponse = await updateCartItem({
          cartId: cart.id,
          cartItemId,
          quantity,
        });

        if (!updateCartItemResponse.success)
          throw new Error(updateCartItemResponse.error_code);
      } catch (error: any) {
        const cartItem = consumableCartItems?.find(
          cci => cci.id === cartItemId
        );
        toast(
          intl.formatMessage(
            {
              id: intl.messages[
                `page.booking.summary.update.option.${error.message}`
              ]
                ? `page.booking.summary.update.option.${error.message}`
                : "page.booking.summary.update.option.error",
            },
            { stock: cartItem?.product.stock }
          ),
          {
            type: "error",
          }
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmit = async values => {
    const { paymentMethod, comment } = values;

    try {
      setLoading(true);

      const finalizeCartResponse = await finalizeCart({
        cartId,
        comment: comment || undefined,
        payment_method: paymentMethod,
      });

      if (!finalizeCartResponse.success)
        throw new Error(finalizeCartResponse.error_code);

      toast(intl.formatMessage({ id: "page.booking.create.booking.success" }), {
        type: "success",
      });

      if (process.env.REACT_APP_EVENT_NAME_BOOKING_CREATED)
        Event("bookings", process.env.REACT_APP_EVENT_NAME_BOOKING_CREATED);

      if (from === EnumBookingFrom.CALENDAR) {
        // go to calendar
        history.replace(
          `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${cid}/${EnumPaths.CALENDAR}`
        );
      } else if (from === EnumBookingFrom.BOOKING_LIST) {
        // go to booking list
        history.replace(
          `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${cid}/${EnumPaths.BOOKINGS}`,
          { date }
        );
      }
    } catch (error: any) {
      toast(
        intl.formatMessage({
          id: intl.messages[`page.booking.create.booking.${error.message}`]
            ? `page.booking.create.booking.${error.message}`
            : "page.booking.create.booking.error",
        }),
        {
          type: "error",
        }
      );

      if (process.env.REACT_APP_EVENT_NAME_BOOKING_FAILED) {
        Event(
          "bookings",
          process.env.REACT_APP_EVENT_NAME_BOOKING_FAILED,
          intl.messages[`page.booking.create.booking.${error.message}`]
            ? { error_code: error.message }
            : undefined
        );
      }

      setLoading(false);
    }
  };

  const consumableCartItems = cart?.items.filter(cartItem => !!cartItem.parent);

  const subtotalExcludedTaxes =
    consumableCartItems?.reduce(
      (subtotal, cartItem) => subtotal + cartItem.total_price,
      0
    ) ?? 0;
  const subtotalTaxes =
    consumableCartItems?.reduce(
      (subtotal, cartItem) => subtotal + cartItem.total_tax,
      0
    ) ?? 0;

  const renderConsumableCartItem = (cartItem: CartItem, index: number) => {
    return (
      <div
        key={cartItem.id}
        className={overrideClasses(
          "my-3 flex items-center justify-between border-b border-inactive pb-4 px-4 mb-0 mt-4",
          { "!mt-0": index === 0 }
        )}
      >
        <p className="w-3/4">{getTranslation(cartItem.product.name)}</p>

        <div className="w-1/4 flex justify-between">
          <span className="mr-3">{`x${cartItem.quantity}`}</span>
          <span className="text-right">
            {getFormattedPrice(cartItem.total_price, cartItem.currency)}
          </span>
        </div>
      </div>
    );
  };

  const Subtotal = ({
    title,
    totalPrice,
  }: {
    title: string;
    totalPrice: number;
  }) => (
    <div className="flex items-center justify-between">
      <IntlMessages id={title} />
      <span>{getFormattedPrice(totalPrice, cart?.currency)}</span>
    </div>
  );

  return !cart ? (
    <div className="loading" />
  ) : (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={overrideClasses({ loading })} />

      {/* Hide when loading */}
      <div className={overrideClasses({ hidden: loading })}>
        <Helmet>
          <title>
            {intl.formatMessage({
              id: "page.booking.summary.document.title",
            })}
          </title>
        </Helmet>

        <PageSubheader
          title="page.booking.options.title"
          goBackEnabled
          goBackId="btn-back-book-summary"
        />

        <div className="px-8 pt-8">
          <IntlMessages
            id="page.booking.options.price.unit"
            values={{
              priceUnit: getTranslation(selectedPriceUnit?.variation.name),
            }}
          />

          <div className="flex space-x-4">
            <div className="w-3/4">
              <ConsumablesTable
                consumableCartItems={consumableCartItems ?? []}
                handleDelete={removeOptionFromCart}
                handleUpdate={updateOptionInCart}
              />

              <Block>
                <DefaultForm
                  fields={formFields}
                  onSubmit={handleSubmit}
                  disablePanel
                  innerRef={formRef}
                />
              </Block>
            </div>

            <div className="flex flex-col w-1/4">
              <Block className="py-4">
                {consumableCartItems?.map(renderConsumableCartItem)}

                <div className="flex flex-col space-y-6 pt-4 px-4 font-semibold">
                  <Subtotal
                    title="general.total.ht"
                    totalPrice={subtotalExcludedTaxes}
                  />
                  <Subtotal title="general.vat" totalPrice={subtotalTaxes} />
                  <Subtotal
                    title="general.total.ttc"
                    totalPrice={subtotalExcludedTaxes + subtotalTaxes}
                  />
                </div>
              </Block>

              <Button
                id="btn-validate-cart"
                name="btn-validate-cart"
                item={null}
                type="submit"
                onClick={() => formRef.current?.submitForm()}
                className="self-end"
              >
                <span className="text-center">
                  <IntlMessages id="general.submit" />
                </span>
              </Button>
            </div>
          </div>
        </div>

        <ConfirmModal
          isOpen={isModalOpen}
          toggle={() => setIsModalOpen(!isModalOpen)}
          onRequestClose={() => setIsModalOpen(!isModalOpen)}
          handleConfirm={handleConfirm}
          content={
            <IntlMessages id="page.booking.summary.confirm.delete.options" />
          }
        />
      </div>
    </>
  );
};

export default injectIntl(BookingSummary);
