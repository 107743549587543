import React, { useState } from "react";
import cx from "classnames";
import { injectIntl, IntlShape } from "react-intl";
import IntlMessages from "../../../utils/messages";
import ModalDateRange from "../../ModalDateRange";
import { displayDayDDMMYYYY, parseISODateFn } from "../../../utils/config";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";

interface Props {
  intl: IntlShape;
  index: number;
  alternate: boolean;
  name: string;
  errors: any;
  touched: any;
  onChange: (dates: { startDate: Date; endDate: Date }) => void;
  useIntl: boolean;
  label: string;
  required?: boolean;
  start: string;
  end: string;
  disabledDates?: Date[];
}

const DateComponent = (props: Props): JSX.Element => {
  const {
    intl,
    index,
    onChange,
    alternate = true,
    name,
    errors,
    touched,
    useIntl,
    label,
    required,
    start,
    end,
    disabledDates,
  } = props;
  const [dateStart, setDateStart] = useState<Date | null>(
    start ? parseISODateFn(start) : null
  );
  const [dateEnd, setDateEnd] = useState<Date | null>(
    end ? parseISODateFn(end) : null
  );

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const [openModal, setOpenModal] = useState(false);

  const onChangeDates = (startDate: Date, endDate: Date): void => {
    setDateStart(startDate);
    setDateEnd(endDate);

    onChange({
      startDate,
      endDate,
    });
  };

  const invalid = errors[name] && touched[name];

  const dateCss = cx(
    "sm:grid sm:gap-4 sm:items-start sm:py-2 sm:px-8 px-2 py-2 sm:grid-cols-3",
    {
      "bg-ground-white-200": alternate && index % 2 === 0,
    }
  );

  const formClassName = cx(
    "form-textarea block w-full transition duration-150 ease-in-out sm:text-14px sm:leading-5 placeholder-ground-gray-100 cursor-pointer",
    {
      "border-red-300 text-red-500 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red":
        invalid,
      "text-ground-black-100 placeholder-ground-black-100": !invalid,
    }
  );

  const getInputDates = (start: Date | null, end: Date | null) => {
    return start && end
      ? `${displayDayDDMMYYYY(start, centerTimezone)} > ${displayDayDDMMYYYY(
          end,
          centerTimezone
        )}`
      : ``;
  };

  return (
    <div key={index} className={dateCss}>
      <label
        htmlFor={name}
        className="block text-14px font-medium leading-5 text-ground-gray-100 sm:mt-px sm:pt-2 "
      >
        {useIntl && <IntlMessages id={label} />}
        {!useIntl && label}
        {required && " *"}
      </label>
      <div className="mt-1 sm:mt-0 max-w-xs sm:col-span-2">
        <div className="relative flex items-center">
          <input
            type="text"
            className={formClassName}
            placeholder={intl.formatMessage({
              id: "page.privatization.dates.placeholder",
            })}
            readOnly
            value={getInputDates(dateStart, dateEnd)}
            onClick={() => setOpenModal(true)}
          />

          <ModalDateRange
            title="page.privatization.dates"
            description="page.privatization.description"
            onSubmit={onChangeDates}
            open={openModal}
            setOpen={setOpenModal}
            start={dateStart}
            end={dateEnd}
            disabledDates={disabledDates}
          />
        </div>
        {invalid && (
          <p className="mt-2 text-11px text-red-600" id={`${name}-error`}>
            {errors[name]}
          </p>
        )}
      </div>
    </div>
  );
};

export default injectIntl(DateComponent);
