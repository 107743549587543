import React from "react";
import { match as Match } from "react-router-dom";
import { injectIntl, IntlShape } from "react-intl";
import { ReportingSalesDate } from "../../../../../../lib/ground-aws-graphql-core/models/Reporting";
import SalesCard from "../sales-card";
import { EnumReportingType } from "../../../../../../lib/ground-aws-graphql-core/models/Product";
import { EnumCurrency } from "../../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { getFormattedPrice } from "../../../../../../utils/price-unit";

interface Props {
  intl: IntlShape;
  match: Match<{ cid: string }>;
  data: {
    type: EnumReportingType;
    data: ReportingSalesDate[];
  }[];
}

const SalesCardList = (props: Props) => {
  const { intl, data } = props;
  const types = data.map(d => d.type);
  const results = types.map(type => {
    const dataForType = data.find(d => d.type === type)?.data || [];

    return {
      key: type,
      label: intl.formatMessage({
        id: `page.dashboards.chart.amount.${type.toLowerCase()}.title`,
      }),
      amount: getFormattedPrice(dataForType[0]?.amount, EnumCurrency.EUR),
      variation: getFormattedPrice(dataForType[0]?.variation, EnumCurrency.EUR),
      evolutionRate: dataForType[0]?.evolutionRate,
      rate: dataForType[0]?.rate,
    };
  });

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-3">
      {results.map(r => {
        return (
          <div id={r.key} data-cy={r.key} data-testid={r.key} key={r.key}>
            <SalesCard
              type={r.key}
              label={r.label}
              amount={r.amount}
              variation={r.variation}
              evolutionRate={r.evolutionRate}
              rate={r.rate}
              selected={false}
            />
          </div>
        );
      })}
    </div>
  );
};

export default injectIntl(SalesCardList);
