import { OperatorConfig } from "..";

// PROD
const sourcesConfig: OperatorConfig = {
  backoffice: {
    display: {
      "sidebar.menu.management.calendar": false,
      "sidebar.menu.management.incidents": false,
      "sidebar.menu.management.visits": false,
    },
  },
};

export default sourcesConfig;
