import React from "react";
import classnames from "classnames";
import Switch from "rc-switch";
import IntlMessages from "../../../utils/messages";
import { getCypressTestId } from "../../../utils/config";

interface Props {
  item: { id: string } | null | undefined;
  id: string;
  name: string;
  label: string;
  index: number;
  last?: boolean;
  value: boolean;
  onChange: (checked, e) => any;
  children?: React.ReactNode;
  useIntl: boolean;
  description?: string;
  alternate?: boolean;
}

const CustomSwitch = (props: Props): JSX.Element => {
  const {
    item,
    id,
    name,
    label,
    index,
    last,
    value,
    onChange,
    children,
    useIntl,
    description,
    alternate,
  } = props;

  const alter = alternate != undefined ? alternate : true;

  const containerClassName = id =>
    classnames(
      "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:py-2 sm:px-8 px-2",
      {
        "bg-ground-white-200": alter && id % 2 === 0,
        "rounded-b-10": last,
      }
    );

  return (
    <>
      <div className={containerClassName(index)}>
        <label
          htmlFor={name}
          className="block text-14px font-medium leading-5 text-ground-gray-100"
        >
          {useIntl && <IntlMessages id={label} />}
          {!useIntl && `${label}`}
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="flex items-center">
            <Switch
              className="max-w-xs"
              checked={value}
              onChange={onChange}
              id={id}
              data-cy={name}
              data-testid={getCypressTestId(item)}
            />
            {description && (
              <span className="text-13px font-medium text-ground-black-100 ml-2">
                <IntlMessages id={`${description}.${value}`} />
              </span>
            )}
          </div>
        </div>
      </div>
      {value && children && (
        <div className={containerClassName(index)}>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="max-w-xs">
              <div className="max-w-xs rounded-md shadow-sm">{children}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomSwitch;
