import React from "react";
import { Router, Route, Switch, match as Match } from "react-router-dom";
import { Location } from "history";
import history from "./history";
import App from "./containers/App";

import "react-datepicker/dist/react-datepicker.css";
import "react-circular-progressbar/dist/styles.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-phone-input-2/lib/style.css";
import "rc-switch/assets/index.css";
import "react-tippy/dist/tippy.css";
import "react-toastify/dist/ReactToastify.css";

import "./assets/css/sass/themes/getground.scss";
import "./styles/tailwind.css";
import "./assets/fonts/opensans/style.css";
import "./styles.css";

interface Props {
  location: Location;
  match: Match;
}

function MainApp(): JSX.Element {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" component={(props: Props) => <App {...props} />} />
      </Switch>
    </Router>
  );
}

export default MainApp;
