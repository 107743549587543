import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Location } from "history";
import { toast } from "react-toastify";
import { injectIntl, IntlShape } from "react-intl";
import { useParams } from "react-router-dom";
import PageSubheader from "../../../../../components/PageSubheader";
import overrideClasses from "../../../../../utils/overrideClasses";
import OptionsTable from "./options-table";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import { getTranslation } from "../../../../../utils/translation";
import IntlMessages from "../../../../../utils/messages";
import Block from "../../../../../components/Tailwind/Block";
import Button from "../../../../../components/Tailwind/Button";
import Tag from "../../../../../components/Tag";
import { ProductOption } from "../../../../../lib/ground-aws-graphql-core/models/Product";
import history from "../../../../../history";
import { EnumBookingFrom, EnumPaths } from "../../../../../utils/navigation";
import Event from "../../../../../utils/tracking-event";

const MAXIMUM_OPTIONS_QUANTITY = 10;

interface Props {
  location: Location;
  intl: IntlShape;
}

const BookingOptions = (props: Props) => {
  const {
    location: { state },
    intl,
  } = props;

  const {
    productId,
    selectedPriceUnit,
    selectedPaymentMethod,
    from,
    date,
    comment,
  } = state;

  const { cid, cartId } = useParams<{ cid: string; cartId: string }>();

  const [loading, setLoading] = useState(false);
  const [optionsSelected, setOptionsSelected] = useState<ProductOption[]>([]);

  const getCart = GroundGraphqlContextStore.useStoreActions(
    a => a.cart.getCart
  );

  const cart = GroundGraphqlContextStore.useStoreState(s => s.cart.cart);

  const addOptions = GroundGraphqlContextStore.useStoreActions(
    a => a.cart.addOptions
  );

  const searchProductOptions = GroundGraphqlContextStore.useStoreActions(
    a => a.product.searchProductOptions
  );

  const productOptions = GroundGraphqlContextStore.useStoreState(
    s => s.product.productOptions
  );

  const deleteCartItem = GroundGraphqlContextStore.useStoreActions(
    a => a.cart.deleteCartItem
  );

  const finalizeCart = GroundGraphqlContextStore.useStoreActions(
    a => a.cart.finalizeCart
  );

  useEffect(() => {
    setLoading(true);

    Promise.all([
      getCart({ id: cartId }),
      searchProductOptions({
        buildingId: cid,
        productId,
        priceUnitVariationId: selectedPriceUnit.variation.id,
      }),
    ]).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (cart?.items.length && productOptions?.length) {
      const optionsInCart = productOptions.filter(option =>
        cart.items.some(cartItem => cartItem.product.id === option.id)
      );
      setOptionsSelected(optionsInCart);
    }
  }, [cart, productOptions]);

  const handleFinalizeCart = async () => {
    const finalizeCartResponse = await finalizeCart({
      cartId,
      comment,
      payment_method: selectedPaymentMethod,
    });

    if (!finalizeCartResponse.success)
      throw new Error(finalizeCartResponse.error_code);

    setLoading(false);
    toast(intl.formatMessage({ id: "page.booking.create.booking.success" }), {
      type: "success",
    });

    if (process.env.REACT_APP_EVENT_NAME_BOOKING_CREATED)
      Event("bookings", process.env.REACT_APP_EVENT_NAME_BOOKING_CREATED);

    if (from === EnumBookingFrom.CALENDAR) {
      // go to calendar
      history.push(
        `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${cid}/${EnumPaths.CALENDAR}`
      );
    } else if (from === EnumBookingFrom.BOOKING_LIST) {
      // go to booking list
      history.push(
        `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${cid}/${EnumPaths.BOOKINGS}`,
        { date }
      );
    }
  };

  const selectOption = (option: ProductOption) => {
    if (optionsSelected.includes(option))
      setOptionsSelected(
        optionsSelected.filter(optionSelected => optionSelected !== option)
      );
    else if (optionsSelected.length === MAXIMUM_OPTIONS_QUANTITY)
      toast(
        intl.formatMessage({
          id: "page.booking.options.max.quantity",
        }),
        {
          type: "error",
        }
      );
    else setOptionsSelected([...optionsSelected, option]);
  };

  const removeOption = (id: string) =>
    setOptionsSelected(
      optionsSelected.filter(optionSelected => optionSelected.id !== id)
    );

  const addOptionsToCart = async () => {
    try {
      setLoading(true);

      if (cart) {
        // CartItems without the space
        const consumableCartItems = cart.items.filter(
          cartItem => !!cartItem.parent
        );

        const optionsToAdd = optionsSelected
          .filter(
            option =>
              !consumableCartItems.some(
                cartItem => cartItem.product.id === option.id
              )
          )
          .map(option => {
            const priceUnitId = option.price_units?.items?.find(
              pu => pu.variation?.id === selectedPriceUnit.variation.id
            )?.id;

            return {
              id: option.id,
              price_unit: { id: priceUnitId ?? "" },
              quantity: 1,
            };
          });

        const optionsToRemove = consumableCartItems
          .filter(
            cartItem =>
              !optionsSelected.some(option => option.id === cartItem.product.id)
          )
          .map(cartItem => {
            return deleteCartItem({
              cartId: cart.id,
              cartItemId: cartItem.id,
            });
          });

        if (optionsToAdd.length || optionsToRemove.length) {
          const itemId = cart.items.find(
            cartItem => cartItem.product.id === productId
          )?.id;

          if (!itemId) throw new Error();

          const promises: any[] = [];
          if (optionsToAdd.length)
            promises.push(
              addOptions({
                cartId,
                itemId,
                options: optionsToAdd,
              })
            );

          if (optionsToRemove.length) promises.push(...optionsToRemove);

          const responses = await Promise.all(promises);

          for (const response of responses) {
            if (!response.success) throw new Error(response.error_code);
          }

          if (
            optionsToAdd.length === 0 &&
            optionsToRemove.length === consumableCartItems.length
          ) {
            handleFinalizeCart();
          } else {
            setLoading(false);

            // Go to the cart summary screen
            history.push(
              `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${cid}/${EnumPaths.CARTS}/${cartId}/${EnumPaths.SUMMARY}`,
              state
            );
          }
        } else if (consumableCartItems.length > 0) {
          setLoading(false);

          // Go to the cart summary screen
          history.push(
            `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${cid}/${EnumPaths.CARTS}/${cartId}/${EnumPaths.SUMMARY}`,
            state
          );
        } else handleFinalizeCart();
      }
    } catch (error: any) {
      toast(
        intl.formatMessage({
          id: "page.booking.options.error",
        }),
        {
          type: "error",
        }
      );

      if (process.env.REACT_APP_EVENT_NAME_BOOKING_FAILED) {
        Event(
          "bookings",
          process.env.REACT_APP_EVENT_NAME_BOOKING_FAILED,
          intl.messages[`page.booking.create.booking.${error.message}`]
            ? { error_code: error.message }
            : undefined
        );
      }

      setLoading(false);
    }
  };

  return !productOptions || !cart ? (
    <div className="loading" />
  ) : (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={overrideClasses({ loading })} />

      {/* Hide when loading */}
      <div className={overrideClasses({ hidden: loading })}>
        <Helmet>
          <title>
            {intl.formatMessage({
              id: "page.booking.options.document.title",
            })}
          </title>
        </Helmet>

        <PageSubheader
          title="page.booking.options.title"
          goBackEnabled
          goBackId="btn-back-book-options"
        />

        <div className="px-8 pt-8">
          <IntlMessages
            id="page.booking.options.price.unit"
            values={{
              priceUnit: getTranslation(selectedPriceUnit?.variation.name),
            }}
          />

          <div className="flex space-x-4">
            <OptionsTable
              productOptions={productOptions.filter(
                po =>
                  po?.price_units?.items?.length &&
                  po?.price_units?.items?.length > 0
              )}
              selectOption={selectOption}
              optionsSelected={optionsSelected}
            />

            <div className="flex flex-col">
              <Block className="p-4 w-72">
                <div className="space-y-2">
                  {optionsSelected.map(option => (
                    <Tag
                      key={option.id}
                      id={option.id}
                      title={getTranslation(option.name)}
                      deleteCondition
                      handleDelete={() => removeOption(option.id)}
                    />
                  ))}
                </div>
              </Block>

              <Button
                id="btn-add-options"
                name="btn-add-options"
                item={null}
                type="submit"
                onClick={addOptionsToCart}
                className="self-end"
              >
                <span className="text-center">
                  <IntlMessages id="general.submit" />
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(BookingOptions);
