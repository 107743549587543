import React, { useEffect, useState } from "react";
import Holidays from "date-holidays";
import { ClosedDayItem } from "../../../../../utils/dayslot";
import CustomDaySlot from "./CustomDaySlot";
import { displayDayYYYYMMDD } from "../../../../../utils/config";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";

interface Props {
  closedDays: ClosedDayItem[] | null;
  country: string;
  onChange: (e) => void;
}

export interface CustomHoliday extends Holidays.Holiday {
  isCustom: boolean;
  isClosed: boolean;
  closedDay?: ClosedDayItem;
}

const getSortedClosedDays = (
  closedDays: ClosedDayItem[] | null
): ClosedDayItem[] => {
  // sort closed days by start
  const values = closedDays
    ? closedDays
        ?.filter(c => !!c.item && !!c.item.start && !!c.item.end)
        .sort((a, b) => {
          return (
            new Date(a.item.start).getTime() - new Date(b.item.start).getTime()
          );
        })
    : [];

  return values;
};

const ClosedDaysComponent = (props: Props): JSX.Element => {
  const { closedDays, country, onChange } = props;
  const [selectedClosedDays, setSelectedClosedDays] = useState<CustomHoliday[]>(
    []
  );

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  useEffect(() => {
    let days: CustomHoliday[] = [];

    // sort by start
    const sortedClosedDays = getSortedClosedDays(closedDays);
    sortedClosedDays?.forEach(day => {
      const { start, end } = day.item;

      const closedDayStartDate = new Date(start);
      const closedDayEndDate = new Date(end);

      const date = `${displayDayYYYYMMDD(
        closedDayStartDate,
        centerTimezone
      )}_${displayDayYYYYMMDD(closedDayEndDate, centerTimezone)}`;
      /** add custom closed days */
      const closed: CustomHoliday = {
        date,
        start: closedDayStartDate,
        end: closedDayEndDate,
        name: "",
        type: "public",
        rule: "",
        isClosed: true,
        isCustom: true,
        closedDay: day,
      };
      days.push(closed);
    });

    days = days.sort((a, b) => {
      return a.start.getTime() - b.start.getTime();
    });
    setSelectedClosedDays(days);
  }, [country, closedDays]);

  const handleOnChange = (closedDay: ClosedDayItem) => {
    onChange(closedDay);
  };

  return (
    <tbody className="bg-white">
      {selectedClosedDays.map((selectedClosedDay, index) => {
        const { isClosed } = selectedClosedDay;

        return (
          <tr className="border border-gray-200" key={index}>
            <CustomDaySlot
              holiday={selectedClosedDay}
              isOpen={!isClosed}
              onChange={handleOnChange}
            />
          </tr>
        );
      })}
    </tbody>
  );
};

export default ClosedDaysComponent;
