import { ErrorGraphQL } from "../../services/coreUtils";
/*
 *
 * PriceUnitVariation Model
 *
 */

import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel } from "..";
import { Operator } from "../Operator";

import {
  CreatePriceUnitVariationInput as CreatePriceUnitVariation,
  UpdatePriceUnitVariationInput as UpdatePriceUnitVariation,
  ModelPriceUnitVariationFilterInput,
} from "../../api/graphql/types";

export interface PriceUnitVariation {
  id: string;
  operator?: Operator;
  key: string;
  name?: string;
  enabled?: boolean;
  markForDelete?: boolean;
}

interface SearchPriceUnitVariationsOpts {
  limit?: number;
  filter?: ModelPriceUnitVariationFilterInput;
  gql?: string | null;
}

export interface ModelPriceUnitVariationConnection {
  items: PriceUnitVariation[];
  total: number;
}

// Interface declaration
export interface PriceUnitVariationModel {
  priceUnitVariations: ModelPriceUnitVariationConnection;
  priceUnitVariation: PriceUnitVariation | null;
  setPriceUnitVariations: Action<
    PriceUnitVariationModel,
    { data: ModelPriceUnitVariationConnection }
  >;
  setPriceUnitVariation: Action<
    PriceUnitVariationModel,
    PriceUnitVariation | null
  >;
  addPriceUnitVariation: Action<PriceUnitVariationModel, PriceUnitVariation>;
  replacePriceUnitVariation: Action<
    PriceUnitVariationModel,
    PriceUnitVariation
  >;
  searchAllPriceUnitVariations: Thunk<
    PriceUnitVariationModel,
    SearchPriceUnitVariationsOpts,
    Injections,
    StoreModel
  >;
  createPriceUnitVariation: Thunk<
    PriceUnitVariationModel,
    CreatePriceUnitVariation,
    Injections
  >;
  updatePriceUnitVariation: Thunk<
    PriceUnitVariationModel,
    UpdatePriceUnitVariation,
    Injections
  >;
}

export const priceUnitVariationModel: PriceUnitVariationModel = {
  priceUnitVariations: { items: [], total: 0 },
  priceUnitVariation: null,

  setPriceUnitVariations: action(
    (state, payload: { data: ModelPriceUnitVariationConnection }) => {
      const { data } = payload;
      state.priceUnitVariations = data;
    }
  ),

  setPriceUnitVariation: action((state, payload) => {
    state.priceUnitVariation = payload;
  }),

  addPriceUnitVariation: action((state, payload) => {
    if (state.priceUnitVariations.items) {
      state.priceUnitVariations.items.push(payload);
    } else {
      state.priceUnitVariations["items"] = [payload];
    }
  }),

  replacePriceUnitVariation: action((state, payload) => {
    state.priceUnitVariation = payload;
    if (state.priceUnitVariations.items) {
      const index = state.priceUnitVariations.items!.findIndex(
        e => e.id === payload.id
      );
      if (index >= 0)
        state.priceUnitVariations.items!.splice(index, 1, payload);
    }
  }),

  searchAllPriceUnitVariations: thunk(
    async (actions, payload, { injections }) => {
      const { priceUnitVariationService } = injections;
      const response =
        await priceUnitVariationService.searchAllPriceUnitVariations(payload);

      const errors = Object.prototype.hasOwnProperty.call(response, "errors");
      if (errors) return ErrorGraphQL(response);

      const data = response.data.searchAllPriceUnitVariations;
      actions.setPriceUnitVariations({ data });

      return data;
    }
  ),

  createPriceUnitVariation: thunk(async (actions, payload, { injections }) => {
    const { priceUnitVariationService } = injections;
    const response = await priceUnitVariationService.createPriceUnitVariation(
      payload
    );

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const item = response.data.createPriceUnitVariation;
    actions.setPriceUnitVariation(item as PriceUnitVariation);
    actions.addPriceUnitVariation(item as PriceUnitVariation);

    return item;
  }),

  updatePriceUnitVariation: thunk(async (actions, payload, { injections }) => {
    const { priceUnitVariationService } = injections;
    const response = await priceUnitVariationService.updatePriceUnitVariation(
      payload
    );

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const item = response.data.updatePriceUnitVariation;
    actions.setPriceUnitVariation(item as PriceUnitVariation);
    actions.replacePriceUnitVariation(item as PriceUnitVariation);

    return item;
  }),
};
