import React from "react";
import { contextStore } from "..";

interface GroundAuthOpts {
  children: React.ReactNode;
}

let GroundAuthContextStore: typeof contextStore;

const GroundAuthProvider = (props: GroundAuthOpts): JSX.Element => {
  const { children } = props;
  GroundAuthContextStore = contextStore;

  return (
    <GroundAuthContextStore.Provider>
      {children}
    </GroundAuthContextStore.Provider>
  );
};

export { GroundAuthProvider, GroundAuthContextStore };
