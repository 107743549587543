import React from "react";
import ReactModal from "react-modal";
import { match as Match } from "react-router-dom";
import { injectIntl, IntlShape } from "react-intl";
import EventTypeForm from "./form";
import { EventType } from "../../../lib/ground-aws-graphql-core/models/EventType";

interface Props {
  isOpen: boolean;
  toggle: (e) => void;
  action: string | null;
  eventType: EventType | null | undefined;
  match: Match<{ cid: string; id: string }>;
  intl: IntlShape;
  onRequestClose: (e) => void;
}

const ModalCreateEventType = (props: Props) => {
  const { isOpen, onRequestClose } = props;

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      portalClassName="modal-center"
      style={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
      shouldCloseOnOverlayClick
      onRequestClose={onRequestClose}
      className="modal-dialog-lg modal-content"
    >
      <EventTypeForm {...props} />
    </ReactModal>
  );
};

export default injectIntl(ModalCreateEventType);
