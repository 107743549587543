import React from "react";
import Switch from "rc-switch";
import { FieldAttributes } from "formik";
import { AdditionalFieldAttributes } from "..";
import IntlMessages from "../../../utils/messages";

interface Props {
  field: FieldAttributes<AdditionalFieldAttributes>;
  description: string;
  onChange: (e: any) => void;
  value: boolean;
  disabled?: boolean;
}

const FormSwitch = ({
  field,
  value,
  description,
  onChange,
  disabled,
}: Props): JSX.Element => {
  return (
    <div className="flex items-center">
      <Switch
        id={field.name}
        className="max-w-xs"
        checked={value}
        disabled={disabled}
        data-cy={field.name}
        onChange={(checked, e) => {
          onChange({
            target: { ...e.target, name: field.name, value: checked },
          });
        }}
      />

      {description && (
        <span className="text-13px text-ground-black-100 ml-2">
          <IntlMessages id={`${description}.${value}`} />
        </span>
      )}
    </div>
  );
};

export default FormSwitch;
