import ApolloClient from "apollo-boost";
import { errorHandler } from "./index";

export default (jwtToken: string): ApolloClient<unknown> => {
  return new ApolloClient({
    uri: `${process.env.REACT_APP_BACKEND_ENDPOINT}/gql`,
    headers: { Authorization: `Bearer ${jwtToken}` },
    onError: errorHandler,
    credentials: "include",
  });
};
