/*
 *
 * Attribute Key Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import { searchAllAttributeKeys as searchAllAttributeKeysQuery } from "../../api/graphql/queries";
import { updateAttributeKey as updateAttributeKeyMutation } from "../../api/graphql/mutations";
import { UpdateAttributeKeyMutation } from "../../api/graphql/types";
import { fetchPolicy } from "../../api/client";
import {
  ModelAttributeKeyConnection,
  SearchAttributeKeysOpts,
} from "../../models/AttributeKey";

const DEFAULT_LIMIT = 100;

export const updateAttributeKey = payload =>
  ApolloClient.mutate({
    mutation: gql(updateAttributeKeyMutation),
    variables: { input: payload },
  }) as {
    data: UpdateAttributeKeyMutation;
  };

export const searchAllAttributeKeys = (payload: SearchAttributeKeysOpts) => {
  const request = payload.gql ? payload.gql : searchAllAttributeKeysQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: {
      filter: payload.filter,
      limit: payload?.limit || DEFAULT_LIMIT,
    },
    fetchPolicy: fetchPolicy,
  }) as {
    data: {
      searchAllAttributeKeys: ModelAttributeKeyConnection;
    };
  };
};
