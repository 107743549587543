import { AxiosResponse } from "axios";
import axiosBackend from "../../../../../axiosBackend";
import {
  CancelOrderOpts,
  CancelOrderItemOpts,
} from "../../../models/Api/Cancellation/index";

export const cancelOrder = async ({
  globalOrderId,
  orderId,
}: CancelOrderOpts): Promise<AxiosResponse> => {
  return axiosBackend
    .post(`/global_orders/${globalOrderId}/orders/${orderId}/cancel`)
    .catch(error => {
      return error.response;
    });
};

export const cancelOrderItem = async ({
  globalOrderId,
  orderId,
  orderItemId,
}: CancelOrderItemOpts): Promise<AxiosResponse> => {
  return axiosBackend
    .post(
      `/global_orders/${globalOrderId}/orders/${orderId}/order_items/${orderItemId}/cancel`
    )
    .catch(error => {
      return error.response;
    });
};
