import { ErrorGraphQL } from "./../../services/coreUtils";
import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { Operator } from "../Operator";

import {
  CreateAttributeInput as CreateAttribute,
  UpdateAttributeInput as UpdateAttribute,
  DeleteAttributeInput as DeleteAttribute,
} from "../../api/graphql/types";
import { AttributeKey } from "../AttributeKey";

export interface Attribute {
  id: string;
  operator?: Operator;
  key: AttributeKey;
  booleanValue?: boolean;
  stringValue?: string;
  urlValue?: string;
  numberValue?: number;
  jsonValue?: string;
  dateValue?: string;
  selectValue?: string;
  value: string;
  enabled?: boolean;
  markForDelete?: boolean;
}

export interface ModelAttributeConnection {
  items: [Attribute] | null;
  total?: number | null;
}

// Interface declaration
export interface AttributeModel {
  attributes: ModelAttributeConnection;
  hasMoreData: boolean;
  attribute: Attribute | null;

  setAttribute: Action<AttributeModel, Attribute | null>;
  addAttribute: Action<AttributeModel, Attribute>;
  removeAttribute: Action<AttributeModel, Attribute>;
  replaceAttribute: Action<AttributeModel, Attribute>;
  createAttribute: Thunk<AttributeModel, CreateAttribute, Injections>;
  updateAttribute: Thunk<AttributeModel, UpdateAttribute, Injections>;
  deleteAttribute: Thunk<AttributeModel, DeleteAttribute, Injections>;
}

export const attributeModel: AttributeModel = {
  attributes: { items: null, total: null },
  hasMoreData: false,
  attribute: null,

  setAttribute: action((state, payload) => {
    state.attribute = payload;
  }),

  addAttribute: action((state, payload) => {
    if (state.attributes.items) {
      state.attributes.items.push(payload);
    } else {
      state.attributes["items"] = [payload];
    }
  }),

  removeAttribute: action((state, payload) => {
    if (state.attributes.items) {
      const index = state.attributes.items!.findIndex(e => e.id === payload.id);
      state.attributes.items!.splice(index, 1);
    }
  }),

  replaceAttribute: action((state, payload) => {
    state.attribute = payload;
    if (state.attributes.items) {
      const index = state.attributes.items!.findIndex(e => e.id === payload.id);
      if (index >= 0) state.attributes.items!.splice(index, 1, payload);
    }
  }),

  createAttribute: thunk(async (actions, payload, { injections }) => {
    const { attributeService } = injections;
    const response = await attributeService.createAttribute(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.createAttribute;
    actions.setAttribute(item);
    actions.addAttribute(item);

    return item;
  }),

  updateAttribute: thunk(async (actions, payload, { injections }) => {
    const { attributeService } = injections;
    const response = await attributeService.updateAttribute(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.updateAttribute;
    actions.setAttribute(item);
    actions.replaceAttribute(item);

    return item;
  }),

  deleteAttribute: thunk(async (actions, payload, { injections }) => {
    const { attributeService } = injections;
    const response = await attributeService.deleteAttribute(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.deleteAttribute;
    actions.setAttribute(item);
    actions.removeAttribute(item);

    return item;
  }),
};
