/*
 *
 * GlobalOrder Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import { GetGlobalOrderQuery } from "../../api/graphql/types";

import {
  getGlobalOrder as getGlobalOrderQuery,
  searchGlobalOrders as searchGlobalOrdersQuery,
} from "../../api/graphql/queries";

import { fetchPolicy } from "../../api/client";
import { SearchGlobalOrdersOpts } from "../../models/GlobalOrder";

const DEFAULT_LIMIT = 100;

export const getGlobalOrder = payload => {
  const request = payload.gql ? payload.gql : getGlobalOrderQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: { id: payload.id },
    fetchPolicy,
  }) as {
    data: GetGlobalOrderQuery;
  };
};

export const searchGlobalOrders = (payload: SearchGlobalOrdersOpts) => {
  const request = payload.gql ? payload.gql : searchGlobalOrdersQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: {
      filter: payload.filter,
      sort: payload.sort,
      limit: payload?.limit || DEFAULT_LIMIT,
      from: payload?.from,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};
