/*
 *
 * Provider Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import {
  GetProviderQuery,
  CreateProviderMutation,
  UpdateProviderMutation,
} from "../../api/graphql/types";

import {
  getProvider as getProviderQuery,
  searchProviders as searchProvidersQuery,
  searchAllProviders as searchAllProvidersQuery,
} from "../../api/graphql/queries";

import {
  createProvider as createProviderMutation,
  updateProvider as updateProviderMutation,
  deleteProvider as deleteProviderMutation,
} from "../../api/graphql/mutations";

import { fetchPolicy } from "../../api/client";
import {
  ModelProviderConnection,
  SearchProvidersOpts,
} from "../../models/Provider";

const DEFAULT_LIMIT = 100;

export const getProvider = payload => {
  const request = payload.gql ? payload.gql : getProviderQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: { id: payload.id },
  }) as {
    data: GetProviderQuery;
  };
};

export const createProvider = payload =>
  ApolloClient.mutate({
    mutation: gql(createProviderMutation),
    variables: { input: payload },
  }) as {
    data: CreateProviderMutation;
  };

export const updateProvider = payload =>
  ApolloClient.mutate({
    mutation: gql(updateProviderMutation),
    variables: { input: payload },
  }) as {
    data: UpdateProviderMutation;
  };

export const deleteProvider = payload =>
  ApolloClient.mutate({
    mutation: gql(deleteProviderMutation),
    variables: { input: payload },
  }) as {
    data: boolean;
  };

export const searchProviders = (payload: SearchProvidersOpts) => {
  return ApolloClient.query({
    query: gql(searchProvidersQuery),
    variables: {
      filter: payload.filter,
      sort: payload.sort,
      locale: payload.locale,
      limit: payload.limit || DEFAULT_LIMIT,
      from: payload.from || 0,
    },
    fetchPolicy: fetchPolicy,
  }) as {
    data: any;
  };
};

export const searchAllProviders = (payload: SearchProvidersOpts) => {
  return ApolloClient.query({
    query: gql(searchAllProvidersQuery),
    variables: {
      filter: payload.filter,
      sort: payload.sort,
      locale: payload.locale,
      limit: payload.limit || DEFAULT_LIMIT,
      from: payload.from || 0,
    },
    fetchPolicy: fetchPolicy,
  }) as {
    data: {
      searchAllProviders: ModelProviderConnection;
    };
  };
};
