import React from "react";
import Holidays from "date-holidays";
import contextStore from "../../../../../../redux/store";
import { getLocale } from "../../../../../../lang";
import { displayDayLabel } from "../../../../../../utils/config";
import { GroundGraphqlContextStore } from "../../../../../../lib/ground-aws-graphql-core";

interface Props {
  holiday: Holidays.Holiday;
}

const HolidaySlot = (props: Props): JSX.Element => {
  const { holiday } = props;

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  return (
    <td className="px-4 py-3 flex justify-end items-center">
      <span className="capitalize">
        {displayDayLabel(holiday.start, currentAppLocale, centerTimezone)} :{" "}
        {holiday.name}
      </span>
    </td>
  );
};

export default HolidaySlot;
