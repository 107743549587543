/*
 *
 * Enterprise Model
 *
 */

import { Action, Thunk, thunk, action } from "easy-peasy";
import {
  GqlQuery,
  StoreModel,
} from "../../../lib/ground-aws-graphql-core/models";
import { User } from "../../../lib/ground-aws-graphql-core/models/User";
import { ErrorGraphQL } from "../../../lib/ground-aws-graphql-core/services/coreUtils";
import { Injections } from "../../store";

export interface Enterprise {
  id: string;
  name: string;
  siret: string;
  paymentOnInvoice: boolean;
  users: User[];
  totalUsers: number;
}

export interface ModelEnterpriseConnection {
  items: Enterprise[];
  total: number;
}

export type CreateEnterpriseInput = {
  name: string;
  siret: string;
  paymentOnInvoice: boolean;
};

export type UpdateEnterpriseInput = {
  id: string;
  name: string;
  siret: string;
  paymentOnInvoice: boolean;
};

export type DeleteEnterpriseInput = {
  id: string;
};

export interface EnterpriseModel {
  enterprises: ModelEnterpriseConnection;
  enterprise: Enterprise | null;
  hasMoreData: boolean;

  getEnterprise: Thunk<EnterpriseModel, GqlQuery, Injections, StoreModel>;
  setEnterprises: Action<
    EnterpriseModel,
    { data: ModelEnterpriseConnection; page?: number }
  >;
  setEnterprise: Action<EnterpriseModel, Enterprise | null>;
  listEnterprises: Thunk<EnterpriseModel, null, Injections, StoreModel>;
  addEnterprise: Action<EnterpriseModel, Enterprise>;
  replaceEnterprise: Action<EnterpriseModel, Enterprise>;
  removeEnterprise: Action<EnterpriseModel, DeleteEnterpriseInput>;
  createEnterprise: Thunk<EnterpriseModel, CreateEnterpriseInput, Injections>;
  updateEnterprise: Thunk<EnterpriseModel, UpdateEnterpriseInput, Injections>;
  deleteEnterprise: Thunk<EnterpriseModel, DeleteEnterpriseInput, Injections>;
}

export const enterpriseModel: EnterpriseModel = {
  enterprises: { items: [] as unknown as Enterprise[], total: 0 },
  hasMoreData: false,
  enterprise: null,

  getEnterprise: thunk(async (actions, payload, { injections }) => {
    const { enterpriseService } = injections;

    const response = await enterpriseService.getEnterprise(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const enterprise = response.data.getEnterprise;

    actions.setEnterprise(enterprise as Enterprise);

    return enterprise;
  }),

  setEnterprises: action((state, payload) => {
    state.enterprises = payload.data;
  }),

  setEnterprise: action((state, payload) => {
    state.enterprise = payload;
  }),

  removeEnterprise: action((state, payload) => {
    state.enterprises.items = state.enterprises.items.filter(
      enterprise => enterprise.id !== payload.id
    );
    state.enterprises.total = state.enterprises.total - 1;
  }),

  listEnterprises: thunk(async (actions, _, { injections }) => {
    const { enterpriseService } = injections;

    const response = await enterpriseService.listEnterprises();

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const data = response.data.listEnterprises;
    actions.setEnterprises({ data });

    return data;
  }),

  addEnterprise: action((state, payload) => {
    if (state.enterprises.items) {
      state.enterprises.items.push(payload);
    } else {
      state.enterprises["items"] = [payload];
    }
  }),

  replaceEnterprise: action((state, payload) => {
    state.enterprise = payload;
    if (state.enterprises.items) {
      const index = state.enterprises.items!.findIndex(
        e => e.id === payload.id
      );
      if (index >= 0) state.enterprises.items!.splice(index, 1, payload);
    }
  }),

  createEnterprise: thunk(async (actions, payload, { injections }) => {
    const { enterpriseService } = injections;
    const response = await enterpriseService.createEnterprise(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const item = response.data.createEnterprise;
    actions.setEnterprise(item as Enterprise);
    actions.addEnterprise(item as Enterprise);

    return item;
  }),

  updateEnterprise: thunk(async (actions, payload, { injections }) => {
    const { enterpriseService } = injections;
    const response = await enterpriseService.updateEnterprise(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const item = response.data.updateEnterprise;
    actions.setEnterprise(item as Enterprise);
    actions.replaceEnterprise(item as Enterprise);

    return item;
  }),

  deleteEnterprise: thunk(async (_actions, payload, { injections }) => {
    const { enterpriseService } = injections;

    const response = await enterpriseService.deleteEnterprise(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    _actions.removeEnterprise(payload);

    return response;
  }),
};
