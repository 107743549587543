import { Thunk, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel } from "../";
import { Image } from "../../../utils/types";
import { AttributeItem } from "../../../utils/attribute";
import { AttributeKey } from "../../../lib/ground-aws-graphql-core/models/AttributeKey";
import { AuthorizationItem } from "../../../lib/ground-aws-graphql-core/models/Authorization";
import { Center } from "../../../lib/ground-aws-graphql-core/models/Center";
import {
  ClosedDayItem,
  DaySlotItem,
  ExceptionalOpeningHourItem,
  HoursRangeItem,
} from "../../../utils/dayslot";

export interface CreateCenterOpts {
  center: Center | null;
  values: any;
  pictures?: Image[];
  attributeKeys: AttributeKey[] | null;
  attributes: AttributeItem[] | null;
  enabled: boolean;
  operator?: any;
  dayslots?: any;
  hoursRanges?: any;
  callbacks: {
    updateCenter: any;
    createCenter: any;
  };
}

export interface UpdateCenterOpts {
  center: Center | null;
  values: any;
  pictures?: Image[];
  attributeKeys: AttributeKey[] | null;
  attributes: AttributeItem[] | null;
  authorizations: AuthorizationItem[] | null;
  enabled: boolean;
  operator: any;
  daySlots?: DaySlotItem[];
  closedDays?: ClosedDayItem[];
  hoursRanges?: HoursRangeItem[];
  exceptionalOpeningHours?: ExceptionalOpeningHourItem[];
  centerTypeId?: string;
  callbacks: {
    updateCenter: any;
    createCenter: any;
    createAuthorization: any;
  };
}

// Interface declaration
export interface CenterModel {
  createCenter: Thunk<CenterModel, CreateCenterOpts, Injections, StoreModel>;
  updateCenter: Thunk<CenterModel, UpdateCenterOpts, Injections, StoreModel>;
  duplicateCenter: Thunk<CenterModel, { id: string }, Injections, StoreModel>;
}

export const centerModel: CenterModel = {
  createCenter: thunk(async (_actions, payload, { injections }) => {
    const { centerService } = injections;
    const response = await centerService.createCenter(payload);

    return response;
  }),
  updateCenter: thunk(async (_actions, payload, { injections }) => {
    const { centerService } = injections;
    const response = await centerService.updateCenter(payload);

    return response;
  }),
  duplicateCenter: thunk(async (_actions, payload, { injections }) => {
    const { centerService } = injections;
    const response = await centerService.duplicateCenter(payload.id);

    return response;
  }),
};
