import React, { Fragment, useState } from "react";
import {
  PriceUnitItem,
  PriceUnitElement,
  getPriceUnitElementLabelByUnit,
  getCurrencySymbol,
} from "../../../utils/price-unit";
import images from "../../../images";
import Button from "../Button";
import { ActionTypes, isConsumable, isPrestation } from "../../../utils/types";
import Switch from "rc-switch";
import TextInput from "../TextInput";
import { getCypressTestId } from "../../../utils/config";
import { injectIntl, IntlShape } from "react-intl";
import IntlMessages from "../../../utils/messages";
import { PriceUnitVariation } from "../../../lib/ground-aws-graphql-core/models/PriceUnitVariation";
import {
  EnumCurrency,
  EnumProductTypology,
  EnumUnit,
} from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import { PriceUnit } from "../../../lib/ground-aws-graphql-core/models/PriceUnit";

interface Props {
  intl: IntlShape;
  variation: PriceUnitVariation;
  productTypology: EnumProductTypology | undefined | null;
  priceUnits: PriceUnitItem[];
  values: PriceUnitElement[];
  name: string;
  label: string;
  index: number;
  last?: boolean;
  onAddPriceUnit: (variation: PriceUnitVariation) => void;
  onUpdatePriceUnit: (item: PriceUnit, variation: PriceUnitVariation) => void;
  onDeletePriceUnit: (item: PriceUnit, variation: PriceUnitVariation) => void;
  onUpdatePriceUnitVariation: () => void;
  alternate?: boolean;
}

const SHOW_PRICE_UNIT_FLEXIBILITY = false;

const ListPriceUnits = (props: Props) => {
  const {
    productTypology,
    intl,
    values,
    priceUnits,
    onUpdatePriceUnitVariation,
    onAddPriceUnit,
    onUpdatePriceUnit,
    onDeletePriceUnit,
    name,
    label,
    variation,
  } = props;

  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const prestation = isPrestation(productTypology);

  const consumable = isConsumable(productTypology);

  const notConsumableAndPrestation = !consumable && !prestation;

  const onChangePriceUnit = (
    value: string | boolean | number | any,
    key: string,
    item: PriceUnitItem
  ) => {
    item.item[key] = value;
    const label =
      key === "unit"
        ? getPriceUnitElementLabelByUnit(intl, item.item.unit)
        : null;
    if (label) {
      item.label = label;
    }

    setTriggerUpdate(!triggerUpdate);
  };

  const priceUnitAllowed = (pu: PriceUnitItem): boolean => {
    const pUnit = values.find(e => e.unit === pu.item.unit);

    return pUnit ? true : false;
  };

  const canAddUnit = (unit: EnumUnit): boolean => {
    const items = priceUnits.filter(e => e.item.unit === unit && !e.edit);

    if (items.length == 0) {
      return true;
    }

    let addUnit = false;
    Object.keys(EnumCurrency).map(c => {
      const currency = hasCurrency(items, c);

      if (!currency) {
        addUnit = true;
      }
    });

    return addUnit;
  };

  const hasCurrency = (items: PriceUnitItem[], currency: string): boolean => {
    const item = items.find(e => e.item.currency === currency);

    return item ? true : false;
  };

  const canAddCurrency = (currency: EnumCurrency, unit: EnumUnit): boolean => {
    const items = priceUnits.filter(e => e.item.unit === unit && !e.edit);

    const item = items.find(e => e.item.currency === currency);

    return item ? false : true;
  };

  const priceUnitsToShow = priceUnits.filter(
    pu => pu.action !== ActionTypes.TO_DELETE && priceUnitAllowed(pu)
  );

  return (
    <div
      key={`price_unit_${props.index}`}
      className="bg-ground-white-200 p-6 pr-0 pt-0 sm:border-t sm:border-gray-200 flex"
    >
      <div className="w-1/6 pt-6">
        <div className="flex">
          <label
            htmlFor={name}
            className="block text-14px font-medium leading-5 text-ground-black-100 sm:mt-px sm:pt-2"
          >
            {label}
          </label>
          <span
            data-cy="link-modify-price-unit-variation"
            data-testid={getCypressTestId(variation)}
            className="text-12px font-medium leading-5 text-ground-blue-100 sm:mt-px sm:pt-2 ml-2"
            onClick={onUpdatePriceUnitVariation}
          >
            + <IntlMessages id="general.modify" />
          </span>
        </div>
        <div className="mt-4 sm:col-span-1 sm:col-start-2">
          <Button
            item={null}
            id={`btn-add-price-unit_${variation.key}`}
            name={`btn-add-price-unit_${variation.key}`}
            type="button"
            outline
            onClick={() => onAddPriceUnit(variation)}
          >
            <span className="text-center">
              <IntlMessages id="page.product.create.price.unit" />
            </span>
          </Button>
        </div>
      </div>

      <div className="w-5/6 flex flex-col">
        {priceUnitsToShow.map((pu: PriceUnitItem) => {
          return (
            <Fragment key={`punit_${pu.item.id}`}>
              <div className="flex pt-6">
                <div className="ml-6 grid grid-cols-1 sm:grid-cols-6 gap-y-6 gap-x-4 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 w-5/6">
                  <div className="sm:col-span-3">
                    <label className="block text-14px font-medium leading-5 text-ground-gray-100">
                      <IntlMessages id="page.product.price.unit.unit" />
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <select
                        className={`block form-select w-full transition duration-150 ease-in-out pl-7 sm:text-14px placeholder-ground-gray-100 ${
                          !pu.edit ? "bg-ground-white-200" : ""
                        }`}
                        id={`unit_${variation.key}_${pu.item.unit}`}
                        name="unit"
                        data-cy="price-unit-unit"
                        data-testid={getCypressTestId(pu.item)}
                        defaultValue={pu.item.unit}
                        placeholder={intl.formatMessage({
                          id: "page.product.price.unit.placeholder",
                        })}
                        disabled={!pu.edit}
                        onChange={e =>
                          onChangePriceUnit(e.target.value, "unit", pu)
                        }
                      >
                        {values.map(
                          (value: PriceUnitElement, index: number) => {
                            return (
                              <option
                                key={index}
                                value={value.unit}
                                disabled={!canAddUnit(value.unit)}
                                //  selected={value.unit == pu.item.unit}
                              >
                                {value.label}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label className="block text-14px font-medium leading-5 text-ground-gray-100">
                      <IntlMessages id="general.amount.ht" />
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-14px sm:leading-5">
                          {getCurrencySymbol(EnumCurrency.EUR)}
                        </span>
                      </div>
                      <input
                        id={`price_${variation.key}_${pu.item.unit}`}
                        type="number"
                        data-cy="price-unit-price"
                        data-testid={getCypressTestId(pu.item)}
                        min={0}
                        step={0.01}
                        defaultValue={pu.item.price}
                        className={`form-input block w-full pl-7 pr-16 sm:text-14px sm:leading-5 ${
                          !pu.edit ? "bg-ground-white-200" : ""
                        }`}
                        placeholder="0.00"
                        disabled={!pu.edit}
                        onChange={e =>
                          onChangePriceUnit(e.target.valueAsNumber, "price", pu)
                        }
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center">
                        <select
                          data-cy="price-unit-currency"
                          data-testid={getCypressTestId(pu.item)}
                          value={pu.item.currency}
                          disabled={!pu.edit}
                          onChange={e =>
                            onChangePriceUnit(e.target.value, "currency", pu)
                          }
                          aria-label="Currency"
                          className="form-select h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm sm:leading-5"
                        >
                          {Object.keys(EnumCurrency).map(key => (
                            <option
                              key={EnumCurrency[key]}
                              value={EnumCurrency[key]}
                              disabled={
                                !canAddCurrency(EnumCurrency[key], pu.item.unit)
                              }
                            >
                              {EnumCurrency[key].slice(0, 3)}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="sm:col-span-3 hidden">
                    <label className="block text-14px font-medium leading-5 text-ground-gray-100">
                      <IntlMessages id="page.product.price.unit.increment" />
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input
                        id={`increment_${variation.key}_${pu.item.unit}`}
                        type="number"
                        data-cy="price-unit-increment"
                        data-testid={getCypressTestId(pu.item)}
                        min={0}
                        defaultValue={pu.item.increment}
                        className={`form-input block w-full pl-7 sm:text-14px sm:leading-5 ${
                          !pu.edit ? "bg-ground-white-200" : ""
                        }`}
                        placeholder="1"
                        disabled={!pu.edit}
                        onChange={e =>
                          onChangePriceUnit(
                            e.target.valueAsNumber,
                            "increment",
                            pu
                          )
                        }
                      />
                    </div>
                  </div>

                  {notConsumableAndPrestation && (
                    <div className="sm:col-span-3">
                      <label className="block text-14px font-medium leading-5 text-ground-gray-100">
                        <IntlMessages id="page.product.price.unit.minimum.approval" />
                      </label>
                      <div className="mt-1 rounded-md shadow-sm">
                        <input
                          id={`minApprovalMinutes_${variation.key}_${pu.item.unit}`}
                          type="number"
                          data-cy="price-unit-minapprovalminutes"
                          data-testid={getCypressTestId(pu.item)}
                          min={0}
                          defaultValue={pu.item.minApprovalMinutes || undefined}
                          className={`form-input block w-full pl-7 sm:text-14px sm:leading-5 ${
                            !pu.edit ? "bg-ground-white-200" : ""
                          }`}
                          disabled={!pu.edit}
                          onChange={e =>
                            onChangePriceUnit(
                              e.target.valueAsNumber,
                              "minApprovalMinutes",
                              pu
                            )
                          }
                        />
                      </div>
                    </div>
                  )}

                  {notConsumableAndPrestation && (
                    <div className="sm:col-span-3">
                      <label className="block text-14px font-medium leading-5 text-ground-gray-100">
                        <IntlMessages id="page.product.price.unit.minimum.cancellation" />
                      </label>
                      <div className="mt-1 rounded-md shadow-sm">
                        <input
                          id={`minCancelMinutes_${variation.key}_${pu.item.unit}`}
                          data-cy="price-unit-mincancelminutes"
                          data-testid={getCypressTestId(pu.item)}
                          type="number"
                          min={0}
                          defaultValue={pu.item.minCancelMinutes || undefined}
                          className={`form-input block w-full pl-7 sm:text-14px sm:leading-5 ${
                            !pu.edit ? "bg-ground-white-200" : ""
                          }`}
                          disabled={!pu.edit}
                          onChange={e =>
                            onChangePriceUnit(
                              e.target.valueAsNumber,
                              "minCancelMinutes",
                              pu
                            )
                          }
                        />
                      </div>
                    </div>
                  )}

                  {SHOW_PRICE_UNIT_FLEXIBILITY &&
                    notConsumableAndPrestation && (
                      <>
                        <div className="sm:col-span-1">
                          <label className="block text-14px font-medium leading-5 text-ground-gray-100">
                            <IntlMessages id="general.flexible" />
                          </label>
                          <Switch
                            id={`switch_flex_puv_${pu.item.id}`}
                            data-cy={`switch_flex_puv_${pu.item.id}`}
                            data-testid={getCypressTestId(pu.item)}
                            disabled={!pu.edit}
                            key={pu.item.id}
                            checked={pu.item.flexible}
                            onChange={e => onChangePriceUnit(e, "flexible", pu)}
                          />
                        </div>
                        <div className="sm:col-span-2">
                          <span className="block text-14px font-medium leading-5 text-ground-gray-100">
                            <IntlMessages id="general.booking.unit" />
                          </span>
                          <div className="rounded-md shadow-sm">
                            <input
                              id={`buyableUnit_${variation.key}_${pu.item.unit}`}
                              type="number"
                              data-cy="price-unit-buyableunit"
                              data-testid={getCypressTestId(pu.item)}
                              min={0}
                              defaultValue={pu.item.buyableUnit}
                              className={`form-input block w-full pl-7 sm:text-14px sm:leading-5 ${
                                !pu.edit || !pu.item.flexible
                                  ? "bg-ground-white-200"
                                  : ""
                              }`}
                              disabled={!pu.edit || !pu.item.flexible}
                              onChange={e =>
                                onChangePriceUnit(
                                  e.target.valueAsNumber,
                                  "buyableUnit",
                                  pu
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <span className="block text-14px font-medium leading-5 text-ground-gray-100">
                            <IntlMessages id="page.product.price.unit.buyable.description" />
                          </span>
                          <TextInput
                            item={pu.item}
                            label=""
                            index={1}
                            errors={""}
                            touched={""}
                            type="text"
                            className={`form-input block w-full pl-7 sm:text-14px sm:leading-5 ${
                              !pu.edit ? "bg-ground-white-200" : ""
                            }`}
                            id={`buyableDescription_${pu.item.unit}`}
                            name="buyableDescription"
                            value={
                              pu.item.buyableDescription
                                ? pu.item.buyableDescription
                                : ""
                            }
                            // placeholder={e.placeholder}
                            onChange={e => {
                              return onChangePriceUnit(
                                e.target.value,
                                "buyableDescription",
                                pu
                              );
                            }}
                            disabled={!pu.edit}
                            useIntl={true}
                            translatable
                            onlyInput
                          />
                        </div>
                      </>
                    )}
                </div>
                <div className="flex self-center justify-end mt-1 sm:mt-0 sm:col-span-1 w-1/6">
                  <div className="px-2 cursor-pointer">
                    {pu.edit && (
                      <img
                        id={pu.item.id}
                        data-cy="validate-picto-price-unit-variation"
                        data-testid={getCypressTestId(pu.item)}
                        src={images.validate}
                        alt="validate"
                        className="pencil-validate"
                        onClick={() => onUpdatePriceUnit(pu.item, variation)}
                      />
                    )}
                    {!pu.edit && (
                      <img
                        id={pu.item.id}
                        data-cy="edit-picto-price-unit-variation"
                        data-testid={getCypressTestId(pu.item)}
                        src={images.pencil}
                        alt="edit"
                        className="pencil-edit"
                        onClick={() => onUpdatePriceUnit(pu.item, variation)}
                      />
                    )}
                  </div>
                  <div className="px-2 cursor-pointer">
                    <img
                      id={pu.item.id}
                      data-cy="trash-picto-price-unit-variation"
                      data-testid={getCypressTestId(pu.item)}
                      src={images.trash}
                      alt="trash"
                      className="pencil-trash"
                      onClick={() => onDeletePriceUnit(pu.item, variation)}
                    />
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default injectIntl(ListPriceUnits);
