import React from "react";
import { Image } from "../../../../utils/types";
import { getImageUrl } from "../../../../utils/picture";
import { EnumPermissionEntity } from "../../../../lib/ground-aws-graphql-core/api/graphql/types";

interface Props {
  entity: EnumPermissionEntity;
  image: Image;
  width: number;
  height: number;
}

const ImageComponent = (props: Props): JSX.Element => {
  const { width, height, image, entity } = props;
  let w = Math.round(width);
  let h = Math.round(height);

  const url = !image.source
    ? getImageUrl(entity, image.picture as string, w, h)
    : (image.picture as string);

  if (!image.picture) {
    w = 25;
    h = 25;
  }

  return (
    <div className="relative text-center">
      <img
        alt="picture"
        className="rounded-3 w-full object-cover"
        src={url}
        style={{ height: `${h}px` }}
      />
    </div>
  );
};

export default ImageComponent;
