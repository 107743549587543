import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import { $enum } from "ts-enum-util";
import { EnumProductTypology } from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import { ALL_FILTER } from "../../Table";

interface Props {
  intl: IntlShape;
  typology?: EnumProductTypology;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const ReportingTypology = (props: Props): JSX.Element => {
  const { intl, typology, onChange } = props;

  const typologies = $enum(EnumProductTypology).getKeys();

  return (
    <div className="h-8 my-4 inline-block border border-ground-gray-400 rounded-4">
      <select
        id="reporting-typology"
        name="reporting-typology"
        className="form-select h-full py-0 bg-transparent text-ground-black-100 text-14px leading-5 border-none rounded-none shadow-none"
        onChange={onChange}
        defaultValue={typology}
      >
        <option value={ALL_FILTER}>
          {intl.formatMessage({ id: "general.all" })}
        </option>
        {typologies.map(d => (
          <option key={d} value={d}>
            {intl.formatMessage({
              id: `page.product.typology.${d}`,
            })}
          </option>
        ))}
      </select>
    </div>
  );
};

export default injectIntl(ReportingTypology);
