import React from "react";
import Table from "../../../../../components/Table";
import { ProductOption } from "../../../../../lib/ground-aws-graphql-core/models/Product";
import { getTranslation } from "../../../../../utils/translation";
import { getDefaultImage, getImageUrl } from "../../../../../utils/picture";

interface Props {
  productOptions: ProductOption[];
  selectOption: (option: ProductOption) => void;
  optionsSelected: ProductOption[];
}

const OptionsTable = (props: Props): JSX.Element => {
  const { productOptions, selectOption, optionsSelected } = props;

  const optionsTableHead = [
    "general.service",
    "general.category",
    "general.provider",
  ];

  const optionsTableBody = productOptions?.map(option => {
    const { id, name, picture, service, provider } = option;

    const optionName = getTranslation(name);
    const isOptionSelected = optionsSelected.includes(option);

    return {
      rowElements: [
        {
          element: (
            <div className="flex flew-row items-center space-x-4">
              <input
                type="checkbox"
                className="form-checkbox h-4 w-4 text-ground-green-100 transition duration-150 ease-in-out"
                onChange={() => selectOption(option)}
                checked={isOptionSelected}
              />
              <img
                alt={optionName}
                src={
                  picture
                    ? getImageUrl(null, picture, 50, 50)
                    : getDefaultImage(null)
                }
                style={picture ? undefined : { width: 50, height: 50 }}
                className="list-thumbnail responsive border-0"
              />

              <div data-cy={`product-option-name-${id}`}>{optionName}</div>
            </div>
          ),
        },
        { element: getTranslation(service?.name) },
        { element: getTranslation(provider?.name) },
      ],
      rowClassName: isOptionSelected ? "bg-gray-100" : "",
    };
  });

  return (
    <Table
      head={optionsTableHead}
      body={optionsTableBody}
      noDataText="general.list.empty"
      className="flex flex-1"
    />
  );
};

export default OptionsTable;
