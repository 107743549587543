import React from "react";
import { getTranslation } from "../../../../../utils/translation";
import { IncidentComment } from "../../../../../lib/ground-aws-graphql-core/models/Api/Incidents";
import {
  BodyElement,
  TableChangeParams,
} from "../../../../../components/Table/types";
import Table from "../../../../../components/Table";
import { displayDayDDMMYYYY_HHMM } from "../../../../../utils/config";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";

type Props = {
  incidentComments?: IncidentComment[];
  total: number;
  onChange: (params: TableChangeParams) => void;
};

const IncidentCommentsTable = (props: Props): JSX.Element => {
  const { incidentComments, total, onChange } = props;

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const incidentCommentsTableHead = [
    "general.date",
    "general.comment",
    "general.issuer",
  ];

  const incidentCommentTableBody = incidentComments?.map(item => {
    const { comment: iComment, issuer, created_at: createdAt } = item;

    const incidentCommentsElements: BodyElement[] = [
      {
        element: displayDayDDMMYYYY_HHMM(createdAt, centerTimezone),
      },
      {
        element: getTranslation(JSON.stringify(iComment)),
      },
      {
        element: issuer || "",
      },
    ];

    return {
      rowElements: incidentCommentsElements,
    };
  });

  return (
    <Table
      head={incidentCommentsTableHead}
      body={incidentCommentTableBody}
      noDataText="page.list.incident.comments.empty"
      paginationTotal={total}
      onChange={onChange}
    />
  );
};

export default IncidentCommentsTable;
