/*
 *
 * User Model
 *
 */
import { Action, Thunk, action, thunk } from "easy-peasy";
import { UserStatusType } from "./../../../../utils/user/index";
import { Injections } from "../../store";
import { StoreModel, GqlQuery } from "../";
import { ModelUnavailabilityConnection } from "./../Unavailability/index";
import { EnumLocale, EnumUserRole } from "./../../api/graphql/types";
import { Operator } from "../Operator";
import { Center } from "../Center";

import {
  SearchableUserFilterInput,
  SearchableUserSortableFields,
} from "../../api/graphql/types";
import { ErrorGraphQL } from "../../services/coreUtils";
import { Enterprise } from "../../../../redux/models/Enterprise";
import { PriceUnitVariation } from "../PriceUnitVariation";
import { PaymentMethod } from "../Api/PaymentMethods";
import { Attribute } from "../Attribute";
import { AttributesItem } from "../../utils/preprocessing";

export interface User {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  username?: string;
  phone?: string | null;
  enterprise?: Enterprise | null;
  priceUnitVariation: PriceUnitVariation | null;
  paymentMethods: {
    items: PaymentMethod[] | [];
    total: number;
  };
  attributes: {
    items: Attribute[] | [];
    total: number;
  };
  status?: UserStatusType | null;
  role?: EnumUserRole;
  picture?: string | null;
  enabled?: boolean;
  markForDelete?: boolean;
  userPoolId?: string;
  identityPoolId?: string;
  tier?: string;
  clientId?: string;
  locale?: string;
  operator?: Operator;
  center?: Center | null;
  unavailabilities?: ModelUnavailabilityConnection | null;
  createdAt: string;
}

export interface ModelUserConnection {
  items: User[] | [];
  total: number;
}

export interface SearchUsersOpts {
  filter: SearchableUserFilterInput;
  sort?: SearchableUserSortableFields;
  limit?: number | null;
  gql?: string | null;
  from?: number;
}

export interface CreateUserOpts {
  email: string;
  first_name: string;
  last_name: string;
  locale?: EnumLocale;
  attributes?: AttributesItem[];
}

export interface UpdateUserOpts {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  locale?: EnumLocale;
  attributes?: AttributesItem[];
}

// Interface declaration
export interface UserModel {
  user: User | null;
  users: ModelUserConnection;
  exportedUsers: ModelUserConnection;

  setUser: Action<UserModel, User | null>;
  setUsers: Action<UserModel, { data: ModelUserConnection }>;
  removeUser: Action<UserModel, { id: string }>;
  setExportedUsers: Action<UserModel, { data: ModelUserConnection }>;

  getUser: Thunk<UserModel, GqlQuery, Injections, StoreModel>;
  searchUsers: Thunk<UserModel, SearchUsersOpts, Injections, StoreModel>;
  exportUsers: Thunk<UserModel, SearchUsersOpts, Injections, StoreModel>;
  createUser: Thunk<UserModel, CreateUserOpts, Injections, StoreModel>;
  updateUser: Thunk<UserModel, UpdateUserOpts, Injections, StoreModel>;
  deleteUser: Thunk<UserModel, { id: string }, Injections, StoreModel>;
}

export const userModel: UserModel = {
  user: null,
  users: { items: [], total: 0 },
  exportedUsers: { items: [], total: 0 },

  setUser: action((state, payload) => {
    state.user = payload;
  }),

  setUsers: action((state, payload) => {
    const { data } = payload;
    state.users = data;
  }),

  removeUser: action((state, payload) => {
    state.users.items = state.users.items.filter(u => u.id !== payload.id);
    state.users.total = state.users.total - 1;
  }),

  setExportedUsers: action((state, payload) => {
    const { data } = payload;
    state.exportedUsers = data;
  }),

  getUser: thunk(async (actions, payload, { injections }) => {
    const { userService } = injections;
    const response = await userService.getUser(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const user = response.data.getUser;
    actions.setUser(user as User);

    return user;
  }),

  searchUsers: thunk(async (actions, payload, { injections }) => {
    const { userService } = injections;
    const response = await userService.searchUsers(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.searchUsers) {
      const data: ModelUserConnection = response.data.searchUsers;
      actions.setUsers({ data });

      return data;
    }
  }),

  exportUsers: thunk(async (actions, payload, { injections }) => {
    const { userService } = injections;
    const response = await userService.exportUsers(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.exportUsers) {
      const data: ModelUserConnection = response.data.exportUsers;
      actions.setExportedUsers({ data });

      return data;
    }
  }),

  createUser: thunk(async (_actions, payload, { injections }) => {
    const { userService } = injections;

    return userService.createUser(payload);
  }),

  updateUser: thunk(async (_actions, payload, { injections }) => {
    const { userService } = injections;

    return userService.updateUser(payload);
  }),

  deleteUser: thunk(async (_actions, payload, { injections }) => {
    const { userService } = injections;
    const response = await userService.deleteUser(payload.id);

    if (response.status === 200 && response.data.success) {
      _actions.setUser(null);
      _actions.removeUser(payload);
    }

    return response;
  }),
};
