import { Attribute } from "../../models/Attribute";
import { AttributeKey } from "../../models/AttributeKey";
import { Authorization } from "../../models/Authorization";
import {
  BackOfficeUser,
  ModelBackOfficeUserConnection,
} from "../../models/BackOfficeUser";
import { Category } from "../../models/Category";
import { Center } from "../../models/Center";
import { CenterType, ModelCenterTypeConnection } from "../../models/CenterType";
import { ClosedDay } from "../../models/ClosedDay";
import { DaySlot } from "../../models/DaySlot";
import { Event } from "../../models/Event";
import { ModelGlobalOrderConnection } from "../../models/GlobalOrder";
import { News } from "../../models/News";
import { Notification } from "../../models/Notification";
import { Option } from "../../models/Option";
import { PriceUnit } from "../../models/PriceUnit";
import {
  EnumCalendarType,
  EnumReportingGlobalEntity,
  EnumReportingView,
  Product,
} from "../../models/Product";
import { Provider } from "../../models/Provider";
import { Service } from "../../models/Service";
import { SingleProduct } from "../../models/SingleProduct";
import { User } from "../../models/User";
import { AttributesItem } from "../../utils/preprocessing";

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export enum EnumHoursRangeName {
  MORNING = "MORNING",
  AFTERNOON = "AFTERNOON",
}

export enum EnumAttributeType {
  PROVIDER = "PROVIDER",
  CENTER = "CENTER",
  SERVICE = "SERVICE",
  PRODUCT = "PRODUCT",
  USER = "USER",
  UNAVAILABILITY = "UNAVAILABILITY",
  OPERATOR = "OPERATOR",
}

export enum EnumAttributeTypology {
  BOOLEAN = "BOOLEAN",
  STRING = "STRING",
  NUMBER = "NUMBER",
  JSON = "JSON",
  URL = "URL",
  PERCENT = "PERCENT",
  DATETIME = "DATETIME",
  SELECT = "SELECT",
}

export enum EnumBackOfficeUserRole {
  SystemAdmin = "SystemAdmin",
  OperatorAdmin = "OperatorAdmin",
  CenterAdmin = "CenterAdmin",
}

export enum EnumLocale {
  fr_FR = "fr_FR",
  en_GB = "en_GB",
  en_US = "en_US",
  es_ES = "es_ES",
  de_DE = "de_DE",
  pt_PT = "pt_PT",
}

export type ModelBackOfficeUserConditionInput = {
  firstname?: ModelStringInput | null;
  lastname?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  role?: ModelEnumBackOfficeUserRoleInput | null;
  picture?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  userPoolId?: ModelStringInput | null;
  identityPoolId?: ModelStringInput | null;
  tier?: ModelStringInput | null;
  clientId?: ModelStringInput | null;
  username?: ModelStringInput | null;
  shortname?: ModelStringInput | null;
  locale?: ModelEnumLocaleInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelBackOfficeUserConditionInput | null> | null;
  or?: Array<ModelBackOfficeUserConditionInput | null> | null;
  not?: ModelBackOfficeUserConditionInput | null;
};

export type ModelEnumBackOfficeUserRoleInput = {
  eq?: EnumBackOfficeUserRole | null;
  ne?: EnumBackOfficeUserRole | null;
};

export type ModelEnumLocaleInput = {
  eq?: EnumLocale | null;
  ne?: EnumLocale | null;
};

type CreateAuthorization = {
  id?: string | null;
  entityType: EnumPermissionEntity;
  entityId: string;
  authorizationUserId: string;
};

export type CreateAuthorizationInput = {
  items: CreateAuthorization[];
};

export enum EnumPermissionEntity {
  BACK_OFFICE_USER = "BACK_OFFICE_USER",
  PRODUCT = "PRODUCT",
  CENTER = "CENTER",
  EVENT = "EVENT",
  PROVIDER = "PROVIDER",
  SERVICE = "SERVICE",
  USER = "USER",
  NEWS = "NEWS",
  CATEGORY = "CATEGORY",
  PRICE_UNIT = "PRICE_UNIT",
  CENTER_TYPE = "CENTER_TYPE",
  ENTERPRISE = "ENTERPRISE",
  BOOKING = "BOOKING",
  BOOKING_LIST = "BOOKING_LIST",
  BOOKING_SPACE_LIST = "BOOKING_SPACE_LIST",
  INCIDENT = "INCIDENT",
  VISIT = "VISIT",
  GLOBAL_ORDER = "GLOBAL_ORDER",
  EVENT_TYPE = "EVENT_TYPE",
  SPACE = "SPACE",
  CALENDAR = "CALENDAR",
  PARTICIPATION = "PARTICIPATION",
  NOTIFICATION = "NOTIFICATION",
  WEBHOOK = "WEBHOOK",
  PAYMENT_METHOD = "PAYMENT_METHOD",
  ATTRIBUTE_KEY = "ATTRIBUTE_KEY",
}

export type ModelAuthorizationConditionInput = {
  entityType?: ModelEnumPermissionEntityInput | null;
  entityId?: ModelIDInput | null;
  and?: Array<ModelAuthorizationConditionInput | null> | null;
  or?: Array<ModelAuthorizationConditionInput | null> | null;
  not?: ModelAuthorizationConditionInput | null;
};

export type ModelEnumPermissionEntityInput = {
  eq?: EnumPermissionEntity | null;
  ne?: EnumPermissionEntity | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum EnumUserRole {
  User = "User",
  Anonymous = "Anonymous",
  Manager = "Manager",
}

export type ModelUserConditionInput = {
  firstname?: ModelStringInput | null;
  lastname?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  role?: ModelEnumUserRoleInput | null;
  picture?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  userPoolId?: ModelStringInput | null;
  identityPoolId?: ModelStringInput | null;
  tier?: ModelStringInput | null;
  clientId?: ModelStringInput | null;
  username?: ModelStringInput | null;
  shortname?: ModelStringInput | null;
  locale?: ModelEnumLocaleInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelUserConditionInput | null> | null;
  or?: Array<ModelUserConditionInput | null> | null;
  not?: ModelUserConditionInput | null;
};

export type ModelEnumUserRoleInput = {
  eq?: EnumUserRole | null;
  ne?: EnumUserRole | null;
};

export enum EnumCurrency {
  EUR = "EUR",
  CREDIT = "CREDIT",
}

export enum EnumChannel {
  WEB = "WEB",
  MOBILE = "MOBILE",
  BACK_OFFICE = "BACK_OFFICE",
  GSUITE = "GSUITE",
  OUTLOOK = "OUTLOOK",
}

export enum EnumOrderStatus {
  PENDING = "PENDING",
  AWAITING_PAYMENT = "AWAITING_PAYMENT",
  PAID = "PAID",
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
  REFUND_REQUESTED = "REFUND_REQUESTED",
  REFUNDED = "REFUNDED",
}

export enum EnumInvoiceStatus {
  DRAFT = "DRAFT",
  OPEN = "OPEN",
  PAID = "PAID",
}

export enum EnumAvailabilityStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
}

export enum EnumUnavailabilityType {
  PARENT = 0,
  CHILD = 1,
}

export enum EnumProductType {
  SIMPLE = "SIMPLE",
  BUNDLE = "BUNDLE",
}

export enum EnumProductTypology {
  OFFICE = "OFFICE",
  MEETING_ROOM = "MEETING_ROOM",
  COWORKING = "COWORKING",
  PARKING = "PARKING",
  SERVICED_SPACE = "SERVICED_SPACE",
  CONSOMMABLE = "CONSOMMABLE",
  PRESTATION = "PRESTATION",
}

export enum EnumUnit {
  HALF_HOUR = "HALF_HOUR",
  HOUR = "HOUR",
  DAY = "DAY",
  HALF_DAY = "HALF_DAY",
  MONTH = "MONTH",
  UNIT = "UNIT",
}

export enum EnumServiceType {
  SPACE = "SPACE",
  SERVICE = "SERVICE",
}

export enum EnumNotificationType {
  ORDER = "ORDER",
  SPACE = "SPACE",
  SERVICE = "SERVICE",
  NEWS = "NEWS",
  EVENT = "EVENT",
  INCIDENT = "INCIDENT",
  BUILDING_VISITOR_CHECKED_IN = "BUILDING_VISITOR_CHECKED_IN",
  BUILDING_VISITOR_CHECKED_OUT = "BUILDING_VISITOR_CHECKED_OUT",
  VISIT_CHECKED_OUT = "VISIT_CHECKED_OUT",
  VISIT_CHECKED_IN = "VISIT_CHECKED_IN",
  VISIT_CANCELED = "VISIT_CANCELED",
  VISIT_VISITOR_CHECKED_IN = "VISIT_VISITOR_CHECKED_IN",
  VISIT_VISITOR_CHECKED_OUT = "VISIT_VISITOR_CHECKED_OUT",
  VISIT_VISITOR_CANCELED = "VISIT_VISITOR_CANCELED",
}

export enum EnumCategoryType {
  SERVICE = "SERVICE",
  SPACE = "SPACE",
  SERVICE_PRODUCT = "SERVICE_PRODUCT",
  SPACE_PRODUCT = "SPACE_PRODUCT",
  ATTRIBUTE = "ATTRIBUTE",
  NEWS = "NEWS",
}

export enum Permission {
  CREATE = "CREATE",
  READ = "READ",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export type ModelPermissionInput = {
  eq?: Permission | null;
  ne?: Permission | null;
};

export enum EnumLanguage {
  fr = "fr",
  en = "en",
  es = "es",
  de = "de",
  pt = "pt",
}

export type ModelEnumLanguageInput = {
  eq?: EnumLanguage | null;
  ne?: EnumLanguage | null;
};

export type CreateCenterInput = {
  id?: string | null;
  name: string;
  description?: string | null;
  address: string;
  zipCode: string;
  city: string;
  country: string;
  location?: GPSInput | null;
  pictures?: Array<string | null> | null;
  enabled: boolean;
  markForDelete: boolean;
  phone?: string | null;
  email?: string | null;
  metadata?: string | null;
  centerOperatorId: string;
  centerTypeId?: string | null;
};

export type GPSInput = {
  lat?: number | null;
  lon?: number | null;
};

export type ModelCenterConditionInput = {
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  address?: ModelStringInput | null;
  zipCode?: ModelStringInput | null;
  city?: ModelStringInput | null;
  country?: ModelStringInput | null;
  pictures?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  phone?: ModelStringInput | null;
  email?: ModelStringInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelCenterConditionInput | null> | null;
  or?: Array<ModelCenterConditionInput | null> | null;
  not?: ModelCenterConditionInput | null;
};

export type UpdateCenterInput = {
  id: string;
  name?: string | null;
  description?: string | null;
  address?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
  location?: GPSInput | null;
  pictures?: Array<string | null> | null;
  enabled?: boolean | null;
  markForDelete?: boolean | null;
  phone?: string | null;
  email?: string | null;
  metadata?: string | null;
  centerOperatorId?: string | null;
  centerTypeId?: string | null;
};

export type UpdateCenterStateInput = {
  id: string;
  enabled: boolean;
};

export type CreateCenterTypeInput = {
  name?: string | null;
  type: string;
  enabled: boolean;
  markForDelete: boolean;
};

export type ModelCenterTypeConditionInput = {
  name?: ModelStringInput | null;
  type?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  and?: Array<ModelCenterTypeConditionInput | null> | null;
  or?: Array<ModelCenterTypeConditionInput | null> | null;
  not?: ModelCenterTypeConditionInput | null;
};

export type UpdateCenterTypeInput = {
  id: string;
  name?: string | null;
  type?: string | null;
  enabled?: boolean | null;
  markForDelete?: boolean | null;
};

export type CreateServiceInput = {
  id?: string | null;
  type: EnumServiceType;
  name: string;
  description?: string | null;
  pictures?: Array<string | null> | null;
  authorized?: boolean | null;
  buyable: boolean;
  enabled: boolean;
  markForDelete: boolean;
  metadata?: string | null;
  centerServicesId?: string | null;
  tenantServiceServicesId?: string | null;
  serviceOperatorId: string;
  serviceCategoryId: string;
};

export type ModelServiceConditionInput = {
  type?: ModelEnumServiceTypeInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  pictures?: ModelStringInput | null;
  authorized?: ModelBooleanInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelServiceConditionInput | null> | null;
  or?: Array<ModelServiceConditionInput | null> | null;
  not?: ModelServiceConditionInput | null;
};

export type ModelEnumServiceTypeInput = {
  eq?: EnumServiceType | null;
  ne?: EnumServiceType | null;
};

export type UpdateServiceInput = {
  id: string;
  centerServicesId: string;
  type?: EnumServiceType | null;
  name?: string | null;
  description?: string | null;
  pictures?: Array<string | null> | null;
  authorized?: boolean | null;
  enabled?: boolean | null;
  markForDelete?: boolean | null;
  metadata?: string | null;
  tenantServiceServicesId?: string | null;
  serviceOperatorId?: string | null;
  serviceCategoryId?: string | null;
};

export type CreateCategoryInput = {
  id?: string | null;
  type: EnumCategoryType;
  name: string;
  description?: string | null;
  picture?: string | null;
  level?: number | null;
  position: number;
  enabled: boolean;
  markForDelete: boolean;
  metadata?: string | null;
  categoryCenterId?: string | null;
  categoryParentId?: string | null;
};

export type ModelCategoryConditionInput = {
  type?: ModelEnumCategoryTypeInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  picture?: ModelStringInput | null;
  level?: ModelIntInput | null;
  position?: ModelIntInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelCategoryConditionInput | null> | null;
  or?: Array<ModelCategoryConditionInput | null> | null;
  not?: ModelCategoryConditionInput | null;
};

export type ModelEnumCategoryTypeInput = {
  eq?: EnumCategoryType | null;
  ne?: EnumCategoryType | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateCategoryInput = {
  id: string;
  type?: EnumCategoryType | null;
  name?: string | null;
  description?: string | null;
  picture?: string | null;
  level?: number | null;
  position?: number | null;
  enabled?: boolean | null;
  markForDelete?: boolean | null;
  metadata?: string | null;
  categoryParentId?: string | null;
  categoryCenterId?: string | null;
};

export type CreateProviderInput = {
  name: string;
  description?: string | null;
  address: string;
  zipCode: string;
  city: string;
  country: string;
  phone?: string | null;
  email?: string | null;
  pictures?: Array<string | null> | null;
  enabled: boolean;
  receivingEmails: boolean;
  attributes: AttributesItem[];
};

export type UpdateProviderInput = {
  id: string;
  name?: string | null;
  description?: string | null;
  address?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
  phone?: string | null;
  email?: string | null;
  pictures?: Array<string | null> | null;
  enabled?: boolean | null;
  receivingEmails?: boolean;
  attributes: AttributesItem[];
};

export type CreateProductInput = {
  id?: string | null;
  type: EnumProductType;
  sku: string;
  name: string;
  typology: EnumProductTypology;
  description?: string | null;
  pictures?: Array<string | null> | null;
  vat: number;
  capacity?: number | null;
  stock: number;
  authorized?: boolean | null;
  enabled: boolean;
  markForDelete: boolean;
  flexible: boolean;
  checkStock: boolean;
  metadata?: string | null;
  centerProductsId?: string | null;
  serviceProductsId?: string | null;
  productOperatorId: string;
  productCenterId: string;
  productCategoryId?: string | null;
  productProviderId: string;
};

export type ModelProductConditionInput = {
  type?: ModelEnumProductTypeInput | null;
  sku?: ModelStringInput | null;
  name?: ModelStringInput | null;
  typology?: ModelEnumProductTypologyInput | null;
  description?: ModelStringInput | null;
  pictures?: ModelStringInput | null;
  vat?: ModelFloatInput | null;
  capacity?: ModelIntInput | null;
  stock?: ModelIntInput | null;
  authorized?: ModelBooleanInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  flexible?: ModelBooleanInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelProductConditionInput | null> | null;
  or?: Array<ModelProductConditionInput | null> | null;
  not?: ModelProductConditionInput | null;
};

export type ModelEnumProductTypeInput = {
  eq?: EnumProductType | null;
  ne?: EnumProductType | null;
};

export type ModelEnumProductTypologyInput = {
  eq?: EnumProductTypology | null;
  ne?: EnumProductTypology | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateProductInput = {
  id: string;
  type?: EnumProductType | null;
  sku?: string | null;
  name?: string | null;
  typology?: EnumProductTypology | null;
  description?: string | null;
  pictures?: Array<string | null> | null;
  vat?: number | null;
  capacity?: number | null;
  stock?: number | null;
  authorized?: boolean | null;
  enabled?: boolean | null;
  markForDelete?: boolean | null;
  flexible?: boolean | null;
  checkStock?: boolean | null;
  metadata?: string | null;
  centerProductsId?: string | null;
  serviceProductsId?: string | null;
  productOperatorId?: string | null;
  productCenterId?: string | null;
  productCategoryId?: string | null;
  productProviderId?: string | null;
};

export type CreateOptionInput = {
  id?: string | null;
  productOptionsId?: string | null;
  optionCenterId: string;
  optionProductId: string;
};

export type ModelOptionConditionInput = {
  and?: Array<ModelOptionConditionInput | null> | null;
  or?: Array<ModelOptionConditionInput | null> | null;
  not?: ModelOptionConditionInput | null;
};

export type DeleteOptionInput = {
  id?: string | null;
};

export type CreateSingleProductInput = {
  id?: string | null;
  productProductsId?: string | null;
  singleProductOperatorId?: string | null;
  singleProductCenterId: string;
  singleProductProductId: string;
};

export type ModelSingleProductConditionInput = {
  and?: Array<ModelSingleProductConditionInput | null> | null;
  or?: Array<ModelSingleProductConditionInput | null> | null;
  not?: ModelSingleProductConditionInput | null;
};

export type DeleteSingleProductInput = {
  id?: string | null;
};

export enum EnumRatingEntity {
  CENTER = "CENTER",
  PROVIDER = "PROVIDER",
  TENANT = "TENANT",
  SERVICE = "SERVICE",
  PRODUCT = "PRODUCT",
  NEWS = "NEWS",
  EVENT = "EVENT",
}

export type ModelAttributeKeyConditionInput = {
  name?: ModelStringInput | null;
  type?: ModelEnumAttributeTypeInput | null;
  description?: ModelStringInput | null;
  picture?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  position?: ModelIntInput | null;
  typology?: ModelEnumAttributeTypologyInput | null;
  system?: ModelBooleanInput | null;
  required?: ModelBooleanInput | null;
  roles?: ModelEnumBackOfficeUserRoleListInput | null;
  unit?: ModelStringInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelAttributeKeyConditionInput | null> | null;
  or?: Array<ModelAttributeKeyConditionInput | null> | null;
  not?: ModelAttributeKeyConditionInput | null;
};

export type ModelEnumAttributeTypeInput = {
  eq?: EnumAttributeType | null;
  ne?: EnumAttributeType | null;
};

export type ModelEnumAttributeTypologyInput = {
  eq?: EnumAttributeTypology | null;
  ne?: EnumAttributeTypology | null;
};

export type ModelEnumBackOfficeUserRoleListInput = {
  eq?: Array<EnumBackOfficeUserRole | null> | null;
  ne?: Array<EnumBackOfficeUserRole | null> | null;
  contains?: EnumBackOfficeUserRole | null;
  notContains?: EnumBackOfficeUserRole | null;
};

export type CreateAttribute = {
  id?: string | null;
  booleanValue?: boolean | null;
  stringValue?: string | null;
  value: string;
  jsonValue?: string | null;
  numberValue?: number | null;
  urlValue?: string | null;
  dateValue?: string | null;
  selectValue?: string | null;
  enabled: boolean;
  markForDelete: boolean;
  operatorAttributesId?: string | null;
  userAttributesId?: string | null;
  centerAttributesId?: string | null;
  tenantAttributesId?: string | null;
  serviceAttributesId?: string | null;
  providerAttributesId?: string | null;
  productAttributesId?: string | null;
  attributeKeyId: string;
  unavailabilityAttributesId?: string | null;
};

export type CreateAttributeInput = {
  items: CreateAttribute[];
};

export type ModelAttributeConditionInput = {
  booleanValue?: ModelBooleanInput | null;
  stringValue?: ModelStringInput | null;
  value?: ModelStringInput | null;
  jsonValue?: ModelStringInput | null;
  numberValue?: ModelFloatInput | null;
  urlValue?: ModelStringInput | null;
  dateValue?: ModelStringInput | null;
  selectValue?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  and?: Array<ModelAttributeConditionInput | null> | null;
  or?: Array<ModelAttributeConditionInput | null> | null;
  not?: ModelAttributeConditionInput | null;
};

export type UpdateAttributeKeyInput = {
  id: string;
  type?: string | null;
  typology?: string | null;
  name?: string | null;
  attributeKeyCategoryId?: string | null;
  description?: string | null;
  metadata?: string | null;
  enabled?: boolean | null;
  system?: boolean | null;
  required?: boolean | null;
  markForDelete?: boolean | null;
  updatedAt?: string | null;
  createdAt?: string | null;
};

export type UpdateAttribute = {
  id: string;
  booleanValue?: boolean | null;
  stringValue?: string | null;
  value?: string | null;
  jsonValue?: string | null;
  numberValue?: number | null;
  urlValue?: string | null;
  dateValue?: string | null;
  selectValue?: string | null;
  enabled?: boolean | null;
  markForDelete?: boolean | null;
  operatorAttributesId?: string | null;
  userAttributesId?: string | null;
  centerAttributesId?: string | null;
  tenantAttributesId?: string | null;
  serviceAttributesId?: string | null;
  providerAttributesId?: string | null;
  productAttributesId?: string | null;
  attributeOperatorId?: string | null;
  attributeKeyId?: string | null;
  unavailabilityAttributesId?: string | null;
};

export type UpdateAttributeInput = {
  items: UpdateAttribute[];
};

export type DeleteAttributeInput = {
  id?: string | null;
};

export type ModelUnavailabilityConditionInput = {
  type?: ModelIntInput | null;
  start?: ModelStringInput | null;
  end?: ModelStringInput | null;
  status?: ModelEnumAvailabilityStatusInput | null;
  relatedUnavailabilityId?: ModelIDInput | null;
  locale?: ModelEnumLocaleInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelUnavailabilityConditionInput | null> | null;
  or?: Array<ModelUnavailabilityConditionInput | null> | null;
  not?: ModelUnavailabilityConditionInput | null;
};

export type ModelEnumAvailabilityStatusInput = {
  eq?: EnumAvailabilityStatus | null;
  ne?: EnumAvailabilityStatus | null;
};

export enum EnumBookingReason {
  ONSITE = "ONSITE",
  MAINTENANCE = "MAINTENANCE",
  OTHER = "OTHER",
}

export type ModelEnumCurrencyInput = {
  eq?: EnumCurrency | null;
  ne?: EnumCurrency | null;
};

export type ModelEnumChannelInput = {
  eq?: EnumChannel | null;
  ne?: EnumChannel | null;
};

export type ModelEnumOrderStatusInput = {
  eq?: EnumOrderStatus | null;
  ne?: EnumOrderStatus | null;
};

export type ModelOrderConditionInput = {
  ormOrder?: ModelStringInput | null;
  price?: ModelFloatInput | null;
  totalTax?: ModelFloatInput | null;
  totalAdjustment?: ModelFloatInput | null;
  transactionFeePercent?: ModelFloatInput | null;
  currency?: ModelEnumCurrencyInput | null;
  channel?: ModelEnumChannelInput | null;
  timeplaced?: ModelStringInput | null;
  deliveryDate?: ModelStringInput | null;
  status?: ModelEnumOrderStatusInput | null;
  locale?: ModelEnumLocaleInput | null;
  metadata?: ModelStringInput | null;
  reason?: ModelEnumBookingReasonInput | null;
  comment?: ModelStringInput | null;
  and?: Array<ModelOrderConditionInput | null> | null;
  or?: Array<ModelOrderConditionInput | null> | null;
  not?: ModelOrderConditionInput | null;
};

export type ModelEnumBookingReasonInput = {
  eq?: EnumBookingReason | null;
  ne?: EnumBookingReason | null;
};

export type ModelEnumInvoiceStatusInput = {
  eq?: EnumInvoiceStatus | null;
  ne?: EnumInvoiceStatus | null;
};

export type ModelEnumUnitInput = {
  eq?: EnumUnit | null;
  ne?: EnumUnit | null;
};

export type ModelEnumHoursRangeNameInput = {
  eq?: EnumHoursRangeName | null;
  ne?: EnumHoursRangeName | null;
};

export type CreateDaySlotInput = {
  id?: string | null;
  name?: string | null;
  day: string;
  dayBreak?: string | null;
  markForDelete: boolean;
  centerDaySlotsId?: string | null;
  tenantDaySlotsId?: string | null;
  productDaySlotsId?: string | null;
  daySlotOperatorId: string;
};

export type ModelDaySlotConditionInput = {
  name?: ModelStringInput | null;
  day?: ModelStringInput | null;
  dayBreak?: ModelStringInput | null;
  markForDelete?: ModelBooleanInput | null;
  and?: Array<ModelDaySlotConditionInput | null> | null;
  or?: Array<ModelDaySlotConditionInput | null> | null;
  not?: ModelDaySlotConditionInput | null;
};

export type UpdateDaySlotInput = {
  id: string;
  name?: string | null;
  day?: string | null;
  dayBreak?: string | null;
  markForDelete?: boolean | null;
  centerDaySlotsId?: string | null;
  tenantDaySlotsId?: string | null;
  productDaySlotsId?: string | null;
  daySlotOperatorId?: string | null;
};

export type BaseDeleteInput = {
  id: string;
};

export type DeleteManyInput = {
  items: BaseDeleteInput[];
};

export type CreateClosedDay = {
  id?: string | null;
  start: string;
  end: string;
  markForDelete: boolean;
  centerClosedDaysId?: string | null;
  tenantClosedDaysId?: string | null;
  productClosedDaysId?: string | null;
};

export type CreateClosedDayInput = {
  items: CreateClosedDay[];
};

export type ModelClosedDayConditionInput = {
  start?: ModelStringInput | null;
  end?: ModelStringInput | null;
  markForDelete?: ModelBooleanInput | null;
  and?: Array<ModelClosedDayConditionInput | null> | null;
  or?: Array<ModelClosedDayConditionInput | null> | null;
  not?: ModelClosedDayConditionInput | null;
};

export type CreatePriceUnitInput = {
  unit: EnumUnit;
  currency: EnumCurrency;
  increment: number;
  price: number;
  enabled: boolean;
  buyableUnit: number;
  buyableDescription?: string;
  flexible: boolean;
  minApprovalMinutes?: number | null;
  minCancelMinutes?: number | null;
  productPriceUnitsId?: string | null;
  priceUnitVariationId: string;
};

export type ModelPriceUnitConditionInput = {
  description?: ModelStringInput | null;
  unit?: ModelEnumUnitInput | null;
  currency?: ModelEnumCurrencyInput | null;
  increment?: ModelIntInput | null;
  price?: ModelFloatInput | null;
  minQuantity?: ModelIntInput | null;
  maxQuantity?: ModelIntInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  buyableUnit?: ModelIntInput | null;
  buyableDescription?: ModelStringInput;
  flexible?: ModelBooleanInput | null;
  minApprovalMinutes?: ModelIntInput | null;
  minCancelMinutes?: ModelIntInput | null;
  metadata?: ModelStringInput | null;
  priceUnitVariationId?: ModelIDInput | null;
  and?: Array<ModelPriceUnitConditionInput | null> | null;
  or?: Array<ModelPriceUnitConditionInput | null> | null;
  not?: ModelPriceUnitConditionInput | null;
};

export type UpdatePriceUnitInput = {
  id: string;
  description?: string | null;
  unit?: EnumUnit | null;
  currency?: EnumCurrency | null;
  increment?: number | null;
  price?: number | null;
  minQuantity?: number | null;
  maxQuantity?: number | null;
  enabled?: boolean | null;
  markForDelete?: boolean | null;
  buyableUnit?: number | null;
  buyableDescription?: string;
  flexible?: boolean | null;
  minApprovalMinutes?: number | null;
  minCancelMinutes?: number | null;
  metadata?: string | null;
  productPriceUnitsId?: string | null;
  priceUnitOperatorId?: string | null;
  priceUnitVariationId?: string | null;
};

export type DeletePriceUnitInput = {
  id?: string | null;
};

export type CreatePriceUnitVariationInput = {
  id?: string | null;
  key: string;
  name: string;
  enabled: boolean;
  markForDelete: boolean;
  metadata?: string | null;
};

export type ModelPriceUnitVariationConditionInput = {
  key?: ModelStringInput | null;
  name?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelPriceUnitVariationConditionInput | null> | null;
  or?: Array<ModelPriceUnitVariationConditionInput | null> | null;
  not?: ModelPriceUnitVariationConditionInput | null;
};

export type UpdatePriceUnitVariationInput = {
  id: string;
  key?: string | null;
  name?: string | null;
  enabled?: boolean | null;
  markForDelete?: boolean | null;
  metadata?: string | null;
};

export type CreateEventInput = {
  title: string;
  description: string;
  picture?: string | null;
  start: string;
  end: string;
  publicationDate: string;
  unpublicationDate: string;
  enabled: boolean;
  centerEventsId: string;
  eventTypeId: string;
};

export type ModelEventConditionInput = {
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  picture?: ModelStringInput | null;
  start?: ModelStringInput | null;
  end?: ModelStringInput | null;
  authorized?: ModelBooleanInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelEventConditionInput | null> | null;
  or?: Array<ModelEventConditionInput | null> | null;
  not?: ModelEventConditionInput | null;
};

export enum EnumParticipation {
  YES = "YES",
  MAYBE = "MAYBE",
  NO = "NO",
}

export type UpdateEventInput = {
  id: string;
  title?: string | null;
  description?: string | null;
  picture?: string | null;
  start?: string | null;
  end?: string | null;
  publicationDate?: string | null;
  unpublicationDate?: string | null;
  enabled?: boolean | null;
  centerEventsId?: string | null;
  eventTypeId?: string | null;
};

export type CreateEventTypeInput = {
  name: string;
  type: string;
  enabled: boolean;
  centerEventTypesId: string;
};

export type ModelEventTypeConditionInput = {
  name?: ModelStringInput | null;
  type?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  and?: Array<ModelEventTypeConditionInput | null> | null;
  or?: Array<ModelEventTypeConditionInput | null> | null;
  not?: ModelEventTypeConditionInput | null;
};

export type UpdateEventTypeInput = {
  id: string;
  name?: string | null;
  type?: string | null;
  enabled?: boolean | null;
};

export type ModelParticipationConditionInput = {
  value?: ModelEnumParticipationInput | null;
  and?: Array<ModelParticipationConditionInput | null> | null;
  or?: Array<ModelParticipationConditionInput | null> | null;
  not?: ModelParticipationConditionInput | null;
};

export type ModelEnumParticipationInput = {
  eq?: EnumParticipation | null;
  ne?: EnumParticipation | null;
};

export type CreateNewsInput = {
  id?: string | null;
  title: string;
  description: string;
  picture?: string | null;
  publicationDate?: string | null;
  unpublicationDate?: string | null;
  enabled: boolean;
  metadata?: string | null;
  centerNewsId?: string | null;
  tenantNewsId?: string | null;
  newsCategoryId?: string | null;
};

export type ModelNewsConditionInput = {
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  picture?: ModelStringInput | null;
  publicationDate?: ModelStringInput | null;
  unpublicationDate?: ModelStringInput | null;
  authorized?: ModelBooleanInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelNewsConditionInput | null> | null;
  or?: Array<ModelNewsConditionInput | null> | null;
  not?: ModelNewsConditionInput | null;
};

export type UpdateNewsInput = {
  id: string;
  title?: string | null;
  description?: string | null;
  picture?: string | null;
  publicationDate?: string | null;
  unpublicationDate?: string | null;
  authorized?: boolean | null;
  enabled?: boolean | null;
  markForDelete?: boolean | null;
  metadata?: string | null;
  centerNewsId?: string | null;
  tenantNewsId?: string | null;
  newsOperatorId?: string | null;
  newsAuthorId?: string | null;
  newsCategoryId?: string | null;
};

export type ModelNotificationConditionInput = {
  type?: ModelEnumNotificationTypeInput | null;
  entity?: ModelStringInput | null;
  description?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  creationDate?: ModelStringInput | null;
  viewDate?: ModelStringInput | null;
  locale?: ModelEnumLocaleInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelNotificationConditionInput | null> | null;
  or?: Array<ModelNotificationConditionInput | null> | null;
  not?: ModelNotificationConditionInput | null;
};

export type ModelEnumNotificationTypeInput = {
  eq?: EnumNotificationType | null;
  ne?: EnumNotificationType | null;
};

export type UpdateNotificationInput = {
  id: string;
  type?: EnumNotificationType | null;
  entity?: string | null;
  description?: string | null;
  enabled?: boolean | null;
  markForDelete?: boolean | null;
  creationDate?: string | null;
  viewDate?: string | null;
  locale?: EnumLocale | null;
  metadata?: string | null;
  notificationOperatorId?: string | null;
  notificationCenterId?: string | null;
  notificationViewerId?: string | null;
};

export type ModelBackOfficeUserFilterInput = {
  id?: ModelIDInput | null;
  firstname?: ModelStringInput | null;
  lastname?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  role?: ModelEnumBackOfficeUserRoleInput | null;
  picture?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  userPoolId?: ModelStringInput | null;
  identityPoolId?: ModelStringInput | null;
  tier?: ModelStringInput | null;
  clientId?: ModelStringInput | null;
  username?: ModelStringInput | null;
  shortname?: ModelStringInput | null;
  locale?: ModelEnumLocaleInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelBackOfficeUserFilterInput | null> | null;
  or?: Array<ModelBackOfficeUserFilterInput | null> | null;
  not?: ModelBackOfficeUserFilterInput | null;
};

export type ModelAuthorizationFilterInput = {
  id?: ModelIDInput | null;
  entityType?: ModelEnumPermissionEntityInput | null;
  entityId?: ModelIDInput | null;
  and?: Array<ModelAuthorizationFilterInput | null> | null;
  or?: Array<ModelAuthorizationFilterInput | null> | null;
  not?: ModelAuthorizationFilterInput | null;
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null;
  firstname?: ModelStringInput | null;
  lastname?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  role?: ModelEnumUserRoleInput | null;
  picture?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  userPoolId?: ModelStringInput | null;
  identityPoolId?: ModelStringInput | null;
  tier?: ModelStringInput | null;
  clientId?: ModelStringInput | null;
  username?: ModelStringInput | null;
  shortname?: ModelStringInput | null;
  locale?: ModelEnumLocaleInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelUserFilterInput | null> | null;
  or?: Array<ModelUserFilterInput | null> | null;
  not?: ModelUserFilterInput | null;
};

export type ModelCenterFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  address?: ModelStringInput | null;
  zipCode?: ModelStringInput | null;
  city?: ModelStringInput | null;
  country?: ModelStringInput | null;
  pictures?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  phone?: ModelStringInput | null;
  email?: ModelStringInput | null;
  centerOperatorId?: ModelIDInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelCenterFilterInput | null> | null;
  or?: Array<ModelCenterFilterInput | null> | null;
  not?: ModelCenterFilterInput | null;
};

export type ModelCenterTypeFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  type?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  and?: Array<ModelCenterTypeFilterInput | null> | null;
  or?: Array<ModelCenterTypeFilterInput | null> | null;
  not?: ModelCenterTypeFilterInput | null;
};

export type ModelServiceFilterInput = {
  id?: ModelIDInput | null;
  type?: ModelEnumServiceTypeInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  pictures?: ModelStringInput | null;
  centerServicesId?: ModelIDInput | null;
  tenantServiceServicesId?: ModelIDInput | null;
  serviceOperatorId?: ModelIDInput | null;
  serviceCategoryId?: ModelIDInput | null;
  authorized?: ModelBooleanInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelServiceFilterInput | null> | null;
  or?: Array<ModelServiceFilterInput | null> | null;
  not?: ModelServiceFilterInput | null;
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null;
  type?: ModelEnumCategoryTypeInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  picture?: ModelStringInput | null;
  level?: ModelIntInput | null;
  position?: ModelIntInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  metadata?: ModelStringInput | null;
  categoryOperatorId?: ModelIDInput | null;
  categoryCenterId?: ModelIDInput | null;
  categoryParentId?: ModelIDInput | null;
  and?: Array<ModelCategoryFilterInput | null> | null;
  or?: Array<ModelCategoryFilterInput | null> | null;
  not?: ModelCategoryFilterInput | null;
};

export type ModelProviderFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  address?: ModelStringInput | null;
  zipCode?: ModelStringInput | null;
  city?: ModelStringInput | null;
  country?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  email?: ModelStringInput | null;
  pictures?: ModelStringInput | null;
  authorized?: ModelBooleanInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  metadata?: ModelStringInput | null;
  centerProvidersId?: ModelIDInput | null;
  providerOperatorId?: ModelIDInput | null;
  and?: Array<ModelProviderFilterInput | null> | null;
  or?: Array<ModelProviderFilterInput | null> | null;
  not?: ModelProviderFilterInput | null;
};

export type ModelProductFilterInput = {
  id?: ModelIDInput | null;
  type?: ModelEnumProductTypeInput | null;
  sku?: ModelStringInput | null;
  name?: ModelStringInput | null;
  typology?: ModelEnumProductTypologyInput | null;
  description?: ModelStringInput | null;
  pictures?: ModelStringInput | null;
  vat?: ModelFloatInput | null;
  capacity?: ModelIntInput | null;
  stock?: ModelIntInput | null;
  authorized?: ModelBooleanInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  flexible?: ModelBooleanInput | null;
  metadata?: ModelStringInput | null;
  centerProductsId?: ModelIDInput | null;
  serviceProductsId?: ModelIDInput | null;
  productOperatorId?: ModelIDInput | null;
  productCenterId?: ModelIDInput | null;
  productCategoryId?: ModelIDInput | null;
  productProviderId?: ModelIDInput | null;
  and?: Array<ModelProductFilterInput | null> | null;
  or?: Array<ModelProductFilterInput | null> | null;
  not?: ModelProductFilterInput | null;
};

export type SearchableOptionFilterInput = {
  id?: ModelIDInput | null;
  productOptionsId?: ModelIDInput | null;
  and?: Array<SearchableOptionFilterInput | null> | null;
  or?: Array<SearchableOptionFilterInput | null> | null;
  not?: SearchableOptionFilterInput | null;
};

export type ModelSingleProductFilterInput = {
  id?: ModelIDInput | null;
  and?: Array<ModelSingleProductFilterInput | null> | null;
  or?: Array<ModelSingleProductFilterInput | null> | null;
  not?: ModelSingleProductFilterInput | null;
};

export type ModelAttributeKeyFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  type?: ModelEnumAttributeTypeInput | null;
  description?: ModelStringInput | null;
  picture?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  position?: ModelIntInput | null;
  typology?: ModelEnumAttributeTypologyInput | null;
  system?: ModelBooleanInput | null;
  required?: ModelBooleanInput | null;
  roles?: ModelEnumBackOfficeUserRoleListInput | null;
  unit?: ModelStringInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelAttributeKeyFilterInput | null> | null;
  or?: Array<ModelAttributeKeyFilterInput | null> | null;
  not?: ModelAttributeKeyFilterInput | null;
};

export type ModelAttributeFilterInput = {
  id?: ModelIDInput | null;
  booleanValue?: ModelBooleanInput | null;
  stringValue?: ModelStringInput | null;
  value?: ModelStringInput | null;
  jsonValue?: ModelStringInput | null;
  numberValue?: ModelFloatInput | null;
  urlValue?: ModelStringInput | null;
  dateValue?: ModelStringInput | null;
  selectValue?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  productAttributesId?: ModelIDInput | null;
  userAttributesId?: ModelIDInput | null;
  centerAttributesId?: ModelIDInput | null;
  tenantAttributesId?: ModelIDInput | null;
  serviceAttributesId?: ModelIDInput | null;
  attributeOperatorId?: ModelIDInput | null;
  and?: Array<ModelAttributeFilterInput | null> | null;
  or?: Array<ModelAttributeFilterInput | null> | null;
  not?: ModelAttributeFilterInput | null;
};

export type ModelUnavailabilityFilterInput = {
  id?: ModelIDInput | null;
  type?: ModelIntInput | null;
  start?: ModelStringInput | null;
  end?: ModelStringInput | null;
  status?: ModelEnumAvailabilityStatusInput | null;
  relatedUnavailabilityId?: ModelIDInput | null;
  locale?: ModelEnumLocaleInput | null;
  metadata?: ModelStringInput | null;
  unavailabilityOperatorId?: ModelIDInput | null;
  unavailabilityProductId?: ModelIDInput | null;
  unavailabilityUserId?: ModelIDInput | null;
  unavailabilityCenterId?: ModelIDInput | null;
  unavailabilityTenantId?: ModelIDInput | null;
  and?: Array<ModelUnavailabilityFilterInput | null> | null;
  or?: Array<ModelUnavailabilityFilterInput | null> | null;
  not?: ModelUnavailabilityFilterInput | null;
};

export type ModelOrderFilterInput = {
  id?: ModelIDInput | null;
  ormOrder?: ModelStringInput | null;
  price?: ModelFloatInput | null;
  totalTax?: ModelFloatInput | null;
  totalAdjustment?: ModelFloatInput | null;
  currency?: ModelEnumCurrencyInput | null;
  transactionFeePercent?: ModelFloatInput | null;
  channel?: ModelEnumChannelInput | null;
  timeplaced?: ModelStringInput | null;
  deliveryDate?: ModelStringInput | null;
  status?: ModelEnumOrderStatusInput | null;
  locale?: ModelEnumLocaleInput | null;
  metadata?: ModelStringInput | null;
  reason?: ModelEnumBookingReasonInput | null;
  comment?: ModelStringInput | null;
  globalOrderOrdersId?: ModelIDInput | null;
  orderOperatorId?: ModelIDInput | null;
  orderUserId?: ModelIDInput | null;
  orderCenterId?: ModelIDInput | null;
  orderTenantId?: ModelIDInput | null;
  orderProviderId?: ModelIDInput | null;
  orderPaymentId?: ModelIDInput | null;
  orderOrdererId?: ModelIDInput | null;
  and?: Array<ModelOrderFilterInput | null> | null;
  or?: Array<ModelOrderFilterInput | null> | null;
  not?: ModelOrderFilterInput | null;
};

export type ModelDaySlotFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  day?: ModelStringInput | null;
  dayBreak?: ModelStringInput | null;
  markForDelete?: ModelBooleanInput | null;
  centerDaySlotsId?: ModelIDInput | null;
  tenantDaySlotsId?: ModelIDInput | null;
  productDaySlotsId?: ModelIDInput | null;
  daySlotOperatorId?: ModelIDInput | null;
  and?: Array<ModelDaySlotFilterInput | null> | null;
  or?: Array<ModelDaySlotFilterInput | null> | null;
  not?: ModelDaySlotFilterInput | null;
};

export type ModelHoursRangeFilterInput = {
  id?: ModelIDInput | null;
  start?: ModelStringInput | null;
  end?: ModelStringInput | null;
  name?: ModelEnumHoursRangeNameInput | null;
  markForDelete?: ModelBooleanInput | null;
  daySlotHoursRangeId?: ModelIDInput | null;
  hoursRangeOperatorId?: ModelIDInput | null;
  and?: Array<ModelHoursRangeFilterInput | null> | null;
  or?: Array<ModelHoursRangeFilterInput | null> | null;
  not?: ModelHoursRangeFilterInput | null;
};

export type ModelClosedDayFilterInput = {
  id?: ModelIDInput | null;
  start?: ModelStringInput | null;
  end?: ModelStringInput | null;
  centerClosedDaysId?: ModelIDInput | null;
  tenantClosedDaysId?: ModelIDInput | null;
  productClosedDaysId?: ModelIDInput | null;
  markForDelete?: ModelBooleanInput | null;
  and?: Array<ModelClosedDayFilterInput | null> | null;
  or?: Array<ModelClosedDayFilterInput | null> | null;
  not?: ModelClosedDayFilterInput | null;
};

export type ModelPriceUnitFilterInput = {
  id?: ModelIDInput | null;
  description?: ModelStringInput | null;
  unit?: ModelEnumUnitInput | null;
  currency?: ModelEnumCurrencyInput | null;
  increment?: ModelIntInput | null;
  price?: ModelFloatInput | null;
  minQuantity?: ModelIntInput | null;
  maxQuantity?: ModelIntInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  buyableUnit?: ModelIntInput | null;
  buyableDescription?: ModelStringInput;
  flexible?: ModelBooleanInput | null;
  minApprovalMinutes?: ModelIntInput | null;
  minCancelMinutes?: ModelIntInput | null;
  metadata?: ModelStringInput | null;
  productPriceUnitsId?: ModelIDInput | null;
  priceUnitOperatorId?: ModelIDInput | null;
  priceUnitVariationId?: ModelIDInput | null;
  and?: Array<ModelPriceUnitFilterInput | null> | null;
  or?: Array<ModelPriceUnitFilterInput | null> | null;
  not?: ModelPriceUnitFilterInput | null;
};

export type ModelPriceUnitVariationFilterInput = {
  id?: ModelIDInput | null;
  key?: ModelStringInput | null;
  name?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelPriceUnitVariationFilterInput | null> | null;
  or?: Array<ModelPriceUnitVariationFilterInput | null> | null;
  not?: ModelPriceUnitVariationFilterInput | null;
};

export type ModelEventFilterInput = {
  id?: ModelIDInput | null;
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  picture?: ModelStringInput | null;
  start?: ModelStringInput | null;
  end?: ModelStringInput | null;
  authorized?: ModelBooleanInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  metadata?: ModelStringInput | null;
  centerEventsId?: ModelIDInput | null;
  tenantEventsId?: ModelIDInput | null;
  eventOperatorId?: ModelIDInput | null;
  eventAuthorId?: ModelIDInput | null;
  eventTypeId?: ModelIDInput | null;
  and?: Array<ModelEventFilterInput | null> | null;
  or?: Array<ModelEventFilterInput | null> | null;
  not?: ModelEventFilterInput | null;
};

export type SearchableEventTypeFilterInput = {
  markForDelete?: ModelBooleanInput | null;
  centerEventTypesId?: ModelIDInput | null;
  and?: Array<SearchableEventTypeFilterInput | null> | null;
  or?: Array<SearchableEventTypeFilterInput | null> | null;
  not?: SearchableEventTypeFilterInput | null;
};

export type ModelParticipationFilterInput = {
  id?: ModelIDInput | null;
  value?: ModelEnumParticipationInput | null;
  eventParticipationsId?: ModelIDInput | null;
  participationOperatorId?: ModelIDInput | null;
  participationUserId?: ModelIDInput | null;
  and?: Array<ModelParticipationFilterInput | null> | null;
  or?: Array<ModelParticipationFilterInput | null> | null;
  not?: ModelParticipationFilterInput | null;
};

export type ModelNewsFilterInput = {
  id?: ModelIDInput | null;
  title?: ModelStringInput | null;
  description?: ModelStringInput | null;
  picture?: ModelStringInput | null;
  publicationDate?: ModelStringInput | null;
  unpublicationDate?: ModelStringInput | null;
  authorized?: ModelBooleanInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  metadata?: ModelStringInput | null;
  centerNewsId?: ModelIDInput | null;
  tenantNewsId?: ModelIDInput | null;
  newsOperatorId?: ModelIDInput | null;
  newsAuthorId?: ModelIDInput | null;
  and?: Array<ModelNewsFilterInput | null> | null;
  or?: Array<ModelNewsFilterInput | null> | null;
  not?: ModelNewsFilterInput | null;
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null;
  type?: ModelEnumNotificationTypeInput | null;
  entity?: ModelStringInput | null;
  description?: ModelStringInput | null;
  enabled?: ModelBooleanInput | null;
  markForDelete?: ModelBooleanInput | null;
  notificationCenterId?: ModelStringInput | null;
  notificationViewerId?: ModelStringInput | null;
  creationDate?: ModelStringInput | null;
  viewDate?: ModelStringInput | null;
  locale?: ModelEnumLocaleInput | null;
  metadata?: ModelStringInput | null;
  and?: Array<ModelNotificationFilterInput | null> | null;
  or?: Array<ModelNotificationFilterInput | null> | null;
  not?: ModelNotificationFilterInput | null;
};

export type SearchableIDFilterInput = {
  ne?: string | null;
  gt?: string | null;
  lt?: string | null;
  gte?: string | null;
  lte?: string | null;
  eq?: string | null;
  match?: string | null;
  matchPhrase?: string | null;
  matchPhrasePrefix?: string | null;
  multiMatch?: string | null;
  exists?: boolean | null;
  wildcard?: string | null;
  regexp?: string | null;
};

export type SearchableStringFilterInput = {
  ne?: string | null;
  gt?: string | null;
  lt?: string | null;
  gte?: string | null;
  lte?: string | null;
  eq?: string | null;
  like?: string | null;
  match?: string | null;
  matchPhrase?: string | null;
  matchPhrasePrefix?: string | null;
  multiMatch?: string | null;
  exists?: boolean | null;
  wildcard?: string | null;
  regexp?: string | null;
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null;
  ne?: boolean | null;
  exists?: boolean | null;
};

export type SearchableDateRangeFilterInput = {
  between?: Array<string> | null;
  gte?: string | null;
  lte?: string | null;
};

export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}

export type SearchableBackOfficeUserFilterInput = {
  firstname?: SearchableStringFilterInput | null;
  lastname?: SearchableStringFilterInput | null;
  email?: SearchableStringFilterInput | null;
  role?: SearchableStringFilterInput | null;
  status?: SearchableStringFilterInput | null;
};

export type SearchableBackOfficeUserSortInput = {
  field?: SearchableBackOfficeUserSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableBackOfficeUserSortableFields {
  id = "id",
  firstname = "firstname",
  lastname = "lastname",
  email = "email",
  phone = "phone",
  picture = "picture",
  enabled = "enabled",
  markForDelete = "markForDelete",
  userPoolId = "userPoolId",
  identityPoolId = "identityPoolId",
  tier = "tier",
  clientId = "clientId",
  username = "username",
  role = "role",
  shortname = "shortname",
  metadata = "metadata",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export type SearchableAuthorizationFilterInput = {
  entityType?: SearchableStringFilterInput | null;
  authorizationUserId?: SearchableStringFilterInput | null;
  entityId?: SearchableStringFilterInput | null;
};

export type SearchableUserFilterInput = {
  firstname?: SearchableStringFilterInput | null;
  lastname?: SearchableStringFilterInput | null;
  status?: SearchableStringFilterInput | null;
  email?: SearchableStringFilterInput | null;
  role?: SearchableStringFilterInput | null;
  and?: Array<SearchableUserFilterInput | null> | null;
  or?: Array<SearchableUserFilterInput | null> | null;
  not?: SearchableUserFilterInput | null;
};

export type SearchableUserSortInput = {
  field?: SearchableUserSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableUserSortableFields {
  firstname = "firstname",
  lastname = "lastname",
  email = "email",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export type SearchableCenterFilterInput = {
  name?: SearchableStringFilterInput | null;
  address?: SearchableStringFilterInput | null;
  city?: SearchableStringFilterInput | null;
};

export type SearchableServiceFilterInput = {
  type: SearchableStringFilterInput;
  centerServicesId?: SearchableIDFilterInput | null;
};

export type SearchableServiceSortInput = {
  field?: SearchableServiceSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableServiceSortableFields {
  id = "id",
  type = "type",
  name = "name",
  description = "description",
  pictures = "pictures",
  authorized = "authorized",
  enabled = "enabled",
  markForDelete = "markForDelete",
  metadata = "metadata",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export type SearchableCategoryFilterInput = {
  type?: SearchableStringFilterInput | null;
  categoryCenterId?: SearchableIDFilterInput | null;
};

export type SearchableIntFilterInput = {
  ne?: number | null;
  gt?: number | null;
  lt?: number | null;
  gte?: number | null;
  lte?: number | null;
  eq?: number | null;
  range?: Array<number | null> | null;
};

export type SearchableCategorySortInput = {
  field?: SearchableCategorySortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableCategorySortableFields {
  position = "position",
  createdAt = "createdAt",
}

export type SearchableProviderFilterInput = {
  name?: SearchableStringFilterInput | null;
  address?: SearchableStringFilterInput | null;
  city?: SearchableStringFilterInput | null;
};

export type SearchableSpaceFilterOrCondition = {
  typology: SearchableStringFilterInput;
};

export type SearchableSpaceTimelineFilterInput = {
  or: Array<SearchableSpaceFilterOrCondition>;
};

export type SearchProvidersSortInput = {
  field?: SearchableProviderSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableProviderSortableFields {
  name = "name",
  createdAt = "createdAt",
}

export type SearchableProductFilterInput = {
  id?: SearchableIDFilterInput | null;
  sku?: SearchableStringFilterInput | null;
  name?: SearchableStringFilterInput | null;
  type?: SearchableStringFilterInput | null;
  typology?: SearchableStringFilterInput | null;
  description?: SearchableStringFilterInput | null;
  pictures?: SearchableStringFilterInput | null;
  vat?: SearchableFloatFilterInput | null;
  capacity?: SearchableIntFilterInput | null;
  stock?: SearchableIntFilterInput | null;
  authorized?: SearchableBooleanFilterInput | null;
  enabled?: SearchableBooleanFilterInput | null;
  markForDelete?: SearchableBooleanFilterInput | null;
  flexible?: SearchableBooleanFilterInput | null;
  metadata?: SearchableStringFilterInput | null;
  serviceProductsId?: SearchableStringFilterInput | null;
  productOperatorId?: SearchableStringFilterInput | null;
  productCenterId?: SearchableStringFilterInput | null;
  productCategoryId?: SearchableStringFilterInput | null;
  productProviderId?: SearchableStringFilterInput | null;
  createdAt?: SearchableStringFilterInput | null;
  updatedAt?: SearchableStringFilterInput | null;
  and?: Array<SearchableProductFilterInput | null> | null;
  or?: Array<SearchableProductFilterInput | null> | null;
  not?: SearchableProductFilterInput | null;
};

export type SearchableProductsFilterInput = {
  serviceId?: SearchableStringFilterInput | null;
  isOption?: SearchableBooleanFilterInput | null;
  inCatalog?: SearchableBooleanFilterInput | null;
  stock?: SearchableIntFilterInput | null;
  or?: Array<SearchableProductFilterInput | null> | null;
};

export type SearchableFloatFilterInput = {
  ne?: number | null;
  gt?: number | null;
  lt?: number | null;
  gte?: number | null;
  lte?: number | null;
  eq?: number | null;
  range?: Array<number | null> | null;
};

export type SearchableProductSortInput = {
  field?: SearchableProductSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableProductSortableFields {
  id = "id",
  sku = "sku",
  name = "name",
  typology = "typology",
  description = "description",
  pictures = "pictures",
  vat = "vat",
  capacity = "capacity",
  stock = "stock",
  authorized = "authorized",
  enabled = "enabled",
  markForDelete = "markForDelete",
  flexible = "flexible",
  metadata = "metadata",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export type SearchableAttributeKeyFilterInput = {
  type?: SearchableStringFilterInput | null;
  name?: SearchableStringFilterInput | null;
  enabled?: SearchableBooleanFilterInput | null;
};

export type SearchableAttributeKeySortInput = {
  field?: SearchableAttributeKeySortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableAttributeKeySortableFields {
  id = "id",
  name = "name",
  description = "description",
  picture = "picture",
  enabled = "enabled",
  markForDelete = "markForDelete",
  position = "position",
  system = "system",
  required = "required",
  unit = "unit",
  metadata = "metadata",
}

export type SearchableUnavailabilityFilterInput = {
  id?: SearchableIDFilterInput | null;
  type?: SearchableIntFilterInput | null;
  date?: SearchableStringFilterInput | null;
  status?: SearchableStringFilterInput | null;
  relatedUnavailabilityId?: SearchableIDFilterInput | null;
  metadata?: SearchableStringFilterInput | null;
  unavailabilityOperatorId?: SearchableStringFilterInput | null;
  unavailabilityCenterId?: SearchableStringFilterInput | null;
  unavailabilityTenantId?: SearchableStringFilterInput | null;
  unavailabilityProductId?: SearchableStringFilterInput | null;
  unavailabilityUserId?: SearchableStringFilterInput | null;
  createdAt?: SearchableStringFilterInput | null;
  updatedAt?: SearchableStringFilterInput | null;
  and?: Array<SearchableUnavailabilityFilterInput | null> | null;
  or?: Array<SearchableUnavailabilityFilterInput | null> | null;
  not?: SearchableUnavailabilityFilterInput | null;
};

export type SearchableCenterSortInput = {
  field?: SearchableCenterSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableCenterSortableFields {
  name = "name",
  createdAt = "createdAt",
}

export type SearchableUnavailabilitySortInput = {
  field?: SearchableUnavailabilitySortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableUnavailabilitySortableFields {
  id = "id",
  type = "type",
  start = "start",
  end = "end",
  relatedUnavailabilityId = "relatedUnavailabilityId",
  status = "status",
  metadata = "metadata",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export type SearchableGlobalOrderFilterInput = {
  status?: SearchableStringFilterInput | null;
  globalOrderCenterId?: SearchableStringFilterInput | null;
  providerId?: SearchableStringFilterInput | null;
  userLastname?: SearchableStringFilterInput | null;
  userEmail?: SearchableStringFilterInput | null;
};

export type SearchableGlobalOrderSortInput = {
  field?: SearchableGlobalOrderSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableGlobalOrderSortableFields {
  timeplaced = "timeplaced",
}

export type SearchablePriceUnitVariationSortInput = {
  field?: SearchablePriceUnitVariationSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchablePriceUnitVariationSortableFields {
  id = "id",
  key = "key",
  name = "name",
  enabled = "enabled",
  markForDelete = "markForDelete",
  metadata = "metadata",
}

export type SearchableEventFilterInput = {
  title?: SearchableStringFilterInput | null;
  start?: SearchableStringFilterInput | null;
  end?: SearchableStringFilterInput | null;
  enabled?: SearchableBooleanFilterInput | null;
  centerEventsId?: SearchableStringFilterInput | null;
  and?: Array<SearchableEventFilterInput | null> | null;
  or?: Array<SearchableEventFilterInput | null> | null;
  not?: SearchableEventFilterInput | null;
};

export type SearchableEventSortInput = {
  field?: SearchableEventSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableEventSortableFields {
  start = "start",
  end = "end",
}

export type SearchableEventTypeSortInput = {
  field?: SearchableEventTypeSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableEventTypeSortableFields {
  id = "id",
  name = "name",
  type = "type",
  enabled = "enabled",
  markForDelete = "markForDelete",
}

export type SearchableParticipationFilterInput = {
  eventParticipationsId?: SearchableStringFilterInput | null;
};

export type ListAssetReportingsFilterInput = {
  buildingId: string;
  date: string;
  type: EnumCalendarType;
};

export type ListOrderReportingsFilterInput = {
  buildingId: string;
  date: string;
  type: EnumCalendarType;
};

export type ListIncidentReportingsFilterInput = {
  buildingId: string;
  date: string;
  type: EnumCalendarType;
};

export type ListReportingsUserFilterInput = {
  date: string;
  view: EnumReportingView;
};

export type ListReportingsGlobalFilterInput = {
  date: string;
  view: EnumReportingView;
  entity: EnumReportingGlobalEntity;
};

export type ListReportingsSalesFilterInput = {
  buildingId: string;
  date: string;
  view: EnumReportingView;
  typology?: EnumProductTypology;
};

export type ListReportingsIncidentFilterInput = {
  buildingId: string;
  date: string;
  view: EnumReportingView;
  status?: number;
  type?: string;
  reason?: string;
};

export type ListReportingsIncidentReasonFilterInput = {
  type: number;
};

export type SearchableParticipationSortInput = {
  field?: SearchableParticipationSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableParticipationSortableFields {
  createdAt = "createdAt",
}

export type SearchableNewsFilterInput = {
  title?: SearchableStringFilterInput | null;
  publicationDate?: SearchableStringFilterInput | null;
  unpublicationDate?: SearchableStringFilterInput | null;
  enabled?: SearchableBooleanFilterInput | null;
  centerNewsId?: SearchableStringFilterInput | null;
  and?: Array<SearchableNewsFilterInput | null> | null;
  or?: Array<SearchableNewsFilterInput | null> | null;
  not?: SearchableNewsFilterInput | null;
};

export type SearchableNewsSortInput = {
  field?: SearchableNewsSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableNewsSortableFields {
  publicationDate = "publicationDate",
  unpublicationDate = "unpublicationDate",
}

export type SearchNotificationsFilterInput = {
  markForDelete?: SearchableBooleanFilterInput | null;
  notificationCenterId?: SearchableStringFilterInput | null;
  notificationViewerId?: SearchableBooleanFilterInput | null;
};

export type SearchNotificationsSortInput = {
  field: SearchableNotificationSortableFields;
  direction?: SearchableSortDirection | null;
};

export enum SearchableNotificationSortableFields {
  CREATION_DATE = "CREATION_DATE",
}

export type CreateAuthorizationMutationVariables = {
  input: CreateAuthorizationInput;
};

export type CreateAuthorizationMutation = {
  createAuthorization: Authorization;
};

export type CreateCenterMutationVariables = {
  input: CreateCenterInput;
  condition?: ModelCenterConditionInput | null;
};

export type CreateCenterMutation = {
  createCenter: Center;
};

export type UpdateCenterMutationVariables = {
  input: UpdateCenterInput;
  condition?: ModelCenterConditionInput | null;
};

export type UpdateCenterMutation = {
  updateCenter: Center;
};

export type CreateCenterTypeMutationVariables = {
  input: CreateCenterTypeInput;
  condition?: ModelCenterTypeConditionInput | null;
};

export type CreateCenterTypeMutation = {
  createCenterType: CenterType;
};

export type UpdateCenterTypeMutationVariables = {
  input: UpdateCenterTypeInput;
};

export type UpdateCenterTypeMutation = {
  updateCenterType: CenterType;
};

export type CreateServiceMutationVariables = {
  input: CreateServiceInput;
  condition?: ModelServiceConditionInput | null;
};

export type CreateServiceMutation = {
  createService: Service;
};

export type UpdateServiceMutationVariables = {
  input: UpdateServiceInput;
  condition?: ModelServiceConditionInput | null;
};

export type UpdateServiceMutation = {
  updateService: Service;
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput;
  condition?: ModelCategoryConditionInput | null;
};

export type CreateCategoryMutation = {
  createCategory: Category;
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput;
  condition?: ModelCategoryConditionInput | null;
};

export type UpdateCategoryMutation = {
  updateCategory: Category;
};

export type CreateProviderMutationVariables = {
  input: CreateProviderInput;
};

export type CreateProviderMutation = {
  createProvider: Provider;
};

export type UpdateProviderMutationVariables = {
  input: UpdateProviderInput;
};

export type UpdateProviderMutation = {
  updateProvider: Provider;
};

export type CreateProductMutationVariables = {
  input: CreateProductInput;
  condition?: ModelProductConditionInput | null;
};

export type CreateProductMutation = {
  createProduct: Product;
};

export type UpdateProductMutationVariables = {
  input: UpdateProductInput;
  condition?: ModelProductConditionInput | null;
};

export type UpdateProductMutation = {
  updateProduct: Product;
};

export type CreateOptionMutationVariables = {
  input: CreateOptionInput;
  condition?: ModelOptionConditionInput | null;
};

export type CreateOptionMutation = {
  createOption: Option;
};

export type DeleteOptionMutationVariables = {
  input: DeleteOptionInput;
  condition?: ModelOptionConditionInput | null;
};

export type DeleteOptionMutation = {
  deleteOption: Option;
};

export type CreateSingleProductMutationVariables = {
  input: CreateSingleProductInput;
  condition?: ModelSingleProductConditionInput | null;
};

export type CreateSingleProductMutation = {
  createSingleProduct: SingleProduct;
};

export type DeleteSingleProductMutationVariables = {
  input: DeleteSingleProductInput;
  condition?: ModelSingleProductConditionInput | null;
};

export type DeleteSingleProductMutation = {
  deleteSingleProduct: SingleProduct;
};

export type CreateAttributeMutationVariables = {
  input: CreateAttributeInput;
};

export type CreateAttributeMutation = {
  createAttribute: Attribute;
};

export type UpdateAttributeMutationVariables = {
  input: UpdateAttributeInput;
  condition?: ModelAttributeConditionInput | null;
};

export type UpdateAttributeMutation = {
  updateAttribute: Attribute;
};

export type UpdateAttributeKeyMutation = {
  updateAttributeKey: AttributeKey;
};

export type DeleteAttributeMutationVariables = {
  input: DeleteAttributeInput;
  condition?: ModelAttributeConditionInput | null;
};

export type DeleteAttributeMutation = {
  deleteAttribute: Attribute;
};

export type CreateDaySlotMutationVariables = {
  input: CreateDaySlotInput;
  condition?: ModelDaySlotConditionInput | null;
};

export type CreateDaySlotMutation = {
  createDaySlot: DaySlot;
};

export type UpdateDaySlotMutationVariables = {
  input: UpdateDaySlotInput;
  condition?: ModelDaySlotConditionInput | null;
};

export type UpdateDaySlotMutation = {
  updateDaySlot: DaySlot;
};

export type DeleteHoursRangeMutationVariables = {
  input: DeleteManyInput;
};

export type CreateClosedDayMutationVariables = {
  input: CreateClosedDayInput;
  condition?: ModelClosedDayConditionInput | null;
};

export type CreateClosedDayMutation = {
  createClosedDay: ClosedDay;
};

export type CreatePriceUnitMutationVariables = {
  input: CreatePriceUnitInput;
};

export type CreatePriceUnitMutation = {
  createPriceUnit: PriceUnit;
};

export type UpdatePriceUnitMutationVariables = {
  input: UpdatePriceUnitInput;
  condition?: ModelPriceUnitConditionInput | null;
};

export type UpdatePriceUnitMutation = {
  updatePriceUnit: PriceUnit;
};

export type DeletePriceUnitMutationVariables = {
  input: DeletePriceUnitInput;
  condition?: ModelPriceUnitConditionInput | null;
};

export type DeletePriceUnitMutation = {
  deletePriceUnit: PriceUnit;
};

export type CreatePriceUnitVariationMutationVariables = {
  input: CreatePriceUnitVariationInput;
};

export type CreatePriceUnitVariationMutation = {
  createPriceUnitVariation: {
    __typename: "PriceUnitVariation";
    id: string;
    key: string;
    name: string;
    enabled: boolean;
    markForDelete: boolean;
    metadata: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdatePriceUnitVariationMutationVariables = {
  input: UpdatePriceUnitVariationInput;
};

export type UpdatePriceUnitVariationMutation = {
  updatePriceUnitVariation: {
    __typename: "PriceUnitVariation";
    id: string;
    key: string;
    name: string;
    enabled: boolean;
    markForDelete: boolean;
    metadata: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateEventMutation = {
  createEvent: Event;
};

export type UpdateEventMutation = {
  updateEvent: Event;
};

export type CreateEventTypeMutation = {
  createEventType: {
    __typename: "EventType";
    id: string;
    name: string | null;
    type: string;
    enabled: boolean;
    markForDelete: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateEventTypeMutation = {
  updateEventType: {
    __typename: "EventType";
    id: string;
    name: string | null;
    type: string;
    enabled: boolean;
    markForDelete: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateNewsMutationVariables = {
  input: CreateNewsInput;
  condition?: ModelNewsConditionInput | null;
};

export type CreateNewsMutation = {
  createNews: News;
};

export type UpdateNewsMutationVariables = {
  input: UpdateNewsInput;
  condition?: ModelNewsConditionInput | null;
};

export type UpdateNewsMutation = {
  updateNews: News;
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput;
  condition?: ModelNotificationConditionInput | null;
};

export type UpdateNotificationMutation = {
  updateNotification: Notification;
};

export type GetBackOfficeUserQueryVariables = {
  id: string;
};

export type GetBackOfficeUserQuery = {
  getBackOfficeUser: BackOfficeUser;
};

export type GetUserQueryVariables = {
  id: string;
};

export type GetUserQuery = {
  getUser: User;
};

export type GetCenterQueryVariables = {
  id: string;
};

export type GetCenterQuery = {
  getCenter: Center;
};

export type ListCenterTypesQueryVariables = {
  filter?: ModelCenterTypeFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListCenterTypesQuery = {
  listCenterTypes: ModelCenterTypeConnection;
};

export type GetServiceQueryVariables = {
  id: string;
};

export type GetServiceQuery = {
  getService: Service;
};

export type ListCategoriesQueryVariables = {
  filter?: ModelCategoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListCategoriesQuery = {
  listCategories: {
    __typename: "ModelCategoryConnection";
    items: Array<{
      __typename: "Category";
      operator: {
        __typename: "Operator";
        id: string;
        shortname: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        organizationName: string | null;
        address: string | null;
        zipCode: string | null;
        city: string | null;
        country: string | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        systemAdminPolicy: string;
        systemAdminRole: string;
        systemUserPolicy: string;
        systemUserRole: string;
        operatorAdminPolicy: string;
        operatorAdminRole: string;
        operatorUserPolicy: string;
        operatorUserRole: string;
        centerAdminPolicy: string;
        centerAdminRole: string;
        centerUserPolicy: string;
        centerUserRole: string;
        tenantAdminPolicy: string;
        tenantAdminRole: string;
        tenantUserPolicy: string;
        tenantUserRole: string;
        userPolicy: string;
        userRole: string;
        anonymousPolicy: string;
        anonymousRole: string;
        trustRole: string;
        tier: string;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      id: string;
      type: EnumCategoryType;
      name: string;
      description: string | null;
      picture: string | null;
      level: number | null;
      position: number;
      center: {
        __typename: "Center";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        type: {
          __typename: "CenterType";
          id: string;
          name: string | null;
          type: string;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null;
        name: string;
        description: string | null;
        address: string;
        zipCode: string;
        city: string;
        country: string;
        location: {
          __typename: "GPS";
          lat: number | null;
          lon: number | null;
        } | null;
        pictures: Array<string | null> | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        daySlots: {
          __typename: "ModelDaySlotConnection";
          nextToken: string | null;
        } | null;
        closedDays: {
          __typename: "ModelClosedDayConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        phone: string | null;
        email: string | null;
        services: {
          __typename: "ModelServiceConnection";
          nextToken: string | null;
        } | null;
        tenantServices: {
          __typename: "ModelTenantServiceConnection";
          nextToken: string | null;
        } | null;
        products: {
          __typename: "ModelProductConnection";
          nextToken: string | null;
        } | null;
        providers: {
          __typename: "ModelProviderConnection";
          nextToken: string | null;
        } | null;
        events: {
          __typename: "SearchEventsOutput";
          nextToken: string | null;
        } | null;
        eventTypes: {
          __typename: "ListEventTypeOutput";
          nextToken: string | null;
        } | null;
        news: {
          __typename: "ModelNewsConnection";
          nextToken: string | null;
        } | null;
        unavailabilities: {
          __typename: "ModelUnavailabilityConnection";
          nextToken: string | null;
        } | null;
        tenants: {
          __typename: "ModelCenterTenantConnection";
          nextToken: string | null;
        } | null;
        notifications: {
          __typename: "ModelNotificationConnection";
          nextToken: string | null;
        } | null;
        categories: {
          __typename: "ModelCategoryConnection";
          nextToken: string | null;
        } | null;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      parent: {
        __typename: "Category";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        type: EnumCategoryType;
        name: string;
        description: string | null;
        picture: string | null;
        level: number | null;
        position: number;
        center: {
          __typename: "Center";
          id: string;
          name: string;
          description: string | null;
          address: string;
          zipCode: string;
          city: string;
          country: string;
          pictures: Array<string | null> | null;
          enabled: boolean;
          markForDelete: boolean;
          phone: string | null;
          email: string | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        parent: {
          __typename: "Category";
          id: string;
          type: EnumCategoryType;
          name: string;
          description: string | null;
          picture: string | null;
          level: number | null;
          position: number;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        categories: {
          __typename: "ModelCategoryConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      categories: {
        __typename: "ModelCategoryConnection";
        items: Array<{
          __typename: "Category";
          id: string;
          type: EnumCategoryType;
          name: string;
          description: string | null;
          picture: string | null;
          level: number | null;
          position: number;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      enabled: boolean;
      markForDelete: boolean;
      metadata: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type GetProviderQueryVariables = {
  id: string;
};

export type GetProviderQuery = {
  getProvider: Provider;
};

export type GetProductQueryVariables = {
  id: string;
};

export type GetProductQuery = {
  getProduct: Product;
};

export type SearchOptionsQueryVariables = {
  filter?: SearchableOptionFilterInput | null;
  limit?: number | null;
};

export type SearchDaySlotsQuery = {
  searchDaySlots: {
    __typename: "ModelOptionConnection";
    items: Array<{
      __typename: "DaySlot";
      operator: {
        __typename: "Operator";
        id: string;
        shortname: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        organizationName: string | null;
        address: string | null;
        zipCode: string | null;
        city: string | null;
        country: string | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        systemAdminPolicy: string;
        systemAdminRole: string;
        systemUserPolicy: string;
        systemUserRole: string;
        operatorAdminPolicy: string;
        operatorAdminRole: string;
        operatorUserPolicy: string;
        operatorUserRole: string;
        centerAdminPolicy: string;
        centerAdminRole: string;
        centerUserPolicy: string;
        centerUserRole: string;
        tenantAdminPolicy: string;
        tenantAdminRole: string;
        tenantUserPolicy: string;
        tenantUserRole: string;
        userPolicy: string;
        userRole: string;
        anonymousPolicy: string;
        anonymousRole: string;
        trustRole: string;
        tier: string;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      id: string;
      name: string | null;
      day: string;
      dayBreak: string | null;
      hoursRange: {
        __typename: "ModelHoursRangeConnection";
        nextToken: string | null;
      } | null;
      markForDelete: boolean;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type GetOrderQueryVariables = {
  id: string;
};

export type GetGlobalOrderQuery = {
  getGlobalOrder: {
    __typename: "GlobalOrder";
    id: string;
    ormOrder: string;
    price: number;
    totalTax: number;
    totalAdjustment: number;
    currency: EnumCurrency;
    channel: EnumChannel;
    timeplaced: string;
    paid: boolean;
    captured: boolean;
    status: EnumOrderStatus;
    calendarEventId: string | null;
    locale: EnumLocale | null;
    metadata: string | null;
    comment: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type GetOrderQuery = {
  getOrder: {
    __typename: "Order";
    operator: {
      __typename: "Operator";
      id: string;
      shortname: string;
      firstname: string;
      lastname: string;
      email: string;
      phone: string | null;
      organizationName: string | null;
      address: string | null;
      zipCode: string | null;
      city: string | null;
      country: string | null;
      attributes: {
        __typename: "ModelAttributeConnection";
        items: Array<{
          __typename: "Attribute";
          id: string;
          booleanValue: boolean | null;
          stringValue: string | null;
          value: string;
          jsonValue: string | null;
          numberValue: number | null;
          urlValue: string | null;
          dateValue: string | null;
          selectValue: string | null;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      enabled: boolean;
      markForDelete: boolean;
      userPoolId: string;
      identityPoolId: string;
      systemAdminPolicy: string;
      systemAdminRole: string;
      systemUserPolicy: string;
      systemUserRole: string;
      operatorAdminPolicy: string;
      operatorAdminRole: string;
      operatorUserPolicy: string;
      operatorUserRole: string;
      centerAdminPolicy: string;
      centerAdminRole: string;
      centerUserPolicy: string;
      centerUserRole: string;
      tenantAdminPolicy: string;
      tenantAdminRole: string;
      tenantUserPolicy: string;
      tenantUserRole: string;
      userPolicy: string;
      userRole: string;
      anonymousPolicy: string;
      anonymousRole: string;
      trustRole: string;
      tier: string;
      metadata: string | null;
      createdAt: string;
      updatedAt: string;
    };
    id: string;
    ormOrder: string;
    user: {
      __typename: "User";
      operator: {
        __typename: "Operator";
        id: string;
        shortname: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        organizationName: string | null;
        address: string | null;
        zipCode: string | null;
        city: string | null;
        country: string | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        systemAdminPolicy: string;
        systemAdminRole: string;
        systemUserPolicy: string;
        systemUserRole: string;
        operatorAdminPolicy: string;
        operatorAdminRole: string;
        operatorUserPolicy: string;
        operatorUserRole: string;
        centerAdminPolicy: string;
        centerAdminRole: string;
        centerUserPolicy: string;
        centerUserRole: string;
        tenantAdminPolicy: string;
        tenantAdminRole: string;
        tenantUserPolicy: string;
        tenantUserRole: string;
        userPolicy: string;
        userRole: string;
        anonymousPolicy: string;
        anonymousRole: string;
        trustRole: string;
        tier: string;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      id: string;
      firstname: string | null;
      lastname: string | null;
      email: string;
      phone: string | null;
      role: EnumUserRole;
      picture: string | null;
      globalOrders: {
        __typename: "ModelGlobalOrderConnection";
        items: Array<{
          __typename: "GlobalOrder";
          id: string;
          ormOrder: string;
          price: number;
          totalTax: number;
          totalAdjustment: number;
          currency: EnumCurrency;
          channel: EnumChannel;
          timeplaced: string;
          paid: boolean;
          captured: boolean;
          status: EnumOrderStatus;
          calendarEventId: string | null;
          locale: EnumLocale | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      invoices: {
        __typename: "ModelInvoiceConnection";
        items: Array<{
          __typename: "Invoice";
          id: string;
          providerInvoiceId: string;
          date: string;
          status: EnumInvoiceStatus;
          amountDue: number;
          amountPaid: number;
          amountRemaining: number;
          currency: EnumCurrency;
          totalPrice: number;
          description: string | null;
          invoiceUrl: string | null;
          invoicePdf: string | null;
          paid: boolean;
          endOfMonth: boolean;
          locale: EnumLocale | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      unavailabilities: {
        __typename: "ModelUnavailabilityConnection";
        items: Array<{
          __typename: "Unavailability";
          id: string;
          type: number;
          start: string;
          end: string;
          status: EnumAvailabilityStatus;
          relatedUnavailabilityId: string | null;
          locale: EnumLocale | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      center: {
        __typename: "Center";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        type: {
          __typename: "CenterType";
          id: string;
          name: string | null;
          type: string;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null;
        name: string;
        description: string | null;
        address: string;
        zipCode: string;
        city: string;
        country: string;
        location: {
          __typename: "GPS";
          lat: number | null;
          lon: number | null;
        } | null;
        pictures: Array<string | null> | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        daySlots: {
          __typename: "ModelDaySlotConnection";
          nextToken: string | null;
        } | null;
        closedDays: {
          __typename: "ModelClosedDayConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        phone: string | null;
        email: string | null;
        services: {
          __typename: "ModelServiceConnection";
          nextToken: string | null;
        } | null;
        tenantServices: {
          __typename: "ModelTenantServiceConnection";
          nextToken: string | null;
        } | null;
        products: {
          __typename: "ModelProductConnection";
          nextToken: string | null;
        } | null;
        providers: {
          __typename: "ModelProviderConnection";
          nextToken: string | null;
        } | null;
        events: {
          __typename: "SearchEventsOutput";
          nextToken: string | null;
        } | null;
        eventTypes: {
          __typename: "ListEventTypeOutput";
          nextToken: string | null;
        } | null;
        news: {
          __typename: "ModelNewsConnection";
          nextToken: string | null;
        } | null;
        unavailabilities: {
          __typename: "ModelUnavailabilityConnection";
          nextToken: string | null;
        } | null;
        tenants: {
          __typename: "ModelCenterTenantConnection";
          nextToken: string | null;
        } | null;
        notifications: {
          __typename: "ModelNotificationConnection";
          nextToken: string | null;
        } | null;
        categories: {
          __typename: "ModelCategoryConnection";
          nextToken: string | null;
        } | null;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      tenant: {
        __typename: "Tenant";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        type: {
          __typename: "TenantType";
          id: string;
          name: string | null;
          type: string;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null;
        name: string;
        description: string | null;
        address: string;
        zipCode: string;
        city: string;
        country: string;
        phone: string | null;
        email: string | null;
        daySlots: {
          __typename: "ModelDaySlotConnection";
          nextToken: string | null;
        } | null;
        closedDays: {
          __typename: "ModelClosedDayConnection";
          nextToken: string | null;
        } | null;
        pictures: Array<string | null> | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        centers: {
          __typename: "ModelCenterTenantConnection";
          nextToken: string | null;
        } | null;
        events: {
          __typename: "SearchEventsOutput";
          nextToken: string | null;
        } | null;
        eventTypes: {
          __typename: "ListEventTypeOutput";
          nextToken: string | null;
        } | null;
        news: {
          __typename: "ModelNewsConnection";
          nextToken: string | null;
        } | null;
        authorized: boolean | null;
        enabled: boolean;
        markForDelete: boolean;
        unavailabilities: {
          __typename: "ModelUnavailabilityConnection";
          nextToken: string | null;
        } | null;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      attributes: {
        __typename: "ModelAttributeConnection";
        items: Array<{
          __typename: "Attribute";
          id: string;
          booleanValue: boolean | null;
          stringValue: string | null;
          value: string;
          jsonValue: string | null;
          numberValue: number | null;
          urlValue: string | null;
          dateValue: string | null;
          selectValue: string | null;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      enabled: boolean;
      markForDelete: boolean;
      userPoolId: string;
      identityPoolId: string;
      tier: string;
      clientId: string;
      username: string | null;
      shortname: string | null;
      locale: EnumLocale | null;
      metadata: string | null;
      createdAt: string;
      updatedAt: string;
    };
    orderItems: {
      __typename: "ModelOrderItemConnection";
      items: Array<{
        __typename: "OrderItem";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        product: string;
        sku: string;
        name: string;
        status: EnumOrderStatus;
        description: string | null;
        pictures: Array<string | null> | null;
        category: string | null;
        provider: string;
        totalPrice: number;
        totalTax: number;
        totalAdjustment: number;
        amountTransfered: number | null;
        price: number;
        currency: EnumCurrency;
        unit: EnumUnit;
        increment: number;
        quantity: number;
        unitQuantity: number | null;
        unitPrice: number | null;
        unitTax: number | null;
        unitAdjustment: number | null;
        priceUnit: string;
        variation: string;
        order: {
          __typename: "Order";
          id: string;
          ormOrder: string;
          price: number;
          totalTax: number;
          totalAdjustment: number;
          transactionFeePercent: number | null;
          currency: EnumCurrency;
          channel: EnumChannel;
          timeplaced: string;
          deliveryDate: string | null;
          status: EnumOrderStatus;
          locale: EnumLocale | null;
          metadata: string | null;
          reason: EnumBookingReason | null;
          comment: string | null;
          createdAt: string;
          updatedAt: string;
        };
        unavailability: {
          __typename: "Unavailability";
          id: string;
          type: number;
          start: string;
          end: string;
          status: EnumAvailabilityStatus;
          relatedUnavailabilityId: string | null;
          locale: EnumLocale | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        vat: number;
        refunded: boolean;
        relatedOrderItemId: string | null;
        refund: {
          __typename: "Refund";
          id: string;
          refundId: string;
          amount: number;
          date: string;
          status: string;
          reason: string | null;
          refundApplicationFee: boolean | null;
          reverseTransfer: boolean | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        invoiceItem: {
          __typename: "InvoiceItem";
          id: string;
          providerInvoiceItemId: string;
          date: string;
          price: number;
          totalPrice: number;
          quantity: number;
          currency: EnumCurrency;
          description: string;
          taxRates: Array<string | null> | null;
          locale: EnumLocale | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        locale: EnumLocale | null;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
      nextToken: string | null;
    } | null;
    price: number;
    totalTax: number;
    totalAdjustment: number;
    transactionFeePercent: number | null;
    currency: EnumCurrency;
    center: {
      __typename: "Center";
      operator: {
        __typename: "Operator";
        id: string;
        shortname: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        organizationName: string | null;
        address: string | null;
        zipCode: string | null;
        city: string | null;
        country: string | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        systemAdminPolicy: string;
        systemAdminRole: string;
        systemUserPolicy: string;
        systemUserRole: string;
        operatorAdminPolicy: string;
        operatorAdminRole: string;
        operatorUserPolicy: string;
        operatorUserRole: string;
        centerAdminPolicy: string;
        centerAdminRole: string;
        centerUserPolicy: string;
        centerUserRole: string;
        tenantAdminPolicy: string;
        tenantAdminRole: string;
        tenantUserPolicy: string;
        tenantUserRole: string;
        userPolicy: string;
        userRole: string;
        anonymousPolicy: string;
        anonymousRole: string;
        trustRole: string;
        tier: string;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      id: string;
      type: {
        __typename: "CenterType";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        name: string | null;
        type: string;
        enabled: boolean;
        markForDelete: boolean;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      description: string | null;
      address: string;
      zipCode: string;
      city: string;
      country: string;
      location: {
        __typename: "GPS";
        lat: number | null;
        lon: number | null;
      } | null;
      pictures: Array<string | null> | null;
      attributes: {
        __typename: "ModelAttributeConnection";
        items: Array<{
          __typename: "Attribute";
          id: string;
          booleanValue: boolean | null;
          stringValue: string | null;
          value: string;
          jsonValue: string | null;
          numberValue: number | null;
          urlValue: string | null;
          dateValue: string | null;
          selectValue: string | null;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      daySlots: {
        __typename: "ModelDaySlotConnection";
        items: Array<{
          __typename: "DaySlot";
          id: string;
          name: string | null;
          day: string;
          dayBreak: string | null;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      closedDays: {
        __typename: "ModelClosedDayConnection";
        items: Array<{
          __typename: "ClosedDay";
          id: string;
          start: string;
          end: string;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      enabled: boolean;
      markForDelete: boolean;
      phone: string | null;
      email: string | null;
      services: {
        __typename: "ModelServiceConnection";
        items: Array<{
          __typename: "Service";
          id: string;
          type: EnumServiceType;
          name: string;
          description: string | null;
          pictures: Array<string | null> | null;
          authorized: boolean | null;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      tenantServices: {
        __typename: "ModelTenantServiceConnection";
        items: Array<{
          __typename: "TenantService";
          id: string;
          name: string;
          description: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      products: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          type: EnumProductType;
          sku: string;
          name: string;
          typology: EnumProductTypology;
          description: string | null;
          pictures: Array<string | null> | null;
          vat: number;
          capacity: number | null;
          stock: number;
          authorized: boolean | null;
          enabled: boolean;
          markForDelete: boolean;
          flexible: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      providers: {
        __typename: "ModelProviderConnection";
        items: Array<{
          __typename: "Provider";
          id: string;
          name: string;
          description: string | null;
          address: string;
          zipCode: string;
          city: string;
          country: string;
          phone: string | null;
          email: string | null;
          pictures: Array<string | null> | null;
          authorized: boolean | null;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      events: {
        __typename: "SearchEventsOutput";
        items: Array<{
          __typename: "Event";
          id: string;
          title: string;
          description: string | null;
          picture: string | null;
          start: string;
          end: string;
          authorized: boolean | null;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      eventTypes: {
        __typename: "ListEventTypeOutput";
        items: Array<{
          __typename: "EventType";
          id: string;
          name: string | null;
          type: string;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      news: {
        __typename: "ModelNewsConnection";
        items: Array<{
          __typename: "News";
          id: string;
          title: string;
          description: string;
          picture: string | null;
          publicationDate: string | null;
          unpublicationDate: string | null;
          authorized: boolean | null;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      unavailabilities: {
        __typename: "ModelUnavailabilityConnection";
        items: Array<{
          __typename: "Unavailability";
          id: string;
          type: number;
          start: string;
          end: string;
          status: EnumAvailabilityStatus;
          relatedUnavailabilityId: string | null;
          locale: EnumLocale | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      tenants: {
        __typename: "ModelCenterTenantConnection";
        items: Array<{
          __typename: "CenterTenant";
          id: string;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      notifications: {
        __typename: "ModelNotificationConnection";
        items: Array<{
          __typename: "Notification";
          id: string;
          type: EnumNotificationType;
          entity: string;
          description: string;
          enabled: boolean;
          markForDelete: boolean;
          creationDate: string;
          viewDate: string | null;
          locale: EnumLocale | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      categories: {
        __typename: "ModelCategoryConnection";
        items: Array<{
          __typename: "Category";
          id: string;
          type: EnumCategoryType;
          name: string;
          description: string | null;
          picture: string | null;
          level: number | null;
          position: number;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      metadata: string | null;
      createdAt: string;
      updatedAt: string;
    };
    tenant: {
      __typename: "Tenant";
      operator: {
        __typename: "Operator";
        id: string;
        shortname: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        organizationName: string | null;
        address: string | null;
        zipCode: string | null;
        city: string | null;
        country: string | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        systemAdminPolicy: string;
        systemAdminRole: string;
        systemUserPolicy: string;
        systemUserRole: string;
        operatorAdminPolicy: string;
        operatorAdminRole: string;
        operatorUserPolicy: string;
        operatorUserRole: string;
        centerAdminPolicy: string;
        centerAdminRole: string;
        centerUserPolicy: string;
        centerUserRole: string;
        tenantAdminPolicy: string;
        tenantAdminRole: string;
        tenantUserPolicy: string;
        tenantUserRole: string;
        userPolicy: string;
        userRole: string;
        anonymousPolicy: string;
        anonymousRole: string;
        trustRole: string;
        tier: string;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      id: string;
      type: {
        __typename: "TenantType";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        name: string | null;
        type: string;
        enabled: boolean;
        markForDelete: boolean;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      description: string | null;
      address: string;
      zipCode: string;
      city: string;
      country: string;
      phone: string | null;
      email: string | null;
      daySlots: {
        __typename: "ModelDaySlotConnection";
        items: Array<{
          __typename: "DaySlot";
          id: string;
          name: string | null;
          day: string;
          dayBreak: string | null;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      closedDays: {
        __typename: "ModelClosedDayConnection";
        items: Array<{
          __typename: "ClosedDay";
          id: string;
          start: string;
          end: string;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      pictures: Array<string | null> | null;
      attributes: {
        __typename: "ModelAttributeConnection";
        items: Array<{
          __typename: "Attribute";
          id: string;
          booleanValue: boolean | null;
          stringValue: string | null;
          value: string;
          jsonValue: string | null;
          numberValue: number | null;
          urlValue: string | null;
          dateValue: string | null;
          selectValue: string | null;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      centers: {
        __typename: "ModelCenterTenantConnection";
        items: Array<{
          __typename: "CenterTenant";
          id: string;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      events: {
        __typename: "SearchEventsOutput";
        items: Array<{
          __typename: "Event";
          id: string;
          title: string;
          description: string | null;
          picture: string | null;
          start: string;
          end: string;
          authorized: boolean | null;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      eventTypes: {
        __typename: "ListEventTypeOutput";
        items: Array<{
          __typename: "EventType";
          id: string;
          name: string | null;
          type: string;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      news: {
        __typename: "ModelNewsConnection";
        items: Array<{
          __typename: "News";
          id: string;
          title: string;
          description: string;
          picture: string | null;
          publicationDate: string | null;
          unpublicationDate: string | null;
          authorized: boolean | null;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      authorized: boolean | null;
      enabled: boolean;
      markForDelete: boolean;
      unavailabilities: {
        __typename: "ModelUnavailabilityConnection";
        items: Array<{
          __typename: "Unavailability";
          id: string;
          type: number;
          start: string;
          end: string;
          status: EnumAvailabilityStatus;
          relatedUnavailabilityId: string | null;
          locale: EnumLocale | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      metadata: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    provider: {
      __typename: "Provider";
      operator: {
        __typename: "Operator";
        id: string;
        shortname: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        organizationName: string | null;
        address: string | null;
        zipCode: string | null;
        city: string | null;
        country: string | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        systemAdminPolicy: string;
        systemAdminRole: string;
        systemUserPolicy: string;
        systemUserRole: string;
        operatorAdminPolicy: string;
        operatorAdminRole: string;
        operatorUserPolicy: string;
        operatorUserRole: string;
        centerAdminPolicy: string;
        centerAdminRole: string;
        centerUserPolicy: string;
        centerUserRole: string;
        tenantAdminPolicy: string;
        tenantAdminRole: string;
        tenantUserPolicy: string;
        tenantUserRole: string;
        userPolicy: string;
        userRole: string;
        anonymousPolicy: string;
        anonymousRole: string;
        trustRole: string;
        tier: string;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      id: string;
      name: string;
      description: string | null;
      address: string;
      zipCode: string;
      city: string;
      country: string;
      phone: string | null;
      email: string | null;
      pictures: Array<string | null> | null;
      attributes: {
        __typename: "ModelAttributeConnection";
        items: Array<{
          __typename: "Attribute";
          id: string;
          booleanValue: boolean | null;
          stringValue: string | null;
          value: string;
          jsonValue: string | null;
          numberValue: number | null;
          urlValue: string | null;
          dateValue: string | null;
          selectValue: string | null;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      authorized: boolean | null;
      enabled: boolean;
      markForDelete: boolean;
      metadata: string | null;
      createdAt: string;
      updatedAt: string;
    };
    channel: EnumChannel;
    timeplaced: string;
    deliveryDate: string | null;
    status: EnumOrderStatus;
    payment: {
      __typename: "Payment";
      operator: {
        __typename: "Operator";
        id: string;
        shortname: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        organizationName: string | null;
        address: string | null;
        zipCode: string | null;
        city: string | null;
        country: string | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        systemAdminPolicy: string;
        systemAdminRole: string;
        systemUserPolicy: string;
        systemUserRole: string;
        operatorAdminPolicy: string;
        operatorAdminRole: string;
        operatorUserPolicy: string;
        operatorUserRole: string;
        centerAdminPolicy: string;
        centerAdminRole: string;
        centerUserPolicy: string;
        centerUserRole: string;
        tenantAdminPolicy: string;
        tenantAdminRole: string;
        tenantUserPolicy: string;
        tenantUserRole: string;
        userPolicy: string;
        userRole: string;
        anonymousPolicy: string;
        anonymousRole: string;
        trustRole: string;
        tier: string;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      id: string;
      paid: boolean;
      refunded: boolean;
      captured: boolean;
      amountCaptured: number;
      amountRefunded: number;
      amountRemaining: number;
      amountTransfered: number | null;
      currency: EnumCurrency;
      captureDate: string;
      paymentDate: string;
      charge: string | null;
      paymentChargeId: string | null;
      intent: string | null;
      paymentIntentId: string | null;
      paymentMethodId: string | null;
      paymentMethodDetails: string | null;
      receiptUrl: string | null;
      status: string;
      refunds: {
        __typename: "ModelRefundConnection";
        items: Array<{
          __typename: "Refund";
          id: string;
          refundId: string;
          amount: number;
          date: string;
          status: string;
          reason: string | null;
          refundApplicationFee: boolean | null;
          reverseTransfer: boolean | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      invoice: {
        __typename: "Invoice";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        providerInvoiceId: string;
        date: string;
        status: EnumInvoiceStatus;
        amountDue: number;
        amountPaid: number;
        amountRemaining: number;
        currency: EnumCurrency;
        totalPrice: number;
        description: string | null;
        invoiceUrl: string | null;
        invoicePdf: string | null;
        paid: boolean;
        endOfMonth: boolean;
        user: {
          __typename: "User";
          id: string;
          firstname: string | null;
          lastname: string | null;
          email: string;
          phone: string | null;
          role: EnumUserRole;
          picture: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          tier: string;
          clientId: string;
          username: string | null;
          shortname: string | null;
          locale: EnumLocale | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        items: {
          __typename: "ModelInvoiceItemConnection";
          nextToken: string | null;
        } | null;
        orders: {
          __typename: "ModelOrderConnection";
          nextToken: string | null;
        } | null;
        locale: EnumLocale | null;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      metadata: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    orderer: {
      __typename: "BackOfficeUser";
      operator: {
        __typename: "Operator";
        id: string;
        shortname: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        organizationName: string | null;
        address: string | null;
        zipCode: string | null;
        city: string | null;
        country: string | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        systemAdminPolicy: string;
        systemAdminRole: string;
        systemUserPolicy: string;
        systemUserRole: string;
        operatorAdminPolicy: string;
        operatorAdminRole: string;
        operatorUserPolicy: string;
        operatorUserRole: string;
        centerAdminPolicy: string;
        centerAdminRole: string;
        centerUserPolicy: string;
        centerUserRole: string;
        tenantAdminPolicy: string;
        tenantAdminRole: string;
        tenantUserPolicy: string;
        tenantUserRole: string;
        userPolicy: string;
        userRole: string;
        anonymousPolicy: string;
        anonymousRole: string;
        trustRole: string;
        tier: string;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      phone: string | null;
      role: EnumBackOfficeUserRole;
      picture: string | null;
      enabled: boolean;
      markForDelete: boolean;
      userPoolId: string;
      identityPoolId: string;
      tier: string;
      clientId: string;
      username: string | null;
      shortname: string | null;
      locale: EnumLocale | null;
      metadata: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    invoice: {
      __typename: "Invoice";
      operator: {
        __typename: "Operator";
        id: string;
        shortname: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        organizationName: string | null;
        address: string | null;
        zipCode: string | null;
        city: string | null;
        country: string | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        systemAdminPolicy: string;
        systemAdminRole: string;
        systemUserPolicy: string;
        systemUserRole: string;
        operatorAdminPolicy: string;
        operatorAdminRole: string;
        operatorUserPolicy: string;
        operatorUserRole: string;
        centerAdminPolicy: string;
        centerAdminRole: string;
        centerUserPolicy: string;
        centerUserRole: string;
        tenantAdminPolicy: string;
        tenantAdminRole: string;
        tenantUserPolicy: string;
        tenantUserRole: string;
        userPolicy: string;
        userRole: string;
        anonymousPolicy: string;
        anonymousRole: string;
        trustRole: string;
        tier: string;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      id: string;
      providerInvoiceId: string;
      date: string;
      status: EnumInvoiceStatus;
      amountDue: number;
      amountPaid: number;
      amountRemaining: number;
      currency: EnumCurrency;
      totalPrice: number;
      description: string | null;
      invoiceUrl: string | null;
      invoicePdf: string | null;
      paid: boolean;
      endOfMonth: boolean;
      user: {
        __typename: "User";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        firstname: string | null;
        lastname: string | null;
        email: string;
        phone: string | null;
        role: EnumUserRole;
        picture: string | null;
        globalOrders: {
          __typename: "ModelGlobalOrderConnection";
          nextToken: string | null;
        } | null;
        invoices: {
          __typename: "ModelInvoiceConnection";
          nextToken: string | null;
        } | null;
        unavailabilities: {
          __typename: "ModelUnavailabilityConnection";
          nextToken: string | null;
        } | null;
        center: {
          __typename: "Center";
          id: string;
          name: string;
          description: string | null;
          address: string;
          zipCode: string;
          city: string;
          country: string;
          pictures: Array<string | null> | null;
          enabled: boolean;
          markForDelete: boolean;
          phone: string | null;
          email: string | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tenant: {
          __typename: "Tenant";
          id: string;
          name: string;
          description: string | null;
          address: string;
          zipCode: string;
          city: string;
          country: string;
          phone: string | null;
          email: string | null;
          pictures: Array<string | null> | null;
          authorized: boolean | null;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        tier: string;
        clientId: string;
        username: string | null;
        shortname: string | null;
        locale: EnumLocale | null;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      items: {
        __typename: "ModelInvoiceItemConnection";
        items: Array<{
          __typename: "InvoiceItem";
          id: string;
          providerInvoiceItemId: string;
          date: string;
          price: number;
          totalPrice: number;
          quantity: number;
          currency: EnumCurrency;
          description: string;
          taxRates: Array<string | null> | null;
          locale: EnumLocale | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      orders: {
        __typename: "ModelOrderConnection";
        items: Array<{
          __typename: "Order";
          id: string;
          ormOrder: string;
          price: number;
          totalTax: number;
          totalAdjustment: number;
          transactionFeePercent: number | null;
          currency: EnumCurrency;
          channel: EnumChannel;
          timeplaced: string;
          deliveryDate: string | null;
          status: EnumOrderStatus;
          locale: EnumLocale | null;
          metadata: string | null;
          reason: EnumBookingReason | null;
          comment: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      locale: EnumLocale | null;
      metadata: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    locale: EnumLocale | null;
    metadata: string | null;
    reason: EnumBookingReason | null;
    comment: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListPriceUnitVariationsQueryVariables = {
  filter?: ModelPriceUnitVariationFilterInput | null;
  limit?: number | null;
  from?: number | null;
};

export type GetEventQueryVariables = {
  id: string;
};

export type GetEventQuery = {
  getEvent: Event;
};

export type ListEventTypesQuery = {
  listEventTypes: {
    __typename: "ListEventTypeOutput";
    items: Array<{
      __typename: "EventType";
      id: string;
      name: string | null;
      type: string;
      enabled: boolean;
      markForDelete: boolean;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    total: number;
  } | null;
};

export type GetNewsQueryVariables = {
  id: string;
};

export type GetNewsQuery = {
  getNews: News;
};

export type SearchBackOfficeUsersQueryVariables = {
  filter?: SearchableBackOfficeUserFilterInput | null;
  sort?: SearchableBackOfficeUserSortInput | null;
  limit?: number | null;
  from?: number | null;
  nextToken?: string | null;
};

export type SearchBackOfficeUsersQuery = {
  searchBackOfficeUsers: ModelBackOfficeUserConnection;
};

export type SearchAuthorizationsQueryVariables = {
  filter?: SearchableAuthorizationFilterInput | null;
  limit?: number | null;
  from?: number | null;
};

export type SearchAuthorizationsQuery = {
  searchAuthorizations: {
    __typename: "SearchableAuthorizationConnection";
    items: Array<{
      __typename: "Authorization";
      operator: {
        __typename: "Operator";
        id: string;
        shortname: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        organizationName: string | null;
        address: string | null;
        zipCode: string | null;
        city: string | null;
        country: string | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        systemAdminPolicy: string;
        systemAdminRole: string;
        systemUserPolicy: string;
        systemUserRole: string;
        operatorAdminPolicy: string;
        operatorAdminRole: string;
        operatorUserPolicy: string;
        operatorUserRole: string;
        centerAdminPolicy: string;
        centerAdminRole: string;
        centerUserPolicy: string;
        centerUserRole: string;
        tenantAdminPolicy: string;
        tenantAdminRole: string;
        tenantUserPolicy: string;
        tenantUserRole: string;
        userPolicy: string;
        userRole: string;
        anonymousPolicy: string;
        anonymousRole: string;
        trustRole: string;
        tier: string;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      id: string;
      user: {
        __typename: "BackOfficeUser";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        role: EnumBackOfficeUserRole;
        picture: string | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        tier: string;
        clientId: string;
        username: string | null;
        shortname: string | null;
        locale: EnumLocale | null;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      entityType: EnumPermissionEntity;
      entityId: string;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
    total: number | null;
  } | null;
};

export type SearchUsersQueryVariables = {
  filter?: SearchableUserFilterInput | null;
  sort?: SearchableUserSortInput | null;
  limit?: number | null;
  from?: number | null;
  nextToken?: string | null;
};

export type SearchUsersQuery = {
  searchUsers: {
    __typename: "SearchableUserConnection";
    items: Array<{
      __typename: "User";
      operator: {
        __typename: "Operator";
        id: string;
        shortname: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        organizationName: string | null;
        address: string | null;
        zipCode: string | null;
        city: string | null;
        country: string | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        systemAdminPolicy: string;
        systemAdminRole: string;
        systemUserPolicy: string;
        systemUserRole: string;
        operatorAdminPolicy: string;
        operatorAdminRole: string;
        operatorUserPolicy: string;
        operatorUserRole: string;
        centerAdminPolicy: string;
        centerAdminRole: string;
        centerUserPolicy: string;
        centerUserRole: string;
        tenantAdminPolicy: string;
        tenantAdminRole: string;
        tenantUserPolicy: string;
        tenantUserRole: string;
        userPolicy: string;
        userRole: string;
        anonymousPolicy: string;
        anonymousRole: string;
        trustRole: string;
        tier: string;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      id: string;
      firstname: string | null;
      lastname: string | null;
      email: string;
      phone: string | null;
      role: EnumUserRole;
      picture: string | null;
      globalOrders: {
        __typename: "ModelGlobalOrderConnection";
        items: Array<{
          __typename: "GlobalOrder";
          id: string;
          ormOrder: string;
          price: number;
          totalTax: number;
          totalAdjustment: number;
          currency: EnumCurrency;
          channel: EnumChannel;
          timeplaced: string;
          paid: boolean;
          captured: boolean;
          status: EnumOrderStatus;
          calendarEventId: string | null;
          locale: EnumLocale | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      invoices: {
        __typename: "ModelInvoiceConnection";
        items: Array<{
          __typename: "Invoice";
          id: string;
          providerInvoiceId: string;
          date: string;
          status: EnumInvoiceStatus;
          amountDue: number;
          amountPaid: number;
          amountRemaining: number;
          currency: EnumCurrency;
          totalPrice: number;
          description: string | null;
          invoiceUrl: string | null;
          invoicePdf: string | null;
          paid: boolean;
          endOfMonth: boolean;
          locale: EnumLocale | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      unavailabilities: {
        __typename: "ModelUnavailabilityConnection";
        items: Array<{
          __typename: "Unavailability";
          id: string;
          type: number;
          start: string;
          end: string;
          status: EnumAvailabilityStatus;
          relatedUnavailabilityId: string | null;
          locale: EnumLocale | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      center: {
        __typename: "Center";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        type: {
          __typename: "CenterType";
          id: string;
          name: string | null;
          type: string;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null;
        name: string;
        description: string | null;
        address: string;
        zipCode: string;
        city: string;
        country: string;
        location: {
          __typename: "GPS";
          lat: number | null;
          lon: number | null;
        } | null;
        pictures: Array<string | null> | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        daySlots: {
          __typename: "ModelDaySlotConnection";
          nextToken: string | null;
        } | null;
        closedDays: {
          __typename: "ModelClosedDayConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        phone: string | null;
        email: string | null;
        services: {
          __typename: "ModelServiceConnection";
          nextToken: string | null;
        } | null;
        tenantServices: {
          __typename: "ModelTenantServiceConnection";
          nextToken: string | null;
        } | null;
        products: {
          __typename: "ModelProductConnection";
          nextToken: string | null;
        } | null;
        providers: {
          __typename: "ModelProviderConnection";
          nextToken: string | null;
        } | null;
        events: {
          __typename: "SearchEventsOutput";
          nextToken: string | null;
        } | null;
        eventTypes: {
          __typename: "ListEventTypeOutput";
          nextToken: string | null;
        } | null;
        news: {
          __typename: "ModelNewsConnection";
          nextToken: string | null;
        } | null;
        unavailabilities: {
          __typename: "ModelUnavailabilityConnection";
          nextToken: string | null;
        } | null;
        tenants: {
          __typename: "ModelCenterTenantConnection";
          nextToken: string | null;
        } | null;
        notifications: {
          __typename: "ModelNotificationConnection";
          nextToken: string | null;
        } | null;
        categories: {
          __typename: "ModelCategoryConnection";
          nextToken: string | null;
        } | null;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      tenant: {
        __typename: "Tenant";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        type: {
          __typename: "TenantType";
          id: string;
          name: string | null;
          type: string;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null;
        name: string;
        description: string | null;
        address: string;
        zipCode: string;
        city: string;
        country: string;
        phone: string | null;
        email: string | null;
        daySlots: {
          __typename: "ModelDaySlotConnection";
          nextToken: string | null;
        } | null;
        closedDays: {
          __typename: "ModelClosedDayConnection";
          nextToken: string | null;
        } | null;
        pictures: Array<string | null> | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        centers: {
          __typename: "ModelCenterTenantConnection";
          nextToken: string | null;
        } | null;
        events: {
          __typename: "SearchEventsOutput";
          nextToken: string | null;
        } | null;
        eventTypes: {
          __typename: "ListEventTypeOutput";
          nextToken: string | null;
        } | null;
        news: {
          __typename: "ModelNewsConnection";
          nextToken: string | null;
        } | null;
        authorized: boolean | null;
        enabled: boolean;
        markForDelete: boolean;
        unavailabilities: {
          __typename: "ModelUnavailabilityConnection";
          nextToken: string | null;
        } | null;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      attributes: {
        __typename: "ModelAttributeConnection";
        items: Array<{
          __typename: "Attribute";
          id: string;
          booleanValue: boolean | null;
          stringValue: string | null;
          value: string;
          jsonValue: string | null;
          numberValue: number | null;
          urlValue: string | null;
          dateValue: string | null;
          selectValue: string | null;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      enabled: boolean;
      markForDelete: boolean;
      userPoolId: string;
      identityPoolId: string;
      tier: string;
      clientId: string;
      username: string | null;
      shortname: string | null;
      locale: EnumLocale | null;
      metadata: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
    total: number | null;
  } | null;
};

export type SearchCentersQueryVariables = {
  filter?: SearchableCenterFilterInput | null;
  limit?: number | null;
  from?: number | null;
  locale?: EnumLocale | null;
};

export type SearchCentersQuery = {
  searchCenters: {
    __typename: "SearchableCenterConnection";
    items: Array<{
      __typename: "Center";
      operator: {
        __typename: "Operator";
        id: string;
        shortname: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        organizationName: string | null;
        address: string | null;
        zipCode: string | null;
        city: string | null;
        country: string | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        systemAdminPolicy: string;
        systemAdminRole: string;
        systemUserPolicy: string;
        systemUserRole: string;
        operatorAdminPolicy: string;
        operatorAdminRole: string;
        operatorUserPolicy: string;
        operatorUserRole: string;
        centerAdminPolicy: string;
        centerAdminRole: string;
        centerUserPolicy: string;
        centerUserRole: string;
        tenantAdminPolicy: string;
        tenantAdminRole: string;
        tenantUserPolicy: string;
        tenantUserRole: string;
        userPolicy: string;
        userRole: string;
        anonymousPolicy: string;
        anonymousRole: string;
        trustRole: string;
        tier: string;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      id: string;
      type: {
        __typename: "CenterType";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        name: string | null;
        type: string;
        enabled: boolean;
        markForDelete: boolean;
        createdAt: string;
        updatedAt: string;
      } | null;
      name: string;
      description: string | null;
      address: string;
      zipCode: string;
      city: string;
      country: string;
      location: {
        __typename: "GPS";
        lat: number | null;
        lon: number | null;
      } | null;
      pictures: Array<string | null> | null;
      attributes: {
        __typename: "ModelAttributeConnection";
        items: Array<{
          __typename: "Attribute";
          id: string;
          booleanValue: boolean | null;
          stringValue: string | null;
          value: string;
          jsonValue: string | null;
          numberValue: number | null;
          urlValue: string | null;
          dateValue: string | null;
          selectValue: string | null;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      daySlots: {
        __typename: "ModelDaySlotConnection";
        items: Array<{
          __typename: "DaySlot";
          id: string;
          name: string | null;
          day: string;
          dayBreak: string | null;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      closedDays: {
        __typename: "ModelClosedDayConnection";
        items: Array<{
          __typename: "ClosedDay";
          id: string;
          start: string;
          end: string;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      enabled: boolean;
      markForDelete: boolean;
      phone: string | null;
      email: string | null;
      services: {
        __typename: "ModelServiceConnection";
        items: Array<{
          __typename: "Service";
          id: string;
          type: EnumServiceType;
          name: string;
          description: string | null;
          pictures: Array<string | null> | null;
          authorized: boolean | null;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      tenantServices: {
        __typename: "ModelTenantServiceConnection";
        items: Array<{
          __typename: "TenantService";
          id: string;
          name: string;
          description: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      products: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          type: EnumProductType;
          sku: string;
          name: string;
          typology: EnumProductTypology;
          description: string | null;
          pictures: Array<string | null> | null;
          vat: number;
          capacity: number | null;
          stock: number;
          authorized: boolean | null;
          enabled: boolean;
          markForDelete: boolean;
          flexible: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      providers: {
        __typename: "ModelProviderConnection";
        items: Array<{
          __typename: "Provider";
          id: string;
          name: string;
          description: string | null;
          address: string;
          zipCode: string;
          city: string;
          country: string;
          phone: string | null;
          email: string | null;
          pictures: Array<string | null> | null;
          authorized: boolean | null;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      events: {
        __typename: "SearchEventsOutput";
        items: Array<{
          __typename: "Event";
          id: string;
          title: string;
          description: string | null;
          picture: string | null;
          start: string;
          end: string;
          authorized: boolean | null;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      eventTypes: {
        __typename: "ListEventTypeOutput";
        items: Array<{
          __typename: "EventType";
          id: string;
          name: string | null;
          type: string;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      news: {
        __typename: "ModelNewsConnection";
        items: Array<{
          __typename: "News";
          id: string;
          title: string;
          description: string;
          picture: string | null;
          publicationDate: string | null;
          unpublicationDate: string | null;
          authorized: boolean | null;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      unavailabilities: {
        __typename: "ModelUnavailabilityConnection";
        items: Array<{
          __typename: "Unavailability";
          id: string;
          type: number;
          start: string;
          end: string;
          status: EnumAvailabilityStatus;
          relatedUnavailabilityId: string | null;
          locale: EnumLocale | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      tenants: {
        __typename: "ModelCenterTenantConnection";
        items: Array<{
          __typename: "CenterTenant";
          id: string;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      notifications: {
        __typename: "ModelNotificationConnection";
        items: Array<{
          __typename: "Notification";
          id: string;
          type: EnumNotificationType;
          entity: string;
          description: string;
          enabled: boolean;
          markForDelete: boolean;
          creationDate: string;
          viewDate: string | null;
          locale: EnumLocale | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      categories: {
        __typename: "ModelCategoryConnection";
        items: Array<{
          __typename: "Category";
          id: string;
          type: EnumCategoryType;
          name: string;
          description: string | null;
          picture: string | null;
          level: number | null;
          position: number;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      metadata: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
    total: number | null;
  } | null;
};

export type SearchServicesQuery = {
  searchServices: {
    __typename: "SearchableServiceConnection";
    items: Array<{
      __typename: "Service";
      operator: {
        __typename: "Operator";
        id: string;
        shortname: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        organizationName: string | null;
        address: string | null;
        zipCode: string | null;
        city: string | null;
        country: string | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        systemAdminPolicy: string;
        systemAdminRole: string;
        systemUserPolicy: string;
        systemUserRole: string;
        operatorAdminPolicy: string;
        operatorAdminRole: string;
        operatorUserPolicy: string;
        operatorUserRole: string;
        centerAdminPolicy: string;
        centerAdminRole: string;
        centerUserPolicy: string;
        centerUserRole: string;
        tenantAdminPolicy: string;
        tenantAdminRole: string;
        tenantUserPolicy: string;
        tenantUserRole: string;
        userPolicy: string;
        userRole: string;
        anonymousPolicy: string;
        anonymousRole: string;
        trustRole: string;
        tier: string;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      id: string;
      type: EnumServiceType;
      name: string;
      description: string | null;
      pictures: Array<string | null> | null;
      category: {
        __typename: "Category";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        type: EnumCategoryType;
        name: string;
        description: string | null;
        picture: string | null;
        level: number | null;
        position: number;
        center: {
          __typename: "Center";
          id: string;
          name: string;
          description: string | null;
          address: string;
          zipCode: string;
          city: string;
          country: string;
          pictures: Array<string | null> | null;
          enabled: boolean;
          markForDelete: boolean;
          phone: string | null;
          email: string | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        parent: {
          __typename: "Category";
          id: string;
          type: EnumCategoryType;
          name: string;
          description: string | null;
          picture: string | null;
          level: number | null;
          position: number;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        categories: {
          __typename: "ModelCategoryConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      products: {
        __typename: "ModelProductConnection";
        items: Array<{
          __typename: "Product";
          id: string;
          type: EnumProductType;
          sku: string;
          name: string;
          typology: EnumProductTypology;
          description: string | null;
          pictures: Array<string | null> | null;
          vat: number;
          capacity: number | null;
          stock: number;
          authorized: boolean | null;
          enabled: boolean;
          markForDelete: boolean;
          flexible: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      attributes: {
        __typename: "ModelAttributeConnection";
        items: Array<{
          __typename: "Attribute";
          id: string;
          booleanValue: boolean | null;
          stringValue: string | null;
          value: string;
          jsonValue: string | null;
          numberValue: number | null;
          urlValue: string | null;
          dateValue: string | null;
          selectValue: string | null;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      authorized: boolean | null;
      enabled: boolean;
      markForDelete: boolean;
      metadata: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
    total: number | null;
  } | null;
};

export type SearchCategoriesQueryVariables = {
  filter?: SearchableCategoryFilterInput | null;
  sort?: SearchableCategorySortInput | null;
  limit?: number | null;
};

export type SearchProvidersQueryVariables = {
  filter?: SearchableProviderFilterInput | null;
  sort?: SearchProvidersSortInput | null;
  limit?: number | null;
  from?: number | null;
  nextToken?: string | null;
  locale?: EnumLocale | null;
};

export type SearchProvidersQuery = {
  searchProviders: {
    __typename: "SearchableProviderConnection";
    items: Array<{
      __typename: "Provider";
      operator: {
        __typename: "Operator";
        id: string;
        shortname: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        organizationName: string | null;
        address: string | null;
        zipCode: string | null;
        city: string | null;
        country: string | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        systemAdminPolicy: string;
        systemAdminRole: string;
        systemUserPolicy: string;
        systemUserRole: string;
        operatorAdminPolicy: string;
        operatorAdminRole: string;
        operatorUserPolicy: string;
        operatorUserRole: string;
        centerAdminPolicy: string;
        centerAdminRole: string;
        centerUserPolicy: string;
        centerUserRole: string;
        tenantAdminPolicy: string;
        tenantAdminRole: string;
        tenantUserPolicy: string;
        tenantUserRole: string;
        userPolicy: string;
        userRole: string;
        anonymousPolicy: string;
        anonymousRole: string;
        trustRole: string;
        tier: string;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      id: string;
      name: string;
      description: string | null;
      address: string;
      zipCode: string;
      city: string;
      country: string;
      phone: string | null;
      email: string | null;
      pictures: Array<string | null> | null;
      attributes: {
        __typename: "ModelAttributeConnection";
        items: Array<{
          __typename: "Attribute";
          id: string;
          booleanValue: boolean | null;
          stringValue: string | null;
          value: string;
          jsonValue: string | null;
          numberValue: number | null;
          urlValue: string | null;
          dateValue: string | null;
          selectValue: string | null;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      authorized: boolean | null;
      enabled: boolean;
      markForDelete: boolean;
      metadata: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
    total: number | null;
  } | null;
};

export type SearchProductsQueryVariables = {
  filter?: SearchableProductFilterInput | null;
  sort?: SearchableProductSortInput | null;
  limit?: number | null;
  from?: number | null;
  nextToken?: string | null;
};

export type SearchAttributeKeysQueryVariables = {
  filter?: SearchableAttributeKeyFilterInput | null;
  sort?: SearchableAttributeKeySortInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SearchUnavailabilitysQueryVariables = {
  filter?: SearchableUnavailabilityFilterInput | null;
  sort?: SearchableUnavailabilitySortInput | null;
  limit?: number | null;
  from?: number | null;
  nextToken?: string | null;
};

export type SearchUnavailabilitiesQuery = {
  searchUnavailabilities: {
    __typename: "SearchableUnavailabilityConnection";
    items: Array<{
      __typename: "Unavailability";
      operator: {
        __typename: "Operator";
        id: string;
        shortname: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        organizationName: string | null;
        address: string | null;
        zipCode: string | null;
        city: string | null;
        country: string | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        systemAdminPolicy: string;
        systemAdminRole: string;
        systemUserPolicy: string;
        systemUserRole: string;
        operatorAdminPolicy: string;
        operatorAdminRole: string;
        operatorUserPolicy: string;
        operatorUserRole: string;
        centerAdminPolicy: string;
        centerAdminRole: string;
        centerUserPolicy: string;
        centerUserRole: string;
        tenantAdminPolicy: string;
        tenantAdminRole: string;
        tenantUserPolicy: string;
        tenantUserRole: string;
        userPolicy: string;
        userRole: string;
        anonymousPolicy: string;
        anonymousRole: string;
        trustRole: string;
        tier: string;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      id: string;
      type: number;
      start: string;
      end: string;
      status: EnumAvailabilityStatus;
      product: {
        __typename: "Product";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        center: {
          __typename: "Center";
          id: string;
          name: string;
          description: string | null;
          address: string;
          zipCode: string;
          city: string;
          country: string;
          pictures: Array<string | null> | null;
          enabled: boolean;
          markForDelete: boolean;
          phone: string | null;
          email: string | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        type: EnumProductType;
        sku: string;
        name: string;
        typology: EnumProductTypology;
        description: string | null;
        pictures: Array<string | null> | null;
        category: {
          __typename: "Category";
          id: string;
          type: EnumCategoryType;
          name: string;
          description: string | null;
          picture: string | null;
          level: number | null;
          position: number;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        daySlots: {
          __typename: "ModelDaySlotConnection";
          nextToken: string | null;
        } | null;
        closedDays: {
          __typename: "ModelClosedDayConnection";
          nextToken: string | null;
        } | null;
        priceUnits: {
          __typename: "ModelPriceUnitConnection";
          nextToken: string | null;
        } | null;
        unavailabilities: {
          __typename: "ModelUnavailabilityConnection";
          nextToken: string | null;
        } | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        provider: {
          __typename: "Provider";
          id: string;
          name: string;
          description: string | null;
          address: string;
          zipCode: string;
          city: string;
          country: string;
          phone: string | null;
          email: string | null;
          pictures: Array<string | null> | null;
          authorized: boolean | null;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        options: {
          __typename: "ModelOptionConnection";
          nextToken: string | null;
        } | null;
        products: {
          __typename: "ModelSingleProductConnection";
          nextToken: string | null;
        } | null;
        vat: number;
        capacity: number | null;
        stock: number;
        authorized: boolean | null;
        enabled: boolean;
        markForDelete: boolean;
        flexible: boolean;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      user: {
        __typename: "User";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        firstname: string | null;
        lastname: string | null;
        email: string;
        phone: string | null;
        role: EnumUserRole;
        picture: string | null;
        globalOrders: {
          __typename: "ModelGlobalOrderConnection";
          nextToken: string | null;
        } | null;
        invoices: {
          __typename: "ModelInvoiceConnection";
          nextToken: string | null;
        } | null;
        unavailabilities: {
          __typename: "ModelUnavailabilityConnection";
          nextToken: string | null;
        } | null;
        center: {
          __typename: "Center";
          id: string;
          name: string;
          description: string | null;
          address: string;
          zipCode: string;
          city: string;
          country: string;
          pictures: Array<string | null> | null;
          enabled: boolean;
          markForDelete: boolean;
          phone: string | null;
          email: string | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tenant: {
          __typename: "Tenant";
          id: string;
          name: string;
          description: string | null;
          address: string;
          zipCode: string;
          city: string;
          country: string;
          phone: string | null;
          email: string | null;
          pictures: Array<string | null> | null;
          authorized: boolean | null;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        tier: string;
        clientId: string;
        username: string | null;
        shortname: string | null;
        locale: EnumLocale | null;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      center: {
        __typename: "Center";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        type: {
          __typename: "CenterType";
          id: string;
          name: string | null;
          type: string;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null;
        name: string;
        description: string | null;
        address: string;
        zipCode: string;
        city: string;
        country: string;
        location: {
          __typename: "GPS";
          lat: number | null;
          lon: number | null;
        } | null;
        pictures: Array<string | null> | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        daySlots: {
          __typename: "ModelDaySlotConnection";
          nextToken: string | null;
        } | null;
        closedDays: {
          __typename: "ModelClosedDayConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        phone: string | null;
        email: string | null;
        services: {
          __typename: "ModelServiceConnection";
          nextToken: string | null;
        } | null;
        tenantServices: {
          __typename: "ModelTenantServiceConnection";
          nextToken: string | null;
        } | null;
        products: {
          __typename: "ModelProductConnection";
          nextToken: string | null;
        } | null;
        providers: {
          __typename: "ModelProviderConnection";
          nextToken: string | null;
        } | null;
        events: {
          __typename: "SearchEventsOutput";
          nextToken: string | null;
        } | null;
        eventTypes: {
          __typename: "ListEventTypeOutput";
          nextToken: string | null;
        } | null;
        news: {
          __typename: "ModelNewsConnection";
          nextToken: string | null;
        } | null;
        unavailabilities: {
          __typename: "ModelUnavailabilityConnection";
          nextToken: string | null;
        } | null;
        tenants: {
          __typename: "ModelCenterTenantConnection";
          nextToken: string | null;
        } | null;
        notifications: {
          __typename: "ModelNotificationConnection";
          nextToken: string | null;
        } | null;
        categories: {
          __typename: "ModelCategoryConnection";
          nextToken: string | null;
        } | null;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      tenant: {
        __typename: "Tenant";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        type: {
          __typename: "TenantType";
          id: string;
          name: string | null;
          type: string;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null;
        name: string;
        description: string | null;
        address: string;
        zipCode: string;
        city: string;
        country: string;
        phone: string | null;
        email: string | null;
        daySlots: {
          __typename: "ModelDaySlotConnection";
          nextToken: string | null;
        } | null;
        closedDays: {
          __typename: "ModelClosedDayConnection";
          nextToken: string | null;
        } | null;
        pictures: Array<string | null> | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        centers: {
          __typename: "ModelCenterTenantConnection";
          nextToken: string | null;
        } | null;
        events: {
          __typename: "SearchEventsOutput";
          nextToken: string | null;
        } | null;
        eventTypes: {
          __typename: "ListEventTypeOutput";
          nextToken: string | null;
        } | null;
        news: {
          __typename: "ModelNewsConnection";
          nextToken: string | null;
        } | null;
        authorized: boolean | null;
        enabled: boolean;
        markForDelete: boolean;
        unavailabilities: {
          __typename: "ModelUnavailabilityConnection";
          nextToken: string | null;
        } | null;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      orderItem: {
        __typename: "OrderItem";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        product: string;
        sku: string;
        name: string;
        status: EnumOrderStatus;
        description: string | null;
        pictures: Array<string | null> | null;
        category: string | null;
        provider: string;
        totalPrice: number;
        totalTax: number;
        totalAdjustment: number;
        amountTransfered: number | null;
        price: number;
        currency: EnumCurrency;
        unit: EnumUnit;
        increment: number;
        quantity: number;
        unitQuantity: number | null;
        unitPrice: number | null;
        unitTax: number | null;
        unitAdjustment: number | null;
        priceUnit: string;
        variation: string;
        order: {
          __typename: "Order";
          id: string;
          ormOrder: string;
          price: number;
          totalTax: number;
          totalAdjustment: number;
          transactionFeePercent: number | null;
          currency: EnumCurrency;
          channel: EnumChannel;
          timeplaced: string;
          deliveryDate: string | null;
          status: EnumOrderStatus;
          locale: EnumLocale | null;
          metadata: string | null;
          reason: EnumBookingReason | null;
          comment: string | null;
          createdAt: string;
          updatedAt: string;
        };
        unavailability: {
          __typename: "Unavailability";
          id: string;
          type: number;
          start: string;
          end: string;
          status: EnumAvailabilityStatus;
          relatedUnavailabilityId: string | null;
          locale: EnumLocale | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        vat: number;
        refunded: boolean;
        relatedOrderItemId: string | null;
        refund: {
          __typename: "Refund";
          id: string;
          refundId: string;
          amount: number;
          date: string;
          status: string;
          reason: string | null;
          refundApplicationFee: boolean | null;
          reverseTransfer: boolean | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        invoiceItem: {
          __typename: "InvoiceItem";
          id: string;
          providerInvoiceItemId: string;
          date: string;
          price: number;
          totalPrice: number;
          quantity: number;
          currency: EnumCurrency;
          description: string;
          taxRates: Array<string | null> | null;
          locale: EnumLocale | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        locale: EnumLocale | null;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      attributes: {
        __typename: "ModelAttributeConnection";
        items: Array<{
          __typename: "Attribute";
          id: string;
          booleanValue: boolean | null;
          stringValue: string | null;
          value: string;
          jsonValue: string | null;
          numberValue: number | null;
          urlValue: string | null;
          dateValue: string | null;
          selectValue: string | null;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      relatedUnavailabilityId: string | null;
      locale: EnumLocale | null;
      metadata: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
    total: number | null;
  } | null;
};

export type SearchGlobalOrdersQuery = {
  searchGlobalOrders: ModelGlobalOrderConnection;
};
export type SearchEventsQuery = {
  searchEvents: {
    __typename: "SearchableEventConnection";
    items: Array<{
      __typename: "Event";
      id: string;
      title: string;
      description: string | null;
      author: {
        __typename: "BackOfficeUser";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        role: EnumBackOfficeUserRole;
        picture: string | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        tier: string;
        clientId: string;
        username: string | null;
        shortname: string | null;
        locale: EnumLocale | null;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      picture: string | null;
      start: string;
      end: string;
      type: {
        __typename: "EventType";
        id: string;
        name: string | null;
        type: string;
        enabled: boolean;
        markForDelete: boolean;
        createdAt: string;
        updatedAt: string;
      };
      likes: {
        __typename: "ModelLikeConnection";
        items: Array<{
          __typename: "Like";
          id: string;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      participations: {
        __typename: "ModelParticipationConnection";
        items: Array<{
          __typename: "Participation";
          id: string;
          value: EnumParticipation;
          createdAt: string;
          updatedAt: string;
        } | null> | null;
        nextToken: string | null;
      } | null;
      authorized: boolean | null;
      enabled: boolean;
      markForDelete: boolean;
      metadata: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    total: number | null;
  } | null;
};

export type SearchParticipationsQueryVariables = {
  filter?: SearchableParticipationFilterInput | null;
  sort?: SearchableParticipationSortInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SearchParticipationsQuery = {
  searchParticipations: {
    __typename: "SearchableParticipationConnection";
    items: Array<{
      __typename: "Participation";
      operator: {
        __typename: "Operator";
        id: string;
        shortname: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        organizationName: string | null;
        address: string | null;
        zipCode: string | null;
        city: string | null;
        country: string | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        systemAdminPolicy: string;
        systemAdminRole: string;
        systemUserPolicy: string;
        systemUserRole: string;
        operatorAdminPolicy: string;
        operatorAdminRole: string;
        operatorUserPolicy: string;
        operatorUserRole: string;
        centerAdminPolicy: string;
        centerAdminRole: string;
        centerUserPolicy: string;
        centerUserRole: string;
        tenantAdminPolicy: string;
        tenantAdminRole: string;
        tenantUserPolicy: string;
        tenantUserRole: string;
        userPolicy: string;
        userRole: string;
        anonymousPolicy: string;
        anonymousRole: string;
        trustRole: string;
        tier: string;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      id: string;
      user: {
        __typename: "User";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        firstname: string | null;
        lastname: string | null;
        email: string;
        phone: string | null;
        role: EnumUserRole;
        picture: string | null;
        globalOrders: {
          __typename: "ModelGlobalOrderConnection";
          nextToken: string | null;
        } | null;
        invoices: {
          __typename: "ModelInvoiceConnection";
          nextToken: string | null;
        } | null;
        unavailabilities: {
          __typename: "ModelUnavailabilityConnection";
          nextToken: string | null;
        } | null;
        center: {
          __typename: "Center";
          id: string;
          name: string;
          description: string | null;
          address: string;
          zipCode: string;
          city: string;
          country: string;
          pictures: Array<string | null> | null;
          enabled: boolean;
          markForDelete: boolean;
          phone: string | null;
          email: string | null;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        tenant: {
          __typename: "Tenant";
          id: string;
          name: string;
          description: string | null;
          address: string;
          zipCode: string;
          city: string;
          country: string;
          phone: string | null;
          email: string | null;
          pictures: Array<string | null> | null;
          authorized: boolean | null;
          enabled: boolean;
          markForDelete: boolean;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        } | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        tier: string;
        clientId: string;
        username: string | null;
        shortname: string | null;
        locale: EnumLocale | null;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      value: EnumParticipation;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
    total: number | null;
  } | null;
};

export type SearchNewsQueryVariables = {
  filter?: SearchableNewsFilterInput | null;
  limit?: number | null;
};

export type SearchNewsQuery = {
  searchNews: {
    __typename: "SearchableNewsConnection";
    items: Array<{
      __typename: "News";
      operator: {
        __typename: "Operator";
        id: string;
        shortname: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        organizationName: string | null;
        address: string | null;
        zipCode: string | null;
        city: string | null;
        country: string | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        systemAdminPolicy: string;
        systemAdminRole: string;
        systemUserPolicy: string;
        systemUserRole: string;
        operatorAdminPolicy: string;
        operatorAdminRole: string;
        operatorUserPolicy: string;
        operatorUserRole: string;
        centerAdminPolicy: string;
        centerAdminRole: string;
        centerUserPolicy: string;
        centerUserRole: string;
        tenantAdminPolicy: string;
        tenantAdminRole: string;
        tenantUserPolicy: string;
        tenantUserRole: string;
        userPolicy: string;
        userRole: string;
        anonymousPolicy: string;
        anonymousRole: string;
        trustRole: string;
        tier: string;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      id: string;
      title: string;
      description: string;
      picture: string | null;
      author: {
        __typename: "BackOfficeUser";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        role: EnumBackOfficeUserRole;
        picture: string | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        tier: string;
        clientId: string;
        username: string | null;
        shortname: string | null;
        locale: EnumLocale | null;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      publicationDate: string | null;
      unpublicationDate: string | null;
      authorized: boolean | null;
      enabled: boolean;
      markForDelete: boolean;
      metadata: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
    total: number | null;
  } | null;
};

export type SearchNotificationsQueryVariables = {
  filter?: SearchNotificationsFilterInput | null;
  sort?: SearchNotificationsSortInput | null;
  limit?: number | null;
  from?: number | null;
  nextToken?: string | null;
};

export type SearchNotificationsQuery = {
  searchNotifications: {
    __typename: "SearchableNotificationConnection";
    items: Array<{
      __typename: "Notification";
      operator: {
        __typename: "Operator";
        id: string;
        shortname: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        organizationName: string | null;
        address: string | null;
        zipCode: string | null;
        city: string | null;
        country: string | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        systemAdminPolicy: string;
        systemAdminRole: string;
        systemUserPolicy: string;
        systemUserRole: string;
        operatorAdminPolicy: string;
        operatorAdminRole: string;
        operatorUserPolicy: string;
        operatorUserRole: string;
        centerAdminPolicy: string;
        centerAdminRole: string;
        centerUserPolicy: string;
        centerUserRole: string;
        tenantAdminPolicy: string;
        tenantAdminRole: string;
        tenantUserPolicy: string;
        tenantUserRole: string;
        userPolicy: string;
        userRole: string;
        anonymousPolicy: string;
        anonymousRole: string;
        trustRole: string;
        tier: string;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      id: string;
      type: EnumNotificationType;
      entity: string;
      description: string;
      enabled: boolean;
      markForDelete: boolean;
      creationDate: string;
      center: {
        __typename: "Center";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        type: {
          __typename: "CenterType";
          id: string;
          name: string | null;
          type: string;
          enabled: boolean;
          markForDelete: boolean;
          createdAt: string;
          updatedAt: string;
        } | null;
        name: string;
        description: string | null;
        address: string;
        zipCode: string;
        city: string;
        country: string;
        location: {
          __typename: "GPS";
          lat: number | null;
          lon: number | null;
        } | null;
        pictures: Array<string | null> | null;
        attributes: {
          __typename: "ModelAttributeConnection";
          nextToken: string | null;
        } | null;
        daySlots: {
          __typename: "ModelDaySlotConnection";
          nextToken: string | null;
        } | null;
        closedDays: {
          __typename: "ModelClosedDayConnection";
          nextToken: string | null;
        } | null;
        enabled: boolean;
        markForDelete: boolean;
        phone: string | null;
        email: string | null;
        services: {
          __typename: "ModelServiceConnection";
          nextToken: string | null;
        } | null;
        tenantServices: {
          __typename: "ModelTenantServiceConnection";
          nextToken: string | null;
        } | null;
        products: {
          __typename: "ModelProductConnection";
          nextToken: string | null;
        } | null;
        providers: {
          __typename: "ModelProviderConnection";
          nextToken: string | null;
        } | null;
        events: {
          __typename: "SearchEventsOutput";
          nextToken: string | null;
        } | null;
        eventTypes: {
          __typename: "ListEventTypeOutput";
          nextToken: string | null;
        } | null;
        news: {
          __typename: "ModelNewsConnection";
          nextToken: string | null;
        } | null;
        unavailabilities: {
          __typename: "ModelUnavailabilityConnection";
          nextToken: string | null;
        } | null;
        tenants: {
          __typename: "ModelCenterTenantConnection";
          nextToken: string | null;
        } | null;
        notifications: {
          __typename: "ModelNotificationConnection";
          nextToken: string | null;
        } | null;
        categories: {
          __typename: "ModelCategoryConnection";
          nextToken: string | null;
        } | null;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
      viewDate: string | null;
      viewer: {
        __typename: "BackOfficeUser";
        operator: {
          __typename: "Operator";
          id: string;
          shortname: string;
          firstname: string;
          lastname: string;
          email: string;
          phone: string | null;
          organizationName: string | null;
          address: string | null;
          zipCode: string | null;
          city: string | null;
          country: string | null;
          enabled: boolean;
          markForDelete: boolean;
          userPoolId: string;
          identityPoolId: string;
          systemAdminPolicy: string;
          systemAdminRole: string;
          systemUserPolicy: string;
          systemUserRole: string;
          operatorAdminPolicy: string;
          operatorAdminRole: string;
          operatorUserPolicy: string;
          operatorUserRole: string;
          centerAdminPolicy: string;
          centerAdminRole: string;
          centerUserPolicy: string;
          centerUserRole: string;
          tenantAdminPolicy: string;
          tenantAdminRole: string;
          tenantUserPolicy: string;
          tenantUserRole: string;
          userPolicy: string;
          userRole: string;
          anonymousPolicy: string;
          anonymousRole: string;
          trustRole: string;
          tier: string;
          metadata: string | null;
          createdAt: string;
          updatedAt: string;
        };
        id: string;
        firstname: string;
        lastname: string;
        email: string;
        phone: string | null;
        role: EnumBackOfficeUserRole;
        picture: string | null;
        enabled: boolean;
        markForDelete: boolean;
        userPoolId: string;
        identityPoolId: string;
        tier: string;
        clientId: string;
        username: string | null;
        shortname: string | null;
        locale: EnumLocale | null;
        metadata: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
      locale: EnumLocale | null;
      metadata: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    nextToken: string | null;
    total: number | null;
  } | null;
};

export interface InvalidParams {
  [fieldName: string]: {
    validations: Array<{
      code: string;
      context?: { [key: string]: string | number };
    }>;
  };
}
