import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import ReactModal from "react-modal";
import { match as Match } from "react-router-dom";
import DuplicateCategoryForm from "./form";
import {
  EnumCategoryType,
  EnumServiceType,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Category } from "../../../../lib/ground-aws-graphql-core/models/Category";

interface Props {
  type: EnumCategoryType;
  serviceType: EnumServiceType;
  toggle: () => void;
  isOpen: boolean;
  match: Match<{ cid: string }>;
  intl: IntlShape;
  category: Category | null;
  onRequestClose?: (e: any) => void;
}

const ModalDuplicateCategory = (props: Props) => {
  const { type, onRequestClose, isOpen } = props;

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      portalClassName="modal-center"
      style={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
      shouldCloseOnOverlayClick
      onRequestClose={onRequestClose}
      className="modal-dialog-xs modal-content overflow-hidden"
    >
      <DuplicateCategoryForm {...props} categoryType={type} />
    </ReactModal>
  );
};

export default injectIntl(ModalDuplicateCategory);
