import React, { useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import overrideClasses from "../../../../../utils/overrideClasses";
import {
  FilterButton,
  FilterReporting,
} from "../../../../../components/Tailwind/Filters";
import { EnumReportingView } from "../../../../../lib/ground-aws-graphql-core/models/Product";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import contextStore from "../../../../../redux/store";
import { getLocale } from "../../../../../lang";
import PageSubheader from "../../../../../components/PageSubheader";
import ReportingView from "../../../../../components/Tailwind/ReportingView";
import { getReportingDates } from "../../../../../utils/filter";
import IncidentCardList from "./incident-card-list";
import ReportingIncidentFilter from "../../../../../components/Tailwind/ReportingIncidentFilter";
import {
  ReportingIncidentReason,
  ReportingIncidentStatus,
  ReportingIncidentType,
} from "../../../../../lib/ground-aws-graphql-core/models/Reporting";
import images from "../../../../../images";
import { getTranslation } from "../../../../../utils/translation";
import {
  getTodayInCenterTimezone,
  getDateInUtcUsingOffset,
  addDuration,
  eachUnitOfInterval,
  isSameDates,
  transformDateForQuery,
  displayDayLabel,
} from "../../../../../utils/config";

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
  backURL: string;
}

const IncidentDashboard = (props: Props): JSX.Element => {
  const { intl, match, backURL } = props;
  const [loading, setLoading] = useState(false);

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  // init weekly view
  const [view, setView] = useState(EnumReportingView.WEEK);

  // init weekly view
  const [status, setStatus] = useState<ReportingIncidentStatus | undefined>();

  // init weekly view
  const [type, setType] = useState<ReportingIncidentType | undefined>();

  // init weekly view
  const [reason, setReason] = useState<ReportingIncidentReason | undefined>();

  const now = getTodayInCenterTimezone(centerTimezone);
  const { current } = getReportingDates(now, view, centerTimezone);

  const [date, setDate] = useState(now);
  const [startDate, setStartDate] = useState(current?.start);
  const [endDate, setEndDate] = useState(current?.end);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const listReportingsIncident = GroundGraphqlContextStore.useStoreActions(
    actions => actions.reporting.listReportingsIncident
  );

  const listReportingsIncidentStatus =
    GroundGraphqlContextStore.useStoreActions(
      actions => actions.reporting.listReportingsIncidentStatus
    );

  const listReportingsIncidentType = GroundGraphqlContextStore.useStoreActions(
    actions => actions.reporting.listReportingsIncidentType
  );

  const listReportingsIncidentReason =
    GroundGraphqlContextStore.useStoreActions(
      actions => actions.reporting.listReportingsIncidentReason
    );

  const reportingsIncident = GroundGraphqlContextStore.useStoreState(
    state => state.reporting.reportingsIncident.items
  );

  const reportingsIncidentStatus = GroundGraphqlContextStore.useStoreState(
    state => state.reporting.reportingsIncidentStatus.items
  );

  const reportingsIncidentType = GroundGraphqlContextStore.useStoreState(
    state => state.reporting.reportingsIncidentType.items
  );

  const reportingsIncidentReason = GroundGraphqlContextStore.useStoreState(
    state => state.reporting.reportingsIncidentReason.items
  );

  let results = reportingsIncident;
  if (status) {
    results = results.filter(s => s.status?.code === status.code);
  } else {
    results = results.filter(s => !s.status);
  }
  if (type) {
    results = results.filter(s => s.type?.code === type.code);
  } else {
    results = results.filter(s => !s.type);
  }
  if (reason) {
    results = results.filter(s => s.reason?.code === reason.code);
  } else {
    results = results.filter(s => !s.reason);
  }

  // already filtered by status, type and reason
  const dataAllForView = results.filter(
    a => a.createdStartDate === null && a.createdEndDate === null
  );

  const days = eachUnitOfInterval(startDate, endDate, "day");

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  useEffect(() => {
    fetchData();
  }, [startDate, view, status, type, reason]);

  useEffect(() => {
    if (type) {
      setLoading(true);
      const incidentType = reportingsIncidentType.find(
        a => a.code === type.code
      );
      Promise.all([
        listReportingsIncidentReason({
          filter: {
            type: incidentType!.id,
          },
        }),
      ]).finally(() => setLoading(false));
    }
  }, [type]);

  const fetchData = () => {
    setLoading(true);

    Promise.all([
      listReportingsIncidentStatus(),
      listReportingsIncidentType(),
      listReportingsIncident({
        filter: {
          buildingId: match.params.cid,
          date: transformDateForQuery(startDate, centerTimezone, view),
          view,
          status: status?.code,
          type: type?.code,
          reason: reason?.code,
        },
      }),
    ]).finally(() => setLoading(false));
  };

  const onChangeDate = (date: Date) => {
    const { current } = getReportingDates(date, view, centerTimezone);
    setDate(date);
    setStartDate(current.start);
    setEndDate(current.end);
  };

  const onPrevious = () => {
    const { previous } = getReportingDates(date, view, centerTimezone);

    setStartDate(previous.start);
    setEndDate(previous.end);
    setDate(previous.start);
  };

  const onNext = () => {
    const { next } = getReportingDates(date, view, centerTimezone);

    setStartDate(next.start);
    setEndDate(next.end);
    setDate(next.start);
  };

  const handleOnChangeReportingView = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const view = e.target.value as EnumReportingView;
    setView(view);
    const { current } = getReportingDates(now, view, centerTimezone);
    setDate(current.start);
    setStartDate(current.start);
    setEndDate(current.end);
  };

  const handleOnChangeReportingStatus = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const status = reportingsIncidentStatus.find(
      r => r.code === parseInt(e.target.value)
    );
    setStatus(status);
  };

  const handleOnChangeReportingType = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const type = reportingsIncidentType.find(r => r.code === e.target.value);
    setType(type);
    setReason(undefined);
  };

  const handleOnChangeReportingReason = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const reason = reportingsIncidentReason.find(
      r => r.code === e.target.value
    );
    setReason(reason);
  };

  const getReportingData = () => {
    const dataByRange = results.filter(
      a => a.createdStartDate && a.createdEndDate
    );

    const data = days.map(date => {
      const value = dataByRange?.find(
        e =>
          isSameDates(
            new Date(e.createdStartDate),
            getDateInUtcUsingOffset(date)
          ) &&
          isSameDates(
            new Date(e.createdEndDate),
            getDateInUtcUsingOffset(addDuration(date, 1, "day"))
          )
      );

      return {
        date: displayDayLabel(date, currentAppLocale, centerTimezone),
        total: value?.total || 0,
        variation: value?.variation || 0,
      };
    });

    return data;
  };

  const options: ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        tools: {
          download: true,
          pan: false,
          reset: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: days.map(d =>
        displayDayLabel(d, currentAppLocale, centerTimezone)
      ),
    },
    colors: ["#309bff"],
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    yaxis: {
      title: {
        text: intl.formatMessage({
          id: `page.dashboards.chart.total.incidents`,
        }),
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toString();
        },
      },
    },
  };

  const getName = () => {
    let name = `${intl.formatMessage({ id: "general.all" })}`;
    if (status || type || reason) {
      name = "";
      if (status) {
        name = name + ` ${getTranslation(status?.label)}`;
      }
      if (type) {
        name = name + ` ${getTranslation(type?.label)}`;
      }
      if (reason) {
        name = name + ` ${getTranslation(reason?.label)}`;
      }
    }

    return name;
  };

  const renderReporting = () => {
    const data = getReportingData();

    const series = [
      {
        name: getName(),
        data: data.map(r => r.total),
      },
    ];

    return (
      <div className="m-10">
        <ReactApexChart
          series={series}
          options={{
            ...options,
            title: {
              text: `${intl.formatMessage({
                id: `page.dashboards.chart.${view.toLowerCase()}.incidents.breakdown.title`,
              })} - ${getName()}`,
              align: "left",
            },
          }}
          type="bar"
          height={500}
        />
      </div>
    );
  };

  return (
    <>
      <div className={overrideClasses({ loading })} />

      <div className={overrideClasses({ hidden: loading })}>
        <Helmet>
          <title>
            {intl.formatMessage({ id: "page.dashboards.document.title" })}
          </title>
        </Helmet>

        <PageSubheader
          goBackEnabled
          goBackId={`btn-back-occupancy-reporting`}
          goBackURL={backURL}
        />

        <div className="flex justify-between px-8">
          <div className="flex items-center gap-4">
            <ReportingView view={view} onChange={handleOnChangeReportingView} />

            <FilterReporting
              date={date}
              view={view}
              locale={currentAppLocale}
              onChangeDate={onChangeDate}
              onPrevious={onPrevious}
              onNext={onNext}
            />

            <FilterButton
              onClick={() => setIsFilterOpen(!isFilterOpen)}
              isOpen={isFilterOpen}
              icon={isFilterOpen ? images.filterOn : images.filterOff}
              title={"page.list.incidents.filter.title"}
            />
          </div>
        </div>

        {isFilterOpen && (
          <div className="flex gap-1 px-8">
            <ReportingIncidentFilter
              values={reportingsIncidentStatus}
              filter="STATUS"
              value={status}
              onChange={handleOnChangeReportingStatus}
            />

            <ReportingIncidentFilter
              values={reportingsIncidentType}
              filter="INCIDENT_TYPE"
              value={type}
              onChange={handleOnChangeReportingType}
            />

            <ReportingIncidentFilter
              values={reportingsIncidentReason}
              filter="INCIDENT_REASON"
              value={reason}
              onChange={handleOnChangeReportingReason}
            />
          </div>
        )}

        <div className="mx-8">
          <IncidentCardList
            {...props}
            data={{
              all: dataAllForView[0],
            }}
          />
          <div id="chart_incidents">{renderReporting()}</div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(IncidentDashboard);
