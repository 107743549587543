import React from "react";
import { FormikTouched, useFormikContext } from "formik";

interface Props<T> {
  field: {
    name: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  };
  onDepend: (
    values: T,
    touched: FormikTouched<T>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => void;
}

const FormDependentField = (props: Props<any>): JSX.Element => {
  const { field, onDepend, ...otherProps } = props;

  const { values, touched, setFieldValue } = useFormikContext();

  onDepend(values, touched, setFieldValue);

  return <input {...otherProps} {...field} />;
};

export default FormDependentField;
