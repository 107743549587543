/*
 *
 * DaySlot Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import { SearchDaySlotsQuery } from "../../api/graphql/types";
import { fetchPolicy } from "../../api/client";

import { searchDaySlots as searchDaySlotsQuery } from "../../api/graphql/queries";
export const searchDaySlots = payload => {
  const request = payload.gql ? payload.gql : searchDaySlotsQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: {
      filter: payload?.filter,
      limit: payload?.limit,
    },
    fetchPolicy,
  }) as {
    data: SearchDaySlotsQuery;
  };
};
