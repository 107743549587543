import React, { useEffect, useState } from "react";
import classnames from "classnames";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Tooltip as ReactToolip } from "react-tippy";
import { Bar, Line } from "react-chartjs-2";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import ReactModal from "react-modal";
import _ from "lodash";
import { Body, Foot, Head, TableContainer } from "../../../components/Table";
import {
  FilterButton,
  FilterCalendar,
} from "../../../components/Tailwind/Filters";
import { getLocale } from "../../../lang";
import contextStore from "../../../redux/store";
import IntlMessages from "../../../utils/messages";
import { getTranslation } from "../../../utils/translation";
import { BodyElement } from "../../../components/Table/types";
import { CloseIcon } from "../../../components/ModalDateRange";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import {
  Booking,
  EnumBookingStatus,
  EnumCalendarType,
  SpaceBookingsCalendar,
  DayCalendar,
  EnumBookableTypology,
  SearchableSpacesBookingsCapacity,
} from "../../../lib/ground-aws-graphql-core/models/Product";
import {
  BookingStatus,
  bookingStatusEnum,
  getCellBackgroundColorFromStatus,
} from "../../../enums/BookingStatusEnum";
import Paginate from "../../../components/Paginate";
import {
  EnumPermissionEntity,
  EnumUnavailabilityType,
} from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import images from "../../../images";
import { EnumBookingFrom, EnumPaths } from "../../../utils/navigation";
import { GroundAuthContextStore } from "../../../lib/ground-aws-cognito-auth-core";
import { getOperatorConfiguration } from "../../../lib/operator-configuration";
import { isDisplayable } from "../../../lib/operator-configuration/backoffice/display";
import history from "../../../history";
import Event from "../../../utils/tracking-event";
import overrideClasses from "../../../utils/overrideClasses";
import { capacityValuesList, EnumFilterFields } from "../../../utils/filter";
import CalendarFilter from "../../../components/Tailwind/Filters/calendar";
import { Privatization } from "../../../redux/models/Privatization";
import { canEditEnterprise } from "../../../utils/types";
import {
  getBookingInBuildingTZ,
  getTodayInCenterTimezone,
  getCurrentWeekInCenterTimezone,
  getWeekInCenterTimezone,
  getPreviousWeek,
  getNextWeek,
  eachUnitOfInterval,
  transformDateForQuery,
  displayDayName,
  displayDayNumberInMonth,
  displayDayDDMMYYYY,
} from "../../../utils/config";
import { Center } from "../../../lib/ground-aws-graphql-core/models/Center";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ModalContent {
  workspace: SpaceBookingsCalendar;
  weekday: DayCalendar;
}

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
}

interface ModalLinkProps {
  linkData: { pathname: string; state: any };
  icon: any;
  title: string;
  enabled: boolean;
}

const LIMIT = 5;

const EnumBackGroundColorTypology = [
  "rgba(153, 102, 255, 0.5)",
  "rgba(255, 99, 132, 0.5)",
  "rgba(53, 162, 235, 0.5)",
  "rgba(54, 192, 0, 0.5)",
  "rgba(255, 150, 0, 0.5)",
  "rgba(36, 36, 36, 0.5)",
];

const EnumBorderColorTypology = [
  "rgba(153, 102, 255)",
  "rgb(255, 99, 132)",
  "rgb(53, 162, 235)",
  "rgb(54, 192, 0)",
  "rgb(255, 150, 0)",
  "rgb(36, 36, 36)",
];

const CalendarScreen = (props: Props) => {
  const { match, intl } = props;

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const now = getTodayInCenterTimezone(centerTimezone);

  const [loading, setLoading] = useState(false);

  const { startWeek, endWeek } = getCurrentWeekInCenterTimezone(centerTimezone);
  const [startDate, setStartDate] = useState(startWeek);
  const [endDate, setEndDate] = useState(endWeek);

  const [date, setDate] = useState(now);
  const [pageIndex, setPageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ModalContent | null>(null);
  const [bookingsPageIndex, setBookingsPageIndex] = useState(0);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [typology, setTypology] = useState<[EnumBookableTypology] | undefined>(
    undefined
  );
  const [capacity, setCapacity] = useState<
    SearchableSpacesBookingsCapacity[] | undefined
  >(undefined);

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const center = GroundGraphqlContextStore.useStoreState(
    state => state.center.center
  );

  const spacesBookingsTotal = GroundGraphqlContextStore.useStoreState(
    state => state.product.spacesBookingsTotal
  );
  const spacesBookings = GroundGraphqlContextStore.useStoreState(
    state => state.product.spacesBookings
  );

  const searchSpacesBookings = GroundGraphqlContextStore.useStoreActions(
    actions => actions.product.searchSpacesBookings
  );

  const spaceDateBookings = GroundGraphqlContextStore.useStoreState(
    state => state.product.spaceDateBookings.bookings.items
  );
  const spaceDateBookingsTotal = GroundGraphqlContextStore.useStoreState(
    state => state.product.spaceDateBookings.bookings.total
  );
  const spaceDateBookingsBookable = GroundGraphqlContextStore.useStoreState(
    state => state.product.spaceDateBookings.bookable
  );
  const spaceDateBookingsPrivatizable = GroundGraphqlContextStore.useStoreState(
    state => state.product.spaceDateBookings.privatizable
  );
  const spaceDateBookingsPrivatizations =
    GroundGraphqlContextStore.useStoreState(
      state => state.product.spaceDateBookings.privatizations
    );

  const searchSpaceDateBookings = GroundGraphqlContextStore.useStoreActions(
    actions => actions.product.searchSpaceDateBookings
  );

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  const authorizedToEditEnterprise = canEditEnterprise(me);

  const configuration = getOperatorConfiguration(me?.operator_id);

  const fetchSpacesBookings = () => {
    setLoading(true);
    searchSpacesBookings({
      filter: {
        centerId: match.params.cid,
        start: transformDateForQuery(startDate, centerTimezone),
        type: EnumCalendarType.WEEK,
        ...(typology &&
          typology.length && {
            typology,
          }),
        ...(capacity &&
          capacity.length && {
            capacity,
          }),
      },
      from: pageIndex * LIMIT,
      limit: LIMIT,
    }).finally(() => setLoading(false));
  };

  const fetchSpaceDateBookings = (
    workspace: SpaceBookingsCalendar | undefined,
    weekday: DayCalendar | undefined
  ) => {
    if (workspace && weekday) {
      searchSpaceDateBookings({
        productId: workspace.id,
        date: weekday.formatted_date,
      });
    }
  };

  useEffect(() => {
    fetchSpacesBookings();
  }, [startDate, pageIndex, typology, capacity]);

  useEffect(() => {
    fetchSpaceDateBookings(modalContent?.workspace, modalContent?.weekday);
  }, [bookingsPageIndex]);

  const onChangeDate = (d: Date) => {
    setDate(d);
    const { startWeek, endWeek } = getWeekInCenterTimezone(d, centerTimezone);
    setStartDate(startWeek);
    setEndDate(endWeek);
  };

  const onPreviousWeek = () => {
    const { startWeek, endWeek } = getPreviousWeek({
      start: startDate,
      end: endDate,
    });

    setStartDate(startWeek);
    setEndDate(endWeek);
    setDate(startWeek);
    if (process.env.REACT_APP_EVENT_NAME_CALENDAR_WEEK_DOWN) {
      Event("calendar", process.env.REACT_APP_EVENT_NAME_CALENDAR_WEEK_DOWN);
    }
  };

  const onNextWeek = () => {
    const { startWeek, endWeek } = getNextWeek({
      start: startDate,
      end: endDate,
    });
    setStartDate(startWeek);
    setEndDate(endWeek);
    setDate(startWeek);
    if (process.env.REACT_APP_EVENT_NAME_CALENDAR_WEEK_UP) {
      Event("calendar", process.env.REACT_APP_EVENT_NAME_CALENDAR_WEEK_UP);
    }
  };

  const onCellClick = (
    workspace: SpaceBookingsCalendar,
    weekday: DayCalendar
  ) => {
    fetchSpaceDateBookings(workspace, weekday);

    setIsModalOpen(true);
    setModalContent({ workspace, weekday });
  };

  const getHeadArray = (): (string | JSX.Element)[] => {
    const days = eachUnitOfInterval(startDate, endDate, "day");
    const headArray = days.map(day => {
      return (
        <span className="text-12px leading-3" key={0}>
          {displayDayName(day, currentAppLocale, centerTimezone)}
          <br />
          <span className="font-semibold text-ground-black-100">
            {displayDayNumberInMonth(day, centerTimezone)}
          </span>
        </span>
      );
    });

    headArray.unshift(
      <span className="text-12px">
        <IntlMessages id="page.calendar.head.workspace" />
      </span>
    );

    return headArray;
  };

  const getBodyArray = () => {
    if (spacesBookings?.length) {
      return spacesBookings?.map((workspace, i, workspacesArray) => {
        const rowClassName = `py-2 ${i === 0 ? "pt-4 pb-2" : ""} ${
          i !== workspacesArray.length - 1 ? "border-b-0" : "pb-4 pt-2"
        }`;

        const workspaceBookings: BodyElement[] = workspace.calendar.items.map(
          (day, j) => {
            const { status, indicators } = day;
            const { occupancy_rate, total_remaining_places } = indicators;

            const closed = status === EnumBookingStatus.CLOSED;

            return {
              element: (
                <ReactToolip
                  position="top"
                  arrowSize="small"
                  animation="fade"
                  trigger="mouseenter"
                  html={
                    <div className="flex flex-col align-items-start">
                      {!closed && (
                        <span className="text-12px">
                          {intl.formatMessage(
                            { id: "general.occupancy.rate" },
                            { rate: (occupancy_rate * 100).toFixed(3) }
                          )}
                        </span>
                      )}
                      {!closed && total_remaining_places && (
                        <span className="text-12px">
                          {intl.formatMessage(
                            { id: "general.total.remaining.places" },
                            { total: total_remaining_places }
                          )}
                        </span>
                      )}
                      {closed && (
                        <span className="text-12px">
                          {intl.formatMessage({
                            id: "page.list.bookings.not.bookable.reason.BUILDING_OR_SPACE_CLOSED",
                          })}
                        </span>
                      )}
                    </div>
                  }
                >
                  <button
                    type="button"
                    aria-label="cell"
                    onClick={() => onCellClick(workspace, day)}
                    disabled={day.status === EnumBookingStatus.CLOSED}
                    className={`h-8 w-full flex items-center justify-center ${
                      j === 0 ? "border-l " : ""
                    }border-t border-r border-b border-neutral-100 ${getCellBackgroundColorFromStatus(
                      day.status
                    )} ${
                      day.status === EnumBookingStatus.CLOSED
                        ? "cursor-default"
                        : ""
                    }`}
                  >
                    {day.privatized && (
                      <img src={images.padlock} alt="privatized" />
                    )}
                  </button>
                </ReactToolip>
              ),
              additionalClassName: `w-1/8 px-0 ${rowClassName}`,
            };
          }
        );

        workspaceBookings.unshift({
          element: (
            <div className="flex">
              <span className="line-clamp-2 font-semibold text-ground-black-100">
                {getTranslation(workspace.name)}
              </span>
              {workspace.combined && (
                <img
                  src={images.combined}
                  alt="combined"
                  className="w-3 h-3 ml-1"
                />
              )}
            </div>
          ),
          additionalClassName: `w-1/4 ${rowClassName}`,
        });

        return { rowElements: workspaceBookings };
      });
    }

    return [];
  };

  const renderPrivatization = (privatization: Privatization) => {
    return (
      <div className="flex items-center" key={privatization.id}>
        <img src={images.padlock} className="w-5 h-6 mr-2" alt="padlock" />

        <div className="flex flex-col">
          <span className="text-14px leading-4 font-semibold">
            <IntlMessages id="general.privatization.by" />{" "}
            <button
              className={`text-ground-blue-100 underline ${
                authorizedToEditEnterprise ? "cursor-pointer" : "cursor-default"
              }`}
              disabled={!authorizedToEditEnterprise}
              type="button"
              onClick={() =>
                history.push(
                  `/${EnumPaths.ROOT}/${EnumPaths.USERS}/${EnumPaths.ENTERPRISE}/${privatization.enterprise.id}/${EnumPaths.EDIT_MODE}`
                )
              }
            >
              {getTranslation(privatization.enterprise.name)}
            </button>
          </span>

          <span className="text-12px leading-normal">
            <IntlMessages
              id="page.calendar.list.privatizations.privatization.dates"
              values={{
                startDate: displayDayDDMMYYYY(
                  privatization.start,
                  centerTimezone
                ),
                endDate: displayDayDDMMYYYY(privatization.end, centerTimezone),
              }}
            />
          </span>
        </div>
      </div>
    );
  };

  const renderBooking = (
    booking: Booking,
    workspace: SpaceBookingsCalendar,
    center: Center
  ) => {
    const { sameDay, startDay, endDay, startHour, endHour } =
      getBookingInBuildingTZ(booking, center.timezone);

    const orderDetailsLink = `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${match.params.cid}/${EnumPaths.ORDERS}/${booking.order.id}`;

    const { product, type } = booking;

    const currentSpace =
      type === EnumUnavailabilityType.PARENT && product.id === workspace.id;

    const className = () =>
      classnames(
        "w-full h-15 flex justify-between items-center rounded-4 px-5 bg-primary-600 text-12px",
        {
          "text-ground-black-100": !currentSpace,
          "text-ground-blue-100": currentSpace,
        }
      );

    return (
      <div key={`booking_${booking.id}`} className={className()}>
        <div className="flex flex-col cursor-pointer">
          <span
            className="underline"
            onClick={() => {
              history.push(orderDetailsLink);
            }}
          >
            {booking.user.firstname} {booking.user.lastname}
          </span>
          {!currentSpace && (
            <span
              className="italic"
              onClick={() => {
                history.push(orderDetailsLink);
              }}
            >
              <IntlMessages
                id={"page.calendar.list.bookings.due.to.bookings"}
                values={{
                  ormOrder: booking.order.orm_order,
                  name: getTranslation(booking.product.name),
                }}
              />
            </span>
          )}
        </div>
        {!sameDay && (
          <div
            className="flex flex-col cursor-pointer underline"
            onClick={() => {
              history.push(orderDetailsLink);
            }}
          >
            <span>
              <IntlMessages id="general.from" /> {startDay}{" "}
              <IntlMessages id="general.at" /> {startHour}
            </span>
            <span>
              <IntlMessages id="general.to" /> {endDay}{" "}
              <IntlMessages id="general.at" /> {endHour}
            </span>
          </div>
        )}
        {sameDay && (
          <span
            className="cursor-pointer underline"
            onClick={() => {
              history.push(orderDetailsLink);
            }}
          >
            {startHour}
            {" - "}
            {endHour}
          </span>
        )}
      </div>
    );
  };

  const renderLegendItem = (bookingStatusItem: BookingStatus) => {
    const { status, cellBgColor } = bookingStatusItem;

    return cellBgColor ? (
      <div key={`status_${status}`} className="flex items-center">
        <div className={`inline-block ${cellBgColor} w-3 h-3 mr-2`} />
        <span className="text-black text-12px leading-normal">
          <IntlMessages id={`page.calendar.legend.${status.toLowerCase()}`} />
        </span>
      </div>
    ) : null;
  };

  const ModalLink = ({ linkData, icon, title, enabled }: ModalLinkProps) => {
    return (
      <button
        className={`self-center flex items-center rounded-4 px-4 py-2 mb-2 border ${
          enabled ? "border-ground-blue-100" : "border-ground-gray-100"
        }`}
        disabled={!enabled}
        onClick={() => history.push(linkData.pathname, linkData.state)}
        type="button"
      >
        <img src={icon} alt="book-button" />
        <span
          className={`ml-1 text-14px leading-4 ${
            enabled ? "text-ground-blue-100" : "text-ground-gray-100"
          }`}
        >
          <IntlMessages id={title} />
        </span>
      </button>
    );
  };

  const onFiltersChange = filtersValues => {
    const capacityValues: number[] =
      filtersValues[EnumFilterFields[EnumFilterFields.CAPACITY]];
    const typologyValues: [EnumBookableTypology] =
      filtersValues[EnumFilterFields[EnumFilterFields.TYPOLOGY]];

    const values = capacityValues?.reduce(
      (acc: SearchableSpacesBookingsCapacity[], el) => {
        const value = capacityValuesList.find(v => v.min === el);
        if (value) {
          acc.push(value);
        }

        return acc;
      },
      []
    );

    setCapacity(values);
    setTypology(typologyValues);
  };

  const days = eachUnitOfInterval(startDate, endDate, "day").map(v =>
    displayDayName(v, currentAppLocale, centerTimezone).toUpperCase()
  );

  const occupancyRateDatasets = spacesBookings?.length
    ? spacesBookings?.map((workspace, index) => {
        const occupancyRates = workspace.calendar.items.map(
          w => w.indicators.occupancy_rate * 100
        );

        return {
          label: getTranslation(workspace.name),
          data: occupancyRates,
          backgroundColor: EnumBackGroundColorTypology[index],
          borderColor: EnumBorderColorTypology[index],
          borderWidth: 1,
        };
      })
    : [];

  const bookingsDatasets = spacesBookings?.length
    ? spacesBookings?.map((workspace, index) => {
        const bookings = workspace.calendar.items.map(
          w => w.indicators.bookings.length
        );

        return {
          label: getTranslation(workspace.name),
          data: bookings,
          backgroundColor: EnumBackGroundColorTypology[index],
          borderColor: EnumBorderColorTypology[index],
          borderWidth: 1,
        };
      })
    : [];

  const renderBookingModal = (content: ModalContent, center: Center) => {
    return (
      <ReactModal
        ariaHideApp={false}
        isOpen={isModalOpen}
        style={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
        shouldCloseOnOverlayClick
        onRequestClose={() => setIsModalOpen(!isModalOpen)}
        className="modal-dialog-lg shadow-none modal-dialog-centered"
      >
        <div className="modal-content">
          <div className="self-end m-4">
            <CloseIcon onClose={() => setIsModalOpen(false)} />
          </div>

          <div className="flex flex-col px-10 mb-8">
            <div className="flex flex-col pb-4 text-12px border-b border-gray-800">
              <span className="text-20px leading-5 font-semibold">
                {getTranslation(content.workspace.name)}
                <span
                  className={`ml-1 text-12px ${
                    content.workspace.enabled
                      ? "text-ground-green-100"
                      : "text-red-500"
                  }`}
                >
                  <IntlMessages
                    id={`page.calendar.list.workspace.enabled.${content.workspace.enabled.toString()}`}
                  />
                </span>
              </span>

              <span>
                <IntlMessages
                  id={`page.product.typology.${content.workspace.typology}`}
                />
              </span>

              <span>
                <IntlMessages
                  id="page.calendar.list.workspace.seats"
                  values={{ capacity: content.workspace.capacity }}
                />
              </span>
            </div>

            {spaceDateBookingsPrivatizations?.length > 0 && (
              <div className="flex flex-col gap-2 py-4 border-b border-gray-800">
                {spaceDateBookingsPrivatizations.map(renderPrivatization)}
              </div>
            )}

            <div className="flex flex-col my-4">
              <span className="text-14px leading-relaxed font-semibold">
                <IntlMessages
                  id="page.calendar.list.bookings"
                  values={{
                    date: _.capitalize(
                      displayDayDDMMYYYY(content.weekday.date, centerTimezone)
                    ),
                  }}
                />
              </span>

              {content.weekday.status === EnumBookingStatus.AVAILABLE ? (
                <span className="italic text-neutral-900 text-14px leading-relaxed">
                  <IntlMessages id="page.calendar.list.bookings.empty" />
                </span>
              ) : (
                <div>
                  <div className="grid gap-y-1">
                    {content.weekday.status === EnumBookingStatus.COMPLETE && (
                      <div className="w-full h-13 flex items-center rounded-4 px-5 bg-red-400 text-12px text-white">
                        <span>
                          <span className="font-semibold">
                            <IntlMessages id="page.calendar.list.bookings.full" />
                          </span>
                          <IntlMessages id="page.calendar.list.bookings.full.description" />
                        </span>
                      </div>
                    )}

                    {spaceDateBookings?.map(booking =>
                      renderBooking(booking, content.workspace, center)
                    )}
                  </div>

                  {spaceDateBookingsTotal > LIMIT && (
                    <Paginate
                      total={spaceDateBookingsTotal}
                      limit={LIMIT}
                      onPageChange={setBookingsPageIndex}
                      forcePage={bookingsPageIndex}
                      className="mt-4"
                    />
                  )}
                </div>
              )}
            </div>

            <ModalLink
              linkData={{
                pathname: `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${match.params.cid}/${EnumPaths.BOOKINGS}/${EnumPaths.BOOK_AVAILABILITY}/${content.workspace.id}/${EnumPaths.BOOK}`,
                state: {
                  date: content.weekday.date,
                  from: EnumBookingFrom.CALENDAR,
                },
              }}
              icon={
                spaceDateBookingsBookable
                  ? images.addActive
                  : images.addInactive
              }
              title="page.calendar.list.bookings.new.booking"
              enabled={spaceDateBookingsBookable}
            />

            {spaceDateBookingsPrivatizable &&
              authorizedToEditEnterprise &&
              isDisplayable(configuration, "feature.enterprise") && (
                <ModalLink
                  linkData={{
                    pathname: `/${EnumPaths.ROOT}/${EnumPaths.USERS}/${EnumPaths.SPACES}/${content.workspace.id}/${EnumPaths.CREATION_MODE}`,
                    state: { date: content.weekday.date },
                  }}
                  icon={images.padlockPrivatizable}
                  title="page.calendar.list.bookings.new.privatization"
                  enabled={spaceDateBookingsPrivatizable}
                />
              )}
          </div>
        </div>
      </ReactModal>
    );
  };

  return (
    <>
      <div className={overrideClasses({ loading })} />

      <div className={overrideClasses("px-8 pb-8", { hidden: loading })}>
        <Helmet>
          <title>
            {intl.formatMessage({ id: "page.calendar.document.title" })}
          </title>
        </Helmet>

        <div className="flex items-center gap-4">
          <FilterCalendar
            date={date}
            locale={currentAppLocale}
            onChangeDate={onChangeDate}
            onPrevious={onPreviousWeek}
            onNext={onNextWeek}
          />

          <FilterButton
            onClick={() => setIsFilterOpen(!isFilterOpen)}
            isOpen={isFilterOpen}
            icon={isFilterOpen ? images.filterOn : images.filterOff}
            title="page.calendar.filter.title"
          />
        </div>

        {isFilterOpen && (
          <CalendarFilter
            title="page.calendar.filter.title"
            onClick={onFiltersChange}
            entity={EnumPermissionEntity.CALENDAR}
          />
        )}

        <TableContainer>
          <Head headArray={getHeadArray()} />
          <Body
            bodyArray={getBodyArray()}
            spanNoData={getHeadArray().length}
            noDataText={
              capacity || typology
                ? "page.calendar.list.workspaces.filter.empty"
                : "page.calendar.list.workspaces.empty"
            }
          />
          <Foot
            total={spacesBookingsTotal}
            limit={LIMIT}
            forcePage={pageIndex}
            onPageChange={page => setPageIndex(page)}
            spanMax={getHeadArray().length}
            intl={intl}
          />
        </TableContainer>

        <div className="flex items-center gap-5 mt-5">
          {bookingStatusEnum.map(renderLegendItem)}

          <div className="flex items-center">
            <img
              src={images.padlock}
              alt="privatized"
              className="w-3 h-3 mr-2"
            />
            <span className="text-black text-12px leading-normal">
              <IntlMessages id="page.calendar.legend.privatized" />
            </span>
          </div>

          <div className="flex items-center">
            <img
              src={images.combined}
              alt="combined"
              className="w-3 h-3 mr-2"
            />
            <span className="text-black text-12px leading-normal">
              <IntlMessages id="page.calendar.legend.combined" />
            </span>
          </div>
        </div>

        <div className="flex my-8">
          <div className="w-1/2">
            <Bar
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top" as const,
                  },
                  title: {
                    display: true,
                    text: intl.formatMessage({
                      id: "page.calendar.chart.occupancy.rate.title",
                    }),
                  },
                },
                scales: {
                  y: {
                    suggestedMax: 100,
                    suggestedMin: 0,
                  },
                },
              }}
              data={{
                labels: days,
                datasets: occupancyRateDatasets,
              }}
            />
          </div>
          <div className="w-1/2">
            <Line
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top" as const,
                  },
                  title: {
                    display: true,
                    text: intl.formatMessage({
                      id: "page.calendar.chart.bookings.title",
                    }),
                  },
                },
              }}
              data={{
                labels: days,
                datasets: bookingsDatasets,
              }}
            />
          </div>
        </div>

        {isModalOpen && modalContent && center && (
          <>{renderBookingModal(modalContent, center)}</>
        )}
      </div>
    </>
  );
};

export default injectIntl(CalendarScreen);
