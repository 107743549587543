import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { ThunkCreator } from "easy-peasy";
import { injectIntl, IntlShape } from "react-intl";
import contextStore from "../../../redux/store";
import { getMenuClasses } from "../../../utils/menu";
import history from "../../../history";
import { EnumPaths } from "../../../utils/navigation";
import IntlMessages from "../../../utils/messages";
import {
  EnumPermissionEntity,
  EnumUserRole,
  SearchableUserFilterInput,
} from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import { GroundAuthContextStore } from "../../../lib/ground-aws-cognito-auth-core";
import PageSubheader from "../../../components/PageSubheader";
import Table, { ALL_FILTER, IFilterField } from "../../../components/Table";
import Badge from "../../../components/Tailwind/Badge";
import {
  EnumUserStatus,
  getRoleBadgeElements,
  getRoleLabel,
  UserStatus,
} from "../../../utils/user";
import Dropdown, {
  DropdownValues,
} from "../../../components/Tailwind/Dropdown";
import {
  SearchUsersOpts,
  User,
} from "../../../lib/ground-aws-graphql-core/models/User";
import ConfirmModal from "../../../utils/modal/confirm";
import { EnumFilterFields } from "../../../utils/filter";
import { BodyElement, BodyRow } from "../../../components/Table/types";
import { useOperatorDisplayConfiguration } from "../../../components/OperatorCustomization";
import images from "../../../images";
import { displayDayYYYYMMDDHHMMSS } from "../../../utils/config";

interface Props {
  intl: IntlShape;
}

const LIMIT = 20;

const ListUsers = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [userId, setUserId] = useState<string>("");
  const [selectedFilters, setSelectedFilters] =
    useState<SearchableUserFilterInput>({});

  const featureForgotPassword = useOperatorDisplayConfiguration([
    "feature.user.forgot_password",
  ]);

  const featureDelete = useOperatorDisplayConfiguration([
    "feature.user.delete",
  ]);

  const { intl } = props;

  const setContainerClassnames = contextStore.useStoreActions(
    actions => actions.menu.setContainerClassnames
  );

  const menuHiddenBreakpoint = contextStore.useStoreState(
    state => state.menu.menuHiddenBreakpoint
  );

  const subHiddenBreakpoint = contextStore.useStoreState(
    state => state.menu.subHiddenBreakpoint
  );

  const searchUsersAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.user.searchUsers
  );

  const exportUsersAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.user.exportUsers
  );

  const forgotPassword = GroundGraphqlContextStore.useStoreActions(
    actions => actions.account.forgotPasswordUser
  );

  const deleteUser = GroundGraphqlContextStore.useStoreActions(
    actions => actions.user.deleteUser
  );

  const searchUsers = searchUsersAction;

  const users = GroundGraphqlContextStore.useStoreState(
    state => state.user.users.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.user.users.total
  );

  const setUser = GroundGraphqlContextStore.useStoreActions(
    actions => actions.user.setUser
  );

  const meDetails = GroundAuthContextStore.useStoreState(
    state => state.authentication.meDetails
  );

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  useEffect(() => {
    fetchUsers();

    const nextClasses = getMenuClasses(
      "menu-hidden",
      menuHiddenBreakpoint,
      subHiddenBreakpoint
    );
    setContainerClassnames({ strCurrentClasses: nextClasses.join(" ") });
  }, []);

  const fetchUsers = (
    pageIndex = 0,
    loader = true,
    filters?: IFilterField<any>[]
  ) => {
    if (loader) {
      setLoading(true);
    }
    let filter;

    filter = {
      role: { ne: EnumUserRole.Anonymous },
    };

    if (filters?.length) {
      filters.forEach(f => {
        if (f.type === EnumFilterFields[EnumFilterFields.STATUS]) {
          if (f.value?.value !== ALL_FILTER) {
            if (f.value?.value === EnumUserStatus.CONFIRMED) {
              filter = {
                ...filter,
                or: [
                  { status: { eq: UserStatus.CONFIRMED } },
                  { status: { eq: UserStatus.EXTERNAL_PROVIDER } },
                ],
              };
            } else if (f.value?.value === EnumUserStatus.UNCONFIRMED) {
              filter = {
                ...filter,
                or: [
                  { status: { eq: UserStatus.ARCHIVED } },
                  { status: { eq: UserStatus.COMPROMISED } },
                  { status: { eq: UserStatus.FORCE_CHANGE_PASSWORD } },
                  { status: { eq: UserStatus.RESET_REQUIRED } },
                  { status: { eq: UserStatus.UNCONFIRMED } },
                  { status: { eq: UserStatus.UNKNOWN } },
                ],
              };
            }
          }
        } else if (f.value) {
          filter = {
            ...filter,
            [f.type.toLocaleLowerCase()]: {
              matchPhrasePrefix: `${f.value.toLowerCase()}`,
            },
          };
        }
      });
    }

    setSelectedFilters(filter);

    searchUsers({
      filter,
      limit: LIMIT,
      from: pageIndex * LIMIT,
    }).finally(() => {
      setLoading(false);
    });
  };

  const exportUsers = (): ThunkCreator<SearchUsersOpts, any> => {
    return exportUsersAction({
      filter: selectedFilters,
      limit: 100,
      from: 0,
    });
  };

  const handleAddNew = () => {
    setUser(null);
    history.push(
      `/${EnumPaths.ROOT}/${EnumPaths.USERS}/${EnumPaths.CREATION_MODE}`
    );
  };

  const handleEdit = (editUserLink: string) => {
    setUser(null);
    history.push(editUserLink);
  };

  const handleForgotPassword = () => {
    // reset password
    setLoading(true);
    setShowDeleteModal(false);
    forgotPassword({ id: userId })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.list.users.forgot.password.success",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.list.users.forgot.password.error",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    setShowDeleteUserModal(false);
    deleteUser({ id: userId })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.list.users.delete.user.success",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.list.users.delete.user.error",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const key = "users";
  const suffix = "user";

  const tableHead = [
    `page.list.${key}.table.head.name`,
    "general.email",
    "page.list.users.table.head.role",
    "page.list.users.table.head.account",
    "general.actions",
  ];

  // Add the column "Company" at index 2 if we are working with only users

  tableHead.splice(2, 0, "general.company");

  const tableBody: BodyRow[] = users?.map((user: User) => {
    const { id, lastname, firstname, email, role, status } = user;

    const editUserLink = `/${EnumPaths.ROOT}/${EnumPaths.USERS}/${id}/${EnumPaths.EDIT_MODE}`;

    const dropdownValues: DropdownValues[] = [
      {
        id: "edit_user",
        label: "general.edit",
        icon: images.edit,
        link: editUserLink,
      },
    ];

    if (featureForgotPassword) {
      dropdownValues.push({
        id: "forgot_password",
        label:
          status === UserStatus.FORCE_CHANGE_PASSWORD
            ? "general.resend.temporary.password"
            : "general.reinitialize.password",
        icon: images.mail,
        onClick: () => {
          setShowDeleteModal(!showDeleteModal);
          setUserId(id);
        },
      });
    }

    if (featureDelete) {
      dropdownValues.push({
        id: "delete",
        label: "general.delete",
        icon: images.deleteIcon,
        onClick: () => {
          setShowDeleteUserModal(!showDeleteUserModal);
          setUserId(id);
        },
      });
    }

    const userElements: BodyElement[] = [
      {
        element: (
          <span
            data-cy={`${suffix}-name-${id}`}
          >{`${lastname} ${firstname}`}</span>
        ),
        onCellClick: () => handleEdit(editUserLink),
      },
      {
        element: <span data-cy={`${suffix}-email-${id}`}>{email}</span>,
        onCellClick: () => handleEdit(editUserLink),
      },
      {
        element: (
          <Badge
            bgClassName={getRoleBadgeElements(role).backgroundColor}
            textClassName={getRoleBadgeElements(role).textColor}
            dataCy={`${suffix}-role-${id}`}
          >
            {getRoleLabel(intl, role)}
          </Badge>
        ),
        onCellClick: () => handleEdit(editUserLink),
      },
      {
        element:
          status &&
          (status === UserStatus.CONFIRMED ||
            status === UserStatus.EXTERNAL_PROVIDER) ? (
            <IntlMessages id="general.CONFIRMED" />
          ) : (
            <IntlMessages id="general.UNCONFIRMED" />
          ),
        onCellClick: () => handleEdit(editUserLink),
      },
      {
        element: (
          <Dropdown
            values={dropdownValues}
            dataCy={`${suffix}-dropdown-${id}`}
          />
        ),
      },
    ];

    // Add the element for the company at index 2 if we are working with only users
    userElements.splice(2, 0, {
      element: (user as User).enterprise?.name,
      onCellClick: () => handleEdit(editUserLink),
    });

    return {
      rowElements: userElements,
    };
  });

  return (
    <div>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: `page.list.${key}.document.title`,
          })}
        </title>
      </Helmet>

      <PageSubheader
        title={`page.list.${key}.title`}
        nbOfResults={total}
        buttonRightTitle={`page.list.${key}.add.${suffix}`}
        buttonRightAction={handleAddNew}
        buttonRightId={`btn-add-${suffix}`}
      />

      <Table
        head={tableHead}
        body={tableBody}
        noDataText="page.list.users.empty"
        permissionEntity={EnumPermissionEntity.USER}
        onChange={({ pageIndex, filters }) =>
          fetchUsers(pageIndex, false, filters)
        }
        exportData={{
          onExport: exportUsers,
          filename: `users-${
            meDetails?.operator.short_name
          }-${displayDayYYYYMMDDHHMMSS(new Date(), centerTimezone)}.csv`,
          headers: [
            { label: "Firstname", key: "firstname" },
            { label: "Lastname", key: "lastname" },
            { label: "Email", key: "email" },
            { label: "Phone", key: "phone" },
            { label: "Locale", key: "locale" },
          ],
        }}
        paginationTotal={total}
        paginationLimit={LIMIT}
        useFilterBlock
        filterBlockTitle={`page.list.${key}.filter.title`}
        loading={loading}
        setLoading={setLoading}
        className="px-8"
      />
      <ConfirmModal
        isOpen={showDeleteModal}
        onRequestClose={() => setShowDeleteModal(!showDeleteModal)}
        toggle={() => setShowDeleteModal(!showDeleteModal)}
        handleConfirm={handleForgotPassword}
        content={<IntlMessages id="page.list.users.forgot.password" />}
        description={
          <IntlMessages id="page.list.users.forgot.password.description" />
        }
      />
      <ConfirmModal
        isOpen={showDeleteUserModal}
        onRequestClose={() => setShowDeleteUserModal(!showDeleteUserModal)}
        toggle={() => setShowDeleteUserModal(!showDeleteUserModal)}
        handleConfirm={handleDelete}
        content={<IntlMessages id="page.list.users.delete.user" />}
      />
    </div>
  );
};

export default injectIntl(ListUsers);
