import classNames from "classnames";
import React, { useState } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { EnumFilterType } from "../../../../utils/filter";
import { IFilterField } from "../../../Table";

interface Props {
  key: number;
  intl: IntlShape;
  fields: {
    value: string;
    type: EnumFilterType;
  }[];
  filters: {
    value: string;
    type: EnumFilterType;
    onSelectChange?: (
      e: any,
      fields: {
        value: string;
        type: EnumFilterType;
      }[]
    ) => void;
    onChange: (type: string, input: string) => void;
  }[];
  selectedFilters?: IFilterField<any>[];
  displayTitle?: boolean;
}

const FilterInput = (props: Props): JSX.Element => {
  const { intl, fields, filters, key, selectedFilters } = props;
  let { displayTitle } = props;

  if (displayTitle === undefined) {
    displayTitle = true;
  }

  const { value, onChange, onSelectChange } = filters[0];

  const currentInput = selectedFilters?.find(f => f.type === value)?.value;

  const [filter, setFilter] = useState<string>(value);
  const [inputValue, setInputValue] = useState<string>(currentInput ?? "");

  const classNameDiv = classNames("inline-block", {
    "my-4 h-8 border border-ground-gray-400 rounded-4": displayTitle,
  });
  const classNameInput = classNames(
    "max-w-xs pl-2 placeholder-ground-gray-100 text-ground-black-100 text-14px leading-5 outline-none",
    {
      "h-8 border border-ground-gray-400 rounded-4": !displayTitle,
    }
  );

  return (
    <div key={key} className={classNameDiv}>
      {displayTitle && (
        <select
          id="filter-field"
          name="filter-field"
          className="form-select h-full py-0 bg-transparent text-ground-black-100 text-14px leading-5 border-t-0 border-b-0 border-l-0 border-r border-ground-gray-400 rounded-none shadow-none"
          onChange={e => {
            setInputValue("");
            setFilter(e.target.value);
            if (onSelectChange) {
              onSelectChange(e, fields);
            }
          }}
        >
          {filters.map(e => (
            <option key={e.value} id={e.value} value={e.value}>
              {intl.formatMessage({ id: `general.filter.${e.value}` })}
            </option>
          ))}
        </select>
      )}

      <input
        type="text"
        name="filter-input"
        id="filter-input"
        className={classNameInput}
        value={inputValue}
        placeholder={intl.formatMessage({
          id: `general.filter.placeholder.${filter}`,
        })}
        onChange={e => {
          setInputValue(e.target.value);
          onChange(filter, e.target.value);
        }}
      />
    </div>
  );
};

export default injectIntl(FilterInput);
