import { AxiosResponse } from "axios";
import axiosBackend from "../../../../../axiosBackend";
import {
  CreateAccountLinksOpts,
  CreateConnectedAccountOpts,
  CreateRefundExternalizedOpts,
  RefundOpts,
  RetryRefundExternalizedOpts,
} from "../../../models/Api/Refund";

export const createRefund = async ({
  orderId,
  orderItemId,
  reason,
}: RefundOpts): Promise<AxiosResponse> => {
  const body = {
    order_id: orderId,
    ...(reason && {
      reason,
    }),
    ...(orderItemId && {
      order_item_id: orderItemId,
    }),
  };

  return axiosBackend.post(`/payment/refund`, body);
};

/**
 * create a refund with the payment externalization
 *
 * @return - A promise resolves create a refund
 */
export const createRefundExternalized = async (
  payload: CreateRefundExternalizedOpts
): Promise<AxiosResponse> => {
  let path = `/global_orders/${payload.globalOrderId}/orders/${payload.orderId}`;

  if (payload.orderItemId) path += `/order_items/${payload.orderItemId}`;

  path += "/refund_request";

  let body = {};
  if (payload.reason) {
    body = { reason: payload.reason };
  }

  return axiosBackend.post(path, body);
};

/**
 * retry a refund request with the payment externalization
 *
 * @return - A promise resolves retry a refund request
 */
export const retryRefundExternalized = async (
  payload: RetryRefundExternalizedOpts
): Promise<AxiosResponse> => {
  return axiosBackend.post(`/webhook_events/${payload.id}`);
};

/**
 * create connected account
 *
 * @return - A promise resolves create connected account
 */
export const createConnectedAccount = async (
  payload: CreateConnectedAccountOpts
): Promise<AxiosResponse> => {
  return axiosBackend.post(`/payment/connect/accounts/${payload.id}`);
};

/**
 * create account links
 *
 * @return - A promise resolves create account links
 */
export const createAccountLinks = async (
  payload: CreateAccountLinksOpts
): Promise<AxiosResponse> => {
  return axiosBackend.post(`/payment/connect/account_links/${payload.id}`, {
    refresh_url: payload.refreshUrl,
    return_url: payload.returnUrl,
  });
};
