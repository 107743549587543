import React, { useState } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import images from "../../../../images";
import IntlMessages from "../../../../utils/messages";
import {
  eachUnitOfInterval,
  getCypressTestId,
  isSameDates,
} from "../../../../utils/config";
import DaySlot from "./DaySlot";
import Add from "../../../Tailwind/Button/Add";
import ModalDateRange from "../../../ModalDateRange";
import {
  DaySlotItem,
  HoursRangeItem,
  ExceptionalOpeningHourItem,
} from "../../../../utils/dayslot";
import ExceptionalOpeningHourComponent from "./ExceptionalOpeningHour";
import { EnumHoursRangeName } from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { ActionTypes } from "../../../../utils/types";
import { afternoon, morning } from "../../../../utils/config";

const MAX_EXCEPTIONAL_OPENING_HOURS = 3;

interface Props {
  item: { id: string } | null;
  daySlots: DaySlotItem[];
  exceptionalOpeningHours: ExceptionalOpeningHourItem[];
  editDaySlots?: boolean;
  onChangeHours: (e: HoursRangeItem) => void;
  onChangeExceptionalOpeningHours: (...e: ExceptionalOpeningHourItem[]) => void;
}

interface ModalDateRangeDates {
  start: Date;
  end: Date;
}

const CardHour = (props: Props): JSX.Element => {
  const {
    daySlots,
    exceptionalOpeningHours,
    editDaySlots = true,
    item,
    onChangeHours,
    onChangeExceptionalOpeningHours,
  } = props;

  const [disableDaysSlots, setDisableDaySlots] = useState(!editDaySlots);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalDateRangeDates, setModalDateRangeDates] =
    useState<ModalDateRangeDates | null>(null);

  const intl = useIntl();

  const hoursIds = exceptionalOpeningHours.map(e => e.item.id ?? 0);
  const maxId = hoursIds.length > 0 ? Math.max(...hoursIds) : 0;

  const handleSubmitExceptionalOpeningHourDates = (start: Date, end: Date) => {
    const s = start.toISOString();
    const e = end.toISOString();

    if (modalDateRangeDates) {
      // Modification of existing exceptional opening hours
      const exceptionalOpeningHoursToModify = exceptionalOpeningHours
        .filter(
          exceptionalHour =>
            isSameDates(
              new Date(exceptionalHour.item.startAt),
              modalDateRangeDates?.start
            ) &&
            isSameDates(
              new Date(exceptionalHour.item.endAt),
              modalDateRangeDates?.end
            )
        )
        .map(exceptionalHour => ({
          ...exceptionalHour,
          item: { ...exceptionalHour.item, startAt: s, endAt: e },
          action:
            exceptionalHour.action !== ActionTypes.TO_ADD
              ? ActionTypes.TO_UPDATE
              : exceptionalHour.action,
        }));

      onChangeExceptionalOpeningHours(...exceptionalOpeningHoursToModify);
    } else {
      // The maxId is used to have a unique id for every exceptional opening hours
      const newExceptionalOpeningHourMorning: ExceptionalOpeningHourItem = {
        item: {
          id: maxId + 1,
          startAt: s,
          endAt: e,
          ...morning,
          name: EnumHoursRangeName.MORNING,
          enabled: true,
        },
        isGenerated: true,
        action: ActionTypes.TO_ADD,
      };
      const newExceptionalOpeningHourAfternoon: ExceptionalOpeningHourItem = {
        item: {
          id: maxId + 2,
          startAt: s,
          endAt: e,
          ...afternoon,
          name: EnumHoursRangeName.AFTERNOON,
          enabled: true,
        },
        isGenerated: true,
        action: ActionTypes.TO_ADD,
      };

      onChangeExceptionalOpeningHours(
        newExceptionalOpeningHourMorning,
        newExceptionalOpeningHourAfternoon
      );
    }

    setModalDateRangeDates(null);
  };

  // Group the exceptional opening hours by their startAt date in order to have groups of one and two elements
  // This way we have the morning and afternoon hours in each group
  const groupedExceptionalOpeningHours = Object.values(
    _.groupBy(exceptionalOpeningHours, "item.startAt")
  ).filter(exceptionalHoursGroup =>
    exceptionalHoursGroup.some(
      exceptionalHour => exceptionalHour.action !== ActionTypes.TO_DELETE
    )
  );

  const handleChangeExceptionalOpeningHourDates = (start: Date, end: Date) => {
    setModalDateRangeDates({ start, end });
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setModalDateRangeDates(null);
    setIsModalOpen(!isModalOpen);
  };

  const listDisabledDatesFromExceptionalOpeningHours = () =>
    exceptionalOpeningHours.reduce((disabledDates, exceptionalHour) => {
      // Don't include deleted exceptional opening hours
      if (exceptionalHour.action === ActionTypes.TO_DELETE)
        return disabledDates;

      // Exclude exceptional opening dates from disabledDates in order to update it correctly
      if (
        modalDateRangeDates?.start &&
        modalDateRangeDates?.end &&
        isSameDates(
          new Date(exceptionalHour.item.startAt),
          modalDateRangeDates?.start
        ) &&
        isSameDates(
          new Date(exceptionalHour.item.endAt),
          modalDateRangeDates?.end
        )
      )
        return disabledDates;

      const dates = eachUnitOfInterval(
        exceptionalHour.item.startAt,
        exceptionalHour.item.endAt,
        "day"
      );

      return disabledDates.concat(dates);
    }, [] as Date[]);

  const handleAddExceptionalOpeningHours = () => {
    if (
      groupedExceptionalOpeningHours.length >= MAX_EXCEPTIONAL_OPENING_HOURS
    ) {
      toast(
        intl.formatMessage(
          { id: "page.exceptional.opening.hours.max.error" },
          { max: MAX_EXCEPTIONAL_OPENING_HOURS }
        ),
        { type: "error" }
      );
    } else setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="bg-white shadow sm:rounded-lg my-30px">
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 className="text-16px leading-6 font-medium text-gray-900">
          <IntlMessages id="page.opening.hours.title" />
        </h3>
      </div>
      <div className="mx-auto xl:grid grid-cols-2 md:px-6 lg:px-8 p-20px bg-gray-100 space-x-8">
        <div className="relative">
          {disableDaysSlots && (
            <div className="absolute w-full h-full flex items-center justify-center bg-white bg-opacity-50 z-50">
              <img
                data-cy="edit-picto-dayslots"
                data-testid={getCypressTestId(item)}
                src={images.pencil}
                alt="edit"
                className="w-8 h-8 cursor-pointer pencil-edit border-2 opacity-75 hover:opacity-100"
                onClick={() => setDisableDaySlots(false)}
              />
            </div>
          )}
          <h4 className="text-16px leading-6 font-medium text-gray-900">
            <IntlMessages id="page.center.daily.hours" />
          </h4>
          <table className="mt-5">
            <thead>
              <tr>
                <th />
                <th className="px-3 py-1 text-left text-gray-500">
                  <IntlMessages id="general.morning" />
                </th>
                <th className="px-3 py-1 text-left text-gray-500">
                  <IntlMessages id="general.afternoon" />
                </th>
              </tr>
            </thead>
            <tbody>
              {daySlots.map((dayslot, index) => {
                return (
                  <DaySlot
                    daySlot={dayslot}
                    onChange={onChangeHours}
                    key={index}
                  />
                );
              })}
            </tbody>
          </table>
        </div>

        <div>
          <div className="items-center inline-flex justify-between w-full">
            <h4 className="text-16px leading-6 font-medium text-gray-900">
              <IntlMessages id="page.exceptional.opening.hours.title" />
            </h4>

            <Add
              label="general.add"
              onClick={handleAddExceptionalOpeningHours}
            />
            <ModalDateRange
              title="page.exceptional.opening.hours.title"
              description="page.exceptional.opening.hours.infos"
              onSubmit={handleSubmitExceptionalOpeningHourDates}
              open={isModalOpen}
              setOpen={setIsModalOpen}
              onRequestClose={closeModal}
              disabledDates={listDisabledDatesFromExceptionalOpeningHours()}
              start={
                modalDateRangeDates?.start
                  ? new Date(modalDateRangeDates.start)
                  : undefined
              }
              end={
                modalDateRangeDates?.end
                  ? new Date(modalDateRangeDates.end)
                  : undefined
              }
            />
          </div>

          {groupedExceptionalOpeningHours &&
            groupedExceptionalOpeningHours?.length > 0 && (
              <table className="mt-5">
                <thead>
                  <tr>
                    <th />
                    <th className="px-3 py-1 text-left text-gray-500">
                      <IntlMessages id="general.morning" />
                    </th>
                    <th className="px-3 py-1 text-left text-gray-500">
                      <IntlMessages id="general.afternoon" />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {groupedExceptionalOpeningHours.map((exceptionalHours, i) => {
                    return (
                      <ExceptionalOpeningHourComponent
                        exceptionalOpeningHours={exceptionalHours}
                        onChange={onChangeExceptionalOpeningHours}
                        onChangeExceptionalOpeningHourDates={
                          handleChangeExceptionalOpeningHourDates
                        }
                        key={`exceptional_hour_${i}`}
                        // In order to have unique ids for every group of exceptional opening hours
                        maxId={maxId * (i + 1)}
                      />
                    );
                  })}
                </tbody>
              </table>
            )}
          {/* when no exceptional hours */}
          {!groupedExceptionalOpeningHours?.length && (
            <div className="mt-5">
              <IntlMessages id="page.exceptional.no.opening.hours" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardHour;
