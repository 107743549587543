import { ErrorGraphQL } from "./../../services/coreUtils";
import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel } from "../";
import {
  EnumNotificationType,
  SearchableSortDirection,
} from "./../../api/graphql/types";
import { Operator } from "../Operator";

import {
  UpdateNotificationInput as UpdateNotification,
  SearchNotificationsFilterInput,
  SearchableNotificationSortableFields,
} from "../../api/graphql/types";
import { Center } from "../Center";
import { BackOfficeUser } from "../BackOfficeUser";
import { Image } from "../../../../utils/types";
import { IMultiLanguage } from "../Api";

export interface Notification {
  operator?: Operator;
  id: string;
  type: EnumNotificationType;
  entity: string;
  description?: any | null;
  enabled: boolean;
  markForDelete: boolean;
  center: Center;
  creationDate: string;
  viewDate: string;
  viewer?: BackOfficeUser | null;
}

export interface SearchNotificationsOpts {
  filter?: SearchNotificationsFilterInput;
  sort?: {
    field: SearchableNotificationSortableFields;
    direction: SearchableSortDirection;
  };
  limit?: number | null;
  gql?: string | null;
  from?: number;
}

export interface ModelNotificationConnection {
  items: [Notification] | null;
  total: number;
}

export interface CreateNotificationOpts {
  building_id: string;
  type: EnumNotificationType;
  headings?: IMultiLanguage;
  contents: IMultiLanguage;
  recipients: {
    administrators: boolean;
    android_users: boolean;
    ios_users: boolean;
    user_ids?: string[];
  };
  send_after?: string;
  pictures?: Image[];
}

// Interface declaration
export interface NotificationModel {
  notifications: ModelNotificationConnection;
  unreadNotificationsTotal: number;

  notification: Notification | null;
  setNotifications: Action<
    NotificationModel,
    { data: ModelNotificationConnection; page?: number }
  >;

  setUnreadNotificationsTotal: Action<NotificationModel, { total: number }>;
  setNotification: Action<NotificationModel, Notification | null>;
  replaceNotification: Action<NotificationModel, Notification>;
  createNotification: Thunk<
    NotificationModel,
    CreateNotificationOpts,
    Injections
  >;
  updateNotification: Thunk<NotificationModel, UpdateNotification, Injections>;
  searchNotifications: Thunk<
    NotificationModel,
    SearchNotificationsOpts,
    Injections,
    StoreModel
  >;
  searchUnreadNotificationsTotal: Thunk<
    NotificationModel,
    SearchNotificationsOpts,
    Injections,
    StoreModel
  >;
}

export const notificationModel: NotificationModel = {
  notifications: { items: null, total: 0 },
  unreadNotificationsTotal: 0,
  notification: null,

  setNotifications: action(
    (state, payload: { data: ModelNotificationConnection }) => {
      state.notifications = payload.data;
    }
  ),
  setUnreadNotificationsTotal: action((state, payload: { total: number }) => {
    state.unreadNotificationsTotal = payload.total;
  }),

  setNotification: action((state, payload) => {
    state.notification = payload;
  }),

  replaceNotification: action((state, payload) => {
    state.notification = payload;
    if (state.notifications.items) {
      const index = state.notifications.items.findIndex(
        e => e.id === payload.id
      );
      if (index >= 0) state.notifications.items.splice(index, 1, payload);
    }
  }),

  createNotification: thunk(async (_actions, payload, { injections }) => {
    const { notificationService } = injections;
    const response = await notificationService.createNotification(payload);

    return response.data;
  }),

  updateNotification: thunk(async (actions, payload, { injections }) => {
    const { notificationService } = injections;
    const response = await notificationService.updateNotification(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.updateNotification;
    actions.setNotification(item);
    actions.replaceNotification(item);

    return item;
  }),

  searchNotifications: thunk(async (actions, payload, { injections }) => {
    const { notificationService } = injections;
    const response = await notificationService.searchNotifications(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.searchNotifications) {
      const data = response.data.searchNotifications;
      actions.setNotifications({ data });

      return data;
    }

    return null;
  }),
  searchUnreadNotificationsTotal: thunk(
    async (actions, payload, { injections }) => {
      const { notificationService } = injections;
      const response = await notificationService.searchNotifications(payload);
      const errors = Object.prototype.hasOwnProperty.call(response, "errors");
      if (errors) return ErrorGraphQL(response);

      const { total } = response.data.searchNotifications;
      actions.setUnreadNotificationsTotal({ total });

      return total;
    }
  ),
};
