import React, { useState } from "react";
import classnames from "classnames";
import { ActionTypes } from "../../../../../../utils/types";
import contextStore from "../../../../../../redux/store";
import { getLocale } from "../../../../../../lang";
import { CustomHoliday } from "..";
import { ClosedDayItem } from "../../../../../../utils/dayslot";
import IntlMessages from "../../../../../../utils/messages";
import { displayDayLabel, isSameDay } from "../../../../../../utils/config";
import { GroundGraphqlContextStore } from "../../../../../../lib/ground-aws-graphql-core";

interface Props {
  holiday: CustomHoliday;
  isOpen: boolean;
  onChange: (e: ClosedDayItem) => void;
}

const CustomDaySlot = (props: Props): JSX.Element => {
  const { holiday, isOpen, onChange } = props;
  const [open, setIsOpen] = useState(isOpen);

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const handleChangeHoliday = () => {
    const { closedDay } = holiday;
    if (closedDay) {
      const exists = !!closedDay.item.id;
      const newOpen = !open;
      const action = newOpen
        ? ActionTypes.TO_DELETE
        : exists
        ? ActionTypes.TO_KEEP
        : ActionTypes.TO_ADD;

      setIsOpen(newOpen);
      const newClosedDay = { ...closedDay, action };
      onChange(newClosedDay);
    }
  };

  const className = classnames(
    "rounded-full p-1 px-3 text-white text-xs cursor-pointer",
    {
      "bg-ground-gray-400": !open,
      "bg-ground-green-100": open,
    }
  );

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  const sameDay = isSameDay(holiday.end, holiday.start);

  let closedDayLabel = `${displayDayLabel(
    holiday.start,
    currentAppLocale,
    centerTimezone
  )} - ${displayDayLabel(holiday.end, currentAppLocale, centerTimezone)}`;
  if (sameDay) {
    closedDayLabel = displayDayLabel(
      holiday.start,
      currentAppLocale,
      centerTimezone
    );
  }

  return (
    <td className="px-4 py-3 flex justify-between items-center">
      <span className={`text-blue-700 capitalize ${open && "line-through"}`}>
        {closedDayLabel}
      </span>
      <button type="button" className={className} onClick={handleChangeHoliday}>
        {open ? (
          <IntlMessages id="general.opened" />
        ) : (
          <IntlMessages id="general.closed" />
        )}
      </button>
    </td>
  );
};

export default CustomDaySlot;
