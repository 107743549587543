import React from "react";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import ProductForm from "../form";
import { withTwoColLayout } from "../../../../utils";
import { EnumServiceType } from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Service } from "../../../../lib/ground-aws-graphql-core/models/Service";
import PageSubheader from "../../../../components/PageSubheader";

interface Props {
  service: Service | null;
  serviceType: EnumServiceType;
  isOpen: boolean;
  handleClose: () => void;
  match: Match<{ cid: string; id: string; pid: string }>;
  intl: IntlShape;
  backURL: string;
}

const ProductCreate = (props: Props) => {
  const { intl, serviceType, match, backURL } = props;

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: `page.product.${serviceType.toLowerCase()}.create.document.title`,
          })}
        </title>
      </Helmet>

      <PageSubheader
        goBackEnabled
        goBackId={`btn-back-product-${serviceType.toLowerCase()}-create`}
        goBackURL={backURL.replace(":id", match.params.id)}
      />

      <ProductForm {...props} edition={false} />
    </>
  );
};

export default withTwoColLayout(injectIntl(ProductCreate));
