import axiosBackend from "../../../../../axiosBackend";
import {
  UpdateOrderItemStatusOpts,
  UpdateOrderStatusOpts,
} from "../../../models/Api/Order";

export const updateOrderStatus = async ({
  globalOrderId,
  orderId,
  status,
  paymentMethod,
}: UpdateOrderStatusOpts): Promise<any> => {
  return axiosBackend.put(`/global_orders/${globalOrderId}/orders/${orderId}`, {
    status,
    ...(paymentMethod && {
      payment_method: paymentMethod,
    }),
  });
};

export const updateOrderItemStatus = async ({
  globalOrderId,
  orderId,
  orderItemId,
  status,
  paymentMethod,
}: UpdateOrderItemStatusOpts): Promise<any> => {
  return axiosBackend.put(
    `/global_orders/${globalOrderId}/orders/${orderId}/order_items/${orderItemId}`,
    {
      status,
      ...(paymentMethod && {
        payment_method: paymentMethod,
      }),
    }
  );
};
