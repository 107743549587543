import React, { useState } from "react";
import { IVisitor } from "../../../../../components/Tailwind/Visitors/VisitorItem";
import ConfirmModal from "../../../../../utils/modal/confirm";
import IntlMessages from "../../../../../utils/messages";
import { FieldAttributes } from "formik";
import DefaultForm, {
  AdditionalFieldAttributes,
} from "../../../../../components/Form";
import { validateEmail } from "../../../../../utils/config";
import Block from "../../../../../components/Tailwind/Block";
import Header from "../../../../../components/Tailwind/Block/Header";
import { EnumPermissionEntity } from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import Button from "../../../../../components/Tailwind/Button";

type Props = {
  onAddVisitors: (visitors: IVisitor[]) => void;
};

const AddVisitors = (props: Props): JSX.Element => {
  const { onAddVisitors } = props;
  const [showAddVisitorsModal, setShowAddVisitorsModal] = useState(false);
  const [visitor, setVisitor] = useState<IVisitor>();

  const formFields: FieldAttributes<AdditionalFieldAttributes>[] = [
    {
      name: "firstname",
      label: "page.visit.create.visit.visitor.first_name",
      placeholder: "page.visit.create.visit.visitor.first_name",
      required: true,
      initialValue: "",
    },
    {
      name: "lastname",
      label: "page.visit.create.visit.visitor.last_name",
      placeholder: "page.visit.create.visit.visitor.last_name",
      required: true,
      initialValue: "",
    },
    {
      name: "email",
      label: "page.visit.create.visit.visitor.email",
      placeholder: "page.visit.create.visit.visitor.email",
      required: true,
      initialValue: "",
      validate: validateEmail,
    },
    {
      name: "company",
      label: "page.visit.create.visit.visitor.company_name",
      placeholder: "page.visit.create.visit.visitor.company_name",
      required: true,
      initialValue: "",
    },
  ];

  const handleSubmit = (values: IVisitor) => {
    setVisitor(values);
    setShowAddVisitorsModal(true);
  };

  return (
    <Block>
      <Header
        item={null}
        title="page.visit.add.visitor"
        entity={EnumPermissionEntity.VISIT}
        className="border-b border-gray-200"
      />
      <DefaultForm fields={formFields} onSubmit={handleSubmit} disablePanel>
        <div className="flex justify-center px-2 py-6">
          <Button id="btn-add-visitor" name="btn-add-visitor" type="submit">
            <span className="text-center">
              <IntlMessages id="general.submit" />
            </span>
          </Button>
        </div>
      </DefaultForm>
      <ConfirmModal
        isOpen={showAddVisitorsModal}
        onRequestClose={() => setShowAddVisitorsModal(!showAddVisitorsModal)}
        toggle={() => setShowAddVisitorsModal(!showAddVisitorsModal)}
        handleConfirm={() => onAddVisitors(visitor ? [visitor] : [])}
        content={<IntlMessages id="page.visit.add.visitors" />}
      />
    </Block>
  );
};

export default AddVisitors;
