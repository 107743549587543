import React, { useState } from "react";
import classnames from "classnames";
import { getTranslation } from "../../../utils/translation";
import { getImageUrl, getDefaultImage } from "../../../utils/picture";
import IntlMessages from "../../../utils/messages";
import { getCypressTestId } from "../../../utils/config";
import { EnumPermissionEntity } from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Center } from "../../../lib/ground-aws-graphql-core/models/Center";
import { Provider } from "../../../lib/ground-aws-graphql-core/models/Provider";

interface Props {
  item: Center | Provider;
  type: EnumPermissionEntity;
  displayable: boolean;
  edition: {
    onClick: (e: any, id: string) => void;
    label: string;
  };
  duplication?: {
    onClick: () => void;
    label: string;
  };
  canDuplicate: boolean;
}

const GridCard = (props: Props): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const { item, edition, displayable, type, duplication, canDuplicate } = props;
  const picture =
    item.pictures && item.pictures.length > 0 ? item.pictures[0] : null;

  const className = classnames(
    "flex flex-col rounded-lg hover:shadow-xl overflow-hidden border-05 border-blue-100",
    {
      "cursor-pointer": displayable,
    }
  );

  return (
    <div
      id={item.id}
      data-cy={`card-${type.toLowerCase()}`}
      data-testid={getCypressTestId(item)}
      key={item.id}
      className={className}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <div className="flex-shrink-0 relative">
        {visible && (
          <div className="flex flex-col absolute left-1/2 top-1/2">
            <button
              onClick={e => edition.onClick(e, item.id)}
              id={item.id}
              name={`btn-manage-${type.toLowerCase()}`}
              data-cy={`btn-manage-${type.toLowerCase()}`}
              data-testid={getCypressTestId(item)}
              type="button"
              className="
          px-4 py-2 border text-12px leading-5 font-medium rounded-5 transform
          -translate-y-1/2 -translate-x-1/2 border-transparent text-white bg-ground-blue-100"
            >
              <IntlMessages id={edition.label} />
            </button>

            {duplication && canDuplicate && (
              <button
                id={item.id}
                onClick={duplication.onClick}
                name={`btn-duplicate-${type.toLowerCase()}`}
                data-cy={`btn-duplicate-${type.toLowerCase()}`}
                data-testid={getCypressTestId(item)}
                type="button"
                className="
            px-4 py-2 mt-2 border text-12px leading-5 font-medium rounded-5 transform
            -translate-y-1/2 -translate-x-1/2 border-transparent text-white bg-ground-green-100"
              >
                <IntlMessages id={duplication?.label} />
              </button>
            )}
          </div>
        )}
        {picture && (
          <img
            className="h-48 w-full object-cover bg-ground-black-100 flex justify-center items-center"
            src={getImageUrl(null, picture)}
            alt={getTranslation(item.name)}
            onClick={e => edition.onClick(e, item.id)}
          />
        )}
        {!picture && (
          <div
            className="h-48 w-full object-cover bg-ground-black-100 flex justify-center items-center"
            onClick={e => edition.onClick(e, item.id)}
          >
            <img
              src={getDefaultImage(null)}
              alt={getTranslation(item.name)}
              onClick={e => edition.onClick(e, item.id)}
            />
          </div>
        )}
      </div>
      <div
        className="flex-1 bg-white p-6 flex flex-col justify-between"
        onClick={e => edition.onClick(e, item.id)}
      >
        <div className="flex-1">
          <p className="text-12px leading-5 font-medium text-gray-600">
            <IntlMessages id={`header.${type}`} />
          </p>
          <div className="block">
            <h3 className="text-lg leading-7 font-semibold text-gray-900">
              {item.name && getTranslation(item.name)}
            </h3>
            <p className="mt-3 text-14px leading-6 text-gray-600">
              {item.address && item.address}
              <br />
              {item.zipCode && item.zipCode} {item.city && item.city}
              <br />
              {item.country && item.country.toUpperCase()}
            </p>
          </div>
        </div>
        <div className="mt-3 flex justify-between">
          <span
            className={
              "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 text-white " +
              (item.enabled ? "bg-ground-green-100" : "bg-red-500")
            }
          >
            {item.enabled ? (
              <IntlMessages id="general.actif" />
            ) : (
              <IntlMessages id="general.inactif" />
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GridCard;
