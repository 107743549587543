import React from "react";
import { Helmet } from "react-helmet";
import { match as Match } from "react-router-dom";
import { injectIntl, IntlShape } from "react-intl";
import { withOneColLayout } from "../../../utils";
import PrivatizationForm from "../form";
import PageSubheader from "../../../components/PageSubheader";

interface Props {
  match: Match<{ id: string; privatizationId: string }>;
  intl: IntlShape;
  backURL: string;
}

const UpdatePrivatization = (props: Props) => {
  const { intl, backURL } = props;

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: "page.privatization.update.document.title",
          })}
        </title>
      </Helmet>

      <PageSubheader
        goBackEnabled
        goBackId="btn-back-privatization-update"
        goBackURL={backURL}
      />

      <PrivatizationForm {...props} edition />
    </>
  );
};

export default withOneColLayout(injectIntl(UpdatePrivatization));
