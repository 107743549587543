import { Action, action } from "easy-peasy";
import {
  defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
} from "../../../constants";

interface MenuOps {
  classname?: string;
  strCurrentClasses: string;
  clickIndex?: number;
}

// Interface declaration
export interface MenuModel {
  containerClassnames: string;
  subHiddenBreakpoint: number;
  menuHiddenBreakpoint: number;
  menuClickCount: number;
  changeDefaultClassnames: Action<MenuModel, string>;
  addContainerClassname: Action<MenuModel, MenuOps>;
  clickOnMobileMenu: Action<MenuModel, string>;
  setContainerClassnames: Action<MenuModel, MenuOps>;
}

const initialState = {
  containerClassnames: defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
  menuClickCount: 0,
};

export const menuModel: MenuModel = {
  ...initialState,
  changeDefaultClassnames: action((state, strCurrentClasses) => {
    state.containerClassnames = strCurrentClasses;
  }),
  addContainerClassname: action((state, payload) => {
    const { classname, strCurrentClasses } = payload;
    const newClasses = !(strCurrentClasses.indexOf(classname || "") > -1)
      ? strCurrentClasses + " " + classname
      : strCurrentClasses;

    state.containerClassnames = newClasses;
  }),
  clickOnMobileMenu: action((state, strCurrentClasses) => {
    const currentClasses = strCurrentClasses
      ? strCurrentClasses
          .split(" ")
          .filter(x => x !== "" && x !== "sub-show-temporary")
      : [""];
    let nextClasses = "";

    if (currentClasses.includes("main-show-temporary")) {
      nextClasses = currentClasses
        .filter(x => x !== "main-show-temporary")
        .join(" ");
    } else {
      nextClasses = currentClasses.join(" ") + " main-show-temporary";
    }
    state.containerClassnames = nextClasses;
    state.menuClickCount = 0;
  }),
  setContainerClassnames: action((state, payload) => {
    const { strCurrentClasses } = payload;
    let clickIndex = payload.clickIndex || 0;
    const currentClasses = strCurrentClasses
      ? strCurrentClasses.split(" ").filter(x => x !== "")
      : "";
    let nextClasses = "";
    if (clickIndex % 4 === 0) {
      if (
        currentClasses.includes("menu-default") &&
        currentClasses.includes("menu-sub-hidden")
      ) {
        nextClasses = "menu-default menu-sub-hidden";
      } else if (currentClasses.includes("menu-default")) {
        nextClasses = "menu-default";
      } else if (currentClasses.includes("menu-sub-hidden")) {
        nextClasses = "menu-sub-hidden";
      } else if (currentClasses.includes("menu-hidden")) {
        nextClasses = "menu-hidden";
      }
      clickIndex = 0;
    } else if (clickIndex % 4 === 1) {
      if (
        currentClasses.includes("menu-default") &&
        currentClasses.includes("menu-sub-hidden")
      ) {
        nextClasses = "menu-default menu-sub-hidden main-hidden sub-hidden";
      } else if (currentClasses.includes("menu-default")) {
        nextClasses = "menu-default sub-hidden";
      } else if (currentClasses.includes("menu-sub-hidden")) {
        nextClasses = "menu-sub-hidden main-hidden sub-hidden";
      } else if (currentClasses.includes("menu-hidden")) {
        nextClasses = "menu-hidden main-show-temporary";
      }
    } else if (clickIndex % 4 === 2) {
      if (
        currentClasses.includes("menu-default") &&
        currentClasses.includes("menu-sub-hidden")
      ) {
        nextClasses = "menu-default menu-sub-hidden sub-hidden";
      } else if (currentClasses.includes("menu-default")) {
        nextClasses = "menu-default main-hidden sub-hidden";
      } else if (currentClasses.includes("menu-sub-hidden")) {
        nextClasses = "menu-sub-hidden sub-hidden";
      } else if (currentClasses.includes("menu-hidden")) {
        nextClasses = "menu-hidden main-show-temporary sub-show-temporary";
      }
    } else if (clickIndex % 4 === 3) {
      if (
        currentClasses.includes("menu-default") &&
        currentClasses.includes("menu-sub-hidden")
      ) {
        nextClasses = "menu-default menu-sub-hidden sub-show-temporary";
      } else if (currentClasses.includes("menu-default")) {
        nextClasses = "menu-default sub-hidden";
      } else if (currentClasses.includes("menu-sub-hidden")) {
        nextClasses = "menu-sub-hidden sub-show-temporary";
      } else if (currentClasses.includes("menu-hidden")) {
        nextClasses = "menu-hidden main-show-temporary";
      }
    }
    if (currentClasses.includes("menu-mobile")) {
      nextClasses += " menu-mobile";
    }
    state.containerClassnames = nextClasses;
    state.menuClickCount = clickIndex;
  }),
};
