import React, { useState } from "react";
import Select from "react-select";
import { IntlShape } from "react-intl";
import { getTranslation } from "../../../../utils/translation";
import {
  EnumRefundReason,
  getRefundReasons,
  getOrderItemTotal,
  isRefundRequestedOrRefunded,
  markOrderAsPaid,
  getPaymentMethods,
} from "../../../../utils/order";
import { getFormattedPrice, getUnitLabel } from "../../../../utils/price-unit";
import IntlMessages from "../../../../utils/messages";
import DropDownStatus from "../../../../routes/management/global-orders/global-orders-list/order/status";
import { Order } from "../../../../lib/ground-aws-graphql-core/models/Order";
import { EnumOrderStatus } from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { GroundAuthContextStore } from "../../../../lib/ground-aws-cognito-auth-core";
import { OrderItem } from "../../../../lib/ground-aws-graphql-core/models/OrderItem/index";
import { BodyElement } from "../../../Table/types";
import { Center } from "../../../../lib/ground-aws-graphql-core/models/Center";
import { getBookingInBuildingTZ } from "../../../../utils/config";
import { PaymentMethod } from "../../../../lib/ground-aws-graphql-core/models/Api/PaymentMethods";
import { GlobalOrder } from "../../../../lib/ground-aws-graphql-core/models/GlobalOrder";

type OrderItemData = {
  orderItemVat: number | null;
  orderItemTotal: number;
  booking?: {
    sameDay: boolean;
    startDay: string;
    endDay: string;
    startHour: string;
    endHour: string;
  };
};

export const getDataRelativeToOrderItem = (
  center: Center,
  orderItem: OrderItem
): OrderItemData => {
  const { vat, unavailability } = orderItem;

  const percent = vat ? vat * 100 : null;
  const orderItemTotal = getOrderItemTotal(orderItem);

  if (unavailability) {
    const { sameDay, startDay, endDay, startHour, endHour } =
      getBookingInBuildingTZ(unavailability, center.timezone);

    return {
      orderItemVat: percent,
      orderItemTotal,
      booking: {
        sameDay,
        startDay,
        endDay,
        startHour,
        endHour,
      },
    };
  } else {
    return {
      orderItemVat: percent,
      orderItemTotal,
    };
  }
};

export const renderOrderItemNameCell = (
  name: string,
  relatedOrderItemId?: string,
  booking?: {
    sameDay: boolean;
    startDay: string;
    endDay: string;
    startHour: string;
    endHour: string;
  }
): JSX.Element => (
  <div className="flex flex-col items-start">
    <span>
      {getTranslation(name)}

      {relatedOrderItemId && (
        <span className="text-ground-gray-100">
          {" "}
          (<IntlMessages id="page.list.products.badge.option" />)
        </span>
      )}
    </span>

    {booking && !booking.sameDay && (
      <>
        <span className="text-ground-gray-100">
          <IntlMessages id="general.from" /> {booking.startDay}{" "}
          <IntlMessages id="general.at" /> {booking.startHour}
        </span>
        <span className="text-ground-gray-100">
          <IntlMessages id="general.to" /> {booking.endDay}{" "}
          <IntlMessages id="general.at" /> {booking.endHour}
        </span>
      </>
    )}

    {booking && booking.sameDay && (
      <div className="flex text-ground-gray-100">
        {booking.startDay} - {booking.startHour}
        {" > "}
        {booking.endHour}
      </div>
    )}
  </div>
);

const OrderItemElementsComponent = (
  center: Center,
  item: OrderItem,
  globalOrder: GlobalOrder,
  order: Order,
  intl: IntlShape,
  onChangeOrderStatus: (
    order: Order,
    orderItem: OrderItem | null | undefined,
    status: EnumOrderStatus
  ) => void,
  onRefund: (
    order: Order,
    orderItem: OrderItem | null | undefined,
    reason: EnumRefundReason
  ) => void,
  onPaid: (
    order: Order,
    orderItem: OrderItem | null | undefined,
    status: EnumOrderStatus,
    paymentMethod: string
  ) => void,
  orderDetailsLink: (() => void) | undefined,
  paymentMethods: PaymentMethod[]
): BodyElement[] => {
  const {
    name,
    relatedOrderItemId,
    quantity,
    unitQuantity,
    unit,
    unitPrice,
    currency,
    totalPrice,
    totalTax,
    status,
    id,
  } = item;

  const [reason, setReason] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(false);

  const { orderItemVat, orderItemTotal, booking } = getDataRelativeToOrderItem(
    center,
    item
  );

  const withTransfer = order.transactionFeePercent > 0;

  const meDetails = GroundAuthContextStore.useStoreState(
    state => state.authentication.meDetails
  );

  const orderItemElements: BodyElement[] = [
    {
      element: renderOrderItemNameCell(name, relatedOrderItemId, booking),
      onCellClick: orderDetailsLink,
    },
    {
      element: (
        <span>
          {getFormattedPrice(unitPrice, currency)}{" "}
          <IntlMessages id="general.ht" />
        </span>
      ),
      onCellClick: orderDetailsLink,
    },
    {
      element: getTranslation(order.provider.name),
      onCellClick: orderDetailsLink,
    },
    {
      element: (
        <span>
          {quantity} <IntlMessages id="general.pers" /> x {unitQuantity}{" "}
          {getUnitLabel(intl, unit)}
        </span>
      ),
      onCellClick: orderDetailsLink,
    },
    {
      element:
        orderItemTotal > 0 ? getFormattedPrice(totalPrice, currency) : null,
      onCellClick: orderDetailsLink,
    },
    {
      element:
        orderItemTotal > 0
          ? `${getFormattedPrice(totalTax, currency)} (${orderItemVat} %)`
          : null,
      onCellClick: orderDetailsLink,
    },
    {
      element:
        orderItemTotal > 0 ? (
          getFormattedPrice(orderItemTotal, currency)
        ) : (
          <IntlMessages id="general.free" />
        ),
      onCellClick: orderDetailsLink,
    },
    {
      element: (
        <>
          <DropDownStatus
            operator={meDetails?.operator}
            globalOrder={globalOrder}
            order={order}
            orderItem={item}
            status={status}
            displayRefundReason
            onChangeOrderStatus={e => {
              const markToPaid = markOrderAsPaid(status, e.value);
              if (markToPaid) {
                setPaymentMethod(true);
              } else if (!isRefundRequestedOrRefunded(e.value)) {
                onChangeOrderStatus(order, item, e.value);
              } else {
                // show reason
                setReason(true);
              }
            }}
            className="items-start"
          />
          {reason && (
            <div id={`refund_status_${id}`} className="mt-2">
              <Select
                placeholder={intl.formatMessage({
                  id: "general.reason",
                })}
                defaultValue={EnumRefundReason.REQUESTED_BY_CUSTOMER}
                onChange={e => {
                  onRefund(order, item, e.value);
                }}
                options={getRefundReasons(intl)}
                menuPortalTarget={document.body}
              />
            </div>
          )}
          {paymentMethod && (
            <div className="mt-2">
              <Select
                placeholder={intl.formatMessage({
                  id: "general.payment_method",
                })}
                defaultValue=""
                onChange={e => {
                  onPaid(order, item, EnumOrderStatus.PAID, e.value);
                }}
                options={getPaymentMethods(paymentMethods)}
                menuPortalTarget={document.body}
              />
            </div>
          )}
        </>
      ),
    },
  ];

  if (withTransfer) {
    orderItemElements.splice(
      3,
      0,
      {
        element: (
          <div className="flex flex-col items-start">
            {orderItemTotal > 0 && order.transactionFeePercent && (
              <span>
                {getFormattedPrice(
                  (totalPrice * (100 - order.transactionFeePercent)) / 100,
                  currency
                )}
              </span>
            )}
          </div>
        ),
        onCellClick: orderDetailsLink,
      },
      {
        element: (
          <div className="flex flex-col items-start">
            {orderItemTotal > 0 && order.transactionFeePercent && (
              <span>
                {getFormattedPrice(
                  (totalPrice * order.transactionFeePercent) / 100,
                  currency
                )}{" "}
                ({order.transactionFeePercent} %)
              </span>
            )}
          </div>
        ),
        onCellClick: orderDetailsLink,
      }
    );
  }

  return orderItemElements;
};

export default OrderItemElementsComponent;
