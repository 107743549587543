import React from "react";
import IntlMessages from "../../../utils/messages";

interface NavProps {
  id: string;
  index: number;
  icon: any;
  text: any;
  type?: any;
  children?: React.ReactNode;
  withoutTitle?: boolean;
}

const NavItem = ({
  id,
  index,
  icon,
  text,
  children,
  withoutTitle,
}: NavProps): JSX.Element => (
  <div id={id} data-cy={id} data-testid={index} className="mt-1">
    {!withoutTitle && (
      <h3 className="group flex items-center px-7 uppercase py-2 leading-5 text-11px font-thin rounded-md text-gray-500 focus:outline-none transition ease-in-out duration-150 cursor-default">
        <img
          src={icon}
          style={{ width: 20, height: 20 }}
          alt=""
          className="mr-2"
        />
        <span className="truncate tracking-widest" data-testid={index}>
          <IntlMessages id={text} />
        </span>
      </h3>
    )}
    {children}
  </div>
);

export default NavItem;
