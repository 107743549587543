import React from "react";
import classnames from "classnames";

interface Props {
  picture: string | null;
  notification: boolean;
  enabled: boolean;
}

const Avatar = (props: Props): JSX.Element => {
  const className = classnames(
    "absolute bottom-0 right-0 block h-2 w-2 rounded-full text-white shadow-solid",
    {
      "bg-red-400": !props.enabled,
      "bg-green-400": props.enabled,
    }
  );

  return (
    <>
      {!props.picture && (
        <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
          <svg
            className="h-full w-full text-gray-300"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      )}
      {props.picture && (
        <span className="inline-block relative flex items-center">
          <img className="h-8 w-8 rounded-full" src={props.picture} alt="" />
          {props.notification && <span className={className}></span>}
        </span>
      )}
    </>
  );
};

export default Avatar;
