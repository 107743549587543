import React, { useEffect, useState } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Footer from "../../../components/Tailwind/Block/Footer";
import GroundFormik from "../../../components/Tailwind/Form";
import Block from "../../../components/Tailwind/Block";
import Header from "../../../components/Tailwind/Block/Header";
import { FormValue } from "../../../utils/types";
import { EnumPermissionEntity } from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import contextStore from "../../../redux/store";
import history from "../../../history";
import IntlMessages from "../../../utils/messages";
import { SpacesTable, UsersTable } from "./tables";
import overrideClasses from "../../../utils/overrideClasses";

interface Props {
  intl: IntlShape;
  match: Match;
  edition: boolean;
}

const minimumNameLength = 3;
const siretLength = 14;

const EnterpriseForm = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [togglePanel, setTogglePanel] = useState(false);

  const { intl, match, edition } = props;

  const enterprise = contextStore.useStoreState(s => s.enterprise.enterprise);

  const getEnterprise = contextStore.useStoreActions(
    a => a.enterprise.getEnterprise
  );

  const createEnterprise = contextStore.useStoreActions(
    a => a.enterprise.createEnterprise
  );

  const updateEnterprise = contextStore.useStoreActions(
    a => a.enterprise.updateEnterprise
  );

  const privatizations = contextStore.useStoreState(
    s => s.privatization.privatizations.items
  );

  const listEnterprisePrivatizations = contextStore.useStoreActions(
    a => a.privatization.listEnterprisePrivatizations
  );

  useEffect(() => {
    if (edition) {
      getEnterprise({ id: match.params.id });
      listEnterprisePrivatizations({ enterpriseId: match.params.id });
    }
  }, []);

  const getNamePolicy = () => {
    const message = intl.formatMessage(
      { id: "page.enterprise.validate.enterpriseName" },
      { length: minimumNameLength }
    );

    return Yup.string().min(minimumNameLength, message);
  };

  const getSiretPolicy = () => {
    const message = intl.formatMessage(
      { id: "page.enterprise.validate.siret" },
      { length: siretLength }
    );

    return Yup.string().matches(/^[0-9]{14}$/, message);
  };

  const schema = Yup.object().shape({
    enterpriseName: getNamePolicy(),
    siret: getSiretPolicy(),
  });

  const handleSubmit = values => {
    setLoading(true);

    if (edition) {
      if (enterprise) {
        updateEnterprise({ id: enterprise.id, ...values })
          .then(() => {
            history.goBack();
            toast(
              props.intl.formatMessage({
                id: "page.enterprise.update.success",
              }),
              { type: "success" }
            );
          })
          .catch(() => {
            toast(
              props.intl.formatMessage({
                id: "page.enterprise.update.error",
              }),
              { type: "error" }
            );
          })
          .finally(() => setLoading(false));
      }
    } else {
      createEnterprise(values)
        .then(() => {
          history.goBack();
          toast(
            props.intl.formatMessage({
              id: "page.enterprise.create.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          toast(
            props.intl.formatMessage({
              id: "page.enterprise.create.error",
            }),
            { type: "error" }
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const handleShowSavePanel = () => {
    setTogglePanel(true);
  };

  const handleHideSavePanel = () => {
    setTogglePanel(false);
  };

  const values: FormValue[] = [
    {
      id: "name",
      name: "name",
      type: "text",
      label: "general.enterpriseName",
      placeholder: "general.enterpriseName",
      required: true,
      value: edition && enterprise ? enterprise.name : "",
      useIntl: true,
    },
    {
      id: "siret",
      name: "siret",
      type: "text",
      label: "general.siret",
      placeholder: "general.siret",
      required: true,
      value: edition && enterprise ? enterprise.siret : "",
      useIntl: true,
    },
    {
      id: "paymentOnInvoice",
      name: "paymentOnInvoice",
      type: "switch",
      label: "general.payment.invoice",
      placeholder: "general.payment.invoice",
      description: "general.payment.invoice.description",
      value: enterprise?.paymentOnInvoice ?? false,
      useIntl: true,
    },
  ];

  return (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={overrideClasses({ loading })} />

      {/* Hide when loading */}
      <div className={overrideClasses("px-8", { hidden: loading })}>
        <Block>
          <Header
            item={edition && enterprise ? enterprise : undefined}
            title={
              edition
                ? "page.enterprise.update.title"
                : "page.enterprise.create.title"
            }
            entity={EnumPermissionEntity.ENTERPRISE}
            checked
            className="border-b border-gray-200"
          />

          <GroundFormik
            item={edition && enterprise ? enterprise : undefined}
            values={values}
            validationSchema={schema}
            onSubmit={handleSubmit}
            onChange={handleShowSavePanel}
            showSavePanel={togglePanel}
            onHideSavePanel={handleHideSavePanel}
          >
            <Footer
              item={edition && enterprise ? enterprise : undefined}
              index={values.length + 1}
              labels={["general.cancel", "general.delete"]}
            />
          </GroundFormik>
        </Block>

        {edition && (
          <>
            <div>
              <span className="text-16px">
                <IntlMessages id="page.enterprise.privatized.spaces.title" />
              </span>

              <SpacesTable
                privatizations={privatizations}
                setLoading={setLoading}
                intl={intl}
                onDeleted={() =>
                  listEnterprisePrivatizations({
                    enterpriseId: match.params.id,
                  })
                }
              />
            </div>
            <div className="flex flex-col my-8">
              <span className="text-16px">
                <IntlMessages id="page.enterprise.users.title" />
              </span>

              <UsersTable enterprise={enterprise} intl={intl} />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default injectIntl(EnterpriseForm);
