import React from "react";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import NewsForm from "../form";
import { withTwoColLayout } from "../../../../utils";
import PageSubheader from "../../../../components/PageSubheader";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  match: Match<{ cid: string; id: string }>;
  intl: IntlShape;
  backURL: string;
}

const NewsCreate = (props: Props) => {
  const { intl, backURL } = props;

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: "page.news.create.document.title" })}
        </title>
      </Helmet>

      <PageSubheader
        goBackEnabled
        goBackId="btn-back-news-create"
        goBackURL={backURL}
      />

      <NewsForm {...props} edition={false} />
    </>
  );
};

export default withTwoColLayout(injectIntl(NewsCreate));
