import React, { forwardRef } from "react";

interface Props {
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  dataCy?: string;
  onClick?: (e: any) => void;
}

const InputDatePicker = ({
  value,
  disabled,
  placeholder,
  dataCy,
  onClick,
}: Props): JSX.Element => (
  <button
    className="w-full h-10 form-input rounded-md"
    onClick={onClick}
    data-cy={dataCy}
    type="button"
    disabled={disabled || false}
  >
    <span className="px-5">{value || placeholder}</span>
  </button>
);

export default forwardRef(InputDatePicker);
