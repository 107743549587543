import React, { useState } from "react";
import { IntlShape } from "react-intl";
import { toast } from "react-toastify";
import {
  IncidentStatus,
  Incident,
} from "../../../lib/ground-aws-graphql-core/models/Api/Incidents/index";
import { getTranslation } from "../../../utils/translation/index";
import ConfirmModal from "../../../utils/modal/confirm";
import IntlMessages from "../../../utils/messages";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import FilterSelect from "../../../components/Tailwind/Filters/select";
import { EnumFilterFields, EnumFilterType } from "../../../utils/filter";
import { EnumIncidentStatus } from "../../../utils/order";
import { FilterDate } from "../Filters";
import { Center } from "../../../lib/ground-aws-graphql-core/models/Center";

interface Props {
  center?: Center | null;
  incidentsStatus: IncidentStatus[];
  incident: Incident;
  intl: IntlShape;
  className: string;
}

const DropdownIncidentsStatus = ({
  incident,
  incidentsStatus,
  intl,
  className,
}: Props): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { id, ref, status } = incident;

  const [resolutionDate, setResolutionDate] = useState<Date | null>(
    incident.resolution_date ? new Date(incident.resolution_date) : null
  );

  const currentStatus = {
    value: `${status.code}`,
    label: getTranslation(status.label),
  };

  const [selectedStatus, setSelectedStatus] = useState<{
    value: string;
    label: string;
  }>(currentStatus);

  const updateIncident = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.updateIncident
  );

  const confirmUpdateIncidentStatus = async () => {
    if (selectedStatus) {
      updateIncident({
        id,
        status_code: parseInt(selectedStatus.value),
        ...(resolutionDate && {
          resolution_date: resolutionDate,
        }),
      })
        .then(() => {
          toast(
            intl.formatMessage({ id: "page.incidents.change.status.success" }),
            { type: "success" }
          );
          setIsModalOpen(false);
        })
        .catch(() => {
          toast(
            intl.formatMessage({ id: "page.incidents.change.status.error" }),
            { type: "error" }
          );
          setIsModalOpen(false);
        });
    }
  };

  const field = {
    value: EnumFilterFields[EnumFilterFields.STATUS],
    type: EnumFilterType.SELECT,
    async: false,
    options: incidentsStatus.map(o => ({
      label: getTranslation(o.label),
      value: `${o.code}`,
    })),
  };

  const onChangeDate = (d: Date) => {
    setIsModalOpen(!isModalOpen);
    setResolutionDate(d);
  };

  return (
    <>
      <div className={`flex flex-col ${className} w-full`} key={ref}>
        <div id={ref} data-cy="incident-status-selector" data-testid={ref}>
          <FilterSelect
            selectedValue={currentStatus.value}
            defaultValue={false}
            className="w-48"
            fields={[field]}
            onChange={(_type, target) => {
              setSelectedStatus(target);
              setResolutionDate(null);
              const displayResolutionDate = [
                EnumIncidentStatus.CLOSED,
                EnumIncidentStatus.CANCELED,
              ].includes(parseInt(target.value));
              if (!displayResolutionDate) {
                setIsModalOpen(!isModalOpen);
              }
            }}
          />
          {[EnumIncidentStatus.CLOSED, EnumIncidentStatus.CANCELED].includes(
            parseInt(selectedStatus.value)
          ) && (
            <div className="mt-2">
              {!resolutionDate && (
                <span className="italic text-red-500">
                  <IntlMessages id="page.incidents.enter.resolution.date" />
                </span>
              )}
              <FilterDate
                onChange={onChangeDate}
                date={resolutionDate}
                title="page.incidents.resolution.date"
              />
            </div>
          )}
        </div>
      </div>
      <ConfirmModal
        isOpen={isModalOpen}
        toggle={() => {
          setIsModalOpen(!isModalOpen);
        }}
        onRequestClose={() => {
          setIsModalOpen(!isModalOpen);
        }}
        handleConfirm={confirmUpdateIncidentStatus}
        content={
          <IntlMessages
            id="page.incidents.change.status.confirm"
            values={{
              ref,
              status: selectedStatus?.label,
            }}
          />
        }
      />
    </>
  );
};

export default DropdownIncidentsStatus;
