/*
 *
 * PriceUnit Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import {
  CreatePriceUnitMutation,
  UpdatePriceUnitMutation,
  DeletePriceUnitMutation,
} from "../../api/graphql/types";

import {
  createPriceUnit as createPriceUnitMutation,
  updatePriceUnit as updatePriceUnitMutation,
  deletePriceUnit as deletePriceUnitMutation,
} from "../../api/graphql/mutations";

export const createPriceUnit = payload =>
  ApolloClient.mutate({
    mutation: gql(createPriceUnitMutation),
    variables: { input: payload },
  }) as {
    data: CreatePriceUnitMutation;
  };

export const updatePriceUnit = payload =>
  ApolloClient.mutate({
    mutation: gql(updatePriceUnitMutation),
    variables: { input: payload },
  }) as {
    data: UpdatePriceUnitMutation;
  };

export const deletePriceUnit = payload =>
  ApolloClient.mutate({
    mutation: gql(deletePriceUnitMutation),
    variables: { input: payload },
  }) as {
    data: DeletePriceUnitMutation;
  };
