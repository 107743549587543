import { action, Action, Thunk, thunk } from "easy-peasy";
import { Injections } from "../../../store";
import { StoreModel } from "../..";
import { IListResponse } from "..";
import { EnumWebhookEventType } from "../RefundRequests";

export interface Webhook {
  id: number;
  url: string;
  events: EnumWebhookEventType[];
  enabled: boolean;
  secret: string;
  created_at: string;
  updated_at: string;
}

export interface GetWebhookOpts {
  id: string;
}

export interface DeleteWebhookOpts {
  id: number;
}

export interface ListWebhookOpts {
  limit: number;
  offset: number;
}

export interface CreateWebhookOpts {
  url: string;
  events: EnumWebhookEventType[];
}

export interface UpdateWebhooksOpts {
  id: number;
  url?: number;
  events?: EnumWebhookEventType[];
  enabled?: boolean;
}

export interface WebhookModel {
  webhooks: {
    items: Webhook[];
    total: number;
    has_more: boolean;
  };
  webhook: Webhook | null;

  setWebhook: Action<WebhookModel, Webhook | null>;
  setWebhooks: Action<
    WebhookModel,
    {
      items: Webhook[];
      total: number;
      has_more: boolean;
    }
  >;
  removeWebhook: Action<WebhookModel, DeleteWebhookOpts>;

  getWebhook: Thunk<WebhookModel, GetWebhookOpts, Injections, StoreModel>;
  listWebhooks: Thunk<WebhookModel, ListWebhookOpts, Injections, StoreModel>;
  createWebhook: Thunk<WebhookModel, CreateWebhookOpts, Injections, StoreModel>;
  updateWebhook: Thunk<
    WebhookModel,
    UpdateWebhooksOpts,
    Injections,
    StoreModel
  >;
  deleteWebhook: Thunk<WebhookModel, DeleteWebhookOpts, Injections, StoreModel>;
}

export const webhookModel: WebhookModel = {
  webhooks: { items: [], total: 0, has_more: false },
  webhook: null,
  setWebhook: action((state, payload: Webhook) => {
    state.webhook = payload;
  }),
  setWebhooks: action((state, payload) => {
    state.webhooks = payload;
  }),
  removeWebhook: action((state, payload) => {
    state.webhooks.items = state.webhooks.items.filter(
      w => w.id !== payload.id
    );
    state.webhooks.total = state.webhooks.total - 1;
  }),
  getWebhook: thunk(
    async (actions, payload: GetWebhookOpts, { injections }) => {
      const { webhooksService } = injections;
      const response = await webhooksService.getWebhook(payload.id);
      if (response.status === 200 && response.data.success) {
        actions.setWebhook(response.data.data);
      }
    }
  ),
  listWebhooks: thunk(
    async (actions, payload: ListWebhookOpts, { injections }) => {
      const { webhooksService } = injections;
      const response = await webhooksService.listWebhooks(
        payload.offset,
        payload.limit
      );
      if (response.status === 200 && response.data.success) {
        const resp: IListResponse<Webhook> = response.data;
        actions.setWebhooks(resp.data);
      }
    }
  ),
  createWebhook: thunk(
    async (actions, payload: CreateWebhookOpts, { injections }) => {
      const { webhooksService } = injections;
      const response = await webhooksService.createWebhook(payload);
      if (response.status === 201 && response.data.success) {
        actions.setWebhook(response.data.data);
      }
    }
  ),
  updateWebhook: thunk(
    async (actions, payload: UpdateWebhooksOpts, { injections, getState }) => {
      const { webhooksService } = injections;

      const response = await webhooksService.updateWebhook(payload);

      if (response.status === 200 && response.data.success) {
        const updatedWebhook = response.data.data;
        actions.setWebhook(updatedWebhook);

        const state = getState();
        const webhookIndex = state.webhooks.items.findIndex(
          webhook => webhook.id === updatedWebhook.id
        );

        if (webhookIndex !== -1) {
          const newWebhooksItems = [...state.webhooks.items];
          newWebhooksItems.splice(webhookIndex, 1, updatedWebhook);

          actions.setWebhooks({
            ...state.webhooks,
            items: newWebhooksItems,
          });
        }
      }

      return response.data;
    }
  ),
  deleteWebhook: thunk(
    async (actions, payload: DeleteWebhookOpts, { injections }) => {
      const { webhooksService } = injections;
      const response = await webhooksService.deleteWebhook(payload.id);
      if (response.status === 200 && response.data.success) {
        actions.setWebhook(null);
        actions.removeWebhook(payload);
      }
    }
  ),
};
