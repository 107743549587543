import {
  DisplayConfiguration,
  defaultBackOfficeDisplay,
} from "./backoffice/display";

export type Action = "INSERT" | "MODIFY" | "REMOVE";
export const models = ["Center", "Product", "Unavailability"] as const;
export type Model = (typeof models)[number];

export type OperatorConfig = {
  operatorId?: string;
  triggers?: {
    /** List of table names and corresponding actions by event types */
    preprocessing?: any;
  };
  baseURL?: string;
  authorization?: string;
  backoffice?: {
    display?: DisplayConfiguration;
  };
};

export const defaultConfig: OperatorConfig = {
  backoffice: {
    display: defaultBackOfficeDisplay,
  },
};

export const getOperatorConfiguration = (
  operatorId?: string
): OperatorConfig => {
  //TODO: requêter sur la base et récupérer la ligne si elle existe.
  // Puis récupération du locale. Ensuite je fusionne les 2. Permet de coexister et gérer la complexité
  // Ajouter un objet options en any qui permet de choisir le mode : 1, 2 ou 1 et 2. -- {disable database: true} = FEATURE FLIPPING (ne pas mettre le nom du client!!). Intégrer les options dans la configuration côté BDD

  if (!operatorId) return defaultConfig;

  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const config = require(`./configurations/${operatorId}`)
      .default as OperatorConfig;

    return {
      ...defaultConfig,
      operatorId,
      ...config,
      backoffice: {
        display: {
          ...defaultConfig.backoffice?.display,
          ...config.backoffice?.display,
        },
      },
    };
  } catch {
    console.warn(
      `Configuration file "${operatorId}" not found, take default config`
    );

    return defaultConfig;
  }
};
