import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import { EnumPermissionEntity } from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import { getSortFields } from "../../../utils/filter";
import SortSelector from "./select";

export interface SortProps {
  permissionEntity: EnumPermissionEntity;
  onChange: (field: string, direction: string) => void;
  intl: IntlShape;
}

export const SortsSelector = injectIntl((props: SortProps) => {
  const { permissionEntity, onChange } = props;
  const fields = getSortFields(permissionEntity);
  if (!fields || fields.length === 0) return null;
  const values = fields.map(field => {
    return { ...field, onChange };
  });

  return <SortSelector sorts={values} />;
});
