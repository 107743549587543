import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import {
  EnumLocale,
  UpdateNotificationMutation,
} from "../../api/graphql/types";
import { searchNotifications as searchNotificationsQuery } from "../../api/graphql/queries";
import { updateNotification as updateNotificationMutation } from "../../api/graphql/mutations";
import {
  CreateNotificationOpts,
  SearchNotificationsOpts,
} from "../../models/Notification";
import axiosBackend from "../../../../axiosBackend";

const DEFAULT_LIMIT = 100;

export const createNotification = (payload: CreateNotificationOpts) => {
  // create new FormData object
  const data = new FormData();
  data.append("type", payload.type);
  data.append("building_id", payload.building_id);

  Object.keys(payload.contents).forEach(lang => {
    const content = payload.contents[`${lang}`];
    data.append(`contents[${lang}]`, content);
  });

  Object.keys(payload.recipients).forEach(recipient => {
    const value = payload.recipients[`${recipient}`];
    if (value instanceof Array) {
      value.forEach((val, index) => {
        data.append(`recipients[${recipient}][${index}]`, val);
      });
    } else {
      data.append(`recipients[${recipient}]`, value);
    }
  });

  if (payload.headings) {
    Object.keys(payload.headings).forEach((lang: EnumLocale) => {
      const heading = payload.headings[`${lang}`];
      data.append(`headings[${lang}]`, heading);
    });
  }

  if (payload.send_after) {
    data.append("send_after", payload.send_after);
  }

  // append files
  if (payload.pictures && payload.pictures.length) {
    const picture = payload.pictures[0];
    if (picture.file) {
      const { file } = picture;
      data.append("picture", file);
    }
  }

  return axiosBackend.post("/notifications", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateNotification = payload =>
  ApolloClient.mutate({
    mutation: gql(updateNotificationMutation),
    variables: { input: payload },
  }) as {
    data: UpdateNotificationMutation;
  };

export const searchNotifications = (payload: SearchNotificationsOpts) => {
  const request = payload.gql ? payload.gql : searchNotificationsQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: {
      filter: payload.filter,
      sort: payload.sort,
      limit: payload && payload.limit ? payload.limit : DEFAULT_LIMIT,
      from: payload && payload.from ? payload.from : 0,
    },
  }) as {
    data: any;
  };
};
