import classNames from "classnames";
import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import ReactPaginate from "react-paginate";

interface Props {
  total: number;
  onPageChange: (page: number) => void;
  limit: number;
  forcePage: number;
  intl: IntlShape;
  className?: string;
}

const Paginate = (props: Props): JSX.Element => {
  const { total, onPageChange, limit, forcePage, intl, className } = props;

  const textClassName = "text-14px leading-5 text-gray-600 mx-2 align-middle";

  return (
    <ReactPaginate
      previousLabel={intl.formatMessage({ id: "general.previous" })}
      nextLabel={intl.formatMessage({ id: "general.next" })}
      breakLabel="..."
      pageCount={total && total > limit ? Math.ceil(total / limit) : 0}
      forcePage={forcePage}
      marginPagesDisplayed={5}
      pageRangeDisplayed={5}
      onPageChange={({ selected }) => onPageChange(selected)}
      breakClassName={textClassName}
      previousClassName={textClassName}
      nextClassName={textClassName}
      previousLinkClassName="outline-none"
      nextLinkClassName="outline-none"
      containerClassName={classNames(
        "flex justify-center items-center text-lg",
        className
      )}
      activeClassName="text-ground-blue-100"
      disabledClassName="text-gray-500"
      pageClassName={textClassName}
      pageLinkClassName="outline-none"
    />
  );
};

export default injectIntl(Paginate);
