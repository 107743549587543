import { Promise } from "bluebird";
import { ActionTypes } from "../../../../utils/types";
import {
  CreateSingleProductInput,
  DeleteSingleProductInput,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Product } from "../../../../lib/ground-aws-graphql-core/models/Product";
import { Operator } from "../../../../lib/ground-aws-graphql-core/models/Operator";

export const getSingleProducts = (
  singleProducts: { id: string; singleProduct: Product; action: ActionTypes }[],
  product: Product | null,
  center: { id: string; operator: Operator | null | undefined }
) => {
  const singleProductsToCreate: CreateSingleProductInput[] = [];
  const singleProductsToDelete: DeleteSingleProductInput[] = [];

  if (product) {
    singleProducts.forEach(el => {
      if (el.action === ActionTypes.TO_ADD) {
        const params: CreateSingleProductInput = {
          productProductsId: product.id,
          singleProductCenterId: center.id,
          singleProductProductId: el.singleProduct.id,
        };
        singleProductsToCreate.push(params);
      }
      if (el.action === ActionTypes.TO_DELETE) {
        const params: DeleteSingleProductInput = {
          id: el.id,
        };
        singleProductsToDelete.push(params);
      }
    });
  }

  return {
    singleProductsToCreate,
    singleProductsToDelete,
  };
};

export function createSingleProductsPromise(
  singleProducts: CreateSingleProductInput[],
  callbacks: {
    createSingleProduct: any;
  }
) {
  return new Promise((resolve): void => {
    createSingleProducts(singleProducts, callbacks, (_error, data) => {
      resolve(data);
    });
  });
}

type Callback = (error?: Error | null, data?: { success: boolean }) => void;

function createSingleProducts(
  singleProducts: CreateSingleProductInput[],
  callbacks: {
    createSingleProduct: any;
  },
  callback: Callback
) {
  if (singleProducts && singleProducts.length > 0) {
    Promise.mapSeries(singleProducts, params => {
      return callbacks.createSingleProduct(params);
    })
      .then(() => {
        callback(null, { success: true });
      })
      .catch(() => {
        console.error("SINGLE PRODUCTS CREATION FAILED !");
        callback(null, { success: false });
      });
  } else {
    callback(null, { success: true });
  }
}

export function deleteSingleProductsPromise(
  singleProducts: DeleteSingleProductInput[],
  callbacks: {
    deleteSingleProduct: any;
  }
) {
  return new Promise((resolve): void => {
    deleteSingleProducts(singleProducts, callbacks, (_error, data) => {
      resolve(data);
    });
  });
}

function deleteSingleProducts(
  singleProducts: DeleteSingleProductInput[],
  callbacks: {
    deleteSingleProduct: any;
  },
  callback: Callback
) {
  if (singleProducts && singleProducts.length > 0) {
    Promise.mapSeries(singleProducts, params => {
      return callbacks.deleteSingleProduct(params);
    })
      .then(() => {
        callback(null, { success: true });
      })
      .catch(() => {
        console.error("SINGLE PRODUCTS DELETE FAILED !");
        callback(null, { success: false });
      });
  } else {
    callback(null, { success: true });
  }
}
