/*
 *
 * Category Service
 *
 */
import { gql } from "apollo-boost";
import { AxiosResponse } from "axios";
import { ApolloClient } from "../../store/provider";
import {
  CreateCategoryMutation,
  UpdateCategoryMutation,
} from "../../api/graphql/types";

import {
  listCategories as listCategoriesQuery,
  searchAllCategories as searchAllCategoriesQuery,
} from "../../api/graphql/queries";

import {
  createCategory as createCategoryMutation,
  updateCategory as updateCategoryMutation,
  deleteCategory as deleteCategoryMutation,
} from "../../api/graphql/mutations";

import { fetchPolicy } from "../../api/client";
import {
  DuplicateCategoryOpts,
  ListCategoriesOpts,
  ModelCategoryConnection,
  SearchCategoriesOpts,
} from "../../models/Category";
import axiosBackend from "../../../../axiosBackend";

const DEFAULT_LIMIT = 100;

export const listCategories = (payload: ListCategoriesOpts) => {
  const request = payload.gql ? payload.gql : listCategoriesQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: {
      filter: payload ? (payload.filter ? payload.filter : null) : null,
      limit: payload.limit || DEFAULT_LIMIT,
    },
    fetchPolicy,
  }) as {
    data: {
      listCategories: ModelCategoryConnection;
    };
  };
};

export const createCategory = payload =>
  ApolloClient.mutate({
    mutation: gql(createCategoryMutation),
    variables: { input: payload },
  }) as {
    data: CreateCategoryMutation;
  };

export const updateCategory = payload =>
  ApolloClient.mutate({
    mutation: gql(updateCategoryMutation),
    variables: { input: payload },
  }) as {
    data: UpdateCategoryMutation;
  };

export const duplicateCategory = async (
  payload: DuplicateCategoryOpts
): Promise<AxiosResponse> => {
  return axiosBackend.post(
    `/buildings/${payload.buildingId}/categories/${payload.categoryId}/duplicate`,
    {
      ...(payload.targetBuildingId && {
        building_id: payload.targetBuildingId,
      }),
    }
  );
};

export const deleteCategory = payload =>
  ApolloClient.mutate({
    mutation: gql(deleteCategoryMutation),
    variables: { input: payload },
  }) as {
    data: UpdateCategoryMutation;
  };

export const searchAllCategories = (payload: SearchCategoriesOpts) => {
  const request = payload.gql ? payload.gql : searchAllCategoriesQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: {
      filter: payload.filter,
      sort: payload.sort,
      limit: payload?.limit || DEFAULT_LIMIT,
      from: payload?.from || 0,
    },
  }) as {
    data: {
      searchAllCategories: ModelCategoryConnection;
    };
  };
};
