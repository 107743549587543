/*
 *
 * Attribute Key Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import { CreateAuthorizationMutation } from "../../api/graphql/types";

import {
  searchAuthorizations as searchAuthorizationsQuery,
  searchAllAuthorizations as searchAllAuthorizationsQuery,
} from "../../api/graphql/queries";

import { createAuthorization as createAuthorizationMutation } from "../../api/graphql/mutations";

import { fetchPolicy } from "../../api/client";
import { SearchAuthorizationsOpts } from "../../models/Authorization";

const DEFAULT_LIMIT = 100;

export const createAuthorization = payload =>
  ApolloClient.mutate({
    mutation: gql(createAuthorizationMutation),
    variables: { input: payload },
  }) as {
    data: CreateAuthorizationMutation;
  };

export const searchAuthorizations = (payload: SearchAuthorizationsOpts) => {
  const request = payload.gql ? payload.gql : searchAuthorizationsQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: {
      filter: payload.filter,
      limit: payload?.limit || DEFAULT_LIMIT,
      from: payload?.from || 0,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};

export const searchAllAuthorizations = (payload: SearchAuthorizationsOpts) => {
  const request = payload.gql ? payload.gql : searchAllAuthorizationsQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: {
      filter: payload.filter,
      limit: payload?.limit || DEFAULT_LIMIT,
      from: payload?.from || 0,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};
