/*
 *
 * Order logic Model
 *
 */

import { Thunk, thunk } from "easy-peasy";
import { Injections } from "../../../store";
import { StoreModel } from "../..";
import { EnumOrderStatus } from "../../../api/graphql/types";

export interface UpdateOrderStatusOpts {
  globalOrderId: string;
  orderId: string;
  status: EnumOrderStatus;
  paymentMethod?: string;
}

export interface UpdateOrderItemStatusOpts {
  globalOrderId: string;
  orderId: string;
  orderItemId: string;
  status: EnumOrderStatus;
  paymentMethod?: string;
}

// Interface declaration
export interface BLOrderModel {
  updateOrderStatus: Thunk<
    BLOrderModel,
    UpdateOrderStatusOpts,
    Injections,
    StoreModel
  >;
  updateOrderItemStatus: Thunk<
    BLOrderModel,
    UpdateOrderItemStatusOpts,
    Injections,
    StoreModel
  >;
}

export const blOrderModel: BLOrderModel = {
  updateOrderStatus: thunk(async (_actions, payload, { injections }) => {
    const { orderApiService } = injections;
    const response = await orderApiService.updateOrderStatus(payload);

    return response;
  }),
  updateOrderItemStatus: thunk(async (_actions, payload, { injections }) => {
    const { orderApiService } = injections;
    const response = await orderApiService.updateOrderItemStatus(payload);

    return response;
  }),
};
