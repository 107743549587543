import React, { useEffect, useState } from "react";
import {
  ExceptionalOpeningHourItem,
  getExceptionalOpeningHours,
} from "../../../../../utils/dayslot";
import IntlMessages from "../../../../../utils/messages";
import RangeHour from "../RangeHour";
import { ExceptionalOpeningHour } from "../../../../../lib/ground-aws-graphql-core/models/Center";
import { ActionTypes } from "../../../../../utils/types";
import images from "../../../../../images";
import { displayDayDDMMYYYY } from "../../../../../utils/config";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";

interface Props {
  exceptionalOpeningHours: ExceptionalOpeningHourItem[];
  onChange: (...e: ExceptionalOpeningHourItem[]) => void;
  onChangeExceptionalOpeningHourDates: (startAt: Date, endAt: Date) => void;
  maxId: number;
}

const ExceptionalOpeningHourComponent = (props: Props): JSX.Element => {
  const {
    exceptionalOpeningHours,
    onChange,
    onChangeExceptionalOpeningHourDates,
    maxId,
  } = props;

  const [exceptionalOpeningHoursRanges, setExceptionalOpeningHoursRanges] =
    useState<ExceptionalOpeningHourItem[]>([]);

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const onChangeExceptionalOpeningHour = (
    exceptionalOpeningHour: ExceptionalOpeningHour,
    action: ActionTypes,
    start: string,
    end: string,
    isGenerated: boolean
  ) => {
    const exceptionalOpeningHourItem: ExceptionalOpeningHourItem = {
      item: {
        ...exceptionalOpeningHour,
        start,
        end,
      },
      isGenerated,
      action,
    };

    onChange(exceptionalOpeningHourItem);
  };

  const handleDelete = () => {
    onChange(
      ...exceptionalOpeningHours.map(e => ({
        ...e,
        action: ActionTypes.TO_DELETE,
      }))
    );
  };

  useEffect(() => {
    const ranges = getExceptionalOpeningHours(exceptionalOpeningHours, maxId);
    setExceptionalOpeningHoursRanges(ranges);
  }, [exceptionalOpeningHours]);

  const { startAt, endAt } = exceptionalOpeningHours[0].item;

  return (
    <tr>
      <td className="pr-6 py-2 text-gray-600 flex items-center">
        <IntlMessages
          id="page.exceptional.opening.hours.dates"
          values={{
            start: displayDayDDMMYYYY(startAt, centerTimezone),
            end: displayDayDDMMYYYY(endAt, centerTimezone),
          }}
        />
        <div className="h-6 w-15">
          <img
            alt="change exceptional opening hour"
            data-cy="change_exceptional_opening_hour_icon"
            src={images.edit}
            className="mx-auto cursor-pointer"
            onClick={() =>
              onChangeExceptionalOpeningHourDates(
                new Date(startAt),
                new Date(endAt)
              )
            }
          />
        </div>
      </td>
      {exceptionalOpeningHoursRanges.map((exceptionalHour, index) => {
        return (
          <td className="px-3 py-2" key={index}>
            <RangeHour<ExceptionalOpeningHour>
              hasHoursRange={!exceptionalHour.isGenerated}
              hoursRangeItem={exceptionalHour.item}
              rangeName={exceptionalHour.item.name}
              onChange={(...values) =>
                onChangeExceptionalOpeningHour(
                  ...values,
                  exceptionalHour.isGenerated
                )
              }
              action={exceptionalHour.action}
            />
          </td>
        );
      })}

      <td>
        <div className="h-6 w-15">
          <img
            alt="delete exceptional opening hour"
            data-cy="delete_exceptional_opening_hour_icon"
            src={images.trash}
            className="mx-auto pencil-trash cursor-pointer"
            onClick={handleDelete}
          />
        </div>
      </td>
    </tr>
  );
};

export default ExceptionalOpeningHourComponent;
