import React from "react";
import Switch from "rc-switch";
import { toast } from "react-toastify";
import { getCypressTestId } from "../../utils/config";
import { injectIntl, IntlShape } from "react-intl";
import { Center } from "../../lib/ground-aws-graphql-core/models/Center";
import { GroundGraphqlContextStore } from "../../lib/ground-aws-graphql-core";
import "./style.css";

interface Props {
  intl: IntlShape;
  center: Center;
}

const ActivationModule = (props: Props) => {
  const updateCenterStateAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.center.updateCenterState
  );

  const updateCenterState = (value: boolean) => {
    const { center } = props;
    updateCenterStateAction({
      id: center.id,
      enabled: value,
    })
      .then(() => {
        const message = value
          ? props.intl.formatMessage({
              id: "page.center.enable.center.success",
            })
          : props.intl.formatMessage({
              id: "page.center.disable.center.success",
            });
        toast(message, { type: "success" });
      })
      .catch(() => {
        const message = value
          ? props.intl.formatMessage({
              id: "page.center.enable.center.error",
            })
          : props.intl.formatMessage({
              id: "page.center.disable.center.error",
            });
        toast(message, { type: "error" });
      });
  };

  const { center } = props;

  const label = center
    ? center.enabled
      ? props.intl.formatMessage({ id: "page.center.enabled" })
      : props.intl.formatMessage({ id: "page.center.disabled" })
    : "";

  return !center ? (
    <div className="loading" />
  ) : (
    <div className="h-2 flex items-center justify-center">
      <span className="hidden sm:block text-12px text-ground-gray-100 pr-2">
        {label}
      </span>
      <Switch
        id={center.id}
        data-cy="switch-center-status"
        data-testid={getCypressTestId(center)}
        checked={center.enabled}
        onChange={updateCenterState}
      />
    </div>
  );
};

export default injectIntl(ActivationModule);
