import React, { useRef, useState } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { $enum } from "ts-enum-util";
import IntlMessages from "../../../../utils/messages";
import {
  EnumPermissionEntity,
  EnumUserRole,
  SearchableUserFilterInput,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import { AsyncFiltersSelect } from "../../../../components/Tailwind/Filters";
import { EnumFilterFields, getFilterFields } from "../../../../utils/filter";
import { User } from "../../../../lib/ground-aws-graphql-core/models/User";
import { ILoadOptionsResponse } from "../../../management/global-orders/global-orders-list/table";

const LIMIT = 10;

export interface NotificationRecipients {
  user: RecipientUser | null;
  allRecipients: AllRecipientsChoice;
  choice: RecipientTargetType;
}

interface RecipientUser {
  id: string;
  lastname: string;
  firstname: string;
  email: string;
}

interface AllRecipientsChoice {
  android: boolean;
  ios: boolean;
  administrators: boolean;
}

interface Props {
  notificationRecipients: NotificationRecipients;
  onChange: (notificationRecipients: NotificationRecipients) => void;
  intl: IntlShape;
}

export enum RecipientTargetType {
  USER = "USER",
  ALL = "ALL",
}

enum RecipientPlatform {
  WEB_BACK_OFFICE,
  ANDROID,
  IOS,
}

const RecipientsComponent = (props: Props): JSX.Element => {
  const { notificationRecipients, onChange, intl } = props;

  // set filter field by default to lastname
  const [filterField, setFilterField] = useState<EnumFilterFields>(
    EnumFilterFields.LASTNAME
  );
  const [users, setUsers] = useState<User[]>([]);

  const { current: choice } = useRef<RecipientTargetType>(
    notificationRecipients.choice
  );

  const searchUsers = GroundGraphqlContextStore.useStoreActions(
    actions => actions.user.searchUsers
  );

  const loadUsers = async (
    searchQuery: any,
    _loadedOptions: any,
    { page }: { page: number }
  ): Promise<ILoadOptionsResponse> => {
    const filter: SearchableUserFilterInput = {
      role: { ne: EnumUserRole.Anonymous },
      ...(!!searchQuery && {
        [EnumFilterFields[filterField].toLowerCase()]: {
          matchPhrasePrefix: `${searchQuery.toLowerCase()}`,
        },
      }),
    };

    const { items, total } = await searchUsers({
      limit: LIMIT,
      filter,
      from: (page - 1) * LIMIT,
    });

    if (page > 1) setUsers([...users, ...items]);
    else setUsers(items);

    const options = items.map(({ id, lastname, firstname, email }: User) => ({
      id,
      name: `${lastname} ${firstname} (${email})`,
    }));

    return {
      options,
      hasMore: LIMIT * page < total,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChoiceChanged = (
    _e: React.ChangeEvent<HTMLInputElement>,
    value: RecipientTargetType
  ) => {
    onChange({
      ...notificationRecipients,
      choice: value,
      user: null,
      allRecipients: {
        administrators: false,
        android: false,
        ios: false,
      },
    });
  };

  const onSelectUserChange = (
    _type: string,
    value: { id: string; name: string }
  ) => {
    const user = users?.find(u => u.id === value.id);
    if (user) {
      onChange({
        ...notificationRecipients,
        user,
        choice: RecipientTargetType.USER,
      });
    }
  };

  const renderRecipientTargetTypeChoice = (
    onInputChange: (
      e: React.ChangeEvent<HTMLInputElement>,
      value: RecipientTargetType
    ) => void
  ) => (
    <div className="flex items-center py-2 justify-evenly">
      <label className="flex items-center mb-0" htmlFor="f_target_user_choice">
        <input
          type="radio"
          id="f_target_user_choice"
          name="f_target_user_choice"
          value={RecipientTargetType.USER}
          className="mr-3"
          onChange={e => onInputChange(e, RecipientTargetType.USER)}
          checked={choice === RecipientTargetType.USER}
        />
        <IntlMessages id="page.notification.target.user" />
      </label>

      <label
        className="flex items-center mb-0"
        htmlFor="f_target_all_users_choice"
      >
        <input
          type="radio"
          id="f_target_all_users_choice"
          name="f_target_all_users_choice"
          value={RecipientTargetType.ALL}
          className="mr-3"
          onChange={e => onInputChange(e, RecipientTargetType.ALL)}
          checked={choice === RecipientTargetType.ALL}
        />
        <IntlMessages id="page.notification.target.all.users" />
      </label>
    </div>
  );

  const handleCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    platform: RecipientPlatform
  ) => {
    const checked = e.target.checked;
    const { allRecipients } = notificationRecipients;

    if (platform === RecipientPlatform.WEB_BACK_OFFICE) {
      allRecipients.administrators = checked;
    }
    if (platform === RecipientPlatform.ANDROID) {
      allRecipients.android = checked;
    }
    if (platform === RecipientPlatform.IOS) {
      allRecipients.ios = checked;
    }
    onChange({
      ...notificationRecipients,
      allRecipients,
    });
  };

  const getCheckedValue = (platform: RecipientPlatform) => {
    let value = false;

    switch (platform) {
      case RecipientPlatform.WEB_BACK_OFFICE:
        value = notificationRecipients.allRecipients.administrators;
        break;
      case RecipientPlatform.ANDROID:
        value = notificationRecipients.allRecipients.android;
        break;
      case RecipientPlatform.IOS:
        value = notificationRecipients.allRecipients.ios;
        break;
      default:
        break;
    }

    return value;
  };

  const platforms = $enum(RecipientPlatform).getKeys();

  const renderAllRecipientsFields = () => {
    return (
      <>
        {platforms.map((field, index) => (
          <div key={index} className="relative">
            <input
              id={field}
              name={field}
              type="checkbox"
              className="form-checkbox h-4 w-4 text-ground-green-100 transition duration-100 ease-in-out"
              checked={getCheckedValue(RecipientPlatform[field])}
              onChange={e => handleCheck(e, RecipientPlatform[field])}
            />
            <label htmlFor={field} className="mb-0 text-ground-gray-100 pl-3">
              <IntlMessages id={`page.notification.target.${field}`} />
            </label>
          </div>
        ))}
      </>
    );
  };

  const renderUserSelected = () => {
    return (
      <div className="inline-flex items-center px-3 py-1.5 text-11px font-medium leading-5 bg-gray-100 text-gray-900">
        <div>
          {notificationRecipients?.user?.firstname}{" "}
          {notificationRecipients?.user?.lastname}
          <br />
          <span className="text-ground-blue-100">
            {notificationRecipients?.user?.email}
          </span>
        </div>

        <button
          name="btn-remove-recipient"
          data-cy="btn-remove-recipient"
          data-testid={notificationRecipients?.user?.id}
          type="button"
          className="flex-shrink-0 border-2 p-1 ml-4 inline-flex text-gray-700 focus:outline-none focus:text-gray-900"
          onClick={() =>
            onChange({
              allRecipients: {
                administrators: false,
                android: false,
                ios: false,
              },
              choice: RecipientTargetType.USER,
              user: null,
            })
          }
        >
          <svg
            style={{ height: "0.6rem", width: "0.6rem" }}
            stroke="currentColor"
            fill="none"
            viewBox="0 0 8 8"
          >
            <path
              strokeLinecap="round"
              strokeWidth="0.7"
              d="M1 1l6 6m0-6L1 7"
            />
          </svg>
        </button>
      </div>
    );
  };

  const filters = getFilterFields(intl, EnumPermissionEntity.NOTIFICATION);
  const values = filters.values.map(v => v.value);

  const loadOptions: {
    [key: string]: (
      searchQuery: any,
      _loadedOptions: any,
      { page }: { page: number }
    ) => Promise<ILoadOptionsResponse>;
  } = {};

  for (const key of values) {
    loadOptions[key] = loadUsers;
  }

  return (
    <div className="grid gap-2" key={`${choice}`}>
      {renderRecipientTargetTypeChoice(onChoiceChanged)}

      {choice === RecipientTargetType.ALL && renderAllRecipientsFields()}

      {choice === RecipientTargetType.USER &&
        !notificationRecipients?.user?.id && (
          <AsyncFiltersSelect
            fields={getFilterFields(
              intl,
              EnumPermissionEntity.NOTIFICATION
            ).values.filter(f => f.async === true)}
            multiple={false}
            onSelectChange={onSelectUserChange}
            onInputChange={type => setFilterField(EnumFilterFields[type])}
            loadOptions={loadOptions}
            isSearchable
          />
        )}

      {!!notificationRecipients?.user?.id &&
        choice === RecipientTargetType.USER &&
        renderUserSelected()}
    </div>
  );
};

export default injectIntl(RecipientsComponent);
