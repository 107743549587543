import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import { $enum } from "ts-enum-util";
import { EnumReportingGlobalEntity } from "../../../lib/ground-aws-graphql-core/models/Product";

interface Props {
  intl: IntlShape;
  entity: EnumReportingGlobalEntity;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const ReportingEntity = (props: Props): JSX.Element => {
  const { intl, entity, onChange } = props;

  const entities = $enum(EnumReportingGlobalEntity).getKeys();

  return (
    <div className="h-8 my-4 inline-block border border-ground-gray-400 rounded-4">
      <select
        id="reporting-entity"
        name="reporting-entity"
        className="form-select h-full py-0 bg-transparent text-ground-black-100 text-14px leading-5 border-none rounded-none shadow-none"
        onChange={onChange}
        defaultValue={entity}
      >
        {entities.map(d => (
          <option key={d} value={d}>
            {intl.formatMessage({
              id: `page.product.typology.${d}`,
            })}
          </option>
        ))}
      </select>
    </div>
  );
};

export default injectIntl(ReportingEntity);
