import React from "react";
import { EnumBackOfficeUserRole } from "../../lib/ground-aws-graphql-core/api/graphql/types";
import { OperatorConfig } from "../../lib/operator-configuration";

export const Context = React.createContext<{
  configuration?: OperatorConfig;
  role?: EnumBackOfficeUserRole;
}>({});

export { CustomizationProvider } from "./CustomizationProvider";
export { Display } from "./Display";
export { useOperatorDisplayConfiguration } from "./useOperatorDisplayConfiguration";
