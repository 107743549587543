import React from "react";
import { Helmet } from "react-helmet";
import { Location } from "history";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import ServiceForm from "../form";
import { withTwoColLayout } from "../../../../utils";
import { EnumServiceType } from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import PageSubheader from "../../../../components/PageSubheader";

interface Props {
  serviceType: EnumServiceType;
  isOpen: boolean;
  handleClose: () => void;
  match: Match;
  intl: IntlShape;
  location: Location;
  backURL: string;
}

const ServiceCreate = (props: Props) => {
  const { intl, serviceType, backURL } = props;

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: `page.${serviceType.toLowerCase()}.create.document.title`,
          })}
        </title>
      </Helmet>

      <PageSubheader
        goBackEnabled
        goBackId={`btn-back-service-${serviceType.toLowerCase()}-create`}
        goBackURL={backURL}
      />

      <ServiceForm {...props} edition={false} />
    </>
  );
};

export default withTwoColLayout(injectIntl(ServiceCreate));
