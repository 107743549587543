import {
  EnumVisitStatus,
  EnumVisitVisitorStatus,
} from "../../lib/ground-aws-graphql-core/models/Api/Visits";

export const getVisitStatusBadgeColor = (status: EnumVisitStatus): string => {
  switch (status) {
    case EnumVisitStatus.PREREGISTERED:
      return "bg-ground-yellow-100";
    case EnumVisitStatus.CHECKED_IN:
      return "bg-ground-green-100";
    case EnumVisitStatus.CANCELED:
      return "bg-red-500";
    case EnumVisitStatus.CHECKED_OUT:
      return "bg-ground-blue-200";
    default:
      return "";
  }
};

export const getVisitorStatusBadgeColor = (
  status: EnumVisitVisitorStatus
): string => {
  switch (status) {
    case EnumVisitVisitorStatus.CHECKED_IN:
      return "bg-ground-green-100";
    case EnumVisitVisitorStatus.CANCELED:
      return "bg-red-500";
    case EnumVisitVisitorStatus.CHECKED_OUT:
      return "bg-ground-blue-200";
    default:
      return "";
  }
};

export const getVisitorInitials = (
  firstname: string | undefined,
  lastname: string | undefined
): string => {
  if (!firstname || !lastname) return "";

  return `${firstname.charAt(0)}${lastname.charAt(0)}`;
};
