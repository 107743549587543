import currencyFormatter from "currency-formatter";
import { IntlShape } from "react-intl";
import { ActionTypes, isBookableService, isConsumableService } from "../types";
import { getTranslation } from "../translation";
import { getEmptyTranslations } from "../../lang";
import {
  EnumCurrency,
  EnumProductTypology,
  EnumServiceType,
  EnumUnit,
} from "../../lib/ground-aws-graphql-core/api/graphql/types";
import { PriceUnitVariation } from "../../lib/ground-aws-graphql-core/models/PriceUnitVariation";
import { PriceUnit } from "../../lib/ground-aws-graphql-core/models/PriceUnit";
import { Product } from "../../lib/ground-aws-graphql-core/models/Product";
import { getBookingPriceUnitsInBO } from "../config";

export interface PriceUnitsInterface {
  value: EnumUnit;
  label: string;
}

export interface PriceUnitItemVariation {
  [key: string]: PriceUnitItem[];
}

export interface PriceUnitItem {
  action: ActionTypes;
  edit: boolean;
  label: string;
  item: PriceUnit;
}

export const getPriceUnitsByVariation = (
  product: Product | null,
  priceUnitVariations: PriceUnitVariation[] | null
): PriceUnitItemVariation => {
  // get price units
  const priceUnits =
    product && product.priceUnits ? product.priceUnits.items : null;

  const mapPriceUnitVariations: {
    [key: string]: PriceUnitVariation;
  } = {};
  if (priceUnitVariations && priceUnitVariations.length > 0) {
    priceUnitVariations.forEach(p => {
      mapPriceUnitVariations[p.id] = p;
    });
  }

  if (priceUnits && priceUnits.length > 0) {
    const results = priceUnits.reduce(
      (acc: PriceUnitItemVariation, item: PriceUnit) => {
        // variation id
        const priceUnitVariationId =
          item.variation && item.variation.id ? item.variation.id : null;

        if (priceUnitVariationId) {
          let array: PriceUnitItem[] = acc[priceUnitVariationId];
          if (!array) {
            array = [];
          }

          // add price unit
          const element = {
            item,
            edit: false,
            label: getTranslation(item.variation.name),
            action: ActionTypes.TO_KEEP,
          } as PriceUnitItem;

          array.push(element);
          acc[priceUnitVariationId] = array;
        }

        return acc;
      },
      {}
    );

    Object.keys(mapPriceUnitVariations).forEach(key => {
      if (!results[key]) {
        results[key] = [];
      }
    });

    return results;
  }

  const results: PriceUnitItemVariation = {};

  Object.keys(mapPriceUnitVariations).forEach(key => {
    results[key] = [];
  });

  return results;
};

const DEFAULT_FLEXIBLE = true;

export interface PriceUnitElement {
  id: number;
  unit: EnumUnit;
  price: number;
  label: string;
  increment: number;
  flexible: boolean;
  buyableUnit: number;
  buyableDescription: string;
  minApprovalMinutes?: number;
  minCancelMinutes?: number;
  currency: EnumCurrency;
}

const buyableDescription = JSON.stringify(getEmptyTranslations());

export const getPriceUnitElements = (intl: IntlShape): PriceUnitElement[] => {
  return [
    {
      id: 0,
      unit: EnumUnit.UNIT,
      price: 0,
      label: intl.formatMessage({ id: "page.product.price.unit.unit" }),
      increment: 1,
      flexible: DEFAULT_FLEXIBLE,
      buyableUnit: 1,
      buyableDescription,
      currency: EnumCurrency.EUR,
    },
    {
      id: 1,
      unit: EnumUnit.HALF_HOUR,
      price: 0,
      label: intl.formatMessage({ id: "page.product.price.unit.halfhour" }),
      increment: 1,
      flexible: DEFAULT_FLEXIBLE,
      buyableUnit: 1,
      buyableDescription,
      currency: EnumCurrency.EUR,
    },
    {
      id: 2,
      unit: EnumUnit.HOUR,
      price: 0,
      label: intl.formatMessage({ id: "general.hour" }),
      increment: 1,
      flexible: DEFAULT_FLEXIBLE,
      buyableUnit: 1,
      buyableDescription,
      currency: EnumCurrency.EUR,
    },
    {
      id: 3,
      unit: EnumUnit.HALF_DAY,
      price: 0,
      label: intl.formatMessage({ id: "page.product.price.unit.halfday" }),
      increment: 1,
      flexible: DEFAULT_FLEXIBLE,
      buyableUnit: 1,
      buyableDescription,
      currency: EnumCurrency.EUR,
    },
    {
      id: 4,
      unit: EnumUnit.DAY,
      price: 0,
      label: intl.formatMessage({ id: "page.product.price.unit.day" }),
      increment: 1,
      flexible: DEFAULT_FLEXIBLE,
      buyableUnit: 1,
      buyableDescription,
      currency: EnumCurrency.EUR,
    },
    {
      id: 5,
      unit: EnumUnit.MONTH,
      price: 0,
      label: intl.formatMessage({ id: "page.product.price.unit.month" }),
      increment: 1,
      flexible: DEFAULT_FLEXIBLE,
      buyableUnit: 1,
      buyableDescription,
      currency: EnumCurrency.EUR,
    },
  ];
};

const getPriceUnitElementByUnit = (
  intl: IntlShape,
  unit: EnumUnit
): PriceUnitElement | undefined => {
  return getPriceUnitElements(intl).find(e => e.unit === unit);
};

export const getPriceUnitElementLabelByUnit = (
  intl: IntlShape,
  unit: EnumUnit | undefined
): string => {
  if (unit) {
    const pu = getPriceUnitElementByUnit(intl, unit);

    return pu ? pu.label : "";
  }

  return "";
};

const getSpacePriceUnitElements = (intl: IntlShape): PriceUnitElement[] => {
  const units = [...getBookingPriceUnitsInBO(), EnumUnit.MONTH];

  return getPriceUnitElements(intl).filter(e => units.includes(e.unit));
};

export const getPriceUnitElementsByType = (
  intl: IntlShape,
  serviceType: EnumServiceType,
  productTypology: EnumProductTypology | null | undefined
): PriceUnitElement[] => {
  if (serviceType === EnumServiceType.SPACE) {
    return getSpacePriceUnitElements(intl);
  }

  if (serviceType === EnumServiceType.SERVICE) {
    if (isConsumableService(productTypology)) {
      return getPriceUnitElements(intl).filter(e => e.unit === EnumUnit.UNIT);
    }

    if (isBookableService(productTypology)) {
      return getSpacePriceUnitElements(intl);
    }

    return getPriceUnitElements(intl);
  }

  return getPriceUnitElements(intl);
};

export const getFormattedPriceUnit = (
  priceUnit: PriceUnit | undefined
): string | null => {
  if (priceUnit) {
    const value = currencyFormatter.format(priceUnit.price, {
      code: priceUnit.currency,
    });

    return value;
  }

  return null;
};

export const getFormattedPrice = (
  price: number,
  currency: EnumCurrency | null | undefined
): number | string => {
  if (price && currency) {
    const value = currencyFormatter.format(price, {
      code: currency,
    });

    return value;
  }

  return price;
};

export const getCurrencySymbol = (
  currency: EnumCurrency
): string | undefined => {
  const value = currencyFormatter.findCurrency(currency);

  return value?.symbol;
};

export const getUnitLabel = (
  intl: IntlShape,
  unit: EnumUnit | undefined
): string | undefined => {
  const el = unit ? getPriceUnitElements(intl).find(e => e.unit === unit) : "";

  return el && el.unit !== EnumUnit.UNIT
    ? el.label
    : unit === EnumUnit.UNIT
    ? ""
    : unit;
};
