import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import history from "../../../history";
import { EnumPaths } from "../../../utils/navigation";
import { getMenuClasses } from "../../../utils/menu";
import contextStore from "../../../redux/store";
import GridCard from "../../../components/Tailwind/GridCard";
import { FiltersInput } from "../../../components/Tailwind/Filters";
import { EnumFilterFields, getFilterFields } from "../../../utils/filter";
import { getLocale } from "../../../lang";
import Paginate from "../../../components/Paginate";
import {
  EnumPermissionEntity,
  SearchableProviderFilterInput,
  SearchableProviderSortableFields,
  SearchableSortDirection,
} from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import PageSubheader from "../../../components/PageSubheader";
import IntlMessages from "../../../utils/messages";
import { GroundAuthContextStore } from "../../../lib/ground-aws-cognito-auth-core";
import { canAddProvider } from "../../../utils/types";
import AddElement from "../../../components/Tailwind/AddElement";

interface Props {
  intl: IntlShape;
}

const LIMIT = 20;

const ListProviders = (props: Props) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState<string>(
    EnumFilterFields[EnumFilterFields.NAME]
  );
  const [filterInput, setFilterInput] = useState<string>("");

  const { intl } = props;

  const searchProviders = GroundGraphqlContextStore.useStoreActions(
    actions => actions.provider.searchProviders
  );

  const setProvider = GroundGraphqlContextStore.useStoreActions(
    actions => actions.provider.setProvider
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.provider.providers.total
  );

  const providers = GroundGraphqlContextStore.useStoreState(
    state => state.provider.providers.items
  );

  const setContainerClassnames = contextStore.useStoreActions(
    actions => actions.menu.setContainerClassnames
  );

  const menuHiddenBreakpoint = contextStore.useStoreState(
    state => state.menu.menuHiddenBreakpoint
  );

  const subHiddenBreakpoint = contextStore.useStoreState(
    state => state.menu.subHiddenBreakpoint
  );

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const authorizedToAddProvider = canAddProvider(me);

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  useEffect(() => {
    fetchData();
    const nextClasses = getMenuClasses(
      "menu-hidden",
      menuHiddenBreakpoint,
      subHiddenBreakpoint
    );
    setContainerClassnames({ strCurrentClasses: nextClasses.join(" ") });
  }, []);

  useEffect(() => {
    fetchData(false);
  }, [pageIndex, filterInput]);

  const fetchData = (loader = true) => {
    if (loader) {
      setLoading(true);
    }

    let filter: SearchableProviderFilterInput = {};
    if (filterInput) {
      switch (filterType) {
        case EnumFilterFields[EnumFilterFields.NAME]:
          filter = {
            ...filter,
            name: { matchPhrasePrefix: `${filterInput.toLowerCase()}` },
          };
          break;
        case EnumFilterFields[EnumFilterFields.CITY]:
          filter = {
            ...filter,
            city: { matchPhrasePrefix: `${filterInput.toLowerCase()}` },
          };
          break;
        case EnumFilterFields[EnumFilterFields.ADDRESS]:
          filter = {
            ...filter,
            address: { matchPhrasePrefix: `${filterInput.toLowerCase()}` },
          };
          break;
        default:
          break;
      }
    }

    searchProviders({
      limit: LIMIT,
      filter,
      locale: currentAppLocale.backend_locale,
      from: pageIndex * LIMIT,
      sort: {
        field: SearchableProviderSortableFields.createdAt,
        direction: SearchableSortDirection.desc,
      },
    }).finally(() => {
      if (loader) setLoading(false);
    });
  };

  const handleEditItem = (_e: any, itemId: string): void => {
    history.push(`${EnumPaths.PROVIDERS}/${itemId}/${EnumPaths.EDIT_MODE}`);
  };

  const handleAddNew = () => {
    setProvider(null);
    history.push(
      `/${EnumPaths.ROOT}/${EnumPaths.PROVIDERS}/${EnumPaths.CREATION_MODE}`
    );
  };

  const renderProviderList = (): JSX.Element => {
    return (
      <div className="grid gap-5 max-w-lg mx-auto lg:grid-cols-4 lg:max-w-none">
        {providers?.map(provider => {
          return (
            <Fragment key={provider.id}>
              <GridCard
                item={provider}
                displayable={true}
                type={EnumPermissionEntity.PROVIDER}
                edition={{
                  onClick: handleEditItem,
                  label: "page.list.providers.manage.provider",
                }}
                canDuplicate={false}
              />
            </Fragment>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: "page.list.providers.document.title",
          })}
        </title>
      </Helmet>

      <PageSubheader
        title="page.list.providers.title"
        nbOfResults={total}
        buttonRightTitle="page.list.providers.create.provider"
        buttonRightAction={handleAddNew}
        buttonRightId="btn-add-provider"
      />

      <div className="px-8 pb-8">
        <FiltersInput
          fields={getFilterFields(intl, EnumPermissionEntity.PROVIDER).values}
          onInputChange={(type, input) => {
            setFilterInput(input);
            setFilterType(type);
            setPageIndex(0);
          }}
        />

        {loading && <div className="loading" />}

        {!loading && providers && providers?.length > 0 && (
          <div>
            {renderProviderList()}
            {total && total > LIMIT && (
              <Paginate
                total={total}
                limit={LIMIT}
                forcePage={pageIndex}
                onPageChange={setPageIndex}
                className="mt-8"
              />
            )}
          </div>
        )}

        {/* when filter input return no results */}
        {!loading && filterInput && providers && !providers.length && (
          <div className="mt-8 text-16px">
            <IntlMessages id="page.list.providers.empty.search" />
          </div>
        )}

        {/* when operator has no providers, operator admin must some one */}
        {!loading &&
          !filterInput &&
          providers &&
          !providers.length &&
          authorizedToAddProvider && (
            <AddElement
              onClick={handleAddNew}
              entity={EnumPermissionEntity.PROVIDER}
            />
          )}
      </div>
    </>
  );
};

export default injectIntl(ListProviders);
