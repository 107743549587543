import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import ReactModal from "react-modal";
import CenterTypeForm from "./form";
import { CenterType } from "../../../../lib/ground-aws-graphql-core/models/CenterType";

interface Props {
  isOpen: boolean;
  toggle: (e) => void;
  onRequestClose?: (e) => void;
  action: string | null;
  type: CenterType | null | undefined;
  match: Match;
  intl: IntlShape;
}

const ModalCreateCenterType = (props: Props) => {
  const { isOpen, onRequestClose } = props;

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      portalClassName="modal-center"
      style={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
      shouldCloseOnOverlayClick
      onRequestClose={onRequestClose}
      className="modal-dialog-lg modal-content"
    >
      <CenterTypeForm {...props} />
    </ReactModal>
  );
};

export default injectIntl(ModalCreateCenterType);
