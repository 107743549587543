import React, { useState, useEffect, Fragment } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { injectIntl, IntlShape } from "react-intl";
import contextStore from "../../../redux/store";
import history from "../../../history";
import { EnumPaths } from "../../../utils/navigation";
import { getMenuClasses } from "../../../utils/menu";
import GridCard from "../../../components/Tailwind/GridCard";
import {
  canDuplicateBuilding,
  canManageAllBuildings,
} from "../../../utils/types";
import { FiltersInput } from "../../../components/Tailwind/Filters";
import { EnumFilterFields, getFilterFields } from "../../../utils/filter";
import { getLocale } from "../../../lang";
import Paginate from "../../../components/Paginate";
import {
  EnumPermissionEntity,
  SearchableCenterFilterInput,
  SearchableCenterSortableFields,
  SearchableSortDirection,
} from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import { GroundAuthContextStore } from "../../../lib/ground-aws-cognito-auth-core";
import { UserCognito } from "../../../lib/ground-aws-cognito-auth-core/models/Authentication";
import PageSubheader from "../../../components/PageSubheader";
import { Center } from "../../../lib/ground-aws-graphql-core/models/Center";
import IntlMessages from "../../../utils/messages";
import ConfirmModal from "../../../utils/modal/confirm";
import AddElement from "../../../components/Tailwind/AddElement";
import { isDisplayable } from "../../../lib/operator-configuration/backoffice/display";
import { getOperatorConfiguration } from "../../../lib/operator-configuration";

interface Props {
  intl: IntlShape;
}

const DEFAULT_LIMIT = 20;

const ListCenters = (props: Props): JSX.Element => {
  const [pageIndex, setPageIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState<string>(
    EnumFilterFields[EnumFilterFields.NAME]
  );
  const [filterInput, setFilterInput] = useState<string>("");
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [centerId, setCenterId] = useState<string>("");
  const { intl } = props;

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const searchAuthorizations = GroundGraphqlContextStore.useStoreActions(
    actions => actions.authorization.searchAuthorizations
  );

  const searchCenters = GroundGraphqlContextStore.useStoreActions(
    actions => actions.center.searchCenters
  );

  const duplicateCenter = contextStore.useStoreActions(
    actions => actions.center.duplicateCenter
  );

  const setCenter = GroundGraphqlContextStore.useStoreActions(
    actions => actions.center.setCenter
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.center.centers.total
  );

  const totalAuthorizations = GroundGraphqlContextStore.useStoreState(
    state => state.authorization.authorizations.total
  );

  const centers = GroundGraphqlContextStore.useStoreState(
    state => state.center.centers.items
  );

  const authorizations = GroundGraphqlContextStore.useStoreState(
    state => state.authorization.authorizations.items
  );

  const setContainerClassnames = contextStore.useStoreActions(
    actions => actions.menu.setContainerClassnames
  );

  const menuHiddenBreakpoint = contextStore.useStoreState(
    state => state.menu.menuHiddenBreakpoint
  );

  const subHiddenBreakpoint = contextStore.useStoreState(
    state => state.menu.subHiddenBreakpoint
  );

  const authorizedToManageAllBuildings = canManageAllBuildings(me);
  const authorizedToDuplicateBuilding = canDuplicateBuilding(me);

  const centersByPage = authorizedToManageAllBuildings
    ? centers
    : authorizations?.reduce((acc: Center[], el) => {
        if (el.center) acc.push(el.center);

        return acc;
      }, []);

  const myTotalCenters = authorizedToManageAllBuildings
    ? total
    : totalAuthorizations;

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  useEffect(() => {
    const nextClasses = getMenuClasses(
      "menu-hidden",
      menuHiddenBreakpoint,
      subHiddenBreakpoint
    );
    setContainerClassnames({ strCurrentClasses: nextClasses.join(" ") });

    return () => setPageIndex(0);
  }, []);

  useEffect(() => {
    if (me) {
      if (!canManageAllBuildings(me)) {
        fetchAuthorizations(me);
      } else {
        fetchData();
      }
    }
  }, [me, pageIndex, filterInput]);

  const handleAddNew = () => {
    setCenter(null);
    history.push(
      `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${EnumPaths.CREATION_MODE}`
    );
  };

  const fetchAuthorizations = (meCognito: UserCognito) => {
    searchAuthorizations({
      limit: DEFAULT_LIMIT,
      filter: {
        authorizationUserId: { eq: meCognito.id },
        entityType: { eq: EnumPermissionEntity.CENTER },
      },
      from: pageIndex * DEFAULT_LIMIT,
    })
      .then(() => {
        if (loading) {
          setLoading(false);
        }
      })
      .catch(() => {
        if (loading) {
          setLoading(false);
        }
      });
  };

  const fetchData = (shouldLoad = true) => {
    if (shouldLoad) setLoading(true);
    let filter: SearchableCenterFilterInput = {};

    if (filterInput) {
      switch (filterType) {
        case EnumFilterFields[EnumFilterFields.NAME]:
          filter = {
            ...filter,
            name: { matchPhrasePrefix: `${filterInput.toLowerCase()}` },
          };
          break;
        case EnumFilterFields[EnumFilterFields.CITY]:
          filter = {
            ...filter,
            city: { matchPhrasePrefix: `${filterInput.toLowerCase()}` },
          };
          break;
        case EnumFilterFields[EnumFilterFields.ADDRESS]:
          filter = {
            ...filter,
            address: { matchPhrasePrefix: `${filterInput.toLowerCase()}` },
          };
          break;
        default:
          break;
      }
    }

    searchCenters({
      limit: DEFAULT_LIMIT,
      filter,
      locale: currentAppLocale.backend_locale,
      from: pageIndex * DEFAULT_LIMIT,
      sort: {
        field: SearchableCenterSortableFields.createdAt,
        direction: SearchableSortDirection.desc,
      },
    }).finally(() => {
      setLoading(false);
    });
  };

  const configuration = getOperatorConfiguration(me?.operator_id);

  const displayable = isDisplayable(
    configuration,
    "sidebar.menu.building.informations"
  );

  const handleEditItem = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    id: string
  ) => {
    e.preventDefault();

    changeDefaultMenuType(e, "menu-sub-hidden");

    localStorage.setItem("parentNavItem", "general");
    localStorage.setItem("navItem", "informations");

    history.push(
      `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${id}/${EnumPaths.INFORMATIONS}`
    );
  };

  const handleDuplicateCenter = (center: Center) => {
    setShowDuplicateModal(true);
    setCenterId(center.id);
  };

  const handleDuplicate = () => {
    setLoading(true);
    setShowDuplicateModal(false);

    duplicateCenter({ id: centerId })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.list.centers.duplicate.center.success",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.list.centers.duplicate.center.error",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderCenterList = (): JSX.Element => {
    return (
      <div className="grid gap-5 max-w-lg mx-auto lg:grid-cols-4 lg:max-w-none">
        {centersByPage?.map(center => {
          return (
            <Fragment key={center.id}>
              <GridCard
                item={center}
                displayable={displayable}
                type={EnumPermissionEntity.CENTER}
                edition={{
                  onClick: displayable ? handleEditItem : () => {},
                  label: "page.list.centers.manage.center",
                }}
                duplication={{
                  onClick: () => handleDuplicateCenter(center),
                  label: "page.list.centers.duplicate.center",
                }}
                canDuplicate={authorizedToDuplicateBuilding}
              />
            </Fragment>
          );
        })}
      </div>
    );
  };

  const changeDefaultMenuType = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    containerClassnames: string
  ) => {
    e.preventDefault();
    const nextClasses = getMenuClasses(
      containerClassnames,
      menuHiddenBreakpoint,
      subHiddenBreakpoint
    );
    setContainerClassnames({
      clickIndex: 0,
      strCurrentClasses: nextClasses.join(" "),
    });
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: "page.list.centers.document.title" })}
        </title>
      </Helmet>
      <div>
        <PageSubheader
          title="page.list.centers.title"
          nbOfResults={myTotalCenters}
          buttonRightTitle="page.list.centers.create.center"
          buttonRightAction={
            authorizedToManageAllBuildings ? handleAddNew : undefined
          }
          buttonRightId="btn-add-center"
        />

        <div className="px-8 pb-8">
          <FiltersInput
            fields={getFilterFields(intl, EnumPermissionEntity.CENTER).values}
            onInputChange={(type, input) => {
              setFilterInput(input);
              setFilterType(type);
              setPageIndex(0);
            }}
          />
          {loading && <div className="loading" />}

          {!loading && centersByPage && centersByPage?.length > 0 && (
            <>
              {renderCenterList()}
              {myTotalCenters && myTotalCenters > DEFAULT_LIMIT && (
                <Paginate
                  total={myTotalCenters}
                  limit={DEFAULT_LIMIT}
                  forcePage={pageIndex}
                  onPageChange={setPageIndex}
                  className="mt-8"
                />
              )}
            </>
          )}

          {/* when filter input return no results */}
          {!loading &&
            filterInput &&
            centersByPage &&
            !centersByPage.length && (
              <div className="mt-8 text-16px">
                <IntlMessages id="page.list.centers.empty.search" />
              </div>
            )}

          {/* when center administrator has no centers authorizations */}
          {!loading &&
            !filterInput &&
            centersByPage &&
            !centersByPage.length &&
            !authorizedToManageAllBuildings && (
              <div className="mt-8 text-16px">
                <IntlMessages id="page.list.centers.empty" />
              </div>
            )}

          {/* when operator has no centers, operator admin must some one */}
          {!loading &&
            !filterInput &&
            centersByPage &&
            !centersByPage.length &&
            authorizedToManageAllBuildings && (
              <AddElement
                onClick={handleAddNew}
                entity={EnumPermissionEntity.CENTER}
              />
            )}

          <ConfirmModal
            isOpen={showDuplicateModal}
            onRequestClose={() => setShowDuplicateModal(!showDuplicateModal)}
            toggle={() => setShowDuplicateModal(!showDuplicateModal)}
            handleConfirm={handleDuplicate}
            content={
              <IntlMessages id="page.list.centers.duplicate.center.confirm" />
            }
          />
        </div>
      </div>
    </>
  );
};

export default injectIntl(ListCenters);
