import React from "react";

const NavBurgerMenu = (): JSX.Element => {
  return (
    <div className="flex items-center lg:hidden">
      <button
        id="btn-nav-burger-menu"
        name="btn-nav-burger-menu"
        data-cy="btn-nav-burger-menu"
        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
      >
        <svg
          className="h-6 w-6"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            //data-todo-colon-className="{'hidden': open, 'inline-flex': !open }"
            className="inline-flex"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
          <path
            //data-todo-colon-className="{'hidden': !open, 'inline-flex': open }"
            className="hidden"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  );
};

export default NavBurgerMenu;
