import { EventModel, eventModel } from "./Event";
import { NewsModel, newsModel } from "./News";
import { ServiceModel, serviceModel } from "./Service";
import { ProductModel, productModel } from "./Product";
import { CenterModel, centerModel } from "./Center";
import { SettingsModel, settingsModel } from "./Settings";
import { MenuModel, menuModel } from "./Menu";
import { ProviderModel, providerModel } from "./Provider";
import { AccountModel, accountModel } from "./Account";
import { AuthenticationModel, authenticationModel } from "./Authentication";
import { EnterpriseModel, enterpriseModel } from "./Enterprise";
import { privatizationModel, PrivatizationModel } from "./Privatization";
import { categoryModel, CategoryModel } from "./Category";

export interface StoreModel {
  event: EventModel;
  news: NewsModel;
  service: ServiceModel;
  product: ProductModel;
  settings: SettingsModel;
  category: CategoryModel;
  menu: MenuModel;
  center: CenterModel;
  provider: ProviderModel;
  account: AccountModel;
  authentication: AuthenticationModel;
  enterprise: EnterpriseModel;
  privatization: PrivatizationModel;
}

export const storeModel = {
  event: eventModel,
  news: newsModel,
  service: serviceModel,
  product: productModel,
  settings: settingsModel,
  category: categoryModel,
  menu: menuModel,
  center: centerModel,
  provider: providerModel,
  account: accountModel,
  authentication: authenticationModel,
  enterprise: enterpriseModel,
  privatization: privatizationModel,
};
