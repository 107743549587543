import React from "react";
import { match as Match } from "react-router-dom";
import {
  SpaceTimeline,
  TimelineDate,
} from "../../lib/ground-aws-graphql-core/models/Product";
import IntlMessages from "../../utils/messages";
import { getDefaultImage, getImageUrl } from "../../utils/picture";
import { getTranslation } from "../../utils/translation";
import TimeFrame from "../TimeFrame";
import images from "../../images";

interface Props {
  match: Match<{ cid: string }>;
  space: SpaceTimeline;
  timelineDate?: TimelineDate;
}

const SpaceTimeFrame = (props: Props): JSX.Element => {
  const { space, timelineDate } = props;
  const { pictures, capacity, name, combined } = space;

  return (
    <div className="flex">
      <img
        alt={getTranslation(name)}
        src={
          pictures?.length && pictures.length > 0
            ? getImageUrl(null, pictures[0])
            : getDefaultImage(null)
        }
        className="mr-4 rounded-4 w-30 h-20 object-stretch"
      />

      <div className="flex flex-col justify-between">
        <div className="flex">
          <span className="text-14px leading-5 text-ground-black-100 font-semibold">
            {getTranslation(name)}
          </span>

          <span className="text-14px leading-5 text-ground-gray-100 ml-1">
            <IntlMessages
              id="page.list.bookings.capacity"
              values={{ total: capacity }}
            />
          </span>

          {combined && (
            <img
              src={images.combined}
              alt="combined"
              className="w-3 h-3 ml-1"
            />
          )}
        </div>

        <TimeFrame {...props} space={space} timelineDate={timelineDate} />
      </div>
    </div>
  );
};

export default SpaceTimeFrame;
