import { ErrorGraphQL } from "../../services/coreUtils";
import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { Operator } from "../Operator";

import {
  CreatePriceUnitInput as CreatePriceUnit,
  UpdatePriceUnitInput as UpdatePriceUnit,
  DeletePriceUnitInput as DeletePriceUnit,
  EnumUnit,
  EnumCurrency,
} from "../../api/graphql/types";
import { PriceUnitVariation } from "../PriceUnitVariation";

export interface PriceUnit {
  operator?: Operator;
  id: string;
  unit: EnumUnit;
  currency?: EnumCurrency;
  increment: number;
  price: number;
  variation: PriceUnitVariation;
  enabled?: boolean;
  markForDelete?: boolean;
  minQuantity?: number | null;
  maxQuantity?: number | null;
  buyableUnit: number;
  buyableDescription?: string;
  flexible: boolean;
  minApprovalMinutes?: number | null;
  minCancelMinutes?: number | null;
  metadata?: string | null;
}

export interface ModelPriceUnitConnection {
  items: [PriceUnit] | null;
  total?: number | null;
}

// Interface declaration
export interface PriceUnitModel {
  priceUnits: ModelPriceUnitConnection;
  priceUnit: PriceUnit | null;

  setPriceUnit: Action<PriceUnitModel, PriceUnit | null>;
  addPriceUnit: Action<PriceUnitModel, PriceUnit>;
  removePriceUnit: Action<PriceUnitModel, PriceUnit>;
  replacePriceUnit: Action<PriceUnitModel, PriceUnit>;

  createPriceUnit: Thunk<PriceUnitModel, CreatePriceUnit, Injections>;
  updatePriceUnit: Thunk<PriceUnitModel, UpdatePriceUnit, Injections>;
  deletePriceUnit: Thunk<PriceUnitModel, DeletePriceUnit, Injections>;
}

export const priceUnitModel: PriceUnitModel = {
  priceUnits: { items: null, total: null },
  priceUnit: null,

  setPriceUnit: action((state, payload) => {
    state.priceUnit = payload;
  }),

  addPriceUnit: action((state, payload) => {
    if (state.priceUnits.items) {
      state.priceUnits.items.push(payload);
    } else {
      state.priceUnits["items"] = [payload];
    }
  }),

  removePriceUnit: action((state, payload) => {
    if (state.priceUnits.items) {
      const index = state.priceUnits.items!.findIndex(e => e.id === payload.id);
      state.priceUnits.items!.splice(index, 1);
    }
  }),

  replacePriceUnit: action((state, payload) => {
    state.priceUnit = payload;
    if (state.priceUnits.items) {
      const index = state.priceUnits.items!.findIndex(e => e.id === payload.id);
      if (index >= 0) state.priceUnits.items!.splice(index, 1, payload);
    }
  }),

  createPriceUnit: thunk(async (actions, payload, { injections }) => {
    const { priceUnitService } = injections;
    const response = await priceUnitService.createPriceUnit(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.createPriceUnit;
    actions.setPriceUnit(item);
    actions.addPriceUnit(item);

    return item;
  }),

  updatePriceUnit: thunk(async (actions, payload, { injections }) => {
    const { priceUnitService } = injections;
    const response = await priceUnitService.updatePriceUnit(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.updatePriceUnit;
    actions.setPriceUnit(item);
    actions.replacePriceUnit(item);

    return item;
  }),

  deletePriceUnit: thunk(async (actions, payload, { injections }) => {
    const { priceUnitService } = injections;
    const response = await priceUnitService.deletePriceUnit(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.deletePriceUnit;
    actions.setPriceUnit(item);
    actions.removePriceUnit(item);

    return item;
  }),
};
