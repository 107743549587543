import React, { useEffect, useState } from "react";
import { $enum } from "ts-enum-util";
import _ from "lodash";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import overrideClasses from "../../../utils/overrideClasses";
import { FilterReporting } from "../../../components/Tailwind/Filters";
import { EnumReportingView } from "../../../lib/ground-aws-graphql-core/models/Product";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import contextStore from "../../../redux/store";
import { getLocale } from "../../../lang";
import UsersCardList from "./users-card-list";
import { EnumUserStatus } from "../../../utils/user";
import {
  EnumBackOfficeUserRole,
  EnumUserRole,
} from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import ReportingView from "../../../components/Tailwind/ReportingView";
import { getReportingDates } from "../../../utils/filter";
import {
  getTodayInCenterTimezone,
  getDateInUtcUsingOffset,
  addDuration,
  eachUnitOfInterval,
  isSameDates,
  transformDateForQuery,
  displayDayLabel,
} from "../../../utils/config";

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
  backURL: string;
}

export enum EnumFinalRole {
  User = "User",
  Admin = "Admin",
}

const UsersDashboard = (props: Props): JSX.Element => {
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  // init weekly view
  const [view, setView] = useState(EnumReportingView.MONTH);

  const now = getTodayInCenterTimezone(centerTimezone);
  const { current } = getReportingDates(now, view, centerTimezone);

  const [date, setDate] = useState(now);
  const [startDate, setStartDate] = useState(current?.start);
  const [endDate, setEndDate] = useState(current?.end);

  const listReportingsUser = GroundGraphqlContextStore.useStoreActions(
    actions => actions.reporting.listReportingsUser
  );

  const reportingsUser = GroundGraphqlContextStore.useStoreState(
    state => state.reporting.reportingsUser.items
  );

  const status = $enum(EnumUserStatus).getValues();
  const allStatus = [undefined, ...status];

  const roles = [undefined, EnumFinalRole.User, EnumFinalRole.Admin];

  const allCombinations = allStatus.map(s => {
    return roles.map(r => {
      return {
        status: s,
        role: r,
      };
    });
  });

  const combinations = _.flatten(allCombinations);

  const dataByStatusAndRole = combinations.map(c => {
    const { status, role } = c;

    let data1 = reportingsUser.filter(
      a => !a.createdStartDate && !a.createdEndDate
    );

    let data2 = reportingsUser.filter(
      a => a.createdStartDate && a.createdEndDate
    );

    if (status) {
      data1 = data1.filter(a => a.status === status);
      data2 = data2.filter(a => a.status === status);
    } else {
      data1 = data1.filter(a => !a.status);
      data2 = data2.filter(a => !a.status);
    }

    if (role) {
      data1 = data1.filter(a => a.role === role);
      data2 = data2.filter(a => a.role === role);
    } else {
      data1 = data1.filter(a => !a.role);
      data2 = data2.filter(a => !a.role);
    }

    return {
      status: c.status,
      role: c.role,
      data: data1,
      range_date: data2,
    };
  });

  // no role filtering (users + administrators)
  const dataByStatus = allStatus.map(s => {
    const data1 = s
      ? reportingsUser.filter(
          a =>
            !a.role &&
            a.status === s &&
            !a.createdStartDate &&
            !a.createdEndDate
        )
      : reportingsUser.filter(
          a => !a.role && !a.status && !a.createdStartDate && !a.createdEndDate
        );

    const data2 = s
      ? reportingsUser.filter(
          a =>
            !a.role && a.status === s && a.createdStartDate && a.createdEndDate
        )
      : reportingsUser.filter(
          a => !a.role && !a.status && a.createdStartDate && a.createdEndDate
        );

    return {
      status: s,
      data: data1,
      range_date: data2,
    };
  });

  const dataAllForView =
    dataByStatusAndRole.find(d => !d.status && !d.role)?.data || [];

  const dataConfirmedForView =
    dataByStatusAndRole.find(
      d => d.status === EnumUserStatus.CONFIRMED && !d.role
    )?.data || [];

  const dataUnConfirmedForView =
    dataByStatusAndRole.find(
      d => d.status === EnumUserStatus.UNCONFIRMED && !d.role
    )?.data || [];

  const dataUsersForView =
    dataByStatusAndRole.find(
      d => d.role && d.role === EnumFinalRole.User && !d.status
    )?.data || [];

  const dataAdministratorsForView =
    dataByStatusAndRole.find(d => d.role === EnumFinalRole.Admin && !d.status)
      ?.data || [];

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  const days = eachUnitOfInterval(startDate, endDate, "day");

  useEffect(() => {
    fetchData();
  }, [startDate, view]);

  const fetchData = () => {
    setLoading(true);

    Promise.all([
      listReportingsUser({
        filter: {
          date: transformDateForQuery(startDate, centerTimezone, view),
          view,
        },
      }),
    ]).finally(() => setLoading(false));
  };

  const onChangeDate = (date: Date) => {
    const { current } = getReportingDates(date, view, centerTimezone);
    setDate(date);
    setStartDate(current.start);
    setEndDate(current.end);
  };

  const onPrevious = () => {
    const { previous } = getReportingDates(date, view, centerTimezone);

    setStartDate(previous.start);
    setEndDate(previous.end);
    setDate(previous.start);
  };

  const onNext = () => {
    const { next } = getReportingDates(date, view, centerTimezone);

    setStartDate(next.start);
    setEndDate(next.end);
    setDate(next.start);
  };

  const handleOnChangeReportingView = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const view = e.target.value as EnumReportingView;
    setView(view);
    const { current } = getReportingDates(now, view, centerTimezone);
    setDate(current.start);
    setStartDate(current.start);
    setEndDate(current.end);
  };

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        tools: {
          download: true,
          pan: false,
          reset: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: days.map(d =>
        displayDayLabel(d, currentAppLocale, centerTimezone)
      ),
    },
    colors: ["#309bff", "#29be95", "#f05e5e", "#ffe0a4", "#928cb8"],
    yaxis: {
      title: {
        text: intl.formatMessage({
          id: `general.number`,
        }),
      },
      min: 0,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val?.toString();
        },
      },
    },
  };

  const getReportingStatusData = () => {
    const results = allStatus.map(s => {
      const data = days.map(date => {
        const data = s
          ? dataByStatus.find(d => d.status === s)?.range_date || []
          : dataByStatus.find(d => !d.status)?.range_date || [];

        const value = data?.find(
          e =>
            isSameDates(
              new Date(e.createdStartDate),
              getDateInUtcUsingOffset(date)
            ) &&
            isSameDates(
              new Date(e.createdEndDate),
              getDateInUtcUsingOffset(addDuration(date, 1, "day"))
            )
        );

        return {
          date: displayDayLabel(date, currentAppLocale, centerTimezone),
          total: value ? value.total : 0,
          variation: value ? value.variation : 0,
        };
      });

      return {
        name: s,
        data,
      };
    });

    return results;
  };

  const getReportingRoleData = () => {
    const results = ["users", "administrators"].map(k => {
      const data = days.map(date => {
        const roles: string[] =
          k === "users"
            ? [EnumUserRole[EnumUserRole.User]]
            : [
                EnumBackOfficeUserRole[EnumBackOfficeUserRole.CenterAdmin],
                EnumBackOfficeUserRole[EnumBackOfficeUserRole.OperatorAdmin],
              ];

        const data =
          dataByStatusAndRole.find(
            r => r.role && roles.includes(r.role) && !r.status
          )?.range_date || [];

        const value = data?.find(
          e =>
            isSameDates(
              new Date(e.createdStartDate),
              getDateInUtcUsingOffset(date)
            ) &&
            isSameDates(
              new Date(e.createdEndDate),
              getDateInUtcUsingOffset(addDuration(date, 1, "day"))
            )
        );

        return {
          date: displayDayLabel(date, currentAppLocale, centerTimezone),
          total: value ? value.total : 0,
          variation: value ? value.variation : 0,
        };
      });

      return {
        name: k,
        data,
      };
    });

    return results;
  };

  const renderReporting = () => {
    const data = getReportingStatusData();

    const series = allStatus.map(s => {
      const dataByStatus = data.find(r => r.name === s)?.data;
      const key = s ? EnumUserStatus[s] : "ALL";

      return {
        name: `${intl.formatMessage({
          id: `page.dashboards.chart.total.${key.toLowerCase()}.title`,
        })}`,
        data: dataByStatus?.map(d => d.total) || [],
      };
    });

    const data1 = getReportingRoleData();
    const series1 = ["users", "administrators"].map(e => {
      const dataByRole = data1.find(r => r.name === e)?.data;

      return {
        name: `${intl.formatMessage({
          id: `page.dashboards.chart.total.${e}.title`,
        })}`,
        data: dataByRole?.map(d => d.total) || [],
      };
    });

    return (
      <div className="m-10">
        <ReactApexChart
          options={{
            ...options,
            title: {
              text: `${intl.formatMessage({
                id: `page.dashboards.chart.${view.toLowerCase()}.user.breakdown.title`,
              })}`,
              align: "left",
            },
          }}
          series={series.concat(series1)}
          type="line"
          height={600}
        />
      </div>
    );
  };

  return (
    <>
      <div className={overrideClasses({ loading })} />

      <div className={overrideClasses({ hidden: loading })}>
        <Helmet>
          <title>
            {intl.formatMessage({ id: "page.dashboards.document.title" })}
          </title>
        </Helmet>

        <div className="flex items-center gap-4 p-8">
          <ReportingView view={view} onChange={handleOnChangeReportingView} />

          <FilterReporting
            date={date}
            view={view}
            locale={currentAppLocale}
            onChangeDate={onChangeDate}
            onPrevious={onPrevious}
            onNext={onNext}
          />
        </div>

        <div className="mx-8">
          <UsersCardList
            {...props}
            data={{
              all: dataAllForView[0],
              users: dataUsersForView[0],
              administrators: dataAdministratorsForView[0],
              confirmed: dataConfirmedForView[0],
              unconfirmed: dataUnConfirmedForView[0],
            }}
          />
        </div>
        <div id="chart_users">{renderReporting()}</div>
      </div>
    </>
  );
};

export default injectIntl(UsersDashboard);
