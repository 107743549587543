import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import history from "../../history";
import { EnumPaths } from "../../utils/navigation";

interface Props {
  intl: IntlShape;
}

const Home = (props: Props): JSX.Element => {
  useEffect(() => {
    history.push(EnumPaths.CENTERS);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {props.intl.formatMessage({ id: "page.list.centers.document.title" })}
        </title>
      </Helmet>
    </>
  );
};

export default injectIntl(Home);
