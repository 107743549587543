import React, { useState } from "react";
import images from "../../../../images";
import { getCountryCodeByName } from "../../../../lang";
import IntlMessages from "../../../../utils/messages";
import { getCypressTestId } from "../../../../utils/config";
import { ClosedDayItem, createClosedDay } from "../../../../utils/dayslot";
import ModalDateRange from "../../../ModalDateRange";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import Add from "../../../Tailwind/Button/Add";
import ClosedDaysComponent from "./ClosedDays";
import HolidaysComponent from "./Holidays";

interface Props {
  item: { id: string } | null;
  closedDays: ClosedDayItem[] | null;
  editClosedDays?: boolean;
  onChangeClosedDays: (closedDay: ClosedDayItem) => void;
}

const CardClosedDays = (props: Props): JSX.Element => {
  const { closedDays, editClosedDays = true, item, onChangeClosedDays } = props;

  const [disableClosedDays, setDisableClosedDays] = useState(!editClosedDays);
  const [openModal, setOpenModal] = useState(false);

  const center = GroundGraphqlContextStore.useStoreState(
    state => state.center.center
  );

  const country = center?.country;
  const countryCode = getCountryCodeByName(country || "");

  const handleAddClosedDay = (start: Date, end: Date) => {
    const closedDay = createClosedDay(start, end);
    onChangeClosedDays(closedDay);
  };

  return !center ? (
    <div className="loading" />
  ) : (
    <div className="bg-white shadow sm:rounded-lg my-30px">
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 className="text-16px leading-6 font-medium text-gray-900">
          <IntlMessages id="page.closed.days.title" />
        </h3>
      </div>
      <div className="mx-auto xl:grid grid-cols-2 md:px-6 lg:px-8 p-20px bg-gray-100">
        <div className="relative">
          {disableClosedDays && (
            <div className="absolute w-full h-full flex items-center justify-center bg-white bg-opacity-50 z-50">
              <img
                data-cy="edit-picto-closeddays"
                data-testid={getCypressTestId(item)}
                src={images.pencil}
                alt="edit"
                className="w-8 h-8 cursor-pointer pencil-edit border-2 opacity-75 hover:opacity-100"
                onClick={() => setDisableClosedDays(false)}
              />
            </div>
          )}
          <div className="items-center inline-flex justify-between w-full h-10">
            <h4 className="text-16px leading-6 font-medium text-gray-900">
              <IntlMessages id="page.center.closed.days" />
            </h4>

            <Add label="general.add" onClick={() => setOpenModal(!openModal)} />
            <ModalDateRange
              title="page.center.closed.days"
              description="page.center.closed.infos"
              onSubmit={handleAddClosedDay}
              open={openModal}
              setOpen={setOpenModal}
              onRequestClose={() => setOpenModal(!openModal)}
            />
          </div>
          <div className="mt-5 max-h-350px overflow-auto">
            {closedDays && closedDays?.length > 0 && (
              <table className="w-full">
                <ClosedDaysComponent
                  closedDays={closedDays}
                  country={countryCode}
                  onChange={onChangeClosedDays}
                />
              </table>
            )}
            {/* when no closed days */}
            {!closedDays?.length && (
              <span>
                <IntlMessages id="page.center.no.closed.days" />
              </span>
            )}
          </div>
        </div>

        <div className="relative ml-5">
          <div className="items-center inline-flex justify-end w-full h-10">
            <h4 className="text-16px leading-6 font-medium text-gray-900">
              <IntlMessages id="page.center.holidays" />
            </h4>
          </div>
          <div className="mt-5 max-h-350px overflow-auto">
            <table className="w-full">
              <HolidaysComponent country={countryCode} />
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardClosedDays;
