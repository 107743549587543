import { Action, Thunk, action, thunk } from "easy-peasy";
import { ErrorGraphQL } from "../../services/coreUtils";
/*
 *
 * GlobalOrder Model
 *
 */

import { Injections } from "../../store";
import { GqlQuery, StoreModel } from "..";
import { BackOfficeUser } from "../BackOfficeUser";
import { User } from "../User";
import { Operator } from "../Operator";

import {
  EnumChannel,
  EnumCurrency,
  EnumOrderStatus,
  SearchableGlobalOrderFilterInput,
  SearchableGlobalOrderSortInput,
} from "../../api/graphql/types";
import { ModelOrderConnection } from "../Order";

export interface GlobalOrder {
  operator?: Operator;
  id: string;
  user: User;
  orders: ModelOrderConnection;
  price: number;
  totalTax: number;
  totalAdjustment: number;
  currency: EnumCurrency;
  channel: EnumChannel;
  comment: string;
  timeplaced: string;
  status: EnumOrderStatus;
  ormOrder: string;
  orderer?: BackOfficeUser | null;
  metadata?: string;
  touchPoint?: string;
}

interface PaginatedGlobalOrders {
  pages: { [key: number]: GlobalOrder[] | null };
  total?: number | null;
}

export interface ModelGlobalOrderConnection {
  items: GlobalOrder[];
  total?: number | null;
}

// Interface declaration

export interface SearchGlobalOrdersOpts {
  filter: SearchableGlobalOrderFilterInput;
  sort?: SearchableGlobalOrderSortInput;
  limit?: number | null;
  gql?: string | null;
  page?: number;
  from?: number;
}

export interface GlobalOrderModel {
  globalOrders: ModelGlobalOrderConnection;
  paginatedGlobalOrders: PaginatedGlobalOrders;
  hasMoreData: boolean;
  globalOrder: GlobalOrder | null;
  setGlobalOrders: Action<
    GlobalOrderModel,
    { data: ModelGlobalOrderConnection; page?: number }
  >;
  setGlobalOrder: Action<GlobalOrderModel, GlobalOrder>;
  getGlobalOrder: Thunk<GlobalOrderModel, GqlQuery, Injections>;
  searchGlobalOrders: Thunk<
    GlobalOrderModel,
    SearchGlobalOrdersOpts,
    Injections,
    StoreModel
  >;
}

export const globalOrderModel: GlobalOrderModel = {
  globalOrders: { items: [], total: 0 },
  paginatedGlobalOrders: { pages: {} },
  hasMoreData: false,
  globalOrder: null,

  setGlobalOrder: action((state, payload) => {
    state.globalOrder = payload;
  }),

  setGlobalOrders: action(
    (state, payload: { data: ModelGlobalOrderConnection; page?: number }) => {
      const { data } = payload;
      let { page } = payload;
      state.globalOrders = data;

      page = page || 0;

      const clone = { ...state.paginatedGlobalOrders };
      clone.pages[page] = data.items;
      state.paginatedGlobalOrders = clone;

      let hasMoreData = false;
      if (data.total) {
        // search query
        let count = 0;
        Object.keys(state.paginatedGlobalOrders.pages).forEach(key => {
          const index = parseInt(key);
          if (index <= page!) {
            const items = state.paginatedGlobalOrders.pages[key];
            count += items.length;
          }
        });
        hasMoreData = count < data.total;
      }

      state.hasMoreData = hasMoreData;
    }
  ),

  getGlobalOrder: thunk(async (actions, payload, { injections }) => {
    const { globalOrderService } = injections;
    const response = await globalOrderService.getGlobalOrder(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    actions.setGlobalOrder(response.data.getGlobalOrder as any);

    return response.data.getGlobalOrder;
  }),
  searchGlobalOrders: thunk(async (_actions, payload, { injections }) => {
    const { globalOrderService } = injections;
    const response = await globalOrderService.searchGlobalOrders(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.searchGlobalOrders) {
      const data = response.data.searchGlobalOrders;
      _actions.setGlobalOrders({ data, page: payload.page });

      return data;
    }

    return null;
  }),
};
