import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import overrideClasses from "../../../../../utils/overrideClasses";
import { FilterCalendar } from "../../../../../components/Tailwind/Filters";
import { EnumCalendarType } from "../../../../../lib/ground-aws-graphql-core/models/Product";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import contextStore from "../../../../../redux/store";
import { getLocale } from "../../../../../lang";
import PageSubheader from "../../../../../components/PageSubheader";
import { getTranslation } from "../../../../../utils/translation";
import {
  getTodayInCenterTimezone,
  getCurrentWeekInCenterTimezone,
  randomColor,
  getWeekInCenterTimezone,
  getPreviousWeek,
  getNextWeek,
  eachUnitOfInterval,
  transformDateForQuery,
  displayDayName,
} from "../../../../../utils/config";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
  backURL: string;
}

const RealTimeIncidentsDashboard = (props: Props): JSX.Element => {
  const { intl, match, backURL } = props;
  const [loading, setLoading] = useState(false);

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const now = getTodayInCenterTimezone(centerTimezone);

  const { startWeek, endWeek } = getCurrentWeekInCenterTimezone(centerTimezone);
  const [startDate, setStartDate] = useState(startWeek);
  const [endDate, setEndDate] = useState(endWeek);

  const [date, setDate] = useState(now);

  const listIncidentReportings = GroundGraphqlContextStore.useStoreActions(
    actions => actions.reporting.listIncidentReportings
  );

  const incidentReportings = GroundGraphqlContextStore.useStoreState(
    state => state.reporting.incidentReportings.items
  );

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  const days = eachUnitOfInterval(startDate, endDate, "day").map(v =>
    displayDayName(v, currentAppLocale, centerTimezone).toUpperCase()
  );

  const status = [
    ...new Set(
      incidentReportings
        .flatMap(e => e.indicators_status)
        .map(i => i.status.code)
    ),
  ];

  const types = [
    ...new Set(
      incidentReportings.flatMap(e => e.indicators_type).map(i => i.type.code)
    ),
  ];

  const allIndicatorsStatus = incidentReportings.flatMap(
    i => i.indicators_status
  );
  const allIndicatorsType = incidentReportings.flatMap(i => i.indicators_type);

  const incidentStatusDatasets = status.map(s => {
    // reportings for status
    const reportingsForStatus = incidentReportings
      .map(r => r.indicators_status.find(r => r.status.code === s))
      .filter(item => !!item);

    const label = allIndicatorsStatus.find(a => a.status.code === s)?.status
      .label;

    const dataIncidents = reportingsForStatus.map(item =>
      item ? item.indicators.total_incidents : 0
    );

    const { color, colorOpacity } = randomColor();

    const datasets = {
      label: getTranslation(label),
      backgroundColor: color,
      borderColor: colorOpacity,
      borderWidth: 1,
    };

    return {
      datasets: {
        ...datasets,
        data: dataIncidents,
      },
    };
  });

  const incidentTypesDatasets = types.map(t => {
    // reportings for type

    const reportingsForType = incidentReportings
      .map(r => r.indicators_type.find(r => r.type.code === t))
      .filter(item => !!item);

    const label = allIndicatorsType.find(a => a.type.code === t)?.type.label;

    const dataIncidents = reportingsForType.map(item =>
      item ? item.indicators.total_incidents : 0
    );

    const { color, colorOpacity } = randomColor();

    const datasets = {
      label: getTranslation(label),
      backgroundColor: color,
      borderColor: colorOpacity,
      borderWidth: 1,
    };

    return {
      datasets: {
        ...datasets,
        data: dataIncidents,
      },
    };
  });

  useEffect(() => {
    fetchData();
  }, [startDate]);

  const fetchData = () => {
    setLoading(true);
    Promise.all([
      listIncidentReportings({
        filter: {
          buildingId: match.params.cid,
          date: transformDateForQuery(startDate, centerTimezone),
          type: EnumCalendarType.WEEK,
        },
      }),
    ]).finally(() => setLoading(false));
  };

  const onChangeDate = (d: Date) => {
    setDate(d);
    const { startWeek, endWeek } = getWeekInCenterTimezone(d, centerTimezone);
    setStartDate(startWeek);
    setEndDate(endWeek);
  };

  const onPreviousWeek = () => {
    const { startWeek, endWeek } = getPreviousWeek({
      start: startDate,
      end: endDate,
    });

    setStartDate(startWeek);
    setEndDate(endWeek);
    setDate(startWeek);
  };

  const onNextWeek = () => {
    const { startWeek, endWeek } = getNextWeek({
      start: startDate,
      end: endDate,
    });

    setStartDate(startWeek);
    setEndDate(endWeek);
    setDate(startWeek);
  };

  return (
    <>
      <div className={overrideClasses({ loading })} />

      <div className={overrideClasses({ hidden: loading })}>
        <Helmet>
          <title>
            {intl.formatMessage({ id: "page.dashboards.document.title" })}
          </title>
        </Helmet>

        <PageSubheader
          goBackEnabled
          goBackId={`btn-back-occupancy-reporting`}
          goBackURL={backURL}
        />

        <div className="flex items-center gap-4 px-8">
          <FilterCalendar
            date={date}
            locale={currentAppLocale}
            onChangeDate={onChangeDate}
            onPrevious={onPreviousWeek}
            onNext={onNextWeek}
          />
        </div>

        <div className="flex flex-col px-8">
          <div className="flex my-8">
            <div className="w-1/2">
              <Line
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: "top" as const,
                    },
                    title: {
                      display: true,
                      text: intl.formatMessage({
                        id: "page.dashboards.chart.total.incidents.status.title",
                      }),
                    },
                  },
                  scales: {
                    y: {
                      suggestedMin: 0,
                    },
                  },
                }}
                data={{
                  labels: days,
                  datasets: incidentStatusDatasets.flatMap(o => o.datasets),
                }}
              />
            </div>
            <div className="w-1/2">
              <Line
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: "top" as const,
                    },
                    title: {
                      display: true,
                      text: intl.formatMessage({
                        id: "page.dashboards.chart.total.incidents.type.title",
                      }),
                    },
                  },
                  scales: {
                    y: {
                      suggestedMin: 0,
                    },
                  },
                }}
                data={{
                  labels: days,
                  datasets: incidentTypesDatasets.flatMap(o => o.datasets),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(RealTimeIncidentsDashboard);
