import { getBucket, getImageHandlerApi } from "../config";
import images from "../../images";
import { EnumPermissionEntity } from "../../lib/ground-aws-graphql-core/api/graphql/types";

export const getImageUrl = (
  entity: EnumPermissionEntity | null,
  picture: string | null,
  width = 320,
  height = 0
): string => {
  const defaultImage = getDefaultImage(entity);

  if (picture) {
    const https = picture.startsWith("https");
    if (https) {
      const url = new URL(picture);
      const bucket = getBucket();
      const api = getImageHandlerApi();

      const pathname = url.pathname.replace(bucket, "").replace("//", "/");
      const w = Math.round(width);
      const h = Math.round(height);

      return api ? `${api}/${w}x${h}${pathname}` : defaultImage;
    } else {
      return defaultImage;
    }
  }

  return defaultImage;
};

export const getDefaultImage = (entity: EnumPermissionEntity | null): any => {
  switch (entity) {
    case EnumPermissionEntity.USER:
    case EnumPermissionEntity.BACK_OFFICE_USER:
      return images.accounts;
    default:
      return images.defaultCenter;
  }
};
