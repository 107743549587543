import React from "react";
import classnames from "classnames";
import { getCypressTestId } from "../../../utils/config";

export interface ButtonProps {
  id: string;
  name: string;
  type: "button" | "submit" | "reset" | undefined;
  children: React.ReactNode;
  item?: { id: string } | null | undefined;
  outline?: boolean;
  disabled?: boolean;
  onClick?: (e: any) => void;
  className?: string;
  small?: boolean;
}

const Button = (props: ButtonProps): JSX.Element => {
  const {
    outline = false,
    disabled,
    className,
    id,
    name,
    item,
    type,
    onClick,
    children,
  } = props;

  const btnBase =
    "items-center px-4 py-2 border rounded-5 focus:outline-none transition ease-in-out duration-150";

  let styles = classnames(
    btnBase,
    {
      "border-ground-blue-100 text-ground-blue-100 bg-ground-white-200 hover:bg-ground-blue-100 hover:text-white active:bg-white active:text-ground-blue-100":
        outline,
      "border-transparent text-white bg-ground-blue-100 hover:bg-white hover:text-ground-blue-100 hover:border-1 hover:border-ground-blue-100 focus:border-ground-blue-100 active:bg-ground-blue-100 active:text-white":
        !outline,
    },
    className
  );

  if (disabled) {
    styles = classnames(
      btnBase,
      {
        "text-12px leading-5 font-medium border-transparent text-white bg-ground-gray-100":
          disabled,
      },
      className
    );
  }

  return (
    <button
      id={id}
      name={name}
      data-cy={name}
      data-testid={getCypressTestId(item)}
      type={type}
      onClick={onClick}
      className={styles}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
