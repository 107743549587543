/*
 *
 * SingleProduct Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import {
  CreateSingleProductMutation,
  DeleteSingleProductMutation,
} from "../../api/graphql/types";

import {
  createSingleProduct as createSingleProductMutation,
  deleteSingleProduct as deleteSingleProductMutation,
} from "../../api/graphql/mutations";

export const createSingleProduct = payload =>
  ApolloClient.mutate({
    mutation: gql(createSingleProductMutation),
    variables: { input: payload },
  }) as {
    data: CreateSingleProductMutation;
  };

export const deleteSingleProduct = payload =>
  ApolloClient.mutate({
    mutation: gql(deleteSingleProductMutation),
    variables: { input: payload },
  }) as {
    data: DeleteSingleProductMutation;
  };
