import React, { useState } from "react";
import classnames from "classnames";
import ModalAddSingleProduct from "../../../../routes/catalog/product/single-products/single-product-modal";
import { ActionTypes } from "../../../../utils/types";
import IntlMessages from "../../../../utils/messages";
import Button from "../../Button";
import { getMaxGroupedProducts } from "../../../../utils/config";
import {
  EnumProductTypology,
  EnumServiceType,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Product } from "../../../../lib/ground-aws-graphql-core/models/Product";

interface Props {
  index: number;
  typology: EnumProductTypology | null | undefined;
  serviceType: EnumServiceType;
  title: string;
  onAddSingleProduct: (o: Product) => void;
  onRemoveSingleProduct: (o: Product) => void;
  items: { id: string; singleProduct: Product; action: ActionTypes }[];
  alternate?: boolean;
}

const HeaderSingleProduct = (props: Props): JSX.Element => {
  const { index, serviceType, title, alternate } = props;

  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    setOpen(!open);
  };

  const alter = alternate != undefined ? alternate : true;

  const className = (id, value) =>
    classnames(value, {
      "bg-ground-white-200": alter && id % 2 === 0,
    });

  return (
    <>
      <div
        key={`single_products_${index}`}
        className={className(
          index,
          "sm:grid sm:gap-4 sm:items-start sm:py-2 sm:px-8 px-2 py-2 sm:grid-cols-3"
        )}
      >
        <label className="block text-14px font-medium leading-5 text-ground-gray-100 sm:mt-px sm:pt-2">
          <IntlMessages id={title} />{" "}
          <IntlMessages
            id="general.max"
            values={{ total: getMaxGroupedProducts() }}
          />
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <Button
            id="btn-add-single-product"
            name="btn-add-single-product"
            item={null}
            type="button"
            outline
            onClick={handleOpenModal}
          >
            <IntlMessages id={`page.product.add.product.${serviceType}`} />
          </Button>
        </div>
      </div>
      {open && (
        <ModalAddSingleProduct
          {...props}
          isOpen={open}
          onRequestClose={handleOpenModal}
        />
      )}
    </>
  );
};

export default HeaderSingleProduct;
