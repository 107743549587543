import React from "react";
import { Helmet } from "react-helmet";
import { match as Match } from "react-router-dom";
import { injectIntl, IntlShape } from "react-intl";
import { withOneColLayout } from "../../../../utils";
import PageSubheader from "../../../../components/PageSubheader";
import AttributeKeyForm from "../form";

interface Props {
  match: Match<{ id: string }>;
  intl: IntlShape;
  backURL: string;
}

const UpdateAttributeKey = (props: Props) => {
  const { intl, backURL } = props;

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: "page.attr.key.update.document.title",
          })}
        </title>
      </Helmet>

      <PageSubheader
        goBackEnabled
        goBackId="btn-back-attr-key-update"
        goBackURL={backURL}
      />

      <AttributeKeyForm {...props} edition />
    </>
  );
};

export default withOneColLayout(injectIntl(UpdateAttributeKey));
