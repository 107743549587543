import React from "react";
import { Route, Redirect } from "react-router-dom";
import { EnumPaths } from "../utils/navigation";

interface Props {
  component: any;
  path: string;
}

const PrivateRoute = (props: Props): JSX.Element => {
  const token = localStorage.getItem("token");

  return (
    <Route
      path={props.path}
      render={renderProps => {
        return token ? (
          <props.component {...renderProps} />
        ) : (
          <Redirect
            to={{
              pathname: `/${EnumPaths.SIGNIN}`,
              state: { from: renderProps.location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
