import React from "react";
import { ActionCreator } from "easy-peasy";
import { match as Match } from "react-router-dom";
import CategoryComponent from "../category";
import ModalCreateUpdateCategory from "../../../category/create-update";
import ModalDuplicateCategory from "../../../category/duplicate";
import {
  EnumCategoryType,
  EnumPermissionEntity,
  EnumServiceType,
} from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Category } from "../../../../../lib/ground-aws-graphql-core/models/Category";
import { Service } from "../../../../../lib/ground-aws-graphql-core/models/Service";
import AddElement from "../../../../../components/Tailwind/AddElement";
import { EnumAction } from "../../../../../utils/types";

type Props = {
  loading: boolean;
  serviceType: EnumServiceType;
  categories: Category[];
  services: Service[];
  handleEditService: (e: any, service: Service) => void;
  handleClickService: (e: any, service: Service) => void;
  fetchData: () => void;
  match: Match<{ cid: string }>;
  modalCreateUpdateCategoryIsOpen: boolean;
  setModalCreateUpdateCategoryIsOpen: ActionCreator<boolean>;
  modalDuplicateCategoryIsOpen: boolean;
  setModalDuplicateCategoryIsOpen: ActionCreator<boolean>;
  modalCategoryAction: EnumAction;
  modalCategorySelected: Category | null;
  handleEditCategory: (category: Category) => void;
  handleDuplicateCategory: (category: Category) => void;
  handleAddService: (category: Category) => void;
};

const ListServiceCategories = ({
  serviceType,
  categories,
  services,
  handleEditService,
  handleClickService,
  fetchData,
  match,
  modalCreateUpdateCategoryIsOpen,
  setModalCreateUpdateCategoryIsOpen,
  modalDuplicateCategoryIsOpen,
  setModalDuplicateCategoryIsOpen,
  modalCategoryAction,
  modalCategorySelected,
  handleEditCategory,
  handleDuplicateCategory,
  handleAddService,
}: Props): JSX.Element => {
  const handleToggleModalCreateUpdateCategory = () => {
    setModalCreateUpdateCategoryIsOpen(!modalCreateUpdateCategoryIsOpen);
  };

  const handleToggleModalDuplicateCategory = () => {
    setModalDuplicateCategoryIsOpen(!modalDuplicateCategoryIsOpen);
  };

  return (
    <>
      {categories?.map(category => (
        <CategoryComponent
          match={match}
          key={category.id}
          category={category}
          services={services.filter(s => s.category?.id === category.id)}
          serviceType={serviceType}
          onEditService={handleEditService}
          onClickService={handleClickService}
          onEditCategory={handleEditCategory}
          onDuplicateCategory={handleDuplicateCategory}
          fetchData={fetchData}
          onAddService={handleAddService}
        />
      ))}

      {(!categories || categories.length === 0) && (
        <AddElement
          onClick={handleToggleModalCreateUpdateCategory}
          entity={EnumPermissionEntity.CATEGORY}
        />
      )}

      <ModalCreateUpdateCategory
        isOpen={modalCreateUpdateCategoryIsOpen}
        parent={false}
        categories={categories}
        toggle={handleToggleModalCreateUpdateCategory}
        action={modalCategoryAction}
        type={
          serviceType === EnumServiceType.SPACE
            ? EnumCategoryType.SPACE
            : EnumCategoryType.SERVICE
        }
        category={modalCategorySelected}
        centerId={match.params.cid}
      />

      <ModalDuplicateCategory
        isOpen={modalDuplicateCategoryIsOpen}
        toggle={handleToggleModalDuplicateCategory}
        serviceType={serviceType}
        type={
          serviceType === EnumServiceType.SPACE
            ? EnumCategoryType.SPACE
            : EnumCategoryType.SERVICE
        }
        category={modalCategorySelected}
        match={match}
      />
    </>
  );
};

export default ListServiceCategories;
