import React, { useState, useEffect, useRef, useMemo } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import { toast } from "react-toastify";
import { FieldAttributes } from "formik";
import Block from "../../../components/Tailwind/Block";
import Header from "../../../components/Tailwind/Block/Header";
import {
  getAdminUserRoleItems,
  getUserRoleItems,
  getAdminUserLocaleItems,
} from "../../../utils/types";
import history from "../../../history";
import { EnumPaths } from "../../../utils/navigation";
import {
  EnumAttributeType,
  EnumPermissionEntity,
} from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import contextStore from "../../../redux/store";
import { GroundAuthContextStore } from "../../../lib/ground-aws-cognito-auth-core";
import { User } from "../../../lib/ground-aws-graphql-core/models/User";
import DefaultForm, {
  AdditionalFieldAttributes,
} from "../../../components/Form/index";
import FormPhone from "../../../components/Form/FormPhone";
import overrideClasses from "../../../utils/overrideClasses";
import { getTranslation } from "../../../utils/translation";
import IntlMessages from "../../../utils/messages";
import CardAttributes from "../../../components/GroundBlocks/BlockCard/CardAttributes";
import {
  AttributeItem,
  getAttributes,
  getRequiredAttributes,
} from "../../../utils/attribute";
import { AttributeKey } from "../../../lib/ground-aws-graphql-core/models/AttributeKey";
import { convertAttributeItems } from "../../../utils/preprocessing";

interface Props {
  intl: IntlShape;
  administrator: boolean;
  match: Match<{ id: string }>;
  edition: boolean;
}

const DEFAULT_LIMIT = 100;

const UserForm = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState<
    string[]
  >([]);
  const attributesFormRef = useRef<React.MutableRefObject<any>>();
  const [attributes, setAttributes] = useState<AttributeItem[]>([]);
  const [attributeKeys, setAttributeKeys] = useState<AttributeKey[]>([]);
  const [showPanel, setShowPanel] = useState(false);
  const { intl, administrator, match, edition } = props;

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const searchAllAttributeKeys = GroundGraphqlContextStore.useStoreActions(
    actions => actions.attributeKey.searchAllAttributeKeys
  );

  const getBackOfficeUserAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.backOfficeUser.getBackOfficeUser
  );

  const setBackOfficeUserAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.backOfficeUser.setBackOfficeUser
  );

  const getUserAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.user.getUser
  );

  const setUserAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.user.setUser
  );

  const getUser = administrator ? getBackOfficeUserAction : getUserAction;
  const setUser = administrator ? setBackOfficeUserAction : setUserAction;

  const createAdminUser = GroundGraphqlContextStore.useStoreActions(
    actions => actions.backOfficeUser.createAdminUser
  );

  const createUser = GroundGraphqlContextStore.useStoreActions(
    actions => actions.user.createUser
  );

  const updateAdminUser = GroundGraphqlContextStore.useStoreActions(
    actions => actions.backOfficeUser.updateAdminUser
  );

  const updateUser = GroundGraphqlContextStore.useStoreActions(
    actions => actions.user.updateUser
  );

  const backOfficeUser = GroundGraphqlContextStore.useStoreState(
    state => state.backOfficeUser.backOfficeUser
  );

  const customer = GroundGraphqlContextStore.useStoreState(
    state => state.user.user
  );

  const enterprises = contextStore.useStoreState(
    state => state.enterprise.enterprises.items
  );

  const listEnterprises = contextStore.useStoreActions(
    actions => actions.enterprise.listEnterprises
  );

  const listPaymentMethods = GroundGraphqlContextStore.useStoreActions(
    actions => actions.paymentMethods.listPaymentMethods
  );

  const paymentMethods = GroundGraphqlContextStore.useStoreState(
    state => state.paymentMethods.paymentMethods
  );

  const searchAllPriceUnitVariations =
    GroundGraphqlContextStore.useStoreActions(
      actions => actions.priceUnitVariation.searchAllPriceUnitVariations
    );

  const priceUnitVariations =
    GroundGraphqlContextStore.useStoreState(
      state => state.priceUnitVariation.priceUnitVariations.items
    ) || [];

  const user = administrator ? backOfficeUser : customer;

  const url = administrator
    ? `/${EnumPaths.ROOT}/${EnumPaths.ADMINISTRATORS}`
    : `/${EnumPaths.ROOT}/${EnumPaths.USERS}`;

  const saveDatas = (formValues?) => {
    const exclude_payment_methods = paymentMethods.items
      .filter(p => !selectedPaymentMethods.includes(p.code))
      .map(i => i.code);
    setLoading(true);

    // Add the '+' to be conformed to the E164 norm only if it isn't already there
    let formattedPhone = formValues.phone;
    if (formValues.phone && !formValues.phone.includes("+"))
      formattedPhone = `+${formValues.phone}`;

    const payload = {
      ...formValues,
      phone: formattedPhone,
      exclude_payment_methods,
      attributes: attributes && convertAttributeItems(attributes),
    };

    if (edition) {
      // Update
      const updateUserAction = administrator ? updateAdminUser : updateUser;
      updateUserAction({ ...payload, id: match.params.id })
        .then(resp => {
          const { status } = resp;
          if (status !== 200) {
            toast(
              administrator
                ? intl.formatMessage({
                    id: "page.administrator.update.administrator.error",
                  })
                : intl.formatMessage({
                    id: "page.user.update.user.error",
                  }),
              {
                type: "error",
              }
            );
          } else {
            history.push(url);
            toast(
              intl.formatMessage({
                id: administrator
                  ? "page.administrator.update.administrator.success"
                  : "page.user.update.user.success",
              }),
              { type: "success" }
            );
          }
        })
        .catch(() => {
          toast(
            administrator
              ? intl.formatMessage({
                  id: "page.administrator.update.administrator.error",
                })
              : intl.formatMessage({ id: "page.user.update.user.error" }),
            {
              type: "error",
            }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      // creation
      const createUserAction = administrator ? createAdminUser : createUser;
      createUserAction(payload)
        .then(resp => {
          const { status } = resp;
          if (status !== 200 && status !== 201) {
            toast(
              administrator
                ? intl.formatMessage({
                    id: "page.administrator.create.administrator.error",
                  })
                : intl.formatMessage({
                    id: "page.user.create.user.error",
                  }),
              {
                type: "error",
              }
            );
          } else {
            history.push(url);
            toast(
              intl.formatMessage({
                id: administrator
                  ? "page.administrator.create.administrator.success"
                  : "page.user.create.user.success",
              }),
              { type: "success" }
            );
          }
        })
        .catch(err => {
          const { data } = err.response;
          const error = data?.error;
          const message = error?.message;
          let msg = administrator
            ? "page.administrator.create.administrator.error"
            : "page.user.create.user.error";

          if (message === "error.user.already.exists") {
            msg = `page.${administrator ? "administrator" : "user"}.${message}`;
          }
          toast(
            intl.formatMessage({
              id: msg,
            }),
            {
              type: "error",
            }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setLoading(true);

    const promises = [
      searchAllAttributeKeys({
        limit: DEFAULT_LIMIT,
        filter: {
          type: { eq: EnumAttributeType.USER },
          enabled: { eq: true },
        },
      }),
    ];
    if (match.params.id) {
      promises.push(getUser({ id: match.params.id }));
    }

    if (!administrator) {
      promises.push(listEnterprises(null));
      promises.push(
        searchAllPriceUnitVariations({
          limit: DEFAULT_LIMIT,
          filter: {
            enabled: { eq: true },
          },
        })
      );
      promises.push(listPaymentMethods({}));
    }

    Promise.all(promises)
      .then(response => {
        setAttributeKeys(response[0].items);
      })
      .finally(() => {
        setLoading(false);
      });

    // Clean user/admin in global state to avoid issues when navigating backwards with the browser
    // Solves user duplication issue
    return () => setUser(null);
  }, []);

  useEffect(() => {
    if (!administrator && user && attributeKeys) {
      const userPaymentMethods = (user as User).paymentMethods.items;
      const attr = getAttributes(user, EnumAttributeType.USER, attributeKeys);
      setAttributes([...attr]);
      setSelectedPaymentMethods(userPaymentMethods.map(p => p.code));
    }
  }, [user, attributeKeys]);

  const isMe = administrator && edition && me?.id === user?.id;

  const localeItemsForm = getAdminUserLocaleItems();

  const roleItems = administrator
    ? getAdminUserRoleItems(me)
    : getUserRoleItems();

  const enterpriseItems = enterprises.map(enterprise => ({
    value: enterprise.id,
    label: enterprise.name,
  }));

  const priceUnitVariationItems = priceUnitVariations.map(
    priceUnitVariation => ({
      value: priceUnitVariation.id,
      label: getTranslation(priceUnitVariation.name),
    })
  );

  const handleCheckPaymentMethod = e => {
    if (!e.target.checked) {
      // payment method is removed, we keep only the others payment methods
      const paymentMethodCodes = selectedPaymentMethods.filter(
        i => i !== e.target.id
      );
      setSelectedPaymentMethods(paymentMethodCodes);
    } else {
      // payment method is chosen, we add it
      const paymentMethodCodes = [...selectedPaymentMethods];
      paymentMethodCodes.push(e.target.id);
      setSelectedPaymentMethods(paymentMethodCodes);
    }
  };

  const handleChangeAttribute = (newAttributes: AttributeItem[]) => {
    setAttributes([...newAttributes]);
    handleShowSavePanel();
  };

  // FIXME: Remove this when we refactor the second part of the form
  useEffect(() => {
    // Reset the showPanel otherwise the panel won't open again when modifying elements outside of the initial form
    if (showPanel) setShowPanel(false);
  }, [showPanel]);

  const handleShowSavePanel = () => setShowPanel(true);

  const handleSavePanelForm = (values): void => {
    if (attributesFormRef.current) {
      attributesFormRef.current.validateForm().then(data => {
        if (Object.keys(data).length === 0) {
          saveDatas(values);
        } else {
          attributesFormRef.current.showErrors(data);
        }
      });
    } else {
      saveDatas(values);
    }
  };

  const requiredAttributes = getRequiredAttributes(attributeKeys, attributes);

  const formFields: FieldAttributes<AdditionalFieldAttributes>[] = useMemo(
    () => [
      {
        name: "last_name",
        label: "general.name",
        placeholder: "general.name",
        initialValue: user?.lastname,
        required: true,
      },
      {
        name: "first_name",
        label: "general.firstname",
        placeholder: "general.firstname",
        initialValue: user?.firstname,
        required: true,
      },
      {
        name: "email",
        label: "general.email",
        placeholder: "general.email",
        initialValue: user?.email,
        required: true,
        disabled: edition,
      },
      {
        name: "phone",
        label: "general.phone",
        placeholder: "general.phone",
        initialValue: user?.phone,
        hidden: administrator,
        component: formikProps => (
          <FormPhone {...formikProps} setShowPanel={handleShowSavePanel} />
        ),
      },
      {
        name: "role",
        label: "general.role",
        placeholder: "general.role",
        initialValue: user?.role,
        required: true,
        as: "select",
        options: roleItems,
        disabled: isMe,
      },
      {
        name: "price_unit_variation_id",
        label: "page.product.price.unit",
        placeholder: "page.product.price.unit",
        initialValue: (user as User)?.priceUnitVariation?.id,
        required: true,
        as: "select",
        options: priceUnitVariationItems,
        hidden: administrator,
      },
      {
        name: "enterprise_id",
        label: "general.company",
        placeholder: "general.company",
        initialValue: (user as User)?.enterprise?.id,
        as: "select",
        options: [...enterpriseItems],
        hidden: administrator,
      },
      ...paymentMethods.items.map(paymentMethod => ({
        name: paymentMethod.code,
        type: "checkbox",
        label: getTranslation(JSON.stringify(paymentMethod.label)),
        isLabel: true,
        initialValue: paymentMethod.code,
        checked: selectedPaymentMethods.includes(paymentMethod.code),
        onChange: handleCheckPaymentMethod,
        value: paymentMethod.code,
        hidden: administrator,
        children: (
          <span className="ml-2">
            <IntlMessages
              id="page.list.users.payment.method.enable"
              values={{
                payment_method: `${getTranslation(
                  JSON.stringify(paymentMethod.label)
                )}`,
              }}
            />
          </span>
        ),
      })),
      {
        name: "locale",
        label: "general.locale.preference",
        placeholder: "general.locale.preference",
        initialValue: user?.locale,
        required: true,
        as: "select",
        options: localeItemsForm,
        hidden: !administrator,
      },
    ],
    [user, paymentMethods, selectedPaymentMethods, isMe]
  );

  const labels = {
    creation: administrator
      ? "page.list.administrators.create.administrator"
      : "page.list.users.create.user",
    edition: administrator
      ? "page.list.administrators.update.administrator"
      : "page.list.users.update.user",
  };

  return !me || (edition && !user) ? (
    <div className="loading" />
  ) : (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={overrideClasses({ loading })} />

      {/* Hide when loading */}
      <div className={overrideClasses("px-8 pb-32", { hidden: loading })}>
        <Block>
          <Header
            item={user}
            title={edition ? labels.edition : labels.creation}
            entity={EnumPermissionEntity.USER}
            className="border-b border-gray-200"
          />

          <DefaultForm
            fields={formFields}
            onSubmit={handleSavePanelForm}
            showPanel={showPanel}
          />
        </Block>
        {!administrator && (
          <CardAttributes
            {...props}
            item={user}
            title="general.param.attributes"
            attributes={attributes.concat(requiredAttributes)}
            attributeKeys={attributeKeys}
            onChange={handleChangeAttribute}
            onDelete={handleChangeAttribute}
            type={EnumAttributeType.USER}
            ref={attributesFormRef}
            zeroMessage="page.user.attributes.empty"
          />
        )}
      </div>
    </>
  );
};

export default injectIntl(UserForm);
