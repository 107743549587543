import React from "react";
import { match as Match } from "react-router-dom";
import { injectIntl, IntlShape } from "react-intl";
import { ReportingBookingsDate } from "../../../../../../lib/ground-aws-graphql-core/models/Reporting";
import AssetCard from "../asset-card";
import {
  EnumReportingBookingType,
  EnumReportingView,
} from "../../../../../../lib/ground-aws-graphql-core/models/Product";

interface Props {
  intl: IntlShape;
  match: Match<{ cid: string }>;
  view: EnumReportingView;
  data: {
    type: EnumReportingBookingType;
    data: ReportingBookingsDate[];
  }[];
}

const AssetCardList = (props: Props) => {
  const { intl, view, data } = props;

  const types = data.map(d => d.type);
  const results = types.map(type => {
    const dataForType = data.find(d => d.type === type)?.data || [];

    return {
      key: type,
      label: intl.formatMessage({
        id: `page.dashboards.chart.card.${view.toLowerCase()}.${type.toLowerCase()}.title`,
      }),
      total: dataForType[0]?.total,
      variation: dataForType[0]?.variation,
      evolutionRate: dataForType[0]?.evolutionRate,
    };
  });

  return (
    <div className="sm:grid sm:grid-cols-2 sm:gap-3">
      {results.map(r => {
        return (
          <div id={r.key} data-cy={r.key} data-testid={r.key} key={r.key}>
            <AssetCard
              type={r.key}
              label={r.label}
              total={r.total}
              variation={r.variation}
              evolutionRate={r.evolutionRate}
              selected={false}
            />
          </div>
        );
      })}
    </div>
  );
};

export default injectIntl(AssetCardList);
