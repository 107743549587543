import { Action, action } from "easy-peasy";
import { Category } from "../../../lib/ground-aws-graphql-core/models/Category";
import { EnumAction } from "../../../utils/types";

// Interface declaration
export interface CategoryModel {
  modalCategoryAction: EnumAction;
  modalCategorySelected: Category | null;
  modalCreateUpdateCategoryIsOpen: boolean;
  modalDuplicateCategoryIsOpen: boolean;

  setModalCategoryAction: Action<CategoryModel, EnumAction>;
  setModalCategorySelected: Action<CategoryModel, Category | null>;
  setModalCreateUpdateCategoryIsOpen: Action<CategoryModel, boolean>;
  setModalDuplicateCategoryIsOpen: Action<CategoryModel, boolean>;
}

export const categoryModel: CategoryModel = {
  modalCategoryAction: EnumAction.CREATE,

  modalCategorySelected: null,
  modalCreateUpdateCategoryIsOpen: false,
  modalDuplicateCategoryIsOpen: false,

  setModalCategoryAction: action((state, payload) => {
    state.modalCategoryAction = payload;
  }),

  setModalCategorySelected: action((state, payload) => {
    state.modalCategorySelected = payload;
  }),

  setModalCreateUpdateCategoryIsOpen: action((state, payload) => {
    state.modalCreateUpdateCategoryIsOpen = payload;
  }),

  setModalDuplicateCategoryIsOpen: action((state, payload) => {
    state.modalDuplicateCategoryIsOpen = payload;
  }),
};
