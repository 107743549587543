import React from "react";
import { match as Match } from "react-router-dom";
import { injectIntl, IntlShape } from "react-intl";
import { ReportingGlobalDate } from "../../../../lib/ground-aws-graphql-core/models/Reporting";
import GlobalCard from "../global-card";

interface Props {
  intl: IntlShape;
  match: Match<{ cid: string }>;
  data: {
    all?: ReportingGlobalDate;
    disabled?: ReportingGlobalDate;
    enabled?: ReportingGlobalDate;
    deleted?: ReportingGlobalDate;
  };
}

interface GlobalCardInterface {
  key: string;
  label: string;
  total?: number;
  rate?: number;
  variation?: number;
}

const GlobalCardList = (props: Props) => {
  const { intl, data } = props;

  const getGlobalCards = (intl: IntlShape): GlobalCardInterface[] => [
    {
      key: "ALL",
      label: intl.formatMessage({
        id: "page.dashboards.chart.total.all.title",
      }),
      total: data.all?.total,
      rate: data.all?.evolutionRate,
      variation: data.all?.variation,
    },
    {
      key: "ENABLED",
      label: intl.formatMessage({
        id: "page.dashboards.chart.total.enabled.title",
      }),
      total: data.enabled?.total,
      rate: data.enabled?.evolutionRate,
      variation: data.enabled?.variation,
    },
    {
      key: "DISABLED",
      label: intl.formatMessage({
        id: "page.dashboards.chart.total.disabled.title",
      }),
      total: data.disabled?.total,
      rate: data.disabled?.evolutionRate,
      variation: data.disabled?.variation,
    },
    {
      key: "DELETED",
      label: intl.formatMessage({
        id: "page.dashboards.chart.total.deleted.title",
      }),
      total: data.deleted?.total,
      rate: data.deleted?.evolutionRate,
      variation: data.deleted?.variation,
    },
  ];

  return (
    <div className="sm:grid sm:grid-cols-4 sm:gap-4">
      {getGlobalCards(intl).map(e => {
        return (
          <div id={e.key} data-cy={e.key} data-testid={e.key} key={e.key}>
            <GlobalCard
              label={e.label}
              total={e.total}
              rate={e.rate}
              variation={e.variation}
              selected={false}
            />
          </div>
        );
      })}
    </div>
  );
};

export default injectIntl(GlobalCardList);
