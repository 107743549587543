import React, { useState } from "react";
import { useIntl } from "react-intl";
import FilterSelect from "../select";
import images from "../../../../images";
import { EnumPermissionEntity } from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import IntlMessages from "../../../../utils/messages";
import { getFilterFields } from "../../../../utils/filter";

type Props = {
  title: string;
  onClick: (filtersValues: any) => void;
  entity: EnumPermissionEntity;
};

const CalendarFilter = (props: Props): JSX.Element => {
  const { title, onClick, entity } = props;

  const intl = useIntl();
  const [filtersValues, setFiltersValues] = useState([]);

  const { values: fields } = getFilterFields(intl, entity);

  return (
    <div className="border rounded-lg border-primary-200 shadow-ground-1 mb-4">
      <div className="px-8 py-4 bg-neutral-200 rounded-t-lg text-17px leading-5 text-ground-black-100">
        {title && <IntlMessages id={title} />}
      </div>

      <div className="px-8 py-4 flex items-end gap-5">
        {fields.map((field, index) => (
          <div key={`filter ${index}`}>
            <div className="text-xs font-semibold mb-2">
              <IntlMessages id={field.title} />
            </div>

            <FilterSelect
              isMulti
              defaultValue={false}
              fields={[field]}
              placeholder={intl.formatMessage({ id: field.title })}
              onChange={(_type, target) => {
                const values = target?.map(e => e.value);
                setFiltersValues({
                  ...filtersValues,
                  [field.value]: values,
                });
              }}
            />
          </div>
        ))}

        <button
          className="h-8 px-4 rounded-4 text-14px leading-5 flex gap-2 items-center text-ground-blue-100 border border-ground-blue-100"
          type="button"
          onClick={() => onClick(filtersValues)}
        >
          <img src={images.filterSearch} alt="filter" />
          <IntlMessages id="general.filter" />
        </button>
      </div>
    </div>
  );
};

export default CalendarFilter;
