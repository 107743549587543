import { action, Action, Thunk, thunk } from "easy-peasy";
import { Injections } from "../../../store";
import { StoreModel } from "../..";
import { IListResponse } from "../../../services/Api/AppClient";

export interface AppClient {
  id: string;
  name: string;
  client_id: string;
  client_secret: string;
}

export interface ListAppClientsOpts {
  limit?: number;
  offset?: number;
}

export interface CreateAppClientOpts {
  name: string;
}

export interface GetAppClientOpts {
  id: string;
}

export interface DeleteAppClientOpts {
  id: string;
}

export interface UpdateAppClientOpts {
  id: string;
  name: string;
}

export interface AppClientsModel {
  appClients: {
    items: AppClient[];
    total: number;
    has_more: boolean;
  };
  appClient: AppClient | null;
  setAppClients: Action<
    AppClientsModel,
    { items: AppClient[]; total: number; has_more: boolean }
  >;
  setAppClient: Action<AppClientsModel, AppClient | null>;
  removeAppClient: Action<AppClientsModel, DeleteAppClientOpts>;
  listAppClients: Thunk<
    AppClientsModel,
    ListAppClientsOpts,
    Injections,
    StoreModel
  >;
  createAppClient: Thunk<
    AppClientsModel,
    CreateAppClientOpts,
    Injections,
    StoreModel
  >;
  getAppClient: Thunk<
    AppClientsModel,
    GetAppClientOpts,
    Injections,
    StoreModel
  >;
  deleteAppClient: Thunk<
    AppClientsModel,
    DeleteAppClientOpts,
    Injections,
    StoreModel
  >;
  updateAppClient: Thunk<
    AppClientsModel,
    UpdateAppClientOpts,
    Injections,
    StoreModel
  >;
}

export const appClientsModel: AppClientsModel = {
  appClients: { items: [], total: 0, has_more: false },
  appClient: null,
  setAppClients: action((state, payload) => {
    state.appClients = payload;
  }),
  setAppClient: action((state, payload) => {
    state.appClient = payload;
  }),
  removeAppClient: action((state, payload) => {
    state.appClients.items = state.appClients.items.filter(
      p => p.id !== payload.id
    );
    state.appClients.total = state.appClients.total - 1;
  }),
  listAppClients: thunk(
    async (actions, payload: ListAppClientsOpts, { injections }) => {
      const { appClientService } = injections;
      const response = await appClientService.listAppClients(payload);
      if (response.status === 200 && response.data.success) {
        const res: IListResponse<AppClient> = response.data;
        actions.setAppClients(res.data);
      }
    }
  ),
  createAppClient: thunk(
    async (actions, payload: CreateAppClientOpts, { injections }) => {
      const { appClientService } = injections;
      const response = await appClientService.createAppClient(payload);
      if (response.status === 201 && response.data.success) {
        actions.setAppClient(response.data.data);
      }
    }
  ),
  getAppClient: thunk(
    async (actions, payload: GetAppClientOpts, { injections }) => {
      const { appClientService } = injections;
      const response = await appClientService.getAppClient(payload);
      if (response.status === 200 && response.data.success) {
        actions.setAppClient(response.data.data);
      }
    }
  ),
  deleteAppClient: thunk(
    async (actions, payload: DeleteAppClientOpts, { injections }) => {
      const { appClientService } = injections;
      const response = await appClientService.deleteAppClient(payload);
      if (response.status === 200 && response.data.success) {
        actions.setAppClient(null);
        actions.removeAppClient(payload);
      }
    }
  ),

  updateAppClient: thunk(
    async (actions, payload: UpdateAppClientOpts, { injections, getState }) => {
      const { appClientService } = injections;
      const response = await appClientService.updateAppClient(payload);
      if (response.status === 200 && response.data.success) {
        const updatedAppClient = response.data.data;
        actions.setAppClient(updatedAppClient);
        const state = getState();

        const appClientIndex = state.appClients.items.findIndex(
          a => a.id === payload.id
        );

        if (appClientIndex !== -1) {
          const newAppClientsItems = [...state.appClients.items];
          newAppClientsItems.splice(appClientIndex, 1, updatedAppClient);

          actions.setAppClients({
            ...state.appClients,
            items: newAppClientsItems,
          });
        }
      }
    }
  ),
};
