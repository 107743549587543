import React from "react";
import { match as Match } from "react-router-dom";
import ReportingCard from "../reporting-card";
import { injectIntl, IntlShape } from "react-intl";
import images from "../../../../images";
import { EnumPaths } from "../../../../utils/navigation";
import history from "../../../../history";
import { Display } from "../../../../components/OperatorCustomization";
import { CardInterface } from "..";

interface Props {
  intl: IntlShape;
  match: Match<{ cid: string }>;
  canView: boolean;
}

const getReportingCategories = (
  id: string,
  intl: IntlShape,
  canView: boolean
): CardInterface[] => [
  {
    category: "occupancy",
    key: "realtime_occupancy",
    label: intl.formatMessage({
      id: "page.dashboards.card.realtime.occupancy.rate",
    }),
    icon: images.sales,
    url: `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${id}/${EnumPaths.DASHBOARD}/realtime/${EnumPaths.OCCUPANCY}`,
    description: intl.formatMessage({
      id: "page.dashboards.card.realtime.occupancy.rate.description",
    }),
    display: true,
    realtime: true,
  },
  {
    category: "occupancy",
    key: "occupancy",
    label: intl.formatMessage({
      id: "page.dashboards.card.occupancy.rate",
    }),
    icon: images.sales,
    url: `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${id}/${EnumPaths.DASHBOARD}/${EnumPaths.OCCUPANCY}`,
    description: intl.formatMessage({
      id: "page.dashboards.card.occupancy.rate.description",
    }),
    display: canView,
    realtime: false,
  },
  {
    category: "sales",
    key: "realtime_sales",
    label: intl.formatMessage({ id: "page.dashboards.card.realtime.sales" }),
    icon: images.cash,
    url: `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${id}/${EnumPaths.DASHBOARD}/realtime/${EnumPaths.SALES}`,
    description: intl.formatMessage({
      id: "page.dashboards.card.realtime.sales.description",
    }),
    display: true,
    realtime: true,
  },
  {
    category: "sales",
    key: "sales",
    label: intl.formatMessage({ id: "page.dashboards.card.sales" }),
    icon: images.cash,
    url: `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${id}/${EnumPaths.DASHBOARD}/${EnumPaths.SALES}`,
    description: intl.formatMessage({
      id: "page.dashboards.card.sales.description",
    }),
    display: canView,
    realtime: false,
  },
  {
    category: "incidents",
    key: "realtime_incidents",
    label: intl.formatMessage({
      id: "page.dashboards.card.realtime.incidents",
    }),
    icon: images.assistance,
    url: `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${id}/${EnumPaths.DASHBOARD}/realtime/${EnumPaths.INCIDENTS}`,
    description: intl.formatMessage({
      id: "page.dashboards.card.realtime.incidents.description",
    }),
    display: true,
    realtime: true,
  },
  {
    category: "incidents",
    key: "incidents",
    label: intl.formatMessage({ id: "page.dashboards.card.incidents" }),
    icon: images.assistance,
    url: `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${id}/${EnumPaths.DASHBOARD}/${EnumPaths.INCIDENTS}`,
    description: intl.formatMessage({
      id: "page.dashboards.card.incidents.description",
    }),
    display: canView,
    realtime: false,
  },
];

const RealTimeReportings = (props: Props) => {
  const { match, intl, canView } = props;

  const categories = ["occupancy", "sales", "incidents"];
  const allReportings = getReportingCategories(match.params.cid, intl, canView);

  return (
    <div className="flex flex-col">
      {categories.map((category, index) => {
        return (
          <Display paths={[`feature.dashboards.${category}`]} key={index}>
            <div>
              <ul className="m-3 list-disc text-16px text-gray-900 underline leading-5">
                <li className="mb-1">
                  {intl.formatMessage({
                    id: `page.dashboards.card.category.${category}`,
                  })}
                </li>
              </ul>
            </div>
            <div className="sm:grid sm:grid-cols-2 sm:gap-4 my-5">
              {allReportings
                .filter(a => a.category === category)
                .map(e => {
                  return (
                    e.display && (
                      <div
                        id={e.key}
                        data-cy={e.key}
                        data-testid={e.key}
                        key={e.key}
                      >
                        <Display paths={[`feature.dashboards.${e.key}`]}>
                          <ReportingCard
                            label={e.label}
                            icon={e.icon}
                            content={e.description}
                            selected={false}
                            realtime={e.realtime}
                            onClick={() => {
                              if (e.target && e.target === "_blank" && e.url) {
                                window.open(e.url);
                              } else {
                                history.push(e.url);
                              }
                            }}
                          />
                        </Display>
                      </div>
                    )
                  );
                })}
            </div>
          </Display>
        );
      })}
    </div>
  );
};

export default injectIntl(RealTimeReportings);
