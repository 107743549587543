import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { match as Match } from "react-router-dom";
import { IntlShape } from "react-intl";
import Block from "../../../../components/Tailwind/Block";
import HeaderOrder from "../../../../components/Tailwind/Block/HeaderOrder";
import {
  getOrderTotal,
  getRefundReason,
  isRefundRequestedOrRefunded,
} from "../../../../utils/order";
import IntlMessages from "../../../../utils/messages";
import { getFormattedPrice } from "../../../../utils/price-unit";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import { getTranslation } from "../../../../utils/translation";
import { retrieveUserFromOrder } from "../../../../utils/user";
import GridOrderItemsAndRefunds, {
  DataToRefund,
  handleUpdateStatus,
} from "../../../../components/GridOrderItemsAndRefunds";
import {
  displayDayDDMMYYYY_HHMM,
  getDifferenceInMilliseconds,
} from "../../../../utils/config";

interface Props {
  match: Match<{ cid: string; id: string }>;
  intl: IntlShape;
}

const OrderForm = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [dataToRefund, setDataToRefund] = useState<DataToRefund | null>();

  const { intl, match } = props;

  const getOrder = GroundGraphqlContextStore.useStoreActions(
    actions => actions.order.getOrder
  );

  const order = GroundGraphqlContextStore.useStoreState(
    state => state.order.order
  );

  const getGlobalOrder = GroundGraphqlContextStore.useStoreActions(
    actions => actions.globalOrder.getGlobalOrder
  );

  const globalOrder = GroundGraphqlContextStore.useStoreState(
    state => state.globalOrder.globalOrder
  );

  const updateOrderStatus = GroundGraphqlContextStore.useStoreActions(
    actions => actions.blOrder.updateOrderStatus
  );

  const updateOrderItemStatus = GroundGraphqlContextStore.useStoreActions(
    actions => actions.blOrder.updateOrderItemStatus
  );

  const cancelOrder = GroundGraphqlContextStore.useStoreActions(
    actions => actions.cancellation.cancelOrder
  );

  const cancelOrderItem = GroundGraphqlContextStore.useStoreActions(
    actions => actions.cancellation.cancelOrderItem
  );

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const listPaymentMethods = GroundGraphqlContextStore.useStoreActions(
    actions => actions.paymentMethods.listPaymentMethods
  );

  const paymentMethods = GroundGraphqlContextStore.useStoreState(
    s => s.paymentMethods.paymentMethods.items
  );

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (refresh) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (loading) setDataToRefund(null);
  }, [loading]);

  useEffect(() => {
    if (order?.globalOrder) getGlobalOrder({ id: order.globalOrder.id });
  }, [order]);

  const fetchData = async () => {
    setLoading(true);
    await Promise.all([
      getOrder({ id: match.params.id }),
      listPaymentMethods({ excludedFromBackOffice: false }),
    ]).finally(() => setLoading(false));
  };

  const allOrderItems = order?.orderItems?.items || [];

  const itemsRefunded = allOrderItems.filter(e =>
    isRefundRequestedOrRefunded(e.status)
  );

  itemsRefunded.sort((a, b) =>
    getDifferenceInMilliseconds(b.refund?.date, a.refund?.date)
  );

  const allItemsRefunded =
    allOrderItems.length === itemsRefunded.length &&
    allOrderItems.length &&
    allOrderItems.length > 0;

  let paymentDate = "";
  if (order) {
    if (order.payment && order.payment.paymentDate) {
      paymentDate = intl.formatMessage(
        { id: "page.order.paid.at" },
        {
          date: displayDayDDMMYYYY_HHMM(
            order.payment.paymentDate,
            centerTimezone
          ),
        }
      );
    }
  }

  const paymentMethodDetails = order?.payment?.paymentMethodDetails;
  const json = paymentMethodDetails ? JSON.parse(paymentMethodDetails) : {};
  const { card, type } = json;

  const puVariationNames: string[] = [];
  allOrderItems.forEach(oi => {
    const puVariation = oi.variation ? JSON.parse(oi.variation) : {};
    const { key } = puVariation;
    if (key && !puVariationNames.includes(key)) {
      puVariationNames.push(key);
    }
  });

  const { client, email } = retrieveUserFromOrder(
    order?.user,
    order?.metadata,
    order?.channel
  );

  return loading || !order || !globalOrder ? (
    <div className="loading" />
  ) : (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "page.order.document.title" })}</title>
      </Helmet>
      <Block className="border border-neutral-100 shadow-ground-1">
        <HeaderOrder
          order={order}
          onChangeOrderStatus={(orderToUpdate, orderItem, status) =>
            handleUpdateStatus(
              orderToUpdate,
              orderItem,
              status,
              globalOrder,
              setLoading,
              setRefresh,
              cancelOrderItem,
              cancelOrder,
              updateOrderStatus,
              updateOrderItemStatus,
              intl
            )
          }
          onRefund={(orderToRefund, orderItem, reason) =>
            setDataToRefund({ orderToRefund, orderItem, reason })
          }
          onPaid={(order, orderItem, status, paymentMethod) =>
            handleUpdateStatus(
              order,
              orderItem,
              status,
              globalOrder,
              setLoading,
              setRefresh,
              cancelOrderItem,
              cancelOrder,
              updateOrderStatus,
              updateOrderItemStatus,
              intl,
              paymentMethod
            )
          }
          paymentMethods={paymentMethods}
          match={match}
        />
        <div className="py-3 sm:py-5 border-b border-gray-200 px-2 sm:px-6 flex flex-col bg-gray-100 rounded-b-10">
          <div className="flex flex-col sm:flex-row justify-between sm:items-start">
            <div className="flex flex-col text-14px leading-relaxed">
              <div className="flex flex-col">
                <p className="text-neutral-900 font-semibold">
                  <IntlMessages id="general.client" />
                </p>
                <p className=" text-ground-gray-100">{client}</p>
                <p className="text-ground-blue-100">{email}</p>
                <p className="text-ground-gray-100">
                  {intl.formatMessage({
                    id: `page.order.channel.${order?.channel}`,
                  })}
                </p>
                {order?.touchPoint && (
                  <p className="text-ground-gray-100">
                    {intl.formatMessage(
                      { id: "page.order.touch.point" },
                      { touchPoint: order.touchPoint }
                    )}
                  </p>
                )}
              </div>
              <div className="flex flex-col mt-4">
                <p className="text-neutral-900 font-semibold">
                  <IntlMessages id="general.provider" />
                </p>
                <p className=" text-ground-gray-100">
                  {getTranslation(order.provider.name)}
                </p>
              </div>
              <div className="flex flex-col mt-4">
                <p className="text-neutral-900 font-semibold">
                  <IntlMessages id="general.comment" />
                </p>
                <p className=" text-ground-gray-100">{order.comment}</p>
              </div>
            </div>
            {order && getOrderTotal(order) > 0 && (
              <>
                {paymentMethodDetails && (
                  <div className="flex flex-col text-14px leading-relaxed text-ground-gray-100">
                    <p className="text-neutral-900 font-semibold">
                      <IntlMessages id="page.order.payment" />
                    </p>
                    <p>
                      <IntlMessages id={`page.order.payment.method.${type}`} />
                    </p>
                    <p>{card && card.brand.toUpperCase()}</p>
                    <p>{card && `************${card.last4}`}</p>
                    <p>{paymentDate}</p>
                  </div>
                )}
                {isRefundRequestedOrRefunded(order.status) &&
                  allItemsRefunded &&
                  itemsRefunded.length &&
                  itemsRefunded[0].refund && (
                    <div className="flex flex-col text-14px leading-relaxed text-ground-gray-100">
                      <p className="text-neutral-900 font-semibold">
                        <IntlMessages id="page.order.refund" />
                      </p>
                      <p>
                        <IntlMessages id="page.order.refunded.at" />{" "}
                        {itemsRefunded[0] &&
                          itemsRefunded[0].refund &&
                          itemsRefunded[0].refund?.date && (
                            <span>
                              {displayDayDDMMYYYY_HHMM(
                                itemsRefunded[0].refund?.date,
                                centerTimezone
                              )}
                            </span>
                          )}
                      </p>
                      <p>
                        <IntlMessages id="general.reason" />:{" "}
                        {
                          getRefundReason(intl, itemsRefunded[0].refund.reason)
                            ?.label
                        }
                      </p>
                    </div>
                  )}

                <div className="flex flex-col text-14px leading-relaxed text-ground-gray-100">
                  <p className="text-neutral-900 font-semibold">
                    <IntlMessages id="page.order.summary" />
                  </p>
                  <p>
                    <IntlMessages id="general.total.ht" /> :{" "}
                    {getFormattedPrice(order.price, order.currency)}
                  </p>
                  <p>
                    <IntlMessages id="general.vat" /> :{" "}
                    {getFormattedPrice(order.totalTax, order.currency)}
                  </p>
                  <p>
                    <IntlMessages id="general.total.ttc" /> :{" "}
                    {getFormattedPrice(
                      order.price + order.totalTax - order.totalAdjustment,
                      order.currency
                    )}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </Block>

      <GridOrderItemsAndRefunds
        setLoading={setLoading}
        setRefresh={setRefresh}
        globalOrder={globalOrder}
        orders={[order]}
        orderDataToRefund={dataToRefund}
      />
    </>
  );
};

export default OrderForm;
