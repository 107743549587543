import React from "react";
import { Location } from "history";
import { match as Match } from "react-router-dom";
import { injectIntl, IntlShape } from "react-intl";
import PageSubheader from "../../../../components/PageSubheader";
import { EnumBookingFrom, EnumPaths } from "../../../../utils/navigation";
import GlobalOrderForm from "../form";

interface Props {
  match: Match<{ cid: string; id: string }>;
  location: Location<{
    from: EnumBookingFrom;
  }>;
  intl: IntlShape;
  backURL: string;
}

const GlobalOrderUpdate = (props: Props): JSX.Element => {
  const {
    location: { state },
  } = props;
  let { backURL } = props;
  const from = state?.from;
  if (from === EnumBookingFrom.BOOKING_LIST) {
    backURL = backURL.replace(
      `${EnumPaths.GLOBAL_ORDERS}`,
      `${EnumPaths.BOOKINGS}`
    );
  } else if (typeof from === "string") {
    backURL = from;
  }

  return (
    <>
      <PageSubheader
        title="page.order.title"
        goBackEnabled
        goBackId="btn-back-update-order"
        goBackURL={backURL}
      />

      <div className="p-2">
        <GlobalOrderForm {...props} />
      </div>
    </>
  );
};

export default injectIntl(GlobalOrderUpdate);
