import { Thunk, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel } from "..";
import { Image } from "../../../utils/types";
import { AttributeItem } from "../../../utils/attribute";
import { AttributeKey } from "../../../lib/ground-aws-graphql-core/models/AttributeKey";
import { Provider } from "../../../lib/ground-aws-graphql-core/models/Provider";

export interface ProviderOpts {
  provider: Provider | null;
  values: any;
  pictures?: Image[];
  attributeKeys: AttributeKey[] | null;
  attributes: AttributeItem[];
  enabled: boolean;
  me?: any;
  callbacks: {
    createProvider: any;
    updateProvider: any;
  };
}

// Interface declaration
export interface ProviderModel {
  createProvider: Thunk<ProviderModel, ProviderOpts, Injections, StoreModel>;
  updateProvider: Thunk<ProviderModel, ProviderOpts, Injections, StoreModel>;
}

export const providerModel: ProviderModel = {
  createProvider: thunk(async (_actions, payload, { injections }) => {
    const { providerService } = injections;
    const response = await providerService.createProvider(payload);

    return response;
  }),
  updateProvider: thunk(async (_actions, payload, { injections }) => {
    const { providerService } = injections;
    const response = await providerService.updateProvider(payload);

    return response;
  }),
};
