import { EnumLocale } from "../lib/ground-aws-graphql-core/api/graphql/types";
import frFRMessages from "./locales/fr-FR.json";
import enGBMessages from "./locales/en-GB.json";

// date-fns locale for react-datepicker (only)
import { fr, enGB } from "date-fns/locale";

// iso countries in 2 languages (fr, en)
import * as i18nIsoCountries from "i18n-iso-countries";
import countriesFr from "i18n-iso-countries/langs/fr.json";
import countriesEn from "i18n-iso-countries/langs/en.json";
i18nIsoCountries.registerLocale(countriesFr);
i18nIsoCountries.registerLocale(countriesEn);

export interface ILocale {
  messages: any;
  locale: string;
  backend_locale: EnumLocale;
  language: string; // ISO 639-1 format string
  isDefault: boolean;
  date: Locale;
  date_code: string;
  country: string; // Alpha2 country code
  supportedCountry: boolean;
  supportedBackOffice: boolean;
}

export const frFRLang: ILocale = {
  messages: frFRMessages,
  locale: "fr-FR",
  backend_locale: EnumLocale.fr_FR,
  language: "fr",
  isDefault: true,
  date: fr,
  date_code: "fr",
  country: "FR",
  supportedCountry: true,
  supportedBackOffice: true,
};

const enGBLang: ILocale = {
  messages: enGBMessages,
  locale: "en-GB",
  backend_locale: EnumLocale.en_GB,
  language: "en",
  isDefault: false,
  date: enGB,
  date_code: "en-GB",
  country: "GB",
  supportedCountry: true,
  supportedBackOffice: true,
};

const esESLang: ILocale = {
  messages: enGBMessages,
  locale: "es-ES",
  backend_locale: EnumLocale.es_ES,
  language: "es",
  isDefault: false,
  date: enGB,
  date_code: "en-GB",
  country: "ES",
  supportedCountry: true,
  supportedBackOffice: false,
};

const deDELang: ILocale = {
  messages: enGBMessages,
  locale: "de-DE",
  backend_locale: EnumLocale.de_DE,
  language: "de",
  isDefault: false,
  date: enGB,
  date_code: "en-GB",
  country: "DE",
  supportedCountry: true,
  supportedBackOffice: false,
};

const ptPTLang: ILocale = {
  messages: enGBMessages,
  locale: "pt-PT",
  backend_locale: EnumLocale.pt_PT,
  language: "pt",
  isDefault: false,
  date: enGB,
  date_code: "en-GB",
  country: "PT",
  supportedCountry: false,
  supportedBackOffice: false,
};

const enKELang: ILocale = {
  messages: enGBMessages,
  locale: "en-GB",
  backend_locale: EnumLocale.en_GB,
  language: "en",
  isDefault: false,
  date: enGB,
  date_code: "en-GB",
  country: "KE",
  supportedCountry: true,
  supportedBackOffice: false,
};

const frCILang: ILocale = {
  messages: frFRMessages,
  locale: "fr-FR",
  backend_locale: EnumLocale.fr_FR,
  language: "fr",
  isDefault: false,
  date: fr,
  date_code: "fr",
  country: "CI",
  supportedCountry: true,
  supportedBackOffice: false,
};

const frSNLang: ILocale = {
  messages: frFRMessages,
  locale: "fr-FR",
  backend_locale: EnumLocale.fr_FR,
  language: "fr",
  isDefault: false,
  date: fr,
  date_code: "fr",
  country: "SN",
  supportedCountry: true,
  supportedBackOffice: false,
};

const enNGLang: ILocale = {
  messages: enGBMessages,
  locale: "en-GB",
  backend_locale: EnumLocale.en_GB,
  language: "en",
  isDefault: false,
  date: enGB,
  date_code: "en-GB",
  country: "NG",
  supportedCountry: true,
  supportedBackOffice: false,
};

const frRWLang: ILocale = {
  messages: frFRMessages,
  locale: "fr-FR",
  backend_locale: EnumLocale.fr_FR,
  language: "fr",
  isDefault: false,
  date: fr,
  date_code: "fr",
  country: "RW",
  supportedCountry: true,
  supportedBackOffice: false,
};

const enZALang: ILocale = {
  messages: enGBMessages,
  locale: "en-GB",
  backend_locale: EnumLocale.en_GB,
  language: "en",
  isDefault: false,
  date: enGB,
  date_code: "en-GB",
  country: "ZA",
  supportedCountry: true,
  supportedBackOffice: false,
};

const enBRLang: ILocale = {
  messages: enGBMessages,
  locale: "en-GB",
  backend_locale: EnumLocale.en_GB,
  language: "en",
  isDefault: false,
  date: enGB,
  date_code: "en-GB",
  country: "BR",
  supportedCountry: true,
  supportedBackOffice: false,
};

const enARLang: ILocale = {
  messages: enGBMessages,
  locale: "en-GB",
  backend_locale: EnumLocale.en_GB,
  language: "en",
  isDefault: false,
  date: enGB,
  date_code: "en-GB",
  country: "AR",
  supportedCountry: true,
  supportedBackOffice: false,
};

const enCLLang: ILocale = {
  messages: enGBMessages,
  locale: "en-GB",
  backend_locale: EnumLocale.en_GB,
  language: "en",
  isDefault: false,
  date: enGB,
  date_code: "en-GB",
  country: "CL",
  supportedCountry: true,
  supportedBackOffice: false,
};

const enPELang: ILocale = {
  messages: enGBMessages,
  locale: "en-GB",
  backend_locale: EnumLocale.en_GB,
  language: "en",
  isDefault: false,
  date: enGB,
  date_code: "en-GB",
  country: "PE",
  supportedCountry: true,
  supportedBackOffice: false,
};

const enCOLang: ILocale = {
  messages: enGBMessages,
  locale: "en-GB",
  backend_locale: EnumLocale.en_GB,
  language: "en",
  isDefault: false,
  date: enGB,
  date_code: "en-GB",
  country: "CO",
  supportedCountry: true,
  supportedBackOffice: false,
};

export interface IAppLocale {
  [key: string]: ILocale;
}

const useNavigatorLocale = false;

export const getLocale = (locale: string | null = null): ILocale => {
  const defaultLocale =
    Object.keys(AppLocale).find(x => AppLocale[x].isDefault) || "fr-FR"; // default language is fr-FR
  let currentAppLocale = AppLocale[defaultLocale];

  if (useNavigatorLocale) {
    const navigatorLocale = getNavigatorLocale();

    if (navigatorLocale) {
      return navigatorLocale;
    }

    return currentAppLocale;
  }

  if (locale) {
    for (const [key, value] of Object.entries(AppLocale)) {
      if (value.locale === locale) {
        currentAppLocale = AppLocale[key];
      }
    }

    return currentAppLocale;
  }

  return currentAppLocale;
};

export const getSupportedLocalesForBO = (): string[] => {
  const locales: string[] = [];
  for (const [, value] of Object.entries(AppLocale)) {
    if (value.supportedBackOffice) {
      locales.push(value.locale);
    }
  }
  const uniqueLocales = [...new Set(locales)];

  return uniqueLocales;
};

export const getSupportedLocales = (): string[] => {
  const locales: string[] = [];
  for (const [, value] of Object.entries(AppLocale)) {
    locales.push(value.locale);
  }
  const uniqueLocales = [...new Set(locales)];

  return uniqueLocales;
};

export const getSupportedLanguages = (): string[] => {
  const languages: string[] = [];
  for (const [, value] of Object.entries(AppLocale)) {
    if (value.supportedBackOffice) {
      languages.push(value.language);
    }
  }
  const uniqueLocales = [...new Set(languages)];

  return uniqueLocales;
};

export const getEmptyTranslations = (): {
  [key: string]: string;
} => {
  const locales = getSupportedLocales();
  const translation = {};
  locales.forEach(locale => {
    translation[`${locale}`] = "";
  });

  return translation;
};

export const getNavigatorLanguages = (): readonly string[] => {
  const languages = navigator.languages || [navigator.language];

  return languages;
};

export interface ICountry {
  id: string;
  name: string;
}

export const getCountries = (locale: string): ICountry[] => {
  const currentAppLocale = getLocale(locale);
  const countries: { id: string; name: string }[] = [];
  Object.values(AppLocale).forEach(i => {
    if (i.supportedCountry) {
      const name = i18nIsoCountries.getName(
        i.country,
        currentAppLocale.language,
        { select: "official" }
      );
      countries.push({
        id: i.country,
        name,
      });
    }
  });

  return countries;
};

export const getCountryCodeByName = (country: string): string => {
  let result;
  for (const locale of Object.values(AppLocale)) {
    if (locale.supportedCountry) {
      const countries = i18nIsoCountries.getNames(locale.language, {
        select: "official",
      });
      let code;
      for (const [key, value] of Object.entries(countries)) {
        if (value === country) {
          code = key;
          break;
        }
      }
      if (code) {
        result = code;
        break;
      }
    }
  }

  return result;
};

export const getNavigatorLocale = (): ILocale | null => {
  const supportedLocales = getSupportedLocales();
  const supportedLanguages = getSupportedLanguages();
  const navigatorLanguages = getNavigatorLanguages();
  let currentAppLocale;

  for (const navigatorLanguage of navigatorLanguages) {
    // using locales
    if (supportedLocales.includes(navigatorLanguage)) {
      currentAppLocale = AppLocale[navigatorLanguage];
      break;
    }
    // using languages
    if (supportedLanguages.includes(navigatorLanguage)) {
      const key = Object.keys(AppLocale).find(
        x => AppLocale[x].language === navigatorLanguage
      );
      if (key) {
        currentAppLocale = AppLocale[key];
      }
      break;
    }
  }

  if (currentAppLocale && currentAppLocale.supportedBackOffice) {
    return currentAppLocale;
  }

  return null;
};

const AppLocale: IAppLocale = {
  "fr-FR": frFRLang,
  "en-GB": enGBLang,
  "es-ES": esESLang,
  "de-DE": deDELang,
  "pt-PT": ptPTLang,
  "en-KE": enKELang,
  "fr-CI": frCILang,
  "fr-SN": frSNLang,
  "en-NG": enNGLang,
  "fr-RW": frRWLang,
  "en-ZA": enZALang,
  "en-BR": enBRLang,
  "en-AR": enARLang,
  "en-CL": enCLLang,
  "en-CO": enCOLang,
  "en-PE": enPELang,
};

export default AppLocale;
