import { Thunk, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel } from "../";
import { Center } from "../../../lib/ground-aws-graphql-core/models/Center";
import { CreateAuthorizationInput } from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Authorization } from "../../../lib/ground-aws-graphql-core/models/Authorization";

export interface CreateAdminUserOpts {
  center: Center;
  values: any;
  callbacks: {
    createUser: any;
    createAuthorization: (input: CreateAuthorizationInput) => Authorization[];
  };
}

export interface AccountModel {
  createAdminUser: Thunk<
    AccountModel,
    CreateAdminUserOpts,
    Injections,
    StoreModel
  >;
}

export const accountModel: AccountModel = {
  createAdminUser: thunk(async (_actions, payload, { injections }) => {
    const { accountService } = injections;
    const response = await accountService.createUser(payload);

    return response;
  }),
};
