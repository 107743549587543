import React from "react";
import Transition from "../../../../components/Transition";
import history from "../../../../history";
import { asyncLocalStorage } from "../../../../utils/token";
import IntlMessages from "../../../../utils/messages";
import { UserCognito } from "../../../../lib/ground-aws-cognito-auth-core/models/Authentication";
import { GroundAuthContextStore } from "../../../../lib/ground-aws-cognito-auth-core";
import images from "../../../../images";

interface Props {
  open: boolean;
  user: UserCognito;
}

const Dropdown = (props: Props): JSX.Element => {
  const logOut = GroundAuthContextStore.useStoreActions(
    actions => actions.authentication.logOut
  );

  const signOut = () => {
    asyncLocalStorage.clear().then(() => {
      logOut();
      history.push("/");
    });
  };

  const { open, user } = props;

  return (
    <div className="relative inline-block text-left z-50">
      <Transition
        show={open}
        enter="transition ease-out duration-150"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="origin-top-right absolute right-0 mt-2 w-80 rounded-md shadow-lg">
          <div className="rounded-md bg-white shadow-xs py-2">
            <div className="hover:bg-gray-200 focus:bg-gray-200 px-4 py-2">
              <div className="flex items-center">
                <div className="flex flex-col">
                  <p className="text-12px text-ground-black-300 font-bold">
                    {user.first_name} {user.last_name}
                  </p>
                  <p
                    id="sub-menu-role"
                    data-cy="sub-menu-role"
                    className="text-11px leading-5 font-medium"
                  >
                    <IntlMessages id="general.role" /> - {user.role}
                  </p>
                  <p
                    id="sub-menu-email"
                    data-cy="sub-menu-email"
                    className="text-11px leading-5 font-medium text-ground-green-100"
                  >
                    {user.email}
                  </p>
                </div>
              </div>
            </div>
            <div className="border-t border-gray-100" />
            <div
              id="sub-menu-signout"
              data-cy="sub-menu-signout"
              className="flex items-center w-full text-left px-4 py-2 text-14px leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-200 focus:text-gray-900"
              onClick={signOut}
            >
              <img src={images.logout} alt="logout" className="w-6 h-6" />
              <span className="ml-2">
                <IntlMessages id="general.logout" />
              </span>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default Dropdown;
