import { action, Action, Thunk, thunk } from "easy-peasy";
import { Injections } from "../../../store";
import { StoreModel } from "../..";
import {
  SearchableBooleanFilterInput,
  SearchableDateRangeFilterInput,
  SearchableStringFilterInput,
} from "../../../api/graphql/types";
import { IListMediaResponse, IListResponse, IMultiLanguage } from "..";

export interface IncidentStatus {
  id: number;
  code: number;
  label: IMultiLanguage;
  created_at: string;
  updated_at: string;
}

export interface IncidentType {
  id: number;
  code: string;
  label: IMultiLanguage;
  created_at: string;
  updated_at: string;
}

export interface IncidentReason {
  id: number;
  code: string;
  label: IMultiLanguage;
  created_at: string;
  updated_at: string;
}

export interface IUser {
  id: string;
  last_name: string;
  first_name: string;
  email: string;
}

export interface Incident {
  id: number;
  ref: string;
  external_ref?: string | null;
  type: IncidentType;
  reason: IncidentReason;
  building: {
    id: string;
  };
  description?: IMultiLanguage;
  location?: IMultiLanguage;
  media: string;
  priority: number;
  status: IncidentStatus;
  rating: number;
  rating_authorized: boolean;
  resolution_date: string;
  user: IUser;
  pinned: boolean;
  viewer_id?: string;
  view_date?: string;
  created_at: string;
  updated_at: string;
  comments: string;
}

export interface IncidentComment {
  id: number;
  comment: IMultiLanguage;
  issuer?: string;
  created_at: string;
  updated_at: string;
}

export interface GetIncidentsOpts {
  id: string;
}

export interface UpdateIncidentsOpts {
  id: number;
  status_code?: number;
  external_ref?: string;
  rating_authorized?: boolean;
  high_priority?: boolean;
  resolution_date?: Date;
}

export interface ViewIncidentsOpts {
  id: number;
}

export interface CommentIncidentsOpts {
  id: number;
  comment: IMultiLanguage;
  issuer?: string;
}

export interface PinIncidentOpts {
  id: number;
}

export type SearchableIncidentsFilterInput = {
  status?: SearchableStringFilterInput;
  type?: SearchableStringFilterInput;
  reason?: SearchableStringFilterInput;
  lastname?: SearchableStringFilterInput;
  pinned?: SearchableBooleanFilterInput;
  viewed?: SearchableBooleanFilterInput;
  created_at?: SearchableDateRangeFilterInput;
};

export interface ListIncidentsOpts {
  buildingId: string;
  limit: number;
  offset: number;
  filter?: SearchableIncidentsFilterInput;
}

export interface ListNotViewedIncidentsOpts {
  buildingId: string;
  limit: number;
  offset: number;
  filter: {
    viewed: SearchableBooleanFilterInput;
  };
}

export interface ListIncidentCommentsOpts {
  incidentId: string;
  limit?: number;
  offset?: number;
}

export interface ListMediaOpts {
  id: string;
}

export interface IncidentModel {
  incidents: {
    items: Incident[];
    total: number;
    has_more: boolean;
  };
  exportedIncidents: {
    items: Incident[];
    total: number;
    has_more: boolean;
  };
  notViewedincidents: {
    items: Incident[];
    total: number;
    has_more: boolean;
  };
  incidentsStatus: {
    items: IncidentStatus[];
    total: number;
    has_more: boolean;
  };
  incidentTypes: {
    items: IncidentType[];
    total: number;
    has_more: boolean;
  };
  incidentReasons: {
    items: IncidentReason[];
    total: number;
    has_more: boolean;
  };
  incidentComments: {
    items: IncidentComment[];
    total: number;
    has_more: boolean;
  };
  incident: Incident | null;
  pictures: string[];

  setIncident: Action<IncidentModel, Incident | null>;
  setIncidents: Action<
    IncidentModel,
    {
      items: Incident[];
      total: number;
      has_more: boolean;
    }
  >;
  setExportedIncidents: Action<
    IncidentModel,
    {
      items: Incident[];
      total: number;
      has_more: boolean;
    }
  >;
  setNotViewedIncidents: Action<
    IncidentModel,
    {
      items: Incident[];
      total: number;
      has_more: boolean;
    }
  >;
  setIncidentsStatus: Action<
    IncidentModel,
    { items: IncidentStatus[]; total: number; has_more: boolean }
  >;
  setIncidentTypes: Action<
    IncidentModel,
    { items: IncidentType[]; total: number; has_more: boolean }
  >;
  setIncidentReasons: Action<
    IncidentModel,
    { items: IncidentReason[]; total: number; has_more: boolean }
  >;
  setIncidentComments: Action<
    IncidentModel,
    { items: IncidentComment[]; total: number; has_more: boolean }
  >;
  setPictures: Action<IncidentModel, string[]>;
  getIncident: Thunk<IncidentModel, GetIncidentsOpts, Injections, StoreModel>;
  updateIncident: Thunk<
    IncidentModel,
    UpdateIncidentsOpts,
    Injections,
    StoreModel
  >;
  viewIncident: Thunk<IncidentModel, ViewIncidentsOpts, Injections, StoreModel>;
  commentIncident: Thunk<
    IncidentModel,
    CommentIncidentsOpts,
    Injections,
    StoreModel
  >;
  pinIncident: Thunk<IncidentModel, PinIncidentOpts, Injections, StoreModel>;
  unpinIncident: Thunk<IncidentModel, PinIncidentOpts, Injections, StoreModel>;
  listIncidents: Thunk<
    IncidentModel,
    ListIncidentsOpts,
    Injections,
    StoreModel
  >;
  exportIncidents: Thunk<
    IncidentModel,
    ListIncidentsOpts,
    Injections,
    StoreModel
  >;
  listNotViewedIncidents: Thunk<
    IncidentModel,
    ListNotViewedIncidentsOpts,
    Injections,
    StoreModel
  >;
  listIncidentStatus: Thunk<IncidentModel, null, Injections, StoreModel>;
  listIncidentTypes: Thunk<IncidentModel, null, Injections, StoreModel>;
  listIncidentReasons: Thunk<
    IncidentModel,
    { id: number; offset: number; limit: number },
    Injections,
    StoreModel
  >;
  listIncidentComments: Thunk<
    IncidentModel,
    ListIncidentCommentsOpts,
    Injections,
    StoreModel
  >;
  listMedia: Thunk<IncidentModel, ListMediaOpts, Injections, StoreModel>;
}

export const incidentModel: IncidentModel = {
  incidents: { items: [], total: 0, has_more: false },
  exportedIncidents: { items: [], total: 0, has_more: false },
  notViewedincidents: { items: [], total: 0, has_more: false },
  incidentsStatus: { items: [], total: 0, has_more: false },
  incidentTypes: { items: [], total: 0, has_more: false },
  incidentReasons: { items: [], total: 0, has_more: false },
  incidentComments: { items: [], total: 0, has_more: false },
  incident: null,
  pictures: [],
  setIncident: action((state, payload: Incident) => {
    state.incident = payload;
  }),
  setIncidents: action((state, payload) => {
    state.incidents = payload;
  }),
  setExportedIncidents: action((state, payload) => {
    state.exportedIncidents = payload;
  }),
  setNotViewedIncidents: action((state, payload) => {
    state.notViewedincidents = payload;
  }),
  setIncidentsStatus: action((state, payload) => {
    state.incidentsStatus = payload;
  }),
  setIncidentTypes: action((state, payload) => {
    state.incidentTypes = payload;
  }),
  setIncidentReasons: action((state, payload) => {
    state.incidentReasons = payload;
  }),
  setIncidentComments: action((state, payload) => {
    state.incidentComments = payload;
  }),
  setPictures: action((state, payload) => {
    state.pictures = payload;
  }),
  getIncident: thunk(
    async (actions, payload: GetIncidentsOpts, { injections }) => {
      const { incidentService } = injections;
      const response = await incidentService.getIncident(payload.id);
      if (response.status === 200 && response.data.success) {
        actions.setIncident(response.data.data);
      }
    }
  ),
  updateIncident: thunk(
    async (actions, payload: UpdateIncidentsOpts, { injections, getState }) => {
      const { incidentService } = injections;

      const response = await incidentService.updateIncident(payload);

      if (response.status === 200 && response.data.success) {
        const updatedIncident = response.data.data;
        actions.setIncident(updatedIncident);

        const state = getState();
        const incidentIndex = state.incidents.items.findIndex(
          incident => incident.id === updatedIncident.id
        );

        if (incidentIndex !== -1) {
          const newIncidentsItems = [...state.incidents.items];
          newIncidentsItems.splice(incidentIndex, 1, updatedIncident);

          actions.setIncidents({
            ...state.incidents,
            items: newIncidentsItems,
          });
        }

        actions.listIncidentComments({ incidentId: payload.id.toString() });
      }

      return response.data;
    }
  ),
  viewIncident: thunk(
    async (actions, payload: ViewIncidentsOpts, { injections, getState }) => {
      const { incidentService } = injections;

      const response = await incidentService.viewIncident(payload);

      if (response.status === 200 && response.data.success) {
        const updatedIncident = response.data.data;
        actions.setIncident(updatedIncident);

        const state = getState();
        const incidentIndex = state.incidents.items.findIndex(
          incident => incident.id === updatedIncident.id
        );

        if (incidentIndex !== -1) {
          const newIncidentsItems = [...state.incidents.items];
          newIncidentsItems.splice(incidentIndex, 1, updatedIncident);

          actions.setIncidents({
            ...state.incidents,
            items: newIncidentsItems,
          });
        }
      }
    }
  ),
  commentIncident: thunk(
    async (actions, payload: CommentIncidentsOpts, { injections }) => {
      const { incidentService } = injections;

      const response = await incidentService.commentIncident(payload);
      actions.listIncidentComments({ incidentId: payload.id.toString() });

      return response.data;
    }
  ),
  pinIncident: thunk(
    async (actions, payload: PinIncidentOpts, { injections, getState }) => {
      const { incidentService } = injections;

      const response = await incidentService.pinIncident(payload);

      if (response.data.success) {
        const updatedIncident = response.data.data;
        actions.setIncident(updatedIncident);

        const state = getState();
        const incidentIndex = state.incidents.items.findIndex(
          incident => incident.id === updatedIncident.id
        );

        if (incidentIndex !== -1) {
          const newIncidentsItems = [...state.incidents.items];
          newIncidentsItems.splice(incidentIndex, 1, updatedIncident);

          actions.setIncidents({
            ...state.incidents,
            items: newIncidentsItems,
          });
        }
      }
    }
  ),
  unpinIncident: thunk(
    async (actions, payload: PinIncidentOpts, { injections, getState }) => {
      const { incidentService } = injections;

      const response = await incidentService.unpinIncident(payload);

      if (response.data.success) {
        const updatedIncident = response.data.data;
        actions.setIncident(updatedIncident);

        const state = getState();
        const incidentIndex = state.incidents.items.findIndex(
          incident => incident.id === updatedIncident.id
        );

        if (incidentIndex !== -1) {
          const newIncidentsItems = [...state.incidents.items];
          newIncidentsItems.splice(incidentIndex, 1, updatedIncident);

          actions.setIncidents({
            ...state.incidents,
            items: newIncidentsItems,
          });
        }
      }
    }
  ),
  listIncidents: thunk(
    async (actions, payload: ListIncidentsOpts, { injections }) => {
      const { incidentService } = injections;
      const response = await incidentService.listIncidents(
        payload.buildingId,
        payload.offset,
        payload.limit,
        payload.filter
      );
      if (response.status === 200 && response.data.success) {
        const incidents: IListResponse<Incident> = response.data;
        actions.setIncidents(incidents.data);
      }
    }
  ),
  exportIncidents: thunk(
    async (actions, payload: ListIncidentsOpts, { injections }) => {
      const { incidentService } = injections;
      const response = await incidentService.exportIncidents(
        payload.buildingId,
        payload.offset,
        payload.limit,
        payload.filter
      );
      if (response.status === 200 && response.data.success) {
        const incidents: IListResponse<Incident> = response.data;
        actions.setExportedIncidents(incidents.data);

        return incidents.data;
      }
    }
  ),
  listNotViewedIncidents: thunk(
    async (actions, payload: ListNotViewedIncidentsOpts, { injections }) => {
      const { incidentService } = injections;
      const response = await incidentService.listIncidents(
        payload.buildingId,
        payload.offset,
        payload.limit,
        payload.filter
      );
      if (response.status === 200 && response.data.success) {
        const incidents: IListResponse<Incident> = response.data;
        actions.setNotViewedIncidents(incidents.data);
      }
    }
  ),
  listIncidentStatus: thunk(async (actions, _payload, { injections }) => {
    const { incidentService } = injections;
    const response = await incidentService.listIncidentStatus();
    if (response.status === 200 && response.data.success) {
      const incidentsStatus: IListResponse<IncidentStatus> = response.data;
      actions.setIncidentsStatus(incidentsStatus.data);

      return incidentsStatus.data;
    }
  }),
  listIncidentTypes: thunk(async (actions, _payload, { injections }) => {
    const { incidentService } = injections;
    const response = await incidentService.listIncidentTypes();
    if (response.status === 200 && response.data.success) {
      const incidentTypes: IListResponse<IncidentType> = response.data;
      actions.setIncidentTypes(incidentTypes.data);

      return incidentTypes.data;
    }
  }),
  listIncidentReasons: thunk(async (actions, _payload, { injections }) => {
    const { incidentService } = injections;
    const response = await incidentService.listIncidentReasons(
      _payload.id,
      _payload.offset,
      _payload.limit
    );
    if (response.status === 200 && response.data.success) {
      const incidentReasons: IListResponse<IncidentReason> = response.data;
      actions.setIncidentReasons(incidentReasons.data);

      return incidentReasons.data;
    }
  }),
  listIncidentComments: thunk(
    async (actions, payload: ListIncidentCommentsOpts, { injections }) => {
      const { incidentService } = injections;
      const response = await incidentService.listIncidentComments(
        payload.incidentId,
        payload.offset,
        payload.limit
      );
      if (response.status === 200 && response.data.success) {
        const incidentComments: IListResponse<IncidentComment> = response.data;
        actions.setIncidentComments(incidentComments.data);
      }
    }
  ),
  listMedia: thunk(async (actions, payload: ListMediaOpts, { injections }) => {
    const { incidentService } = injections;
    const response = await incidentService.listMedia(payload.id);
    if (response.status === 200 && response.data.success) {
      const resp: IListMediaResponse = response.data;
      actions.setPictures(resp.data.pictures);
    }
  }),
};
