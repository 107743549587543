import React from "react";
import { FieldProps } from "formik";
import PhoneInput from "react-phone-input-2";
import { getCountries, getLocale } from "../../../lang";
import contextStore from "../../../redux/store";

interface Props extends FieldProps {
  setShowPanel: () => void;
}

const FormPhone = ({ field, setShowPanel }: Props): JSX.Element => {
  const { value, name, onChange, ...otherFieldProps } = field;

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);
  const countries = getCountries(locale);

  const countryCodes = countries?.map(c => c.id.toLowerCase());

  return (
    <PhoneInput
      country={currentAppLocale ? currentAppLocale.country.toLowerCase() : ""}
      onlyCountries={countryCodes}
      value={value}
      inputClass="rounded-md shadow-sm sm:text-14px text-ground-black-100 border-ground-gray-500 w-full h-10"
      dropdownClass="rounded-md"
      onChange={val => {
        // Mock classic onChange event
        onChange({
          target: { name: field.name, value: val },
        });

        setShowPanel();
      }}
      inputProps={{
        ...otherFieldProps,
        id: name,
        name,
        "data-cy": name,
      }}
    />
  );
};

export default FormPhone;
