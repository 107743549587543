/*
 *
 * Service Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import {
  GetServiceQuery,
  CreateServiceMutation,
  UpdateServiceMutation,
} from "../../api/graphql/types";

import {
  getService as getServiceQuery,
  searchAllServices as searchAllServicesQuery,
} from "../../api/graphql/queries";

import {
  createService as createServiceMutation,
  updateService as updateServiceMutation,
  deleteService as deleteServiceMutation,
} from "../../api/graphql/mutations";

import { fetchPolicy } from "../../api/client";
import {
  ModelServiceConnection,
  SearchServicesOpts,
} from "../../models/Service";

const DEFAULT_LIMIT = 100;

export const getService = payload => {
  const request = payload.gql ? payload.gql : getServiceQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: { id: payload.id },
    fetchPolicy,
  }) as {
    data: GetServiceQuery;
  };
};

export const createService = payload =>
  ApolloClient.mutate({
    mutation: gql(createServiceMutation),
    variables: { input: payload },
  }) as {
    data: CreateServiceMutation;
  };

export const updateService = payload =>
  ApolloClient.mutate({
    mutation: gql(updateServiceMutation),
    variables: { input: payload },
  }) as {
    data: UpdateServiceMutation;
  };

export const deleteService = payload =>
  ApolloClient.mutate({
    mutation: gql(deleteServiceMutation),
    variables: { input: payload },
  }) as {
    data: UpdateServiceMutation;
  };

export const searchAllServices = (payload: SearchServicesOpts) => {
  const request = payload.gql ? payload.gql : searchAllServicesQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: {
      filter: payload.filter,
      limit: payload?.limit || DEFAULT_LIMIT,
    },
    fetchPolicy,
  }) as {
    data: {
      searchAllServices: ModelServiceConnection;
    };
  };
};
