import React from "react";
import classNames from "classnames";
import { IntlShape, injectIntl } from "react-intl";
import images from "../../../../../../images";
import { EnumReportingType } from "../../../../../../lib/ground-aws-graphql-core/models/Product";

interface Props {
  intl: IntlShape;
  type: EnumReportingType;
  label: string;
  amount?: string | number | null;
  variation?: string | number | null;
  rate?: number;
  evolutionRate?: number;
  selected: boolean;
}

const SalesCard = (props: Props): JSX.Element => {
  const {
    intl,
    type,
    label,
    amount,
    variation,
    rate,
    evolutionRate,
    selected,
  } = props;
  const className = `flex items-center border border-ground-gray-200 rounded-5 justify-center`;

  let eRate = 0;
  if (evolutionRate) {
    eRate = evolutionRate * 100;
  }
  let tRate = 0;
  if (rate) {
    tRate = rate * 100;
  }

  const classNameRate = classNames(
    "flex items-center text-ground-blue-700 border rounded-3 px-2",
    {
      "text-ground-blue-700 border-ground-blue-600 bg-ground-blue-500":
        eRate > 0,
      "text-ground-orange-300 border-ground-orange-200 bg-ground-orange-100":
        eRate < 0,
      "text-ground-green-400 border-ground-green-300 bg-ground-green-200":
        eRate === 0,
    }
  );

  return (
    <div className="mt-1 sm:mt-0 sm:col-span-1">
      <div
        className={className}
        style={{
          boxShadow: selected ? "0px 7px 10px #3063D526" : "none",
        }}
      >
        <div className="flex flex-col items-center justify-center py-2">
          <span className="text-ground-gray-100 text-l mt-2 text-center">
            {label}
          </span>
          <div className="flex mt-2 items-center">
            <div className="flex flex-col">
              <div className="flex">
                <span className="text-center text-xl mx-5">
                  {amount}
                  {" -"}
                </span>
                {amount !== undefined && (
                  <div className={classNameRate}>
                    <img
                      src={
                        eRate > 0
                          ? images.trendingUp
                          : eRate < 0
                          ? images.trendingDown
                          : images.trendingFlat
                      }
                      style={{ width: 25, height: 25 }}
                      className="list-thumbnail responsive border-0"
                    />
                    <span className="ml-1">{eRate.toFixed(2)} %</span>
                    {variation !== 0 && (
                      <span className="text-l mx-2">(+{variation})</span>
                    )}
                  </div>
                )}
              </div>
              <div className="flex items-center mt-2">
                <span className="text-l underline">
                  {intl.formatMessage({
                    id: `page.dashboards.chart.${type.toLowerCase()}.rate`,
                  })}
                </span>
                <span className="text-l ml-2">: {tRate.toFixed(2)} %</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(SalesCard);
