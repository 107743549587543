import React from "react";
import Select from "react-select";
import { FieldAttributes } from "formik";
import { AdditionalFieldAttributes, SelectOption } from "..";

interface Props {
  field: FieldAttributes<AdditionalFieldAttributes>;
  options: SelectOption[];
  multiple?: boolean;
  onChange: (e: any) => void;
  placeholder?: string;
}

const FormSelect = (props: Props): JSX.Element => {
  const { field, options, multiple, onChange, placeholder } = props;

  return (
    <div className="flex items-center">
      <Select
        defaultValue={field.value?.map(v => options.find(o => o.value === v))}
        placeholder={placeholder}
        id={field.name}
        className="w-full"
        isMulti={multiple}
        data-cy={field.name}
        onChange={e => {
          // Mandatory: Mock a Formik onChange
          onChange({ target: { name: field.name, value: e } });
        }}
        options={options}
      />
    </div>
  );
};

export default FormSelect;
