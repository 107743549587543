import { IntlShape } from "react-intl";
import * as Yup from "yup";

// Cognito Password policy
// Minimum length, which must be at least 6 characters but fewer than 99 characters
// Require numbers
// Require a special character from this set:
// = + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ `
// Require uppercase letters
// Require lowercase letters

export const getPasswordPolicy = (intl: IntlShape): any => {
  let minimumLength = 8;
  try {
    if (process.env.REACT_APP_PASSWORD_MINIMUM_LENGTH) {
      minimumLength = parseInt(process.env.REACT_APP_PASSWORD_MINIMUM_LENGTH);
    }
  } catch (error) {
    console.warn(
      `Unable to parse value ${process.env.REACT_APP_PASSWORD_MINIMUM_LENGTH}`
    );
  }
  const requireNumbers = process.env.REACT_APP_PASSWORD_REQUIRE_NUMBERS;
  const requireSpecialCharacter =
    process.env.REACT_APP_PASSWORD_REQUIRE_SPECIAL_CHARACTER;
  const requireUppercase =
    process.env.REACT_APP_PASSWORD_REQUIRE_UPPERCASE_LETTERS;
  const requireLowercase =
    process.env.REACT_APP_PASSWORD_REQUIRE_LOWERCASE_LETTERS;

  const message1 = intl.formatMessage(
    { id: "page.validate.password.policy.error" },
    { length: minimumLength }
  );
  const message2 = intl.formatMessage({
    id: "page.validate.password.policy.require.numbers",
  });
  const message3 = intl.formatMessage({
    id: "page.validate.password.policy.require.uppercase",
  });
  const message4 = intl.formatMessage({
    id: "page.validate.password.policy.require.lowercase",
  });
  const message5 = intl.formatMessage({
    id: "page.validate.password.policy.require.special.character",
  });

  let errorMessage = message1;
  if (requireNumbers == "true") {
    errorMessage = errorMessage + ", " + message2;
  }
  if (requireUppercase == "true") {
    errorMessage = errorMessage + ", " + message3;
  }
  if (requireLowercase == "true") {
    errorMessage = errorMessage + ", " + message4;
  }
  if (requireSpecialCharacter == "true") {
    errorMessage = errorMessage + ", " + message5;
  }

  const regexp1 = Yup.string().trim().min(minimumLength, errorMessage);
  const regexp2 = Yup.string()
    .trim()
    .matches(/^(?=.*[0-9])/, errorMessage);
  const regexp3 = Yup.string()
    .trim()
    .matches(/^(?=.*[A-Z])/, errorMessage);
  const regexp4 = Yup.string()
    .trim()
    .matches(/^(?=.*[a-z])/, errorMessage);
  const regexp5 = Yup.string()
    .trim()
    .matches(/^(?=.*[\^$*.\[\]{}\(\)?\-“+=!@#%&\/,><\’:;|_~`])/, errorMessage);

  let value = regexp1;
  if (requireNumbers == "true") {
    value = value.concat(regexp2);
  }
  if (requireUppercase == "true") {
    value = value.concat(regexp3);
  }
  if (requireLowercase == "true") {
    value = value.concat(regexp4);
  }
  if (requireSpecialCharacter == "true") {
    value = value.concat(regexp5);
  }

  return value;
};

export const getPasswordPolicyInfos = (intl: IntlShape): string => {
  const minimumLength = process.env.REACT_APP_PASSWORD_MINIMUM_LENGTH;
  const requireNumbers = process.env.REACT_APP_PASSWORD_REQUIRE_NUMBERS;
  const requireSpecialCharacter =
    process.env.REACT_APP_PASSWORD_REQUIRE_SPECIAL_CHARACTER;
  const requireUppercase =
    process.env.REACT_APP_PASSWORD_REQUIRE_UPPERCASE_LETTERS;
  const requireLowercase =
    process.env.REACT_APP_PASSWORD_REQUIRE_LOWERCASE_LETTERS;

  const message1 = intl.formatMessage(
    { id: "page.validate.password.policy.minimum.length" },
    { length: minimumLength }
  );
  const message2 = intl.formatMessage({
    id: "page.validate.password.policy.require.numbers",
  });
  const message3 = intl.formatMessage({
    id: "page.validate.password.policy.require.uppercase",
  });
  const message4 = intl.formatMessage({
    id: "page.validate.password.policy.require.lowercase",
  });
  const message5 = intl.formatMessage({
    id: "page.validate.password.policy.require.special.character",
  });

  let message = message1;
  if (requireNumbers == "true") {
    message = message + ", " + message2;
  }
  if (requireUppercase == "true") {
    message = message + ", " + message3;
  }
  if (requireLowercase == "true") {
    message = message + ", " + message4;
  }
  if (requireSpecialCharacter == "true") {
    message = message + ", " + message5;
  }

  return message;
};
