import React, { useState } from "react";
import classnames from "classnames";
import Avatar from "react-avatar";
import { injectIntl, IntlShape } from "react-intl";
import DropDown from "./DropDown";
import MyClickAwayListener from "../../../components/ClickAwayListener";
import { getRoleLabel, getRole } from "../../../utils/user";
import { UserCognito } from "../../../lib/ground-aws-cognito-auth-core/models/Authentication";
import ChangeLogWidget from "../../../components/ChangeLog";
import { getChangeLogWidgetKey } from "../../../utils/config";
import images from "../../../images";

interface Props {
  intl: IntlShape;
  user: UserCognito;
  className?: string;
}

const NavUser = ({ user, className, intl }: Props) => {
  const [open, setOpen] = useState(false);

  const userRole = getRole(user.role);
  const role = userRole ? getRoleLabel(intl, userRole) : user.role;

  return (
    <div className={classnames("relative flex-shrink-0 z-50", className)}>
      <div className="flex flex-row justify-center items-center">
        <div className="onesignal-customlink-container flex items-center mr-8" />

        {getChangeLogWidgetKey() && (
          <div className="flex flex-col">
            <ChangeLogWidget />
          </div>
        )}
        <div
          id="btn-user-avatar"
          data-cy="btn-user-avatar"
          onClick={() => setOpen(!open)}
          className="flex text-sm border-2 ml-3 border-transparent items-center cursor-pointer rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out"
        >
          <Avatar
            name={`${user.first_name} ${user.last_name}`}
            size="40"
            round={true}
            color="#898E9A"
          />
          <div className="flex flex-col ml-2 items-end">
            <p className="text-ground-white-100 text-12px">
              {user.first_name} {user.last_name}
            </p>
            <p className="text-ground-green-100 text-10px text-right">{role}</p>
          </div>
          <div className="flex items-center ml-2">
            <img src={images.bottomArrow} alt="logout" className="w-8 h-8" />
          </div>
          {open && (
            <MyClickAwayListener
              item={{ id: user.id }}
              id="click-away-user"
              onClickAway={() => setOpen(!open)}
            >
              <DropDown open={open} user={user} />
            </MyClickAwayListener>
          )}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(NavUser);
