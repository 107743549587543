import React from "react";
import VisitorItem, { VisitorInfo } from "./VisitorItem";

interface Props {
  visitors: VisitorInfo[];
  onValidate?: (visitor: VisitorInfo) => void;
  onRemove?: (visitor: VisitorInfo) => void;
}

const Visitors = (props: Props): JSX.Element => {
  const { visitors, ...rest } = props;

  return (
    <div className="flex flex-row space-x-2">
      {visitors.map(visitor => (
        <VisitorItem
          key={`visitor_${visitor.index}`}
          visitor={visitor}
          {...rest}
        />
      ))}
    </div>
  );
};

export default Visitors;
