import { AxiosResponse } from "axios";
import axiosBackend from "../../../../../axiosBackend";
import {
  AttributeKey,
  CreateAttributeKeyOpts,
  ListAttributeKeysOpts,
  UpdateAttributeKeyOpts,
} from "../../../models/Api/AttributeKey";
import { IGetResponse } from "../../../models/Api";
import { getEntityType } from "../../../../../utils/types";

export interface IListResponse<T> {
  data: {
    items: [T];
    total: number;
    has_more: boolean;
    next_url: string | null;
  };
  success: boolean;
}

export const listAttributeKeys = async (
  payload: ListAttributeKeysOpts
): Promise<AxiosResponse<IListResponse<AttributeKey>>> => {
  const { entityType } = payload;
  const params = {
    offset: payload.offset,
    ...(payload.limit && {
      limit: payload.limit,
    }),
  };

  return entityType
    ? axiosBackend.get(`/${entityType}/attributes`, { params })
    : axiosBackend.get(`/attributes`, { params });
};

export const createAttributeKey = async ({
  type,
  ...payload
}: CreateAttributeKeyOpts): Promise<
  AxiosResponse<IGetResponse<AttributeKey>>
> => {
  //
  const entityType = getEntityType(type);

  return axiosBackend.post(`/${entityType}/attributes`, payload);
};

export const getAttributeKey = async (
  id: string
): Promise<AxiosResponse<IGetResponse<AttributeKey>>> => {
  return axiosBackend.get(`/attributes/${id}`);
};

export const deleteAttributeKey = async (
  id: string
): Promise<AxiosResponse<IGetResponse<AttributeKey>>> => {
  return axiosBackend.delete(`/attributes/${id}`);
};

export const updateAttributeKey = async (
  payload: UpdateAttributeKeyOpts
): Promise<AxiosResponse<IGetResponse<AttributeKey>>> => {
  const { id, ...params } = payload;

  return axiosBackend.put(`/attributes/${id}`, params);
};
