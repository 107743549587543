/*
 *
 * Privatization Model
 *
 */

import { Action, Thunk, thunk, action } from "easy-peasy";
import {
  GqlQuery,
  StoreModel,
} from "../../../lib/ground-aws-graphql-core/models";
import { Product } from "../../../lib/ground-aws-graphql-core/models/Product";
import { ErrorGraphQL } from "../../../lib/ground-aws-graphql-core/services/coreUtils";
import { Injections } from "../../store";
import { Enterprise } from "../Enterprise";

export interface Privatization {
  id: string;
  start: string;
  end: string;
  product: Product;
  enterprise: Enterprise;
}

export interface ModelPrivatizationConnection {
  items: [Privatization];
  total: number;
}

export type ListEnterprisePrivatizationInput = {
  enterpriseId: string;
};

export type ListProductPrivatizationInput = {
  productId: string;
};

export type CreatePrivatizationInput = {
  productId: string;
  enterpriseId: string;
  start: Date;
  end: Date;
};

export type UpdatePrivatizationInput = {
  id: string;
  start: Date;
  end: Date;
};

export type DeletePrivatizationInput = {
  id: string;
};

export interface PrivatizationModel {
  privatizations: ModelPrivatizationConnection;
  privatization: Privatization | null;
  hasMoreData: boolean;

  getPrivatization: Thunk<PrivatizationModel, GqlQuery, Injections, StoreModel>;
  setPrivatizations: Action<
    PrivatizationModel,
    { data: ModelPrivatizationConnection; page?: number }
  >;
  setPrivatization: Action<PrivatizationModel, Privatization | null>;
  listEnterprisePrivatizations: Thunk<
    PrivatizationModel,
    ListEnterprisePrivatizationInput,
    Injections,
    StoreModel
  >;
  listProductPrivatizations: Thunk<
    PrivatizationModel,
    ListProductPrivatizationInput,
    Injections,
    StoreModel
  >;
  addPrivatization: Action<PrivatizationModel, Privatization>;
  replacePrivatization: Action<PrivatizationModel, Privatization>;
  createPrivatization: Thunk<
    PrivatizationModel,
    CreatePrivatizationInput,
    Injections
  >;
  updatePrivatization: Thunk<
    PrivatizationModel,
    UpdatePrivatizationInput,
    Injections
  >;
  deletePrivatization: Thunk<
    PrivatizationModel,
    DeletePrivatizationInput,
    Injections
  >;
}

export const privatizationModel: PrivatizationModel = {
  privatizations: { items: [] as unknown as [Privatization], total: 0 },
  hasMoreData: false,
  privatization: null,

  getPrivatization: thunk(async (actions, payload, { injections }) => {
    const { privatizationService } = injections;

    const response = await privatizationService.getPrivatization(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const privatization = response.data.getPrivatization;

    actions.setPrivatization(privatization as Privatization);

    return privatization;
  }),
  setPrivatizations: action((state, payload) => {
    state.privatizations = payload.data;
  }),

  setPrivatization: action((state, payload) => {
    state.privatization = payload;
  }),

  listEnterprisePrivatizations: thunk(
    async (actions, payload, { injections }) => {
      const { privatizationService } = injections;

      const response = await privatizationService.listEnterprisePrivatizations(
        payload
      );

      const errors = Object.prototype.hasOwnProperty.call(response, "errors");
      if (errors) return ErrorGraphQL(response);

      const data = response.data.listEnterprisePrivatizations;
      actions.setPrivatizations({ data });

      return data;
    }
  ),
  listProductPrivatizations: thunk(async (actions, payload, { injections }) => {
    const { privatizationService } = injections;

    const response = await privatizationService.listProductPrivatizations(
      payload
    );

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const data = response.data.listProductPrivatizations;
    actions.setPrivatizations({ data });

    return data;
  }),

  addPrivatization: action((state, payload) => {
    if (state.privatizations.items) {
      state.privatizations.items.push(payload);
    } else {
      state.privatizations["items"] = [payload];
    }
  }),

  replacePrivatization: action((state, payload) => {
    state.privatization = payload;
    if (state.privatizations.items) {
      const index = state.privatizations.items!.findIndex(
        e => e.id === payload.id
      );
      if (index >= 0) state.privatizations.items!.splice(index, 1, payload);
    }
  }),

  createPrivatization: thunk(async (actions, payload, { injections }) => {
    const { privatizationService } = injections;
    const response = await privatizationService.addPrivatization(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const item = response.data.addPrivatization;
    actions.setPrivatization(item as Privatization);
    actions.addPrivatization(item as Privatization);

    return item;
  }),

  updatePrivatization: thunk(async (actions, payload, { injections }) => {
    const { privatizationService } = injections;
    const response = await privatizationService.updatePrivatization(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const item = response.data.updatePrivatization;
    actions.setPrivatization(item as Privatization);
    actions.replacePrivatization(item as Privatization);

    return item;
  }),

  deletePrivatization: thunk(async (_actions, payload, { injections }) => {
    const { privatizationService } = injections;

    return privatizationService.deletePrivatization(payload);
  }),
};
