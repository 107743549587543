import React from "react";
import classnames from "classnames";
import IntlMessages from "../../../utils/messages";

interface Props {
  id: string;
  index: number;
  label: string;
  onClick: (e) => void;
  className?: string;
  active?: boolean;
}

const NavItem = (props: Props): JSX.Element => {
  const value =
    "flex items-center px-1 pt-1 border-b-4 text-14px focus:outline-none transition duration-150 ease-in-out";

  const custom = props.className ? props.className : "";
  const className = classnames(value, custom, {
    "border-ground-green-100 text-white hover:border-ground-green-10 focus:border-ground-green-10":
      props.active,
    "border-transparent text-ground-gray-100 hover:text-white hover:border-white focus:text-white focus:border-white":
      !props.active,
  });

  return (
    <a
      href="#"
      id={props.id}
      data-cy={props.id}
      data-testid={props.index}
      className={className}
      onClick={props.onClick}
    >
      <IntlMessages id={props.label} />
    </a>
  );
};

export default NavItem;
