import { AxiosResponse } from "axios";
import axiosBackend from "../../../../../axiosBackend";
import {
  Incident,
  IncidentComment,
  IncidentReason,
  IncidentType,
  PinIncidentOpts,
  SearchableIncidentsFilterInput,
  UpdateIncidentsOpts,
  ViewIncidentsOpts,
} from "../../../models/Api/Incidents";
import {
  IncidentStatus,
  CommentIncidentsOpts,
} from "../../../models/Api/Incidents/index";
import {
  IGetResponse,
  IListMediaResponse,
  IListResponse,
} from "../../../models/Api";

export const getIncident = async (
  id: string
): Promise<AxiosResponse<IGetResponse<Incident>>> => {
  return axiosBackend.get(`/incidents/${id}`);
};

export const updateIncident = async ({
  id,
  ...payload
}: UpdateIncidentsOpts): Promise<AxiosResponse<IGetResponse<Incident>>> => {
  return axiosBackend.put(`/incidents/${id}`, payload);
};

export const viewIncident = async ({
  id,
}: ViewIncidentsOpts): Promise<AxiosResponse<IGetResponse<Incident>>> => {
  return axiosBackend.post(`/incidents/${id}/view`);
};

export const commentIncident = async ({
  id,
  ...payload
}: CommentIncidentsOpts): Promise<
  AxiosResponse<IGetResponse<IncidentComment>>
> => {
  return axiosBackend.post(`/incidents/${id}/comments`, payload);
};

export const pinIncident = async ({
  id,
}: PinIncidentOpts): Promise<AxiosResponse<IGetResponse<Incident>>> => {
  return axiosBackend.post(`/incidents/${id}/pin`);
};

export const unpinIncident = async ({
  id,
}: PinIncidentOpts): Promise<AxiosResponse<IGetResponse<Incident>>> => {
  return axiosBackend.post(`/incidents/${id}/unpin`);
};

export const listIncidents = async (
  buildingId: string,
  offset: number,
  limit: number,
  filter?: SearchableIncidentsFilterInput
): Promise<AxiosResponse<IListResponse<Incident>>> => {
  const params = {
    offset,
    limit,
  };

  if (filter) {
    const keys = Object.keys(filter);
    keys.forEach(key => {
      const operator = Object.keys(filter[key]);
      if (operator) {
        const value = filter[key][operator];
        if (operator.includes("between")) {
          value.forEach((val: string, index: number) => {
            params[`${key}[${operator}][${index}]`] = val;
          });
        } else {
          if (value !== null && value !== "" && value !== undefined) {
            params[`${key}[${operator}]`] = value;
          }
        }
      }
    });
  }

  return axiosBackend.get(`/incidents/buildings/${buildingId}`, { params });
};

export const exportIncidents = async (
  buildingId: string,
  offset: number,
  limit: number,
  filter?: SearchableIncidentsFilterInput
): Promise<AxiosResponse<IListResponse<Incident>>> => {
  const params = { offset, limit };

  if (filter) {
    const keys = Object.keys(filter);
    keys.forEach(key => {
      const operator = Object.keys(filter[key]);
      if (operator) {
        const value = filter[key][operator];
        if (operator.includes("between")) {
          value.forEach((val: string, index: number) => {
            params[`${key}[${operator}][${index}]`] = val;
          });
        } else {
          if (value !== null && value !== "" && value !== undefined) {
            params[`${key}[${operator}]`] = value;
          }
        }
      }
    });
  }

  return axiosBackend.get(`/export/incidents/buildings/${buildingId}`, {
    params,
  });
};

export const listIncidentStatus = async (): Promise<
  AxiosResponse<IListResponse<IncidentStatus>>
> => {
  return axiosBackend.get("/incidents/status");
};

export const listIncidentTypes = async (): Promise<
  AxiosResponse<IListResponse<IncidentType>>
> => {
  return axiosBackend.get("/incidents/types");
};

export const listIncidentReasons = async (
  id: number,
  offset: number,
  limit: number
): Promise<AxiosResponse<IListResponse<IncidentReason>>> => {
  return axiosBackend.get(
    `/incidents/types/${id}/reasons?offset=${offset}&limit=${limit}`
  );
};

export const listIncidentComments = async (
  incidentId: string,
  offset?: number,
  limit?: number
): Promise<AxiosResponse<IListResponse<IncidentComment>>> => {
  return axiosBackend.get(`/incidents/${incidentId}/comments`, {
    params: { offset, limit },
  });
};

export const listMedia = async (
  id: string
): Promise<AxiosResponse<IListMediaResponse>> => {
  return axiosBackend.get(`/incidents/${id}/media`);
};
