import React, { useState, useEffect } from "react";
import { injectIntl, IntlShape } from "react-intl";
import RangeHour from "../RangeHour";
import {
  getDayName,
  getSlotHoursRange,
  DaySlotItem,
  HoursRangeItem,
} from "../../../../../utils/dayslot";
import { EnumHoursRangeName } from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { HoursRange } from "../../../../../lib/ground-aws-graphql-core/models/HoursRange";
import { ActionTypes } from "../../../../../utils/types";

interface Props {
  intl: IntlShape;
  daySlot: DaySlotItem;
  onChange: (e: HoursRangeItem) => void;
}

const DaySlot = (props: Props) => {
  const { daySlot, onChange, intl } = props;
  const [hoursRanges, setHoursRanges] = useState<HoursRangeItem[]>([]);

  const addUpdatedHoursRange = (
    hoursRange: HoursRange,
    action: ActionTypes,
    start: string,
    end: string
  ) => {
    const hoursRangeItem: HoursRangeItem = {
      item: {
        id: hoursRange.id,
        start,
        end,
        name: hoursRange.name,
        operator: hoursRange.operator,
      },
      action,
      isGenerated: false,
      daySlot: daySlot.item,
    };
    onChange(hoursRangeItem);
  };

  useEffect(() => {
    const ranges = getSlotHoursRange(daySlot.item);
    setHoursRanges(ranges);
  }, [daySlot]);

  return (
    <tr>
      <td className="pr-6 py-2 text-gray-600">
        {getDayName(intl, daySlot.item.name)}
      </td>
      {hoursRanges.map((hourRange, index) => {
        return (
          <td className="px-3 py-2" key={index}>
            <RangeHour<HoursRange>
              hasHoursRange={!hourRange.isGenerated}
              hoursRangeItem={hourRange.item}
              rangeName={
                index === 0
                  ? EnumHoursRangeName.MORNING
                  : EnumHoursRangeName.AFTERNOON
              }
              onChange={addUpdatedHoursRange}
            />
          </td>
        );
      })}
    </tr>
  );
};

export default injectIntl(DaySlot);
