/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 */
const Event = (category: string, action: string, value = {}): void => {
  window.gtag("event", action, {
    ...value,
    event_category: category,
  });
};

export default Event;
