import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import {
  ReportingIncidentReason,
  ReportingIncidentStatus,
  ReportingIncidentType,
} from "../../../lib/ground-aws-graphql-core/models/Reporting";
import { getTranslation } from "../../../utils/translation";
import { ALL_FILTER } from "../../Table";

interface Props {
  intl: IntlShape;
  filter: string;
  values:
    | ReportingIncidentStatus[]
    | ReportingIncidentType[]
    | ReportingIncidentReason[];
  value?:
    | ReportingIncidentStatus
    | ReportingIncidentType
    | ReportingIncidentReason;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const ReportingIncidentFilter = (props: Props): JSX.Element => {
  const { intl, onChange, values, value, filter } = props;

  return (
    <div className="h-8 my-4 inline-block border border-ground-gray-400 rounded-4">
      <span
        id="select-label"
        key="select-label"
        className="h-full px-4 bg-transparent text-ground-black-100 text-14px leading-5 border-t-0 border-b-0 border-l-0 border-r border-ground-gray-400 rounded-none shadow-none"
      >
        {intl.formatMessage({ id: `general.filter.${filter}` })}
      </span>
      <select
        id={`reporting-filter-${filter}`}
        name={`reporting-filter-${filter}`}
        className="form-select h-full py-0 bg-transparent text-ground-black-100 text-14px leading-5 border-none rounded-none shadow-none"
        onChange={onChange}
        defaultValue={value?.code}
      >
        <option value={ALL_FILTER}>
          {intl.formatMessage({ id: "general.all" })}
        </option>
        {values.map(status => (
          <option key={status.code} value={status.code}>
            {getTranslation(status.label)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default injectIntl(ReportingIncidentFilter);
