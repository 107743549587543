import React, { useState } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { SearchableSortDirection } from "../../../../lib/ground-aws-graphql-core/api/graphql/types";

interface Props {
  intl: IntlShape;
  sorts: {
    field: string;
    directions: SearchableSortDirection[];
    onChange: (field: string, direction: string) => void;
  }[];
}

const SortSelector = (props: Props): JSX.Element => {
  const { intl, sorts } = props;

  const sort = sorts[0];
  const { field, directions, onChange } = sort;
  const [sortField, setSortField] = useState<string>(field);
  const [sortDirection, setSortDirection] = useState<string>(directions[0]);

  return (
    <div className="h-8 my-4 inline-block border border-ground-gray-400 rounded-4">
      <select
        id="sort-field"
        name="sort-field"
        className="form-select h-full py-0 bg-transparent text-ground-black-100 text-14px leading-5 border-t-0 border-b-0 border-l-0 border-r border-ground-gray-400 rounded-none shadow-none"
        onChange={e => {
          setSortField(e.target.value);
          onChange(e.target.value, sortDirection);
        }}
      >
        {sorts?.map(s => (
          <option key={s.field} value={s.field}>
            {intl.formatMessage({
              id: `general.sort.${s.field}`,
            })}
          </option>
        ))}
      </select>
      <select
        id="sort-direction"
        name="sort-direction"
        className="form-select h-full py-0 bg-transparent text-ground-black-100 text-14px leading-5 border-none rounded-none shadow-none"
        onChange={e => {
          setSortDirection(e.target.value);
          onChange(sortField, e.target.value);
        }}
      >
        {sort?.directions?.map(d => (
          <option key={d} value={d}>
            {intl.formatMessage({
              id: `general.sort.direction.${d}`,
            })}
          </option>
        ))}
      </select>
    </div>
  );
};

export default injectIntl(SortSelector);
