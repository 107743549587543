import React, { useState, useEffect, Fragment } from "react";
import { Helmet } from "react-helmet";
import { Location } from "history";
import { toast } from "react-toastify";
import { match as Match } from "react-router-dom";
import { IntlShape, useIntl } from "react-intl";
import PageSubheader from "../../../../components/PageSubheader";
import { withTwoColLayout } from "../../../../utils";
import Table, { IFilterField } from "../../../../components/Table";
import { TableChangeParams } from "../../../../components/Table/types";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import {
  EnumVisitStatus,
  EnumVisitorStatus,
  Visit,
  Visitor,
} from "../../../../lib/ground-aws-graphql-core/models/Api/Visits";
import Dropdown, {
  DropdownValues,
} from "../../../../components/Tailwind/Dropdown";
import { getVisitStatusBadgeColor } from "../../../../utils/visits";
import { EnumPaths } from "../../../../utils/navigation";
import history from "../../../../history";
import images from "../../../../images";
import { displayDayDDMMYYYY_HHMM } from "../../../../utils/config";

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
  location: Location<{
    pageIndex: number;
    limit: number;
    type: string;
    filters: IFilterField<{
      code: number;
      id: string;
      name: string;
    }>[];
  }>;
}

const DEFAULT_LIMIT = 20;

const ListVisits = (props: Props) => {
  const { match, location } = props;
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [initialParams, setInitialParams] = useState<TableChangeParams | null>(
    location?.state
  );

  const listVisits = GroundGraphqlContextStore.useStoreActions(
    actions => actions.visits.listVisits
  );

  const visits = GroundGraphqlContextStore.useStoreState(
    state => state.visits.visits.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.visits.visits.total
  );

  const totalCheckedIn = GroundGraphqlContextStore.useStoreState(
    state => state.visits.visits.total_checked_in
  );

  const checkInVisit = GroundGraphqlContextStore.useStoreActions(
    actions => actions.visits.checkInVisit
  );

  const checkOutVisit = GroundGraphqlContextStore.useStoreActions(
    actions => actions.visits.checkOutVisit
  );

  const cancelVisit = GroundGraphqlContextStore.useStoreActions(
    actions => actions.visits.cancelVisit
  );

  const resendInvitation = GroundGraphqlContextStore.useStoreActions(
    actions => actions.visits.resendInvitation
  );

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  useEffect(() => {
    if (location?.state) {
      fetchData(location?.state?.pageIndex, true, location?.state?.limit);
    } else fetchData();
  }, []);

  const fetchData = (pageIndex = 0, loader = true, limit = DEFAULT_LIMIT) => {
    if (loader) setLoading(true);

    listVisits({
      buildingId: match.params.cid,
      offset: pageIndex * limit,
      limit,
    }).finally(() => setLoading(false));
  };

  const handleResendInvitation = (id: number) => {
    setLoading(true);
    resendInvitation({ id })
      .then((isInvitationSent: boolean) => {
        if (isInvitationSent) {
          toast(
            intl.formatMessage({
              id: "page.list.visits.resend.invitation.success",
            }),
            { type: "success" }
          );
        } else {
          toast(
            intl.formatMessage({
              id: "page.list.visits.resend.invitation.error",
            }),
            { type: "error" }
          );
        }
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.list.visits.resend.invitation.error",
          }),
          { type: "error" }
        );
      })
      .finally(() => setLoading(false));
  };

  const handleCheckInVisit = (id: number) => {
    setLoading(true);
    checkInVisit({ id })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.list.visits.visit.check.in.success",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.list.visits.visit.check.in.error",
          }),
          { type: "error" }
        );
      })
      .finally(() => setLoading(false));
  };

  const handleCheckOutVisit = (id: number) => {
    setLoading(true);
    checkOutVisit({ id })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.list.visits.visit.check.out.success",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.list.visits.visit.check.out.error",
          }),
          { type: "error" }
        );
      })
      .finally(() => setLoading(false));
  };

  const handleCancelVisit = (id: number) => {
    setLoading(true);
    cancelVisit({ id })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.list.visits.visit.cancel.success",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.list.visits.visit.cancel.error",
          }),
          { type: "error" }
        );
      })
      .finally(() => setLoading(false));
  };

  const onVisitClick = (editLink: string) =>
    history.push(editLink, initialParams);

  const tableHead = [
    "general.ref",
    "general.date",
    "general.host",
    "general.visitor",
    "general.status",
    "general.check.in",
    "general.check.out",
    "general.actions",
  ];

  const tableBody = visits?.map((visit: Visit) => {
    const { id, ref, visitors, date, status, host } = visit;

    const editVisitLink = `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${match.params.cid}/${EnumPaths.VISITS}/${id}/details`;

    const dropdownValues: DropdownValues[] = [
      {
        id: "edit_visit",
        label: "page.list.visits.edit",
        icon: images.edit,
        link: editVisitLink,
      },
    ];

    if (visit.status === EnumVisitStatus.PREREGISTERED) {
      dropdownValues.push(
        {
          id: "resendInvitation",
          label: "page.list.visits.actions.resend.invitation",
          icon: images.mail,
          onClick: () => {
            handleResendInvitation(id);
          },
        },
        {
          id: "checkIn",
          label: "page.list.visits.actions.check.in",
          icon: images.checkIn,
          onClick: () => {
            handleCheckInVisit(id);
          },
        },
        {
          id: "cancel",
          label: "page.list.visits.actions.cancel",
          icon: images.cancel,
          onClick: () => {
            handleCancelVisit(id);
          },
        }
      );
    }

    if (visit.status === EnumVisitStatus.CHECKED_IN) {
      dropdownValues.push({
        id: "checkOut",
        label: "page.list.visits.actions.check.out",
        icon: images.checkOut,
        onClick: () => {
          handleCheckOutVisit(id);
        },
      });
    }

    const renderVisitor = (visitor: Visitor) => {
      return (
        <Fragment key={visitor.id}>
          <div className="inline-block relative">
            <div className="flex flex-col items-start border rounded-3 text-ground-gray-100 bg-gray-100 p-2 m-1">
              <div className="flex justify-between w-full">
                <span className="text-gray-700">
                  {`${visitor.first_name} ${visitor.last_name}`}
                </span>
              </div>
              <span className="text-gray-700">{visitor.email}</span>
              <span>
                {intl.formatMessage(
                  { id: "general.company_name" },
                  { company_name: visitor.company_name || "-" }
                )}
              </span>
            </div>
          </div>
        </Fragment>
      );
    };

    const visitElements = [
      {
        element: ref,
        onCellClick: () => onVisitClick(editVisitLink),
      },
      {
        element: displayDayDDMMYYYY_HHMM(date, centerTimezone),
        onCellClick: () => onVisitClick(editVisitLink),
      },
      {
        element: `${host.first_name} ${host.last_name}`,
        onCellClick: () => onVisitClick(editVisitLink),
      },
      {
        element: (
          <div className="flex flex-col">
            {visitors?.map(visitor => renderVisitor(visitor))}
          </div>
        ),
        onCellClick: () => onVisitClick(editVisitLink),
      },
      {
        element: (
          <div
            className={`rounded-full p-1 text-white ${getVisitStatusBadgeColor(
              status
            )}`}
          >
            {intl.formatMessage({ id: `page.list.visits.status.${status}` })}
          </div>
        ),
        onCellClick: () => onVisitClick(editVisitLink),
      },
      {
        element: displayDayDDMMYYYY_HHMM(visit.checked_in, centerTimezone),
        onCellClick: () => onVisitClick(editVisitLink),
      },
      {
        element: displayDayDDMMYYYY_HHMM(visit.checked_out, centerTimezone),
        onCellClick: () => onVisitClick(editVisitLink),
      },
      {
        element: (
          <Dropdown values={dropdownValues} dataCy={`visit-dropdown-${id}`} />
        ),
      },
    ];

    return {
      rowElements: visitElements,
    };
  });

  const handleOnChange = (changeParams: TableChangeParams) => {
    const { pageIndex, limit } = changeParams;

    setInitialParams(changeParams);

    fetchData(pageIndex, false, limit);
  };

  const addNewVisit = () => {
    history.push(
      `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${match.params.cid}/${EnumPaths.VISITS}/${EnumPaths.CREATION_MODE}`
    );
  };

  return (
    <div>
      <Helmet>
        <title>
          {intl.formatMessage({ id: "page.list.visits.document.title" })}
        </title>
      </Helmet>

      <PageSubheader
        title="page.list.visits.title"
        nbOfResults={total}
        buttonRightTitle="page.list.visits.create.visit"
        buttonRightAction={addNewVisit}
        buttonRightId="btn-create-visit"
      />

      {!loading && (
        <>
          <div className="flex justify-end mt-4 px-8">
            <div className="flex px-8 p-2 leading-4 rounded-full border-2 border-ground-green-100">
              <span className="text-xl text-ground-green-100 mr-2">
                {totalCheckedIn}
              </span>
              <span className="text-xs text-ground-green-100">
                {intl.formatMessage({
                  id: `page.list.visits.visitor.status.${EnumVisitorStatus.CHECKED_IN}`,
                })}
              </span>
            </div>
          </div>
          <div className="flex justify-end my-2 px-8">
            <button
              id="link-list-visitors-onsite"
              name="link-list-visitors-onsite"
              type="button"
              className="underline"
              onClick={() => {
                history.push(
                  `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${match.params.cid}/${EnumPaths.VISITORS}`
                );
              }}
            >
              <span>
                {intl.formatMessage({
                  id: "page.list.visits.visitors.onsite",
                })}
              </span>
            </button>
          </div>
        </>
      )}

      <Table
        initialParams={initialParams}
        head={tableHead}
        body={tableBody}
        noDataText="page.list.visits.empty"
        onChange={handleOnChange}
        paginationTotal={total}
        paginationLimit={DEFAULT_LIMIT}
        // TODO: Will be used when using filters
        // permissionEntity={EnumPermissionEntity.VISIT}
        // useFilterBlock
        // filterBlockTitle="page.list.visits.filter.title"
        loading={loading}
        setLoading={setLoading}
        className="px-8"
      />
    </div>
  );
};

export default withTwoColLayout(ListVisits);
