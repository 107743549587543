import { EnumBookingStatus } from "../lib/ground-aws-graphql-core/models/Product";

export type BookingStatus = {
  status: EnumBookingStatus;
  cellBgColor: string;
};

export const bookingStatusEnum: BookingStatus[] = [
  { status: EnumBookingStatus.AVAILABLE, cellBgColor: "" },
  { status: EnumBookingStatus.CLOSED, cellBgColor: "bg-neutral-200" },
  { status: EnumBookingStatus.BOOKED, cellBgColor: "bg-ground-blue-500" },
  { status: EnumBookingStatus.COMPLETE, cellBgColor: "bg-red-500" },
];

export const getCellBackgroundColorFromStatus = (
  status: EnumBookingStatus
): string | undefined =>
  bookingStatusEnum.find(bookingStatus => bookingStatus.status === status)
    ?.cellBgColor;
