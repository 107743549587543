import React, { useEffect, useState } from "react";
import {
  match as Match,
  Route,
  withRouter,
  Switch,
  Redirect,
} from "react-router-dom";
import OneSignal from "react-onesignal";
import Home from "./home";
import ListCenters from "./center/centers-list";
import ListProviders from "./providers/providers-list";
import ProviderUpdate from "./providers/edition";
import ProviderCreate from "./providers/creation";

import ListNotifications from "./notifications/notifications-list";
import ListDocuments from "./documents/documents-list";
import NotificationCreate from "./notifications/creation";

import Informations from "./center/edition";

import Dashboard from "./management/dashboards";
import RealTimeAssetDashboard from "./management/dashboards/real-time/asset";
import RealTimeSalesDashboard from "./management/dashboards/real-time/sales";
import RealTimeIncidentsDashboard from "./management/dashboards/real-time/incidents";
import UsersDashboard from "./indicators/users";
import GlobalDashboard from "./indicators/global";
import IncidentDashboard from "./management/dashboards/consolidation/incidents";
import SalesDashboard from "./management/dashboards/consolidation/sales";
import AssetDashboard from "./management/dashboards/consolidation/asset";
import CalendarScreen from "./management/calendar";
import ListGlobalOrders from "./management/global-orders/global-orders-list";
import OrderUpdate from "./management/orders/edition";

import ListBookings from "./management/booking/bookings-list";
import ListBookingProducts from "./management/booking/book";
import BookingCreate from "./management/booking/book/create";

import ListNews from "./marketing/news/news-list";
import NewsUpdate from "./marketing/news/edition";
import NewsCreation from "./marketing/news/creation";

import ListEvents from "./marketing/event/events-list";
import EventUpdate from "./marketing/event/edition";
import EventCreate from "./marketing/event/creation";

import ListServices from "./catalog/service/services-list";
import ServiceUpdate from "./catalog/service/edition";
import ServiceCreate from "./catalog/service/creation";
import ListProducts from "./catalog/product/products-list";
import ProductUpdate from "./catalog/product/edition";
import ProductCreate from "./catalog/product/creation";

import ListWebhooks from "./developers/webhooks";
import CreateWebhook from "./developers/webhooks/creation";
import UpdateWebhook from "./developers/webhooks/edition";
import ListAppClients from "./developers/app-clients";
import CreateAppClient from "./developers/app-clients/creation";
import UpdateAppClient from "./developers/app-clients/edition";

import ListPaymentMethods from "./parameters/payment-methods";
import CreatePaymentMethod from "./parameters/payment-methods/creation";
import UpdatePaymentMethod from "./parameters/payment-methods/edition";
import ListAttributeKeys from "./parameters/attribute-keys";
import CreateAttributeKey from "./parameters/attribute-keys/creation";
import UpdateAttributeKey from "./parameters/attribute-keys/edition";

import ListUsers from "./users/users-list";
import ListAdministrators from "./users/administrators-list";
import UserUpdate from "./users/edition";
import UserCreate from "./users/creation";
import EnterpriseCreate from "./enterprise/creation";
import EnterpriseUpdate from "./enterprise/edition";
import ListEnterprises from "./enterprise/enterprises-list";

import CenterCreate from "./center/creation";

import ListSpaces from "./spaces/spaces-list";
import CreatePrivatization from "./spaces/creation";
import UpdatePrivatization from "./spaces/edition";

import ListIncidents from "./management/incidents/incidents-list";
import DetailIncident from "./management/incidents/incident";

import ListVisits from "./management/visits/visits-list";
import ListVisitors from "./management/visits/visitors-list";
import DetailVisit from "./management/visits/visit";
import VisitCreate from "./management/visits/creation";

import BookingOptions from "./management/booking/book/options";
import BookingSummary from "./management/booking/book/summary";

import {
  withOneColLayout,
  withTwoColLayout,
  customUsersTwoColLayout,
  customParametersTwoColLayout,
  customIndicatorsTwoColLayout,
  customDevelopersTwoColLayout,
} from "../utils";
import { EnumPaths, getSpecificIdFromUrl } from "../utils/navigation";
import withTracker from "../utils/tracking";
import {
  EnumCategoryType,
  EnumNotificationType,
  EnumServiceType,
} from "../lib/ground-aws-graphql-core/api/graphql/types";
import Navbar from "../containers/Navbar";
import PageSubheader from "../components/PageSubheader";
import history from "../history";
import GlobalOrderUpdate from "./management/global-orders/edition";
import { GroundAuthContextStore } from "../lib/ground-aws-cognito-auth-core";
import { asyncLocalStorage } from "../utils/token";
import { GroundGraphqlContextStore } from "../lib/ground-aws-graphql-core";
import { getOperatorConfiguration } from "../lib/operator-configuration";
import { isDisplayable } from "../lib/operator-configuration/backoffice/display";
import { OneSignalAttributeKeys } from "../utils/attribute";

interface Props {
  match: Match;
}

const MainApp = (mainProps: Props) => {
  const { match } = mainProps;
  const { url } = match;
  const [initialized, setInitialized] = useState<boolean>(false);

  const handleGetCid = () => {
    return getSpecificIdFromUrl(history.location.pathname, "centers/");
  };

  const getMe = GroundAuthContextStore.useStoreActions(
    actions => actions.authentication.getMe
  );
  const getMeDetails = GroundAuthContextStore.useStoreActions(
    actions => actions.authentication.getMeDetails
  );
  const logOut = GroundAuthContextStore.useStoreActions(
    actions => actions.authentication.logOut
  );

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const getOperatorAttributeValue = GroundGraphqlContextStore.useStoreActions(
    actions => actions.attributeValue.getOperatorAttributeValue
  );

  const configuration = getOperatorConfiguration(me?.operator_id);

  useEffect(() => {
    // Log out the user if there is any error on the getMe request
    // Prevents blank page completely
    getMe().catch(() => {
      asyncLocalStorage.clear().then(() => {
        logOut();
        history.push(`/${EnumPaths.SIGNIN}`);
      });
    });
    getMeDetails();
  }, []);

  useEffect(() => {
    if (me) {
      getOperatorAttributeValue({
        operatorId: me.operator_id,
        attributeKeyName: OneSignalAttributeKeys.ONESIGNAL_BO_APP_ID,
      }).then((resp: any) => {
        if (resp.data?.data?.value) {
          OneSignal.init({
            appId: resp.data.data.value,
          });
          OneSignal.showCategorySlidedown();
          setInitialized(true);
        }
      });
    }
  }, [me]);

  useEffect(() => {
    if (initialized && me) {
      OneSignal.setExternalUserId(me.id);
      OneSignal.setEmail(me.email);
      OneSignal.sendTag("operator_id", me.operator_id);
      OneSignal.sendTag("role", me.role);
    }
  }, [initialized]);

  return (
    me && (
      <>
        <Navbar />
        <Switch>
          {/* START - HOME */}
          <Route
            exact
            path={`${url}/${EnumPaths.HOME}`}
            component={withOneColLayout(
              withTracker(props => <Home {...props} />)
            )}
          />
          {/* END - HOME */}

          {/* START - CENTERS */}
          <Route
            exact
            path={`${url}/${EnumPaths.CENTERS}`}
            component={withOneColLayout(
              withTracker(props => <ListCenters {...props} />)
            )}
          />
          <Route
            exact
            path={`${url}/${EnumPaths.CENTERS}/${EnumPaths.CREATION_MODE}`}
            component={withTracker(props => (
              <CenterCreate
                {...props}
                backURL={`${url}/${EnumPaths.CENTERS}`}
              />
            ))}
          />
          {/* END - CENTERS */}

          {/* START - PROVIDERS */}
          {isDisplayable(configuration, "header.providers", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.PROVIDERS}`}
              component={withOneColLayout(
                withTracker(props => <ListProviders {...props} />)
              )}
            />
          )}
          {isDisplayable(configuration, "header.providers", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.PROVIDERS}/${EnumPaths.CREATION_MODE}`}
              component={withTracker(props => (
                <ProviderCreate
                  {...props}
                  backURL={`${url}/${EnumPaths.PROVIDERS}`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "header.providers", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.PROVIDERS}/:id/${EnumPaths.EDIT_MODE}`}
              component={withTracker(props => (
                <ProviderUpdate
                  {...props}
                  backURL={`${url}/${EnumPaths.PROVIDERS}`}
                />
              ))}
            />
          )}
          {/* END - PROVIDERS */}

          {/* START - USERS */}
          {isDisplayable(configuration, "header.users", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.USERS}`}
              component={customUsersTwoColLayout(
                withTracker(props => <ListUsers {...props} />)
              )}
            />
          )}
          {isDisplayable(configuration, "header.users", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.USERS}/${EnumPaths.CREATION_MODE}`}
              component={withTracker(props => (
                <UserCreate
                  {...props}
                  administrator={false}
                  backURL={`${url}/${EnumPaths.USERS}`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "header.users", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.USERS}/:id/${EnumPaths.EDIT_MODE}`}
              component={withTracker(props => (
                <UserUpdate
                  {...props}
                  administrator={false}
                  backURL={`${url}/${EnumPaths.USERS}`}
                />
              ))}
            />
          )}
          {/* END - USERS */}

          {/* START - ADMINISTRATORS */}
          {isDisplayable(configuration, "header.administrators", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.ADMINISTRATORS}`}
              component={withOneColLayout(
                withTracker(props => <ListAdministrators {...props} />)
              )}
            />
          )}

          {isDisplayable(configuration, "header.administrators", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.ADMINISTRATORS}/:id/${EnumPaths.EDIT_MODE}`}
              component={withTracker(props => (
                <UserUpdate
                  {...props}
                  administrator
                  backURL={`${url}/${EnumPaths.ADMINISTRATORS}`}
                />
              ))}
            />
          )}

          {isDisplayable(configuration, "header.administrators", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.ADMINISTRATORS}/${EnumPaths.CREATION_MODE}`}
              component={withTracker(props => (
                <UserCreate
                  {...props}
                  administrator
                  backURL={`${url}/${EnumPaths.ADMINISTRATORS}`}
                />
              ))}
            />
          )}

          {/* END - ADMINISTRATORS */}

          {/* START - PARAMETERS */}
          {isDisplayable(configuration, "header.parameters", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.PAYMENT_METHODS}`}
              component={customParametersTwoColLayout(
                withTracker(props => <ListPaymentMethods {...props} />)
              )}
            />
          )}
          {isDisplayable(configuration, "header.parameters", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.PAYMENT_METHODS}/${EnumPaths.CREATION_MODE}`}
              component={withTracker(props => (
                <CreatePaymentMethod
                  {...props}
                  backURL={`${url}/${EnumPaths.PAYMENT_METHODS}`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "header.parameters", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.PAYMENT_METHODS}/:id/${EnumPaths.EDIT_MODE}`}
              component={withTracker(props => (
                <UpdatePaymentMethod
                  {...props}
                  backURL={`${url}/${EnumPaths.PAYMENT_METHODS}`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "header.parameters", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.ATTRIBUTE_KEYS}`}
              component={customParametersTwoColLayout(
                withTracker(props => <ListAttributeKeys {...props} />)
              )}
            />
          )}
          {isDisplayable(configuration, "header.parameters", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.ATTRIBUTE_KEYS}/${EnumPaths.CREATION_MODE}`}
              component={withTracker(props => (
                <CreateAttributeKey
                  {...props}
                  backURL={`${url}/${EnumPaths.ATTRIBUTE_KEYS}`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "header.parameters", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.ATTRIBUTE_KEYS}/:id/${EnumPaths.EDIT_MODE}`}
              component={withTracker(props => (
                <UpdateAttributeKey
                  {...props}
                  backURL={`${url}/${EnumPaths.ATTRIBUTE_KEYS}`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "header.developers", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.WEBHOOKS}`}
              component={customDevelopersTwoColLayout(
                withTracker(ListWebhooks)
              )}
            />
          )}
          {isDisplayable(configuration, "header.developers", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.WEBHOOKS}/${EnumPaths.CREATION_MODE}`}
              component={withTracker(props => (
                <CreateWebhook
                  {...props}
                  backURL={`${url}/${EnumPaths.WEBHOOKS}`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "header.developers", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.WEBHOOKS}/:id/${EnumPaths.EDIT_MODE}`}
              component={withTracker(props => (
                <UpdateWebhook
                  {...props}
                  backURL={`${url}/${EnumPaths.WEBHOOKS}`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "header.developers", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.APP_CLIENTS}`}
              component={customDevelopersTwoColLayout(
                withTracker(props => <ListAppClients {...props} />)
              )}
            />
          )}
          {isDisplayable(configuration, "header.developers", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.APP_CLIENTS}/${EnumPaths.CREATION_MODE}`}
              component={withTracker(props => (
                <CreateAppClient
                  {...props}
                  backURL={`${url}/${EnumPaths.APP_CLIENTS}`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "header.developers", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.APP_CLIENTS}/:id/${EnumPaths.EDIT_MODE}`}
              component={withTracker(props => (
                <UpdateAppClient
                  {...props}
                  backURL={`${url}/${EnumPaths.APP_CLIENTS}`}
                />
              ))}
            />
          )}
          {/* END - PARAMETERS */}

          {/* START - ENTERPRISES */}
          {isDisplayable(configuration, "feature.enterprise") && (
            <Route
              exact
              path={`${url}/${EnumPaths.USERS}/${EnumPaths.ENTERPRISE}`}
              component={customUsersTwoColLayout(
                withTracker(props => <ListEnterprises {...props} />)
              )}
            />
          )}
          {isDisplayable(configuration, "feature.enterprise") && (
            <Route
              exact
              path={`${url}/${EnumPaths.USERS}/${EnumPaths.ENTERPRISE}/${EnumPaths.CREATION_MODE}`}
              component={withTracker(props => (
                <EnterpriseCreate
                  {...props}
                  backURL={`${url}/${EnumPaths.USERS}/${EnumPaths.ENTERPRISE}`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "feature.enterprise") && (
            <Route
              exact
              path={`${url}/${EnumPaths.USERS}/${EnumPaths.ENTERPRISE}/:id/${EnumPaths.EDIT_MODE}`}
              component={withTracker(props => (
                <EnterpriseUpdate
                  {...props}
                  backURL={`${url}/${EnumPaths.USERS}/${EnumPaths.ENTERPRISE}`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "feature.enterprise") && (
            <Route
              exact
              path={`${url}/${EnumPaths.USERS}/${EnumPaths.SPACES}`}
              component={customUsersTwoColLayout(
                withTracker(props => <ListSpaces {...props} />)
              )}
            />
          )}
          {isDisplayable(configuration, "feature.enterprise") && (
            <Route
              exact
              path={`${url}/${EnumPaths.USERS}/${EnumPaths.SPACES}/:id/${EnumPaths.CREATION_MODE}`}
              component={withTracker(props => (
                <CreatePrivatization
                  {...props}
                  backURL={`${url}/${EnumPaths.USERS}/${EnumPaths.SPACES}`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "feature.enterprise") && (
            <Route
              exact
              path={`${url}/${EnumPaths.USERS}/${EnumPaths.SPACES}/:id/:privatizationId/${EnumPaths.EDIT_MODE}`}
              component={withTracker(props => (
                <UpdatePrivatization
                  {...props}
                  backURL={`${url}/${EnumPaths.USERS}/${EnumPaths.SPACES}`}
                />
              ))}
            />
          )}
          {/* END - ENTERPRISES */}

          {/* START - INDICATORS */}
          {isDisplayable(configuration, "header.indicators", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.INDICATORS_USERS}`}
              component={customIndicatorsTwoColLayout(
                withTracker(UsersDashboard)
              )}
            />
          )}
          {isDisplayable(configuration, "header.indicators", me?.role) && (
            <Route
              exact
              path={`${url}/${EnumPaths.INDICATORS_GLOBAL}`}
              component={customIndicatorsTwoColLayout(
                withTracker(GlobalDashboard)
              )}
            />
          )}
          {/* END - INDICATORS */}

          {/* START - GENERAL */}
          {isDisplayable(
            configuration,
            "sidebar.menu.building.informations"
          ) && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.INFORMATIONS}`}
              component={withTwoColLayout(
                withTracker(props => <Informations {...props} />),
                <PageSubheader title="page.center.info.title" />
              )}
            />
          )}
          {/* END - GENERAL */}

          {/* START - DOCUMENTS */}
          {isDisplayable(configuration, "sidebar.menu.building.documents") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.DOCUMENTS}`}
              component={withTwoColLayout(withTracker(ListDocuments))}
            />
          )}

          {/* START - NOTIFICATIONS */}
          {isDisplayable(
            configuration,
            "sidebar.menu.building.notifications"
          ) && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.NOTIFICATIONS}`}
              component={withTwoColLayout(withTracker(ListNotifications))}
            />
          )}
          {isDisplayable(
            configuration,
            "sidebar.menu.building.notifications"
          ) && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.NOTIFICATIONS}/${EnumPaths.CREATION_MODE}`}
              component={withTracker(props => (
                <NotificationCreate
                  {...props}
                  backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                    EnumPaths.NOTIFICATIONS
                  }`}
                />
              ))}
            />
          )}
          {isDisplayable(
            configuration,
            "sidebar.menu.building.notifications"
          ) && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${
                EnumPaths.NOTIFICATIONS
              }/${EnumNotificationType.NEWS.toLocaleLowerCase()}/:id/${
                EnumPaths.CREATION_MODE
              }`}
              component={withTracker(props => (
                <NotificationCreate
                  {...props}
                  notificationType={EnumNotificationType.NEWS}
                  backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                    EnumPaths.MARKETING
                  }/${EnumPaths.NEWS}`}
                />
              ))}
            />
          )}
          {isDisplayable(
            configuration,
            "sidebar.menu.building.notifications"
          ) && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${
                EnumPaths.NOTIFICATIONS
              }/${EnumNotificationType.EVENT.toLocaleLowerCase()}/:id/${
                EnumPaths.CREATION_MODE
              }`}
              component={withTracker(props => (
                <NotificationCreate
                  {...props}
                  notificationType={EnumNotificationType.EVENT}
                  backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                    EnumPaths.MARKETING
                  }/${EnumPaths.EVENTS}`}
                />
              ))}
            />
          )}
          {/* END - NOTIFICATIONS */}

          {/* START - MARKETING */}
          {isDisplayable(configuration, "sidebar.menu.communicate.news") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.MARKETING}/${EnumPaths.NEWS}`}
              component={withTwoColLayout(withTracker(ListNews))}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.communicate.news") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.MARKETING}/${EnumPaths.NEWS}/${EnumPaths.CREATION_MODE}`}
              component={withTracker(props => (
                <NewsCreation
                  {...props}
                  backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                    EnumPaths.MARKETING
                  }/${EnumPaths.NEWS}`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.communicate.news") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.MARKETING}/${EnumPaths.NEWS}/:id/${EnumPaths.EDIT_MODE}`}
              component={withTracker(props => (
                <NewsUpdate
                  {...props}
                  backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                    EnumPaths.MARKETING
                  }/${EnumPaths.NEWS}`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.communicate.events") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.MARKETING}/${EnumPaths.EVENTS}`}
              component={withTwoColLayout(withTracker(ListEvents))}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.communicate.events") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.MARKETING}/${EnumPaths.EVENTS}/${EnumPaths.CREATION_MODE}`}
              component={withTracker(props => (
                <EventCreate
                  {...props}
                  backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                    EnumPaths.MARKETING
                  }/${EnumPaths.EVENTS}`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.communicate.events") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.MARKETING}/${EnumPaths.EVENTS}/:id/${EnumPaths.EDIT_MODE}`}
              component={withTracker(props => (
                <EventUpdate
                  {...props}
                  backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                    EnumPaths.MARKETING
                  }/${EnumPaths.EVENTS}`}
                />
              ))}
            />
          )}
          {/* END - MARKETING */}

          {/* START - MANAGEMENT : DASHBOARDS */}
          {isDisplayable(
            configuration,
            "sidebar.menu.management.dashboards"
          ) && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.DASHBOARD}`}
              component={withTwoColLayout(
                withTracker(Dashboard),
                <PageSubheader title="page.dashboards.title" />
              )}
            />
          )}
          {isDisplayable(
            configuration,
            "feature.dashboards.realtime_occupancy"
          ) && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.DASHBOARD}/realtime/${EnumPaths.OCCUPANCY}`}
              component={withTwoColLayout(
                withTracker(props => (
                  <RealTimeAssetDashboard
                    {...props}
                    backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                      EnumPaths.DASHBOARD
                    }`}
                  />
                ))
              )}
            />
          )}
          {isDisplayable(
            configuration,
            "feature.dashboards.realtime_sales"
          ) && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.DASHBOARD}/realtime/${EnumPaths.SALES}`}
              component={withTwoColLayout(
                withTracker(props => (
                  <RealTimeSalesDashboard
                    {...props}
                    backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                      EnumPaths.DASHBOARD
                    }`}
                  />
                ))
              )}
            />
          )}
          {isDisplayable(
            configuration,
            "feature.dashboards.realtime_incidents"
          ) && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.DASHBOARD}/realtime/${EnumPaths.INCIDENTS}`}
              component={withTwoColLayout(
                withTracker(props => (
                  <RealTimeIncidentsDashboard
                    {...props}
                    backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                      EnumPaths.DASHBOARD
                    }`}
                  />
                ))
              )}
            />
          )}
          {isDisplayable(configuration, "feature.dashboards.occupancy") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.DASHBOARD}/${EnumPaths.OCCUPANCY}`}
              component={withTwoColLayout(
                withTracker(props => (
                  <AssetDashboard
                    {...props}
                    backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                      EnumPaths.DASHBOARD
                    }`}
                  />
                ))
              )}
            />
          )}
          {isDisplayable(configuration, "feature.dashboards.sales") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.DASHBOARD}/${EnumPaths.SALES}`}
              component={withTwoColLayout(
                withTracker(props => (
                  <SalesDashboard
                    {...props}
                    backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                      EnumPaths.DASHBOARD
                    }`}
                  />
                ))
              )}
            />
          )}
          {isDisplayable(configuration, "feature.dashboards.incidents") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.DASHBOARD}/${EnumPaths.INCIDENTS}`}
              component={withTwoColLayout(
                withTracker(props => (
                  <IncidentDashboard
                    {...props}
                    backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                      EnumPaths.DASHBOARD
                    }`}
                  />
                ))
              )}
            />
          )}
          {/* END - MANAGEMENT : DASHBOARDS */}

          {/* START - MANAGEMENT : CALENDAR */}
          {isDisplayable(configuration, "sidebar.menu.management.calendar") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.CALENDAR}`}
              component={withTwoColLayout(
                withTracker(CalendarScreen),
                <PageSubheader title="page.calendar.title" />
              )}
            />
          )}
          {/* END - MANAGEMENT : CALENDAR */}

          {/* START - MANAGEMENT : INCIDENTS */}
          {isDisplayable(
            configuration,
            "sidebar.menu.management.incidents"
          ) && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.INCIDENTS}`}
              component={withTracker(ListIncidents)}
            />
          )}
          {isDisplayable(
            configuration,
            "sidebar.menu.management.incidents"
          ) && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.INCIDENTS}/:id/${EnumPaths.EDIT_MODE}`}
              component={withTracker(props => (
                <DetailIncident
                  {...props}
                  backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                    EnumPaths.INCIDENTS
                  }`}
                />
              ))}
            />
          )}
          {/* END - MANAGEMENT : INCIDENTS */}

          {/* START - MANAGEMENT : VISITS */}
          {isDisplayable(configuration, "sidebar.menu.management.visits") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.VISITS}`}
              component={withTracker(ListVisits)}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.management.visits") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.VISITORS}`}
              component={withTracker(props => (
                <ListVisitors
                  {...props}
                  backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                    EnumPaths.VISITS
                  }`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.management.visits") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.VISITS}/:id/details`}
              component={withTracker(props => (
                <DetailVisit
                  {...props}
                  backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                    EnumPaths.VISITS
                  }`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.management.visits") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.VISITS}/${EnumPaths.CREATION_MODE}`}
              component={withTracker(props => (
                <VisitCreate
                  {...props}
                  backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                    EnumPaths.VISITS
                  }`}
                />
              ))}
            />
          )}
          {/* END - MANAGEMENT : VISITS */}

          {/* START - MANAGEMENT : ORDERS */}
          {isDisplayable(configuration, "sidebar.menu.management.orders") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.ORDERS}/:id`}
              component={withTwoColLayout(
                withTracker(props => (
                  <OrderUpdate
                    {...props}
                    backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                      EnumPaths.GLOBAL_ORDERS
                    }`}
                  />
                ))
              )}
            />
          )}
          {/* END - MANAGEMENT : ORDERS */}

          {/* START - MANAGEMENT : GLOBAL_ORDERS */}
          {isDisplayable(configuration, "sidebar.menu.management.orders") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.GLOBAL_ORDERS}`}
              component={withTwoColLayout(withTracker(ListGlobalOrders))}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.management.orders") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.GLOBAL_ORDERS}/:id`}
              component={withTwoColLayout(
                withTracker(props => (
                  <GlobalOrderUpdate
                    {...props}
                    backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                      EnumPaths.GLOBAL_ORDERS
                    }`}
                  />
                ))
              )}
            />
          )}
          {/* END - MANAGEMENT : GLOBAL_ORDERS */}

          {/* START - MANAGEMENT : BOOKINGS */}
          {isDisplayable(configuration, "sidebar.menu.management.bookings") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.BOOKINGS}`}
              component={withTwoColLayout(withTracker(ListBookings))}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.management.bookings") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.BOOKINGS}/${EnumPaths.BOOK_AVAILABILITY}`}
              component={withTwoColLayout(
                withTracker(props => (
                  <ListBookingProducts
                    {...props}
                    backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                      EnumPaths.BOOKINGS
                    }`}
                  />
                ))
              )}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.management.bookings") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.BOOKINGS}/${EnumPaths.BOOK_AVAILABILITY}/:pid/${EnumPaths.BOOK}`}
              component={withTwoColLayout(
                withTracker(props => (
                  <BookingCreate
                    {...props}
                    backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                      EnumPaths.BOOKINGS
                    }/${EnumPaths.BOOK_AVAILABILITY}`}
                  />
                ))
              )}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.management.bookings") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.CARTS}/:cartId/${EnumPaths.OPTIONS}`}
              component={withTwoColLayout(
                withTracker(props => <BookingOptions {...props} />)
              )}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.management.bookings") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.CARTS}/:cartId/${EnumPaths.SUMMARY}`}
              component={withTwoColLayout(
                withTracker(props => <BookingSummary {...props} />)
              )}
            />
          )}
          {/* END - MANAGEMENT : BOOKINGS */}

          {/* START - SERVICES */}
          {isDisplayable(configuration, "sidebar.menu.catalog.services") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.SERVICES}`}
              component={withTwoColLayout(
                withTracker(props => (
                  <ListServices
                    {...props}
                    serviceType={EnumServiceType.SERVICE}
                    categoryType={EnumCategoryType.SERVICE}
                  />
                ))
              )}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.catalog.services") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.SERVICES}/${EnumPaths.CREATION_MODE}`}
              component={withTracker(props => (
                <ServiceCreate
                  {...props}
                  serviceType={EnumServiceType.SERVICE}
                  backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                    EnumPaths.SERVICES
                  }`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.catalog.services") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.SERVICES}/:id/${EnumPaths.EDIT_MODE}`}
              component={withTracker(props => (
                <ServiceUpdate
                  {...props}
                  serviceType={EnumServiceType.SERVICE}
                  backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                    EnumPaths.SERVICES
                  }/:id`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.catalog.services") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.SERVICES}/:id`}
              component={withTwoColLayout(
                withTracker(props => (
                  <ListProducts
                    {...props}
                    serviceType={EnumServiceType.SERVICE}
                  />
                ))
              )}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.catalog.services") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.SERVICES}/:id/${EnumPaths.PRODUCTS}/${EnumPaths.CREATION_MODE}`}
              component={withTracker(props => (
                <ProductCreate
                  {...props}
                  serviceType={EnumServiceType.SERVICE}
                  backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                    EnumPaths.SERVICES
                  }/:id`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.catalog.services") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.SERVICES}/:id/${EnumPaths.PRODUCTS}/:pid/${EnumPaths.EDIT_MODE}`}
              component={withTracker(props => (
                <ProductUpdate
                  {...props}
                  serviceType={EnumServiceType.SERVICE}
                  backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                    EnumPaths.SERVICES
                  }/:id`}
                />
              ))}
            />
          )}
          {/* END - SERVICES */}

          {/* START - SPACES */}
          {isDisplayable(configuration, "sidebar.menu.catalog.workspaces") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.SPACES}`}
              component={withTwoColLayout(
                withTracker(props => (
                  <ListProducts
                    {...props}
                    serviceType={EnumServiceType.SPACE}
                  />
                ))
              )}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.catalog.workspaces") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.SPACES}/${EnumPaths.PRODUCTS}/${EnumPaths.CREATION_MODE}`}
              component={withTracker(props => (
                <ProductCreate
                  {...props}
                  serviceType={EnumServiceType.SPACE}
                  backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                    EnumPaths.SPACES
                  }`}
                />
              ))}
            />
          )}
          {isDisplayable(configuration, "sidebar.menu.catalog.workspaces") && (
            <Route
              exact
              path={`${url}/${EnumPaths.CENTERS}/:cid/${EnumPaths.SPACES}/${EnumPaths.PRODUCTS}/:pid/${EnumPaths.EDIT_MODE}`}
              component={withTracker(props => (
                <ProductUpdate
                  {...props}
                  serviceType={EnumServiceType.SPACE}
                  backURL={`${url}/${EnumPaths.CENTERS}/${handleGetCid()}/${
                    EnumPaths.SPACES
                  }`}
                />
              ))}
            />
          )}
          {/* END - SPACES */}

          <Redirect to={`/${EnumPaths.ERROR}`} />
        </Switch>
      </>
    )
  );
};

export default withRouter(MainApp);
