import React from "react";
import Table from "../../../../../components/Table";
import { getTranslation } from "../../../../../utils/translation";
import { getDefaultImage, getImageUrl } from "../../../../../utils/picture";
import { CartItem } from "../../../../../lib/ground-aws-graphql-core/models/Api/Cart";
import { getFormattedPrice } from "../../../../../utils/price-unit";
import IntlMessages from "../../../../../utils/messages";
import images from "../../../../../images";

interface Props {
  consumableCartItems: CartItem[];
  handleDelete: (cartItemId: string) => void;
  handleUpdate: (cartItemId: string, quantity: number) => void;
}

const ConsumablesTable = (props: Props): JSX.Element => {
  const { consumableCartItems, handleDelete, handleUpdate } = props;

  const consumablesTableHead = [
    "general.services.consumables",
    "general.quantity",
    "general.unit.price",
    "general.total.ht",
    "general.vat",
    "general.total.ttc",
  ];

  const consumablesTableBody = consumableCartItems?.map(consumableCartItem => {
    const {
      id,
      quantity,
      price,
      currency,
      vat,
      total_tax: totalTax,
      total_price: totalPrice,
    } = consumableCartItem;
    const { name, pictures, stock } = consumableCartItem.product;

    const consumableProductName = getTranslation(name);

    const picture = pictures && pictures.length > 0 ? pictures[0] : null;

    return {
      rowElements: [
        {
          element: (
            <div className="flex flew-row items-center space-x-4">
              <img
                id={`consumable_cart_item_delete_${id}`}
                data-cy={`consumable_cart_item_delete_${id}`}
                src={images.trash}
                alt="trash"
                className="h-6 w-6 cursor-pointer bg-white border border-red-500 p-1 rounded-5"
                onClick={() => handleDelete(id)}
              />
              <img
                alt={consumableProductName}
                src={
                  picture
                    ? getImageUrl(null, picture, 50, 50)
                    : getDefaultImage(null)
                }
                style={picture ? undefined : { width: 50, height: 50 }}
                className="list-thumbnail responsive border-0"
              />

              <div data-cy={`consumable-cart-item-${id}`}>
                {consumableProductName}
              </div>
            </div>
          ),
        },
        {
          element: (
            <input
              id={`consumable_cart_item_quantity_${id}`}
              type="number"
              data-cy={`consumable_cart_item_quantity_${id}`}
              min={1}
              max={stock}
              step={1}
              defaultValue={quantity}
              className="form-input w-16 sm:text-14px sm:leading-5"
              onBlur={e => handleUpdate(id, parseInt(e.target.value, 10))}
            />
          ),
        },
        {
          element: (
            <span>
              {getFormattedPrice(price, currency)}{" "}
              <IntlMessages id="general.ht" />
            </span>
          ),
        },
        {
          element:
            totalPrice > 0 ? getFormattedPrice(totalPrice, currency) : null,
        },
        {
          element:
            totalTax > 0
              ? `${getFormattedPrice(totalTax, currency)} (${vat} %)`
              : null,
        },
        {
          element:
            totalPrice + totalTax > 0 ? (
              getFormattedPrice(totalPrice + totalTax, currency)
            ) : (
              <IntlMessages id="general.free" />
            ),
        },
      ],
    };
  });

  return (
    <Table
      head={consumablesTableHead}
      body={consumablesTableBody}
      noDataText="general.list.empty"
      className="flex flex-1"
    />
  );
};

export default ConsumablesTable;
