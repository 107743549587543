/*
 *
 * Order Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import { GetOrderQuery } from "../../api/graphql/types";

import { getOrder as getOrderQuery } from "../../api/graphql/queries";

import { fetchPolicy } from "../../api/client";

export const getOrder = payload => {
  const request = payload.gql ? payload.gql : getOrderQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: { id: payload.id },
    fetchPolicy,
  }) as {
    data: GetOrderQuery;
  };
};
