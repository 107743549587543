import React, { useState, useEffect } from "react";
import { match as Match } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import history from "../../../../history";
import {
  navTo,
  EnumPaths,
  getSpecificIdFromUrl,
} from "../../../../utils/navigation";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import Table, { ALL_FILTER, IFilterField } from "../../../../components/Table";
import { getDefaultImage, getImageUrl } from "../../../../utils/picture";
import { getTranslation } from "../../../../utils/translation";
import {
  EnumNotificationType,
  EnumParticipation,
  EnumPermissionEntity,
  SearchableEventSortableFields,
  SearchableSortDirection,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import Dropdown from "../../../../components/Tailwind/Dropdown";
import ConfirmModal from "../../../../utils/modal/confirm";
import IntlMessages from "../../../../utils/messages";
import { EnumFilterFields, getSortFields } from "../../../../utils/filter";
import contextStore from "../../../../redux/store";
import { getLocale } from "../../../../lang";
import PageSubheader from "../../../../components/PageSubheader";
import images from "../../../../images";
import {
  displayDayDDMMYYYY_HHMM,
  formatISODateFn,
  parseISODateFn,
} from "../../../../utils/config";

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
}

const LIMIT = 20;

export const EnumEventStatus = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
};

const ListEvents = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [eventToDeleteId, setEventToDeleteId] = useState<string | null>(null);

  const { match, intl } = props;

  const searchEvents = GroundGraphqlContextStore.useStoreActions(
    actions => actions.event.searchEvents
  );

  const setEvent = GroundGraphqlContextStore.useStoreActions(
    actions => actions.event.setEvent
  );

  const events = GroundGraphqlContextStore.useStoreState(
    state => state.event.events.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.event.events.total
  );

  const deleteEventAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.event.deleteEvent
  );

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (
    pageIndex = 0,
    loader = true,
    filters?: IFilterField<any>[],
    sort?: {
      field: string;
      direction: SearchableSortDirection;
    }
  ) => {
    if (loader) {
      setLoading(true);
    }
    let filter;
    if (filters?.length) {
      filters.forEach(f => {
        if (f.type === EnumFilterFields[EnumFilterFields.ENABLED]) {
          const value = f.value?.value === EnumEventStatus.ENABLED;
          if (f.value?.value !== ALL_FILTER) {
            filter = {
              ...filter,
              enabled: {
                eq: value,
              },
            };
          }
        }
        if (f.type === EnumFilterFields[EnumFilterFields.TITLE]) {
          if (f.value) {
            filter = {
              ...filter,
              title: { matchPhrasePrefix: f.value.toLowerCase() },
            };
          }
        }
        if (f.type === EnumFilterFields[EnumFilterFields.START_DATE]) {
          if (f.value) {
            const start = parseISODateFn(f.value);
            start.setHours(0, 0, 0, 0);
            filter = {
              ...filter,
              start: { gte: formatISODateFn(start) },
            };
          }
        }
        if (f.type === EnumFilterFields[EnumFilterFields.END_DATE]) {
          if (f.value) {
            const end = parseISODateFn(f.value);
            end.setHours(0, 0, 0, 0);
            filter = {
              ...filter,
              end: { lte: formatISODateFn(end) },
            };
          }
        }
      });
    }

    const defaultSort = getSortFields(EnumPermissionEntity.EVENT)[0];
    const defaultSortField = SearchableEventSortableFields[defaultSort.field];
    const defaultSortDirection = defaultSort.directions[0];
    const defautSort = {
      field: defaultSortField,
      direction: defaultSortDirection,
    };
    searchEvents({
      limit: LIMIT,
      filter: {
        ...filter,
        centerEventsId: { eq: match.params.cid },
      },
      from: pageIndex * LIMIT,
      locale: currentAppLocale.backend_locale,
      sort: sort ? { ...sort } : defautSort,
    }).finally(() => setLoading(false));
  };

  const handleEditItem = (id: string) => {
    const centerId = match.params.cid;
    history.push(
      navTo(
        `${EnumPaths.MARKETING}/${EnumPaths.EVENTS}/${id}/${EnumPaths.EDIT_MODE}`,
        centerId
      )
    );
  };

  const handleNotify = (id: string) => {
    const centerId = match.params.cid;

    localStorage.setItem("parentNavItem", "general");
    localStorage.setItem("navItem", "notifications");

    history.push(
      navTo(
        `${
          EnumPaths.NOTIFICATIONS
        }/${EnumNotificationType.EVENT.toLocaleLowerCase()}/${id}/${
          EnumPaths.CREATION_MODE
        }`,
        centerId
      )
    );
  };

  const handleAddNewEvent = () => {
    const centerId = getSpecificIdFromUrl(
      history.location.pathname,
      "centers/"
    );

    if (centerId) {
      setEvent(null);
      history.push(
        navTo(
          `${EnumPaths.MARKETING}/${EnumPaths.EVENTS}/${EnumPaths.CREATION_MODE}`,
          centerId
        )
      );
    }
  };

  const handleDelete = () => {
    if (eventToDeleteId) {
      setLoading(true);
      setShowDeleteModal(false);
      deleteEventAction({ id: eventToDeleteId })
        .then(() => {
          fetchData();
          toast(
            intl.formatMessage({
              id: "general.event.delete",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          toast(
            intl.formatMessage({
              id: "general.event.delete",
            }),
            {
              type: "error",
            }
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const eventsTableHead = [
    "general.title",
    "general.category",
    "general.author",
    "general.startdate",
    "general.enddate",
    "page.list.events.table.head.participants",
    "general.actions",
  ];

  const eventsTableBody = events
    ?.filter(n => !!n)
    .map(event => {
      const { id, picture, title, type, author, start, end, participations } =
        event;
      const eventTitle = getTranslation(title);
      const participants = participations?.items?.filter(
        e => e.value === EnumParticipation.YES
      );

      const dropdownValues = [
        {
          id: "edit_event",
          label: "general.edit",
          icon: images.edit,
          onClick: () => handleEditItem(id),
        },
        {
          id: "notify",
          label: "general.notify",
          icon: images.notification,
          onClick: () => handleNotify(id),
        },
        {
          id: "delete_event",
          label: "general.delete",
          icon: images.deleteIcon,
          onClick: () => {
            setShowDeleteModal(!showDeleteModal);
            setEventToDeleteId(id);
          },
        },
      ];

      return {
        rowElements: [
          {
            element: (
              <div className="flex flew-row items-center">
                <div>
                  <img
                    alt={eventTitle}
                    src={
                      picture
                        ? getImageUrl(null, picture, 50, 50)
                        : getDefaultImage(null)
                    }
                    style={{ width: 50, height: 50 }}
                    className="list-thumbnail responsive border-0"
                  />
                </div>
                <div className="pl-2">
                  <span className="block" data-cy={`event-title-${id}`}>
                    {eventTitle}
                  </span>
                </div>
              </div>
            ),
            onCellClick: () => handleEditItem(id),
          },
          {
            element: getTranslation(type?.type),
            onCellClick: () => handleEditItem(id),
          },
          {
            element: `${author?.lastname} ${author?.firstname}`,
            onCellClick: () => handleEditItem(id),
          },
          {
            element: displayDayDDMMYYYY_HHMM(start, centerTimezone),
            onCellClick: () => handleEditItem(id),
          },
          {
            element: displayDayDDMMYYYY_HHMM(end, centerTimezone),
            onCellClick: () => handleEditItem(id),
          },
          {
            element: participants?.length,
            onCellClick: () => handleEditItem(id),
          },
          {
            element: (
              <Dropdown
                values={dropdownValues}
                dataCy={`event-dropdown-${id}`}
              />
            ),
          },
        ],
      };
    });

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: "page.list.events.document.title" })}
        </title>
      </Helmet>

      <PageSubheader
        title="page.list.events.title"
        nbOfResults={total}
        buttonRightTitle="page.list.events.create.event"
        buttonRightAction={handleAddNewEvent}
        buttonRightId="btn-add-event"
      />

      <Table
        permissionEntity={EnumPermissionEntity.EVENT}
        head={eventsTableHead}
        body={eventsTableBody}
        onChange={({ pageIndex, filters, sort }) =>
          fetchData(pageIndex, false, filters, sort)
        }
        loading={loading}
        setLoading={setLoading}
        paginationTotal={total}
        useFilterBlock
        filterBlockTitle="page.list.events.filter.title"
        noDataText="page.list.events.empty"
        className="px-8"
      />

      <ConfirmModal
        isOpen={showDeleteModal}
        toggle={() => setShowDeleteModal(!showDeleteModal)}
        onRequestClose={() => setShowDeleteModal(!showDeleteModal)}
        handleConfirm={handleDelete}
        content={<IntlMessages id="page.event.delete.event" />}
      />
    </>
  );
};

export default injectIntl(ListEvents);
