import React from "react";
import { match as Match } from "react-router-dom";
import { injectIntl, IntlShape } from "react-intl";
import images from "../../../../../../images";
import RealTimeSalesCard from "../sales-card";
import { EnumReportingType } from "../../../../../../lib/ground-aws-graphql-core/models/Product";

interface Props {
  intl: IntlShape;
  match: Match<{ cid: string }>;
  totals: {
    sales: number;
    cancellations: number;
    refunds: number;
  };
}

interface SalesCardInterface {
  key: string;
  label: string;
  icon: any;
  price: number;
}

const RealTimeSalesCardList = (props: Props) => {
  const { intl, totals } = props;

  const getSalesCards = (intl: IntlShape): SalesCardInterface[] => [
    {
      key: EnumReportingType.SALES,
      label: intl.formatMessage({
        id: "page.dashboards.chart.week.total.sales.title",
      }),
      icon: images.cash,
      price: totals.sales,
    },
    {
      key: EnumReportingType.CANCELLATION,
      label: intl.formatMessage({
        id: "page.dashboards.chart.week.total.cancellations.title",
      }),
      icon: images.cash,
      price: totals.cancellations,
    },
    {
      key: EnumReportingType.REFUND,
      label: intl.formatMessage({
        id: "page.dashboards.chart.week.total.refunds.title",
      }),
      icon: images.cash,
      price: totals.refunds,
    },
  ];

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4">
      {getSalesCards(intl).map(e => {
        return (
          <div id={e.key} data-cy={e.key} data-testid={e.key} key={e.key}>
            <RealTimeSalesCard
              label={e.label}
              icon={e.icon}
              price={e.price}
              selected={false}
            />
          </div>
        );
      })}
    </div>
  );
};

export default injectIntl(RealTimeSalesCardList);
