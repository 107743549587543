import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ArcElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  ChartData,
} from "chart.js";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import overrideClasses from "../../../../../utils/overrideClasses";
import { FilterCalendar } from "../../../../../components/Tailwind/Filters";
import {
  EnumCalendarType,
  EnumBookableTypology,
} from "../../../../../lib/ground-aws-graphql-core/models/Product";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import contextStore from "../../../../../redux/store";
import { getLocale } from "../../../../../lang";
import PageSubheader from "../../../../../components/PageSubheader";
import { getTranslation } from "../../../../../utils/translation";
import {
  getTodayInCenterTimezone,
  getCurrentWeekInCenterTimezone,
  getWeekInCenterTimezone,
  getPreviousWeek,
  getNextWeek,
  eachUnitOfInterval,
  transformDateForQuery,
  displayDayName,
} from "../../../../../utils/config";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler
);

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
  backURL: string;
}

enum EnumBackGroundColorTypology {
  ALL_SPACES = "rgba(153, 102, 255, 0.5)",
  COWORKING = "rgba(255, 99, 132, 0.5)",
  MEETING_ROOM = "rgba(53, 162, 235, 0.5)",
  OFFICE = "rgba(54, 192, 0, 0.5)",
  PARKING = "rgba(255, 150, 0, 0.5)",
  SERVICED_SPACE = "rgba(36, 36, 36, 0.5)",
  CONSOMMABLE = "rgba(153, 102, 255, 0.5)",
}

enum EnumBorderColorTypology {
  ALL_SPACES = "rgba(153, 102, 255)",
  COWORKING = "rgba(255, 99, 132)",
  MEETING_ROOM = "rgba(53, 162, 235)",
  OFFICE = "rgba(54, 192, 0)",
  PARKING = "rgba(255, 150, 0)",
  SERVICED_SPACE = "rgba(36, 36, 36)",
  CONSOMMABLE = "rgba(153, 102, 255)",
}

const backGroundColorSpaces = [
  "rgba(255, 99, 132, 0.2)",
  "rgba(54, 162, 235, 0.2)",
  "rgba(255, 206, 86, 0.2)",
  "rgba(75, 192, 192, 0.2)",
  "rgba(153, 102, 255, 0.2)",
];

const borderColorSpaces = [
  "rgba(255, 99, 132, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)",
];

const TOP_SPACES_NUMBER = 5;

const RealTimeAssetDashboard = (props: Props): JSX.Element => {
  const { intl, match, backURL } = props;
  const [loading, setLoading] = useState(false);

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const now = getTodayInCenterTimezone(centerTimezone);
  const { startWeek, endWeek } = getCurrentWeekInCenterTimezone(centerTimezone);
  const [startDate, setStartDate] = useState(startWeek);
  const [endDate, setEndDate] = useState(endWeek);

  const [date, setDate] = useState(now);

  const listAssetReportings = GroundGraphqlContextStore.useStoreActions(
    actions => actions.reporting.listAssetReportings
  );

  const assetReportings = GroundGraphqlContextStore.useStoreState(
    state => state.reporting.assetReportings.items
  );

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  const days = eachUnitOfInterval(startDate, endDate, "day");

  const dayNames = days.map(v =>
    displayDayName(v, currentAppLocale, centerTimezone).toUpperCase()
  );

  const typologies = [
    ...new Set(
      assetReportings.reduce((acc: EnumBookableTypology[], el) => {
        const typos = el.data.map(r => r.typology);

        return acc.concat(typos);
      }, [])
    ),
  ];

  const assetDatasets = typologies.map(typology => {
    // reportings for typology
    const reportingsForTypology = assetReportings
      .map(r => r.data.find(r => r.typology === typology))
      .filter(item => !!item);

    const dataOccupancyRate = reportingsForTypology.map(item =>
      item ? item.indicators.occupancy_rate * 100 : 0
    );

    const dataBookings = reportingsForTypology.map(item =>
      item ? item.indicators.bookings.length : 0
    );

    const datasets = {
      label: intl.formatMessage({ id: `page.product.typology.${typology}` }),
      backgroundColor: EnumBackGroundColorTypology[typology],
      borderColor: EnumBorderColorTypology[typology],
      borderWidth: 1,
    };

    return {
      occupancy_rate: {
        datasets: {
          ...datasets,
          data: dataOccupancyRate,
        },
      },
      bookings: {
        datasets: {
          ...datasets,
          data: dataBookings,
        },
      },
    };
  });

  const weekDatasets = days.reduce(
    (
      acc: {
        mostPopulars: {
          date: Date;
          data: ChartData<"doughnut">;
        }[];
        lessPopulars: {
          date: Date;
          data: ChartData<"bar">;
        }[];
      },
      day
    ) => {
      const date = transformDateForQuery(day, centerTimezone);
      const assetForDay = assetReportings.find(a => a.date === date);

      if (assetForDay) {
        const mostPopulars = assetForDay.indicators.spaces
          .filter(s => s.indicators.occupancy_rate > 0)
          .slice(0, TOP_SPACES_NUMBER);

        const lessPopulars = assetForDay.indicators.spaces.slice(
          -TOP_SPACES_NUMBER
        );

        if (mostPopulars.length > 0) {
          const labels = mostPopulars.map(e => getTranslation(e.space.name));

          const results = mostPopulars.map((e, index) => ({
            occupancyRate: e.indicators.occupancy_rate * 100,
            backgroundColor: backGroundColorSpaces[index],
            borderColor: borderColorSpaces[index],
          }));
          const rates = results.map(r => r.occupancyRate);
          const backgroundColors = results.map(r => r.backgroundColor);
          const borderColors = results.map(r => r.borderColor);

          const data: ChartData<"doughnut"> = {
            labels: labels,
            datasets: [
              {
                label: intl.formatMessage({
                  id: "page.calendar.chart.occupancy.rate",
                }),
                data: rates,
                backgroundColor: backgroundColors,
                borderColor: borderColors,
                borderWidth: 0.5,
              },
            ],
          };

          acc.mostPopulars.push({
            date: day,
            data,
          });
        }

        if (lessPopulars.length > 0) {
          const labels = lessPopulars.map(e => getTranslation(e.space.name));

          const results = lessPopulars.map((e, index) => ({
            occupancyRate: 100 - e.indicators.occupancy_rate * 100,
            backgroundColor: backGroundColorSpaces[index],
            borderColor: borderColorSpaces[index],
          }));
          const rates = results.map(r => r.occupancyRate);
          const backgroundColors = results.map(r => r.backgroundColor);
          const borderColors = results.map(r => r.borderColor);

          const data: ChartData<"bar"> = {
            labels: labels,
            datasets: [
              {
                label: intl.formatMessage({
                  id: "page.calendar.chart.occupancy.rate",
                }),
                data: rates,
                backgroundColor: backgroundColors,
                borderColor: borderColors,
                borderWidth: 0.5,
              },
            ],
          };

          acc.lessPopulars.push({
            date: day,
            data,
          });
        }
      }

      return acc;
    },
    {
      mostPopulars: [],
      lessPopulars: [],
    }
  );

  useEffect(() => {
    fetchData();
  }, [startDate]);

  const fetchData = () => {
    setLoading(true);
    Promise.all([
      listAssetReportings({
        filter: {
          buildingId: match.params.cid,
          date: transformDateForQuery(startDate, centerTimezone),
          type: EnumCalendarType.WEEK,
        },
      }),
    ]).finally(() => setLoading(false));
  };

  const onChangeDate = (d: Date) => {
    setDate(d);
    const { startWeek, endWeek } = getWeekInCenterTimezone(d, centerTimezone);
    setStartDate(startWeek);
    setEndDate(endWeek);
  };

  const onPreviousWeek = () => {
    const { startWeek, endWeek } = getPreviousWeek({
      start: startDate,
      end: endDate,
    });

    setStartDate(startWeek);
    setEndDate(endWeek);
    setDate(startWeek);
  };

  const onNextWeek = () => {
    const { startWeek, endWeek } = getNextWeek({
      start: startDate,
      end: endDate,
    });

    setStartDate(startWeek);
    setEndDate(endWeek);
    setDate(startWeek);
  };

  const doughnutOptions = (date: Date): ChartOptions<"doughnut"> => ({
    responsive: true,
    aspectRatio: 1.5,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: intl.formatMessage(
          {
            id: "page.dashboards.chart.most.occupancy.rate.space.title",
          },
          {
            top_number: TOP_SPACES_NUMBER,
            date: displayDayName(date, currentAppLocale, centerTimezone),
          }
        ),
      },
    },
  });

  const barOptions = (date: Date): ChartOptions<"bar"> => ({
    responsive: true,
    indexAxis: "y" as const,
    plugins: {
      title: {
        display: true,
        text: intl.formatMessage(
          {
            id: "page.dashboards.chart.less.occupancy.rate.space.title",
          },
          {
            top_number: TOP_SPACES_NUMBER,
            date: displayDayName(date, currentAppLocale, centerTimezone),
          }
        ),
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
    },
  });

  const renderDayMostPopularSpacesOccupancyRate = (
    data: ChartData<"doughnut">,
    date: Date,
    index: number
  ) => {
    return (
      <div key={`doughnut_${index}`}>
        <Doughnut data={data} options={doughnutOptions(date)} />
      </div>
    );
  };

  const renderDayLessPopularSpacesOccupancyRate = (
    data: ChartData<"bar">,
    date: Date,
    index: number
  ) => {
    return (
      <div key={`bar_${index}`}>
        <Bar data={data} options={barOptions(date)} />
      </div>
    );
  };

  return (
    <>
      <div className={overrideClasses({ loading })} />

      <div className={overrideClasses({ hidden: loading })}>
        <Helmet>
          <title>
            {intl.formatMessage({ id: "page.dashboards.document.title" })}
          </title>
        </Helmet>

        <PageSubheader
          goBackEnabled
          goBackId={`btn-back-occupancy-reporting`}
          goBackURL={backURL}
        />

        <div className="flex items-center gap-4 px-8">
          <FilterCalendar
            date={date}
            locale={currentAppLocale}
            onChangeDate={onChangeDate}
            onPrevious={onPreviousWeek}
            onNext={onNextWeek}
          />
        </div>

        <div className="flex px-8 my-8">
          <div className="w-1/2">
            <Bar
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top" as const,
                  },
                  title: {
                    display: true,
                    text: intl.formatMessage({
                      id: "page.dashboards.chart.occupancy.rate.title",
                    }),
                  },
                },
                scales: {
                  y: {
                    suggestedMax: 100,
                    suggestedMin: 0,
                  },
                },
              }}
              data={{
                labels: dayNames,
                datasets: assetDatasets.flatMap(o => o.occupancy_rate.datasets),
              }}
            />
          </div>
          <div className="w-1/2">
            <Line
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top" as const,
                  },
                  title: {
                    display: true,
                    text: intl.formatMessage({
                      id: "page.dashboards.chart.bookings.title",
                    }),
                  },
                },
                scales: {
                  y: {
                    suggestedMin: 0,
                  },
                },
              }}
              data={{
                labels: dayNames,
                datasets: assetDatasets.flatMap(o => o.bookings.datasets),
              }}
            />
          </div>
        </div>
        {weekDatasets.mostPopulars.length > 0 && (
          <div className="my-8 sm:grid sm:grid-cols-3 sm:gap-4">
            {weekDatasets.mostPopulars.map((e, index) =>
              renderDayMostPopularSpacesOccupancyRate(e.data, e.date, index)
            )}
          </div>
        )}

        {weekDatasets.lessPopulars.length > 0 && (
          <div className="my-8 sm:grid sm:grid-cols-3 sm:gap-4">
            {weekDatasets.lessPopulars.map((e, index) =>
              renderDayLessPopularSpacesOccupancyRate(e.data, e.date, index)
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default injectIntl(RealTimeAssetDashboard);
