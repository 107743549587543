import { createContextStore, persist } from "easy-peasy";
import { storeModel } from "../models";
import * as AuthenticationService from "../services/Authentication";

export interface Injections {
  authenticationService: typeof AuthenticationService;
}

export const contextStorePersist = storage =>
  createContextStore(
    persist(storeModel, {
      storage: storage,
    }),
    {
      name: "Ground Authentication Store Persist",
      injections: {
        authenticationService: AuthenticationService,
      },
    }
  );

export const contextStore = createContextStore(storeModel, {
  name: "Ground Authentication Store",
  injections: {
    authenticationService: AuthenticationService,
  },
});
