import { ErrorGraphQL } from "../../services/coreUtils";
import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { Operator } from "../Operator";

import {
  CreateSingleProductInput as CreateSingleProduct,
  DeleteSingleProductInput as DeleteSingleProduct,
} from "../../api/graphql/types";
import { Product } from "../Product";
import { Center } from "../Center";

export interface SingleProduct {
  operator: Operator;
  center: Center;
  id: string;
  product: Product;
}

export interface ModelSingleProductConnection {
  items: [SingleProduct] | null;
  total?: number | null;
}

// Interface declaration
export interface SingleProductModel {
  singleProducts: ModelSingleProductConnection;
  singleProduct: SingleProduct | null;
  setSingleProduct: Action<SingleProductModel, SingleProduct | null>;
  addSingleProduct: Action<SingleProductModel, SingleProduct>;
  removeSingleProduct: Action<SingleProductModel, SingleProduct>;
  createSingleProduct: Thunk<
    SingleProductModel,
    CreateSingleProduct,
    Injections
  >;
  deleteSingleProduct: Thunk<
    SingleProductModel,
    DeleteSingleProduct,
    Injections
  >;
}

export const singleProductModel: SingleProductModel = {
  singleProducts: { items: null, total: null },
  singleProduct: null,

  setSingleProduct: action((state, payload) => {
    state.singleProduct = payload;
  }),

  addSingleProduct: action((state, payload) => {
    if (state.singleProducts.items) {
      state.singleProducts.items.push(payload);
    } else {
      state.singleProducts["items"] = [payload];
    }
  }),

  removeSingleProduct: action((state, payload) => {
    if (state.singleProducts.items) {
      const index = state.singleProducts.items!.findIndex(
        e => e.id === payload.id
      );
      state.singleProducts.items!.splice(index, 1);
    }
  }),

  createSingleProduct: thunk(async (actions, payload, { injections }) => {
    const { singleProductService } = injections;
    const response = await singleProductService.createSingleProduct(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.createSingleProduct;
    actions.setSingleProduct(item);
    actions.addSingleProduct(item);

    return item;
  }),

  deleteSingleProduct: thunk(async (actions, payload, { injections }) => {
    const { singleProductService } = injections;
    const response = await singleProductService.deleteSingleProduct(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.deleteSingleProduct;
    actions.setSingleProduct(item);
    actions.removeSingleProduct(item);

    return item;
  }),
};
