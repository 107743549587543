import React from "react";
import classnames from "classnames";
import { injectIntl, IntlShape } from "react-intl";
import { TagsInput } from "react-tag-input-component";
import IntlMessages from "../../../utils/messages";

interface Props {
  intl: IntlShape;
  name: string;
  value: string | null | undefined;
  label: string;
  index: number;
  last?: boolean;
  required?: boolean;
  onChange: (e) => void;
  errors: any;
  touched: any;
  grid?: number;
  className?: string;
  action?: React.ReactNode;
  useIntl: boolean;
  alternate?: boolean;
  width?: string;
}

const MultipleInput = (props: Props) => {
  const {
    intl,
    alternate,
    value,
    onChange,
    className,
    grid,
    name,
    last,
    errors,
    touched,
    width,
    action,
    index,
    label,
    useIntl,
    required,
  } = props;

  const [tags, setTags] = React.useState<string[]>(value || []);

  const classNameVar = grid ? `sm:grid-cols-${grid}` : "sm:grid-cols-3";

  const containerClassName = (id: number) =>
    name === "nocs"
      ? classnames("sm:grid sm:items-start")
      : classnames(
          `sm:grid sm:gap-4 sm:items-start sm:py-2 sm:px-8 px-2 py-2 ${classNameVar}`,
          className,
          {
            "bg-ground-white-200": alternate && id % 2 === 0,
            "rounded-b-10": last,
          }
        );

  const invalid = errors[name] && touched[name];

  const invalidCss = invalid ? classnames("invalid-multiple-input") : "";

  const labelClassName = classnames(
    "block text-14px font-medium leading-5 text-ground-gray-100 sm:mt-px sm:pt-2 ",
    {
      "sm:col-span-2": grid === 4,
    }
  );

  const inputClassName = classnames(`mt-1 sm:mt-0 ${width || ""}`, {
    "sm:col-span-2": !action,
  });

  return (
    <>
      <div className={containerClassName(index)}>
        {label && (
          <label htmlFor={name} className={labelClassName}>
            {useIntl && <IntlMessages id={label} />}
            {!useIntl && `${label}`} {required && "*"}
          </label>
        )}
        <div className={inputClassName}>
          <div className={`relative ${invalidCss}`}>
            <TagsInput
              value={tags}
              onChange={e => {
                setTags(e);
                onChange(e);
              }}
              classNames={{
                input: "w-full",
              }}
              name="provider_emails"
              placeHolder={intl.formatMessage({
                id: "page.providers.email.placeholder",
              })}
            />
            {invalid && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <svg
                  className="h-5 w-5 text-red-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            )}
          </div>
          {invalid && (
            <p className="mt-2 text-11px text-red-600" id={`${name}-error`}>
              {errors[name]}
            </p>
          )}
        </div>
        {action && <div className="my-auto">{action}</div>}
      </div>
    </>
  );
};

export default injectIntl(MultipleInput);
