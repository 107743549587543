import { ErrorGraphQL } from "./../../services/coreUtils";
import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel, GqlQuery } from "../";
import { BackOfficeUser } from "../BackOfficeUser";
import { Category } from "../Category";
import { Operator } from "../Operator";

import {
  CreateNewsInput as CreateNews,
  UpdateNewsInput as UpdateNews,
  SearchableNewsFilterInput,
  SearchableNewsSortInput,
  EnumLocale,
} from "../../api/graphql/types";

export interface News {
  operator?: Operator;
  id: string;
  title?: any;
  description?: any;
  picture?: string | null;
  author?: BackOfficeUser;
  publicationDate?: string | null;
  unpublicationDate?: string | null;
  authorized?: boolean | null;
  enabled?: boolean;
  markForDelete?: boolean;
  category?: Category;
}

export interface SearchNewsOpts {
  filter: SearchableNewsFilterInput;
  locale?: EnumLocale;
  sort?: SearchableNewsSortInput;
  limit?: number | null;
  from?: number | null;
}

export interface ModelNewsConnection {
  items: [News] | null;
  total?: number | null;
}

// Interface declaration
export interface NewsModel {
  newss: ModelNewsConnection;
  news: News | null;

  setNewss: Action<NewsModel, ModelNewsConnection>;
  setNews: Action<NewsModel, News | null>;
  addNews: Action<NewsModel, News>;
  replaceNews: Action<NewsModel, News>;
  getNews: Thunk<NewsModel, GqlQuery, Injections>;
  createNews: Thunk<NewsModel, CreateNews, Injections>;
  updateNews: Thunk<NewsModel, UpdateNews, Injections>;
  searchNews: Thunk<NewsModel, SearchNewsOpts, Injections, StoreModel>;
}

export const newsModel: NewsModel = {
  newss: { items: null, total: null },
  news: null,

  setNewss: action((state, data: ModelNewsConnection) => {
    state.newss = { items: data.items, total: data.total };
  }),

  setNews: action((state, payload) => {
    state.news = payload;
  }),

  addNews: action((state, payload) => {
    if (state.newss.items) {
      state.newss.items.push(payload);
    } else {
      state.newss["items"] = [payload];
    }
  }),

  replaceNews: action((state, payload) => {
    state.news = payload;
    if (state.newss.items) {
      const index = state.newss.items!.findIndex(e => e.id === payload.id);
      if (index >= 0) state.newss.items!.splice(index, 1, payload);
    }
  }),

  getNews: thunk(async (actions, payload, { injections }) => {
    const { newsService } = injections;
    const response = await newsService.getNews(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    actions.setNews(response.data.getNews);

    return response.data.getNews;
  }),

  createNews: thunk(async (actions, payload, { injections }) => {
    const { newsService } = injections;
    const response = await newsService.createNews(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.createNews;
    actions.setNews(item);
    actions.addNews(item);

    return item;
  }),

  updateNews: thunk(async (actions, payload, { injections }) => {
    const { newsService } = injections;
    const response = await newsService.updateNews(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.updateNews;
    actions.setNews(item);
    actions.replaceNews(item);

    return item;
  }),

  searchNews: thunk(async (actions, payload, { injections }) => {
    const { newsService } = injections;
    const response = await newsService.searchNews(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.searchNews) {
      const data = response.data.searchNews;
      actions.setNewss(data);

      return data;
    }

    return null;
  }),
};
