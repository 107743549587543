import React, { useState } from "react";
import { FilterBase } from "..";
import { EnumFilterType, IFilter } from "../../../../utils/filter";
import contextStore from "../../../../redux/store";
import { getLocale } from "../../../../lang";
import { IFilterField } from "../../../Table";
import DatePickerInput from "../date-picker";
import {
  DATE_PICKER_FORMAT_CALENDAR,
  DATE_PICKER_FORMAT_DAY,
  parseISODateFn,
} from "../../../../utils/config";
import { ZonedDatePicker } from "../../../ZonedDatePicker";

interface Props {
  fields: {
    value: string;
    type: EnumFilterType;
    title?: string;
  }[];
  selectedValue?: Date;
  customInput?: boolean;
  defaultValue?: boolean;
  onChange: (type: string, e: any) => void;
  className?: string;
  selectedFilters: IFilterField<any>[];
  filters: IFilter[];
  displayTitle?: boolean;
}

const FilterDates = (props: Props): JSX.Element => {
  const {
    fields,
    onChange,
    selectedValue,
    selectedFilters,
    filters,
    className,
  } = props;
  let { defaultValue, customInput, displayTitle } = props;
  if (defaultValue === undefined) {
    defaultValue = true;
  }
  if (displayTitle === undefined) {
    displayTitle = true;
  }
  if (customInput === undefined) {
    customInput = false;
  }

  const { value, title } = fields[0];

  let currentValue = selectedFilters?.find(f => f.type === value)?.value;
  if (currentValue) currentValue = parseISODateFn(currentValue);

  let minDate;
  let maxDate;
  const dependsOn = filters.find(f => f.value === value)?.dependsOn;
  if (dependsOn?.length) {
    const { field, operator } = dependsOn[0];
    const v = selectedFilters.find(f => f.type === field)?.value;
    if (v) {
      const date = parseISODateFn(v);
      if (operator === "gte") {
        minDate = date;
      }
      if (operator === "lte") {
        maxDate = date;
      }
    }
  }

  // default value
  const [selectValue, setSelectValue] = useState<Date | null | undefined>(
    currentValue || selectedValue
  );
  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  return (
    <FilterBase
      title={displayTitle ? (title ? `${title}` : "general.date") : null}
      className={className}
    >
      <div id="formatted-calendar">
        <ZonedDatePicker
          onChange={e => {
            setSelectValue(e);

            return onChange(value, e);
          }}
          selectsEnd
          minDate={minDate}
          maxDate={maxDate}
          selected={selectValue}
          locale={currentAppLocale.date}
          dateFormatCalendar={DATE_PICKER_FORMAT_CALENDAR}
          dateFormat={DATE_PICKER_FORMAT_DAY}
          useWeekdaysShort
          customInput={customInput && <DatePickerInput />}
        />
      </div>
    </FilterBase>
  );
};

export default FilterDates;
