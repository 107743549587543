/*
 *
 * Center Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import {
  GetCenterQuery,
  CreateCenterMutation,
  UpdateCenterMutation,
} from "../../api/graphql/types";

import {
  getCenter as getCenterQuery,
  searchCenters as searchCentersQuery,
  searchAllCenters as searchAllCentersQuery,
} from "../../api/graphql/queries";

import {
  createCenter as createCenterMutation,
  updateCenter as updateCenterMutation,
  deleteCenter as deleteCenterMutation,
  updateCenterState as updateCenterStateMutation,
} from "../../api/graphql/mutations";

import { fetchPolicy } from "../../api/client";
import { SearchCentersOpts } from "../../models/Center";

const DEFAULT_LIMIT = 100;

export const getCenter = payload => {
  const request = payload.gql ? payload.gql : getCenterQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: { id: payload.id },
    fetchPolicy,
  }) as {
    data: GetCenterQuery;
  };
};

export const createCenter = payload =>
  ApolloClient.mutate({
    mutation: gql(createCenterMutation),
    variables: { input: payload },
  }) as {
    data: CreateCenterMutation;
  };

export const updateCenter = payload =>
  ApolloClient.mutate({
    mutation: gql(updateCenterMutation),
    variables: { input: payload },
  }) as {
    data: UpdateCenterMutation;
  };

export const updateCenterState = payload =>
  ApolloClient.mutate({
    mutation: gql(updateCenterStateMutation),
    variables: { input: payload },
  }) as {
    data: any;
  };

export const deleteCenter = payload =>
  ApolloClient.mutate({
    mutation: gql(deleteCenterMutation),
    variables: { input: payload },
  });

export const searchCenters = (payload: SearchCentersOpts) => {
  const request = payload.gql ? payload.gql : searchCentersQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: {
      filter: payload.filter,
      limit: payload && payload.limit ? payload.limit : DEFAULT_LIMIT,
      from: payload && payload.from ? payload.from : 0,
      locale: payload.locale,
      sort: payload.sort,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};

export const searchAllCenters = (payload: SearchCentersOpts) => {
  const request = payload.gql ? payload.gql : searchAllCentersQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: {
      filter: payload.filter,
      limit: payload?.limit || DEFAULT_LIMIT,
      from: payload?.from || 0,
      locale: payload.locale,
      sort: payload.sort,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};
