import React from "react";
import images from "../../images";
import history from "../../history";
import IntlMessages from "../../utils/messages";

interface GoBackProps {
  id: string;
  name: string;
  className?: string;
  url?: string;
  location?: { state: any };
}

const GoBackButton = (props: GoBackProps): JSX.Element => {
  const { id, name, className, url, location } = props;
  const buttonClassName = className || "";

  return (
    <button
      id={id}
      name={name}
      data-cy={id}
      type="button"
      onClick={() => {
        if (url) {
          history.push(url, location?.state);
        } else {
          // fallback
          history.goBack();
        }
      }}
      className={`flex items-center ${buttonClassName}`}
    >
      <img src={images.backBlack} alt="goBack" />

      <p
        className="ml-8 text-14px text-black leading-4"
        style={{ fontFamily: "OpenSans-Regular" }}
      >
        <IntlMessages id="general.back" />
      </p>
    </button>
  );
};

export default GoBackButton;
