import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { match as Match } from "react-router-dom";
import { injectIntl, IntlShape } from "react-intl";
import history from "../../../../history";
import {
  navTo,
  EnumPaths,
  getServicesPathByTypology,
} from "../../../../utils/navigation";
import ListServiceCategories from "./categories-list";
import {
  EnumCategoryType,
  EnumServiceType,
  SearchableCategorySortableFields,
  SearchableSortDirection,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Category } from "../../../../lib/ground-aws-graphql-core/models/Category";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import contextStore from "../../../../redux/store";
import PageSubheader from "../../../../components/PageSubheader";
import { Service } from "../../../../lib/ground-aws-graphql-core/models/Service";
import { EnumAction } from "../../../../utils/types";
import { getLocale } from "../../../../lang";

interface Props {
  intl: IntlShape;
  serviceType: EnumServiceType;
  categoryType: EnumCategoryType;
  match: Match<{ cid: string }>;
}

const DEFAULT_LIMIT = 100;

const ListServices = (props: Props) => {
  const [loading, setLoading] = useState(false);

  const { serviceType, categoryType, match, intl } = props;

  const searchAllServices = GroundGraphqlContextStore.useStoreActions(
    actions => actions.service.searchAllServices
  );

  const setService = GroundGraphqlContextStore.useStoreActions(
    actions => actions.service.setService
  );

  const categories = GroundGraphqlContextStore.useStoreState(
    state => state.category.categories.items
  );

  const services =
    serviceType === EnumServiceType.SPACE
      ? GroundGraphqlContextStore.useStoreState(
          state => state.service.spaces.items
        )
      : GroundGraphqlContextStore.useStoreState(
          state => state.service.services.items
        );

  const searchAllCategories = GroundGraphqlContextStore.useStoreActions(
    actions => actions.category.searchAllCategories
  );

  const modalCategoryAction = contextStore.useStoreState(
    state => state.category.modalCategoryAction
  );

  const modalCategorySelected = contextStore.useStoreState(
    state => state.category.modalCategorySelected
  );

  const modalCreateUpdateCategoryIsOpen = contextStore.useStoreState(
    state => state.category.modalCreateUpdateCategoryIsOpen
  );

  const modalDuplicateCategoryIsOpen = contextStore.useStoreState(
    state => state.category.modalDuplicateCategoryIsOpen
  );

  const setModalCategoryAction = contextStore.useStoreActions(
    actions => actions.category.setModalCategoryAction
  );

  const setModalCategorySelected = contextStore.useStoreActions(
    actions => actions.category.setModalCategorySelected
  );

  const setModalCreateUpdateCategoryIsOpen = contextStore.useStoreActions(
    actions => actions.category.setModalCreateUpdateCategoryIsOpen
  );

  const setModalDuplicateCategoryIsOpen = contextStore.useStoreActions(
    actions => actions.category.setModalDuplicateCategoryIsOpen
  );

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  useEffect(() => {
    fetchData();
  }, [serviceType]);

  const fetchAllCategories = () => {
    return searchAllCategories({
      filter: {
        type: {
          eq: categoryType,
        },
        categoryCenterId: {
          eq: match.params.cid,
        },
      },
      sort: {
        field: SearchableCategorySortableFields.position,
        direction: SearchableSortDirection.asc,
      },
      locale: currentAppLocale.backend_locale,
    });
  };

  const fetchAllServices = () => {
    return searchAllServices({
      limit: DEFAULT_LIMIT,
      filter: {
        centerServicesId: { eq: match.params.cid },
        type: { eq: serviceType },
      },
    });
  };

  const fetchData = async () => {
    setLoading(true);
    await Promise.all([fetchAllCategories(), fetchAllServices()]).finally(() =>
      setLoading(false)
    );
  };

  useEffect(() => {}, [categories]);

  const handleAddNewService = (category: Category) => {
    setService(null);

    const centerId = match.params.cid;

    const path = getServicesPathByTypology(serviceType);

    const navPath = navTo(`${path}/${EnumPaths.CREATION_MODE}`, centerId);

    history.push(navPath, { category });
  };

  const handleEditCategory = (category: Category) => {
    setModalCategoryAction(EnumAction.UPDATE);
    setModalCategorySelected(category);
    setModalCreateUpdateCategoryIsOpen(true);
  };

  const handleDuplicateCategory = (category: Category) => {
    setModalCategoryAction(EnumAction.DUPLICATE);
    setModalCategorySelected(category);
    setModalDuplicateCategoryIsOpen(true);
  };

  const handleClickService = (_e: any, service: Service) => {
    const path = getServicesPathByTypology(serviceType);
    history.push(navTo(`${path}/${service.id}`, match.params.cid));
  };

  const handleEditService = (_e: any, service: Service) => {
    const path = getServicesPathByTypology(serviceType);
    history.push(
      navTo(`${path}/${service.id}/${EnumPaths.EDIT_MODE}`, match.params.cid)
    );
  };

  const handleAddCategory = () => {
    setModalCategoryAction(EnumAction.CREATE);
    setModalCategorySelected(null);
    setModalCreateUpdateCategoryIsOpen(true);
  };

  return loading ? (
    <div className="loading" />
  ) : (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: `page.list.${serviceType.toLowerCase()}s.document.title`,
          })}
        </title>
      </Helmet>

      <PageSubheader
        title={`page.list.${serviceType}.title`}
        buttonRightTitle="general.add.CATEGORY"
        buttonRightAction={handleAddCategory}
        buttonRightId="btn-add-category"
      />

      <div className="px-8 pt-8">
        <ListServiceCategories
          loading={loading}
          serviceType={serviceType}
          handleEditService={handleEditService}
          handleClickService={handleClickService}
          fetchData={fetchData}
          categories={categories}
          services={services}
          match={match}
          modalCreateUpdateCategoryIsOpen={modalCreateUpdateCategoryIsOpen}
          setModalCreateUpdateCategoryIsOpen={
            setModalCreateUpdateCategoryIsOpen
          }
          modalDuplicateCategoryIsOpen={modalDuplicateCategoryIsOpen}
          setModalDuplicateCategoryIsOpen={setModalDuplicateCategoryIsOpen}
          modalCategoryAction={modalCategoryAction}
          modalCategorySelected={modalCategorySelected}
          handleEditCategory={handleEditCategory}
          handleDuplicateCategory={handleDuplicateCategory}
          handleAddService={handleAddNewService}
        />
      </div>
    </>
  );
};

export default injectIntl(ListServices);
