import { ErrorGraphQL } from "./../../services/coreUtils";
/*
 *
 * ClosedDay Model
 *
 */

import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { Operator } from "../Operator";

import { CreateClosedDayInput as CreateClosedDay } from "../../api/graphql/types";

export interface ClosedDay {
  operator?: Operator;
  id: string;
  start: string;
  end: string;
  markForDelete?: boolean;
}

export interface ModelClosedDayConnection {
  items: [ClosedDay] | null;
  total?: number | null;
}

// Interface declaration
export interface ClosedDayModel {
  closedDays: ModelClosedDayConnection;
  closedDay: ClosedDay | null;

  setClosedDay: Action<ClosedDayModel, ClosedDay>;
  addClosedDay: Action<ClosedDayModel, ClosedDay>;
  createClosedDay: Thunk<ClosedDayModel, CreateClosedDay, Injections>;
}

export const closedDayModel: ClosedDayModel = {
  closedDays: { items: null, total: null },
  closedDay: null,

  setClosedDay: action((state, payload) => {
    state.closedDay = payload;
  }),

  addClosedDay: action((state, payload) => {
    if (state.closedDays.items) {
      state.closedDays.items.push(payload);
    } else {
      state.closedDays["items"] = [payload];
    }
  }),

  createClosedDay: thunk(async (actions, payload, { injections }) => {
    const { closedDayService } = injections;
    const response = await closedDayService.createClosedDay(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const items = response.data.createClosedDay;
    items.map(actions.setClosedDay);
    items.map(actions.addClosedDay);

    return items;
  }),
};
