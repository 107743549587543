import { ErrorGraphQL } from "./../../services/coreUtils";
import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel } from "../";

import {
  CreateCenterTypeInput as CreateCenterType,
  UpdateCenterTypeInput as UpdateCenterType,
  ModelCenterTypeFilterInput,
} from "../../api/graphql/types";
import { Operator } from "../Operator";

export interface CenterType {
  id: string;
  operator?: Operator;
  type?: any;
  name?: string;
  enabled?: boolean;
  markForDelete?: boolean;
}

interface ListCenterTypesOpts {
  limit?: number;
  filter?: ModelCenterTypeFilterInput;
  page?: number;
  gql?: string | null;
}

export interface ModelCenterTypeConnection {
  items: CenterType[];
  total: number;
}

// Interface declaration
export interface CenterTypeModel {
  centerTypes: ModelCenterTypeConnection;
  centerType: CenterType | null;

  setCenterTypes: Action<
    CenterTypeModel,
    { data: ModelCenterTypeConnection; page?: number }
  >;
  setCenterType: Action<CenterTypeModel, CenterType | null>;
  addCenterType: Action<CenterTypeModel, CenterType>;
  replaceCenterType: Action<CenterTypeModel, CenterType>;
  listCenterTypes: Thunk<
    CenterTypeModel,
    ListCenterTypesOpts,
    Injections,
    StoreModel
  >;
  createCenterType: Thunk<CenterTypeModel, CreateCenterType, Injections>;
  updateCenterType: Thunk<CenterTypeModel, UpdateCenterType, Injections>;
}

export const centerTypeModel: CenterTypeModel = {
  centerTypes: { items: [], total: 0 },
  centerType: null,

  setCenterTypes: action(
    (state, payload: { data: ModelCenterTypeConnection }) => {
      const { data } = payload;
      state.centerTypes = data;
    }
  ),

  setCenterType: action((state, payload) => {
    state.centerType = payload;
  }),

  addCenterType: action((state, payload) => {
    if (state.centerTypes.items) {
      state.centerTypes.items.push(payload);
    } else {
      state.centerTypes["items"] = [payload];
    }
  }),

  replaceCenterType: action((state, payload) => {
    state.centerType = payload;
    if (state.centerTypes.items) {
      const index = state.centerTypes.items!.findIndex(
        e => e.id === payload.id
      );
      if (index >= 0) state.centerTypes.items!.splice(index, 1, payload);
    }
  }),

  listCenterTypes: thunk(async (actions, payload, { injections }) => {
    const { centerTypeService } = injections;
    const response = await centerTypeService.listCenterTypes(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const data = response.data.listCenterTypes;
    actions.setCenterTypes({ data: data, page: payload.page });

    return data;
  }),

  createCenterType: thunk(async (actions, payload, { injections }) => {
    const { centerTypeService } = injections;
    const response = await centerTypeService.createCenterType(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.createCenterType;
    actions.setCenterType(item);
    actions.addCenterType(item);

    return item;
  }),

  updateCenterType: thunk(async (actions, payload, { injections }) => {
    const { centerTypeService } = injections;
    const response = await centerTypeService.updateCenterType(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.updateCenterType;
    actions.setCenterType(item);
    actions.replaceCenterType(item);

    return item;
  }),
};
