import React, { useState } from "react";
import {
  AttributeItem,
  createAttributeItem,
  ProductAttributeKeys,
} from "../../../../utils/attribute";
import IntlMessages from "../../../../utils/messages";
import {
  ActionTypes,
  getFieldAttributeByAttributeKey,
} from "../../../../utils/types";
import { getCypressTestId } from "../../../../utils/config";
import { AttributeKey } from "../../../../lib/ground-aws-graphql-core/models/AttributeKey";
import { EnumAttributeTypology } from "../../../../lib/ground-aws-graphql-core/api/graphql/types";

interface Props {
  name: string;
  attributes?: AttributeItem[];
  attributeKeys?: AttributeKey[] | null;
  onChange: (a: AttributeItem[]) => void;
}

const Attribute = (props: Props): JSX.Element => {
  const checkboxAttributeKeys = [] as AttributeKey[];
  const [checked, setChecked] = useState([] as boolean[]);

  props.attributeKeys?.map(attrKey => {
    if (
      attrKey.typology == EnumAttributeTypology.BOOLEAN &&
      !checkboxAttributeKeys.includes(attrKey)
    ) {
      if (
        attrKey.name === ProductAttributeKeys.AS_OPTION ||
        attrKey.name === ProductAttributeKeys.IN_CATALOG
      ) {
        checkboxAttributeKeys.push(attrKey);
      }
    }
  });

  const handleChangeAttribute = (e, attrKey: AttributeKey) => {
    const value = e.target.checked;
    const newAttributes = props.attributes;

    let attr = newAttributes?.find(
      e =>
        e.item.key?.id === attrKey.id && e.actionValue !== ActionTypes.TO_DELETE
    );

    const field = getFieldAttributeByAttributeKey(attrKey);

    if (attr) {
      if (attr.actionValue === ActionTypes.TO_KEEP) {
        attr.actionValue = ActionTypes.TO_UPDATE;
      }
      if (field) {
        attr.item[`${field}`] = value;
      }
    } else {
      attr = createAttributeItem(value, attrKey, field);
      newAttributes?.push(attr);
    }

    newAttributes?.map(attribute => {
      return attribute.item.id === attr?.item.id ? attr : attribute;
    });

    if (newAttributes) {
      props.onChange(newAttributes);
    }
  };

  return (
    <>
      {checkboxAttributeKeys && (
        <>
          {checkboxAttributeKeys.map((attributeKey, index) => {
            const attr = props.attributes?.find(
              e => e.item.key?.id === attributeKey.id
            );

            return (
              <div key={index} className="flex items-center pl-3 text-12px">
                <input
                  id={attributeKey.id}
                  data-cy="checkbox-attribute"
                  data-testid={getCypressTestId(attributeKey)}
                  name={attributeKey.name}
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-ground-green-100 transition duration-100 ease-in-out"
                  checked={attr?.item.booleanValue}
                  disabled={attributeKey.system}
                  onChange={e => {
                    handleChangeAttribute(e, attributeKey!);
                    const newArr = [...checked];
                    newArr[index] = !newArr[index];
                    setChecked(newArr);
                  }}
                />
                <label
                  htmlFor={attributeKey.name}
                  className="mb-0 text-ground-gray-100 pl-3"
                >
                  <IntlMessages id={`page.product.${attributeKey.name}`} />
                </label>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default Attribute;
