import { OperatorConfig } from "..";

// INT
const sourcesConfig: OperatorConfig = {
  backoffice: {
    display: {
      "feature.enterprise": false,
      "sidebar.menu.catalog.workspaces": false,
      "sidebar.menu.management.incidents": false,
      "sidebar.menu.management.visits": false,
      "feature.dashboards.realtime_incidents": false,
      "feature.dashboards.incidents": false,
    },
  },
};

export default sourcesConfig;
