import { Thunk, thunk } from "easy-peasy";
import { Injections } from "../../../store";
import { StoreModel } from "../..";

export interface CancelOrderOpts {
  globalOrderId: string;
  orderId: string;
}

export interface CancelOrderItemOpts {
  globalOrderId: string;
  orderId: string;
  orderItemId: string;
}

export interface CancellationModel {
  cancelOrder: Thunk<
    CancellationModel,
    CancelOrderOpts,
    Injections,
    StoreModel
  >;
  cancelOrderItem: Thunk<
    CancellationModel,
    CancelOrderItemOpts,
    Injections,
    StoreModel
  >;
}

export const cancellationModel: CancellationModel = {
  cancelOrder: thunk(async (_actions, payload, { injections }) => {
    const { cancellationApiService } = injections;

    return cancellationApiService.cancelOrder(payload);
  }),
  cancelOrderItem: thunk(async (_actions, payload, { injections }) => {
    const { cancellationApiService } = injections;

    return cancellationApiService.cancelOrderItem(payload);
  }),
};
