import React from "react";
import { match as Match } from "react-router-dom";
import { injectIntl, IntlShape } from "react-intl";
import { ReportingIncidentDate } from "../../../../../../lib/ground-aws-graphql-core/models/Reporting";
import IncidentCard from "../incident-card";

interface Props {
  intl: IntlShape;
  match: Match<{ cid: string }>;
  data: {
    all?: ReportingIncidentDate;
  };
}

interface InterfaceCardInterface {
  key: string;
  label: string;
  total?: number;
  evolutionRate?: number;
  variation?: number;
  resolutionTime?: number;
}

const IncidentCardList = (props: Props) => {
  const { intl, data } = props;

  const getIncidentCards = (intl: IntlShape): InterfaceCardInterface[] => [
    {
      key: "ALL",
      label: intl.formatMessage({
        id: "page.dashboards.chart.total.incidents",
      }),
      total: data.all?.total,
      evolutionRate: data.all?.evolutionRate,
      resolutionTime: data.all?.resolutionTime,
      variation: data.all?.variation,
    },
  ];

  return (
    <>
      {getIncidentCards(intl).map(e => {
        return (
          <div id={e.key} data-cy={e.key} data-testid={e.key} key={e.key}>
            <IncidentCard
              label={e.label}
              total={e.total}
              evolutionRate={e.evolutionRate}
              variation={e.variation}
              resolutionTime={e.resolutionTime}
              selected={false}
            />
          </div>
        );
      })}
    </>
  );
};

export default injectIntl(IncidentCardList);
