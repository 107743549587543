export const getMenuClasses = (
  classes: string,
  menuHiddenBreakpoint: number,
  subHiddenBreakpoint: number
): string[] => {
  let values = classes.split(" ").filter(x => x !== "");
  const windowWidth = window.innerWidth;
  if (windowWidth < menuHiddenBreakpoint) {
    values.push("menu-mobile");
  } else if (windowWidth < subHiddenBreakpoint) {
    values = values.filter((x: string) => x !== "menu-mobile");
    if (
      values.includes("menu-default") &&
      !values.includes("menu-sub-hidden")
    ) {
      values.push("menu-sub-hidden");
    }
  } else {
    values = values.filter((x: string) => x !== "menu-mobile");
    if (values.includes("menu-default") && values.includes("menu-sub-hidden")) {
      values = values.filter((x: string) => x !== "menu-sub-hidden");
    }
  }

  return values;
};

interface IEnumMenus {
  CENTERS: string;
  PROVIDERS: string;
  USERS: string;
  ADMINISTRATORS: string;
}

export const EnumMenus: IEnumMenus = {
  CENTERS: "CENTERS",
  PROVIDERS: "PROVIDERS",
  USERS: "USERS",
  ADMINISTRATORS: "ADMINISTRATORS",
};
