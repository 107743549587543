/*
 *
 * CenterType Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import {
  ListCenterTypesQuery,
  CreateCenterTypeMutation,
  UpdateCenterTypeMutation,
} from "../../api/graphql/types";

import { listCenterTypes as listCenterTypesQuery } from "../../api/graphql/queries";

import {
  createCenterType as createCenterTypeMutation,
  updateCenterType as updateCenterTypeMutation,
} from "../../api/graphql/mutations";

export const listCenterTypes = payload => {
  const request = payload.gql ? payload.gql : listCenterTypesQuery;

  return ApolloClient.query({
    query: gql(request),
  }) as {
    data: ListCenterTypesQuery;
  };
};

export const createCenterType = payload =>
  ApolloClient.mutate({
    mutation: gql(createCenterTypeMutation),
    variables: { input: payload },
  }) as {
    data: CreateCenterTypeMutation;
  };

export const updateCenterType = payload =>
  ApolloClient.mutate({
    mutation: gql(updateCenterTypeMutation),
    variables: { input: payload },
  }) as {
    data: UpdateCenterTypeMutation;
  };
