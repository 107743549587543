import React from "react";
import { match as Match } from "react-router-dom";
import { injectIntl, IntlShape } from "react-intl";
import UsersCard from "../users-card";
import { ReportingUserDate } from "../../../../lib/ground-aws-graphql-core/models/Reporting";

interface Props {
  intl: IntlShape;
  match: Match<{ cid: string }>;
  data: {
    all?: ReportingUserDate;
    users?: ReportingUserDate;
    administrators?: ReportingUserDate;
    confirmed?: ReportingUserDate;
    unconfirmed?: ReportingUserDate;
  };
}

interface UsersCardInterface {
  key: string;
  label: string;
  total?: number;
  rate?: number;
  variation?: number;
}

const UsersCardList = (props: Props) => {
  const { intl, data } = props;

  const getUsersCards = (intl: IntlShape): UsersCardInterface[] => [
    {
      key: "ALL",
      label: intl.formatMessage({
        id: "page.dashboards.chart.total.all.users.title",
      }),
      total: data.all?.total,
      rate: data.all?.evolutionRate,
      variation: data.all?.variation,
    },
    {
      key: "USERS",
      label: intl.formatMessage({
        id: "page.dashboards.chart.total.users.title",
      }),
      total: data.users?.total,
      rate: data.users?.evolutionRate,
      variation: data.users?.variation,
    },
    {
      key: "ADMINISTRATORS",
      label: intl.formatMessage({
        id: "page.dashboards.chart.total.administrators.title",
      }),
      total: data.administrators?.total,
      rate: data.administrators?.evolutionRate,
      variation: data.administrators?.variation,
    },
    {
      key: "CONFIRMED",
      label: intl.formatMessage({
        id: "page.dashboards.chart.total.confirmed.title",
      }),
      total: data.confirmed?.total,
      rate: data.confirmed?.evolutionRate,
      variation: data.confirmed?.variation,
    },
    {
      key: "UNCONFIRMED",
      label: intl.formatMessage({
        id: "page.dashboards.chart.total.unconfirmed.title",
      }),
      total: data.unconfirmed?.total,
      rate: data.unconfirmed?.evolutionRate,
      variation: data.unconfirmed?.variation,
    },
  ];

  return (
    <div className="sm:grid sm:grid-cols-5 sm:gap-4">
      {getUsersCards(intl).map(e => {
        return (
          <div id={e.key} data-cy={e.key} data-testid={e.key} key={e.key}>
            <UsersCard
              label={e.label}
              total={e.total}
              rate={e.rate}
              variation={e.variation}
              selected={false}
            />
          </div>
        );
      })}
    </div>
  );
};

export default injectIntl(UsersCardList);
