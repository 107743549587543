import { OperatorConfig } from "..";

// PREPROD
const sourcesConfig: OperatorConfig = {
  backoffice: {
    display: {
      "sidebar.menu.building.notifications": true,
      "sidebar.menu.catalog": false,
      "sidebar.menu.management.calendar": false,
      "sidebar.menu.management.bookings": false,
      "sidebar.menu.management.orders": false,
      "sidebar.menu.communicate": false,
      "feature.dashboards.realtime_occupancy": false,
      "feature.dashboards.realtime_sales": false,
      "feature.dashboards.realtime_incidents": false,
      "feature.dashboards.ga": false,
      "feature.dashboards.incidents": false,
      "feature.dashboards.sales": false,
      "feature.dashboards.occupancy": false,
    },
  },
};

export default sourcesConfig;
