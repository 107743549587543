import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import {
  SignInOpts,
  RefreshTokenOpts,
  ForgotPasswordOpts,
  ConfirmPasswordOpts,
} from "../../services/Authentication";
import { StoreModel } from "..";

interface Response {
  code: string;
  message: string;
}

export interface UserCognito {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  operator_id: string;
}

export interface IOperator {
  id: string;
  payment_externalized: boolean;
  pre_ordering: boolean;
  short_name: string;
}

// Interface declaration
export interface AuthenticationModel {
  me: UserCognito | null;
  setMe: Action<AuthenticationModel, UserCognito | null>;

  meDetails: {
    operator: IOperator;
  } | null;
  setMeDetails: Action<
    AuthenticationModel,
    {
      operator: IOperator;
    } | null
  >;

  token: string | null;
  setToken: Action<AuthenticationModel, string | null>;

  accessToken: string | null;
  setAccessToken: Action<AuthenticationModel, string | null>;

  refreshToken: string | null;
  setRefreshToken: Action<AuthenticationModel, string | null>;

  signInSuccess: boolean;
  setSignInSuccess: Action<AuthenticationModel, boolean>;
  signInResponse: Response | null;
  setSignInResponse: Action<AuthenticationModel, Response | null>;
  signIn: Thunk<AuthenticationModel, SignInOpts, Injections, StoreModel>;
  forgotPassword: Thunk<
    AuthenticationModel,
    ForgotPasswordOpts,
    Injections,
    StoreModel
  >;
  setForgotPasswordSuccess: Action<AuthenticationModel, boolean>;
  forgotPasswordSuccess: boolean;

  confirmPassword: Thunk<
    AuthenticationModel,
    ConfirmPasswordOpts,
    Injections,
    StoreModel
  >;
  setConfirmPasswordSuccess: Action<AuthenticationModel, boolean>;
  confirmPasswordSuccess: boolean;

  requestRefreshToken: Thunk<
    AuthenticationModel,
    RefreshTokenOpts,
    Injections,
    StoreModel
  >;

  getMe: Thunk<AuthenticationModel, undefined, Injections, StoreModel>;
  getMeDetails: Thunk<AuthenticationModel, undefined, Injections, StoreModel>;
  logOut: Thunk<AuthenticationModel, undefined, Injections, StoreModel>;
}

export const authenticationModel: AuthenticationModel = {
  me: null,
  setMe: action((state, payload) => {
    state.me = payload;
  }),

  meDetails: null,
  setMeDetails: action((state, payload) => {
    state.meDetails = payload;
  }),

  token: null,
  setToken: action((state, payload) => {
    state.token = payload;
  }),

  accessToken: null,
  setAccessToken: action((state, payload) => {
    state.accessToken = payload;
  }),

  refreshToken: null,
  setRefreshToken: action((state, payload) => {
    state.refreshToken = payload;
  }),

  signInSuccess: false,
  setSignInSuccess: action((state, payload) => {
    state.signInSuccess = payload;
  }),
  signInResponse: null,
  setSignInResponse: action((state, payload) => {
    state.signInResponse = payload;
  }),

  signIn: thunk(async (actions, payload, { injections }) => {
    const { authenticationService } = injections;
    const response = await authenticationService.signIn(payload);

    const { data, status } = response;

    actions.setSignInResponse(data as Response);

    if (status === 200) {
      const { token, accessToken, refreshToken } = data;
      const meResponse = await authenticationService.getMe();

      // set token
      actions.setToken(token);
      // set access token
      actions.setAccessToken(accessToken);
      // set refresh token
      actions.setRefreshToken(refreshToken);

      actions.setSignInSuccess(true);

      // set me
      actions.setMe(meResponse.data.data);
    } else {
      actions.setSignInSuccess(false);
    }

    return response;
  }),

  forgotPasswordSuccess: false,
  forgotPassword: thunk(async (actions, payload, { injections }) => {
    const { authenticationService } = injections;
    const response = await authenticationService.forgotPassword(payload.email);
    const { status } = response;
    if (status === 200) {
      actions.setForgotPasswordSuccess(true);
    }

    return response;
  }),

  setForgotPasswordSuccess: action((state, payload) => {
    state.forgotPasswordSuccess = payload;
  }),

  confirmPasswordSuccess: false,
  confirmPassword: thunk(async (actions, payload, { injections }) => {
    const { authenticationService } = injections;
    const response = await authenticationService.confirmPassword(payload);
    const { status } = response;
    if (status === 200) {
      actions.setConfirmPasswordSuccess(true);
      actions.signIn({
        email: payload.email,
        password: payload.password,
      });
    }

    return response;
  }),

  setConfirmPasswordSuccess: action((state, payload) => {
    state.confirmPasswordSuccess = payload;
  }),

  requestRefreshToken: thunk(async (actions, payload, { injections }) => {
    const { authenticationService } = injections;
    const response = await authenticationService.refreshToken(payload);
    const { data } = response;

    const { token, access_token } = data;
    if (token && access_token) {
      actions.setToken(token);
      actions.setAccessToken(access_token);

      const resp = await authenticationService.getMe();
      // set me
      actions.setMe(resp.data.data);
    }

    return response;
  }),

  getMe: thunk(async (actions, _payload, { injections }) => {
    const { authenticationService } = injections;
    const response = await authenticationService.getMe();
    actions.setMe(response.data.data);

    return response;
  }),

  getMeDetails: thunk(async (actions, _payload, { injections }) => {
    const { authenticationService } = injections;
    const response = await authenticationService.getMeDetails();
    actions.setMeDetails(response.data.data);

    return response;
  }),

  logOut: thunk(async (actions, _payload, { injections }) => {
    const { authenticationService } = injections;
    await authenticationService.logOut();

    // Still needed because of the legacy APIs
    actions.setToken(null);
    actions.setAccessToken(null);
    actions.setRefreshToken(null);
    actions.setMe(null);
  }),
};
