import React from "react";
import { Helmet } from "react-helmet";
import { match as Match } from "react-router-dom";
import { Location } from "history";
import { injectIntl, IntlShape } from "react-intl";
import ServiceForm from "../form";
import { withTwoColLayout } from "../../../../utils";
import GoBackButton from "../../../../components/GoBackButton";
import { EnumServiceType } from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Category } from "../../../../lib/ground-aws-graphql-core/models/Category";

interface Props {
  serviceType: EnumServiceType;
  match: Match<{ cid: string; id: string }>;
  intl: IntlShape;
  location: Location<{
    category: Category;
  }>;
  backURL: string;
}

const ServiceUpdate = (props: Props) => {
  const { intl, serviceType, match, backURL } = props;

  return (
    <div className="p-2">
      <Helmet>
        <title>
          {intl.formatMessage({
            id: `page.${serviceType.toLowerCase()}.update.document.title`,
          })}
        </title>
      </Helmet>
      <GoBackButton
        id="btn-back-update-service"
        name="btn-back-update-service"
        url={backURL.replace(":id", match.params.id)}
        className="m-4"
      />
      <ServiceForm {...props} edition />
    </div>
  );
};

export default withTwoColLayout(injectIntl(ServiceUpdate));
