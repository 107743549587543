import React, { useState, forwardRef } from "react";
import images from "../../../../images";
import ModalAddAttribute from "../../../../routes/center/edition/attribute-modal";
import {
  AttributeItem,
  ProductAttributeKeys,
  createAttributeItem,
} from "../../../../utils/attribute";
import IntlMessages from "../../../../utils/messages";
import {
  ActionTypes,
  getFieldAttributeByAttributeKey,
  getAttributeValueByAttributeKey,
  getInputTypeByTypology,
  FormValue,
  Item,
  getAttributeKeyItems,
} from "../../../../utils/types";
import { getTranslation } from "../../../../utils/translation";
import { getCypressTestId } from "../../../../utils/config";
import GroundFormik from "../../../Tailwind/Form";
import { AttributeKey } from "../../../../lib/ground-aws-graphql-core/models/AttributeKey";
import { Center } from "../../../../lib/ground-aws-graphql-core/models/Center";
import { Product } from "../../../../lib/ground-aws-graphql-core/models/Product";
import {
  EnumAttributeType,
  EnumAttributeTypology,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Provider } from "../../../../lib/ground-aws-graphql-core/models/Provider";
import { User } from "../../../../lib/ground-aws-graphql-core/models/User";
import Tag from "../../../Tag";

interface Props {
  item: Center | Product | Provider | User | null;
  title: string;
  subTitle?: string;
  attributes?: AttributeItem[];
  attributeKeys?: AttributeKey[] | null;
  onChange: (attributes: AttributeItem[]) => void;
  onDelete: (attributes: AttributeItem[]) => void;
  zeroMessage?: string;
  type: EnumAttributeType;
}

const CardAttributes = (props: Props, ref): JSX.Element => {
  const {
    attributes,
    attributeKeys,
    onChange,
    onDelete,
    zeroMessage,
    title,
    subTitle,
    item,
  } = props;
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(images.add);

  const handleOpenModal = () => {
    setOpen(!open);
  };

  const checkboxAttributes = [] as AttributeItem[];
  const fieldAttributes = [] as AttributeItem[];

  const attrKeys = attributeKeys?.filter(
    a =>
      ![
        ProductAttributeKeys.AS_OPTION,
        ProductAttributeKeys.IN_CATALOG,
        ProductAttributeKeys.MINIMAL_APPROVAL,
      ].includes(a.name as ProductAttributeKeys)
  );

  attributes?.map(attr => {
    if (
      attr.item.key?.name === ProductAttributeKeys.AS_OPTION ||
      attr.item.key?.name === ProductAttributeKeys.IN_CATALOG ||
      attr.item.key?.name === ProductAttributeKeys.MINIMAL_APPROVAL
    ) {
      return;
    }
    if (attr.item.key?.typology === EnumAttributeTypology.BOOLEAN) {
      if (attr.item.booleanValue) {
        checkboxAttributes.push(attr);
      }
    } else {
      if (attr.actionValue !== ActionTypes.TO_DELETE) {
        fieldAttributes.push(attr);
      }
    }
  });

  const handleChangeAttribute = (e, item: AttributeKey) => {
    const newAttributes = attributes;
    let attr =
      item &&
      newAttributes?.find(
        e =>
          e.item.key?.id === item.id && e.actionValue !== ActionTypes.TO_DELETE
      );

    const field = getFieldAttributeByAttributeKey(item);
    const value = getAttributeValue(e, item);
    if (attr) {
      if (attr.actionValue === ActionTypes.TO_KEEP) {
        attr.actionValue = ActionTypes.TO_UPDATE;
      }
      if (field) {
        attr.item[`${field}`] = value;
      }
    } else if (item) {
      attr = createAttributeItem(value, item, field);
      newAttributes?.push(attr);
    } else {
      // attrKey is null or undefined
    }

    newAttributes?.map(attribute => {
      return attribute.item.id === attr?.item.id ? attr : attribute;
    });

    if (newAttributes) {
      onChange(newAttributes);
    }
  };

  const handleDeleteAttribute = (id: string) => {
    const attribute = attributes?.find(
      e => e.item.id === id && e.actionValue !== ActionTypes.TO_DELETE
    );

    if (attribute) {
      const filteredAttributes = attributes?.filter(e => e.item.id !== id);

      if (attribute.actionValue !== ActionTypes.TO_ADD) {
        if (attribute.item.key?.typology === EnumAttributeTypology.BOOLEAN) {
          attribute.item.booleanValue = false;
          if (attribute.actionValue === ActionTypes.TO_KEEP) {
            attribute.actionValue = ActionTypes.TO_UPDATE;
          }
        } else {
          attribute.actionValue = ActionTypes.TO_DELETE;
        }
        filteredAttributes?.push(attribute);
      }

      if (filteredAttributes) {
        onDelete(filteredAttributes);
      }
    }
  };

  const getAttributeValue = (e, attrKey: AttributeKey) => {
    if (attrKey.typology === EnumAttributeTypology.BOOLEAN) {
      return e.target.checked;
    } else if (
      attrKey.typology === EnumAttributeTypology.NUMBER ||
      attrKey.typology === EnumAttributeTypology.PERCENT
    ) {
      return parseFloat(e.target.value);
    }
    if (attrKey.typology === EnumAttributeTypology.SELECT) {
      return e.target.value;
    } else {
      return e.target.value;
    }
  };

  const getAttributeValues = (
    fieldAttributes: AttributeItem[],
    handleDeleteAttribute: (id: string) => void
  ): FormValue[] => {
    const attributes: FormValue[] = [];
    if (fieldAttributes && fieldAttributes.length > 0) {
      fieldAttributes.map((attribute, index) => {
        const items: Item[] = getAttributeKeyItems(attribute.item.key!);
        let { attributeValue } = getAttributeValueByAttributeKey(
          attribute.item.key!,
          attribute.item
        );
        const type = getInputTypeByTypology(attribute.item.key);

        if (attribute.item.key) {
          let stepOverride;
          const typology = attribute.item.key.typology;
          if (
            typology === EnumAttributeTypology.NUMBER ||
            typology === EnumAttributeTypology.PERCENT
          ) {
            stepOverride = 0.01;
          }

          const translatable = typology === EnumAttributeTypology.JSON;

          let max;
          if (
            typology === EnumAttributeTypology.NUMBER ||
            typology === EnumAttributeTypology.PERCENT
          ) {
            max = 100;
            attributeValue = attribute.item.numberValue
              ? attribute.item.numberValue.toString()
              : "0";
          }

          const label =
            attribute.item.key.description &&
            getTranslation(attribute.item.key.description)
              ? getTranslation(attribute.item.key.description)
              : attribute.item.key.name;

          const v: FormValue = {
            item: attribute.item.key,
            id: attribute.item.key.id,
            name: attribute.item.key.name,
            type,
            translatable,
            required: attribute.item.key.required,
            disabled: attribute.item.key.system,
            items,
            values: [
              {
                name: attributeValue,
                id: attributeValue,
              },
            ],
            label,
            placeholder: label,
            value: attributeValue,
            step: stepOverride,
            index,
            max,
            useIntl: false,
            children:
              !attribute.item.key.required && !attribute.item.key.system
                ? [
                    attribute.item.key.system && (
                      <img
                        id={attribute.item.id}
                        data-cy="trash-picto-attribute"
                        data-testid={getCypressTestId(attribute.item)}
                        src={images.trash}
                        alt="trash"
                        className="pencil-trash"
                        onClick={() => handleDeleteAttribute(attribute.item.id)}
                      />
                    ),
                  ]
                : [],
          };

          attributes.push(v);
        }
      });
    }

    return attributes;
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg my-30px">
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6 flex justify-between">
        <div>
          <h3 className="text-16px leading-6 font-medium text-gray-900">
            <IntlMessages id={title} />
          </h3>
          {subTitle && (
            <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
              <IntlMessages id={subTitle} />
            </p>
          )}
        </div>
        {attrKeys && attrKeys.length > 0 && (
          <>
            <div
              className="flex items-center sm:justify-center cursor-pointer hover:text-ground-blue-100 text-ground-gray-100"
              onMouseEnter={() => setImage(images.addBlue)}
              onMouseLeave={() => setImage(images.add)}
              data-cy="btn-add-attribute"
              onClick={handleOpenModal}
            >
              <img alt="" src={image} />
              <span className="hidden sm:block text-12px pr-2">
                <IntlMessages id="general.add" />
              </span>
            </div>
            <ModalAddAttribute
              {...props}
              isOpen={open}
              onRequestClose={handleOpenModal}
              items={attributes ?? []}
              attributeKeys={attributeKeys ?? []}
              onChangeAttribute={handleChangeAttribute}
            />
          </>
        )}
      </div>

      {checkboxAttributes.length > 0 && (
        <div className="p-20px">
          {checkboxAttributes.map((attribute, index) => (
            <Tag
              key={`${attribute.item.key?.id}_${index}`}
              id={attribute.item.id}
              title={
                attribute.item.key?.description &&
                getTranslation(attribute.item.key?.description)
                  ? getTranslation(attribute.item.key?.description)
                  : attribute.item.key?.name ?? ""
              }
              deleteCondition={
                !attribute.item.key?.required && !attribute.item.key?.system
              }
              handleDelete={() => handleDeleteAttribute(attribute.item.id)}
            />
          ))}
        </div>
      )}

      {fieldAttributes.length > 0 && (
        <div>
          <dl>
            <GroundFormik
              item={item}
              values={getAttributeValues(
                fieldAttributes,
                handleDeleteAttribute
              )}
              onChange={handleChangeAttribute}
              ref={ref}
            />
          </dl>
        </div>
      )}
      {fieldAttributes.length === 0 && checkboxAttributes.length === 0 && (
        <div className="flex justify-center sm:py-6 sm:px-6 px-2 py-2 bg-ground-white-200 rounded-b-10">
          <span className="text-12px text-ground-gray-100 italic">
            <IntlMessages
              id={zeroMessage ? zeroMessage : "general.attributes.empty"}
            />
          </span>
        </div>
      )}
    </div>
  );
};

export default forwardRef(CardAttributes);
