/*
 *
 * OrderItem Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import { getOrderItems as getOrderItemsQuery } from "../../api/graphql/queries";
import { fetchPolicy } from "../../api/client";

export const getOrderItems = payload => {
  return ApolloClient.query({
    query: gql(getOrderItemsQuery),
    variables: payload,
    fetchPolicy,
  }) as {
    data: any;
  };
};
