import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import IntlMessages from "../../../utils/messages";
import Button from "../Button";

interface Props {
  onClick: any;
  headers: { label: string; key: string }[];
  filename: string;
}

const DownloadCSVButton = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [csvData, setCsvData] = useState([]);
  const csvInstance = useRef<any | null>(null);

  const { onClick, headers, filename } = props;

  const fetchResults = () => {
    if (!loading) {
      setLoading(true);
      onClick()
        .then(res => {
          setCsvData(res.items);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData([]);
      });
    }
  }, [csvData]);

  return (
    <div>
      <Button
        name="btn-export"
        id="btn-export"
        className="my-4"
        disabled={loading}
        onClick={() => {
          fetchResults();
        }}
        type="button"
      >
        <IntlMessages id="general.export" />
      </Button>
      {csvData.length > 0 ? (
        <CSVLink
          headers={headers}
          filename={filename}
          data={csvData}
          ref={csvInstance}
          separator={";"}
        />
      ) : undefined}
    </div>
  );
};

export default DownloadCSVButton;
