import { updateProductGql, createProductGql } from "../../../utils/gql/gql";
import {
  convertAttributeItems,
  convertClosedDaysItems,
  convertDaySlotsItems,
  convertExceptionalOpeningHoursItems,
  convertPriceUnitsVariations,
} from "../../../utils/preprocessing";
import { ProductOpts } from "../../models/Product";
import { uploadPicturesPromise } from "../Image";
import {
  createOptionsPromise,
  deleteOptionsPromise,
  getOptions,
} from "./Option";
import {
  createSingleProductsPromise,
  deleteSingleProductsPromise,
  getSingleProducts,
} from "./SingleProduct";
import { EnumPermissionEntity } from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import {
  CreateProductOpts,
  UpdateProductOpts,
} from "../../../lib/ground-aws-graphql-core/models/Product";

export const createProduct = async (payload: ProductOpts) => {
  const response = await uploadPicturesPromise(
    payload.pictures,
    EnumPermissionEntity.PRODUCT
  );

  const { urls } = response;

  const attributes = payload.attributes;
  const { priceUnitsVariation } = payload;

  const params: CreateProductOpts = {
    product: {
      ...payload.values,
      name: payload.values.name,
      description: payload.values.description,
      productOperatorId: payload.center.operator?.id,
      productCenterId: payload.center.id,
      centerProductsId: payload.center.id,
      serviceProductsId: payload.service?.id,
      pictures: urls,
      vat: payload.values.vat / 100,
      enabled: payload.enabled,
      authorized: true,
      attributes: attributes && convertAttributeItems(attributes),
      priceUnits:
        priceUnitsVariation && convertPriceUnitsVariations(priceUnitsVariation),
    },
    gql: createProductGql,
  };

  const productCreated = await payload.callbacks.createProduct(params);

  const options = getOptions(payload.options, productCreated, payload.center);

  await createOptionsPromise(options.optionsToCreate, payload.callbacks);

  const products = getSingleProducts(
    payload.singleProducts,
    productCreated,
    payload.center
  );

  await createSingleProductsPromise(
    products.singleProductsToCreate,
    payload.callbacks
  );

  return productCreated;
};

export const updateProduct = async (payload: ProductOpts) => {
  const response = await uploadPicturesPromise(
    payload.pictures,
    EnumPermissionEntity.PRODUCT
  );

  const { urls } = response;
  const { closedDays, daySlots, hoursRanges, exceptionalOpeningHours } =
    payload;
  const attributes = payload.attributes;
  const priceUnitsVariation = payload.priceUnitsVariation;

  const params: UpdateProductOpts = {
    product: {
      ...payload.values,
      id: payload.product!.id,
      productOperatorId: payload.product!.productOperatorId,
      name: payload.values.name,
      description: payload.values.description,
      productCenterId: payload.center.id,
      centerProductsId: payload.center.id,
      serviceProductsId: payload.service?.id,
      pictures: urls,
      vat: payload.values.vat / 100,
      enabled: payload.enabled,
      flexible: payload.values.flexible,
      authorized: true,
      attributes: attributes && convertAttributeItems(attributes),
      closedDays: closedDays && convertClosedDaysItems(closedDays),
      exceptionalOpeningHours:
        exceptionalOpeningHours &&
        convertExceptionalOpeningHoursItems(exceptionalOpeningHours),
      daySlots:
        daySlots && hoursRanges && convertDaySlotsItems(daySlots, hoursRanges),
      priceUnits:
        priceUnitsVariation && convertPriceUnitsVariations(priceUnitsVariation),
    },
    gql: updateProductGql,
  };

  const options = getOptions(payload.options, payload.product, payload.center);

  await createOptionsPromise(options.optionsToCreate, payload.callbacks);
  await deleteOptionsPromise(options.optionsToDelete, payload.callbacks);

  const products = getSingleProducts(
    payload.singleProducts,
    payload.product,
    payload.center
  );

  await createSingleProductsPromise(
    products.singleProductsToCreate,
    payload.callbacks
  );
  await deleteSingleProductsPromise(
    products.singleProductsToDelete,
    payload.callbacks
  );

  const productUpdate = await payload.callbacks.updateProduct(params);

  return productUpdate;
};
