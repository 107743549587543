import React, { useContext, useEffect, useState } from "react";
import { IntlShape, injectIntl } from "react-intl";
import Select, { SingleValueProps, components } from "react-select";
import { ThemeContext } from "../../../../containers/App";
import images from "../../../../images";
import IntlMessages from "../../../../utils/messages";
import { EnumFilterType } from "../../../../utils/filter";
import { ALL_FILTER, IFilterField } from "../../../Table";

interface Props {
  intl: IntlShape;
  fields: {
    value: string;
    type: EnumFilterType;
    title?: string;
    options?: {
      value: string | number;
      label: string;
      code?: number;
    }[];
    async?: boolean;
    disabled?: boolean;
    dependsOn?: {
      field: string;
      operator: string;
    }[];
  }[];
  selectedFilters?: IFilterField<any>[];
  selectedValue?: string;
  defaultValue?: boolean;
  onChange: (type: string, e: any) => void;
  disabled?: boolean;
  isMulti?: boolean;
  placeholder?: string;
  className?: string;
  displayTitle?: boolean;
}

const selectStyles = (label?: string) => {
  const theme = useContext(ThemeContext);
  const { colors: themeColors, boxShadow } = theme;

  // We add 8 for the additional right padding between the label and the select value
  const labelWidth = label ? label.length * 8 + 8 : 0;

  return {
    container: (base, state) => {
      const { value, isMulti } = state.selectProps;

      if (isMulti) return base;

      const valueLength = value?.label?.length;
      // 48 is three times the value of the horizontal padding
      const width = `${(valueLength ?? 12) * 8 + labelWidth + 48}px`;

      return { ...base, width };
    },
    control: (base, state) => ({
      ...base,
      backgroundColor: state.isDisabled ? themeColors.gray[300] : "white",
      cursor: "pointer",
      borderRadius: 4,
      minHeight: 32,
      borderColor: state.isDisabled
        ? themeColors.gray[200]
        : state.isFocused
        ? themeColors.ground["gray-300"]
        : themeColors.ground["gray-400"],
      boxShadow: "transparent",
      ":hover": {
        borderColor: themeColors.ground["gray-300"],
      },
    }),
    option: (base, state) => {
      return {
        ...base,
        backgroundColor: "white",
        cursor: "pointer",
        color: state.isSelected
          ? themeColors.ground["blue-100"]
          : themeColors.neutral[900],
        fontSize: 14,
        lineHeight: 1.25,
        fontFamily: "OpenSans-Regular",
        ":hover": {
          backgroundColor: themeColors.neutral[300],
        },
      };
    },
    singleValue: (base, state) => ({
      ...base,
      color: state.isDisabled
        ? themeColors.primary[400]
        : themeColors.ground["blue-100"],
      fontSize: 14,
      lineHeight: 1.25,
      marginLeft: 4,
      fontFamily: "OpenSans-Regular",
    }),
    multiValue: base => ({
      ...base,
      borderRadius: 4,
      backgroundColor: themeColors.neutral[100],
    }),
    multiValueRemove: base => ({
      ...base,
      color: themeColors.ground["blue-100"],
      ":hover": {
        backgroundColor: themeColors.ground["blue-100"],
        color: "white",
      },
    }),
    menu: base => ({
      ...base,
      borderRadius: 8,
      borderColor: themeColors.ground["gray-250"],
      boxShadow: boxShadow["ground-1"],
      width: "max-content",
      minWidth: "100%",
    }),
    // Used for scaling the input to the right size of the placeholder
    placeholder: base => ({
      ...base,
      position: "static",
      transform: "none",
    }),
    clearIndicator: base => ({
      ...base,
      padding: 0,
    }),
  };
};

const DropdownIndicator = dropdownProps => {
  return (
    <components.DropdownIndicator {...dropdownProps}>
      <img src={images.chevronDown} alt="chevron-down" className="mr-2" />
    </components.DropdownIndicator>
  );
};

interface CustomSingleValueProps extends SingleValueProps {
  children: React.ReactNode;
}

const FilterSelect = (props: Props): JSX.Element => {
  const {
    fields,
    selectedFilters,
    onChange,
    selectedValue,
    intl,
    className,
    placeholder,
    disabled,
    isMulti,
  } = props;

  let { displayTitle, defaultValue } = props;

  if (displayTitle === undefined) displayTitle = true;
  if (defaultValue === undefined) defaultValue = true;

  const selectedFilterTypes = selectedFilters?.map(s => s.type);

  const { value, title, dependsOn } = fields[0];

  const selectedFilter = selectedFilters?.find(f => f.type === value);
  const currentValue = selectedFilter?.value;

  if (dependsOn && dependsOn?.length && selectedFilterTypes?.length) {
    const dependingFilter = dependsOn.filter(
      d =>
        d.operator === "filter_value" && selectedFilterTypes?.includes(d.field)
    );
    if (dependingFilter.length) {
      dependingFilter.forEach(d => {
        const f = selectedFilters?.find(s => s.type === d.field);
        if (f?.value.id !== ALL_FILTER) {
          fields[0].disabled = false;
          fields[0].options = selectedFilter?.options;
        }
      });
    }
  }

  const isDisabled = fields[0].disabled || disabled;
  const options = fields[0].options;

  // default value
  let v = defaultValue && options?.length ? options[0] : null;
  if (selectedValue && options?.length) {
    const opt = options.find(o => o.value === selectedValue);
    if (opt) {
      v = opt;
    }
  }

  const [selectValue, setSelectValue] = useState(currentValue || v);

  useEffect(() => {
    if (currentValue) setSelectValue(currentValue);
  }, [currentValue]);

  const CustomSingleValue = ({
    children,
    ...singleValueProps
  }: CustomSingleValueProps) => (
    <components.SingleValue {...singleValueProps}>
      {title && displayTitle && (
        <>
          <span className="text-ground-black-100 text-14px">
            <IntlMessages id={title} />
          </span>
          <span className="mx-2 border-t-0 border-b-0 border-l-0 border-r border-ground-gray-400 rounded-none shadow-none" />
        </>
      )}
      <span>{children}</span>
    </components.SingleValue>
  );

  const label = title ? intl.formatMessage({ id: title }) : undefined;

  return (
    <Select
      className={className}
      value={selectValue}
      menuPortalTarget={document.body}
      styles={selectStyles(label)}
      onChange={e => {
        setSelectValue(e);

        return onChange(value, e);
      }}
      options={options}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isSearchable={false}
      isMulti={isMulti}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator,
        SingleValue: CustomSingleValue,
      }}
    />
  );
};

export default injectIntl(FilterSelect);
