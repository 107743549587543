/*
 *
 * User Service
 *
 */
import { gql } from "apollo-boost";
import { AxiosResponse } from "axios";
import { ApolloClient } from "../../store/provider";
import { GetUserQuery } from "../../api/graphql/types";
import axiosBackend from "../../../../axiosBackend";

import {
  getUser as getUserQuery,
  searchUsers as searchUsersQuery,
  exportUsers as exportUsersQuery,
} from "../../api/graphql/queries";

import { fetchPolicy } from "../../api/client";
import {
  CreateUserOpts,
  SearchUsersOpts,
  UpdateUserOpts,
} from "../../models/User";

const DEFAULT_LIMIT = 100;

export const getUser = payload => {
  const request = payload.gql ? payload.gql : getUserQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: { id: payload.id },
    fetchPolicy,
  }) as {
    data: GetUserQuery;
  };
};

export const searchUsers = (payload: SearchUsersOpts) => {
  const request = payload.gql ? payload.gql : searchUsersQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: {
      filter: payload.filter,
      limit: payload
        ? payload.limit
          ? payload.limit
          : DEFAULT_LIMIT
        : DEFAULT_LIMIT,
      from: payload ? (payload.from ? payload.from : null) : null,
    },
  }) as {
    data: any;
  };
};

export const exportUsers = (payload: SearchUsersOpts) => {
  const request = payload.gql ? payload.gql : exportUsersQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: {
      filter: payload.filter,
      limit: payload.limit,
      from: payload.from,
    },
  }) as {
    data: any;
  };
};

/**
 * Create user
 *
 * @param {CreateUserOpts} payload
 * @return - A promise
 */
export const createUser = async (
  payload: CreateUserOpts
): Promise<AxiosResponse> => {
  return axiosBackend.post("/users", payload);
};

/**
 * Update user
 *
 * @param {UpdateUserOpts} payload
 * @return - A promise
 */
export const updateUser = async (
  payload: UpdateUserOpts
): Promise<AxiosResponse> => {
  return axiosBackend.put(`/users/${payload.id}`, _.omit(payload, "id"));
};

/**
 * delete user
 *
 * @param id
 * @returns
 */
export const deleteUser = async (id: string): Promise<AxiosResponse> => {
  return axiosBackend.delete(`/users/${id}`);
};
