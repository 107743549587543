import React from "react";
import ClickAwayListener from "react-click-away-listener";
import { getCypressTestId } from "../../utils/config";

interface Props {
  item: { id: string } | null;
  id: string;
  onClickAway: any;
  children: React.ReactElement<any>;
}

const MyClickAwayListener = (props: Props): JSX.Element => {
  const { onClickAway, children, id, item } = props;

  return (
    <div id={id} data-cy={id} data-testid={getCypressTestId(item)}>
      <ClickAwayListener onClickAway={onClickAway}>
        {children}
      </ClickAwayListener>
    </div>
  );
};

export default MyClickAwayListener;
