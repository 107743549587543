import React, { Fragment } from "react";
import _ from "lodash";
import Admin from "../../../components/Tailwind/Admin";
import { ActionTypes } from "../../../utils/types";
import IntlMessages from "../../../utils/messages";
import { Authorization } from "../../../lib/ground-aws-graphql-core/models/Authorization";

interface Props {
  items: { authorization: Authorization; action: ActionTypes }[];
  onRemoveAuthorization: (a: Authorization) => void;
}

const ListAuthorizations = (props: Props): JSX.Element => {
  const authorizations = props.items.filter(
    e => e.action !== ActionTypes.TO_DELETE
  );
  const noAuthorizations = _.isEmpty(authorizations);

  if (noAuthorizations) {
    return (
      <div className="flex justify-center sm:py-6 sm:px-6 px-2 py-2 bg-ground-white-200 rounded-b-10">
        <span className="text-12px text-ground-gray-100 italic">
          <IntlMessages id="page.center.administrators.empty" />
        </span>
      </div>
    );
  }

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:py-6 sm:px-6 px-2 py-2 bg-ground-white-200 rounded-b-10">
      {authorizations.map((i, index) => {
        return (
          <Fragment key={index}>
            <Admin
              authorization={i.authorization}
              onRemoveAuthorization={props.onRemoveAuthorization}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default ListAuthorizations;
