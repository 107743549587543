import React, { useEffect, useState } from "react";
import { $enum } from "ts-enum-util";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import overrideClasses from "../../../utils/overrideClasses";
import { FilterReporting } from "../../../components/Tailwind/Filters";
import {
  EnumReportingGlobalEntity,
  EnumReportingGlobalStatus,
  EnumReportingView,
} from "../../../lib/ground-aws-graphql-core/models/Product";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import contextStore from "../../../redux/store";
import { getLocale } from "../../../lang";
import ReportingView from "../../../components/Tailwind/ReportingView";
import { getReportingDates } from "../../../utils/filter";
import GlobalCardList from "./global-card-list";
import ReportingEntity from "../../../components/Tailwind/ReportingEntity";
import {
  getTodayInCenterTimezone,
  getDateInUtcUsingOffset,
  addDuration,
  eachUnitOfInterval,
  isSameDates,
  transformDateForQuery,
  displayDayLabel,
} from "../../../utils/config";

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
  backURL: string;
}

const GlobalDashboard = (props: Props): JSX.Element => {
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  // init weekly view
  const [view, setView] = useState(EnumReportingView.MONTH);

  // init weekly view
  const [entity, setEntity] = useState(EnumReportingGlobalEntity.CENTER);

  const now = getTodayInCenterTimezone(centerTimezone);
  const { current } = getReportingDates(now, view, centerTimezone);

  const [date, setDate] = useState(now);
  const [startDate, setStartDate] = useState(current?.start);
  const [endDate, setEndDate] = useState(current?.end);

  const listReportingsGlobal = GroundGraphqlContextStore.useStoreActions(
    actions => actions.reporting.listReportingsGlobal
  );

  const reportingsGlobal = GroundGraphqlContextStore.useStoreState(
    state => state.reporting.reportingsGlobal.items
  );

  const status = $enum(EnumReportingGlobalStatus).getValues();
  const allStatus = [undefined, ...status];

  const dataByStatus = allStatus.map(s => {
    const data1 = s
      ? reportingsGlobal.filter(
          a => a.status === s && !a.createdStartDate && !a.createdEndDate
        )
      : reportingsGlobal.filter(
          a => !a.status && !a.createdStartDate && !a.createdEndDate
        );
    const data2 = s
      ? reportingsGlobal.filter(
          a => a.status === s && a.createdStartDate && a.createdEndDate
        )
      : reportingsGlobal.filter(
          a => !a.status && a.createdStartDate && a.createdEndDate
        );

    return {
      status: s,
      data: data1,
      range_date: data2,
    };
  });

  // with no status
  const dataAllForView = dataByStatus.find(d => !d.status)?.data || [];

  const dataEnabledForView =
    dataByStatus.find(d => d.status === EnumReportingGlobalStatus.ENABLED)
      ?.data || [];

  const dataDisabledForView =
    dataByStatus.find(d => d.status === EnumReportingGlobalStatus.DISABLED)
      ?.data || [];

  const dataDeletedForView =
    dataByStatus.find(d => d.status === EnumReportingGlobalStatus.DELETED)
      ?.data || [];

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  const days = eachUnitOfInterval(startDate, endDate, "day");

  useEffect(() => {
    fetchData();
  }, [startDate, view, entity]);

  const fetchData = () => {
    setLoading(true);

    Promise.all([
      listReportingsGlobal({
        filter: {
          date: transformDateForQuery(startDate, centerTimezone, view),
          view,
          entity: entity,
        },
      }),
    ]).finally(() => setLoading(false));
  };

  const onChangeDate = (date: Date) => {
    const { current } = getReportingDates(date, view, centerTimezone);
    setDate(date);
    setStartDate(current.start);
    setEndDate(current.end);
  };

  const onPrevious = () => {
    const { previous } = getReportingDates(date, view, centerTimezone);

    setStartDate(previous.start);
    setEndDate(previous.end);
    setDate(previous.start);
  };

  const onNext = () => {
    const { next } = getReportingDates(date, view, centerTimezone);

    setStartDate(next.start);
    setEndDate(next.end);
    setDate(next.start);
  };

  const handleOnChangeReportingView = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const view = e.target.value as EnumReportingView;
    setView(view);
    const { current } = getReportingDates(now, view, centerTimezone);
    setDate(current.start);
    setStartDate(current.start);
    setEndDate(current.end);
  };

  const handleOnChangeReportingEntity = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const entity = e.target.value as EnumReportingGlobalEntity;
    setEntity(entity);
  };

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        tools: {
          download: true,
          pan: false,
          reset: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: days.map(d =>
        displayDayLabel(d, currentAppLocale, centerTimezone)
      ),
    },
    colors: ["#309bff", "#29be95", "#f05e5e", "#ffe0a4"],
    yaxis: {
      title: {
        text: intl.formatMessage({
          id: `general.number`,
        }),
      },
      min: 0,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val?.toString();
        },
      },
    },
  };

  const getTitle = (entity: EnumReportingGlobalEntity) => {
    return `${intl.formatMessage({
      id: `page.dashboards.chart.${view.toLowerCase()}.${entity.toLowerCase()}.breakdown.title`,
    })}`;
  };

  const getReportingStatusData = () => {
    const results = allStatus.map(s => {
      const data = days.map(date => {
        const data = s
          ? dataByStatus.find(d => d.status === s)?.range_date || []
          : dataByStatus.find(d => !d.status)?.range_date || [];

        const value = data?.find(
          e =>
            isSameDates(
              new Date(e.createdStartDate),
              getDateInUtcUsingOffset(date)
            ) &&
            isSameDates(
              new Date(e.createdEndDate),
              getDateInUtcUsingOffset(addDuration(date, 1, "day"))
            )
        );

        return {
          date: displayDayLabel(date, currentAppLocale, centerTimezone),
          total: value ? value.total : 0,
          variation: value ? value.variation : 0,
        };
      });

      return {
        name: s,
        data,
      };
    });

    return results;
  };

  const renderReporting = () => {
    const data = getReportingStatusData();

    const series = allStatus.map(s => {
      const dataByStatus = data.find(r => r.name === s)?.data;
      const key = s ? EnumReportingGlobalStatus[s] : "ALL";

      return {
        name: `${intl.formatMessage({
          id: `page.dashboards.chart.total.${key.toLowerCase()}.title`,
        })}`,
        data: dataByStatus?.map(d => d.total) || [],
      };
    });

    return (
      <div className="m-10">
        <ReactApexChart
          options={{
            ...options,
            title: {
              text: getTitle(entity),
              align: "left",
            },
          }}
          series={series}
          type="line"
          height={600}
        />
      </div>
    );
  };

  return (
    <>
      <div className={overrideClasses({ loading })} />

      <div className={overrideClasses({ hidden: loading })}>
        <Helmet>
          <title>
            {intl.formatMessage({ id: "page.dashboards.document.title" })}
          </title>
        </Helmet>

        <div className="flex justify-between p-8">
          <div className="flex items-center gap-4">
            <ReportingView view={view} onChange={handleOnChangeReportingView} />

            <FilterReporting
              date={date}
              view={view}
              locale={currentAppLocale}
              onChangeDate={onChangeDate}
              onPrevious={onPrevious}
              onNext={onNext}
            />
          </div>

          <div className="flex">
            <ReportingEntity
              entity={entity}
              onChange={handleOnChangeReportingEntity}
            />
          </div>
        </div>

        <div className="mx-8">
          <GlobalCardList
            {...props}
            data={{
              all: dataAllForView[0],
              enabled: dataEnabledForView[0],
              disabled: dataDisabledForView[0],
              deleted: dataDeletedForView[0],
            }}
          />
        </div>
        <div id="chart_global">{renderReporting()}</div>
      </div>
    </>
  );
};

export default injectIntl(GlobalDashboard);
