import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import { $enum } from "ts-enum-util";
import { EnumReportingView } from "../../../lib/ground-aws-graphql-core/models/Product";

interface Props {
  intl: IntlShape;
  view: EnumReportingView;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const ReportingView = (props: Props): JSX.Element => {
  const { intl, view, onChange } = props;

  const views = $enum(EnumReportingView).getKeys();

  return (
    <div className="h-8 my-4 inline-block border border-ground-gray-400 rounded-4">
      <select
        id="reporting-view"
        name="reporting-view"
        className="form-select h-full py-0 bg-transparent text-ground-black-100 text-14px leading-5 border-none rounded-none shadow-none"
        onChange={onChange}
        defaultValue={view}
      >
        {views.map(d => (
          <option key={d} value={d}>
            {intl.formatMessage({
              id: `general.view.${d}`,
            })}
          </option>
        ))}
      </select>
    </div>
  );
};

export default injectIntl(ReportingView);
