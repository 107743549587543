const theme = {
  lineClamp: { 1: "1", 2: "2", 3: "3", 4: "4", 5: "5", 6: "6" },
  colors: {
    transparent: "transparent",
    white: "#ffffff",
    black: "#000000",
    gray: {
      100: "#F8F8FA",
      200: "#EFEFF2",
      300: "#3063D514",
      400: "#3063D526",
      500: "#8F9197",
      600: "#898E9A",
      700: "#707070",
      800: "#2d3748",
      900: "#212631",
    },
    "cool-gray": {
      50: "#fbfdfe",
      100: "#f1f5f9",
      200: "#e2e8f0",
      300: "#cfd8e3",
      400: "#97a6ba",
      500: "#64748b",
      600: "#475569",
      700: "#364152",
      800: "#27303f",
      900: "#1a202e",
    },
    red: {
      100: "#fff5f5",
      200: "#fed7d7",
      300: "#feb2b2",
      400: "#fc8181",
      500: "#FF6565",
      600: "#e53e3e",
      700: "#c53030",
      800: "#9b2c2c",
      900: "#742a2a",
    },
    orange: {
      50: "#fff8f1",
      100: "#feecdc",
      200: "#fcd9bd",
      300: "#fdba8c",
      400: "#ff8a4c",
      500: "#ff5a1f",
      600: "#d03801",
      700: "#b43403",
      800: "#8a2c0d",
      900: "#771d1d",
    },
    yellow: {
      50: "#fdfdea",
      100: "#fdf6b2",
      200: "#fce96a",
      300: "#faca15",
      400: "#e3a008",
      500: "#c27803",
      600: "#9f580a",
      700: "#8e4b10",
      800: "#723b13",
      900: "#633112",
    },
    green: {
      50: "#f3faf7",
      100: "#def7ec",
      200: "#bcf0da",
      300: "#84e1bc",
      400: "#31c48d",
      500: "#0e9f6e",
      600: "#057a55",
      700: "#046c4e",
      800: "#03543f",
      900: "#014737",
    },
    teal: {
      50: "#edfafa",
      100: "#d5f5f6",
      200: "#afecef",
      300: "#7edce2",
      400: "#16bdca",
      500: "#0694a2",
      600: "#047481",
      700: "#036672",
      800: "#05505c",
      900: "#014451",
    },
    blue: {
      50: "#ebf5ff",
      100: "#e1effe",
      200: "#c3ddfd",
      300: "#a4cafe",
      400: "#76a9fa",
      500: "#3f83f8",
      600: "#1c64f2",
      700: "#1a56db",
      800: "#1e429f",
      900: "#233876",
    },
    indigo: {
      50: "#f0f5ff",
      100: "#e5edff",
      200: "#cddbfe",
      300: "#b4c6fc",
      400: "#8da2fb",
      500: "#6875f5",
      600: "#5850ec",
      700: "#5145cd",
      800: "#42389d",
      900: "#362f78",
    },
    purple: {
      50: "#f6f5ff",
      100: "#edebfe",
      200: "#dcd7fe",
      300: "#cabffd",
      400: "#ac94fa",
      500: "#9061f9",
      600: "#7e3af2",
      700: "#6c2bd9",
      800: "#5521b5",
      900: "#4a1d96",
    },
    pink: {
      50: "#fdf2f8",
      100: "#fce8f3",
      200: "#fad1e8",
      300: "#f8b4d9",
      400: "#f17eb8",
      500: "#e74694",
      600: "#d61f69",
      700: "#bf125d",
      800: "#99154b",
      900: "#751a3d",
    },
    ground: {
      "green-100": "#00D480",
      "black-100": "#212631",
      "gray-100": "#898E9A",
      "gray-200": "rgba(48, 99, 213, 0.08)",
      "gray-250": "rgba(48, 99, 213, 0.1)",
      "gray-300": "#989EAC",
      "gray-400": "#CBCDD4",
      "gray-500": "#d2d6dc",
      "blue-100": "#3063d5",
      "blue-200": "#3063D580",
      "blue-300": "#a7bceb",
      "blue-400": "#3063D5CC",
      "blue-500": "#1677FF",
      "blue-600": "#E6F4FF",
      "blue-700": "#69B1FF",
      "white-100": "#F9F9FC",
      "white-200": "#F8F8FA",
      "white-300": "#EFF3FC",
      "yellow-100": "#eac603",
      "gradiant-100": "#e2e2f2",
    },
    neutral: { 100: "#eaeffb", 300: "#f5f5fa", 900: "#212121" },
    primary: { 400: "#8ca1d9" },
  },
  spacing: {
    0: "0",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    13: "3.25rem",
    14: "3.5rem",
    15: "3.75rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    48: "12rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "0.875rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    "1/12": "8.333333%",
    "2/12": "16.666667%",
    "3/12": "25%",
    "4/12": "33.333333%",
    "5/12": "41.666667%",
    "6/12": "50%",
    "7/12": "58.333333%",
    "8/12": "66.666667%",
    "9/12": "75%",
    "10/12": "83.333333%",
    "11/12": "91.666667%",
    full: "100%",
  },
  boxShadow: {
    xs: "0 0 0 1px rgba(0, 0, 0, 0.05)",
    sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    default: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
    outline: "0 0 0 3px rgba(118, 169, 250, 0.45)",
    none: "none",
    solid: "0 0 0 2px currentColor",
    "outline-gray": "0 0 0 3px rgba(159, 166, 178, 0.45)",
    "outline-blue": "0 0 0 3px rgba(164, 202, 254, 0.45)",
    "outline-teal": "0 0 0 3px rgba(126, 220, 226, 0.45)",
    "outline-green": "0 0 0 3px rgba(132, 225, 188, 0.45)",
    "outline-yellow": "0 0 0 3px rgba(250, 202, 21, 0.45)",
    "outline-orange": "0 0 0 3px rgba(253, 186, 140, 0.45)",
    "outline-red": "0 0 0 3px rgba(248, 180, 180, 0.45)",
    "outline-pink": "0 0 0 3px rgba(248, 180, 217, 0.45)",
    "outline-purple": "0 0 0 3px rgba(202, 191, 253, 0.45)",
    "outline-indigo": "0 0 0 3px rgba(180, 198, 252, 0.45)",
    "ground-2": "0px 2px 5px #3063D526",
    "ground-10": "0px 10px 20px #3063D514",
    "ground-3": "0 3.5px 5px 0 rgba(48, 99, 213, 0.15)",
    "ground-1": "0 2px 4px 0 rgba(48, 99, 213, 0.08)",
  },
  maxWidth: {
    none: "none",
    xs: "20rem",
    sm: "24rem",
    md: "28rem",
    lg: "32rem",
    xl: "36rem",
    "2xl": "42rem",
    "3xl": "48rem",
    "4xl": "56rem",
    "5xl": "64rem",
    "6xl": "72rem",
    "7xl": "80rem",
    full: "100%",
    "screen-sm": "640px",
    "screen-md": "768px",
    "screen-lg": "1024px",
    "screen-xl": "1280px",
  },
  maxHeight: {
    0: "0",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    13: "3.25rem",
    14: "3.5rem",
    15: "3.75rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    48: "12rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    screen: "100vh",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "0.875rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    "1/12": "8.333333%",
    "2/12": "16.666667%",
    "3/12": "25%",
    "4/12": "33.333333%",
    "5/12": "41.666667%",
    "6/12": "50%",
    "7/12": "58.333333%",
    "8/12": "66.666667%",
    "9/12": "75%",
    "10/12": "83.333333%",
    "11/12": "91.666667%",
    full: "100%",
    "350px": "350px",
  },
  inset: {
    0: "0",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    13: "3.25rem",
    14: "3.5rem",
    15: "3.75rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "40px",
    48: "12rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    auto: "auto",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "0.875rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    "1/12": "8.333333%",
    "2/12": "16.666667%",
    "3/12": "25%",
    "4/12": "33.333333%",
    "5/12": "41.666667%",
    "6/12": "50%",
    "7/12": "58.333333%",
    "8/12": "66.666667%",
    "9/12": "75%",
    "10/12": "83.333333%",
    "11/12": "91.666667%",
    full: "100%",
  },
  customForms: {
    default: {
      input: {
        appearance: "none",
        backgroundColor: "#ffffff",
        borderColor: "#d2d6dc",
        borderWidth: "1px",
        borderRadius: "0.375rem",
        paddingTop: "0.5rem",
        paddingRight: "0.75rem",
        paddingBottom: "0.5rem",
        paddingLeft: "0.75rem",
        fontSize: "1rem",
        lineHeight: "1.5",
        "&::placeholder": { color: "#9fa6b2" },
        "&:focus": {
          outline: "none",
          boxShadow: "0 0 0 3px rgba(164, 202, 254, 0.45)",
          borderColor: "#a4cafe",
        },
      },
      textarea: {
        appearance: "none",
        backgroundColor: "#ffffff",
        borderColor: "#d2d6dc",
        borderWidth: "1px",
        borderRadius: "0.375rem",
        paddingTop: "0.5rem",
        paddingRight: "0.75rem",
        paddingBottom: "0.5rem",
        paddingLeft: "0.75rem",
        fontSize: "1rem",
        lineHeight: "1.5",
        "&::placeholder": { color: "#9fa6b2", opacity: "1" },
        "&:focus": {
          outline: "none",
          boxShadow: "0 0 0 3px rgba(164, 202, 254, 0.45)",
          borderColor: "#a4cafe",
        },
      },
      multiselect: {
        appearance: "none",
        backgroundColor: "#ffffff",
        borderColor: "#d2d6dc",
        borderWidth: "1px",
        borderRadius: "0.375rem",
        paddingTop: "0.5rem",
        paddingRight: "0.75rem",
        paddingBottom: "0.5rem",
        paddingLeft: "0.75rem",
        fontSize: "1rem",
        lineHeight: "1.5",
        "&:focus": {
          outline: "none",
          boxShadow: "0 0 0 3px rgba(164, 202, 254, 0.45)",
          borderColor: "#a4cafe",
        },
      },
      select: {
        appearance: "none",
        colorAdjust: "exact",
        "&::-ms-expand": {
          border: "none",
          "@media not print": { display: "none" },
        },
        backgroundRepeat: "no-repeat",
        backgroundColor: "#ffffff",
        borderColor: "#d2d6dc",
        borderWidth: "1px",
        borderRadius: "0.375rem",
        paddingTop: "0.5rem",
        paddingRight: "2.5rem",
        paddingBottom: "0.5rem",
        paddingLeft: "0.75rem",
        fontSize: "1rem",
        lineHeight: "1.5",
        backgroundPosition: "right 0.5rem center",
        backgroundSize: "1.5em 1.5em",
        iconColor: "#9fa6b2",
        "&:focus": {
          outline: "none",
          boxShadow: "0 0 0 3px rgba(164, 202, 254, 0.45)",
          borderColor: "#a4cafe",
        },
      },
      checkbox: {
        appearance: "none",
        colorAdjust: "exact",
        "&::-ms-check": {
          "@media not print": {
            color: "transparent",
            background: "inherit",
            borderColor: "inherit",
            borderRadius: "inherit",
          },
        },
        display: "inline-block",
        verticalAlign: "middle",
        backgroundOrigin: "border-box",
        userSelect: "none",
        flexShrink: 0,
        height: "1rem",
        width: "1rem",
        color: "#3f83f8",
        backgroundColor: "#ffffff",
        borderColor: "#d2d6dc",
        borderWidth: "1px",
        borderRadius: "0.25rem",
        iconColor: "#ffffff",
        "&:focus": {
          outline: "none",
          boxShadow: "0 0 0 3px rgba(164, 202, 254, 0.45)",
          borderColor: "#a4cafe",
        },
        "&:checked": {
          borderColor: "transparent",
          backgroundColor: "currentColor",
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        },
        "&:checked:focus": { borderColor: "transparent" },
      },
      radio: {
        appearance: "none",
        colorAdjust: "exact",
        "&::-ms-check": {
          "@media not print": {
            color: "transparent",
            background: "inherit",
            borderColor: "inherit",
            borderRadius: "inherit",
          },
        },
        display: "inline-block",
        verticalAlign: "middle",
        backgroundOrigin: "border-box",
        userSelect: "none",
        flexShrink: 0,
        borderRadius: "100%",
        height: "1rem",
        width: "1rem",
        color: "#3f83f8",
        backgroundColor: "#ffffff",
        borderColor: "#d2d6dc",
        borderWidth: "1px",
        iconColor: "#ffffff",
        "&:focus": {
          outline: "none",
          boxShadow: "0 0 0 3px rgba(164, 202, 254, 0.45)",
          borderColor: "#a4cafe",
        },
        "&:checked": {
          borderColor: "transparent",
          backgroundColor: "currentColor",
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        },
        "&:checked:focus": { borderColor: "transparent" },
      },
    },
  },
  screens: { sm: "640px", md: "768px", lg: "1024px", xl: "1280px" },
  backgroundColor: {
    transparent: "transparent",
    white: "#ffffff",
    black: "#000000",
    gray: {
      100: "#F8F8FA",
      200: "#EFEFF2",
      300: "#3063D514",
      400: "#3063D526",
      500: "#8F9197",
      600: "#898E9A",
      700: "#707070",
      800: "#2d3748",
      900: "#212631",
    },
    "cool-gray": {
      50: "#fbfdfe",
      100: "#f1f5f9",
      200: "#e2e8f0",
      300: "#cfd8e3",
      400: "#97a6ba",
      500: "#64748b",
      600: "#475569",
      700: "#364152",
      800: "#27303f",
      900: "#1a202e",
    },
    red: {
      100: "#fff5f5",
      200: "#fed7d7",
      300: "#feb2b2",
      400: "#fc8181",
      500: "#FF6565",
      600: "#e53e3e",
      700: "#c53030",
      800: "#9b2c2c",
      900: "#742a2a",
    },
    orange: {
      50: "#fff8f1",
      100: "#feecdc",
      200: "#fcd9bd",
      300: "#fdba8c",
      400: "#ff8a4c",
      500: "#ff5a1f",
      600: "#d03801",
      700: "#b43403",
      800: "#8a2c0d",
      900: "#771d1d",
    },
    yellow: {
      50: "#fdfdea",
      100: "#fdf6b2",
      200: "#fce96a",
      300: "#faca15",
      400: "#e3a008",
      500: "#c27803",
      600: "#9f580a",
      700: "#8e4b10",
      800: "#723b13",
      900: "#633112",
    },
    green: {
      50: "#f3faf7",
      100: "#def7ec",
      200: "#bcf0da",
      300: "#84e1bc",
      400: "#31c48d",
      500: "#0e9f6e",
      600: "#057a55",
      700: "#046c4e",
      800: "#03543f",
      900: "#014737",
    },
    teal: {
      50: "#edfafa",
      100: "#d5f5f6",
      200: "#afecef",
      300: "#7edce2",
      400: "#16bdca",
      500: "#0694a2",
      600: "#047481",
      700: "#036672",
      800: "#05505c",
      900: "#014451",
    },
    blue: {
      50: "#ebf5ff",
      100: "#e1effe",
      200: "#c3ddfd",
      300: "#a4cafe",
      400: "#76a9fa",
      500: "#3f83f8",
      600: "#1c64f2",
      700: "#1a56db",
      800: "#1e429f",
      900: "#233876",
    },
    indigo: {
      50: "#f0f5ff",
      100: "#e5edff",
      200: "#cddbfe",
      300: "#b4c6fc",
      400: "#8da2fb",
      500: "#6875f5",
      600: "#5850ec",
      700: "#5145cd",
      800: "#42389d",
      900: "#362f78",
    },
    purple: {
      50: "#f6f5ff",
      100: "#edebfe",
      200: "#dcd7fe",
      300: "#cabffd",
      400: "#ac94fa",
      500: "#9061f9",
      600: "#7e3af2",
      700: "#6c2bd9",
      800: "#5521b5",
      900: "#4a1d96",
    },
    pink: {
      50: "#fdf2f8",
      100: "#fce8f3",
      200: "#fad1e8",
      300: "#f8b4d9",
      400: "#f17eb8",
      500: "#e74694",
      600: "#d61f69",
      700: "#bf125d",
      800: "#99154b",
      900: "#751a3d",
    },
    ground: {
      "green-100": "#00D480",
      "black-100": "#212631",
      "gray-100": "#898E9A",
      "gray-200": "rgba(48, 99, 213, 0.08)",
      "gray-250": "rgba(48, 99, 213, 0.1)",
      "gray-300": "#989EAC",
      "gray-400": "#CBCDD4",
      "gray-500": "#d2d6dc",
      "blue-100": "#3063d5",
      "blue-200": "#3063D580",
      "blue-300": "#a7bceb",
      "blue-400": "#3063D5CC",
      "white-100": "#F9F9FC",
      "white-200": "#F8F8FA",
      "white-300": "#EFF3FC",
      "yellow-100": "#eac603",
      "gradiant-100": "#e2e2f2",
    },
    neutral: { 100: "#eaeffb", 300: "#f5f5fa", 900: "#212121" },
    primary: { 400: "#8ca1d9" },
  },
  backgroundImage: {
    none: "none",
    "gradient-to-t": "linear-gradient(to top, var(--gradient-color-stops))",
    "gradient-to-tr":
      "linear-gradient(to top right, var(--gradient-color-stops))",
    "gradient-to-r": "linear-gradient(to right, var(--gradient-color-stops))",
    "gradient-to-br":
      "linear-gradient(to bottom right, var(--gradient-color-stops))",
    "gradient-to-b": "linear-gradient(to bottom, var(--gradient-color-stops))",
    "gradient-to-bl":
      "linear-gradient(to bottom left, var(--gradient-color-stops))",
    "gradient-to-l": "linear-gradient(to left, var(--gradient-color-stops))",
    "gradient-to-tl":
      "linear-gradient(to top left, var(--gradient-color-stops))",
  },
  gradientColorStops: {
    transparent: "transparent",
    white: "#ffffff",
    black: "#000000",
    gray: {
      100: "#F8F8FA",
      200: "#EFEFF2",
      300: "#3063D514",
      400: "#3063D526",
      500: "#8F9197",
      600: "#898E9A",
      700: "#707070",
      800: "#2d3748",
      900: "#212631",
    },
    "cool-gray": {
      50: "#fbfdfe",
      100: "#f1f5f9",
      200: "#e2e8f0",
      300: "#cfd8e3",
      400: "#97a6ba",
      500: "#64748b",
      600: "#475569",
      700: "#364152",
      800: "#27303f",
      900: "#1a202e",
    },
    red: {
      100: "#fff5f5",
      200: "#fed7d7",
      300: "#feb2b2",
      400: "#fc8181",
      500: "#FF6565",
      600: "#e53e3e",
      700: "#c53030",
      800: "#9b2c2c",
      900: "#742a2a",
    },
    orange: {
      50: "#fff8f1",
      100: "#feecdc",
      200: "#fcd9bd",
      300: "#fdba8c",
      400: "#ff8a4c",
      500: "#ff5a1f",
      600: "#d03801",
      700: "#b43403",
      800: "#8a2c0d",
      900: "#771d1d",
    },
    yellow: {
      50: "#fdfdea",
      100: "#fdf6b2",
      200: "#fce96a",
      300: "#faca15",
      400: "#e3a008",
      500: "#c27803",
      600: "#9f580a",
      700: "#8e4b10",
      800: "#723b13",
      900: "#633112",
    },
    green: {
      50: "#f3faf7",
      100: "#def7ec",
      200: "#bcf0da",
      300: "#84e1bc",
      400: "#31c48d",
      500: "#0e9f6e",
      600: "#057a55",
      700: "#046c4e",
      800: "#03543f",
      900: "#014737",
    },
    teal: {
      50: "#edfafa",
      100: "#d5f5f6",
      200: "#afecef",
      300: "#7edce2",
      400: "#16bdca",
      500: "#0694a2",
      600: "#047481",
      700: "#036672",
      800: "#05505c",
      900: "#014451",
    },
    blue: {
      50: "#ebf5ff",
      100: "#e1effe",
      200: "#c3ddfd",
      300: "#a4cafe",
      400: "#76a9fa",
      500: "#3f83f8",
      600: "#1c64f2",
      700: "#1a56db",
      800: "#1e429f",
      900: "#233876",
    },
    indigo: {
      50: "#f0f5ff",
      100: "#e5edff",
      200: "#cddbfe",
      300: "#b4c6fc",
      400: "#8da2fb",
      500: "#6875f5",
      600: "#5850ec",
      700: "#5145cd",
      800: "#42389d",
      900: "#362f78",
    },
    purple: {
      50: "#f6f5ff",
      100: "#edebfe",
      200: "#dcd7fe",
      300: "#cabffd",
      400: "#ac94fa",
      500: "#9061f9",
      600: "#7e3af2",
      700: "#6c2bd9",
      800: "#5521b5",
      900: "#4a1d96",
    },
    pink: {
      50: "#fdf2f8",
      100: "#fce8f3",
      200: "#fad1e8",
      300: "#f8b4d9",
      400: "#f17eb8",
      500: "#e74694",
      600: "#d61f69",
      700: "#bf125d",
      800: "#99154b",
      900: "#751a3d",
    },
    ground: {
      "green-100": "#00D480",
      "black-100": "#212631",
      "gray-100": "#898E9A",
      "gray-200": "rgba(48, 99, 213, 0.08)",
      "gray-250": "rgba(48, 99, 213, 0.1)",
      "gray-300": "#989EAC",
      "gray-400": "#CBCDD4",
      "gray-500": "#d2d6dc",
      "blue-100": "#3063d5",
      "blue-200": "#3063D580",
      "blue-300": "#a7bceb",
      "blue-400": "#3063D5CC",
      "blue-500": "#E6F4FF",
      "blue-600": "#69B1FF",
      "blue-700": "#1677FF",
      "orange-100": "#FFFBE6",
      "orange-200": "#FFD666",
      "orange-300": "#FFAD14",
      "green-200": "#DAF7A6",
      "green-300": "#9DD10B",
      "green-400": "#94C310",
      "white-100": "#F9F9FC",
      "white-200": "#F8F8FA",
      "white-300": "#EFF3FC",
      "yellow-100": "#eac603",
      "gradiant-100": "#e2e2f2",
    },
    neutral: { 100: "#eaeffb", 300: "#f5f5fa", 900: "#212121" },
    primary: { 400: "#8ca1d9" },
  },
  backgroundOpacity: {
    0: "0",
    25: "0.25",
    50: "0.5",
    70: ".7",
    75: "0.75",
    90: ".9",
    100: "1",
  },
  backgroundPosition: {
    bottom: "bottom",
    center: "center",
    left: "left",
    "left-bottom": "left bottom",
    "left-top": "left top",
    right: "right",
    "right-bottom": "right bottom",
    "right-top": "right top",
    top: "top",
  },
  backgroundSize: { auto: "auto", cover: "cover", contain: "contain" },
  borderColor: {
    transparent: "transparent",
    white: "#ffffff",
    black: "#000000",
    gray: {
      100: "#F8F8FA",
      200: "#EFEFF2",
      300: "#3063D514",
      400: "#3063D526",
      500: "#8F9197",
      600: "#898E9A",
      700: "#707070",
      800: "#2d3748",
      900: "#212631",
    },
    "cool-gray": {
      50: "#fbfdfe",
      100: "#f1f5f9",
      200: "#e2e8f0",
      300: "#cfd8e3",
      400: "#97a6ba",
      500: "#64748b",
      600: "#475569",
      700: "#364152",
      800: "#27303f",
      900: "#1a202e",
    },
    red: {
      100: "#fff5f5",
      200: "#fed7d7",
      300: "#feb2b2",
      400: "#fc8181",
      500: "#FF6565",
      600: "#e53e3e",
      700: "#c53030",
      800: "#9b2c2c",
      900: "#742a2a",
    },
    orange: {
      50: "#fff8f1",
      100: "#feecdc",
      200: "#fcd9bd",
      300: "#fdba8c",
      400: "#ff8a4c",
      500: "#ff5a1f",
      600: "#d03801",
      700: "#b43403",
      800: "#8a2c0d",
      900: "#771d1d",
    },
    yellow: {
      50: "#fdfdea",
      100: "#fdf6b2",
      200: "#fce96a",
      300: "#faca15",
      400: "#e3a008",
      500: "#c27803",
      600: "#9f580a",
      700: "#8e4b10",
      800: "#723b13",
      900: "#633112",
    },
    green: {
      50: "#f3faf7",
      100: "#def7ec",
      200: "#bcf0da",
      300: "#84e1bc",
      400: "#31c48d",
      500: "#0e9f6e",
      600: "#057a55",
      700: "#046c4e",
      800: "#03543f",
      900: "#014737",
    },
    teal: {
      50: "#edfafa",
      100: "#d5f5f6",
      200: "#afecef",
      300: "#7edce2",
      400: "#16bdca",
      500: "#0694a2",
      600: "#047481",
      700: "#036672",
      800: "#05505c",
      900: "#014451",
    },
    blue: {
      50: "#ebf5ff",
      100: "#e1effe",
      200: "#c3ddfd",
      300: "#a4cafe",
      400: "#76a9fa",
      500: "#3f83f8",
      600: "#1c64f2",
      700: "#1a56db",
      800: "#1e429f",
      900: "#233876",
    },
    indigo: {
      50: "#f0f5ff",
      100: "#e5edff",
      200: "#cddbfe",
      300: "#b4c6fc",
      400: "#8da2fb",
      500: "#6875f5",
      600: "#5850ec",
      700: "#5145cd",
      800: "#42389d",
      900: "#362f78",
    },
    purple: {
      50: "#f6f5ff",
      100: "#edebfe",
      200: "#dcd7fe",
      300: "#cabffd",
      400: "#ac94fa",
      500: "#9061f9",
      600: "#7e3af2",
      700: "#6c2bd9",
      800: "#5521b5",
      900: "#4a1d96",
    },
    pink: {
      50: "#fdf2f8",
      100: "#fce8f3",
      200: "#fad1e8",
      300: "#f8b4d9",
      400: "#f17eb8",
      500: "#e74694",
      600: "#d61f69",
      700: "#bf125d",
      800: "#99154b",
      900: "#751a3d",
    },
    ground: {
      "green-100": "#00D480",
      "black-100": "#212631",
      "gray-100": "#898E9A",
      "gray-200": "rgba(48, 99, 213, 0.08)",
      "gray-250": "rgba(48, 99, 213, 0.1)",
      "gray-300": "#989EAC",
      "gray-400": "#CBCDD4",
      "gray-500": "#d2d6dc",
      "blue-100": "#3063d5",
      "blue-200": "#3063D580",
      "blue-300": "#a7bceb",
      "blue-400": "#3063D5CC",
      "white-100": "#F9F9FC",
      "white-200": "#F8F8FA",
      "white-300": "#EFF3FC",
      "yellow-100": "#eac603",
      "gradiant-100": "#e2e2f2",
    },
    neutral: { 100: "#eaeffb", 300: "#f5f5fa", 900: "#212121" },
    primary: { 400: "#8ca1d9" },
    default: "#3063D514",
  },
  borderOpacity: {
    0: "0",
    25: "0.25",
    50: "0.5",
    70: ".7",
    75: "0.75",
    90: ".9",
    100: "1",
  },
  borderRadius: {
    3: "3px",
    4: "4px",
    5: "5px",
    10: "10px",
    none: "0",
    sm: "0.125rem",
    default: "0.25rem",
    md: "0.375rem",
    lg: "0.5rem",
    xl: "0.75rem",
    "2xl": "1rem",
    "3xl": "1.5rem",
    full: "9999px",
    "1p5": "1.5px",
  },
  borderWidth: {
    0: "0",
    2: "2px",
    4: "4px",
    8: "8px",
    default: "1px",
    "05": "0.5px",
  },
  container: {},
  cursor: {
    auto: "auto",
    default: "default",
    pointer: "pointer",
    wait: "wait",
    text: "text",
    move: "move",
    "not-allowed": "not-allowed",
  },
  divideColor: {
    transparent: "transparent",
    white: "#ffffff",
    black: "#000000",
    gray: {
      100: "#F8F8FA",
      200: "#EFEFF2",
      300: "#3063D514",
      400: "#3063D526",
      500: "#8F9197",
      600: "#898E9A",
      700: "#707070",
      800: "#2d3748",
      900: "#212631",
    },
    "cool-gray": {
      50: "#fbfdfe",
      100: "#f1f5f9",
      200: "#e2e8f0",
      300: "#cfd8e3",
      400: "#97a6ba",
      500: "#64748b",
      600: "#475569",
      700: "#364152",
      800: "#27303f",
      900: "#1a202e",
    },
    red: {
      100: "#fff5f5",
      200: "#fed7d7",
      300: "#feb2b2",
      400: "#fc8181",
      500: "#FF6565",
      600: "#e53e3e",
      700: "#c53030",
      800: "#9b2c2c",
      900: "#742a2a",
    },
    orange: {
      50: "#fff8f1",
      100: "#feecdc",
      200: "#fcd9bd",
      300: "#fdba8c",
      400: "#ff8a4c",
      500: "#ff5a1f",
      600: "#d03801",
      700: "#b43403",
      800: "#8a2c0d",
      900: "#771d1d",
    },
    yellow: {
      50: "#fdfdea",
      100: "#fdf6b2",
      200: "#fce96a",
      300: "#faca15",
      400: "#e3a008",
      500: "#c27803",
      600: "#9f580a",
      700: "#8e4b10",
      800: "#723b13",
      900: "#633112",
    },
    green: {
      50: "#f3faf7",
      100: "#def7ec",
      200: "#bcf0da",
      300: "#84e1bc",
      400: "#31c48d",
      500: "#0e9f6e",
      600: "#057a55",
      700: "#046c4e",
      800: "#03543f",
      900: "#014737",
    },
    teal: {
      50: "#edfafa",
      100: "#d5f5f6",
      200: "#afecef",
      300: "#7edce2",
      400: "#16bdca",
      500: "#0694a2",
      600: "#047481",
      700: "#036672",
      800: "#05505c",
      900: "#014451",
    },
    blue: {
      50: "#ebf5ff",
      100: "#e1effe",
      200: "#c3ddfd",
      300: "#a4cafe",
      400: "#76a9fa",
      500: "#3f83f8",
      600: "#1c64f2",
      700: "#1a56db",
      800: "#1e429f",
      900: "#233876",
    },
    indigo: {
      50: "#f0f5ff",
      100: "#e5edff",
      200: "#cddbfe",
      300: "#b4c6fc",
      400: "#8da2fb",
      500: "#6875f5",
      600: "#5850ec",
      700: "#5145cd",
      800: "#42389d",
      900: "#362f78",
    },
    purple: {
      50: "#f6f5ff",
      100: "#edebfe",
      200: "#dcd7fe",
      300: "#cabffd",
      400: "#ac94fa",
      500: "#9061f9",
      600: "#7e3af2",
      700: "#6c2bd9",
      800: "#5521b5",
      900: "#4a1d96",
    },
    pink: {
      50: "#fdf2f8",
      100: "#fce8f3",
      200: "#fad1e8",
      300: "#f8b4d9",
      400: "#f17eb8",
      500: "#e74694",
      600: "#d61f69",
      700: "#bf125d",
      800: "#99154b",
      900: "#751a3d",
    },
    ground: {
      "green-100": "#00D480",
      "black-100": "#212631",
      "gray-100": "#898E9A",
      "gray-200": "rgba(48, 99, 213, 0.08)",
      "gray-250": "rgba(48, 99, 213, 0.1)",
      "gray-300": "#989EAC",
      "gray-400": "#CBCDD4",
      "gray-500": "#d2d6dc",
      "blue-100": "#3063d5",
      "blue-200": "#3063D580",
      "blue-300": "#a7bceb",
      "blue-400": "#3063D5CC",
      "white-100": "#F9F9FC",
      "white-200": "#F8F8FA",
      "white-300": "#EFF3FC",
      "yellow-100": "#eac603",
      "gradiant-100": "#e2e2f2",
    },
    neutral: { 100: "#eaeffb", 300: "#f5f5fa", 900: "#212121" },
    primary: { 400: "#8ca1d9" },
    default: "#3063D514",
  },
  divideOpacity: {
    0: "0",
    25: "0.25",
    50: "0.5",
    70: ".7",
    75: "0.75",
    90: ".9",
    100: "1",
  },
  divideWidth: {
    0: "0",
    2: "2px",
    4: "4px",
    8: "8px",
    default: "1px",
    "05": "0.5px",
  },
  fill: { current: "currentColor" },
  flex: { 1: "1 1 0%", auto: "1 1 auto", initial: "0 1 auto", none: "none" },
  flexGrow: { 0: "0", default: "1" },
  flexShrink: { 0: "0", default: "1" },
  fontFamily: {
    sans: [
      "system-ui",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "Noto Sans",
      "sans-serif",
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
      "Noto Color Emoji",
    ],
    serif: ["Georgia", "Cambria", "Times New Roman", "Times", "serif"],
    mono: [
      "Menlo",
      "Monaco",
      "Consolas",
      "Liberation Mono",
      "Courier New",
      "monospace",
    ],
  },
  fontSize: {
    xs: "0.75rem",
    sm: "0.875rem",
    base: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
    "5px": "5px",
    "6px": "6px",
    "7px": "7px",
    "8px": "8px",
    "9px": "9px",
    "10px": "10px",
    "11px": "11px",
    "12px": "12px",
    "13px": "13px",
    "14px": "14px",
    "15px": "15px",
    "16px": "16px",
    "17px": "17px",
    "18px": "18px",
    "19px": "19px",
    "20px": "20px",
    "21px": "21px",
    "22px": "22px",
    "23px": "23px",
    "24px": "24px",
    "25px": "25px",
    "30px": "30px",
  },
  fontWeight: {
    hairline: "100",
    thin: "200",
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
    extrabold: "800",
    black: "900",
  },
  height: {
    0: "0",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    13: "3.25rem",
    14: "3.5rem",
    15: "3.75rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    48: "12rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    auto: "auto",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "0.875rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    "1/12": "8.333333%",
    "2/12": "16.666667%",
    "3/12": "25%",
    "4/12": "33.333333%",
    "5/12": "41.666667%",
    "6/12": "50%",
    "7/12": "58.333333%",
    "8/12": "66.666667%",
    "9/12": "75%",
    "10/12": "83.333333%",
    "11/12": "91.666667%",
    full: "100%",
    screen: "100vh",
    "140px": "140px",
  },
  letterSpacing: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: ".20em",
  },
  lineHeight: {
    3: ".75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    none: "1",
    tight: "1.25",
    snug: "1.375",
    normal: "1.5",
    relaxed: "1.625",
    loose: "2",
  },
  listStyleType: { none: "none", disc: "disc", decimal: "decimal" },
  margin: {
    0: "0",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    13: "3.25rem",
    14: "3.5rem",
    15: "3.75rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    48: "12rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    auto: "auto",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "0.875rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    "1/12": "8.333333%",
    "2/12": "16.666667%",
    "3/12": "25%",
    "4/12": "33.333333%",
    "5/12": "41.666667%",
    "6/12": "50%",
    "7/12": "58.333333%",
    "8/12": "66.666667%",
    "9/12": "75%",
    "10/12": "83.333333%",
    "11/12": "91.666667%",
    full: "100%",
    "-1": "-0.25rem",
    "-2": "-0.5rem",
    "-3": "-0.75rem",
    "-4": "-1rem",
    "-5": "-1.25rem",
    "-6": "-1.5rem",
    "-7": "-1.75rem",
    "-8": "-2rem",
    "-9": "-2.25rem",
    "-10": "-2.5rem",
    "-11": "-2.75rem",
    "-12": "-3rem",
    "-13": "-3.25rem",
    "-14": "-3.5rem",
    "-15": "-3.75rem",
    "-16": "-4rem",
    "-20": "-5rem",
    "-24": "-6rem",
    "-28": "-7rem",
    "-32": "-8rem",
    "-36": "-9rem",
    "-40": "-10rem",
    "-48": "-12rem",
    "-56": "-14rem",
    "-60": "-15rem",
    "-64": "-16rem",
    "-72": "-18rem",
    "-80": "-20rem",
    "-96": "-24rem",
    "-px": "-1px",
    "-0.5": "-0.125rem",
    "-1.5": "-0.375rem",
    "-2.5": "-0.625rem",
    "-3.5": "-0.875rem",
    "-1/2": "-50%",
    "-1/3": "-33.33333%",
    "-2/3": "-66.66667%",
    "-1/4": "-25%",
    "-2/4": "-50%",
    "-3/4": "-75%",
    "-1/5": "-20%",
    "-2/5": "-40%",
    "-3/5": "-60%",
    "-4/5": "-80%",
    "-1/6": "-16.66667%",
    "-2/6": "-33.33333%",
    "-3/6": "-50%",
    "-4/6": "-66.66667%",
    "-5/6": "-83.33333%",
    "-1/12": "-8.33333%",
    "-2/12": "-16.66667%",
    "-3/12": "-25%",
    "-4/12": "-33.33333%",
    "-5/12": "-41.66667%",
    "-6/12": "-50%",
    "-7/12": "-58.33333%",
    "-8/12": "-66.66667%",
    "-9/12": "-75%",
    "-10/12": "-83.33333%",
    "-11/12": "-91.66667%",
    "-full": "-100%",
    "30px": "30px",
  },
  minHeight: {
    0: "0",
    full: "100%",
    screen: "100vh",
    "390px": "390px",
    "600px": "600px",
  },
  minWidth: { 0: "0", full: "100%" },
  objectPosition: {
    bottom: "bottom",
    center: "center",
    left: "left",
    "left-bottom": "left bottom",
    "left-top": "left top",
    right: "right",
    "right-bottom": "right bottom",
    "right-top": "right top",
    top: "top",
  },
  opacity: {
    0: "0",
    25: "0.25",
    50: "0.5",
    70: ".7",
    75: "0.75",
    90: ".9",
    100: "1",
  },
  order: {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
    11: "11",
    12: "12",
    first: "-9999",
    last: "9999",
    none: "0",
  },
  outline: {
    none: ["2px solid transparent", "2px"],
    white: ["2px dotted white", "2px"],
    black: ["2px dotted black", "2px"],
  },
  padding: {
    0: "0",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    13: "3.25rem",
    14: "3.5rem",
    15: "3.75rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    30: "7.5rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    48: "12rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "0.875rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    "1/12": "8.333333%",
    "2/12": "16.666667%",
    "3/12": "25%",
    "4/12": "33.333333%",
    "5/12": "41.666667%",
    "6/12": "50%",
    "7/12": "58.333333%",
    "8/12": "66.666667%",
    "9/12": "75%",
    "10/12": "83.333333%",
    "11/12": "91.666667%",
    full: "100%",
    "1px": "1px",
    "2px": "2px",
    "3px": "3px",
    "4px": "4px",
    "5px": "5px",
    "6px": "6px",
    "10px": "10px",
    "20px": "20px",
    "200px": "200px",
  },
  placeholderColor: {
    transparent: "transparent",
    white: "#ffffff",
    black: "#000000",
    gray: {
      100: "#F8F8FA",
      200: "#EFEFF2",
      300: "#3063D514",
      400: "#3063D526",
      500: "#8F9197",
      600: "#898E9A",
      700: "#707070",
      800: "#2d3748",
      900: "#212631",
    },
    "cool-gray": {
      50: "#fbfdfe",
      100: "#f1f5f9",
      200: "#e2e8f0",
      300: "#cfd8e3",
      400: "#97a6ba",
      500: "#64748b",
      600: "#475569",
      700: "#364152",
      800: "#27303f",
      900: "#1a202e",
    },
    red: {
      100: "#fff5f5",
      200: "#fed7d7",
      300: "#feb2b2",
      400: "#fc8181",
      500: "#FF6565",
      600: "#e53e3e",
      700: "#c53030",
      800: "#9b2c2c",
      900: "#742a2a",
    },
    orange: {
      50: "#fff8f1",
      100: "#feecdc",
      200: "#fcd9bd",
      300: "#fdba8c",
      400: "#ff8a4c",
      500: "#ff5a1f",
      600: "#d03801",
      700: "#b43403",
      800: "#8a2c0d",
      900: "#771d1d",
    },
    yellow: {
      50: "#fdfdea",
      100: "#fdf6b2",
      200: "#fce96a",
      300: "#faca15",
      400: "#e3a008",
      500: "#c27803",
      600: "#9f580a",
      700: "#8e4b10",
      800: "#723b13",
      900: "#633112",
    },
    green: {
      50: "#f3faf7",
      100: "#def7ec",
      200: "#bcf0da",
      300: "#84e1bc",
      400: "#31c48d",
      500: "#0e9f6e",
      600: "#057a55",
      700: "#046c4e",
      800: "#03543f",
      900: "#014737",
    },
    teal: {
      50: "#edfafa",
      100: "#d5f5f6",
      200: "#afecef",
      300: "#7edce2",
      400: "#16bdca",
      500: "#0694a2",
      600: "#047481",
      700: "#036672",
      800: "#05505c",
      900: "#014451",
    },
    blue: {
      50: "#ebf5ff",
      100: "#e1effe",
      200: "#c3ddfd",
      300: "#a4cafe",
      400: "#76a9fa",
      500: "#3f83f8",
      600: "#1c64f2",
      700: "#1a56db",
      800: "#1e429f",
      900: "#233876",
    },
    indigo: {
      50: "#f0f5ff",
      100: "#e5edff",
      200: "#cddbfe",
      300: "#b4c6fc",
      400: "#8da2fb",
      500: "#6875f5",
      600: "#5850ec",
      700: "#5145cd",
      800: "#42389d",
      900: "#362f78",
    },
    purple: {
      50: "#f6f5ff",
      100: "#edebfe",
      200: "#dcd7fe",
      300: "#cabffd",
      400: "#ac94fa",
      500: "#9061f9",
      600: "#7e3af2",
      700: "#6c2bd9",
      800: "#5521b5",
      900: "#4a1d96",
    },
    pink: {
      50: "#fdf2f8",
      100: "#fce8f3",
      200: "#fad1e8",
      300: "#f8b4d9",
      400: "#f17eb8",
      500: "#e74694",
      600: "#d61f69",
      700: "#bf125d",
      800: "#99154b",
      900: "#751a3d",
    },
    ground: {
      "green-100": "#00D480",
      "black-100": "#212631",
      "gray-100": "#898E9A",
      "gray-200": "rgba(48, 99, 213, 0.08)",
      "gray-250": "rgba(48, 99, 213, 0.1)",
      "gray-300": "#989EAC",
      "gray-400": "#CBCDD4",
      "gray-500": "#d2d6dc",
      "blue-100": "#3063d5",
      "blue-200": "#3063D580",
      "blue-300": "#a7bceb",
      "blue-400": "#3063D5CC",
      "white-100": "#F9F9FC",
      "white-200": "#F8F8FA",
      "white-300": "#EFF3FC",
      "yellow-100": "#eac603",
      "gradiant-100": "#e2e2f2",
    },
    neutral: { 100: "#eaeffb", 300: "#f5f5fa", 900: "#212121" },
    primary: { 400: "#8ca1d9" },
  },
  placeholderOpacity: {
    0: "0",
    25: "0.25",
    50: "0.5",
    70: ".7",
    75: "0.75",
    90: ".9",
    100: "1",
  },
  space: {
    0: "0",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    13: "3.25rem",
    14: "3.5rem",
    15: "3.75rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    48: "12rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "0.875rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    "1/12": "8.333333%",
    "2/12": "16.666667%",
    "3/12": "25%",
    "4/12": "33.333333%",
    "5/12": "41.666667%",
    "6/12": "50%",
    "7/12": "58.333333%",
    "8/12": "66.666667%",
    "9/12": "75%",
    "10/12": "83.333333%",
    "11/12": "91.666667%",
    full: "100%",
    "-1": "-0.25rem",
    "-2": "-0.5rem",
    "-3": "-0.75rem",
    "-4": "-1rem",
    "-5": "-1.25rem",
    "-6": "-1.5rem",
    "-7": "-1.75rem",
    "-8": "-2rem",
    "-9": "-2.25rem",
    "-10": "-2.5rem",
    "-11": "-2.75rem",
    "-12": "-3rem",
    "-13": "-3.25rem",
    "-14": "-3.5rem",
    "-15": "-3.75rem",
    "-16": "-4rem",
    "-20": "-5rem",
    "-24": "-6rem",
    "-28": "-7rem",
    "-32": "-8rem",
    "-36": "-9rem",
    "-40": "-10rem",
    "-48": "-12rem",
    "-56": "-14rem",
    "-60": "-15rem",
    "-64": "-16rem",
    "-72": "-18rem",
    "-80": "-20rem",
    "-96": "-24rem",
    "-px": "-1px",
    "-0.5": "-0.125rem",
    "-1.5": "-0.375rem",
    "-2.5": "-0.625rem",
    "-3.5": "-0.875rem",
    "-1/2": "-50%",
    "-1/3": "-33.33333%",
    "-2/3": "-66.66667%",
    "-1/4": "-25%",
    "-2/4": "-50%",
    "-3/4": "-75%",
    "-1/5": "-20%",
    "-2/5": "-40%",
    "-3/5": "-60%",
    "-4/5": "-80%",
    "-1/6": "-16.66667%",
    "-2/6": "-33.33333%",
    "-3/6": "-50%",
    "-4/6": "-66.66667%",
    "-5/6": "-83.33333%",
    "-1/12": "-8.33333%",
    "-2/12": "-16.66667%",
    "-3/12": "-25%",
    "-4/12": "-33.33333%",
    "-5/12": "-41.66667%",
    "-6/12": "-50%",
    "-7/12": "-58.33333%",
    "-8/12": "-66.66667%",
    "-9/12": "-75%",
    "-10/12": "-83.33333%",
    "-11/12": "-91.66667%",
    "-full": "-100%",
  },
  stroke: { current: "currentColor" },
  strokeWidth: { 0: "0", 1: "1", 2: "2" },
  textColor: {
    transparent: "transparent",
    white: "#ffffff",
    black: "#000000",
    gray: {
      100: "#F8F8FA",
      200: "#EFEFF2",
      300: "#3063D514",
      400: "#3063D526",
      500: "#8F9197",
      600: "#898E9A",
      700: "#707070",
      800: "#2d3748",
      900: "#212631",
    },
    "cool-gray": {
      50: "#fbfdfe",
      100: "#f1f5f9",
      200: "#e2e8f0",
      300: "#cfd8e3",
      400: "#97a6ba",
      500: "#64748b",
      600: "#475569",
      700: "#364152",
      800: "#27303f",
      900: "#1a202e",
    },
    red: {
      100: "#fff5f5",
      200: "#fed7d7",
      300: "#feb2b2",
      400: "#fc8181",
      500: "#FF6565",
      600: "#e53e3e",
      700: "#c53030",
      800: "#9b2c2c",
      900: "#742a2a",
    },
    orange: {
      50: "#fff8f1",
      100: "#feecdc",
      200: "#fcd9bd",
      300: "#fdba8c",
      400: "#ff8a4c",
      500: "#ff5a1f",
      600: "#d03801",
      700: "#b43403",
      800: "#8a2c0d",
      900: "#771d1d",
    },
    yellow: {
      50: "#fdfdea",
      100: "#fdf6b2",
      200: "#fce96a",
      300: "#faca15",
      400: "#e3a008",
      500: "#c27803",
      600: "#9f580a",
      700: "#8e4b10",
      800: "#723b13",
      900: "#633112",
    },
    green: {
      50: "#f3faf7",
      100: "#def7ec",
      200: "#bcf0da",
      300: "#84e1bc",
      400: "#31c48d",
      500: "#0e9f6e",
      600: "#057a55",
      700: "#046c4e",
      800: "#03543f",
      900: "#014737",
    },
    teal: {
      50: "#edfafa",
      100: "#d5f5f6",
      200: "#afecef",
      300: "#7edce2",
      400: "#16bdca",
      500: "#0694a2",
      600: "#047481",
      700: "#036672",
      800: "#05505c",
      900: "#014451",
    },
    blue: {
      50: "#ebf5ff",
      100: "#e1effe",
      200: "#c3ddfd",
      300: "#a4cafe",
      400: "#76a9fa",
      500: "#3f83f8",
      600: "#1c64f2",
      700: "#1a56db",
      800: "#1e429f",
      900: "#233876",
    },
    indigo: {
      50: "#f0f5ff",
      100: "#e5edff",
      200: "#cddbfe",
      300: "#b4c6fc",
      400: "#8da2fb",
      500: "#6875f5",
      600: "#5850ec",
      700: "#5145cd",
      800: "#42389d",
      900: "#362f78",
    },
    purple: {
      50: "#f6f5ff",
      100: "#edebfe",
      200: "#dcd7fe",
      300: "#cabffd",
      400: "#ac94fa",
      500: "#9061f9",
      600: "#7e3af2",
      700: "#6c2bd9",
      800: "#5521b5",
      900: "#4a1d96",
    },
    pink: {
      50: "#fdf2f8",
      100: "#fce8f3",
      200: "#fad1e8",
      300: "#f8b4d9",
      400: "#f17eb8",
      500: "#e74694",
      600: "#d61f69",
      700: "#bf125d",
      800: "#99154b",
      900: "#751a3d",
    },
    ground: {
      "green-100": "#00D480",
      "black-100": "#212631",
      "gray-100": "#898E9A",
      "gray-200": "rgba(48, 99, 213, 0.08)",
      "gray-250": "rgba(48, 99, 213, 0.1)",
      "gray-300": "#989EAC",
      "gray-400": "#CBCDD4",
      "gray-500": "#d2d6dc",
      "blue-100": "#3063d5",
      "blue-200": "#3063D580",
      "blue-300": "#a7bceb",
      "blue-400": "#3063D5CC",
      "white-100": "#F9F9FC",
      "white-200": "#F8F8FA",
      "white-300": "#EFF3FC",
      "yellow-100": "#eac603",
      "gradiant-100": "#e2e2f2",
    },
    neutral: { 100: "#eaeffb", 300: "#f5f5fa", 900: "#212121" },
    primary: { 400: "#8ca1d9" },
  },
  textOpacity: {
    0: "0",
    25: "0.25",
    50: "0.5",
    70: ".7",
    75: "0.75",
    90: ".9",
    100: "1",
  },
  width: {
    0: "0",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    13: "3.25rem",
    14: "3.5rem",
    15: "3.75rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    30: "7.5rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    48: "12rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    auto: "auto",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "0.875rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    "1/12": "8.333333%",
    "2/12": "16.666667%",
    "3/12": "25%",
    "4/12": "33.333333%",
    "5/12": "41.666667%",
    "6/12": "50%",
    "7/12": "58.333333%",
    "8/12": "66.666667%",
    "9/12": "75%",
    "10/12": "83.333333%",
    "11/12": "91.666667%",
    full: "100%",
    screen: "100vw",
    "200px": "200px",
  },
  zIndex: {
    0: "0",
    10: "10",
    20: "20",
    30: "30",
    40: "40",
    50: "50",
    auto: "auto",
  },
  gap: {
    0: "0",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    13: "3.25rem",
    14: "3.5rem",
    15: "3.75rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    48: "12rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "0.875rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    "1/12": "8.333333%",
    "2/12": "16.666667%",
    "3/12": "25%",
    "4/12": "33.333333%",
    "5/12": "41.666667%",
    "6/12": "50%",
    "7/12": "58.333333%",
    "8/12": "66.666667%",
    "9/12": "75%",
    "10/12": "83.333333%",
    "11/12": "91.666667%",
    full: "100%",
  },
  gridTemplateColumns: {
    1: "repeat(1, minmax(0, 1fr))",
    2: "repeat(2, minmax(0, 1fr))",
    3: "repeat(3, minmax(0, 1fr))",
    4: "repeat(4, minmax(0, 1fr))",
    5: "repeat(5, minmax(0, 1fr))",
    6: "repeat(6, minmax(0, 1fr))",
    7: "repeat(7, minmax(0, 1fr))",
    8: "repeat(8, minmax(0, 1fr))",
    9: "repeat(9, minmax(0, 1fr))",
    10: "repeat(10, minmax(0, 1fr))",
    11: "repeat(11, minmax(0, 1fr))",
    12: "repeat(12, minmax(0, 1fr))",
    none: "none",
  },
  gridAutoColumns: {
    auto: "auto",
    min: "min-content",
    max: "max-content",
    fr: "minmax(0, 1fr)",
  },
  gridColumn: {
    auto: "auto",
    "span-1": "span 1 / span 1",
    "span-2": "span 2 / span 2",
    "span-3": "span 3 / span 3",
    "span-4": "span 4 / span 4",
    "span-5": "span 5 / span 5",
    "span-6": "span 6 / span 6",
    "span-7": "span 7 / span 7",
    "span-8": "span 8 / span 8",
    "span-9": "span 9 / span 9",
    "span-10": "span 10 / span 10",
    "span-11": "span 11 / span 11",
    "span-12": "span 12 / span 12",
    "span-full": "1 / -1",
  },
  gridColumnStart: {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
    11: "11",
    12: "12",
    13: "13",
    auto: "auto",
  },
  gridColumnEnd: {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
    11: "11",
    12: "12",
    13: "13",
    auto: "auto",
  },
  gridTemplateRows: {
    1: "repeat(1, minmax(0, 1fr))",
    2: "repeat(2, minmax(0, 1fr))",
    3: "repeat(3, minmax(0, 1fr))",
    4: "repeat(4, minmax(0, 1fr))",
    5: "repeat(5, minmax(0, 1fr))",
    6: "repeat(6, minmax(0, 1fr))",
    none: "none",
  },
  gridAutoRows: {
    auto: "auto",
    min: "min-content",
    max: "max-content",
    fr: "minmax(0, 1fr)",
  },
  gridRow: {
    auto: "auto",
    "span-1": "span 1 / span 1",
    "span-2": "span 2 / span 2",
    "span-3": "span 3 / span 3",
    "span-4": "span 4 / span 4",
    "span-5": "span 5 / span 5",
    "span-6": "span 6 / span 6",
    "span-full": "1 / -1",
  },
  gridRowStart: {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    auto: "auto",
  },
  gridRowEnd: {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    auto: "auto",
  },
  transformOrigin: {
    center: "center",
    top: "top",
    "top-right": "top right",
    right: "right",
    "bottom-right": "bottom right",
    bottom: "bottom",
    "bottom-left": "bottom left",
    left: "left",
    "top-left": "top left",
  },
  scale: {
    0: "0",
    50: ".5",
    75: ".75",
    90: ".9",
    95: ".95",
    100: "1",
    105: "1.05",
    110: "1.1",
    125: "1.25",
    150: "1.5",
  },
  rotate: {
    0: "0",
    1: "1deg",
    2: "2deg",
    3: "3deg",
    6: "6deg",
    12: "12deg",
    45: "45deg",
    90: "90deg",
    180: "180deg",
    "-180": "-180deg",
    "-90": "-90deg",
    "-45": "-45deg",
    "-12": "-12deg",
    "-6": "-6deg",
    "-3": "-3deg",
    "-2": "-2deg",
    "-1": "-1deg",
  },
  translate: {
    0: "0",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    13: "3.25rem",
    14: "3.5rem",
    15: "3.75rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    48: "12rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "0.875rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    "1/12": "8.333333%",
    "2/12": "16.666667%",
    "3/12": "25%",
    "4/12": "33.333333%",
    "5/12": "41.666667%",
    "6/12": "50%",
    "7/12": "58.333333%",
    "8/12": "66.666667%",
    "9/12": "75%",
    "10/12": "83.333333%",
    "11/12": "91.666667%",
    full: "100%",
    "-1": "-0.25rem",
    "-2": "-0.5rem",
    "-3": "-0.75rem",
    "-4": "-1rem",
    "-5": "-1.25rem",
    "-6": "-1.5rem",
    "-7": "-1.75rem",
    "-8": "-2rem",
    "-9": "-2.25rem",
    "-10": "-2.5rem",
    "-11": "-2.75rem",
    "-12": "-3rem",
    "-13": "-3.25rem",
    "-14": "-3.5rem",
    "-15": "-3.75rem",
    "-16": "-4rem",
    "-20": "-5rem",
    "-24": "-6rem",
    "-28": "-7rem",
    "-32": "-8rem",
    "-36": "-9rem",
    "-40": "-10rem",
    "-48": "-12rem",
    "-56": "-14rem",
    "-60": "-15rem",
    "-64": "-16rem",
    "-72": "-18rem",
    "-80": "-20rem",
    "-96": "-24rem",
    "-px": "-1px",
    "-0.5": "-0.125rem",
    "-1.5": "-0.375rem",
    "-2.5": "-0.625rem",
    "-3.5": "-0.875rem",
    "-1/2": "-50%",
    "-1/3": "-33.33333%",
    "-2/3": "-66.66667%",
    "-1/4": "-25%",
    "-2/4": "-50%",
    "-3/4": "-75%",
    "-1/5": "-20%",
    "-2/5": "-40%",
    "-3/5": "-60%",
    "-4/5": "-80%",
    "-1/6": "-16.66667%",
    "-2/6": "-33.33333%",
    "-3/6": "-50%",
    "-4/6": "-66.66667%",
    "-5/6": "-83.33333%",
    "-1/12": "-8.33333%",
    "-2/12": "-16.66667%",
    "-3/12": "-25%",
    "-4/12": "-33.33333%",
    "-5/12": "-41.66667%",
    "-6/12": "-50%",
    "-7/12": "-58.33333%",
    "-8/12": "-66.66667%",
    "-9/12": "-75%",
    "-10/12": "-83.33333%",
    "-11/12": "-91.66667%",
    "-full": "-100%",
  },
  skew: {
    0: "0",
    1: "1deg",
    2: "2deg",
    3: "3deg",
    6: "6deg",
    12: "12deg",
    "-12": "-12deg",
    "-6": "-6deg",
    "-3": "-3deg",
    "-2": "-2deg",
    "-1": "-1deg",
  },
  transitionProperty: {
    none: "none",
    all: "all",
    default:
      "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform",
    colors: "background-color, border-color, color, fill, stroke",
    opacity: "opacity",
    shadow: "box-shadow",
    transform: "transform",
  },
  transitionTimingFunction: {
    linear: "linear",
    in: "cubic-bezier(0.4, 0, 1, 1)",
    out: "cubic-bezier(0, 0, 0.2, 1)",
    "in-out": "cubic-bezier(0.4, 0, 0.2, 1)",
  },
  transitionDuration: {
    75: "75ms",
    100: "100ms",
    150: "150ms",
    200: "200ms",
    300: "300ms",
    500: "500ms",
    700: "700ms",
    1000: "1000ms",
  },
  transitionDelay: {
    75: "75ms",
    100: "100ms",
    150: "150ms",
    200: "200ms",
    300: "300ms",
    500: "500ms",
    700: "700ms",
    1000: "1000ms",
  },
  animation: {
    none: "none",
    spin: "spin 1s linear infinite",
    ping: "ping 1s cubic-bezier(0, 0, 0.2, 1) infinite",
    pulse: "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite",
    bounce: "bounce 1s infinite",
  },
  keyframes: {
    spin: { to: { transform: "rotate(360deg)" } },
    ping: { "75%, 100%": { transform: "scale(2)", opacity: "0" } },
    pulse: { "50%": { opacity: ".5" } },
    bounce: {
      "0%, 100%": {
        transform: "translateY(-25%)",
        animationTimingFunction: "cubic-bezier(0.8,0,1,1)",
      },
      "50%": {
        transform: "none",
        animationTimingFunction: "cubic-bezier(0,0,0.2,1)",
      },
    },
  },
};

export default theme;
