import { ErrorGraphQL } from "./../../services/coreUtils";
/*
 *
 * Unavailability Model
 *
 */

import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel } from "../";
import { User } from "./../User/index";
import { Product } from "./../Product/index";
import { Center } from "../Center";
import { Operator } from "../Operator";

import {
  SearchableUnavailabilityFilterInput,
  EnumAvailabilityStatus,
  SearchableUnavailabilitySortInput,
  EnumUnavailabilityType,
} from "../../api/graphql/types";
import { OrderItem } from "../OrderItem";

export interface Unavailability {
  operator?: Operator;
  id: string;
  start: string;
  end: string;
  status: EnumAvailabilityStatus;
  type: EnumUnavailabilityType;
  relatedUnavailabilityId?: string;
  product?: Product;
  user?: User;
  center?: Center;
  orderItem: OrderItem;
}

interface PaginatedUnavailabilitys {
  pages: { [key: number]: [Unavailability] | null };
  nextTokens: { [key: number]: string | null };
  total?: number | null;
}

export interface SearchUnavailabilitiesOpts {
  filter: SearchableUnavailabilityFilterInput;
  sort?: SearchableUnavailabilitySortInput;
  limit?: number | null;
  gql?: string | null;
  page?: number;
  from?: number;
}

export interface ModelUnavailabilityConnection {
  items: [Unavailability] | null;
  total?: number | null;
}

// Interface declaration
export interface UnavailabilityModel {
  unavailabilitys: ModelUnavailabilityConnection;
  paginatedUnavailabilitys: PaginatedUnavailabilitys;
  hasMoreData: boolean;
  setUnavailabilitys: Action<
    UnavailabilityModel,
    { data: ModelUnavailabilityConnection; page?: number }
  >;
  searchUnavailabilities: Thunk<
    UnavailabilityModel,
    SearchUnavailabilitiesOpts,
    Injections,
    StoreModel
  >;
}

export const unavailabilityModel: UnavailabilityModel = {
  unavailabilitys: { items: null, total: null },
  paginatedUnavailabilitys: { pages: {}, nextTokens: {} },
  hasMoreData: false,
  setUnavailabilitys: action(
    (
      state,
      payload: { data: ModelUnavailabilityConnection; page?: number }
    ) => {
      let { page } = payload;
      const { data } = payload;
      state.unavailabilitys = data;

      page = page ? page : 0;

      const clone = Object.assign({}, state.paginatedUnavailabilitys);
      clone.pages[page] = data.items;

      state.paginatedUnavailabilitys = clone;

      let hasMoreData = false;
      if (data.total) {
        // search query
        let count = 0;
        Object.keys(state.paginatedUnavailabilitys.pages).forEach(key => {
          const index = parseInt(key);
          if (index <= page!) {
            const items = state.paginatedUnavailabilitys.pages[key];
            count = count + items.length;
          }
        });
        hasMoreData = count < data.total;
      }
      state.hasMoreData = hasMoreData;
    }
  ),

  searchUnavailabilities: thunk(async (actions, payload, { injections }) => {
    const { unavailabilityService } = injections;
    const response = await unavailabilityService.searchUnavailabilities(
      payload
    );
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.searchUnavailabilities) {
      const data = response.data.searchUnavailabilities;
      actions.setUnavailabilitys({ data, page: payload.page });

      return data;
    }

    return null;
  }),
};
