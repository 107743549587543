import { createContextStore } from "easy-peasy";
import * as ProductService from "../services/Product";
import * as ServiceService from "../services/Service";
import * as NewsService from "../services/News";
import * as EventService from "../services/Event";
import * as CenterService from "../services/Center";
import * as ProviderService from "../services/Provider";
import * as AccountService from "../services/Account";
import * as EnterpriseService from "../services/Enterprise";
import * as PrivatizationService from "../services/Privatization";
import { storeModel } from "../models";

export interface Injections {
  productService: typeof ProductService;
  serviceService: typeof ServiceService;
  newsService: typeof NewsService;
  eventService: typeof EventService;
  centerService: typeof CenterService;
  providerService: typeof ProviderService;
  accountService: typeof AccountService;
  enterpriseService: typeof EnterpriseService;
  privatizationService: typeof PrivatizationService;
}

const contextStore = createContextStore(storeModel, {
  name: "Local Store",
  injections: {
    productService: ProductService,
    serviceService: ServiceService,
    newsService: NewsService,
    eventService: EventService,
    centerService: CenterService,
    providerService: ProviderService,
    accountService: AccountService,
    enterpriseService: EnterpriseService,
    privatizationService: PrivatizationService,
  },
});

export default contextStore;
