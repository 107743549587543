import React, { Fragment, useEffect, useState } from "react";
import ReactModal from "react-modal";
import GroundFormik from "../../../../components/Tailwind/Form";
import {
  AttributeItem,
  ProductAttributeKeys,
  CenterAttributeKeys,
} from "../../../../utils/attribute";
import Button from "../../../../components/Tailwind/Button";
import CenterAttribute from "./attribute";
import IntlMessages from "../../../../utils/messages";
import {
  ActionTypes,
  FormValue,
  Item,
  getAttributeValueByAttributeKey,
  getInputTypeByTypology,
  getAttributeKeyItems,
  EnumAction,
} from "../../../../utils/types";
import { getTranslation } from "../../../../utils/translation";
import ModalCreateUpdateCategory from "../../../catalog/category/create-update";
import { AttributeKey } from "../../../../lib/ground-aws-graphql-core/models/AttributeKey";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import {
  EnumAttributeType,
  EnumAttributeTypology,
  EnumCategoryType,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";

interface Props {
  title: string;
  subTitle?: string;
  isOpen: boolean;
  onRequestClose: (e) => void;
  onChangeAttribute: (e, key: AttributeKey) => void;
  items: AttributeItem[];
  attributeKeys: AttributeKey[] | null;
  type: EnumAttributeType;
}

/** Default sort except for undefined categories put to the end */
const sortAttributeKeysById = (k1: string, k2: string): number => {
  if (k1 === "undefined") return 1;
  if (k2 === "undefined") return -1;

  return 0;
};

const DEFAULT_LIMIT = 100;

const ModalAddAttribute = (props: Props): JSX.Element => {
  const {
    onRequestClose,
    attributeKeys,
    isOpen,
    type,
    subTitle,
    title,
    onChangeAttribute,
    items,
  } = props;

  const [localAttributeKeys, setAttributeKeys] = useState<
    AttributeKey[] | null
  >();

  useEffect(() => {
    setAttributeKeys(localAttributeKeys || attributeKeys);
  }, [localAttributeKeys, attributeKeys]);

  const checkboxAttributeKeys = [] as AttributeKey[];
  const fieldAttributeKeys = [] as AttributeKey[];
  const [addParentCategory, setAddParentCategory] = useState<string>();
  const [addAttributeCategory, setAddAttributeCategory] = useState<string>();

  const attributeCategories = GroundGraphqlContextStore.useStoreState(
    s => s.category.attributeCategories
  ).items;

  const listAttributeCategories = GroundGraphqlContextStore.useStoreActions(
    actions => actions.category.listAttributeCategories
  );

  const updateCategoryAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.category.updateCategory
  );

  const updateAttributeKey = GroundGraphqlContextStore.useStoreActions(
    actions => actions.attributeKey.updateAttributeKey
  );

  const searchAllAttributeKeys = GroundGraphqlContextStore.useStoreActions(
    actions => actions.attributeKey.searchAllAttributeKeys
  );

  useEffect(() => {
    listAttributeCategories({
      filter: { type: { eq: EnumCategoryType.ATTRIBUTE } },
      limit: 1000,
    });
  }, [
    attributeCategories,
    addParentCategory,
    addAttributeCategory,
    localAttributeKeys,
  ]);

  localAttributeKeys
    ?.filter(
      e =>
        e.name !== ProductAttributeKeys.AS_OPTION &&
        e.name !== ProductAttributeKeys.MINIMAL_APPROVAL &&
        e.name !== ProductAttributeKeys.IN_CATALOG &&
        e.name !== CenterAttributeKeys.RATING
    )
    .forEach(attrKey => {
      if (attrKey.typology === EnumAttributeTypology.BOOLEAN)
        checkboxAttributeKeys.push(attrKey);
      else fieldAttributeKeys.push(attrKey);
    });

  const categoriesById: Record<
    string,
    { id: string; name: string; level: number }
  > =
    attributeCategories?.reduce((acc, c) => {
      acc[c.id] = c;

      return acc;
    }, {}) || {};

  const categorizedAttributeKeys = checkboxAttributeKeys?.reduce(
    (acc, attribute) => {
      const parentId = attribute.category?.parent?.id;
      const currentId = attribute.category?.id;

      if (!acc[parentId]) acc[parentId] = {};
      if (!acc[parentId][currentId]) acc[parentId][currentId] = [];

      const existingCategoryContent = acc[parentId][currentId];

      acc[parentId][currentId] = [...existingCategoryContent, attribute];

      return acc;
    },
    {} as Record<string, Record<string, AttributeKey[]>>
  );

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      portalClassName="modal-center"
      style={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
      shouldCloseOnOverlayClick
      onRequestClose={onRequestClose}
      className="modal-dialog-lg modal-content"
    >
      {/* Add a parent category */}
      {/* TODO: Both createCategory modals aren't used, delete them */}
      <ModalCreateUpdateCategory
        isOpen={!!addParentCategory}
        action={EnumAction.CREATE}
        categories={attributeCategories}
        toggle={async (_type, category) => {
          if (!category) {
            setAddParentCategory(undefined);

            return;
          }

          if (addParentCategory) {
            await updateCategoryAction({
              id: addParentCategory,
              type: EnumCategoryType.ATTRIBUTE,
              categoryParentId: category.id,
            });
          }

          const attrKeys = await searchAllAttributeKeys({
            filter: {
              type: { eq: type },
              enabled: { eq: true },
            },
            limit: DEFAULT_LIMIT,
          });
          setAttributeKeys(attrKeys.items);
          setAddParentCategory(undefined);
        }}
        type={EnumCategoryType.ATTRIBUTE}
        category={null}
        parent
        centerId=""
        existing
      />
      {/* Add a category to an attribute */}
      <ModalCreateUpdateCategory
        isOpen={!!addAttributeCategory}
        action={EnumAction.CREATE}
        categories={Object.values(categoriesById).filter(v => !!v)}
        toggle={async (_type, category) => {
          if (!category) {
            setAddAttributeCategory(undefined);

            return;
          }

          if (addAttributeCategory) {
            await updateAttributeKey({
              id: addAttributeCategory,
              attributeKeyCategoryId: category.id,
            });
          }

          const attrKeys = await searchAllAttributeKeys({
            filter: {
              type: { eq: type },
              enabled: { eq: true },
            },
            limit: DEFAULT_LIMIT,
          });
          setAttributeKeys(attrKeys.items);
          setAddAttributeCategory(undefined);
        }}
        type={EnumCategoryType.ATTRIBUTE}
        category={null}
        parent={false}
        centerId=""
        existing
      />
      <div className="bg-white overflow-x-auto m-30px">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 className="text-16px leading-6 font-medium text-gray-900">
            <IntlMessages id={title} />
          </h3>
          {subTitle && (
            <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
              <IntlMessages id={subTitle} />
            </p>
          )}
        </div>
        <>
          {Object.entries(categorizedAttributeKeys)
            .sort(([k1], [k2]) => sortAttributeKeysById(k1, k2))
            .map(([categoryId, subcategoryIds], index) => (
              <Fragment key={index}>
                {categoryId !== "undefined" && (
                  <h4
                    key={categoryId}
                    className="ml-5 mt-2 text-14px leading-6 font-extrabold text-gray-900"
                  >
                    {categoriesById[categoryId] &&
                      getTranslation(categoriesById[categoryId].name)}
                  </h4>
                )}
                {Object.entries(subcategoryIds)
                  .sort(([k1], [k2]) => sortAttributeKeysById(k1, k2))
                  .map(([subcategoryId, attrKeys]) => (
                    <Fragment key={subcategoryId}>
                      {subcategoryId !== "undefined" && (
                        <h5
                          key={subcategoryId}
                          className="ml-5 mt-2 text-13px leading-6 font-medium text-gray-900"
                        >
                          {categoriesById[subcategoryId] &&
                            getTranslation(categoriesById[subcategoryId].name)}
                          {/* {categoryId === "undefined" && (
                            <button
                              id="btn-add-parent-category"
                              name="btn-add-parent-category"
                              data-cy="btn-add-parent-category"
                              className="text-12px font-medium leading-5 text-ground-blue-100 sm:mt-px sm:pt-2 ml-2"
                              onClick={() =>
                                setAddParentCategory(subcategoryId)
                              }
                            >
                              <IntlMessages id="general.add.parent.category" />
                            </button>
                          )} */}
                        </h5>
                      )}
                      <div className="px-4 py-5 flex flex-wrap">
                        {attrKeys.map(attributeKey => {
                          const attr = items.find(
                            e => e.item.key?.id === attributeKey.id
                          );

                          return (
                            <Fragment key={attributeKey.id}>
                              <CenterAttribute
                                attribute={attr}
                                attributeKey={attributeKey}
                                onChange={onChangeAttribute}
                                index={index}
                              />
                              {/* {subcategoryId === "undefined" && (
                                <button
                                  id="btn-add-category"
                                  name="btn-add-category"
                                  data-cy="btn-add-category"
                                  data-testid={index}
                                  className="text-12px font-medium leading-5 text-ground-blue-100 mr-5"
                                  onClick={() =>
                                    setAddAttributeCategory(attributeKey.id)
                                  }
                                >
                                  <IntlMessages id="general.add.CATEGORY" />
                                </button>
                              )} */}
                            </Fragment>
                          );
                        })}
                      </div>
                    </Fragment>
                  ))}
              </Fragment>
            ))}
        </>
        <AttributesForm
          fieldAttributeKeys={fieldAttributeKeys}
          items={items}
          onChangeAttribute={onChangeAttribute}
          onSubmitForm={onRequestClose}
        />
      </div>
    </ReactModal>
  );
};

interface AttributesFormProps {
  onSubmitForm: (e: any) => void;
  items: AttributeItem[];
  onChangeAttribute: (e: any, key: AttributeKey) => void;
  fieldAttributeKeys: AttributeKey[];
}

const AttributesForm = ({
  onSubmitForm,
  items,
  onChangeAttribute,
  fieldAttributeKeys,
}: AttributesFormProps) => {
  const attributes = fieldAttributeKeys?.reduce(
    (acc: FormValue[], el: AttributeKey) => {
      const attr = items.find(
        e => e.item.key?.id === el.id && e.actionValue !== ActionTypes.TO_DELETE
      );

      const elements: Item[] = getAttributeKeyItems(el);
      let { attributeValue } = getAttributeValueByAttributeKey(el, attr?.item);

      const type = getInputTypeByTypology(el);
      const { typology } = el;
      const translatable = typology === EnumAttributeTypology.JSON;

      let stepOverride;
      if (
        typology === EnumAttributeTypology.NUMBER ||
        typology === EnumAttributeTypology.PERCENT
      ) {
        stepOverride = 0.01;
        attributeValue = attr?.item.numberValue
          ? attr?.item.numberValue.toString()
          : "0";
      }

      let max;
      if (typology === EnumAttributeTypology.PERCENT) {
        max = 100;
      }

      const label =
        el.description && getTranslation(el.description)
          ? getTranslation(el.description)
          : el.name;

      const v: FormValue = {
        item: el,
        id: el.id,
        items: elements,
        values: [
          {
            name: attributeValue,
            id: attributeValue,
          },
        ],
        name: el.name,
        disabled: el.system,
        type,
        translatable,
        required: el.required,
        label,
        placeholder: label,
        value: attributeValue,
        step: stepOverride,
        max,
        useIntl: false,
      };
      acc.push(v);

      return acc;
    },
    []
  );

  return (
    <GroundFormik
      item={null}
      values={attributes}
      onChange={onChangeAttribute}
      onSubmit={onSubmitForm}
    >
      <div className="flex justify-center py-6">
        <Button
          id="btn-close-attribute-modal"
          name="btn-close-attribute-modal"
          item={null}
          type="submit"
        >
          <span className="text-center">
            <IntlMessages id="general.submit" />
          </span>
        </Button>
      </div>
    </GroundFormik>
  );
};

export default ModalAddAttribute;
