import React from "react";
import classnames from "classnames";
import Add from "../Button/Add";
import { EnumPermissionEntity } from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import IntlMessages from "../../../utils/messages";

interface Props {
  entity: EnumPermissionEntity;
  onClick: (e: any) => void;
}

const AddElement = (props: Props): JSX.Element => {
  const { onClick, entity } = props;
  const containerClassName = classnames(
    "flex flex-col items-center justify-center rounded-3 bg-transparent border border-ground-blue-100 border-dashed h-140px w-full"
  );

  return (
    <div className={containerClassName} onClick={onClick}>
      <span className="text-14px">
        <IntlMessages id={`${entity}.empty`} />
      </span>
      <Add label={`general.add.${entity}`} />
    </div>
  );
};

export default AddElement;
