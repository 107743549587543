import { Thunk, thunk } from "easy-peasy";
import { ErrorGraphQL } from "../../services/coreUtils";
import { Injections } from "../../store";
import { ModelHoursRangeConnection } from "../HoursRange";
import { Operator } from "../Operator";
import { ModelDaySlotFilterInput } from "../../api/graphql/types";

export interface DaySlot {
  operator?: Operator;
  id: string;
  day?: any;
  name?: string;
  dayBreak?: string | null;
  hoursRange?: ModelHoursRangeConnection | null;
  markForDelete?: boolean;
}

interface SearchDaySlotsOpts {
  limit?: number;
  filter?: ModelDaySlotFilterInput;
  page?: number;
  gql?: string | null;
}

export interface ModelDaySlotConnection {
  items: [DaySlot] | null;
  total?: number | null;
}

// Interface declaration
export interface DaySlotModel {
  daySlots: ModelDaySlotConnection;
  searchDaySlots: Thunk<DaySlotModel, SearchDaySlotsOpts, Injections>;
}

export const daySlotModel: DaySlotModel = {
  daySlots: { items: null, total: null },
  searchDaySlots: thunk(async (_actions, payload, { injections }) => {
    const { daySlotService } = injections;
    const response = await daySlotService.searchDaySlots(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const data = response.data.searchDaySlots as ModelDaySlotConnection;

    return data;
  }),
};
