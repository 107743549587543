import React from "react";
import overrideClasses from "../../../utils/overrideClasses";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Block = ({ children, className }: Props): JSX.Element => {
  return (
    <div
      className={overrideClasses(
        "bg-white rounded-10 shadow-ground-2 my-8",
        className
      )}
    >
      {children}
    </div>
  );
};

export default Block;
