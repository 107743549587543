import React from "react";
import { IntlShape, injectIntl } from "react-intl";

interface Props {
  intl: IntlShape;
  label: string;
  icon: any;
  price: number;
  selected: boolean;
}

const RealTimeSalesCard = (props: Props): JSX.Element => {
  const { intl, label, icon, price, selected } = props;
  const className = `flex items-center border border-ground-gray-200 rounded-10 justify-center py-5`;

  return (
    <div className="mt-1 sm:mt-0 sm:col-span-1">
      <div
        className={className}
        style={{
          boxShadow: selected ? "0px 7px 10px #3063D526" : "none",
        }}
      >
        <div className="flex flex-col h-32 items-center justify-center">
          <img alt="" src={icon} />
          <span className="text-ground-gray-100 text-xl mt-2">{label}</span>
          <span className="text-center text-2xl mt-2">
            {intl.formatMessage(
              { id: "general.price.ttc" },
              { price: price.toFixed(2) }
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(RealTimeSalesCard);
