import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Location } from "history";
import { toast } from "react-toastify";
import { injectIntl, IntlShape } from "react-intl";
import { TableChangeParams } from "../../../components/Table/types";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import PageSubheader from "../../../components/PageSubheader";
import { EnumPaths } from "../../../utils/navigation";
import history from "../../../history";
import Table from "../../../components/Table";
import Dropdown, {
  DropdownValues,
} from "../../../components/Tailwind/Dropdown";
import { Webhook } from "../../../lib/ground-aws-graphql-core/models/Api/Webhooks";
import images from "../../../images";
import Badge from "../../../components/Tailwind/Badge";
import ConfirmModal from "../../../utils/modal/confirm";
import IntlMessages from "../../../utils/messages";

interface Props {
  intl: IntlShape;
  location: Location<{
    pageIndex: number;
    limit: number;
    type: string;
  }>;
}

const DEFAULT_LIMIT = 20;

const ListWebhooks = (props: Props): JSX.Element => {
  const { intl, location } = props;

  const [loading, setLoading] = useState(false);

  const [showDeleteWebhookModal, setShowDeleteWebhookModal] = useState(false);
  const [webhookId, setWebhookId] = useState<number>();

  const [initialParams, setInitialParams] = useState<TableChangeParams | null>(
    location?.state
  );

  const listWebhooks = GroundGraphqlContextStore.useStoreActions(
    actions => actions.webhooks.listWebhooks
  );

  const webhooks = GroundGraphqlContextStore.useStoreState(
    state => state.webhooks.webhooks.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.webhooks.webhooks.total
  );

  const setWebhook = GroundGraphqlContextStore.useStoreActions(
    actions => actions.webhooks.setWebhook
  );

  const deleteWebhook = GroundGraphqlContextStore.useStoreActions(
    actions => actions.webhooks.deleteWebhook
  );

  useEffect(() => {
    if (location?.state) {
      fetchData(location?.state?.pageIndex, true, location?.state?.limit);
    } else fetchData();
  }, []);

  const fetchData = (pageIndex = 0, loader = true, limit = DEFAULT_LIMIT) => {
    if (loader) setLoading(true);

    listWebhooks({
      offset: pageIndex * limit,
      limit,
    }).finally(() => setLoading(false));
  };

  const addNewWebhook = () => {
    history.push(
      `/${EnumPaths.ROOT}/${EnumPaths.WEBHOOKS}/${EnumPaths.CREATION_MODE}`
    );
  };

  const handleOnChange = (changeParams: TableChangeParams) => {
    const { pageIndex, limit } = changeParams;

    setInitialParams(changeParams);

    fetchData(pageIndex, false, limit);
  };

  const handleEditWebhook = (id: number) => {
    const editLink = `/${EnumPaths.ROOT}/${EnumPaths.WEBHOOKS}/${id}/${EnumPaths.EDIT_MODE}`;
    setWebhook(null);
    history.push(editLink);
  };

  const handleDelete = () => {
    setLoading(true);
    setShowDeleteWebhookModal(false);
    deleteWebhook({ id: webhookId! })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.webhook.delete.webhook.success",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.webhooks.delete.webhook.error",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tableHead = [
    "page.webhook.url",
    "page.webhook.events",
    "page.webhook.secret",
    "general.status",
    "general.actions",
  ];

  const tableBody = webhooks?.map((webhook: Webhook) => {
    const { id, url, events, secret, enabled } = webhook;
    const editLink = `/${EnumPaths.ROOT}/${EnumPaths.WEBHOOKS}/${id}/${EnumPaths.EDIT_MODE}`;

    const dropdownValues: DropdownValues[] = [
      {
        id: "edit_webhook",
        label: "page.list.webhooks.edit",
        icon: images.edit,
        link: editLink,
      },
      {
        id: "delete",
        label: "general.delete",
        icon: images.deleteIcon,
        onClick: () => {
          setShowDeleteWebhookModal(!showDeleteWebhookModal);
          setWebhookId(id);
        },
      },
    ];

    const webhookElements = [
      {
        element: url,
        onCellClick: () => handleEditWebhook(id),
      },
      {
        element: (
          <div className="flex flex-col">
            {events.map((event, index) => {
              return (
                <span key={index}>
                  <Badge label={event} bgClassName="bg-ground-blue-200 mb-1" />
                </span>
              );
            })}
          </div>
        ),
        onCellClick: () => handleEditWebhook(id),
      },
      {
        element: secret,
        onCellClick: () => handleEditWebhook(id),
      },
      {
        element: enabled ? (
          <Badge label="general.actif" bgClassName="bg-ground-green-100" />
        ) : (
          <Badge label="general.inactif" bgClassName="bg-red-500" />
        ),
        onCellClick: () => handleEditWebhook(id),
      },
      {
        element: (
          <Dropdown values={dropdownValues} dataCy={`webhook-dropdown-${id}`} />
        ),
      },
    ];

    return {
      rowElements: webhookElements,
    };
  });

  return (
    <div>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: `page.list.webhooks.document.title`,
          })}
        </title>
      </Helmet>

      <PageSubheader
        title="page.list.webhooks.title"
        nbOfResults={total}
        buttonRightTitle="page.list.webhooks.create.webhook"
        buttonRightAction={addNewWebhook}
        buttonRightId="btn-create-webhook"
      />

      <Table
        initialParams={initialParams}
        head={tableHead}
        body={tableBody}
        noDataText="page.list.webhooks.empty"
        onChange={handleOnChange}
        paginationLimit={DEFAULT_LIMIT}
        paginationTotal={total}
        loading={loading}
        setLoading={setLoading}
        className="px-8"
      />

      <ConfirmModal
        isOpen={showDeleteWebhookModal}
        onRequestClose={() =>
          setShowDeleteWebhookModal(!showDeleteWebhookModal)
        }
        toggle={() => setShowDeleteWebhookModal(!showDeleteWebhookModal)}
        handleConfirm={handleDelete}
        content={<IntlMessages id="page.list.webhooks.delete.webhook" />}
      />
    </div>
  );
};

export default injectIntl(ListWebhooks);
