import { Thunk, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel } from "../";
import { Image } from "../../../utils/types";
import { Operator } from "../../../lib/ground-aws-graphql-core/models/Operator";
import { Service } from "../../../lib/ground-aws-graphql-core/models/Service";

export interface ServiceOpts {
  service: Service | null;
  values: any;
  pictures?: Image[];
  center: {
    id: string;
    operator: Operator | undefined;
  };
  enabled: boolean;
  callbacks: {
    updateService: any;
    createService: any;
  };
}

// Interface declaration
export interface ServiceModel {
  createService: Thunk<ServiceModel, ServiceOpts, Injections, StoreModel>;
  updateService: Thunk<ServiceModel, ServiceOpts, Injections, StoreModel>;
}

export const serviceModel: ServiceModel = {
  createService: thunk(async (_actions, payload, { injections }) => {
    const { serviceService } = injections;
    const response = await serviceService.createService(payload);

    return response;
  }),
  updateService: thunk(async (_actions, payload, { injections }) => {
    const { serviceService } = injections;
    const response = await serviceService.updateService(payload);

    return response;
  }),
};
