import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import _ from "lodash";
import { match as Match } from "react-router-dom";
import { EnumRefundReason } from "../../../utils/order";
import { Order } from "../../../lib/ground-aws-graphql-core/models/Order";
import { OrderItem } from "../../../lib/ground-aws-graphql-core/models/OrderItem";
import { EnumOrderStatus } from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import Table from "../../Table";
import OrderItemElementsComponent from "./GridOrderItem";
import IntlMessages from "../../../utils/messages";
import { EnumPaths } from "../../../utils/navigation";
import history from "../../../history";
import { BodyElement } from "../../Table/types";
import { Center } from "../../../lib/ground-aws-graphql-core/models/Center";
import { PaymentMethod } from "../../../lib/ground-aws-graphql-core/models/Api/PaymentMethods";
import { GlobalOrder } from "../../../lib/ground-aws-graphql-core/models/GlobalOrder";

interface Props {
  center: Center;
  globalOrder: GlobalOrder;
  orders: Order[];
  showOrderHeader?: boolean;
  onChangeOrderStatus: (
    order: Order,
    orderItem: OrderItem | null | undefined,
    status: EnumOrderStatus
  ) => void;
  onRefund: (
    order: Order,
    orderItem: OrderItem | null | undefined,
    reason: EnumRefundReason
  ) => void;
  onPaid: (
    order: Order,
    orderItem: OrderItem | null | undefined,
    status: EnumOrderStatus,
    paymentMethod: string
  ) => void;
  paymentMethods: PaymentMethod[];
  intl: IntlShape;
  match?: Match<{ cid: string; id: string }>;
}

const GridOrderItems = (props: Props) => {
  const {
    center,
    globalOrder,
    orders,
    showOrderHeader,
    match,
    intl,
    onChangeOrderStatus,
    onRefund,
    onPaid,
    paymentMethods,
  } = props;

  const withTransfer = orders.some(order => order.transactionFeePercent > 0);

  const orderItemsTableHead = [
    "page.order.table.head.product",
    "page.order.table.head.price.unit",
    "general.provider",
    "page.order.table.head.quantity",
    "general.total.ht",
    "general.vat",
    "general.total.ttc",
    "general.status",
  ];

  if (withTransfer) {
    orderItemsTableHead.splice(
      3,
      0,
      "page.order.table.head.transfert",
      "page.order.table.head.applicationfee.amount"
    );
  }
  const orderItemsTableBody: Array<{ rowElements: BodyElement[] }> = [];
  orders.forEach(order => {
    // If relatedOrderItemId is not defined it means that it's the initial product, otherwise it's an option
    const children = order.orderItems.items;
    const orderItems = children.filter(i => !i.relatedOrderItemId);
    const orderDetailsLink = showOrderHeader
      ? () =>
          history.push({
            pathname: `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${match?.params.cid}/${EnumPaths.ORDERS}/${order.id}`,
            state: { from: history.location.pathname },
          })
      : undefined;

    // Take the remaining elements
    const allOptions = _.difference(children, orderItems);

    // Sort the options by initial product, return the options in case there are no products (different providers at the time of booking)
    const allOrderItems =
      orderItems.length > 0
        ? orderItems.reduce((all: OrderItem[], orderItem) => {
            all.push(orderItem);

            const orderItemOptions = allOptions.filter(
              o => o.relatedOrderItemId === orderItem.id
            );

            if (orderItemOptions && orderItemOptions.length > 0)
              all.push(...orderItemOptions);

            return all;
          }, [])
        : allOptions;

    const orderItemsTableBodyGroup = allOrderItems.map(orderItem => ({
      rowElements: OrderItemElementsComponent(
        center,
        orderItem,
        globalOrder,
        order,
        intl,
        onChangeOrderStatus,
        onRefund,
        onPaid,
        orderDetailsLink,
        paymentMethods
      ),
    }));

    if (showOrderHeader) {
      orderItemsTableBodyGroup.unshift({
        rowElements: [
          {
            element: (
              <span style={{ color: "#fff" }} className="hover:underline">
                <IntlMessages id="page.list.orders.table.head.name" /> #
                {order.ormOrder}
              </span>
            ),
            colSpan: orderItemsTableHead.length,
            additionalClassName: "text-white bg-primary-800 py-1 text-8px",
            onCellClick: orderDetailsLink,
          },
        ],
      });
    }
    orderItemsTableBody.push(...orderItemsTableBodyGroup);
  });

  return (
    <Table
      head={orderItemsTableHead}
      body={orderItemsTableBody}
      noDataText=""
    />
  );
};

export default injectIntl(GridOrderItems);
