import React from "react";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import { withOneColLayout } from "../../../../utils";
import PageSubheader from "../../../../components/PageSubheader";
import PaymentMethodForm from "../form";

interface Props {
  intl: IntlShape;
  backURL: string;
}

const CreatePaymentMethod = (props: Props) => {
  const { intl, backURL } = props;

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: "page.payment.method.create.document.title",
          })}
        </title>
      </Helmet>

      <PageSubheader
        goBackEnabled
        goBackId="btn-back-payment-method-create"
        goBackURL={backURL}
      />

      <PaymentMethodForm {...props} edition={false} />
    </>
  );
};

export default withOneColLayout(injectIntl(CreatePaymentMethod));
