import { Action, action } from "easy-peasy";
import { getLocale } from "../../../lang";

// Interface declaration
export interface SettingsModel {
  language: string;
  locale: string;
  changeLocale: Action<SettingsModel, string>;
}

const locale = localStorage.getItem("locale");
const currentAppLocale = getLocale(locale);

const initialState = {
  locale: currentAppLocale.locale,
  language: currentAppLocale.language,
};

export const settingsModel: SettingsModel = {
  ...initialState,
  changeLocale: action((state, payload: string) => {
    const currentAppLocale = getLocale(payload);
    const { locale, language } = currentAppLocale;
    state.locale = locale;
    state.language = language;

    localStorage.setItem("locale", locale);
    localStorage.setItem("language", language);
  }),
};
