import React, { useEffect, useState } from "react";
import { match as Match } from "react-router-dom";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import PageSubheader from "../../../../components/PageSubheader";
import { ALL_FILTER, IFilterField } from "../../../../components/Table";
import history from "../../../../history";
import { getLocale } from "../../../../lang";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import {
  EnumPermissionEntity,
  SearchableNewsSortableFields,
  SearchableSortDirection,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import contextStore from "../../../../redux/store";
import { EnumFilterFields, getSortFields } from "../../../../utils/filter";
import {
  EnumPaths,
  getSpecificIdFromUrl,
  navTo,
} from "../../../../utils/navigation";
import NewsTable, { EnumNewsStatus } from "./table";
import { formatISODateFn, parseISODateFn } from "../../../../utils/config";

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
}

const LIMIT = 20;

const ListNews = (props: Props) => {
  const [loading, setLoading] = useState(false);

  const { match, intl } = props;

  const searchNews = GroundGraphqlContextStore.useStoreActions(
    actions => actions.news.searchNews
  );

  const news = GroundGraphqlContextStore.useStoreState(
    state => state.news.newss.items
  );

  const setNews = GroundGraphqlContextStore.useStoreActions(
    actions => actions.news.setNews
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.news.newss.total
  );

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddNewNews = () => {
    const centerId = getSpecificIdFromUrl(
      history.location.pathname,
      "centers/"
    );

    if (centerId) {
      setNews(null);
      history.push(
        navTo(
          `${EnumPaths.MARKETING}/${EnumPaths.NEWS}/${EnumPaths.CREATION_MODE}`,
          centerId
        )
      );
    }
  };

  const fetchData = (
    pageIndex = 0,
    loader = true,
    filters?: IFilterField<any>[],
    sort?: {
      field: string;
      direction: SearchableSortDirection;
    }
  ) => {
    if (loader) {
      setLoading(true);
    }
    let filter;
    if (filters?.length) {
      filters.forEach(f => {
        if (f.type === EnumFilterFields[EnumFilterFields.ENABLED]) {
          const value = f.value?.value === EnumNewsStatus.ENABLED;
          if (f.value?.value !== ALL_FILTER) {
            filter = {
              ...filter,
              enabled: {
                eq: value,
              },
            };
          }
        }
        if (f.type === EnumFilterFields[EnumFilterFields.TITLE]) {
          if (f.value) {
            filter = {
              ...filter,
              title: { matchPhrasePrefix: f.value.toLowerCase() },
            };
          }
        }
        if (f.type === EnumFilterFields[EnumFilterFields.PUBLICATION_DATE]) {
          if (f.value) {
            const pubDate = parseISODateFn(f.value);
            pubDate.setHours(0, 0, 0, 0);
            filter = {
              ...filter,
              publicationDate: { gte: formatISODateFn(pubDate) },
            };
          }
        }
        if (f.type === EnumFilterFields[EnumFilterFields.UNPUBLICATION_DATE]) {
          if (f.value) {
            const unpubDate = parseISODateFn(f.value);
            unpubDate.setHours(0, 0, 0, 0);
            filter = {
              ...filter,
              unpublicationDate: { lte: formatISODateFn(unpubDate) },
            };
          }
        }
      });
    }

    const defaultSort = getSortFields(EnumPermissionEntity.NEWS)[0];
    const defaultSortField = SearchableNewsSortableFields[defaultSort.field];
    const defaultSortDirection = defaultSort.directions[0];

    const defautSort = {
      field: defaultSortField,
      direction: defaultSortDirection,
    };
    searchNews({
      limit: LIMIT,
      filter: {
        ...filter,
        centerNewsId: { eq: match.params.cid },
      },
      from: pageIndex * LIMIT,
      locale: currentAppLocale.backend_locale,
      sort: sort ? { ...sort } : defautSort,
    }).finally(() => setLoading(false));
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: "page.list.news.document.title" })}
        </title>
      </Helmet>
      <PageSubheader
        title="page.list.news.title"
        nbOfResults={total}
        buttonRightTitle="page.list.news.create.news"
        buttonRightAction={handleAddNewNews}
        buttonRightId="btn-add-news"
      />
      <div className="px-8">
        <NewsTable
          news={news}
          total={total}
          onChange={({ pageIndex, filters, sort }) =>
            fetchData(pageIndex, false, filters, sort)
          }
          loading={loading}
          setLoading={setLoading}
          onDeleted={fetchData}
          match={match}
        />
      </div>
    </>
  );
};

export default injectIntl(ListNews);
