import React, { forwardRef } from "react";
import { displayMonthName } from "../../../../utils/config";
import { ILocale } from "../../../../lang";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";

interface Props {
  date: Date;
  locale: ILocale;
  onClick: (e: any) => void;
}

const DatePickerMonthInput = ({ date, locale, onClick }: Props) => {
  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  return (
    <button
      className="bg-transparent text-ground-blue-100 text-14px leading-5 flex items-center"
      onClick={onClick}
      type="button"
    >
      <span className="pl-2">
        {displayMonthName(date, locale, centerTimezone)}
      </span>
    </button>
  );
};

export default forwardRef(DatePickerMonthInput);
