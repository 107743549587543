import React from "react";
import classnames from "classnames";
import { injectIntl, IntlShape } from "react-intl";
import IntlMessages from "../../../utils/messages";
import contextStore from "../../../redux/store";
import { getLocale } from "../../../lang";
import { ZonedDatePicker } from "../../ZonedDatePicker";

interface Props {
  intl: IntlShape;
  name: string;
  label: string;
  index: number;
  last?: boolean;
  placeholder?: string;
  minDate: Date | null;
  maxDate?: Date | null;
  startDate?: Date | null;
  endDate?: Date | null;
  selected: Date | null;
  onChange: (e) => void;
  selector?: string;
  dateFormat: string;
  showTimeSelect?: boolean;
  timeFormat?: string;
  timeIntervals?: number;
  timeCaption?: string;
  invalid?: boolean;
  alternate?: boolean;
  customInput?: JSX.Element;
  isClearable?: boolean;
}

const CustomDatePicker = (props: Props): JSX.Element => {
  const {
    alternate = true,
    invalid,
    index,
    name,
    label,
    intl,
    placeholder,
    minDate,
    maxDate,
    selected,
    onChange,
    selector,
    startDate,
    endDate,
    dateFormat,
    showTimeSelect,
    timeFormat,
    timeIntervals,
    timeCaption,
    last,
    customInput,
    isClearable,
  } = props;

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  const containerClassName = id =>
    classnames(
      "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:py-2 sm:px-8 px-2 py-2 ",
      {
        "bg-ground-white-200": alternate && id % 2 === 0,
        "rounded-b-10": last,
      }
    );

  const formClassName = classnames(
    "block text-14px font-medium leading-5 text-ground-gray-100 sm:mt-px sm:pt-2 ",
    {
      "border-red-300 text-red-500 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red":
        invalid,
    }
  );

  const placeholderText = placeholder
    ? intl.formatMessage({
        id: `${placeholder}`,
      })
    : "";

  const selectsStart = selector ? selector === "selectsStart" : undefined;
  const selectsEnd = selector ? selector === "selectsEnd" : undefined;

  return (
    <>
      <div className={containerClassName(index)}>
        <label htmlFor={name} className={formClassName}>
          <IntlMessages id={label} />
        </label>
        <div className="mt-1 sm:mt-0 max-w-xs sm:col-span-2">
          <div>
            <ZonedDatePicker
              placeholderText={placeholderText}
              minDate={minDate}
              maxDate={maxDate}
              selected={selected}
              onChange={onChange}
              selectsStart={selectsStart}
              selectsEnd={selectsEnd}
              startDate={startDate}
              endDate={endDate}
              locale={currentAppLocale.date}
              dateFormat={dateFormat}
              showTimeSelect={showTimeSelect || false}
              timeFormat={timeFormat}
              timeIntervals={timeIntervals}
              timeCaption={timeCaption}
              customInput={customInput}
              isClearable={isClearable}
            />
          </div>

          {invalid && (
            <p className="mt-2 text-11px text-red-600" id={`${name}-error`}>
              <IntlMessages id="general.required.field" />
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default injectIntl(CustomDatePicker);
