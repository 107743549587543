import React, { useState } from "react";
import classnames from "classnames";
import IntlMessages from "../../../utils/messages";

interface Props {
  id: string;
  index: number;
  onMouseOverImage: any;
  onMouseOutImage: any;
  onClick: (e) => void;
  className?: string;
  active?: boolean;
  label: string;
}

const NavItemImage = (props: Props): JSX.Element => {
  const [src, setSrc] = useState(props.onMouseOutImage);

  const value =
    "flex items-center px-1 pt-1 border-b-4 text-14px focus:outline-none transition duration-150 ease-in-out";

  const custom = props.className ? props.className : "";
  const className = classnames(value, custom, {
    "border-ground-green-100 text-white hover:border-ground-green-10 focus:border-ground-green-10":
      props.active,
    "border-transparent text-ground-gray-100 hover:text-white hover:border-white focus:text-white focus:border-white":
      !props.active,
  });

  return (
    <a
      id={props.id}
      data-cy={props.id}
      data-testid={props.index}
      href="#"
      className={className}
      onClick={props.onClick}
      onMouseOver={() => {
        setSrc(props.onMouseOverImage);
      }}
      onMouseOut={() => {
        setSrc(props.onMouseOutImage);
      }}
    >
      <img
        alt=""
        style={{ marginRight: 10 }}
        src={props.active ? props.onMouseOverImage : src}
      />
      <IntlMessages id={props.label} />
    </a>
  );
};

export default NavItemImage;
