import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import { getSupportedLocalesForBO } from "../../lang";
import contextStore from "../../redux/store";

interface Props {
  intl: IntlShape;
}

const LocaleSwitch = (props: Props) => {
  const locale = contextStore.useStoreState(state => state.settings.locale);
  const changeLocale = contextStore.useStoreActions(
    actions => actions.settings.changeLocale
  );

  const handleChangeLocale = e => {
    changeLocale(e.target.value);
  };

  const values = getSupportedLocalesForBO();

  return (
    <div className="flex">
      <select
        className="form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150"
        value={locale}
        onChange={handleChangeLocale}
      >
        {values.map(i => {
          const label = props.intl.formatMessage({
            id: `general.language.${i}`,
          });

          return (
            <option key={i} value={i}>
              {label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default injectIntl(LocaleSwitch);
