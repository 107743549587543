import React, { useRef, useState } from "react";
import { Field } from "formik";
import { injectIntl, IntlShape } from "react-intl";
import IntlMessages from "../../../../../utils/messages";
import {
  EnumPermissionEntity,
  EnumUserRole,
  SearchableUserFilterInput,
} from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import { AsyncFiltersSelect } from "../../../../../components/Tailwind/Filters";
import { EnumFilterFields, getFilterFields } from "../../../../../utils/filter";
import { User } from "../../../../../lib/ground-aws-graphql-core/models/User";
import { ILoadOptionsResponse } from "../../../global-orders/global-orders-list/table";

const LIMIT = 10;
export interface UserFormData {
  user?: UserData;
  accountlessUser?: UserData;
  choice?: UserType;
}

interface UserData {
  id?: string;
  lastname?: string;
  firstname?: string;
  email?: string;
}
interface Props {
  userFormData: UserFormData | null;
  onChange: (userFormData: UserFormData | null) => void;
  intl: IntlShape;
}

export enum UserType {
  USER = "user",
  ACCOUNT_LESS = "accountless",
}

const UserComponent = (props: Props): JSX.Element => {
  const { userFormData, onChange, intl } = props;
  const [filterField, setFilterField] = useState<EnumFilterFields>(
    EnumFilterFields.LASTNAME
  );
  const [users, setUsers] = useState<User[]>([]);
  const [accountlessUserForm, setAccountlessUserForm] = useState(
    userFormData?.accountlessUser
  );

  const { current: choice } = useRef<UserType>(
    userFormData?.choice || UserType.USER
  );

  const searchUsers = GroundGraphqlContextStore.useStoreActions(
    actions => actions.user.searchUsers
  );

  const loadUsers = async (
    searchQuery: any,
    _loadedOptions: any,
    { page }: { page: number }
  ) => {
    // if (!searchQuery) return;
    const filter: SearchableUserFilterInput = {
      role: { ne: EnumUserRole.Anonymous },
      ...(!!searchQuery && {
        [EnumFilterFields[filterField].toLowerCase()]: {
          matchPhrasePrefix: `${searchQuery.toLowerCase()}`,
        },
      }),
    };

    const { items, total } = await searchUsers({
      limit: LIMIT,
      filter,
      from: (page - 1) * LIMIT,
    });

    if (page > 1) setUsers([...users, ...items]);
    else setUsers(items);

    const options = items.map(({ id, lastname, firstname, email }) => ({
      id,
      name: `${lastname} ${firstname} (${email})`,
    }));

    return {
      options,
      hasMore: LIMIT * page < total,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChoiceChanged = (_e, value) => {
    if (value === UserType.USER)
      onChange({ ...userFormData, choice: UserType.USER });
    else onChange({ ...userFormData, choice: UserType.ACCOUNT_LESS });
  };

  const onFieldChange = (value, fieldName) => {
    if (userFormData?.accountlessUser) {
      onChange({
        ...userFormData,
        accountlessUser: {
          ...userFormData.accountlessUser,
          [fieldName]: value,
        },
        choice: UserType.ACCOUNT_LESS,
      });
    } else {
      onChange({
        ...userFormData,
        accountlessUser: { [fieldName]: value },
        choice: UserType.ACCOUNT_LESS,
      });
    }
  };

  const onSelectUserChange = (_type, value) => {
    const userFound = users?.find(u => u.id === value.id);

    if (userFound) {
      const { id, firstname, lastname, email } = userFound;
      const selectedUserData = {
        id,
        firstname: firstname ?? undefined,
        lastname: lastname ?? undefined,
        email,
      };

      onChange({
        ...userFormData,
        user: selectedUserData,
        choice: UserType.USER,
      });
    }
  };

  const renderUserTypeChoice = (onInputChange: (e, value) => void) => (
    <div className="flex items-center py-2 justify-evenly">
      <label className="flex items-center mb-0" htmlFor="f_user_choice">
        <input
          type="radio"
          id="f_user_choice"
          name="f_user_choice"
          value={UserType.USER}
          className="mr-3"
          onChange={e => onInputChange(e, UserType.USER)}
          checked={choice === UserType.USER}
        />
        <IntlMessages id="general.user" />
      </label>

      <label
        className="flex items-center mb-0"
        htmlFor="f_user_accountless_choice"
      >
        <input
          type="radio"
          id="f_user_accountless_choice"
          name="f_user_choice"
          value={UserType.ACCOUNT_LESS}
          className="mr-3"
          onChange={e => onInputChange(e, UserType.ACCOUNT_LESS)}
          checked={choice === UserType.ACCOUNT_LESS}
        />
        <IntlMessages id="general.user.accountless" />
      </label>
    </div>
  );

  const renderUserAccountLessFields = () => {
    return (
      <>
        {[
          {
            name: "firstname",
            label: "general.firstname",
            placeholder: "general.firstname",
            required: true,
          },
          {
            name: "lastname",
            label: "general.name",
            placeholder: "general.name",
            required: true,
          },
          {
            name: "email",
            label: "general.email",
            placeholder: "general.email",
            required: true,
          },
        ].map(field => (
          <div className="relative" key={field.name}>
            <Field
              className="rounded-md shadow-sm form-input w-full text-14px leading-5 text-ground-black-100 placeholder-ground-gray-100"
              {...field}
              placeholder={
                field.placeholder &&
                intl.formatMessage({ id: field.placeholder })
              }
              value={
                accountlessUserForm?.[field.name] ??
                userFormData?.accountlessUser?.[field.name] ??
                ""
              }
              onChange={e =>
                setAccountlessUserForm({
                  ...accountlessUserForm,
                  [field.name]: e.target.value,
                })
              }
              onBlur={e => onFieldChange(e.target.value, field.name)}
              id={field.name}
            />
          </div>
        ))}
      </>
    );
  };

  const renderUserSelected = () => {
    return (
      <div className="inline-flex items-center px-3 py-1.5 text-11px font-medium leading-5 bg-gray-100 text-gray-900">
        <div>
          {userFormData?.user?.firstname} {userFormData?.user?.lastname}
          <br />
          <span className="text-ground-blue-100">
            {userFormData?.user?.email}
          </span>
        </div>

        <button
          name="btn-remove-user"
          data-cy="btn-remove-user"
          data-testid={userFormData?.user?.id}
          type="button"
          className="flex-shrink-0 border-2 p-1 ml-4 inline-flex text-gray-700 focus:outline-none focus:text-gray-900"
          onClick={() => onChange(null)}
        >
          <svg
            style={{ height: "0.6rem", width: "0.6rem" }}
            stroke="currentColor"
            fill="none"
            viewBox="0 0 8 8"
          >
            <path
              strokeLinecap="round"
              strokeWidth="0.7"
              d="M1 1l6 6m0-6L1 7"
            />
          </svg>
        </button>
      </div>
    );
  };

  const keyFirstname = EnumFilterFields[EnumFilterFields.FIRSTNAME];
  const keyLastname = EnumFilterFields[EnumFilterFields.LASTNAME];
  const keyEmail = EnumFilterFields[EnumFilterFields.EMAIL];

  const loadOptions: {
    [key: string]: (
      searchQuery: any,
      _loadedOptions: any,
      { page }: { page: number }
    ) => Promise<ILoadOptionsResponse>;
  } = {};
  loadOptions[`${keyFirstname}`] = loadUsers;
  loadOptions[`${keyLastname}`] = loadUsers;
  loadOptions[`${keyEmail}`] = loadUsers;

  return (
    <div className="grid gap-2" key={`${choice}`}>
      {renderUserTypeChoice(onChoiceChanged)}

      {choice === UserType.ACCOUNT_LESS && renderUserAccountLessFields()}

      {choice === UserType.USER && !userFormData?.user?.id && (
        <AsyncFiltersSelect
          fields={getFilterFields(
            intl,
            EnumPermissionEntity.BOOKING
          ).values.filter(f => f.async === true)}
          multiple={false}
          onSelectChange={onSelectUserChange}
          onInputChange={type => setFilterField(EnumFilterFields[type])}
          loadOptions={loadOptions}
          isSearchable
        />
      )}

      {!!userFormData?.user?.id &&
        choice !== UserType.ACCOUNT_LESS &&
        renderUserSelected()}
    </div>
  );
};

export default injectIntl(UserComponent);
