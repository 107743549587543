/*
 *
 * Cart Model
 *
 */
import { action, Action, Thunk, thunk } from "easy-peasy";
import { Injections } from "../../../store";
import { StoreModel } from "../..";
import {
  EnumBookingReason,
  EnumCurrency,
  EnumProductTypology,
  EnumUnit,
} from "../../../api/graphql/types";
import { UserFormData } from "../../../../../routes/management/booking/book/form-user-component";
import { IMultiLanguage } from "..";

export interface BookingFormData {
  date: string;
  time: {
    start: Date;
    end: Date;
  };
  participants: number;
  totalPrice: string;
  reason: EnumBookingReason;
  user: undefined;
  paymentMethod?: string;
  comment?: string;
  selectedSlot: string;
  selectedUnit: string;
  selectedPriceUnitId: string;
  userFormData: UserFormData;
  showPaymentMethods: boolean;
}

interface CartUser {
  id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
}

interface PriceUnit {
  id: string;
}

interface CartProduct {
  id: string;
  price_unit: PriceUnit;
  booking?: {
    total_participants: number;
    start_date: string;
    end_date: string;
  };
  quantity?: number;
}

interface CartProductWithOptions extends CartProduct {
  options?: CartProduct[];
}

export interface GetCartOpts {
  id: string;
}

export interface DeleteCartOpts {
  id: string;
}

export interface CreateCartOpts {
  touch_point?: string;
  user: CartUser;
  products: CartProductWithOptions[];
  quantity?: number;
}

export interface AddOptionsOpts {
  cartId: string;
  itemId: string;
  options: CartProduct[];
}

export interface CartBooking {
  id: string;
  start_date: string;
  end_date: string;
  participants: number;
}

export interface CartProductOutput {
  id: string;
  sku: string;
  name: IMultiLanguage;
  description: IMultiLanguage;
  typology: EnumProductTypology;
  capacity?: number;
  stock?: number;
  pictures: string[];
}

export interface CartItem {
  id: string;
  total_price: number;
  total_tax: number;
  price: number;
  tax: number;
  vat: number;
  unit: EnumUnit;
  currency: EnumCurrency;
  quantity: number;
  booking: CartBooking;
  product: CartProductOutput;
  parent: { id: string };
  orm_order: string;
  payment_intent_id: string;
}

export interface Cart {
  id: string;
  building: { id: string };
  touch_point: string;
  total_price: number;
  total_tax: number;
  currency: EnumCurrency;
  timeplaced: string;
  orm_order: string;
  items: CartItem[];
}

export interface UpdateCartItemOpts {
  cartId: string;
  cartItemId: string;
  quantity: number;
}

export interface DeleteCartItemOpts {
  cartId: string;
  cartItemId: string;
}

export interface FinalizeCartOpts {
  cartId: string;
  comment?: string;
  payment_method?: string;
}

export enum BookingReason {
  ONSITE = "ONSITE",
  MAINTENANCE = "MAINTENANCE",
  OTHER = "OTHER",
}

export enum OrderChannel {
  BACK_OFFICE = "BACK_OFFICE",
}

// Interface declaration
export interface CartModel {
  bookingForm: BookingFormData | null;
  setBookingForm: Action<CartModel, BookingFormData | null>;
  cart: Cart | null;
  setCart: Action<CartModel, Cart | null>;
  getCart: Thunk<CartModel, GetCartOpts, Injections, StoreModel>;
  deleteCart: Thunk<CartModel, DeleteCartOpts, Injections, StoreModel>;
  createCart: Thunk<CartModel, CreateCartOpts, Injections, StoreModel>;
  addOptions: Thunk<CartModel, AddOptionsOpts, Injections, StoreModel>;
  updateCartItem: Thunk<CartModel, UpdateCartItemOpts, Injections, StoreModel>;
  deleteCartItem: Thunk<CartModel, DeleteCartItemOpts, Injections, StoreModel>;
  finalizeCart: Thunk<CartModel, FinalizeCartOpts, Injections, StoreModel>;
}

export const cartModel: CartModel = {
  bookingForm: null,
  setBookingForm: action((state, payload) => {
    state.bookingForm = payload;
  }),
  cart: null,
  setCart: action((state, payload) => {
    state.cart = payload;
  }),
  getCart: thunk(async (actions, payload, { injections }) => {
    const { cartApiService } = injections;
    const response = await cartApiService.getCart(payload);

    if (response.data.success) actions.setCart(response.data.data);

    return response.data;
  }),
  deleteCart: thunk(async (actions, payload, { injections }) => {
    const { cartApiService } = injections;
    const response = await cartApiService.deleteCart(payload);

    if (response.data.success) actions.setCart(null);

    return response.data;
  }),
  createCart: thunk(async (actions, payload, { injections }) => {
    const { cartApiService } = injections;
    const response = await cartApiService.createCart(payload);

    if (response.data.success) actions.setCart(response.data.data);

    return response.data;
  }),
  addOptions: thunk(async (actions, payload, { injections }) => {
    const { cartApiService } = injections;
    const response = await cartApiService.addOptions(payload);

    if (response.data.success) actions.setCart(response.data.data);

    return response.data;
  }),
  updateCartItem: thunk(async (actions, payload, { injections }) => {
    const { cartApiService } = injections;
    const response = await cartApiService.updateCartItem(payload);

    if (response.data.success) actions.setCart(response.data.data);

    return response.data;
  }),
  deleteCartItem: thunk(async (actions, payload, { injections }) => {
    const { cartApiService } = injections;
    const response = await cartApiService.deleteCartItem(payload);

    if (response.data.success) actions.setCart(response.data.data);

    return response.data;
  }),
  finalizeCart: thunk(async (actions, payload, { injections }) => {
    const { cartApiService } = injections;
    const response = await cartApiService.finalizeCart(payload);
    actions.setBookingForm(null);

    return response.data;
  }),
};
