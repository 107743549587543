import React from "react";
import ChildNavItem from "../../Sidebar/ChildNavItem";
import { EnumPaths } from "../../../utils/navigation";
import images from "../../../images";
import NavItem from "../../Sidebar/NavItem";
import history from "../../../history";

interface Props {
  children: React.ReactNode;
}

const CustomIndicatorsTwoColumns = ({ children }: Props): JSX.Element => {
  const goToPage = (e: any, path: string) => {
    e.preventDefault();

    history.push(`${path}`);
  };

  const isActive = (path: string): boolean => {
    return history.location.pathname === path;
  };

  return (
    <main className="h-screen flex overflow-hidden pt-20">
      <div className="hidden md:flex md:flex-shrink-0 w-2/12">
        <div className="flex flex-col w-full bg-neutral-300">
          <div className="h-0 flex-1 flex flex-col overflow-y-auto w-full">
            <nav className="flex-1">
              <div className="py-5 divide-gray-200">
                <NavItem
                  id="menu_indicators"
                  index={0}
                  icon={images.nav_pilot_2}
                  text="navbar.menu.indicators.users"
                  withoutTitle
                >
                  <ChildNavItem
                    index={0}
                    text="navbar.menu.indicators.users"
                    onClick={e =>
                      goToPage(
                        e,
                        `/${EnumPaths.ROOT}/${EnumPaths.INDICATORS_USERS}`
                      )
                    }
                    isActive={isActive(
                      `/${EnumPaths.ROOT}/${EnumPaths.INDICATORS_USERS}`
                    )}
                    icon="users"
                  />

                  <ChildNavItem
                    index={1}
                    text="navbar.menu.indicators.global"
                    onClick={e =>
                      goToPage(
                        e,
                        `/${EnumPaths.ROOT}/${EnumPaths.INDICATORS_GLOBAL}`
                      )
                    }
                    isActive={isActive(
                      `/${EnumPaths.ROOT}/${EnumPaths.INDICATORS_GLOBAL}`
                    )}
                    icon="analytics"
                  />
                </NavItem>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="m-0 flex-1 relative z-0 overflow-y-auto focus:outline-none bg-white">
          <div className="dashboard-wrapper">{children}</div>
        </div>
      </div>
    </main>
  );
};

export default CustomIndicatorsTwoColumns;
