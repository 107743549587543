import { Action, Thunk, action, thunk } from "easy-peasy";
import { GqlQuery, StoreModel } from "../index";
import { ErrorGraphQL } from "../../services/coreUtils";
import { Injections } from "../../store";
import {
  CreateCenterInput as CreateCenter,
  UpdateCenterInput as UpdateCenter,
  SearchableCenterFilterInput,
  EnumLocale,
  UpdateCenterStateInput,
  EnumHoursRangeName,
  SearchableCenterSortInput,
} from "../../api/graphql/types";
import { Operator } from "../Operator";
import { CenterType } from "../CenterType";
import { ModelDaySlotConnection } from "../DaySlot";
import { ModelClosedDayConnection } from "../ClosedDay";
import { ModelServiceConnection } from "../Service";
import { ModelProviderConnection } from "../Provider";
import { SearchEventsOutput } from "../Event";
import { ListEventTypeOutput } from "../EventType";
import { ModelNewsConnection } from "../News";
import { ModelUnavailabilityConnection } from "../Unavailability";
import { ModelCategoryConnection } from "../Category";
import { ModelNotificationConnection } from "../Notification";
import { ModelAttributeConnection } from "../Attribute";
import {
  AttributesItem,
  ClosedDaysItem,
  DaySlotsItem,
  ExceptionalOpeningHoursItem,
} from "../../utils/preprocessing";

export interface Center {
  id: string;
  operator?: Operator;
  type?: CenterType | null;
  name: string;
  description?: string;
  address?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  email?: string;
  phone?: string;
  location?: GPS | null;
  pictures?: Array<string | null> | null;
  attributes?: ModelAttributeConnection | null;
  daySlots?: ModelDaySlotConnection | null;
  closedDays?: ModelClosedDayConnection | null;
  exceptionalOpeningHours?: ModelExceptionalOpeningHoursConnection | null;
  enabled?: boolean;
  markForDelete?: boolean;
  services?: ModelServiceConnection | null;
  providers?: ModelProviderConnection | null;
  events?: SearchEventsOutput | null;
  eventTypes?: ListEventTypeOutput | null;
  news?: ModelNewsConnection | null;
  unavailabilities?: ModelUnavailabilityConnection | null;
  categories?: ModelCategoryConnection | null;
  notifications?: ModelNotificationConnection | null;
  metadata?: string;
  timezone: string;
}

export interface SearchCentersOpts {
  filter?: SearchableCenterFilterInput;
  sort?: SearchableCenterSortInput;
  locale?: EnumLocale;
  limit?: number | null;
  from?: number;
  gql?: string | null;
}

export interface ModelCenterConnection {
  items: [Center] | null;
  total: number;
}

export interface ExceptionalOpeningHour {
  enabled: boolean;
  end: string;
  endAt: string;
  id?: number;
  name: EnumHoursRangeName;
  start: string;
  startAt: string;
}

export interface ModelExceptionalOpeningHoursConnection {
  items: [ExceptionalOpeningHour] | null;
  total?: number | null;
}

interface GPS {
  lat?: number | null;
  lon?: number | null;
}

type Create = CreateCenter & {
  id: string;
  centerOperatorId: string;
  closedDays?: ClosedDaysItem[];
  daySlots?: DaySlotsItem[];
  attributes?: AttributesItem[];
};

type Update = UpdateCenter & {
  id: string;
  centerOperatorId: string;
  daySlots?: DaySlotsItem[];
  closedDays?: ClosedDaysItem[];
  exceptionalOpeningHours?: ExceptionalOpeningHoursItem[];
  attributes: AttributesItem[];
};

type Delete = {
  id: string;
};

const DEFAULT_TIMEZONE = "Europe/Paris";

export interface CenterModel {
  centers: ModelCenterConnection;
  center: Center | null;
  centerTimezone: string;
  error: string | null;
  setCenters: Action<CenterModel, { data: ModelCenterConnection }>;
  setCenter: Action<CenterModel, Center | null>;
  setCenterTimezone: Action<CenterModel, string>;
  addCenter: Action<CenterModel, Center>;
  replaceCenter: Action<CenterModel, Center>;
  setError: Action<CenterModel, string>;
  getCenter: Thunk<CenterModel, GqlQuery, Injections>;
  createCenter: Thunk<CenterModel, Create, Injections>;
  updateCenter: Thunk<CenterModel, Update, Injections>;
  updateCenterState: Thunk<CenterModel, UpdateCenterStateInput, Injections>;
  deleteCenter: Thunk<CenterModel, Delete, Injections>;
  searchCenters: Thunk<CenterModel, SearchCentersOpts, Injections, StoreModel>;
  searchAllCenters: Thunk<
    CenterModel,
    SearchCentersOpts,
    Injections,
    StoreModel
  >;
}

export const centerModel: CenterModel = {
  centers: { items: null, total: 0 },
  center: null,
  centerTimezone: DEFAULT_TIMEZONE,
  error: null,

  setCenters: action((state, payload: { data: ModelCenterConnection }) => {
    state.centers = payload.data;
  }),

  setCenter: action((state, payload) => {
    state.center = payload;
  }),
  setCenterTimezone: action((state, payload) => {
    state.centerTimezone = payload;
  }),

  addCenter: action((state, payload) => {
    if (state.centers.items) {
      state.centers.items.push(payload);
    } else {
      state.centers.items = [payload];
    }
  }),

  replaceCenter: action((state, payload) => {
    if (state.centers.items) {
      const index = state.centers.items!.findIndex(e => e.id === payload.id);
      if (index >= 0) state.centers.items!.splice(index, 1, payload);
    }
  }),

  setError: action((state, payload) => {
    state.error = payload;
  }),

  getCenter: thunk(async (actions, payload, { injections }) => {
    const { centerService } = injections;
    const response = await centerService.getCenter(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    actions.setCenter(response.data.getCenter);
    actions.setCenterTimezone(response.data.getCenter.timezone);

    return response.data.getCenter;
  }),

  createCenter: thunk(async (actions, payload, { injections }) => {
    const { closedDays, daySlots, attributes, ...input } = payload;
    const { centerService } = injections;
    const response = await centerService.createCenter(input);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const createdCenter = response.data.createCenter;
    actions.setCenter(createdCenter);
    actions.addCenter(createdCenter);

    return createdCenter;
  }),

  updateCenter: thunk(async (actions, payload, { injections }) => {
    const { centerOperatorId, ...input } = payload;
    const { centerService } = injections;
    const response = await centerService.updateCenter(input);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const updatedCenter = response.data.updateCenter;
    actions.setCenter(updatedCenter);
    actions.setCenterTimezone(updatedCenter.timezone);
    actions.replaceCenter(updatedCenter);

    return updatedCenter;
  }),

  updateCenterState: thunk(async (actions, payload, { injections }) => {
    const { centerService } = injections;
    const response = await centerService.updateCenterState(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const updatedCenterState = response.data.updateCenterState;
    actions.setCenter(updatedCenterState);
    actions.replaceCenter(updatedCenterState);

    return updatedCenterState;
  }),

  deleteCenter: thunk(async (_actions, payload, { injections }) => {
    const { centerService } = injections;
    const response = await centerService.deleteCenter({
      id: payload.id,
    });
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    return true;
  }),

  searchCenters: thunk(async (actions, payload, { injections }) => {
    const { centerService } = injections;
    const response = await centerService.searchCenters(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.searchCenters) {
      const data = response.data.searchCenters;
      actions.setCenters({ data });

      return data;
    }

    return null;
  }),

  searchAllCenters: thunk(async (actions, payload, { injections }) => {
    const { centerService } = injections;
    const response = await centerService.searchAllCenters(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    if (response.data.searchAllCenters) {
      const data = response.data.searchAllCenters;
      actions.setCenters({ data });

      return data;
    }

    return null;
  }),
};
