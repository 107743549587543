export const getProductGql = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      productOperatorId
      productCenterId
      id
      type
      sku
      name
      typology
      description
      pictures
      category {
        id
        type
        name
        description
        picture
        level
        parent {
          id
          name
          level
          enabled
        }
        position
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      # daySlots {
      #   items {
      #     id
      #     day
      #     name
      #     markForDelete
      #     hoursRange {
      #       items {
      #         id
      #         start
      #         end
      #         name
      #         markForDelete
      #         createdAt
      #         updatedAt
      #       }
      #     }
      #   }
      # }
      closedDays {
        items {
          id
          start
          end
          markForDelete
        }
      }
      exceptionalOpeningHours {
        items {
          id
          start
          end
          name
          enabled
          startAt
          endAt
        }
      }
      priceUnits {
        items {
          id
          unit
          currency
          increment
          price
          variation {
            id
            key
            name
            enabled
            markForDelete
            metadata
            createdAt
            updatedAt
          }
          minQuantity
          maxQuantity
          enabled
          markForDelete
          buyableUnit
          buyableDescription
          flexible
          minApprovalMinutes
          minCancelMinutes
          createdAt
          updatedAt
        }
      }
      provider {
        id
        name
        authorized
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      attributes {
        items {
          id
          key {
            id
            name
            type
            description
            picture
            enabled
            markForDelete
            position
            typology
            system
            required
            roles
            unit
            metadata
            createdAt
            updatedAt
          }
          booleanValue
          stringValue
          value
          jsonValue
          numberValue
          urlValue
          dateValue
          selectValue
          enabled
          markForDelete
          createdAt
          updatedAt
        }
      }
      products {
        items {
          id
          product {
            id
            type
            sku
            name
            typology
            description
            pictures
            vat
            capacity
            stock
            checkStock
            authorized
            enabled
            markForDelete
            flexible
            metadata
            provider {
              id
              name
              authorized
              enabled
              markForDelete
              metadata
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
        }
      }
      vat
      capacity
      stock
      checkStock
      authorized
      enabled
      markForDelete
      flexible
      metadata
      createdAt
      updatedAt
    }
  }
`;

export const updateProductGql = /* GraphQL */ `
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
      type
      sku
      name
      typology
      description
      pictures
      category {
        id
        type
        name
        description
        picture
        level
        parent {
          id
          name
          level
          enabled
        }
        position
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      provider {
        id
        name
        description
        address
        zipCode
        city
        country
        phone
        email
        pictures
        authorized
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      vat
      capacity
      stock
      checkStock
      authorized
      enabled
      markForDelete
      flexible
      metadata
      createdAt
      updatedAt
    }
  }
`;

export const createProductGql = /* GraphQL */ `
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      id
      type
      sku
      name
      typology
      description
      pictures
      category {
        id
        type
        name
        description
        picture
        level
        parent {
          id
          name
          level
          enabled
        }
        position
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      closedDays {
        items {
          id
          start
          end
          markForDelete
        }
      }
      provider {
        id
        name
        description
        address
        zipCode
        city
        country
        phone
        email
        pictures
        authorized
        enabled
        markForDelete
        metadata
        createdAt
        updatedAt
      }
      vat
      capacity
      stock
      checkStock
      authorized
      enabled
      markForDelete
      flexible
      metadata
      createdAt
      updatedAt
    }
  }
`;

export const getCenterGql = /* GraphQL */ `
  query GetCenter($id: ID!) {
    getCenter(id: $id) {
      id
      name
      description
      timezone
      daySlots {
        items {
          id
          day
          name
          markForDelete
          hoursRange {
            items {
              id
              start
              end
              markForDelete
              createdAt
              updatedAt
            }
          }
        }
      }
      closedDays {
        items {
          id
          start
          end
          markForDelete
          createdAt
          updatedAt
        }
      }
      exceptionalOpeningHours {
        items {
          id
          start
          end
          name
          enabled
          startAt
          endAt
        }
      }
      enabled
      phone
      email
    }
  }
`;
