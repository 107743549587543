import { useMemo, useState } from "react";

interface UseTranslatableFieldReturn {
  /** Value to be sent in form */
  value: string | null | undefined | Record<string, string>;
  /** Function to call to change locale of the field */
  setCurrentLocale: (locale: string) => void;
  currentLocale: string;
  /** Value to be displayed in the field */
  displayedValue: string;
  /** event handler to use in onChange props of the input component */
  onChange: (e: any) => any;
}

export const useTranslatableField = (
  /** Must be set to true to handle translation */
  translatable: boolean | undefined,
  /** current value of the field */
  value: string | null | undefined | Record<string, string>,
  /** original onChange event handler */
  onChange: (e: any) => any,
  locale: string
): UseTranslatableFieldReturn => {
  // Prevents the error for non translatable fields : "Rendered more hooks than during the previous render"
  if (!translatable) {
    return {
      value,
      setCurrentLocale: () => {},
      currentLocale: locale,
      displayedValue: (value as string) ?? "",
      onChange,
    };
  }

  const [currentLocale, setCurrentLocale] = useState<string>(locale);

  const parsedValue = useMemo(() => {
    if (value && typeof value !== "string") return value;
    try {
      const result = JSON.parse(value || "{}");
      if (typeof result === "string") return { [currentLocale]: result };

      return result;
    } catch {
      return { [currentLocale]: value || "" };
    }
    // It's intentional to not include currentLocale in dependency list
  }, [value]);

  const update = (updatedValue: string) =>
    JSON.stringify({
      ...parsedValue,
      [currentLocale]: updatedValue,
    });

  const onChangeOverride = e => {
    onChange({
      ...e,
      target: {
        ...e.target,
        name: e.target.name,
        value: update(e.target.value),
      },
    });
  };

  return {
    value: parsedValue,
    setCurrentLocale,
    currentLocale,
    displayedValue: parsedValue[currentLocale] ?? "",
    onChange: onChangeOverride,
  };
};
