import React, { useState } from "react";
import { injectIntl, IntlShape } from "react-intl";
import Switch from "rc-switch";
import classNames from "classnames";
import images from "../../../images";
import { getLocale, ILocale } from "../../../lang";
import contextStore from "../../../redux/store";
import IntlMessages from "../../../utils/messages";
import { EnumFilterType } from "../../../utils/filter";
import FilterInput from "./input";
import AsyncFilterSelect, { Additional } from "./select/async";
import { IFilterField } from "../../Table";
import DatePickerInput from "./date-picker";
import DatePickerWeekInput from "./date-picker-week";
import DatePickerMonthInput from "./date-picker-month";
import { ILoadOptionsResponse } from "../../../routes/management/global-orders/global-orders-list/table";
import { EnumReportingView } from "../../../lib/ground-aws-graphql-core/models/Product";
import {
  DATE_PICKER_FORMAT_CALENDAR,
  DATE_PICKER_FORMAT_DAY,
} from "../../../utils/config";
import { ZonedDatePicker } from "../../ZonedDatePicker";

interface InputProps {
  fields: {
    value: string;
    type: EnumFilterType;
  }[];
  selectedFilters?: IFilterField<any>[];
  onSelectChange?: (
    e: any,
    fields: {
      value: string;
      type: EnumFilterType;
    }[]
  ) => void;
  onInputChange: (type: string, input: string) => void;
  intl: IntlShape;
  displayTitle?: boolean;
}

interface AsyncSelectProps {
  fields: {
    value: string;
    type: EnumFilterType;
  }[];
  displayTitle?: boolean;
  selectedFilters?: IFilterField<any>[];
  multiple: boolean;
  onSelectChange: (type: string, e: any) => void;
  onInputChange?: (type: string | string, value: string) => void;
  loadOptions: {
    [key: string]: (
      searchQuery: any,
      _loadedOptions: any,
      additional: Additional
    ) => Promise<ILoadOptionsResponse>;
  };
  isSearchable?: boolean;
  className?: string;
}

const renderFilterField = (
  field: {
    value: string;
    type: EnumFilterType;
  },
  index: number,
  onSelectChange,
  onInputChange,
  loadOptions,
  isSearchable,
  displayTitle,
  className,
  selectedFilters?: IFilterField<any>[]
) => {
  const filters = [
    {
      ...field,
      onChange: onSelectChange,
      onInputChange,
    },
  ];

  return (
    <AsyncFilterSelect
      key={index}
      filters={filters}
      selectedFilters={selectedFilters}
      loadOptions={loadOptions}
      className={className}
      isSearchable={isSearchable}
      displayTitle={displayTitle}
    />
  );
};

export const AsyncFiltersSelect = (props: AsyncSelectProps): JSX.Element => {
  const {
    fields,
    multiple,
    onSelectChange,
    onInputChange,
    loadOptions,
    isSearchable,
    displayTitle,
    className,
    selectedFilters,
  } = props;
  if (!fields || fields.length === 0) return <div />;

  if (multiple) {
    return (
      <>
        {fields?.map((f, index) =>
          renderFilterField(
            f,
            index,
            onSelectChange,
            onInputChange,
            loadOptions,
            isSearchable,
            displayTitle,
            className,
            selectedFilters
          )
        )}
      </>
    );
  }

  return (
    <AsyncFilterSelect
      filters={fields.map(f => ({
        ...f,
        onChange: onSelectChange,
        onInputChange,
      }))}
      selectedFilters={selectedFilters}
      loadOptions={loadOptions}
      className={className}
      isSearchable={isSearchable}
      displayTitle={displayTitle}
    />
  );
};

export const FiltersInput = injectIntl((props: InputProps) => {
  const {
    fields,
    onInputChange,
    onSelectChange,
    displayTitle,
    selectedFilters,
  } = props;
  if (!fields || fields.length === 0) return null;

  const values = fields.map(field => {
    return { ...field, onSelectChange, onChange: onInputChange };
  });

  return (
    <FilterInput
      key={0}
      filters={values}
      fields={fields}
      displayTitle={displayTitle}
      selectedFilters={selectedFilters}
    />
  );
});

type BaseProps = {
  children: JSX.Element;
  title: string | null;
  className?: string;
};

export const FilterBase = ({
  children,
  title,
  className,
}: BaseProps): JSX.Element => (
  <div className="inline-block flex items-center">
    <div
      className={classNames(
        "h-8 px-4 flex justify-between items-center border border-ground-gray-400 rounded-4",
        className
      )}
    >
      {title && (
        <div className="pr-4 bg-transparent text-ground-black-100 text-14px leading-5 border-t-0 border-b-0 border-l-0 border-r border-ground-gray-400 rounded-none shadow-none">
          <IntlMessages id={title} />
        </div>
      )}

      {children}
    </div>
  </div>
);

type DateProps = {
  date: Date | null;
  onChange: (date: Date) => void;
  input?: JSX.Element;
  className?: string;
  title?: string;
};

export const FilterDate = (props: DateProps): JSX.Element => {
  const { date, onChange, input, className, title } = props;
  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  return (
    <FilterBase title={title || "general.date"} className={className}>
      <div id="calendar-range-week">
        <ZonedDatePicker
          onChange={onChange}
          selected={date}
          locale={currentAppLocale.date}
          useWeekdaysShort
          dateFormatCalendar={DATE_PICKER_FORMAT_CALENDAR}
          dateFormat={!input ? DATE_PICKER_FORMAT_DAY : undefined}
          customInput={input || <DatePickerInput />}
        />
      </div>
    </FilterBase>
  );
};

type ReportingDateProps = {
  date: Date | null;
  view: EnumReportingView;
  input: JSX.Element;
  onChange: (date: Date) => void;
  className?: string;
  title?: string;
};

export const FilterReportingDate = (props: ReportingDateProps): JSX.Element => {
  const { date, view, onChange, input, className, title } = props;

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  return (
    <FilterBase title={title || `general.${view}`} className={className}>
      <div id="formatted-reporting-date-picker">
        <ZonedDatePicker
          onChange={onChange}
          selected={date}
          showMonthYearPicker={view === EnumReportingView.MONTH}
          useWeekdaysShort={view === EnumReportingView.WEEK}
          dateFormatCalendar={DATE_PICKER_FORMAT_CALENDAR}
          locale={currentAppLocale.date}
          customInput={input}
        />
      </div>
    </FilterBase>
  );
};

type ButtonProps = {
  onClick: () => void;
  icon?: string;
  title?: string;
  isOpen?: boolean;
};

export const FilterButton = ({
  onClick,
  icon,
  title,
  isOpen,
}: ButtonProps): JSX.Element => (
  <button
    className={`h-8 px-4 my-4 rounded-4 text-14px leading-5 flex gap-2 items-center ${
      isOpen
        ? "text-gray-100 bg-ground-blue-100"
        : "text-ground-black-100 border border-ground-gray-400"
    }`}
    type="button"
    onClick={onClick}
  >
    {icon && <img src={icon} alt="" />}
    {title && <IntlMessages id={title} />}
  </button>
);

type CalendarProps = {
  date: Date;
  locale: ILocale;
  onChangeDate: (date: Date) => void;
  onPrevious: () => void;
  onNext: () => void;
};

export const FilterCalendar = (props: CalendarProps): JSX.Element => {
  const { date, locale, onChangeDate, onPrevious, onNext } = props;

  return (
    <div className="flex gap-1">
      <FilterButton icon={images.previous} onClick={onPrevious} />

      <FilterDate
        date={date}
        onChange={onChangeDate}
        input={
          <DatePickerWeekInput
            onClick={onChangeDate}
            date={date}
            locale={locale}
          />
        }
      />

      <FilterButton icon={images.next} onClick={onNext} />
    </div>
  );
};

type ReportingProps = {
  date: Date;
  locale: ILocale;
  view: EnumReportingView;
  onChangeDate: (date: Date) => void;
  onPrevious: () => void;
  onNext: () => void;
};

export const FilterReporting = (props: ReportingProps): JSX.Element => {
  const { date, locale, view, onChangeDate, onPrevious, onNext } = props;

  return (
    <div className="flex gap-1">
      <FilterButton icon={images.previous} onClick={onPrevious} />

      <FilterReportingDate
        date={date}
        view={view}
        onChange={onChangeDate}
        input={
          view && view === EnumReportingView.MONTH ? (
            <DatePickerMonthInput
              onClick={onChangeDate}
              date={date}
              locale={locale}
            />
          ) : (
            <DatePickerWeekInput
              onClick={onChangeDate}
              date={date}
              locale={locale}
            />
          )
        }
      />

      <FilterButton icon={images.next} onClick={onNext} />
    </div>
  );
};

type SwitchProps = {
  field: {
    value: string;
    type: EnumFilterType;
  };
  onChange: (type: string, isChecked: boolean) => void;
  selectedFilters?: IFilterField<any>[];
};

export const FilterSwitch = (props: SwitchProps): JSX.Element => {
  const { field, onChange, selectedFilters } = props;

  const currentState = selectedFilters?.find(
    f => f.type === field.value
  )?.value;

  const [checked, setChecked] = useState(!!currentState);

  return (
    <Switch
      checked={checked}
      onChange={isChecked => {
        setChecked(isChecked);
        onChange(field.value, isChecked);
      }}
    />
  );
};
