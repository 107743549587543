import React, { Fragment } from "react";
import _ from "lodash";
import Option from "../../../../components/Tailwind/Option";
import { ActionTypes } from "../../../../utils/types";
import IntlMessages from "../../../../utils/messages";
import { ProductAttributeKeys } from "../../../../utils/attribute";
import { Attribute } from "../../../../lib/ground-aws-graphql-core/models/Attribute";
import { Product } from "../../../../lib/ground-aws-graphql-core/models/Product";
import { getTranslation } from "../../../../utils/translation";

interface Props {
  items: { id: string; option: Product; action: ActionTypes }[];
  onRemoveOption: (o: Product) => void;
}

const ListOptions = (props: Props): JSX.Element => {
  const { items } = props;
  const options = items.filter(e => {
    const checkOptionStatus = (option): Attribute => {
      return option.attributes?.items?.find(
        attribute => attribute.key.name === ProductAttributeKeys.AS_OPTION
      );
    };
    const attribute = checkOptionStatus(e.option);
    // Secure display

    return (
      e.action !== ActionTypes.TO_DELETE && attribute?.booleanValue === true
    );
  });
  const noOptions = _.isEmpty(options);

  if (noOptions) {
    return (
      <div className="flex justify-center sm:py-6 sm:px-6 px-2 py-2 bg-ground-white-200 rounded-b-10">
        <span className="text-12px text-ground-gray-100 italic">
          <IntlMessages id="page.product.options.empty" />
        </span>
      </div>
    );
  }

  const optionsGrouped = options.reduce((result, el) => {
    const categoryName = getTranslation(el.option.service?.category?.name);
    if (!result[categoryName]) {
      result[categoryName] = [];
    }
    result[categoryName].push(el);

    return result;
  }, {});

  return (
    <div className="sm:grid sm:gap-1 sm:items-start sm:py-6 sm:px-6 px-2 py-2 bg-ground-white-200 rounded-b-10">
      {Object.keys(optionsGrouped)
        .sort()
        .map(categoryName => {
          return (
            <Fragment key={categoryName}>
              <div className="text-14px text-ground-gray-100">
                {categoryName}
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start py-1 sm:px-3 rounded-b-10">
                {optionsGrouped[categoryName].map(i => {
                  return (
                    <Fragment key={i.option.id}>
                      <Option
                        product={i.option}
                        onRemoveOption={props.onRemoveOption}
                      />
                    </Fragment>
                  );
                })}
              </div>
            </Fragment>
          );
        })}
    </div>
  );
};

export default ListOptions;
