import React, { Fragment, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import _ from "lodash";
import IntlMessages from "../../utils/messages";
import Paginate from "../Paginate";
import {
  BodyElement,
  BodyProps,
  FootProps,
  HeadProps,
  TableChangeParams,
  TableContainerProps,
  TableProps,
} from "./types";
import {
  FiltersInput,
  AsyncFiltersSelect,
  FilterButton,
  FilterDate,
} from "../Tailwind/Filters";
import FilterSelect from "../Tailwind/Filters/select";
import FilterDates from "../Tailwind/Filters/date";
import overrideClasses from "../../utils/overrideClasses";
import DownloadCSVButton from "../Tailwind/DownloadCSVButton";
import { EnumFilterType, getFilterFields, IFilter } from "../../utils/filter";
import { SortsSelector } from "./Sorts";
import { SearchableSortDirection } from "../../lib/ground-aws-graphql-core/api/graphql/types";
import images from "../../images";
import FilterBlock from "../Tailwind/Filters/events";
import { formatISODateFn } from "../../utils/config";

const LIMIT = 20;
const LIMIT_OPTIONS = [10, 20, 30, 40, 50];

export const TableContainer = ({
  children,
}: TableContainerProps): JSX.Element => (
  <table className="min-w-full mt-2 border-separate border-spacing-0 rounded-lg shadow-ground-1">
    {children}
  </table>
);

export const Head = ({
  headArray,
  headBgColor,
  headTextColor,
}: HeadProps): JSX.Element => {
  return (
    <thead>
      <tr className={headBgColor || "bg-gray-100"}>
        {headArray.map((headTitle, index) => (
          <th
            key={
              headTitle && typeof headTitle === "string"
                ? `${headTitle} ${index}`
                : `head ${index}`
            }
            className={overrideClasses(
              "px-8 h-10 text-14px leading-5 text-ground-gray-100 uppercase border-b border-t border-neutral-100",
              headTextColor,
              index === 0 ? "text-left rounded-tl-lg border-l" : "text-center",
              index === headArray.length - 1 ? "rounded-tr-lg border-r" : "",
              headTitle === "general.actions" ? "w-0" : ""
            )}
          >
            {headTitle && typeof headTitle === "string" ? (
              <IntlMessages id={headTitle} />
            ) : (
              headTitle
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export const Body = (props: BodyProps): JSX.Element => {
  const { bodyArray, spanNoData, noDataText } = props;

  const cellClassName =
    "px-5 py-3 text-14px leading-5 text-neutral-900 border-b border-neutral-100";

  const renderBodyElement = (
    bodyElement: BodyElement,
    index: number,
    array: BodyElement[]
  ) => (
    <td
      key={`bodyElement_${index}`}
      colSpan={bodyElement.colSpan}
      onClick={bodyElement.onCellClick}
      className={overrideClasses(
        cellClassName,
        bodyElement.onCellClick ? "cursor-pointer" : "",
        index === 0 ? "text-left border-l" : "text-center",
        index === array?.length - 1 ? "border-r" : "",
        bodyElement.additionalClassName
      )}
    >
      {bodyElement.element}
    </td>
  );

  const renderNoData = (
    <tr>
      <td
        colSpan={spanNoData}
        className={overrideClasses(cellClassName, "text-center border")}
      >
        {noDataText && <IntlMessages id={noDataText} />}
      </td>
    </tr>
  );

  return (
    <tbody>
      {bodyArray && !_.isEmpty(bodyArray)
        ? bodyArray.map((body, index) => {
            const { rowElements, onRowClick, rowClassName } = body;

            return rowElements && !_.isEmpty(rowElements) ? (
              <tr
                key={`body_${index}`}
                onClick={onRowClick}
                className={overrideClasses(
                  onRowClick ? "cursor-pointer" : "",
                  rowClassName
                )}
              >
                {rowElements.map(renderBodyElement)}
              </tr>
            ) : (
              renderNoData
            );
          })
        : renderNoData}
    </tbody>
  );
};

export const Foot = (props: FootProps): JSX.Element => {
  const {
    total,
    limit,
    limitChoiceEnabled,
    onPageChange,
    onLimitChange,
    forcePage,
    spanMax,
    intl,
  } = props;

  return (
    <tfoot>
      <tr>
        <td
          colSpan={spanMax}
          className="px-8 py-3 h-10 border-b border-l border-r rounded-bl-lg rounded-br-lg border-neutral-100"
        >
          <div className="flex w-full h-full items-center relative">
            {total !== undefined && total !== null && total > limit && (
              <div className="flex flex-1 justify-center">
                <Paginate
                  total={total}
                  limit={limit}
                  onPageChange={onPageChange}
                  forcePage={forcePage}
                />
              </div>
            )}

            {limitChoiceEnabled && onLimitChange && (
              <div className="absolute top-0 bottom-0 right-0 space-x-2">
                <label htmlFor="limitOptions">
                  {intl.formatMessage({ id: "general.items.per.page" })}
                </label>
                <select
                  id="limitOptions"
                  name="limitOptions"
                  className="outline-none"
                  value={limit}
                  onChange={e => onLimitChange(e.target.value)}
                >
                  {LIMIT_OPTIONS.map(o => (
                    <option key={o} value={o}>
                      {o}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </td>
      </tr>
    </tfoot>
  );
};

export const ALL_FILTER = "ALL";
export const ALL_FILTER_NUMBER = -1;

export interface IFilterField<T> {
  type: string;
  value: T | null;
  current?: IFilter;
  options?: {
    value: string | number;
    label: string;
    code?: number;
  }[];
}

const Table = (props: TableProps) => {
  const {
    initialParams: tableInitialParams,
    head,
    headBgColor,
    headTextColor,
    body,
    noDataText,
    permissionEntity,
    onChange,
    exportData,
    loadOptions,
    className,
    paginationTotal,
    paginationLimit,
    limitChoiceEnabled,
    loading,
    setLoading,
    intl,
    filterBlockTitle,
    date,
    onChangeDate,
  } = props;

  const initialParams: TableChangeParams = tableInitialParams ?? {
    pageIndex: 0,
    type: "",
    filters: [],
    limit: paginationLimit || LIMIT,
  };

  const [changeParams, setChangeParams] =
    useState<TableChangeParams>(initialParams);

  useEffect(() => {
    if (onChange && changeParams !== initialParams) onChange(changeParams);
  }, [changeParams]);

  const { pageIndex, limit } = changeParams;

  const sort1 = permissionEntity && !!onChange;

  const { values: filterFields, multiple } = getFilterFields(
    intl,
    permissionEntity
  );

  // input filters
  const inputFilters = filterFields.filter(
    v => v.type === EnumFilterType.INPUT
  );

  // filter select
  const selectFilters = filterFields.filter(
    v => v.type === EnumFilterType.SELECT
  );

  const selectFiltersSync = filterFields.filter(v => v.async === false);
  const selectFiltersAsync = filterFields.filter(v => v.async === true);

  // filter date
  const dateFilters = filterFields.filter(v => v.type === EnumFilterType.DATE);

  const filterInput = inputFilters.length > 0 && !!onChange;

  const filterSelect =
    selectFilters.filter(s => s.async === false).length > 0 && !!onChange;

  const filterSelectAsync =
    selectFiltersAsync.length > 0 && !!onChange && loadOptions;

  const filterDate = dateFilters.length > 0;

  const hasFilter =
    filterInput || filterDate || filterSelect || filterSelectAsync;

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  let { useFilterBlock } = props;
  if (!useFilterBlock) {
    useFilterBlock = false;
  }

  return loading ? (
    <div className="loading" />
  ) : (
    <div className={overrideClasses(className)}>
      <div
        className={`flex items-center ${
          hasFilter ? "justify-between" : "justify-end"
        }`}
      >
        <div className="flex">
          {useFilterBlock && (
            <>
              {date && onChangeDate && (
                <div className="mr-2">
                  {
                    <FilterDate
                      onChange={d => {
                        setChangeParams({
                          ...changeParams,
                          pageIndex: 0,
                        });
                        onChangeDate(d);
                      }}
                      date={date}
                      className="mt-4"
                    />
                  }
                </div>
              )}
              <FilterButton
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                isOpen={isFilterOpen}
                icon={isFilterOpen ? images.filterOn : images.filterOff}
                title={filterBlockTitle}
              />
            </>
          )}

          {!useFilterBlock && (
            <>
              {dateFilters.map((filter, index) => (
                <Fragment key={index}>
                  <FilterDates
                    fields={[filter]}
                    filters={dateFilters}
                    selectedValue={filter.defaultValue as Date}
                    customInput={filter.customInput}
                    selectedFilters={changeParams.filters ?? []}
                    onChange={(type, target: Date) => {
                      const filters: IFilterField<string>[] =
                        changeParams.filters
                          ? [...changeParams.filters].filter(
                              e => e.type !== type
                            )
                          : [];
                      filters.push({
                        type,
                        value: target ? formatISODateFn(target) : null,
                        current: filter,
                      });

                      return setChangeParams({
                        ...changeParams,
                        pageIndex: 0,
                        type,
                        filters,
                      });
                    }}
                    className="mr-2"
                  />
                </Fragment>
              ))}

              {filterSelect && (
                <FilterSelect
                  fields={selectFiltersSync}
                  selectedFilters={changeParams.filters}
                  onChange={(type, target) => {
                    const filters: IFilterField<string>[] = changeParams.filters
                      ? [...changeParams.filters].filter(e => e.type !== type)
                      : [];

                    filters.push({ type, value: target });

                    return setChangeParams({
                      ...changeParams,
                      pageIndex: 0,
                      type,
                      filters,
                    });
                  }}
                  className="my-4 mr-2"
                />
              )}

              {filterSelectAsync && (
                <AsyncFiltersSelect
                  fields={selectFiltersAsync}
                  selectedFilters={changeParams.filters}
                  multiple={
                    multiple
                      ? multiple[`${EnumFilterType[EnumFilterType.SELECT]}`]
                      : false
                  }
                  onSelectChange={(type, target) => {
                    const filters: IFilterField<string>[] = changeParams.filters
                      ? [...changeParams.filters].filter(e => e.type !== type)
                      : [];

                    filters.push({ type, value: target });

                    return setChangeParams({
                      ...changeParams,
                      pageIndex: 0,
                      type,
                      filters,
                    });
                  }}
                  className="my-4 mr-2"
                  loadOptions={loadOptions}
                />
              )}

              {filterInput && (
                <FiltersInput
                  fields={inputFilters}
                  selectedFilters={changeParams.filters}
                  onSelectChange={(
                    e: any,
                    fields: {
                      value: string;
                      type: EnumFilterType;
                    }[]
                  ) => {
                    const types = fields.map(f => f.value);
                    const filters: IFilterField<string>[] = changeParams.filters
                      ? [...changeParams.filters].filter(
                          e => !types.includes(e.type)
                        )
                      : [];

                    return setChangeParams({
                      ...changeParams,
                      pageIndex: 0,
                      type: e.target.value,
                      filters,
                    });
                  }}
                  onInputChange={(type, target) => {
                    const filters: IFilterField<string>[] = changeParams.filters
                      ? [...changeParams.filters].filter(e => e.type !== type)
                      : [];
                    filters.push({ type, value: target });

                    return setChangeParams({
                      ...changeParams,
                      pageIndex: 0,
                      type,
                      filters,
                    });
                  }}
                />
              )}
            </>
          )}
        </div>
        <div className="flex">
          {sort1 && (
            <SortsSelector
              permissionEntity={permissionEntity}
              onChange={(field, direction) => {
                return setChangeParams({
                  ...changeParams,
                  pageIndex: 0,
                  sort: {
                    field,
                    direction: SearchableSortDirection[direction],
                  },
                });
              }}
            />
          )}

          {!!exportData?.onExport && exportData?.filename && (
            <>
              <DownloadCSVButton
                onClick={exportData?.onExport}
                filename={exportData?.filename}
                headers={exportData?.headers || []}
              />
            </>
          )}
        </div>
      </div>

      {isFilterOpen && (
        <FilterBlock
          title={filterBlockTitle}
          permissionEntity={permissionEntity}
          changeParams={changeParams}
          onChange={(type, filters) => {
            return setChangeParams({
              ...changeParams,
              pageIndex: 0,
              type,
              filters,
            });
          }}
          loadOptions={loadOptions}
        />
      )}

      <TableContainer>
        <Head
          headArray={head}
          headBgColor={headBgColor}
          headTextColor={headTextColor}
        />

        <Body
          bodyArray={body}
          spanNoData={head.length}
          noDataText={noDataText}
        />

        <Foot
          total={paginationTotal}
          limit={limit}
          limitChoiceEnabled={limitChoiceEnabled}
          forcePage={pageIndex}
          onPageChange={page => {
            setChangeParams({
              ...changeParams,
              pageIndex: page,
            });
            if (setLoading) setLoading(true);
          }}
          onLimitChange={newLimit => {
            setChangeParams({
              ...changeParams,
              pageIndex: 0,
              limit: parseInt(newLimit, 10),
            });
            if (setLoading) setLoading(true);
          }}
          spanMax={head.length}
          intl={intl}
        />
      </TableContainer>
    </div>
  );
};

export default injectIntl(Table);
