/*
 *
 * Participation Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import { searchParticipations as searchParticipationsQuery } from "../../api/graphql/queries";
import { fetchPolicy } from "../../api/client";
import { SearchParticipationsOpts } from "./../../models/Participation";

const DEFAULT_LIMIT = 100;

export const searchParticipations = (payload: SearchParticipationsOpts) => {
  return ApolloClient.query({
    query: gql(searchParticipationsQuery),
    variables: {
      filter: payload.filter,
      limit: payload?.limit || DEFAULT_LIMIT,
      from: payload?.from,
      sort: payload?.sort,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};
