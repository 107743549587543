import React, { useState } from "react";
import { IntlShape } from "react-intl";
import { toast } from "react-toastify";
import Table from "../../../../components/Table";
import Badge from "../../../../components/Tailwind/Badge";
import Dropdown from "../../../../components/Tailwind/Dropdown";
import history from "../../../../history";
import { EnumServiceType } from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Enterprise } from "../../../../redux/models/Enterprise";
import { Privatization } from "../../../../redux/models/Privatization";
import contextStore from "../../../../redux/store";
import IntlMessages from "../../../../utils/messages";
import ConfirmModal from "../../../../utils/modal/confirm";
import { EnumPaths } from "../../../../utils/navigation";
import { getDefaultImage, getImageUrl } from "../../../../utils/picture";
import { getTranslation } from "../../../../utils/translation";
import {
  getRoleBadgeElements,
  getRoleLabel,
  UserStatus,
} from "../../../../utils/user";
import images from "../../../../images";
import { displayDayDDMMYYYY } from "../../../../utils/config";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";

type UsersProps = {
  enterprise: Enterprise | null;
  intl: IntlShape;
};

export const UsersTable = (usersProps: UsersProps): JSX.Element => {
  const { enterprise, intl } = usersProps;

  const usersTableHead = [
    "page.list.users.table.head.name",
    "general.email",
    "page.list.users.table.head.role",
    "page.list.users.table.head.account",
  ];

  const usersTableBody = enterprise?.users?.reduce(
    (
      acc: {
        rowElements: (
          | {
              element: string | undefined;
              onCellClick?: undefined;
            }
          | {
              element: JSX.Element;
              onCellClick: () => void;
            }
        )[];
      }[],
      user
    ) => {
      if (user) {
        const editUserLink = `/${EnumPaths.ROOT}/${EnumPaths.USERS}/${user.id}/${EnumPaths.EDIT_MODE}`;
        const row = {
          rowElements: [
            { element: `${user.lastname} ${user.firstname}` },
            { element: user.email },
            {
              element: (
                <Badge
                  bgClassName={getRoleBadgeElements(user.role).backgroundColor}
                  textClassName={getRoleBadgeElements(user.role).textColor}
                >
                  {getRoleLabel(intl, user.role)}
                </Badge>
              ),
              onCellClick: () => history.push(editUserLink),
            },
            {
              element: (
                <IntlMessages
                  id={`general.${
                    (user.status && user.status === UserStatus.CONFIRMED) ||
                    user.status === UserStatus.EXTERNAL_PROVIDER
                      ? "CONFIRMED"
                      : "UNCONFIRMED"
                  }`}
                />
              ),
              onCellClick: () => history.push(editUserLink),
            },
          ],
        };
        acc.push(row);
      }

      return acc;
    },
    []
  );

  return (
    <Table
      head={usersTableHead}
      body={usersTableBody}
      noDataText="page.list.users.empty"
    />
  );
};

type SpacesProps = {
  privatizations: [Privatization];
  setLoading: (loading: boolean) => void;
  intl: IntlShape;
  onDeleted?: () => void;
};

export const SpacesTable = (spacesProps: SpacesProps): JSX.Element => {
  const { privatizations, setLoading, intl, onDeleted } = spacesProps;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [privatizationToDeleteId, setPrivatizationToDeleteId] = useState<
    string | null
  >(null);

  const deletePrivatization = contextStore.useStoreActions(
    a => a.privatization.deletePrivatization
  );

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const handleDelete = () => {
    if (privatizationToDeleteId) {
      setShowDeleteModal(false);
      setLoading(true);
      deletePrivatization({
        id: privatizationToDeleteId,
      })
        .then(() => {
          if (onDeleted) {
            onDeleted();
          }
          toast(
            intl.formatMessage({ id: "page.privatization.delete.success" }),
            {
              type: "success",
            }
          );
        })
        .catch(err => {
          const errorMessage = Array.isArray(err.graphQLErrors)
            ? err.graphQLErrors[0].extensions?.exception?.response?.error
            : "";
          let errorKey = errorMessage.includes("in progress")
            ? ".inprogress"
            : "";
          errorKey = errorMessage.includes("ended") ? ".ended" : errorKey;

          toast(
            intl.formatMessage({
              id: `page.privatization.delete.error${errorKey}`,
            }),
            {
              type: "error",
            }
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const spacesTableHead = [
    "header.SPACE",
    "general.building",
    "general.type",
    "general.capacity",
    "general.status",
    "general.privatization",
    "general.actions",
  ];

  const spacesTableBody = privatizations?.map(privatization => {
    const { product } = privatization;
    const { id, pictures, name, enabled, sku, center, typology, capacity } =
      product;

    const picture =
      product && pictures && pictures.length > 0 ? pictures[0] : null;

    const productName = getTranslation(name);

    const editPrivatizationLink =
      privatization &&
      `/${EnumPaths.ROOT}/${EnumPaths.USERS}/${EnumPaths.SPACES}/${product.id}/${privatization.id}/${EnumPaths.EDIT_MODE}`;
    const privatizeLink = `/${EnumPaths.ROOT}/${EnumPaths.USERS}/${EnumPaths.SPACES}/${id}/${EnumPaths.CREATION_MODE}`;

    const dropdownValues = privatization
      ? [
          {
            id: "edit_privatization",
            label: "general.edit",
            icon: images.edit,
            link: editPrivatizationLink,
          },
          {
            id: "delete_space",
            label: "general.delete",
            icon: images.deleteIcon,
            onClick: () => {
              setShowDeleteModal(!showDeleteModal);
              setPrivatizationToDeleteId(privatization.id);
            },
          },
        ]
      : [
          {
            id: "privatize_space",
            label: "general.privatize",
            icon: images.padlock,
            link: privatizeLink,
          },
        ];

    return {
      rowElements: [
        {
          element: (
            <div className="flex flew-row items-center">
              <div>
                <span className="inline-block relative">
                  <img
                    alt={productName}
                    src={
                      picture
                        ? getImageUrl(null, picture)
                        : getDefaultImage(null)
                    }
                    className="list-thumbnail responsive border-0 w-12 h-8 object-contain"
                  />
                  <span
                    className={`absolute bottom-0 right-0 block h-2 w-2 rounded-full text-white shadow-solid ${
                      enabled ? "bg-green-400" : "bg-red-400"
                    }`}
                  />
                </span>
              </div>

              <div className="pl-2">
                <span className="block">{productName}</span>
                <span className="block text-ground-gray-100">#{sku}</span>
              </div>
            </div>
          ),
          onCellClick: () => history.push(editPrivatizationLink),
        },
        {
          element: getTranslation(center?.name),
          onCellClick: () => history.push(editPrivatizationLink),
        },
        {
          element: intl.formatMessage({
            id: `page.product.typology.${typology}`,
          }),
          onCellClick: () => history.push(editPrivatizationLink),
        },
        {
          element: intl.formatMessage(
            { id: "general.capacity.label" },
            { capacity }
          ),
          onCellClick: () => history.push(editPrivatizationLink),
        },
        {
          element: (
            <IntlMessages
              id={`page.list.users.table.account.${
                enabled ? "active" : "inactive"
              }`}
            />
          ),
          onCellClick: () => history.push(editPrivatizationLink),
        },
        {
          element: `${displayDayDDMMYYYY(
            privatization.start,
            centerTimezone
          )} > ${displayDayDDMMYYYY(privatization.end, centerTimezone)}`,
          onCellClick: () => history.push(editPrivatizationLink),
        },
        { element: <Dropdown values={dropdownValues} /> },
      ],
    };
  });

  return (
    <>
      <Table
        head={spacesTableHead}
        body={spacesTableBody}
        noDataText={`page.list.products.${EnumServiceType.SPACE}.empty`}
      />

      <ConfirmModal
        isOpen={showDeleteModal}
        toggle={() => {
          setShowDeleteModal(!showDeleteModal);
          setPrivatizationToDeleteId(null);
        }}
        onRequestClose={() => setShowDeleteModal(!showDeleteModal)}
        handleConfirm={handleDelete}
        content={<IntlMessages id="page.privatization.delete.privatization" />}
      />
    </>
  );
};
