import React, { useState } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { toast } from "react-toastify";
import { match as Match } from "react-router-dom";
import Header from "../../../../../components/Tailwind/Block/Header";
import {
  EnumCategoryType,
  EnumPermissionEntity,
  EnumServiceType,
  SearchableCategorySortableFields,
  SearchableSortDirection,
} from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Category } from "../../../../../lib/ground-aws-graphql-core/models/Category";
import { AsyncFiltersSelect } from "../../../../../components/Tailwind/Filters";
import { EnumFilterType, IFilter } from "../../../../../utils/filter";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import { SearchableCenterFilterInput } from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { EnumFilterFields } from "../../../../../utils/filter";
import { ILoadOptionsResponse } from "../../../../management/global-orders/global-orders-list/table";
import { Center } from "../../../../../lib/ground-aws-graphql-core/models/Center";
import { getTranslation } from "../../../../../utils/translation";
import LinkButton from "../../../../../components/Tailwind/LinkButton";
import Button from "../../../../../components/Tailwind/Button";
import IntlMessages from "../../../../../utils/messages";
import contextStore from "../../../../../redux/store";
import { getLocale } from "../../../../../lang";

interface Props {
  categoryType: EnumCategoryType;
  serviceType: EnumServiceType;
  isOpen: boolean;
  toggle: () => void;
  category: Category | null;
  match: Match<{ cid: string }>;
  intl: IntlShape;
}

const LIMIT = 20;

const DEFAULT_LIMIT = 100;

const DuplicateCategoryForm = (props: Props) => {
  const { toggle, intl, categoryType, serviceType, category, match } = props;
  const [loading, setLoading] = useState<boolean>(false);

  // set filter field by default to name
  const [filterField, setFilterField] = useState<EnumFilterFields>(
    EnumFilterFields.NAME
  );

  const [centers, setCenters] = useState<Center[]>([]);
  const [selectedCenter, setSelectedCenter] = useState<{
    id: string;
    name: string;
  }>();

  const duplicateCategory = GroundGraphqlContextStore.useStoreActions(
    actions => actions.category.duplicateCategory
  );

  const searchAllCategories = GroundGraphqlContextStore.useStoreActions(
    actions => actions.category.searchAllCategories
  );

  const searchAllServices = GroundGraphqlContextStore.useStoreActions(
    actions => actions.service.searchAllServices
  );

  const fetchAllCategories = () => {
    return searchAllCategories({
      filter: {
        type: {
          eq: categoryType,
        },
        categoryCenterId: {
          eq: match.params.cid,
        },
      },
      sort: {
        field: SearchableCategorySortableFields.createdAt,
        direction: SearchableSortDirection.desc,
      },
      locale: currentAppLocale.backend_locale,
    });
  };

  const fetchAllServices = () => {
    return searchAllServices({
      limit: DEFAULT_LIMIT,
      filter: {
        centerServicesId: { eq: match.params.cid },
        type: { eq: serviceType },
      },
    });
  };

  const fetchData = async () => {
    setLoading(true);
    await Promise.all([fetchAllCategories(), fetchAllServices()]).finally(() =>
      setLoading(false)
    );
  };

  const handleDuplicate = () => {
    setLoading(true);

    duplicateCategory({
      buildingId: match.params.cid,
      categoryId: category!.id,
      targetBuildingId: selectedCenter?.id,
    })
      .then(() => {
        toggle();
        const message = intl.formatMessage(
          {
            id: "page.list.categories.duplicate.category.success",
          },
          {
            name: selectedCenter?.name,
          }
        );
        toast(message, { type: "success" });
        // list fetch data (list categories + services)
        fetchData();
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.list.categories.duplicate.category.error",
          }),
          { type: "error" }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const searchCenters = GroundGraphqlContextStore.useStoreActions(
    actions => actions.center.searchCenters
  );

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  const onSelectCenterChange = (
    _type: string,
    value: { id: string; name: string }
  ) => {
    setSelectedCenter(value);
  };

  const loadCenters = async (
    searchQuery: any,
    _loadedOptions: any,
    { page }: { page: number }
  ): Promise<ILoadOptionsResponse> => {
    const filter: SearchableCenterFilterInput = {
      ...(!!searchQuery && {
        [EnumFilterFields[EnumFilterFields.NAME].toLowerCase()]: {
          matchPhrasePrefix: `${searchQuery.toLowerCase()}`,
        },
      }),
    };

    const { items, total } = await searchCenters({
      limit: LIMIT,
      filter,
      from: (page - 1) * LIMIT,
      locale: currentAppLocale.backend_locale,
    });

    if (page > 1) setCenters([...centers, ...items]);
    else setCenters(items);

    const options = items.map(({ id, name }: Center) => ({
      id,
      name: getTranslation(name),
    }));

    return {
      options,
      hasMore: LIMIT * page < total,
      additional: {
        page: page + 1,
      },
    };
  };

  const filters: IFilter[] = [
    {
      value: EnumFilterFields[EnumFilterFields.NAME],
      type: EnumFilterType.INPUT,
    },
  ];
  const values = filters.map(v => v.value);

  const loadOptions: {
    [key: string]: (
      searchQuery: any,
      _loadedOptions: any,
      { page }: { page: number }
    ) => Promise<ILoadOptionsResponse>;
  } = {};

  for (const key of values) {
    loadOptions[key] = loadCenters;
  }

  return (
    <>
      <Header
        item={category}
        title="page.list.categories.duplicate.category"
        entity={EnumPermissionEntity.CATEGORY}
        description="page.list.categories.duplicate.category.description"
      />

      <AsyncFiltersSelect
        fields={filters}
        multiple={false}
        onSelectChange={onSelectCenterChange}
        loadOptions={loadOptions}
        onInputChange={type => setFilterField(EnumFilterFields[type])}
        isSearchable
        className="m-10"
      />

      {selectedCenter && (
        <span className="px-2 sm:px-6 text-14px font-bold text-black-500">
          {intl.formatMessage(
            { id: "page.list.categories.duplicate.category.selected.building" },
            {
              name: selectedCenter.name,
            }
          )}
        </span>
      )}
      <div className="py-5 border-b border-gray-200 px-6 flex flex-row justify-end items-center rounded-b-10">
        <div className="flex items-center justify-between">
          <Button
            id="btn-validate"
            name="btn-validate"
            type="button"
            item={category}
            disabled={loading}
            onClick={handleDuplicate}
          >
            <span className="text-center">
              <IntlMessages id={"general.submit"} />
            </span>
          </Button>
        </div>
        <div className="flex items-center justify-between px-2">
          <LinkButton
            id="link-cancel"
            name="link-cancel"
            label="general.cancel"
            onClick={() => toggle()}
          />
        </div>
      </div>
    </>
  );
};

export default injectIntl(DuplicateCategoryForm);
