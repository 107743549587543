import { action, Action, Thunk, thunk } from "easy-peasy";
import { Injections } from "../../../store";
import { StoreModel } from "../..";
import { SearchableDateRangeFilterInput } from "../../../api/graphql/types";
import { IListVisitorsResponse, IListVisitsResponse } from "..";

export enum EnumVisitStatus {
  PREREGISTERED = "preregistered",
  CHECKED_IN = "checked_in",
  CHECKED_OUT = "checked_out",
  CANCELED = "canceled",
}

export enum EnumVisitorStatus {
  CHECKED_IN = "checked_in",
  CHECKED_OUT = "checked_out",
}

export enum EnumVisitVisitorStatus {
  CHECKED_IN = "checked_in",
  CHECKED_OUT = "checked_out",
  CANCELED = "canceled",
}

export interface Visitor {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  company_name?: string;
  status: EnumVisitorStatus;
  checked_in?: string;
  checked_out?: string;
  visit_status?: EnumVisitVisitorStatus;
  visit_checked_in?: string;
  visit_checked_out?: string;
  created_at: string;
  updated_at: string;
}

export interface Host {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  created_at: string;
  updated_at: string;
}

export interface Visit {
  id: number;
  ref: string;
  date: string;
  status: EnumVisitStatus;
  visitors: Visitor[];
  host: Host;
  building: {
    id: string;
  };
  checked_in?: string;
  checked_out?: string;
  created_at: string;
  updated_at: string;
}

export interface GetVisitOpts {
  id: string;
}

export type SearchableVisitsFilterInput = {
  date?: SearchableDateRangeFilterInput;
};

export interface ListVisitsOpts {
  buildingId: string;
  limit: number;
  offset: number;
  filter?: SearchableVisitsFilterInput;
}

export interface ListVisitorsOpts {
  buildingId: string;
  limit: number;
  offset: number;
}

export interface CreateVisitVisitorOpts {
  first_name: string;
  last_name: string;
  email: string;
  company_name: string;
}

export interface CreateVisitHostOpts {
  first_name: string;
  last_name: string;
  email: string;
}

export interface CreateVisitOpts {
  building_id: string;
  visitors: CreateVisitVisitorOpts[];
  date?: string;
  host?: CreateVisitHostOpts;
  host_id?: string;
}

export interface UpdateVisitOpts {
  id: string;
  date: string;
}

export interface AddVisitorsOpts {
  id: string;
  visitors: CreateVisitVisitorOpts[];
}

export interface RemoveVisitorsOpts {
  id: number;
  visitors: number[];
}

export interface CheckInOutCancelVisitOpts {
  id: number;
}

export interface CheckInOutCancelVisitorOpts {
  visitId: number;
  visitorId: number;
}

export interface ResendInvitationOpts {
  id: number;
}

export interface VisitModel {
  visits: {
    items: Visit[];
    total: number;
    has_more: boolean;
    total_checked_in: number;
  };
  visitors: {
    items: Visitor[];
    total: number;
    has_more: boolean;
  };
  visit: Visit | null;
  setVisit: Action<VisitModel, Visit | null>;
  setVisits: Action<
    VisitModel,
    {
      items: Visit[];
      total: number;
      has_more: boolean;
      total_checked_in: number;
    }
  >;
  setVisitors: Action<
    VisitModel,
    {
      items: Visitor[];
      total: number;
      has_more: boolean;
    }
  >;
  getVisit: Thunk<VisitModel, GetVisitOpts, Injections, StoreModel>;
  listVisits: Thunk<VisitModel, ListVisitsOpts, Injections, StoreModel>;
  listVisitors: Thunk<VisitModel, ListVisitorsOpts, Injections, StoreModel>;
  createVisit: Thunk<VisitModel, CreateVisitOpts, Injections, StoreModel>;
  updateVisit: Thunk<VisitModel, UpdateVisitOpts, Injections, StoreModel>;
  addVisitors: Thunk<VisitModel, AddVisitorsOpts, Injections, StoreModel>;
  removeVisitors: Thunk<VisitModel, RemoveVisitorsOpts, Injections, StoreModel>;
  checkInVisit: Thunk<
    VisitModel,
    CheckInOutCancelVisitOpts,
    Injections,
    StoreModel
  >;
  checkInVisitor: Thunk<
    VisitModel,
    CheckInOutCancelVisitorOpts,
    Injections,
    StoreModel
  >;
  checkOutVisit: Thunk<
    VisitModel,
    CheckInOutCancelVisitOpts,
    Injections,
    StoreModel
  >;
  checkOutVisitor: Thunk<
    VisitModel,
    CheckInOutCancelVisitorOpts,
    Injections,
    StoreModel
  >;
  cancelVisit: Thunk<
    VisitModel,
    CheckInOutCancelVisitOpts,
    Injections,
    StoreModel
  >;
  cancelVisitorVisit: Thunk<
    VisitModel,
    CheckInOutCancelVisitorOpts,
    Injections,
    StoreModel
  >;
  resendInvitation: Thunk<
    VisitModel,
    ResendInvitationOpts,
    Injections,
    StoreModel
  >;
}

export const visitModel: VisitModel = {
  visits: { items: [], total: 0, has_more: false, total_checked_in: 0 },
  visitors: { items: [], total: 0, has_more: false },
  visit: null,
  setVisit: action((state, payload: Visit) => {
    state.visit = payload;
  }),
  setVisits: action((state, payload) => {
    state.visits = payload;
  }),
  setVisitors: action((state, payload) => {
    state.visitors = payload;
  }),
  getVisit: thunk(async (actions, payload: GetVisitOpts, { injections }) => {
    const { visitsService } = injections;
    const response = await visitsService.getVisit(payload.id);
    if (response.status === 200 && response.data.success) {
      actions.setVisit(response.data.data);
    }
  }),
  listVisits: thunk(
    async (actions, payload: ListVisitsOpts, { injections }) => {
      const { visitsService } = injections;
      const response = await visitsService.listVisits(
        payload.buildingId,
        payload.offset,
        payload.limit,
        payload.filter
      );
      if (response.status === 200 && response.data.success) {
        const visits: IListVisitsResponse<Visit> = response.data;
        actions.setVisits(visits.data);
      }
    }
  ),
  listVisitors: thunk(
    async (actions, payload: ListVisitorsOpts, { injections }) => {
      const { visitsService } = injections;
      const response = await visitsService.listVisitors(
        payload.buildingId,
        payload.offset,
        payload.limit
      );
      if (response.status === 200 && response.data.success) {
        const visitors: IListVisitorsResponse<Visitor> = response.data;
        actions.setVisitors(visitors.data);
      }
    }
  ),
  createVisit: thunk(
    async (actions, payload: CreateVisitOpts, { injections }) => {
      const { visitsService } = injections;
      const response = await visitsService.createVisit(payload);
      if (response.status === 201 && response.data.success) {
        actions.setVisit(response.data.data);
      }
    }
  ),
  updateVisit: thunk(
    async (actions, payload: UpdateVisitOpts, { injections }) => {
      const { visitsService } = injections;
      const response = await visitsService.updateVisit(payload);
      if (response.status === 200 && response.data.success) {
        actions.setVisit(response.data.data);
      }
    }
  ),
  addVisitors: thunk(
    async (actions, payload: AddVisitorsOpts, { injections }) => {
      const { visitsService } = injections;
      const response = await visitsService.addVisitors(payload);
      if (response.status === 201 && response.data.success) {
        actions.setVisit(response.data.data);
      }
    }
  ),
  removeVisitors: thunk(
    async (actions, payload: RemoveVisitorsOpts, { injections }) => {
      const { visitsService } = injections;
      const response = await visitsService.removeVisitors(payload);
      if (response.status === 200 && response.data.success) {
        actions.setVisit(response.data.data);
      }
    }
  ),
  checkInVisit: thunk(
    async (
      actions,
      payload: CheckInOutCancelVisitOpts,
      { injections, getState }
    ) => {
      const { visitsService } = injections;
      const response = await visitsService.checkInVisit(payload);

      if (response.data.success) {
        const updatedVisit = response.data.data;
        actions.setVisit(updatedVisit);

        const state = getState();
        const visitIndex = state.visits.items.findIndex(
          visit => visit.id === updatedVisit.id
        );

        if (visitIndex !== -1) {
          const newVisitsItems = [...state.visits.items];
          newVisitsItems.splice(visitIndex, 1, updatedVisit);

          actions.setVisits({
            ...state.visits,
            items: newVisitsItems,
          });
        }
      }
    }
  ),
  checkInVisitor: thunk(
    async (actions, payload: CheckInOutCancelVisitorOpts, { injections }) => {
      const { visitsService } = injections;
      const response = await visitsService.checkInVisitor(payload);
      if (response.status === 201 && response.data.success) {
        actions.setVisit(response.data.data);
      }
    }
  ),
  checkOutVisit: thunk(
    async (
      actions,
      payload: CheckInOutCancelVisitOpts,
      { injections, getState }
    ) => {
      const { visitsService } = injections;
      const response = await visitsService.checkOutVisit(payload);

      if (response.data.success) {
        const updatedVisit = response.data.data;
        actions.setVisit(updatedVisit);

        const state = getState();
        const visitIndex = state.visits.items.findIndex(
          visit => visit.id === updatedVisit.id
        );

        if (visitIndex !== -1) {
          const newVisitsItems = [...state.visits.items];
          newVisitsItems.splice(visitIndex, 1, updatedVisit);

          actions.setVisits({
            ...state.visits,
            items: newVisitsItems,
          });
        }
      }
    }
  ),
  checkOutVisitor: thunk(
    async (actions, payload: CheckInOutCancelVisitorOpts, { injections }) => {
      const { visitsService } = injections;
      const response = await visitsService.checkOutVisitor(payload);
      if (response.status === 201 && response.data.success) {
        actions.setVisit(response.data.data);
      }
    }
  ),
  cancelVisit: thunk(
    async (
      actions,
      payload: CheckInOutCancelVisitOpts,
      { injections, getState }
    ) => {
      const { visitsService } = injections;
      const response = await visitsService.cancelVisit(payload);

      if (response.data.success) {
        const updatedVisit = response.data.data;
        actions.setVisit(updatedVisit);

        const state = getState();
        const visitIndex = state.visits.items.findIndex(
          visit => visit.id === updatedVisit.id
        );

        if (visitIndex !== -1) {
          const newVisitsItems = [...state.visits.items];
          newVisitsItems.splice(visitIndex, 1, updatedVisit);

          actions.setVisits({
            ...state.visits,
            items: newVisitsItems,
          });
        }
      }
    }
  ),
  cancelVisitorVisit: thunk(
    async (actions, payload: CheckInOutCancelVisitorOpts, { injections }) => {
      const { visitsService } = injections;
      const response = await visitsService.cancelVisitorVisit(payload);

      if (response.status === 201 && response.data.success) {
        actions.setVisit(response.data.data);
      }
    }
  ),
  resendInvitation: thunk(
    async (_actions, payload: ResendInvitationOpts, { injections }) => {
      const { visitsService } = injections;
      const response = await visitsService.resendInvitation(payload);

      return response.data?.success;
    }
  ),
};
