import React, { useState, useEffect } from "react";
import { match as Match } from "react-router-dom";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import {
  EnumOrderStatus,
  SearchableGlobalOrderFilterInput,
  SearchableProviderSortableFields,
  SearchableGlobalOrderSortableFields,
  SearchableSortDirection,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { ALL_FILTER, IFilterField } from "../../../../components/Table";
import { EnumFilterFields } from "../../../../utils/filter";
import { getTranslation } from "../../../../utils/translation";
import contextStore from "../../../../redux/store";
import { getLocale } from "../../../../lang";
import GlobalOrdersTable, { ILoadOptionsResponse } from "./table";
import PageSubheader from "../../../../components/PageSubheader";

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
}

const LIMIT = 20;

const ListGlobalOrders = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { intl, match } = props;

  const searchGlobalOrders = GroundGraphqlContextStore.useStoreActions(
    actions => actions.globalOrder.searchGlobalOrders
  );

  const searchProviders = GroundGraphqlContextStore.useStoreActions(
    actions => actions.provider.searchProviders
  );

  const globalOrders = GroundGraphqlContextStore.useStoreState(
    state => state.globalOrder.globalOrders.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.globalOrder.globalOrders.total
  );

  const center = GroundGraphqlContextStore.useStoreState(
    state => state.center.center
  );

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (refresh) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  const fetchData = (
    pageIndex = 0,
    loader = true,
    filters?: IFilterField<any>[]
  ) => {
    if (loader) setLoading(true);
    fetchGlobalOrders(pageIndex, filters).finally(() => {
      setLoading(false);
    });
  };

  const loadProviders = async (
    _searchQuery: any,
    _loadedOptions: any,
    { page }: { page: number }
  ) => {
    const searchProvidersResponse = await searchProviders({
      limit: LIMIT,
      sort: {
        field: SearchableProviderSortableFields.name,
        direction: SearchableSortDirection.asc,
      },
      locale: currentAppLocale.backend_locale,
      from: (page - 1) * LIMIT,
    });
    const { items } = searchProvidersResponse;
    let options = items.map(({ id, name }) => {
      return { id, name: getTranslation(name) };
    });

    options = [
      { id: ALL_FILTER, name: intl.formatMessage({ id: "general.all" }) },
      ...options,
    ];

    return {
      options,
      hasMore: LIMIT * page < searchProvidersResponse.total,
      additional: {
        page: page + 1,
      },
    };
  };

  const fetchGlobalOrders = (pageIndex = 0, filters?: IFilterField<any>[]) => {
    let filter: SearchableGlobalOrderFilterInput = {
      globalOrderCenterId: {
        eq: match.params.cid,
      },
      status: { ne: EnumOrderStatus.PENDING },
    };

    if (filters?.length) {
      filters.forEach(f => {
        if (f.type === EnumFilterFields[EnumFilterFields.STATUS]) {
          if (f.value?.value !== ALL_FILTER) {
            filter = {
              ...filter,
              [f.type.toLocaleLowerCase()]: { eq: `${f.value.value}` },
            };
          }
        }
        if (f.type === EnumFilterFields[EnumFilterFields.CLIENT_NAME]) {
          if (f.value) {
            filter = {
              ...filter,
              userLastname: { matchPhrasePrefix: f.value.toLowerCase() },
            };
          }
        }
        if (f.type === EnumFilterFields[EnumFilterFields.EMAIL]) {
          if (f.value) {
            filter = {
              ...filter,
              userEmail: { matchPhrasePrefix: f.value.toLowerCase() },
            };
          }
        }
        if (f.type === EnumFilterFields[EnumFilterFields.PROVIDER]) {
          if (f.value.id !== ALL_FILTER) {
            filter = {
              ...filter,
              providerId: { eq: f.value.id },
            };
          }
        }
      });
    }

    return searchGlobalOrders({
      limit: LIMIT,
      filter,
      sort: {
        field: SearchableGlobalOrderSortableFields.timeplaced,
        direction: SearchableSortDirection.desc,
      },
      from: pageIndex * LIMIT,
    });
  };

  const keyProvider = EnumFilterFields[EnumFilterFields.PROVIDER];
  const loadOptions: {
    [key: string]: (
      searchQuery: any,
      _loadedOptions: any,
      { page }: { page: number }
    ) => Promise<ILoadOptionsResponse>;
  } = {};
  loadOptions[`${keyProvider}`] = loadProviders;

  if (!center) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: "page.list.orders.document.title" })}
        </title>
      </Helmet>

      <PageSubheader title="page.list.orders.title" nbOfResults={total} />

      <div className="px-8">
        <GlobalOrdersTable
          center={center}
          globalOrders={globalOrders}
          total={total}
          setRefresh={setRefresh}
          onChange={({ pageIndex, filters }) =>
            fetchData(pageIndex, false, filters)
          }
          loadOptions={loadOptions}
          loading={loading}
          setLoading={setLoading}
          match={match}
        />
      </div>
    </>
  );
};

export default injectIntl(ListGlobalOrders);
