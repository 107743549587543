import { v4 as uuidv4 } from "uuid";
import { ActionTypes, getFieldAttributeByAttributeKey } from "../types";
import { AttributeKey } from "../../lib/ground-aws-graphql-core/models/AttributeKey";
import { Center } from "../../lib/ground-aws-graphql-core/models/Center";
import { Attribute } from "../../lib/ground-aws-graphql-core/models/Attribute";
import {
  EnumAttributeType,
  EnumAttributeTypology,
} from "../../lib/ground-aws-graphql-core/api/graphql/types";
import { Product } from "../../lib/ground-aws-graphql-core/models/Product";
import { Provider } from "../../lib/ground-aws-graphql-core/models/Provider";
import { User } from "../../lib/ground-aws-graphql-core/models/User";

export enum CenterAttributeKeys {
  RATING = "rating",
}

export enum ProviderAttributeKeys {
  STRIPE_ACCOUNT_ID = "STRIPE_ACCOUNT_ID",
  STRIPE_PAYOUTS_ENABLED = "STRIPE_PAYOUTS_ENABLED",
  STRIPE_CHARGES_ENABLED = "STRIPE_CHARGES_ENABLED",
  TRANSACTION_FEE_PERCENT = "TRANSACTION_FEE_PERCENT",
}

export enum ProductAttributeKeys {
  IN_CATALOG = "in_catalog",
  AS_OPTION = "as_option",
  MINIMAL_APPROVAL = "minimal_approval",
}

export enum OneSignalAttributeKeys {
  ONESIGNAL_BO_APP_ID = "ONESIGNAL_BO_APP_ID",
  ONESIGNAL_BO_API_KEY = "ONESIGNAL_BO_API_KEY",
  ONESIGNAL_ANDROID_APP_ID = "ONESIGNAL_ANDROID_APP_ID",
  ONESIGNAL_ANDROID_API_KEY = "ONESIGNAL_ANDROID_API_KEY",
  ONESIGNAL_IOS_APP_ID = "ONESIGNAL_IOS_APP_ID",
  ONESIGNAL_IOS_API_KEY = "ONESIGNAL_IOS_API_KEY",
}

export enum MsOfficeAttributeKeys {
  MS_OFFICE_RESOURCE_ID = "MS_OFFICE_RESOURCE_EMAIL",
  MS_OFFICE_SUBSCRIPTION_ID = "MS_OFFICE_SUBSCRIPTION_ID",
  MS_OFFICE_EVENT_ID = "MS_OFFICE_EVENT_ID",
  MS_OFFICE_EVENT_ORGANIZER_EMAIL_ADDRESS_ID = "MS_OFFICE_EVENT_ORGANIZER_EMAIL_ADDRESS_ID",
  MS_OFFICE_EVENT_ROOM_ID = "MS_OFFICE_EVENT_ROOM_ID",
}

export enum GAAttributeKeys {
  GA_URL = "GA_URL",
}

export interface AttributeItem {
  actionKey: ActionTypes;
  actionValue: ActionTypes;
  item: Attribute;
}

export const getAttributeItem = (
  attributeKeys: AttributeKey[],
  attribute: Attribute,
  type: EnumAttributeType
): AttributeItem | null => {
  const attributeName = attribute.key.name;
  const attributeKey = attributeKeys.find(
    e => e.name === attributeName && e.type === type
  );

  if (attributeKey && attribute) {
    const field = getFieldAttributeByAttributeKey(attributeKey);
    const attrValue =
      attribute && field && typeof attribute[`${field}`] !== undefined
        ? attribute[`${field}`]
        : null;
    if (attrValue !== null || typeof attrValue !== undefined) {
      const params = {};
      params[`${field}`] = attrValue;
      const element: AttributeItem = {
        item: {
          ...params,
          id: attribute.id,
          key: attributeKey,
          //FIXME: to delete
          value: "",
        },
        actionKey: attributeKey ? ActionTypes.TO_KEEP : ActionTypes.TO_ADD,
        actionValue: attribute.id ? ActionTypes.TO_KEEP : ActionTypes.TO_ADD,
      };

      return element;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const createAttributeItem = (
  value: string | number | boolean,
  attributeKey: AttributeKey,
  field: string | undefined
): AttributeItem => {
  const params = {};
  params[`${field}`] = value;
  const item = {
    ...params,
    id: uuidv4(),
    key: attributeKey,
    //FIXME: to delete
    value: "",
  };
  const element: AttributeItem = {
    item: item,
    actionKey: ActionTypes.TO_KEEP,
    actionValue: ActionTypes.TO_ADD,
  };

  return element;
};

export const getAttributes = (
  object: Center | Product | Provider | User | null,
  type: EnumAttributeType,
  attributeKeys: AttributeKey[]
): AttributeItem[] => {
  if (object?.attributes?.items) {
    const values = Object.values(object.attributes.items).reduce(
      (acc: AttributeItem[], el) => {
        const attributeItem = getAttributeItem(attributeKeys, el, type);
        if (attributeItem) {
          acc.push(attributeItem);
        }

        return acc;
      },
      []
    );

    return values;
  }

  return [];
};

export const getRequiredAttributes = (
  attributeKeys: AttributeKey[],
  attributes: AttributeItem[]
): AttributeItem[] => {
  let requiredAttributeKeys = attributeKeys?.filter(
    e => e.required && e.typology !== EnumAttributeTypology.BOOLEAN
  );
  attributes?.forEach(attr => {
    if (attr.item.key?.typology !== EnumAttributeTypology.BOOLEAN) {
      if (requiredAttributeKeys && requiredAttributeKeys.length > 0) {
        // keep only attribute keys required and not valorised
        requiredAttributeKeys = requiredAttributeKeys.filter(
          e => e.id !== attr.item.key?.id
        );
      }
    }
  });
  const requiredAttributes = [] as AttributeItem[];
  if (requiredAttributeKeys && requiredAttributeKeys.length > 0) {
    requiredAttributeKeys.forEach(attributeKey => {
      const field = getFieldAttributeByAttributeKey(attributeKey);
      const attr = createAttributeItem("", attributeKey, field);
      requiredAttributes.push(attr);
    });
  }

  return requiredAttributes;
};
