import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import IntlMessages from "../../utils/messages";
import Button from "../Tailwind/Button";
import { EnumUnit } from "../../lib/ground-aws-graphql-core/api/graphql/types";
import DateRangeComponent from "../DateRange";
import { endOfDay, getDayInLocal, startOfDay } from "../../utils/config";
import { forceHourToCenterTimezone } from "../../utils/config/index";
import { GroundGraphqlContextStore } from "../../lib/ground-aws-graphql-core";

interface CloseIconProps {
  onClose: () => void;
}

export const CloseIcon = ({ onClose }: CloseIconProps): JSX.Element => (
  <svg
    onClick={onClose}
    width="15"
    height="15"
    viewBox="0 0 8 8"
    className="stroke-current cursor-pointer text-gray-500 hover:text-gray-900"
  >
    <path strokeLinecap="round" strokeWidth="0.7" d="M1 1l6 6m0-6L1 7" />
  </svg>
);

interface Props {
  title: string;
  description: string;
  onSubmit: (start: Date | null, end: Date | null) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  start?: Date | null;
  end?: Date | null;
  disabledDates?: Date[];
  onRequestClose?: (e) => void;
}

interface DateRangeSelection {
  selection: { startDate: Date; endDate: Date };
}

const ModalDateRange: React.FC<Props> = (props: Props) => {
  const {
    title,
    description,
    onSubmit,
    open,
    setOpen,
    start: defaultStart,
    end: defaultEnd,
    disabledDates,
    onRequestClose,
  } = props;

  const { startDay, endDay } = getDayInLocal();
  const [start, setStart] = useState(startDay);
  const [end, setEnd] = useState(endDay);

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  // Default date values into the useStates don't work properly so we need a useEffect
  useEffect(() => {
    if (defaultStart && defaultEnd) {
      setStart(defaultStart);
      setEnd(defaultEnd);
    }
  }, [defaultStart, defaultEnd]);

  const onSelection = ({
    selection: { startDate, endDate },
  }: DateRangeSelection) => {
    setStart(startOfDay(startDate));
    setEnd(endOfDay(endDate));
  };

  const submit = () => {
    onSubmit(
      forceHourToCenterTimezone(start, centerTimezone),
      forceHourToCenterTimezone(end, centerTimezone)
    );
    setOpen(false);
  };

  return (
    <>
      <ReactModal
        ariaHideApp={false}
        isOpen={open}
        style={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
        shouldCloseOnOverlayClick
        onRequestClose={onRequestClose}
        className="modal-dialog-lg max-w-7xl shadow-none modal-dialog-centered "
      >
        <div className="modal-content">
          <div className="py-4 px-6 flex justify-between border-b">
            <h4 className="text-16px leading-6 font-medium text-gray-900">
              <IntlMessages id={title} />
            </h4>

            <CloseIcon onClose={() => setOpen(false)} />
          </div>

          <div className="py-4 px-6">
            <p>
              <IntlMessages id={description} />
            </p>
          </div>

          <DateRangeComponent
            startDate={start}
            endDate={end}
            handleChange={onSelection}
            unit={EnumUnit.DAY}
            disabledDates={disabledDates}
          />

          <div className="flex justify-center border-t py-4">
            <Button
              id="btn-add-closed-day"
              name="btn-add-closed-day"
              item={null}
              type="button"
              onClick={submit}
            >
              <span className="text-center">
                <IntlMessages id="general.submit" />
              </span>
            </Button>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default ModalDateRange;
