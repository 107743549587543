import { Unavailability } from "../Unavailability";
import {
  EnumCurrency,
  EnumUnit,
  EnumOrderStatus,
} from "./../../api/graphql/types";
import { Operator } from "../Operator";
import { Order } from "../Order";
import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel } from "../";
import { ErrorGraphQL } from "../../services/coreUtils";

export interface OrderItem {
  operator?: Operator;
  id: string;
  product?: string;
  status: EnumOrderStatus;
  sku: string;
  name: any;
  description?: any | null;
  pictures?: Array<string | null> | null;
  category?: any | null;
  provider?: any;
  totalPrice: number;
  totalTax: number;
  totalAdjustment: number;
  price: number;
  currency: EnumCurrency;
  unit: EnumUnit;
  unitPrice: number;
  unitQuantity: number;
  increment: number;
  quantity: number;
  variation: any;
  order: Order;
  unavailability?: Unavailability | null;
  vat: number;
  refund?: Refund | null;
  refunded?: boolean;
  relatedOrderItemId?: string;
}

export interface Refund {
  amount: number;
  createdAt: string;
  date: string;
  id: string;
  markForDelete: boolean;
  reason: string;
  refundId: string;
  status: string;
  updatedAt: string;
}

export interface ModelOrderItemConnection {
  items: OrderItem[];
  total?: number | null;
}

export interface GetOrderItemsOpts {
  orderId: string;
}

// Interface declaration
export interface OrderItemModel {
  orderItems: OrderItem[];
  setOrderItems: Action<OrderItemModel, ModelOrderItemConnection>;
  getOrderItems: Thunk<
    OrderItemModel,
    GetOrderItemsOpts,
    Injections,
    StoreModel
  >;
}

export const orderItemModel: OrderItemModel = {
  orderItems: [],
  setOrderItems: action((state, payload) => {
    state.orderItems = payload.items || [];
  }),
  getOrderItems: thunk(async (actions, payload, { injections }) => {
    const { orderItemService } = injections;
    const response = await orderItemService.getOrderItems(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.getOrderItems) {
      const data = response.data.getOrderItems;
      actions.setOrderItems(data);

      return data;
    }

    return null;
  }),
};
