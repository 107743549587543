import React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { contextStore } from "..";
import ApolloBasicClient from "../../api/client/apollo";

let apolloClient;
let token: string;
let GroundGraphqlContextStore: typeof contextStore;

interface GroundProviderOpts {
  jwtToken: string;
  children: React.ReactNode;
}

const GroundGraphqlProvider = (props: GroundProviderOpts) => {
  const { children, jwtToken } = props;
  apolloClient = ApolloBasicClient(jwtToken);
  token = jwtToken;
  GroundGraphqlContextStore = contextStore;

  // return ground apollo provider
  return (
    <ApolloProvider client={apolloClient}>
      <GroundGraphqlContextStore.Provider>
        {children}
      </GroundGraphqlContextStore.Provider>
    </ApolloProvider>
  );
};

export {
  GroundGraphqlProvider,
  GroundGraphqlContextStore,
  apolloClient as ApolloClient,
  token as Token,
};
