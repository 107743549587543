import React from "react";
import ReactQuill from "react-quill";
import { FieldAttributes } from "formik";
import { AdditionalFieldAttributes } from "..";
import "react-quill/dist/quill.snow.css";

interface Props {
  field: FieldAttributes<AdditionalFieldAttributes>;
  onChange: (e: any) => void;
  value: string;
}

const TOOLBAR_MODULES = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
  ],
};

const FormQuill = ({ field, value, onChange }: Props): JSX.Element => {
  return (
    <ReactQuill
      id={field.name}
      value={value}
      modules={TOOLBAR_MODULES}
      onChange={(content, _, source) => {
        // Check the source because the onChange event can be fired without a user input
        // For more information, please read https://github.com/zenoamaro/react-quill#usage and https://github.com/zenoamaro/react-quill/issues/259
        if (source === "user") {
          onChange({
            target: { name: field.name, value: content },
          });
        }
      }}
    />
  );
};

export default FormQuill;
