import { AxiosResponse } from "axios";
import axiosBackend from "../../../../../axiosBackend";

/**
 * forgot password admin user
 *
 * @param id
 * @returns
 */
export const forgotPasswordAdminUser = async (
  id: string
): Promise<AxiosResponse> => {
  return axiosBackend.post(`/backoffice_users/${id}/forgot_password`);
};

/**
 * forgot password user
 *
 * @param id
 * @returns
 */
export const forgotPasswordUser = async (
  id: string
): Promise<AxiosResponse> => {
  return axiosBackend.post(`/users/${id}/forgot_password`);
};
