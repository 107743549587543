import { IntlShape } from "react-intl";
import { GlobalOrder } from "../../lib/ground-aws-graphql-core/models/GlobalOrder";
import { Order } from "../../lib/ground-aws-graphql-core/models/Order";
import { OrderItem } from "../../lib/ground-aws-graphql-core/models/OrderItem";
import {
  EnumChannel,
  EnumOrderStatus,
} from "../../lib/ground-aws-graphql-core/api/graphql/types";
import { PaymentMethod } from "../../lib/ground-aws-graphql-core/models/Api/PaymentMethods";
import { SelectOption } from "../../components/Form";
import { getTranslation } from "../translation";

export const getGlobalOrderTotal = (globalOrder: GlobalOrder): number =>
  globalOrder.price + globalOrder.totalTax - globalOrder.totalAdjustment;

export const isFreeGlobalOrder = (globalOrder: GlobalOrder): boolean => {
  const total = getGlobalOrderTotal(globalOrder);

  return total === 0;
};

export const getOrderTotal = (order: Order): number =>
  order.price + order.totalTax - order.totalAdjustment;

export const getOrderItemTotal = (orderItem: OrderItem): number =>
  orderItem.totalPrice + orderItem.totalTax - orderItem.totalAdjustment;

export const isFree = (order: Order, orderItem?: OrderItem): boolean => {
  const total = orderItem ? getOrderItemTotal(orderItem) : getOrderTotal(order);

  return total === 0;
};

export const isInvoicePayment = (
  order: Order,
  paymentExternalized: boolean
): boolean => {
  const { payment } = order;
  const paymentMethodDetails = payment?.paymentMethodDetails;
  const json = paymentMethodDetails ? JSON.parse(paymentMethodDetails) : {};
  const { type } = json;

  const paymentOnInvoice = type === "invoice";

  return paymentOnInvoice && !paymentExternalized;
};

export const isWebOrder = (order: Order): boolean => {
  return order.channel === EnumChannel.WEB;
};

export const isMobileOrder = (order: Order): boolean => {
  return order.channel === EnumChannel.MOBILE;
};

export const isMobileOrWebOrder = (order: Order | undefined): boolean => {
  if (!order) {
    return false;
  }

  return isMobileOrder(order) || isWebOrder(order);
};

export enum EnumRefundReason {
  DUPLICATE = "duplicate",
  FRAUDULENT = "fraudulent",
  REQUESTED_BY_CUSTOMER = "requested_by_customer",
}

export const getRefundReasons = (intl: IntlShape): SelectOption[] => {
  return [
    {
      value: EnumRefundReason.DUPLICATE,
      label: intl.formatMessage({
        id: `page.order.refund.reason.${EnumRefundReason.DUPLICATE}`,
      }),
    },
    {
      value: EnumRefundReason.FRAUDULENT,
      label: intl.formatMessage({
        id: `page.order.refund.reason.${EnumRefundReason.FRAUDULENT}`,
      }),
    },
    {
      value: EnumRefundReason.REQUESTED_BY_CUSTOMER,
      label: intl.formatMessage({
        id: `page.order.refund.reason.${EnumRefundReason.REQUESTED_BY_CUSTOMER}`,
      }),
    },
  ];
};

export const getPaymentMethods = (
  paymentMethods: PaymentMethod[]
): SelectOption[] => {
  return paymentMethods.map(p => ({
    value: p.code,
    label: getTranslation(p.label),
  }));
};

export const getRefundReason = (
  intl: IntlShape,
  reason: string
): SelectOption | undefined => {
  const item = getRefundReasons(intl).find(r => r.value === reason);

  return item;
};

export enum EnumIncidentStatus {
  NEW = 0,
  CLOSED = 1,
  CANCELED = 2,
}

export const markOrderAsPaid = (
  status: EnumOrderStatus,
  nextStatus: EnumOrderStatus
): boolean => {
  return (
    status === EnumOrderStatus.AWAITING_PAYMENT &&
    nextStatus === EnumOrderStatus.PAID
  );
};

export const isRefunded = (status: EnumOrderStatus): boolean => {
  return status === EnumOrderStatus.REFUNDED;
};

export const isRefundRequested = (status: EnumOrderStatus): boolean => {
  return status === EnumOrderStatus.REFUND_REQUESTED;
};

export const isRefundRequestedOrRefunded = (
  status: EnumOrderStatus
): boolean => {
  return isRefunded(status) || isRefundRequested(status);
};
