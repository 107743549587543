import { AxiosResponse } from "axios";
import axiosBackend from "../../../../../axiosBackend";
import {
  AddVisitorsOpts,
  CheckInOutCancelVisitOpts,
  CheckInOutCancelVisitorOpts,
  CreateVisitOpts,
  RemoveVisitorsOpts,
  ResendInvitationOpts,
  SearchableVisitsFilterInput,
  UpdateVisitOpts,
  Visit,
  Visitor,
} from "../../../models/Api/Visits";
import {
  IGetResponse,
  IListVisitorsResponse,
  IListVisitsResponse,
} from "../../../models/Api";

export const getVisit = async (
  id: string
): Promise<AxiosResponse<IGetResponse<Visit>>> => {
  return axiosBackend.get(`/visits/${id}`);
};

export const listVisits = async (
  buildingId: string,
  offset: number,
  limit: number,
  filter?: SearchableVisitsFilterInput
): Promise<AxiosResponse<IListVisitsResponse<Visit>>> => {
  const params = { offset, limit };

  if (filter) {
    const keys = Object.keys(filter);
    keys.forEach(key => {
      const operator = Object.keys(filter[key]);
      if (operator) {
        const value = filter[key][operator];
        if (operator.includes("between")) {
          value.forEach((val: string, index: number) => {
            params[`${key}[${operator}][${index}]`] = val;
          });
        } else {
          if (value !== null && value !== "" && value !== undefined) {
            params[`${key}[${operator}]`] = value;
          }
        }
      }
    });
  }

  return axiosBackend.get(`/visits/buildings/${buildingId}`, { params });
};

export const listVisitors = async (
  buildingId: string,
  offset: number,
  limit: number
): Promise<AxiosResponse<IListVisitorsResponse<Visitor>>> => {
  const params = { offset, limit };

  return axiosBackend.get(`/visitors/buildings/${buildingId}`, { params });
};

export const createVisit = async (
  payload: CreateVisitOpts
): Promise<AxiosResponse<IGetResponse<Visit>>> => {
  return axiosBackend.post("/visits", payload);
};

export const updateVisit = async (
  payload: UpdateVisitOpts
): Promise<AxiosResponse<IGetResponse<Visit>>> => {
  const { id, date } = payload;

  return axiosBackend.patch(`/visits/${id}`, { date });
};

export const addVisitors = async (
  payload: AddVisitorsOpts
): Promise<AxiosResponse<IGetResponse<Visit>>> => {
  const { id, visitors } = payload;

  return axiosBackend.post(`/visits/${id}/visitors`, { visitors });
};

export const removeVisitors = async (
  payload: RemoveVisitorsOpts
): Promise<AxiosResponse<IGetResponse<Visit>>> => {
  const { id, visitors } = payload;

  return axiosBackend.delete(`/visits/${id}/visitors`, {
    data: {
      visitors,
    },
  });
};

export const checkInVisit = async ({
  id,
}: CheckInOutCancelVisitOpts): Promise<AxiosResponse<IGetResponse<Visit>>> => {
  return axiosBackend.post(`/visits/${id}/check_in`);
};

export const checkInVisitor = async ({
  visitId,
  visitorId,
}: CheckInOutCancelVisitorOpts): Promise<
  AxiosResponse<IGetResponse<Visit>>
> => {
  return axiosBackend.post(`/visits/${visitId}/visitors/${visitorId}/check_in`);
};

export const checkOutVisit = async ({
  id,
}: CheckInOutCancelVisitOpts): Promise<AxiosResponse<IGetResponse<Visit>>> => {
  return axiosBackend.post(`/visits/${id}/check_out`);
};

export const checkOutVisitor = async ({
  visitId,
  visitorId,
}: CheckInOutCancelVisitorOpts): Promise<
  AxiosResponse<IGetResponse<Visit>>
> => {
  return axiosBackend.post(
    `/visits/${visitId}/visitors/${visitorId}/check_out`
  );
};

export const cancelVisit = async ({
  id,
}: CheckInOutCancelVisitOpts): Promise<AxiosResponse<IGetResponse<Visit>>> => {
  return axiosBackend.post(`/visits/${id}/cancel`);
};

export const cancelVisitorVisit = async ({
  visitId,
  visitorId,
}: CheckInOutCancelVisitorOpts): Promise<
  AxiosResponse<IGetResponse<Visit>>
> => {
  return axiosBackend.post(`/visits/${visitId}/visitors/${visitorId}/cancel`);
};

export const resendInvitation = async ({
  id,
}: ResendInvitationOpts): Promise<AxiosResponse<IGetResponse<void>>> => {
  return axiosBackend.post(`/visits/${id}/invitation`);
};
