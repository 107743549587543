import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import compose from "recompose/compose";
import ReactModal from "react-modal";
import AdministratorCreate from "../form";
import Button from "../../../../components/Tailwind/Button";
import IntlMessages from "../../../../utils/messages";
import {
  ActionTypes,
  canAddBuildingAdministrator,
} from "../../../../utils/types";
import {
  isMaxAdministratorsPerCenterReached,
  getMaxAdministratorsPerCenter,
} from "../../../../utils/config";
import LinkButton from "../../../../components/Tailwind/LinkButton";
import { Authorization } from "../../../../lib/ground-aws-graphql-core/models/Authorization";
import { Center } from "../../../../lib/ground-aws-graphql-core/models/Center";
import { BackOfficeUser } from "../../../../lib/ground-aws-graphql-core/models/BackOfficeUser";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import { GroundAuthContextStore } from "../../../../lib/ground-aws-cognito-auth-core";
import Table, { IFilterField } from "../../../../components/Table";
import {
  EnumPermissionEntity,
  EnumBackOfficeUserRole,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import Badge from "../../../../components/Tailwind/Badge";
import {
  getRoleBadgeElements,
  getRoleLabel,
  UserStatus,
} from "../../../../utils/user";
import Tag from "../../../../components/Tag";

interface Props {
  intl: IntlShape;
  match: Match;
  title: string;
  description?: string;
  center: Center;
  isOpen: boolean;
  onRequestClose: (e) => void;
  onAddAuthorization: (u: BackOfficeUser) => void;
  onRemoveAuthorization: (a: Authorization) => void;
  items: { authorization: Authorization; action: ActionTypes }[];
}

const LIMIT = 10;

const ModalAddAdmin = (props: Props) => {
  const {
    isOpen,
    items,
    onRequestClose,
    description,
    intl,
    onAddAuthorization,
    onRemoveAuthorization,
  } = props;
  const [loading, setLoading] = useState(false);
  const [adminView, setAdminView] = useState(false);

  // authorizations not to delete
  const elements = items.filter(i => i.action !== ActionTypes.TO_DELETE);

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const searchUsers = GroundGraphqlContextStore.useStoreActions(
    actions => actions.backOfficeUser.searchBackOfficeUsers
  );

  const authorizedToAddAdministrator = canAddBuildingAdministrator(me);

  const maxAdministratorsPerCenterReached =
    isMaxAdministratorsPerCenterReached(elements);

  const fetchUsers = (
    pageIndex = 0,
    loader = true,
    filters?: IFilterField<any>[]
  ) => {
    if (loader) {
      setLoading(true);
    }
    let filter = {
      role: { eq: EnumBackOfficeUserRole.CenterAdmin },
    };
    if (filters && filters.length) {
      filters.forEach(f => {
        filter = {
          ...filter,
          [f.type.toLocaleLowerCase()]: {
            matchPhrasePrefix: `${f.value.toLowerCase()}`,
          },
        };
      });
    }
    searchUsers({
      filter,
      limit: LIMIT,
      from: pageIndex * LIMIT,
    }).finally(() => {
      if (loader) {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchUsers();
  }, [isOpen]);

  const handleClick = user => {
    // find authorization for this user
    const item = items.find(
      i =>
        i.authorization?.user?.id === user.id &&
        i.action !== ActionTypes.TO_DELETE
    );
    const unchecked = !item;
    const disabled = unchecked && isMaxAdministratorsPerCenterReached(elements);
    if (!disabled) {
      if (!item) {
        // add authorization
        onAddAuthorization(user);
      }
    } else {
      const message = intl.formatMessage(
        { id: "page.center.max.administrators.reached" },
        { total: getMaxAdministratorsPerCenter() }
      );
      toast(message, {
        type: "error",
      });
    }
  };

  const goToAdministratorCreation = () => {
    if (maxAdministratorsPerCenterReached) {
      const message = intl.formatMessage(
        { id: "page.center.max.administrators.reached" },
        { total: getMaxAdministratorsPerCenter() }
      );
      toast(message, {
        type: "error",
      });
    } else {
      setAdminView(true);
    }
  };

  const tableHead = [
    "page.list.administrators.table.head.name",
    "general.email",
    "page.list.users.table.head.role",
    "page.list.users.table.head.account",
  ];

  const backOfficeUsers = GroundGraphqlContextStore.useStoreState(
    state => state.backOfficeUser.backOfficeUsers.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.backOfficeUser.backOfficeUsers.total
  );

  const tableBody = backOfficeUsers?.map((user: BackOfficeUser) => {
    const { lastname, firstname, email, role, status } = user;

    const userElements = [
      {
        element: `${lastname} ${firstname}`,
        onCellClick: () => handleClick(user),
      },
      { element: email, onCellClick: () => handleClick(user) },
      {
        element: (
          <Badge
            bgClassName={getRoleBadgeElements(role).backgroundColor}
            textClassName={getRoleBadgeElements(role).textColor}
          >
            {getRoleLabel(intl, role)}
          </Badge>
        ),
        onCellClick: () => handleClick(user),
      },
      {
        element:
          status &&
          (status === UserStatus.CONFIRMED ||
            status === UserStatus.EXTERNAL_PROVIDER) ? (
            <IntlMessages id="general.CONFIRMED" />
          ) : (
            <IntlMessages id="general.UNCONFIRMED" />
          ),
        onCellClick: () => handleClick(user),
      },
    ];

    return {
      rowElements: userElements,
    };
  });

  return (
    <ReactModal
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={onRequestClose}
    >
      {loading && <div className="loading" />}
      {!loading && !adminView && (
        <div className="py-6">
          <div className="flex justify-between items-center px-8">
            <div>
              <h3 className="text-16px text-ground-black-100">
                <IntlMessages id="page.center.modal.administrators.title" />{" "}
                <span className="text-ground-gray-100">
                  <IntlMessages
                    id="general.max"
                    values={{ total: getMaxAdministratorsPerCenter() }}
                  />
                </span>
              </h3>
              {description && (
                <p className="mt-1 text-13px text-ground-gray-100">
                  <IntlMessages id={description} />
                </p>
              )}
            </div>
            {authorizedToAddAdministrator && (
              <div>
                <Button
                  id="btn-add-administrator"
                  name="btn-add-administrator"
                  type="button"
                  item={null}
                  outline
                  onClick={goToAdministratorCreation}
                >
                  <IntlMessages id="page.list.administrators.create.administrator" />
                </Button>
              </div>
            )}
          </div>
          <Table
            head={tableHead}
            body={tableBody}
            noDataText="page.center.administrators.empty"
            permissionEntity={EnumPermissionEntity.USER}
            onChange={({ pageIndex, filters }) =>
              fetchUsers(pageIndex, false, filters)
            }
            paginationLimit={LIMIT}
            paginationTotal={total}
            className="px-8"
          />
          <div className="flex py-2 px-8">
            <span>
              <IntlMessages
                id="page.center.modal.administrators.selection.total"
                values={{ total: elements.length }}
              />
            </span>
          </div>
          {elements.length > 0 && (
            <div className="px-8">
              {elements.map((item, index) => (
                <Tag
                  key={`${item.authorization.id}_${index}`}
                  id={item.authorization.id}
                  title={`${item.authorization.user.lastname} ${item.authorization.user.firstname}`}
                  deleteCondition
                  handleDelete={() => onRemoveAuthorization(item.authorization)}
                />
              ))}
            </div>
          )}
          <div className="flex py-6 px-8">
            <Button
              id="btn-close-administrator-modal"
              name="btn-close-administrator-modal"
              type="button"
              item={null}
              onClick={e => {
                onRequestClose(e);
              }}
            >
              <span className="text-center">
                <IntlMessages id="general.validate.selection" />
              </span>
            </Button>
          </div>
        </div>
      )}
      {!loading && adminView && (
        <div className="py-6">
          <div className="flex justify-end m-4">
            <LinkButton
              id="link-back-administrator"
              name="link-back-administrator"
              label="page.administrator.back"
              onClick={() => setAdminView(false)}
            />
          </div>
          <AdministratorCreate {...props} />
        </div>
      )}
    </ReactModal>
  );
};

const enhance = compose(injectIntl, withRouter);
export default enhance(ModalAddAdmin);
