import { Thunk, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel } from "../";
import { Image } from "../../../utils/types";
import { News } from "../../../lib/ground-aws-graphql-core/models/News";

export interface NewsOpts {
  news: News | null;
  values: any;
  pictures?: Image[];
  center: {
    id: string;
  };
  newsCategoryId?: string | null;
  me?: any;
  publicationDate: Date;
  unpublicationDate: Date;
  enabled: boolean;
  callbacks: {
    updateNews: any;
    createNews: any;
  };
}

// Interface declaration
export interface NewsModel {
  createNews: Thunk<NewsModel, NewsOpts, Injections, StoreModel>;
  updateNews: Thunk<NewsModel, NewsOpts, Injections, StoreModel>;
}

export const newsModel: NewsModel = {
  createNews: thunk(async (_actions, payload, { injections }) => {
    const { newsService } = injections;
    const response = await newsService.createNews(payload);

    return response;
  }),
  updateNews: thunk(async (_actions, payload, { injections }) => {
    const { newsService } = injections;
    const response = await newsService.updateNews(payload);

    return response;
  }),
};
