import { AxiosResponse } from "axios";
import axiosBackend from "../../../../../axiosBackend";
import {
  CreateAppClientOpts,
  ListAppClientsOpts,
  AppClient,
  GetAppClientOpts,
  DeleteAppClientOpts,
  UpdateAppClientOpts,
} from "../../../models/Api/AppClient";
import { IGetResponse } from "../../../models/Api";

export interface IListResponse<T> {
  data: {
    items: [T];
    total: number;
    has_more: boolean;
    next_url: string | null;
  };
  success: boolean;
}

export const listAppClients = async (
  payload: ListAppClientsOpts
): Promise<AxiosResponse<IListResponse<AppClient>>> => {
  const params = {
    offset: payload.offset,
    ...(payload.limit && {
      limit: payload.limit,
    }),
  };

  return axiosBackend.get("/app_clients", { params });
};

export const createAppClient = async (
  payload: CreateAppClientOpts
): Promise<AxiosResponse<IGetResponse<AppClient>>> => {
  return axiosBackend.post("/app_clients", payload);
};

export const getAppClient = async (
  params: GetAppClientOpts
): Promise<AxiosResponse<IGetResponse<AppClient>>> => {
  return axiosBackend.get(`/app_clients/${params.id}`);
};

export const deleteAppClient = async (
  params: DeleteAppClientOpts
): Promise<AxiosResponse<IGetResponse<AppClient>>> => {
  return axiosBackend.delete(`/app_clients/${params.id}`);
};

export const updateAppClient = async ({
  id,
  ...payload
}: UpdateAppClientOpts): Promise<AxiosResponse<IGetResponse<AppClient>>> => {
  return axiosBackend.put(`/app_clients/${id}`, payload);
};
