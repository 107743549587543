import React from "react";
import { createRoot } from "react-dom/client";
import contextStore from "./redux/store";
import App from "./App";
import { CustomizationProvider } from "./components/OperatorCustomization";
import { GroundAuthProvider } from "./lib/ground-aws-cognito-auth-core";
import reportWebVitals from "./reportWebVitals";
import reportHandler from "./reportHandler";

createRoot(document.getElementById("root") as HTMLElement).render(
  <contextStore.Provider>
    <GroundAuthProvider>
      <CustomizationProvider>
        <App />
      </CustomizationProvider>
    </GroundAuthProvider>
  </contextStore.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(reportHandler);
