import React, { useState } from "react";
import { match as Match } from "react-router-dom";
import { toast } from "react-toastify";
import { injectIntl, IntlShape } from "react-intl";
import Table from "../../../../../components/Table";
import { TableChangeParams } from "../../../../../components/Table/types.js";
import Badge from "../../../../../components/Tailwind/Badge";
import Dropdown from "../../../../../components/Tailwind/Dropdown";
import history from "../../../../../history";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import {
  EnumNotificationType,
  EnumPermissionEntity,
} from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { News } from "../../../../../lib/ground-aws-graphql-core/models/News";
import IntlMessages from "../../../../../utils/messages";
import ConfirmModal from "../../../../../utils/modal/confirm";
import { EnumPaths, navTo } from "../../../../../utils/navigation";
import { getDefaultImage, getImageUrl } from "../../../../../utils/picture";
import { getTranslation } from "../../../../../utils/translation";
import images from "../../../../../images";
import { displayDayDDMMYYYY } from "../../../../../utils/config";

type Props = {
  news: News[] | null;
  total: number | null | undefined;
  onChange: (params: TableChangeParams) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  onDeleted?: () => void;
  intl: IntlShape;
  match: Match<{ cid: string }>;
};

export const EnumNewsStatus = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
};

const NewsTable = (props: Props): JSX.Element => {
  const { news, total, onChange, loading, setLoading, intl, match, onDeleted } =
    props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newsToDeleteId, setNewsToDeleteId] = useState<string | null>(null);

  const updateNewsAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.news.updateNews
  );

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const handleEditNews = (id: string) => {
    const centerId = match.params.cid;
    history.push(
      navTo(
        `${EnumPaths.MARKETING}/${EnumPaths.NEWS}/${id}/${EnumPaths.EDIT_MODE}`,
        centerId
      )
    );
  };

  const handleNotify = (id: string) => {
    const centerId = match.params.cid;

    localStorage.setItem("parentNavItem", "general");
    localStorage.setItem("navItem", "notifications");

    history.push(
      navTo(
        `${
          EnumPaths.NOTIFICATIONS
        }/${EnumNotificationType.NEWS.toLocaleLowerCase()}/${id}/${
          EnumPaths.CREATION_MODE
        }`,
        centerId
      )
    );
  };

  const handleDelete = () => {
    if (newsToDeleteId) {
      setLoading(true);
      setShowDeleteModal(false);
      updateNewsAction({ id: newsToDeleteId, markForDelete: true })
        .then(() => {
          if (typeof onDeleted === "function") {
            onDeleted();
          }
          toast(
            intl.formatMessage({
              id: "general.news.delete",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          toast(
            intl.formatMessage({
              id: "general.news.delete",
            }),
            {
              type: "error",
            }
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const newsTableHead = [
    "general.title",
    "general.author",
    "page.list.news.table.head.startdate",
    "page.list.news.table.head.enddate",
    "general.status",
    "general.actions",
  ];

  const newsTableBody = news?.map(newsElement => {
    const {
      title,
      picture,
      author,
      publicationDate,
      unpublicationDate,
      enabled,
      id,
    } = newsElement;

    const newsTitle = getTranslation(title);

    const dropdownValues = [
      {
        id: "edit_new",
        label: "general.edit",
        icon: images.edit,
        onClick: () => handleEditNews(id),
      },
      {
        id: "notify",
        label: "general.notify",
        icon: images.notification,
        onClick: () => handleNotify(id),
      },
      {
        id: "delete_new",
        label: "general.delete",
        icon: images.deleteIcon,
        onClick: () => {
          setNewsToDeleteId(id);
          setShowDeleteModal(!showDeleteModal);
        },
      },
    ];

    return {
      rowElements: [
        {
          element: (
            <div className="flex flew-row items-center">
              <div>
                <img
                  alt={newsTitle}
                  src={
                    picture
                      ? getImageUrl(null, picture, 50, 50)
                      : getDefaultImage(null)
                  }
                  style={picture ? undefined : { width: 50, height: 50 }}
                  className="list-thumbnail responsive border-0"
                />
              </div>
              <div className="pl-2">
                <span className="block" data-cy={`news-title-${id}`}>
                  {newsTitle}
                </span>
              </div>
            </div>
          ),
          onCellClick: () => handleEditNews(id),
        },
        {
          element: `${author?.lastname} ${author?.firstname}`,
          onCellClick: () => handleEditNews(id),
        },
        {
          element: displayDayDDMMYYYY(publicationDate, centerTimezone),
          onCellClick: () => handleEditNews(id),
        },
        {
          element: displayDayDDMMYYYY(unpublicationDate, centerTimezone),
          onCellClick: () => handleEditNews(id),
        },
        {
          element: enabled ? (
            <Badge label="general.actif" bgClassName="bg-ground-green-100" />
          ) : (
            <Badge label="general.inactif" bgClassName="bg-red-500" />
          ),
          onCellClick: () => handleEditNews(id),
        },
        {
          element: (
            <Dropdown values={dropdownValues} dataCy={`news-dropdown-${id}`} />
          ),
        },
      ],
    };
  });

  return (
    <>
      <Table
        permissionEntity={EnumPermissionEntity.NEWS}
        head={newsTableHead}
        body={newsTableBody}
        paginationTotal={total}
        onChange={onChange}
        noDataText="page.list.news.empty"
        loading={loading}
        useFilterBlock
        filterBlockTitle="page.list.news.filter.title"
        setLoading={setLoading}
      />
      <ConfirmModal
        isOpen={showDeleteModal}
        onRequestClose={() => setShowDeleteModal(!showDeleteModal)}
        toggle={() => setShowDeleteModal(!showDeleteModal)}
        handleConfirm={handleDelete}
        content={<IntlMessages id="page.news.delete.news" />}
      />
    </>
  );
};

export default injectIntl(NewsTable);
