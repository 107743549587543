import React, { useState } from "react";
import { injectIntl, IntlShape } from "react-intl";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  Suggestion,
} from "react-places-autocomplete";

import IntlMessages from "../../utils/messages";

interface Props {
  intl: IntlShape;
  getGeolocation: (e: Address | null) => void;
  placesAutocomplete: boolean;
}

export interface Address {
  street: string;
  zipCode: string;
  city: string;
  country: string;
  country_code: string;
  location?: {
    lat: number;
    lon: number;
  };
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

const SearchBox = (props: Props) => {
  const { getGeolocation, placesAutocomplete, intl } = props;
  const [address, setAddress] = useState<string>("");
  const [noResults, setNoResults] = useState<boolean>(false);

  const handleChangeAddress = (add: string) => {
    setAddress(add);
    if (!add) {
      getGeolocation(null);
      // no address
    }
    setNoResults(false);
  };

  const handleSelectAddress = async (s: string) => {
    try {
      const completeAddress = await handleSelect(s);
      if (completeAddress) {
        getGeolocation(completeAddress);
        const newAddress = `${completeAddress.street}, ${completeAddress.zipCode}, ${completeAddress.city}`;
        handleChangeAddress(newAddress);
      } else {
        // no address
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleSelect = async (add: string): Promise<Address | null> => {
    try {
      const results = await geocodeByAddress(add);
      const firstResult = results[0];
      const completeAddress = getAddress(firstResult);
      const latLng = await getLatLng(firstResult);

      completeAddress.location = {
        lat: latLng.lat,
        lon: latLng.lng,
      };

      return completeAddress;
    } catch (e) {
      console.error(e);

      return null;
    }
  };

  // Log error status and clear dropdown when Google Maps API returns an error.
  const handleError = (status, clearSuggestions) => {
    console.error("Google Maps API returned error with status: ", status);
    clearSuggestions();
    if (status === "ZERO_RESULTS") {
      // no results
      setNoResults(true);
    }
  };

  const getAddress = (result: {
    address_components: AddressComponent[];
    formatted_address: string;
  }) => {
    const newAddress: Address = {
      city: "",
      zipCode: "",
      country: "",
      country_code: "",
      street: "",
    };

    let streetNumber = "";
    let street = "";
    const components = result.address_components;
    components.forEach(component => {
      if (component.types.includes("street_number"))
        streetNumber = component.short_name;
      if (component.types.includes("route")) street = component.long_name;
      if (component.types.includes("locality"))
        newAddress.city = component.long_name;
      if (component.types.includes("postal_code"))
        newAddress.zipCode = component.long_name;
      if (component.types.includes("country")) {
        newAddress.country = component.long_name;
        newAddress.country_code = component.short_name;
      }
    });
    newAddress.street = `${streetNumber} ${street}`;

    return newAddress;
  };

  if (!placesAutocomplete) {
    return null;
  }

  const options = {
    types: ["address"],
  };

  const renderSuggestion = (
    suggestion: Suggestion,
    getSuggestionItemProps: any,
    index: number
  ) => {
    const className = suggestion.active
      ? "suggestion-item--active my-2"
      : "suggestion-item my-2";
    const style = suggestion.active
      ? { backgroundColor: "#fafafa", cursor: "pointer" }
      : { backgroundColor: "#ffffff", cursor: "pointer" };

    return (
      <div
        key={index}
        {...getSuggestionItemProps(suggestion, {
          className,
          style,
        })}
      >
        <span className="text-blue-700">{suggestion.description}</span>
      </div>
    );
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChangeAddress}
      onSelect={handleSelectAddress}
      onError={handleError}
      highlightFirstSuggestion
      searchOptions={options}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="mt-4">
          <div className="flex mb-1">
            <span className="text-14px font-medium leading-5 text-ground-gray-100">
              <IntlMessages id="page.center.search.address" />
            </span>
          </div>
          <div className="relative max-w-xs rounded-md shadow-sm">
            <input
              {...getInputProps({
                placeholder: intl.formatMessage({
                  id: "page.center.input.address",
                }),
                className:
                  "form-input block w-full transition duration-150 ease-in-out sm:text-14px sm:leading-5 placeholder-ground-gray-100 text-ground-black-100 placeholder-ground-black-100",
              })}
            />
          </div>
          <div className="autocomplete-dropdown-container">
            {loading && (
              <div className="flex my-2">
                <span className="text-blue-700">
                  <IntlMessages id="page.center.input.address.loading" />
                </span>
              </div>
            )}
            {suggestions.map((suggestion, index) => {
              return renderSuggestion(
                suggestion,
                getSuggestionItemProps,
                index
              );
            })}
            {noResults && (
              <div className="flex my-2">
                <span className="text-red-500 italic">
                  <IntlMessages id="page.center.input.address.invalid" />
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default injectIntl(SearchBox);
