import React from "react";
import Slider from "react-slick";
import { Image, ActionTypes } from "../../utils/types";
import ImageComponent from "../Tailwind/ImageGallery/Image";
import ImageInput from "../Tailwind/ImageInput";
import images from "../../images";
import { getCypressTestId } from "../../utils/config";
import { Center } from "../../lib/ground-aws-graphql-core/models/Center";
import "./styles.css";
import { EnumPermissionEntity } from "../../lib/ground-aws-graphql-core/api/graphql/types";

interface Props {
  center: Center;
  pictures: Image[];
  onAddImage: (result: string | ArrayBuffer, file: File) => void;
  onRemoveImage: (image: Image) => void;
}

const SETTINGS = {
  dots: false,
  infinite: false,
  focusOnSelect: true,
  slidesToShow: 4,
  slidesToScroll: 1,
};

const MAX_PHOTOS = 5;

const ImageCarousel = (props: Props): JSX.Element => {
  const { pictures, center, onAddImage, onRemoveImage } = props;

  const notDeletedPictures = pictures.filter(
    picture => picture.action !== ActionTypes.TO_DELETE
  );

  return (
    <div className="p-5 w-full">
      <Slider {...SETTINGS}>
        {notDeletedPictures.length < MAX_PHOTOS && (
          <ImageInput
            item={center}
            label="general.add"
            onAddImage={onAddImage}
            className="hover:bg-black"
          />
        )}

        {notDeletedPictures.map((picture, index) => (
          <div className="relative" key={index}>
            <ImageComponent
              image={picture}
              width={300}
              height={140}
              entity={EnumPermissionEntity.CENTER}
            />
            <img
              data-cy={picture.picture}
              data-testid={getCypressTestId(center)}
              src={images.trash}
              alt="trash"
              className="absolute cursor-pointer right-2 top-2 bg-white border border-red-500 opacity-100 p-1 rounded-5"
              style={{ top: "8px", right: "20px" }}
              onClick={() => onRemoveImage(picture)}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageCarousel;
