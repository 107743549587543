import React from "react";
import { match as Match } from "react-router-dom";
import { IntlShape, injectIntl } from "react-intl";
import { BodyElement } from "../../../../../components/Table/types";
import Table from "../../../../../components/Table";
import {
  EnumVisitStatus,
  EnumVisitVisitorStatus,
  Visit,
} from "../../../../../lib/ground-aws-graphql-core/models/Api/Visits";
import { getVisitorStatusBadgeColor } from "../../../../../utils/visits";
import Dropdown, {
  DropdownValues,
} from "../../../../../components/Tailwind/Dropdown";
import images from "../../../../../images";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import { displayDayDDMMYYYY_HHMM } from "../../../../../utils/config";

type Props = {
  intl: IntlShape;
  match: Match<{ cid: string }>;
  visit: Visit;
  onRemoveVisitor: (visit: Visit, id: number) => void;
  onCheckInVisitor: (visit: Visit, id: number) => void;
  onCheckOutVisitor: (visit: Visit, id: number) => void;
  onCancelVisitorVisit: (visit: Visit, id: number) => void;
};

const ListVisitorsTable = (props: Props): JSX.Element => {
  const {
    intl,
    visit,
    onRemoveVisitor,
    onCheckInVisitor,
    onCheckOutVisitor,
    onCancelVisitorVisit,
  } = props;
  const { visitors } = visit;

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const visitsTableHead = [
    "general.visitor",
    "general.email",
    "general.company",
    "general.status",
    "general.check.in",
    "general.check.out",
    "general.actions",
  ];

  const visitorsTableBody = visitors?.map(item => {
    const {
      id,
      last_name,
      first_name,
      visit_status,
      email,
      company_name,
      visit_checked_in,
      visit_checked_out,
    } = item;

    const dropdownValues: DropdownValues[] = [];
    if (!visit_status) {
      //
      dropdownValues.push({
        id: "checkIn",
        label: "page.list.visitors.actions.check.in",
        icon: images.checkIn,
        onClick: () => {
          onCheckInVisitor(visit, id);
        },
      });

      if (visit.status === EnumVisitStatus.PREREGISTERED) {
        dropdownValues.push({
          id: "cancel",
          label: "page.list.visitors.actions.cancel",
          icon: images.cancel,
          onClick: () => {
            onCancelVisitorVisit(visit, id);
          },
        });
        if (visit.visitors.length > 1) {
          dropdownValues.push({
            id: "cancel",
            label: "page.list.visitors.actions.remove",
            icon: images.trash,
            onClick: () => {
              onRemoveVisitor(visit, id);
            },
          });
        }
      }
    }

    if (visit_status === EnumVisitVisitorStatus.CHECKED_IN) {
      dropdownValues.push({
        id: "checkOut",
        label: "page.list.visitors.actions.check.out",
        icon: images.checkOut,
        onClick: () => {
          onCheckOutVisitor(visit, id);
        },
      });
    }

    const elements: BodyElement[] = [
      {
        element: `${last_name} ${first_name}`,
      },
      {
        element: email,
      },
      {
        element: company_name,
      },
      {
        element: visit_status ? (
          <div
            className={`rounded-full p-1 text-white ${getVisitorStatusBadgeColor(
              visit_status
            )}`}
          >
            {intl.formatMessage({
              id: `page.list.visits.visitor.status.${visit_status}`,
            })}
          </div>
        ) : (
          ""
        ),
      },
      {
        element: displayDayDDMMYYYY_HHMM(visit_checked_in, centerTimezone),
      },
      {
        element: displayDayDDMMYYYY_HHMM(visit_checked_out, centerTimezone),
      },
      {
        element: (
          <Dropdown values={dropdownValues} dataCy={`visit-dropdown-${id}`} />
        ),
      },
    ];

    return {
      rowElements: elements,
    };
  });

  return (
    <Table head={visitsTableHead} body={visitorsTableBody} noDataText="" />
  );
};

export default injectIntl(ListVisitorsTable);
