import React from "react";
import IntlMessages from "../../utils/messages";
import GoBackButton from "../GoBackButton";
import Button from "../Tailwind/Button";

interface Props {
  title?: string;
  nbOfResults?: number | null;
  buttonRightTitle?: string;
  buttonRightAction?: () => void;
  buttonRightId?: string;
  goBackEnabled?: boolean;
  goBackId?: string;
  goBackURL?: string;
  location?: { state: any };
}

const PageSubheader = (props: Props): JSX.Element => {
  const {
    title,
    nbOfResults,
    buttonRightTitle,
    buttonRightAction,
    buttonRightId,
    goBackEnabled,
    goBackId,
    goBackURL,
    location,
  } = props;

  return (
    <div className="md:flex md:items-center md:justify-between bg-white px-8 h-20 w-full shadow-ground-1">
      <div className="flex items-center">
        {goBackEnabled && goBackId && (
          <GoBackButton
            className="mr-8"
            id={goBackId}
            name={goBackId}
            url={goBackURL}
            location={location}
          />
        )}

        <div style={{ fontFamily: "OpenSans-SemiBold" }}>
          <span className="text-20px text-gray-900 leading-5">
            {title && <IntlMessages id={title} />}
          </span>

          {nbOfResults !== undefined && (
            <span className="text-17px text-ground-blue-100 leading-5">
              {" "}
              ({nbOfResults})
            </span>
          )}
        </div>
      </div>

      {buttonRightAction && buttonRightTitle && buttonRightId && (
        <Button
          id={buttonRightId}
          name={buttonRightId}
          item={null}
          outline
          type="button"
          onClick={buttonRightAction}
        >
          <IntlMessages id={buttonRightTitle} />
        </Button>
      )}
    </div>
  );
};

export default PageSubheader;
