import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { Location } from "history";
import { injectIntl, IntlShape } from "react-intl";
import { TableChangeParams } from "../../../components/Table/types";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import PageSubheader from "../../../components/PageSubheader";
import { EnumPaths } from "../../../utils/navigation";
import history from "../../../history";
import Table, { ALL_FILTER } from "../../../components/Table";
import Dropdown, {
  DropdownValues,
} from "../../../components/Tailwind/Dropdown";
import images from "../../../images";
import { AttributeKey } from "../../../lib/ground-aws-graphql-core/models/Api/AttributeKey";
import { getTranslation } from "../../../utils/translation";
import AttributeKeyType from "../../../components/Tailwind/AttributeKeyType";
import { EnumAttributeType } from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import Badge from "../../../components/Tailwind/Badge";
import ConfirmModal from "../../../utils/modal/confirm";
import IntlMessages from "../../../utils/messages";
import {
  getEntityType,
  isAttributeSystem,
  isSystemAdmin,
} from "../../../utils/types";
import { displayDayDDMMYYYY_HHMM } from "../../../utils/config";
import { GroundAuthContextStore } from "../../../lib/ground-aws-cognito-auth-core";

interface Props {
  intl: IntlShape;
  location: Location<{
    pageIndex: number;
    limit: number;
    type: string;
  }>;
}

const DEFAULT_LIMIT = 10;

const ListAttributeKeys = (props: Props): JSX.Element => {
  const { intl, location } = props;
  const [loading, setLoading] = useState(false);

  const [showDeleteAttributeKeyModal, setShowDeleteAttributeKeyModal] =
    useState(false);
  const [attributeKeyId, setAttributeKeyId] = useState<string>();
  const [initialParams, setInitialParams] = useState<TableChangeParams | null>(
    location?.state
  );

  // init attribute key type
  const [type, setType] = useState<EnumAttributeType | undefined>();

  const listAttributeKeys = GroundGraphqlContextStore.useStoreActions(
    actions => actions.attributeKeys.listAttributeKeys
  );

  const attributeKeys = GroundGraphqlContextStore.useStoreState(
    state => state.attributeKeys.attributeKeys.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.attributeKeys.attributeKeys.total
  );

  const setAttributeKey = GroundGraphqlContextStore.useStoreActions(
    actions => actions.attributeKeys.setAttributeKey
  );

  const deleteAttributeKey = GroundGraphqlContextStore.useStoreActions(
    actions => actions.attributeKeys.deleteAttributeKey
  );

  useEffect(() => {
    if (location?.state) {
      fetchData(location?.state?.pageIndex, true, location?.state?.limit);
    } else fetchData();
  }, [type]);

  const fetchData = (pageIndex = 0, loader = true, limit = DEFAULT_LIMIT) => {
    if (loader) setLoading(true);

    // entity type
    const entityType = getEntityType(type);

    listAttributeKeys({
      offset: pageIndex,
      limit,
      entityType,
    }).finally(() => setLoading(false));
  };

  const handleOnChangeAttributeKeyType = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = e.target.value;
    if (value !== ALL_FILTER) {
      setType(value as EnumAttributeType);
    } else {
      setType(undefined);
    }
  };

  const addNewAppClient = () => {
    history.push(
      `/${EnumPaths.ROOT}/${EnumPaths.ATTRIBUTE_KEYS}/${EnumPaths.CREATION_MODE}`
    );
  };

  const handleOnChange = (changeParams: TableChangeParams) => {
    const { pageIndex, limit } = changeParams;

    setInitialParams(changeParams);

    fetchData(pageIndex, false, limit);
  };

  const handleEditAppClient = (id: string, disabled: false) => {
    if (!disabled) {
      const editLink = `/${EnumPaths.ROOT}/${EnumPaths.ATTRIBUTE_KEYS}/${id}/${EnumPaths.EDIT_MODE}`;
      setAttributeKey(null);
      history.push(editLink);
    }
  };

  const handleDelete = () => {
    setLoading(true);
    setShowDeleteAttributeKeyModal(false);
    deleteAttributeKey({ id: attributeKeyId! })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.attr.key.delete.success",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.attr.key.delete.error",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tableHead = [
    "page.attr.key.label",
    "page.attr.key.code",
    "page.attr.key.type",
    "page.attr.key.typology",
    "page.attr.key.required",
    "page.attr.key.editable",
    "general.status",
    "page.attr.key.created_at",
    "page.attr.key.updated_at",
    "general.actions",
  ];

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const tableBody = attributeKeys?.map((attributeKey: AttributeKey) => {
    const {
      id,
      code,
      label,
      type,
      typology,
      created_at,
      required,
      enabled,
      editable,
      updated_at,
    } = attributeKey;
    const editLink = `/${EnumPaths.ROOT}/${EnumPaths.ATTRIBUTE_KEYS}/${id}/${EnumPaths.EDIT_MODE}`;

    const isAttrSystem = isAttributeSystem(attributeKey);
    const disabled = isAttrSystem ? !isSystemAdmin(me) : false;

    const dropdownValues: DropdownValues[] = [
      {
        id: "edit_attribute_key",
        label: "page.list.attr.keys.edit",
        icon: images.edit,
        link: editLink,
      },
      {
        id: "delete",
        label: "general.delete",
        icon: images.deleteIcon,
        onClick: () => {
          setShowDeleteAttributeKeyModal(!showDeleteAttributeKeyModal);
          setAttributeKeyId(id);
        },
      },
    ];

    const attributeKeyElements = [
      {
        element: getTranslation(label),
        disabled,
        onCellClick: () => handleEditAppClient(id, disabled),
      },
      {
        element: code,
        disabled,
        onCellClick: () => handleEditAppClient(id, disabled),
      },
      {
        element: `${intl.formatMessage({
          id: `page.attr.key.type.${type}`,
        })}`,
        disabled,
        onCellClick: () => handleEditAppClient(id, disabled),
      },
      {
        element: `${intl.formatMessage({
          id: `page.attr.key.typology.${typology}`,
        })}`,
        disabled,
        onCellClick: () => handleEditAppClient(id, disabled),
      },
      {
        element: required ? (
          <Badge label="general.yes" bgClassName="bg-ground-green-100" />
        ) : (
          <Badge label="general.no" bgClassName="bg-red-500" />
        ),
        disabled,
        onCellClick: () => handleEditAppClient(id, disabled),
      },
      {
        element: editable ? (
          <Badge label="general.yes" bgClassName="bg-ground-green-100" />
        ) : (
          <Badge label="general.no" bgClassName="bg-red-500" />
        ),
        disabled,
        onCellClick: () => handleEditAppClient(id, disabled),
      },
      {
        element: enabled ? (
          <Badge label="general.actif" bgClassName="bg-ground-green-100" />
        ) : (
          <Badge label="general.inactif" bgClassName="bg-red-500" />
        ),
        disabled,
        onCellClick: () => handleEditAppClient(id, disabled),
      },
      {
        element: displayDayDDMMYYYY_HHMM(created_at, centerTimezone),
        disabled,
        onCellClick: () => handleEditAppClient(id, disabled),
      },
      {
        element: displayDayDDMMYYYY_HHMM(updated_at, centerTimezone),
        disabled,
        onCellClick: () => handleEditAppClient(id, disabled),
      },
      {
        element: !disabled ? (
          <Dropdown
            values={dropdownValues}
            dataCy={`attr-key-dropdown-${id}`}
          />
        ) : (
          <></>
        ),
      },
    ];

    return {
      rowElements: attributeKeyElements,
    };
  });

  return (
    <div>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: `page.list.attr.keys.document.title`,
          })}
        </title>
      </Helmet>

      <PageSubheader
        title="page.list.attr.keys.title"
        nbOfResults={total}
        buttonRightTitle="page.list.attr.keys.create.attr.key"
        buttonRightAction={addNewAppClient}
        buttonRightId="btn-create-attr-key"
      />

      <div className="flex px-8">
        <AttributeKeyType
          type={type}
          onChange={handleOnChangeAttributeKeyType}
        />
      </div>

      <Table
        initialParams={initialParams}
        head={tableHead}
        body={tableBody}
        noDataText="page.list.attr.keys.empty"
        onChange={handleOnChange}
        paginationLimit={DEFAULT_LIMIT}
        paginationTotal={total}
        loading={loading}
        setLoading={setLoading}
        className="px-8"
      />

      <ConfirmModal
        isOpen={showDeleteAttributeKeyModal}
        onRequestClose={() =>
          setShowDeleteAttributeKeyModal(!showDeleteAttributeKeyModal)
        }
        toggle={() =>
          setShowDeleteAttributeKeyModal(!showDeleteAttributeKeyModal)
        }
        handleConfirm={handleDelete}
        content={<IntlMessages id="page.list.attr.keys.delete" />}
      />
    </div>
  );
};

export default injectIntl(ListAttributeKeys);
