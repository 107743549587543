import React from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { GroundGraphqlContextStore } from "../../lib/ground-aws-graphql-core";
import {
  forceHourToCenterTimezone,
  utcToZonedTimeDateFn,
} from "../../utils/config";

export const ZonedDatePicker = (props: ReactDatePickerProps): JSX.Element => {
  const { selected, startDate, endDate, minTime, maxTime, onChange, ...rest } =
    props;

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  // Convert local time to center timezone before returning it
  const zonedOnChange = (
    date: Date | null,
    e: React.SyntheticEvent<any, Event> | undefined
  ): void => onChange(forceHourToCenterTimezone(date, centerTimezone), e);

  return (
    <DatePicker
      selected={
        selected ? utcToZonedTimeDateFn(selected, centerTimezone) : null
      }
      onChange={zonedOnChange}
      startDate={
        startDate ? utcToZonedTimeDateFn(startDate, centerTimezone) : undefined
      }
      endDate={
        endDate ? utcToZonedTimeDateFn(endDate, centerTimezone) : undefined
      }
      minTime={
        minTime ? utcToZonedTimeDateFn(minTime, centerTimezone) : undefined
      }
      maxTime={
        maxTime ? utcToZonedTimeDateFn(maxTime, centerTimezone) : undefined
      }
      {...rest}
    />
  );
};
