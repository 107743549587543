export const asyncLocalStorage = {
  setItem: (key: string, value: any): Promise<void> => {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: (key: string): Promise<string | null> => {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  },
  removeItem: (key: string): Promise<void> => {
    return Promise.resolve().then(function () {
      return localStorage.removeItem(key);
    });
  },
  clear: function (): Promise<void> {
    return Promise.resolve().then(function () {
      return localStorage.clear();
    });
  },
};
