import { AxiosResponse } from "axios";
import axiosBackend from "../../../../../axiosBackend";
import { GetOperatorAttributeValueOpts } from "../../../models/Api/Attribute";

export const getOperatorAttributeValue = async ({
  operatorId,
  attributeKeyName,
}: GetOperatorAttributeValueOpts): Promise<AxiosResponse> => {
  return axiosBackend
    .get(`/operators/${operatorId}/attributes/${attributeKeyName}`)
    .catch(error => {
      return error.response;
    });
};
