import { useContext } from "react";
import { Context } from ".";
import {
  DisplayConfiguration,
  isDisplayable,
} from "../../lib/operator-configuration/backoffice/display";

export const useOperatorDisplayConfiguration = (
  paths: [keyof DisplayConfiguration]
): boolean => {
  const { configuration, role } = useContext(Context);
  const value = paths.every(path =>
    isDisplayable(configuration || {}, path, role)
  );

  return value;
};
