import { ErrorGraphQL } from "./../../services/coreUtils";
/*
 *
 * Order Model
 *
 */

import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { GqlQuery } from "..";
import { Payment } from "../Payment";
import { ModelOrderItemConnection } from "../OrderItem";
import { BackOfficeUser } from "../BackOfficeUser";
import { Provider } from "../Provider";
import { User } from "../User";
import { Operator } from "../Operator";
import { Center } from "../Center";
import { GlobalOrder } from "../GlobalOrder";

import {
  EnumChannel,
  EnumCurrency,
  EnumOrderStatus,
  EnumBookingReason,
} from "../../api/graphql/types";
import { Invoice } from "../Invoice";

export interface Order {
  operator?: Operator;
  id: string;
  user: User;
  orderItems: ModelOrderItemConnection;
  globalOrder: GlobalOrder | null;
  price: number;
  totalTax: number;
  totalAdjustment: number;
  transactionFeePercent: number;
  currency: EnumCurrency;
  center: Center;
  provider: Provider;
  channel: EnumChannel;
  timeplaced: string;
  deliveryDate?: string;
  status: EnumOrderStatus;
  ormOrder: string;
  payment?: Payment | null;
  orderer?: BackOfficeUser | null;
  invoice?: Invoice | null;
  metadata?: string;
  reason?: EnumBookingReason;
  comment?: string;
  touchPoint?: string;
}

export interface ModelOrderConnection {
  items: Order[];
  total?: number | null;
}

// Interface declaration
export interface OrderModel {
  order: Order | null;
  setOrder: Action<OrderModel, Order>;
  getOrder: Thunk<OrderModel, GqlQuery, Injections>;
}

export const orderModel: OrderModel = {
  order: null,

  setOrder: action((state, payload) => {
    state.order = payload;
  }),

  getOrder: thunk(async (actions, payload, { injections }) => {
    const { orderService } = injections;
    const response = await orderService.getOrder(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    actions.setOrder(response.data.getOrder as any);

    return response.data.getOrder;
  }),
};
