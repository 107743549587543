import { action, Action, Thunk, thunk } from "easy-peasy";
import { Injections } from "../../../store";
import { StoreModel } from "../..";
import { IListResponse } from "../../../services/Api/AppClient";
import { IMultiLanguage } from "..";
import {
  EnumAttributeType,
  EnumAttributeTypology,
} from "../../../api/graphql/types";

export interface AttributeKey {
  id: string;
  code: string;
  typology: EnumAttributeTypology;
  type: EnumAttributeType;
  label: IMultiLanguage;
  required: boolean;
  enabled: boolean;
  editable: boolean;
  created_at: string;
  updated_at: string;
}

export interface ListAttributeKeysOpts {
  limit?: number;
  offset?: number;
  entityType?:
    | "operators"
    | "buildings"
    | "providers"
    | "services"
    | "users"
    | "products"
    | "unavailabilities";
}

export interface GetAttributeKeyOpts {
  id: string;
}

export interface DeleteAttributeKeyOpts {
  id: string;
}

export interface UpdateAttributeKeyOpts {
  id: string;
  code?: string;
  label?: IMultiLanguage;
  enabled?: boolean;
  required?: boolean;
  editable?: boolean;
}

export interface CreateAttributeKeyOpts {
  code: string;
  label: IMultiLanguage;
  type: EnumAttributeType;
  typology: EnumAttributeTypology;
  enabled: boolean;
  required: boolean;
  editable: boolean;
}

export interface AttributeKeysModel {
  attributeKeys: {
    items: AttributeKey[];
    total: number;
    has_more: boolean;
  };
  attributeKey: AttributeKey | null;
  setAttributeKeys: Action<
    AttributeKeysModel,
    { items: AttributeKey[]; total: number; has_more: boolean }
  >;
  setAttributeKey: Action<AttributeKeysModel, AttributeKey | null>;
  removeAttributeKey: Action<AttributeKeysModel, DeleteAttributeKeyOpts>;
  listAttributeKeys: Thunk<
    AttributeKeysModel,
    ListAttributeKeysOpts,
    Injections,
    StoreModel
  >;
  getAttributeKey: Thunk<
    AttributeKeysModel,
    GetAttributeKeyOpts,
    Injections,
    StoreModel
  >;
  createAttributeKey: Thunk<
    AttributeKeysModel,
    CreateAttributeKeyOpts,
    Injections,
    StoreModel
  >;
  updateAttributeKey: Thunk<
    AttributeKeysModel,
    UpdateAttributeKeyOpts,
    Injections,
    StoreModel
  >;
  deleteAttributeKey: Thunk<
    AttributeKeysModel,
    DeleteAttributeKeyOpts,
    Injections,
    StoreModel
  >;
}

export const attributeKeysModel: AttributeKeysModel = {
  attributeKeys: { items: [], total: 0, has_more: false },
  attributeKey: null,
  setAttributeKeys: action((state, payload) => {
    state.attributeKeys = payload;
  }),
  setAttributeKey: action((state, payload) => {
    state.attributeKey = payload;
  }),
  removeAttributeKey: action((state, payload) => {
    state.attributeKeys.items = state.attributeKeys.items.filter(
      p => p.id !== payload.id
    );
    state.attributeKeys.total = state.attributeKeys.total - 1;
  }),
  listAttributeKeys: thunk(
    async (actions, payload: ListAttributeKeysOpts, { injections }) => {
      const { attributeKeyRestService } = injections;
      const response = await attributeKeyRestService.listAttributeKeys(payload);
      if (response.status === 200 && response.data.success) {
        const res: IListResponse<AttributeKey> = response.data;
        actions.setAttributeKeys(res.data);
      }
    }
  ),
  createAttributeKey: thunk(
    async (actions, payload: CreateAttributeKeyOpts, { injections }) => {
      const { attributeKeyRestService } = injections;
      const response = await attributeKeyRestService.createAttributeKey(
        payload
      );
      if (response.status === 201 && response.data.success) {
        actions.setAttributeKey(response.data.data);
      }
    }
  ),
  getAttributeKey: thunk(
    async (actions, payload: GetAttributeKeyOpts, { injections }) => {
      const { attributeKeyRestService } = injections;
      const response = await attributeKeyRestService.getAttributeKey(
        payload.id
      );
      if (response.status === 200 && response.data.success) {
        actions.setAttributeKey(response.data.data);
      }
    }
  ),
  deleteAttributeKey: thunk(
    async (actions, payload: DeleteAttributeKeyOpts, { injections }) => {
      const { attributeKeyRestService } = injections;
      const response = await attributeKeyRestService.deleteAttributeKey(
        payload.id
      );
      if (response.status === 200 && response.data.success) {
        actions.setAttributeKey(null);
        actions.removeAttributeKey(payload);
      }
    }
  ),
  updateAttributeKey: thunk(
    async (
      actions,
      payload: UpdateAttributeKeyOpts,
      { injections, getState }
    ) => {
      const { attributeKeyRestService } = injections;

      const response = await attributeKeyRestService.updateAttributeKey(
        payload
      );

      if (response.status === 200 && response.data.success) {
        const updatedAttributeKey = response.data.data;
        actions.setAttributeKey(updatedAttributeKey);

        const state = getState();
        const index = state.attributeKeys.items.findIndex(
          p => p.id === updatedAttributeKey.id
        );

        if (index !== -1) {
          const newItems = [...state.attributeKeys.items];
          newItems.splice(index, 1, updatedAttributeKey);

          actions.setAttributeKeys({
            ...state.attributeKeys,
            items: newItems,
          });
        }
      }

      return response.data;
    }
  ),
};
