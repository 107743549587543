import React, { useEffect, useState } from "react";
import { IntlShape } from "react-intl";
import { toast } from "react-toastify";
import { FieldAttributes } from "formik";
import { match as Match } from "react-router-dom";
import Block from "../../../../components/Tailwind/Block";
import Header from "../../../../components/Tailwind/Block/Header";
import { EnumPermissionEntity } from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import DefaultForm, {
  AdditionalFieldAttributes,
} from "../../../../components/Form";
import overrideClasses from "../../../../utils/overrideClasses";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import { EnumPaths } from "../../../../utils/navigation";
import history from "../../../../history";
import { getWebhookEndpointEvents } from "../../../../utils/types";
import FormSelect from "../../../../components/Form/FormSelect";

interface Props {
  match?: Match<{ id: string }>;
  intl: IntlShape;
  edition: boolean;
}

const WebhookForm = (props: Props): JSX.Element => {
  const { intl, match, edition } = props;
  const [enabled, setEnabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [togglePanel, setTogglePanel] = useState(false);

  const getWebhook = GroundGraphqlContextStore.useStoreActions(
    actions => actions.webhooks.getWebhook
  );

  const createWebhook = GroundGraphqlContextStore.useStoreActions(
    actions => actions.webhooks.createWebhook
  );

  const updateWebhook = GroundGraphqlContextStore.useStoreActions(
    actions => actions.webhooks.updateWebhook
  );

  const setWebhook = GroundGraphqlContextStore.useStoreActions(
    actions => actions.webhooks.setWebhook
  );

  const webhook = GroundGraphqlContextStore.useStoreState(
    state => state.webhooks.webhook
  );

  const formFields: FieldAttributes<AdditionalFieldAttributes>[] = [
    {
      name: "url",
      label: "page.webhook.url",
      placeholder: "page.webhook.url.placeholder",
      initialValue: webhook?.url,
      required: true,
    },
    {
      name: "events",
      label: "page.webhook.events",
      placeholder: "page.webhook.events.placeholder",
      initialValue: webhook?.events,
      required: true,
      component: props => (
        <FormSelect {...props} multiple options={getWebhookEndpointEvents()} />
      ),
      validate: values => {
        if (!values?.length) return "general.required.field";

        return "";
      },
    },
  ];

  const fetchData = () => {
    setLoading(true);
    if (match?.params.id) {
      getWebhook({ id: match?.params.id }).finally(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    // Clean the event from the state when we unmount this component
    return () => {
      setWebhook(null);
    };
  }, []);

  useEffect(() => {
    if (webhook) setEnabled(webhook.enabled);
  }, [webhook]);

  const handleShowSavePanel = () => {
    setTogglePanel(true);
  };

  const handleSubmit = (values: any) => {
    setLoading(true);
    const webhookEvents = values.events
      ?.map(event => event.value)
      .filter(n => !!n);

    if (edition && webhook) {
      updateWebhook({
        ...values,
        ...(webhookEvents.length && {
          events: webhookEvents,
        }),
        id: webhook.id,
        enabled: enabled,
      })
        .then(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.WEBHOOKS}`);
          toast(
            intl.formatMessage({
              id: "page.webhook.update.webhook.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.WEBHOOKS}`);
          toast(
            intl.formatMessage({
              id: "page.webhook.update.webhook.error",
            }),
            {
              type: "error",
            }
          );
        });
    } else {
      createWebhook({
        ...values,
        events: webhookEvents,
        enabled,
      })
        .then(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.WEBHOOKS}`);
          toast(
            intl.formatMessage({ id: "page.webhook.create.webhook.success" }),
            { type: "success" }
          );
        })
        .catch(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.WEBHOOKS}`);
          toast(
            intl.formatMessage({ id: "page.webhook.create.webhook.error" }),
            { type: "error" }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return edition && !webhook ? (
    <div className="loading" />
  ) : (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={overrideClasses({ loading })} />

      {/* Hide when loading */}
      <div className={overrideClasses("px-8 pb-32", { hidden: loading })}>
        <Block>
          <Header
            item={webhook}
            checked={enabled}
            title={
              edition
                ? "page.list.webhooks.update.webhook"
                : "page.list.webhooks.create.webhook"
            }
            entity={EnumPermissionEntity.WEBHOOK}
            onChange={e => {
              setEnabled(e);

              // show save panel
              handleShowSavePanel();
            }}
            className="border-b border-gray-200"
          />

          <DefaultForm
            fields={formFields}
            onSubmit={handleSubmit}
            showPanel={togglePanel}
          />
        </Block>
      </div>
    </>
  );
};

export default WebhookForm;
