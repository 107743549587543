import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import {
  GetEventQuery,
  CreateEventMutation,
  UpdateEventMutation,
} from "../../api/graphql/types";
import {
  getEvent as getEventQuery,
  searchEvents as searchEventsQuery,
} from "../../api/graphql/queries";
import {
  createEvent as createEventMutation,
  updateEvent as updateEventMutation,
  deleteEvent as deleteEventMutation,
} from "../../api/graphql/mutations";
import { fetchPolicy } from "../../api/client";
import { SearchEventsOpts } from "./../../models/Event";

export const getEvent = payload => {
  const request = payload.gql ? payload.gql : getEventQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: { id: payload.id },
    fetchPolicy,
  }) as {
    data: GetEventQuery;
  };
};

export const createEvent = payload =>
  ApolloClient.mutate({
    mutation: gql(createEventMutation),
    variables: { input: payload },
  }) as {
    data: CreateEventMutation;
  };

export const updateEvent = payload =>
  ApolloClient.mutate({
    mutation: gql(updateEventMutation),
    variables: { input: payload },
  }) as {
    data: UpdateEventMutation;
  };

export const deleteEvent = payload =>
  ApolloClient.mutate({
    mutation: gql(deleteEventMutation),
    variables: { input: payload },
  }) as {
    data: boolean;
  };

export const searchEvents = (payload: SearchEventsOpts) => {
  const request = payload.gql ? payload.gql : searchEventsQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: {
      filter: payload.filter,
      locale: payload.locale,
      limit: payload.limit,
      from: payload.from,
      sort: payload.sort,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};
