import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Switch from "rc-switch";
import { useIntl } from "react-intl";
import dayjs from "dayjs";
import { ActionTypes } from "../../../../../utils/types";
import {
  DATE_FORMAT_HOUR,
  DATE_PICKER_TIME_INTERVALS,
  afternoon,
  getCypressTestId,
  getDayInLocal,
  morning,
  subDuration,
  transformDateInHourForQuery,
} from "../../../../../utils/config";
import IntlMessages from "../../../../../utils/messages";
import { EnumHoursRangeName } from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { ILocale, getLocale } from "../../../../../lang";
import contextStore from "../../../../../redux/store";

interface HoursRangeItem {
  id?: string | number;
  start: string;
  end: string;
}

interface RangeProps<T> {
  hasHoursRange: boolean;
  hoursRangeItem: T;
  rangeName: EnumHoursRangeName;
  onChange: (e: T, action: ActionTypes, start: string, end: string) => void;
  action?: ActionTypes;
}

enum ChangeHourType {
  START,
  END,
}

const RangeHour = <T extends HoursRangeItem>(
  props: RangeProps<T>
): JSX.Element => {
  const { hasHoursRange, hoursRangeItem, rangeName, onChange, action } = props;
  const intl = useIntl();

  const [opened, setOpened] = useState(
    hasHoursRange || action === ActionTypes.TO_ADD
  );
  const [change, setChange] = useState(0);
  const isMorning = rangeName === EnumHoursRangeName.MORNING;

  const startMorning = dayjs(morning.start, DATE_FORMAT_HOUR);
  const endMorning = dayjs(morning.end, DATE_FORMAT_HOUR);
  const startAfternoon = dayjs(afternoon.start, DATE_FORMAT_HOUR);
  const endAfternoon = dayjs(afternoon.end, DATE_FORMAT_HOUR);

  let start = isMorning ? startMorning : startAfternoon;
  if (hoursRangeItem && hoursRangeItem.start)
    start = dayjs(hoursRangeItem.start, DATE_FORMAT_HOUR);

  let end = isMorning ? endMorning : endAfternoon;
  if (hoursRangeItem && hoursRangeItem.end)
    end = dayjs(hoursRangeItem.end, DATE_FORMAT_HOUR);

  const [startDate, setStartDate] = useState(start.toDate());
  const [endDate, setEndDate] = useState(end.toDate());

  useEffect(() => {
    if (change > 0) {
      const startHour = transformDateInHourForQuery(startDate);
      const endHour = transformDateInHourForQuery(endDate);

      if (hasHoursRange) {
        if (opened) {
          onChange(hoursRangeItem, ActionTypes.TO_UPDATE, startHour, endHour);
        } else {
          onChange(hoursRangeItem, ActionTypes.TO_DELETE, startHour, endHour);
        }
      } else if (opened) {
        onChange(hoursRangeItem, ActionTypes.TO_ADD, startHour, endHour);
      } else {
        // Here, it can either be for keeping the hoursRangeItem or deleting it locally if this hoursRangeItem was just added.
        // We check which case it is in the center/edition page
        onChange(hoursRangeItem, ActionTypes.TO_KEEP, startHour, endHour);
      }
    }
  }, [change]);

  const changeHour = (date: Date | null, type: ChangeHourType) => {
    if (date) {
      if (type === ChangeHourType.START) {
        setStartDate(date);
        setChange(change + 1);
      } else if (type === ChangeHourType.END) {
        setEndDate(date);
        setChange(change + 1);
      }
    }
  };

  const toggleOpenClose = (toggle: boolean) => {
    setOpened(!toggle);
    setChange(change + 1);
  };

  const displayHoursRange = (isOpened: boolean, locale: ILocale) => {
    // if morning =>  min hours is 00:00, if afternoon => min hours is 12:00
    const { startDay, endDay } = getDayInLocal();

    const minTime = isMorning ? startDay : endMorning.toDate();

    // if morning => end hours is 14:00, if afternoon => end hours is 23:30 (24:00 - 30 minutes)
    const maxTime = isMorning
      ? startAfternoon.toDate()
      : subDuration(endDay, 0, "minute");

    return isOpened ? (
      <>
        <div>
          <DatePicker
            selected={startDate}
            onChange={date => changeHour(date, ChangeHourType.START)}
            onKeyDown={e => {
              e.preventDefault();
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={DATE_PICKER_TIME_INTERVALS}
            timeCaption={intl.formatMessage({ id: "general.hour" })}
            dateFormat={DATE_FORMAT_HOUR}
            minTime={minTime}
            maxTime={endDate}
            locale={locale.date}
            className="w-15 h-8 border border-gray-200 bg-white border-r-0"
          />
        </div>
        <div>
          <DatePicker
            selected={endDate}
            onChange={date => changeHour(date, ChangeHourType.END)}
            onKeyDown={e => {
              e.preventDefault();
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={DATE_PICKER_TIME_INTERVALS}
            timeCaption={intl.formatMessage({ id: "general.hour" })}
            dateFormat={DATE_FORMAT_HOUR}
            minTime={startDate}
            maxTime={maxTime}
            locale={locale.date}
            className="w-15 h-8 border border-gray-200 bg-white border-r-0"
          />
        </div>
      </>
    ) : (
      <IntlMessages id="general.closed" />
    );
  };
  const suffix = isMorning ? "am" : "pm";

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  return (
    <div className="inline-flex items-center">
      <Switch
        id={`switch_range_${rangeName.toLowerCase()}_${suffix}`}
        data-cy={`switch_range_${rangeName.toLowerCase()}_${suffix}`}
        data-testid={getCypressTestId(hoursRangeItem)}
        className="mr-3"
        checked={opened}
        onChange={() => toggleOpenClose(opened)}
      />
      {displayHoursRange(opened, currentAppLocale)}
    </div>
  );
};

export default RangeHour;
