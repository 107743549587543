import React from "react";
import images from "../../../../images";

interface Props {
  label: string;
  icon: any;
  content: string;
  selected: boolean;
  realtime: boolean;
  onClick: () => void;
}

const ReportingCard = (props: Props): JSX.Element => {
  const { label, icon, content, selected, onClick, realtime } = props;
  const className = `flex items-center border bg-neutral-300 hover:bg-white shadow-xl overflow-hidden rounded-10 justify-center py-5 ${
    !onClick ? "" : "cursor-pointer"
  }`;

  return (
    <div className="mt-1 sm:mt-0 sm:col-span-1" onClick={onClick}>
      <div
        className={className}
        style={{
          boxShadow: selected ? "0px 7px 10px #3063D526" : "none",
        }}
      >
        <div className="flex flex-col h-32 items-center justify-center">
          <div className="flex">
            <img alt="" src={icon} />
            {realtime && <img alt="" src={images.realtime} />}
          </div>
          <span className="text-ground-gray-100 text-xl mt-2">{label}</span>
          <span className="text-center text-sm mx-5">{content}</span>
        </div>
      </div>
    </div>
  );
};

export default ReportingCard;
