import { Thunk, thunk } from "easy-peasy";
import { StoreModel } from "../";
import { AttributeItem } from "../../../utils/attribute";
import { PriceUnitItemVariation } from "../../../utils/price-unit";
import { ActionTypes, Image } from "../../../utils/types";
import { Injections } from "../../store";
import { AttributeKey } from "../../../lib/ground-aws-graphql-core/models/AttributeKey";
import { Operator } from "../../../lib/ground-aws-graphql-core/models/Operator";
import { ModelDaySlotConnection } from "../../../lib/ground-aws-graphql-core/models/DaySlot";
import { ModelClosedDayConnection } from "../../../lib/ground-aws-graphql-core/models/ClosedDay";
import { ModelAttributeConnection } from "../../../lib/ground-aws-graphql-core/models/Attribute";
import { Product } from "../../../lib/ground-aws-graphql-core/models/Product";
import { Service } from "../../../lib/ground-aws-graphql-core/models/Service";
import {
  ClosedDayItem,
  DaySlotItem,
  HoursRangeItem,
  ExceptionalOpeningHourItem,
} from "../../../utils/dayslot";

export interface ProductOpts {
  product: Product | null;
  service: Service | null;
  values: any;
  pictures?: Image[];
  priceUnitsVariation: PriceUnitItemVariation | null;
  attributeKeys: AttributeKey[] | null;
  attributes: AttributeItem[] | null;
  options: { id: string; option: Product; action: ActionTypes }[];
  singleProducts: { id: string; singleProduct: Product; action: ActionTypes }[];
  center: {
    id: string;
    attributes: ModelAttributeConnection | null | undefined;
    daySlots: ModelDaySlotConnection | null | undefined;
    closedDays: ModelClosedDayConnection | null | undefined;
    operator: Operator | null | undefined;
  };
  enabled: boolean;
  callbacks: {
    createOption: any;
    deleteOption: any;
    createSingleProduct: any;
    deleteSingleProduct: any;
    updateProduct: any;
    createProduct: any;
  };
  daySlots?: DaySlotItem[];
  closedDays?: ClosedDayItem[];
  hoursRanges?: HoursRangeItem[];
  exceptionalOpeningHours?: ExceptionalOpeningHourItem[];
}

// Interface declaration
export interface ProductModel {
  createProduct: Thunk<ProductModel, ProductOpts, Injections, StoreModel>;
  updateProduct: Thunk<ProductModel, ProductOpts, Injections, StoreModel>;
}

export const productModel: ProductModel = {
  createProduct: thunk(async (_actions, payload, { injections }) => {
    const { productService } = injections;
    const response = await productService.createProduct(payload);

    return response;
  }),
  updateProduct: thunk(async (_actions, payload, { injections }) => {
    const { productService } = injections;
    const response = await productService.updateProduct(payload);

    return response;
  }),
};
