import React from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import "./style.css";
import contextStore from "../../redux/store";
import { getLocale } from "../../lang";
import { EnumUnit } from "../../lib/ground-aws-graphql-core/api/graphql/types";
import {
  DATE_PICKER_FORMAT_CALENDAR,
  getTodayInCenterTimezone,
} from "../../utils/config";
import { GroundGraphqlContextStore } from "../../lib/ground-aws-graphql-core";

interface DateRangeProps {
  startDate: Date;
  endDate: Date;
  className?: string;
  handleChange: (e) => void;
  unit: EnumUnit;
  disabledDates?: Date[];
}

const DateRangeComponent = (props: DateRangeProps): JSX.Element => {
  const { startDate, endDate, className, handleChange, unit, disabledDates } =
    props;

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  const selectionRange = { startDate, endDate, key: "selection" };

  return (
    <DateRange
      minDate={getTodayInCenterTimezone(centerTimezone)}
      ranges={[selectionRange]}
      onChange={handleChange}
      className={className}
      color="#3063d5"
      rangeColors={["#3063d5", "#3ecf8e", "#fed14c"]}
      months={3}
      direction="horizontal"
      locale={currentAppLocale.date}
      monthDisplayFormat={DATE_PICKER_FORMAT_CALENDAR}
      showMonthAndYearPickers={false}
      showDateDisplay={false}
      showPreview={unit !== EnumUnit.HOUR}
      disabledDates={disabledDates}
      fixedHeight
    />
  );
};

export default DateRangeComponent;
