import React, { useEffect, useState } from "react";
import { Location } from "history";
import { match as Match } from "react-router-dom";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import { toast } from "react-toastify";
import { withTwoColLayout } from "../../../../utils";
import IntlMessages from "../../../../utils/messages";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import PageSubheader from "../../../../components/PageSubheader";
import Block from "../../../../components/Tailwind/Block";
import ListVisitorsTable from "./visitors-list";
import { getVisitStatusBadgeColor } from "../../../../utils/visits";
import {
  DATE_PICKER_FORMAT_DAY_HOUR,
  displayDayDDMMYYYY_HHMM,
  getTodayInCenterTimezone,
} from "../../../../utils/config";
import InputDatePicker from "../../../../components/InputDatePicker";
import { ZonedDatePicker } from "../../../../components/ZonedDatePicker";
import {
  EnumVisitStatus,
  Visit as IVisit,
} from "../../../../lib/ground-aws-graphql-core/models/Api/Visits";
import AddVisitors from "./add-visitors";
import ConfirmModal from "../../../../utils/modal/confirm";
import { IVisitor } from "../../../../components/Tailwind/Visitors/VisitorItem";

interface Props {
  match: Match<{ cid: string; id: string }>;
  intl: IntlShape;
  backURL: string;
  location: Location;
}

const Visit = (props: Props) => {
  const [loading, setLoading] = useState(false);

  const { intl, match, backURL, location } = props;
  const [visitDate, setVisitDate] = useState<Date | null>(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const getVisit = GroundGraphqlContextStore.useStoreActions(
    actions => actions.visits.getVisit
  );

  const setVisit = GroundGraphqlContextStore.useStoreActions(
    actions => actions.visits.setVisit
  );

  const visit = GroundGraphqlContextStore.useStoreState(
    state => state.visits.visit
  );

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const updateVisit = GroundGraphqlContextStore.useStoreActions(
    actions => actions.visits.updateVisit
  );

  const addVisitors = GroundGraphqlContextStore.useStoreActions(
    actions => actions.visits.addVisitors
  );

  const checkInVisitor = GroundGraphqlContextStore.useStoreActions(
    actions => actions.visits.checkInVisitor
  );

  const checkOutVisitor = GroundGraphqlContextStore.useStoreActions(
    actions => actions.visits.checkOutVisitor
  );

  const cancelVisitorVisit = GroundGraphqlContextStore.useStoreActions(
    actions => actions.visits.cancelVisitorVisit
  );

  const removeVisitors = GroundGraphqlContextStore.useStoreActions(
    actions => actions.visits.removeVisitors
  );

  useEffect(() => {
    setLoading(true);

    Promise.all([getVisit({ id: match.params.id })]).finally(() =>
      setLoading(false)
    );

    return () => {
      setVisit(null);
    };
  }, []);

  useEffect(() => {
    if (visit?.date) {
      setVisitDate(new Date(visit?.date));
    }
  }, [visit]);

  const handleUpdateVisit = (date: Date) => {
    setShowUpdateModal(false);
    setLoading(true);
    updateVisit({
      id: match.params.id,
      date: date?.toISOString(),
    })
      .then(() => {
        toast(intl.formatMessage({ id: "page.visit.update.visit.success" }), {
          type: "success",
        });
      })
      .catch(() => {
        toast(intl.formatMessage({ id: "page.visit.update.visit.error" }), {
          type: "error",
        });
      })
      .finally(() => setLoading(false));
  };

  const handleCancelVisitorVisit = (visit: IVisit, id: number) => {
    setLoading(true);
    cancelVisitorVisit({ visitId: visit.id, visitorId: id })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.visit.cancel.visitor.success",
          }),
          {
            type: "success",
          }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.visit.cancel.visitor.error",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => setLoading(false));
  };

  const handleCheckInVisitor = (visit: IVisit, id: number) => {
    setLoading(true);
    checkInVisitor({ visitId: visit.id, visitorId: id })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.visit.check.in.visitor.success",
          }),
          {
            type: "success",
          }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.visit.check.in.visitor.error",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => setLoading(false));
  };

  const handleCheckOutVisitor = (visit: IVisit, id: number) => {
    setLoading(true);
    checkOutVisitor({ visitId: visit.id, visitorId: id })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.visit.check.out.visitor.success",
          }),
          {
            type: "success",
          }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.visit.check.out.visitor.error",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => setLoading(false));
  };

  const handleRemoveVisitor = (visit: IVisit, id: number) => {
    setLoading(true);
    removeVisitors({ id: visit.id, visitors: [id] })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.visit.remove.visitor.success",
          }),
          {
            type: "success",
          }
        );
      })
      .catch(() => {
        toast(intl.formatMessage({ id: "page.visit.remove.visitor.error" }), {
          type: "error",
        });
      })
      .finally(() => setLoading(false));
  };

  const handleAddVisitors = (visitors: IVisitor[]) => {
    setLoading(true);

    const visitorsToAdd = visitors.map(v => ({
      first_name: v.firstname,
      last_name: v.lastname,
      email: v.email,
      company_name: v.company,
    }));

    addVisitors({
      id: match.params.id,
      visitors: visitorsToAdd,
    })
      .then(() => {
        toast(intl.formatMessage({ id: "page.visit.add.visitors.success" }), {
          type: "success",
        });
      })
      .catch(() => {
        toast(intl.formatMessage({ id: "page.visit.add.visitors.error" }), {
          type: "error",
        });
      })
      .finally(() => setLoading(false));
  };

  const currentDate = getTodayInCenterTimezone(centerTimezone);

  return loading || !visit || !visitDate ? (
    <div className="loading" />
  ) : (
    <>
      <>
        <Helmet>
          <title>
            {intl.formatMessage({
              id: "page.detail.visits.document.title",
            })}
          </title>
        </Helmet>

        <PageSubheader
          goBackEnabled
          goBackId="btn-back-visit"
          goBackURL={backURL}
          location={location}
          title="page.detail.visits.title"
        />

        <div className="px-8 pb-32">
          <Block>
            <div className="py-3 sm:py-5 border-b border-gray-200 px-2 sm:px-6 flex flex-col">
              <div className="flex flex-col sm:flex-row justify-between sm:items-center">
                <div className="mt-1">
                  <div className="text-17px text-neutral-900 leading-5">
                    <IntlMessages
                      id="page.detail.visit.number"
                      values={{ ref: visit?.ref }}
                    />
                  </div>
                  {visit?.created_at && (
                    <div className="text-14px text-ground-gray-100 leading-relaxed">
                      {displayDayDDMMYYYY_HHMM(
                        visit.created_at,
                        centerTimezone
                      )}
                    </div>
                  )}
                </div>

                {visit && visit.status && (
                  <div
                    className={`rounded-full px-5 py-1 text-white ${getVisitStatusBadgeColor(
                      visit.status
                    )}`}
                  >
                    {intl.formatMessage({
                      id: `page.list.visits.status.${visit.status}`,
                    })}
                  </div>
                )}
              </div>
            </div>

            <div className="py-3 bg-gray-100 px-2 sm:px-6 flex flex-col">
              <div className="flex flex-col sm:flex-row justify-between sm:items-start">
                <div className="flex flex-col text-14px leading-relaxed">
                  <p className="text-neutral-900 font-semibold">
                    <IntlMessages id="general.host" />
                  </p>
                  <p className="text-ground-gray-100">
                    {visit?.host?.first_name} {visit?.host?.last_name}
                  </p>
                  <p className="text-ground-blue-100">{visit?.host?.email}</p>
                </div>
              </div>
            </div>

            <div className="py-3 bg-gray-100 px-2 sm:px-6 flex flex-col">
              <div className="flex flex-col sm:flex-row justify-between sm:items-start lg:w-1/3 sm:w-full">
                <div className="flex text-14px leading-relaxed items-center">
                  <p className="text-neutral-900 font-semibold pr-2">
                    <IntlMessages id="general.date" />
                  </p>
                  <ZonedDatePicker
                    minDate={currentDate}
                    selected={visitDate}
                    onChange={selectedDate => {
                      setVisitDate(selectedDate);
                      setShowUpdateModal(true);
                    }}
                    dateFormat={DATE_PICKER_FORMAT_DAY_HOUR}
                    timeCaption={intl.formatMessage({ id: "general.hour" })}
                    showTimeSelect
                    disabled={visit.status !== EnumVisitStatus.PREREGISTERED}
                    timeIntervals={5}
                    className="bg-red-500"
                    customInput={<InputDatePicker dataCy="visitDate" />}
                  />
                </div>
              </div>
            </div>
          </Block>

          <ListVisitorsTable
            {...props}
            visit={visit}
            onRemoveVisitor={handleRemoveVisitor}
            onCheckInVisitor={handleCheckInVisitor}
            onCheckOutVisitor={handleCheckOutVisitor}
            onCancelVisitorVisit={handleCancelVisitorVisit}
          />

          {visit.status === EnumVisitStatus.PREREGISTERED && (
            <AddVisitors {...props} onAddVisitors={handleAddVisitors} />
          )}

          <ConfirmModal
            isOpen={showUpdateModal}
            onRequestClose={() => setShowUpdateModal(!showUpdateModal)}
            toggle={() => setShowUpdateModal(!showUpdateModal)}
            handleConfirm={() => handleUpdateVisit(visitDate)}
            content={<IntlMessages id="page.visit.update.visit.date" />}
          />
        </div>
      </>
    </>
  );
};

export default withTwoColLayout(injectIntl(Visit));
