import React from "react";
import Sidebar from "../../Sidebar";
import Topbar from "../../Topbar";

interface Props {
  children: React.ReactNode;
  subheader?: JSX.Element;
}

const Layout = ({ children, subheader }: Props): JSX.Element => {
  return (
    <main className="twoColumn h-screen flex overflow-hidden">
      <Sidebar />

      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div
          className="m-0 flex-1 relative z-0 overflow-y-auto focus:outline-none bg-white"
          tabIndex={0}
        >
          <Topbar />

          {subheader}

          <div className="dashboard-wrapper">{children}</div>
        </div>
      </div>
    </main>
  );
};

export default Layout;
