import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import {
  GetProductQuery,
  CreateProductMutation,
  UpdateProductMutation,
} from "../../api/graphql/types";

import {
  getProduct as getProductQuery,
  getProductOnly as getProductOnlyQuery,
  searchSpaces as searchSpacesQuery,
  searchWorkSpaces as searchWorkSpacesQuery,
  searchSpacesBookings as searchSpacesBookingsQuery,
  searchSpaceDateBookings as searchSpaceDateBookingsQuery,
  searchProducts as searchProductsQuery,
  searchSpaceTimelines as searchSpaceTimelinesQuery,
  getSpaceTimeline as getSpaceTimelineQuery,
} from "../../api/graphql/queries";

import {
  createProduct as createProductMutation,
  updateProduct as updateProductMutation,
  deleteProduct as deleteProductMutation,
} from "../../api/graphql/mutations";

import { fetchPolicy } from "../../api/client";
import {
  ProductOption,
  SearchProductOptionsOpts,
  SearchProductsOpts,
} from "../../models/Product/index";
import {
  SearchSpaceDateBookingsOpts,
  SearchSpacesBookingsOpts,
  SearchSpacesOpts,
  SearchSpaceTimelinesOpts,
  SearchWorkSpacesOpts,
} from "../../models/Product";
import axiosBackend from "../../../../axiosBackend";
import { AxiosResponse } from "axios";

const DEFAULT_LIMIT = 100;

export const getProduct = payload => {
  const request = payload.gql ? payload.gql : getProductQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: { id: payload.id },
  }) as {
    data: GetProductQuery;
  };
};
export const getProductOnly = payload => {
  const request = payload.gql ? payload.gql : getProductOnlyQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: { id: payload.id },
  }) as {
    data: GetProductQuery;
  };
};

export const createProduct = (payload, gqlReq) => {
  const request = gqlReq || createProductMutation;

  return ApolloClient.mutate({
    mutation: gql(request),
    variables: { input: payload },
  }) as {
    data: CreateProductMutation;
  };
};

export const updateProduct = (payload, gqlReq) => {
  const request = gqlReq || updateProductMutation;

  return ApolloClient.mutate({
    mutation: gql(request),
    variables: { input: payload },
  }) as {
    data: UpdateProductMutation;
  };
};

export const deleteProduct = (input, gqlReq) => {
  const request = gqlReq || deleteProductMutation;

  return ApolloClient.mutate({
    mutation: gql(request),
    variables: { input },
  });
};

export const searchProducts = (payload: SearchProductsOpts) => {
  return ApolloClient.query({
    query: gql(searchProductsQuery),
    variables: {
      buildingId: payload.buildingId,
      filter: payload.filter,
      limit: payload?.limit || DEFAULT_LIMIT,
      from: payload?.from,
    },
  }) as {
    data: any;
  };
};

export const searchWorkSpaces = (payload: SearchWorkSpacesOpts) => {
  return ApolloClient.query({
    query: gql(searchWorkSpacesQuery),
    variables: {
      filter: payload.filter,
      limit: payload?.limit || DEFAULT_LIMIT,
      from: payload?.from,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};

export const searchSpaces = (payload: SearchSpacesOpts) => {
  return ApolloClient.query({
    query: gql(searchSpacesQuery),
    variables: {
      filter: payload.filter,
      limit: payload?.limit || DEFAULT_LIMIT,
      from: payload?.from,
    },
  }) as {
    data: any;
  };
};

export const searchSpacesBookings = (payload: SearchSpacesBookingsOpts) => {
  return ApolloClient.query({
    query: gql(searchSpacesBookingsQuery),
    variables: {
      filter: payload.filter,
      limit: payload.limit ? payload.limit : DEFAULT_LIMIT,
      from: payload.from ? payload.from : null,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};

export const searchSpaceDateBookings = (
  payload: SearchSpaceDateBookingsOpts
) => {
  return ApolloClient.query({
    query: gql(searchSpaceDateBookingsQuery),
    variables: {
      productId: payload.productId,
      date: payload.date,
      sort: payload.sort,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};

export const searchSpaceTimelines = (payload: SearchSpaceTimelinesOpts) => {
  return ApolloClient.query({
    query: gql(searchSpaceTimelinesQuery),
    variables: {
      buildingId: payload.buildingId,
      date: payload.date,
      filter: payload.filter,
      from: payload.from ? payload.from : null,
      limit: payload.limit ? payload.limit : DEFAULT_LIMIT,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};

export const getSpaceTimeline = payload => {
  return ApolloClient.query({
    query: gql(getSpaceTimelineQuery),
    variables: { id: payload.id, startDate: payload.date },
  }) as {
    data: any;
  };
};

export const searchProductOptions = async ({
  buildingId,
  productId,
  priceUnitVariationId,
}: SearchProductOptionsOpts): Promise<
  AxiosResponse<{ success: boolean; data: ProductOption[] }>
> => {
  const response = await axiosBackend
    .get(`/buildings/${buildingId}/products/${productId}/options`, {
      params: { "price_variation_id[eq]": priceUnitVariationId },
    })
    .catch(error => {
      return error.response;
    });

  return response;
};
