import { Action, Thunk, action, thunk } from "easy-peasy";
import { ErrorGraphQL } from "../../services/coreUtils";
import { Injections } from "../../store";
import { StoreModel } from "..";
import { Operator } from "../Operator";

import {
  CreateAuthorizationInput as CreateAuthorization,
  SearchableAuthorizationFilterInput,
  EnumPermissionEntity,
} from "../../api/graphql/types";
import { BackOfficeUser } from "../BackOfficeUser";
import { Center } from "../Center";
import { ActionTypes } from "../../../../utils/types";

export interface Authorization {
  id: string;
  operator: Operator;
  entityType: EnumPermissionEntity;
  entityId: string;
  user: BackOfficeUser;
  center?: Center;
}

export interface AuthorizationItem {
  authorization: Authorization;
  action: ActionTypes;
}

export interface ModelAuthorizationConnection {
  items: Authorization[];
  total: number;
}

export interface SearchAuthorizationsOpts {
  filter?: SearchableAuthorizationFilterInput;
  limit?: number | null;
  from?: number | null;
  gql?: string | null;
}

export interface AuthorizationModel {
  authorizations: ModelAuthorizationConnection;
  buildingAuthorizations: ModelAuthorizationConnection;
  authorization: Authorization | null;
  setAuthorizations: Action<
    AuthorizationModel,
    { data: ModelAuthorizationConnection }
  >;
  setBuildingAuthorizations: Action<
    AuthorizationModel,
    { data: ModelAuthorizationConnection }
  >;
  setAuthorization: Action<AuthorizationModel, Authorization | null>;
  addAuthorization: Action<AuthorizationModel, Authorization>;
  createAuthorization: Thunk<
    AuthorizationModel,
    CreateAuthorization,
    Injections
  >;
  searchAuthorizations: Thunk<
    AuthorizationModel,
    SearchAuthorizationsOpts,
    Injections,
    StoreModel
  >;
  searchAllAuthorizations: Thunk<
    AuthorizationModel,
    SearchAuthorizationsOpts,
    Injections,
    StoreModel
  >;
  searchBuildingAuthorizations: Thunk<
    AuthorizationModel,
    SearchAuthorizationsOpts,
    Injections,
    StoreModel
  >;
}

export const authorizationModel: AuthorizationModel = {
  authorizations: { items: [], total: 0 },
  buildingAuthorizations: { items: [], total: 0 },
  authorization: null,
  setAuthorizations: action(
    (state, payload: { data: ModelAuthorizationConnection }) => {
      state.authorizations = payload.data;
    }
  ),
  setBuildingAuthorizations: action(
    (state, payload: { data: ModelAuthorizationConnection }) => {
      state.buildingAuthorizations = payload.data;
    }
  ),

  setAuthorization: action((state, payload) => {
    state.authorization = payload;
  }),

  addAuthorization: action((state, payload) => {
    if (state.authorizations.items) {
      state.authorizations.items.push(payload);
    } else {
      state.authorizations.items = [payload];
    }
  }),

  createAuthorization: thunk(async (actions, payload, { injections }) => {
    const { authorizationService } = injections;
    const response = await authorizationService.createAuthorization(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const items = response.data.createAuthorization;
    items.map(actions.setAuthorization);
    items.map(actions.addAuthorization);

    return items;
  }),

  searchAuthorizations: thunk(async (actions, payload, { injections }) => {
    const { authorizationService } = injections;
    const response = await authorizationService.searchAuthorizations(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    if (response.data.searchAuthorizations) {
      const data = response.data.searchAuthorizations;
      actions.setAuthorizations({ data });

      return data;
    }

    return null;
  }),

  searchAllAuthorizations: thunk(async (actions, payload, { injections }) => {
    const { authorizationService } = injections;
    const response = await authorizationService.searchAllAuthorizations(
      payload
    );

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    if (response.data.searchAllAuthorizations) {
      const data = response.data.searchAllAuthorizations;
      actions.setAuthorizations({ data });

      return data;
    }

    return null;
  }),

  searchBuildingAuthorizations: thunk(
    async (actions, payload, { injections }) => {
      const { authorizationService } = injections;
      const response = await authorizationService.searchAllAuthorizations(
        payload
      );

      const errors = Object.prototype.hasOwnProperty.call(response, "errors");
      if (errors) return ErrorGraphQL(response);

      if (response.data.searchAllAuthorizations) {
        const data = response.data.searchAllAuthorizations;
        actions.setBuildingAuthorizations({ data });

        return data;
      }

      return null;
    }
  ),
};
