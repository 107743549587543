import React, { memo, Fragment } from "react";
import classnames from "classnames";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import IntlMessages from "../../../utils/messages";

export interface DropdownValues {
  id: string;
  link?: string | undefined;
  label: string;
  icon: any;
  onClick?: (e: any) => void;
}

interface Props {
  values: DropdownValues[] | null;
  width?: string;
  dataCy?: string;
}

const Dropdown = ({ values, width, dataCy }: Props) => {
  if (!values?.length) return null;

  const MenuItems = values?.map(({ id, link, label, icon, onClick }) => {
    const containerClassName = classnames(
      `flex items-center px-4 py-1 text-sm hover:bg-neutral-300 text-neutral-900 leading-5 ${
        width || "w-56"
      }`
    );

    const content = (
      <>
        <img src={icon} alt={id} className="mr-2 w-4 h-4" />
        <IntlMessages id={label} />
      </>
    );

    return (
      <Menu.Item key={id}>
        {() => (
          <>
            {link && (
              <Link to={link} className={containerClassName}>
                {content}
              </Link>
            )}
            {onClick && (
              <button
                type="button"
                onClick={onClick}
                className={containerClassName}
              >
                {content}
              </button>
            )}
          </>
        )}
      </Menu.Item>
    );
  });

  return (
    <Menu as="div" className="relative inline-block text-left align-middle">
      {({ open }) => (
        <>
          <div data-cy={dataCy}>
            <Menu.Button className="bg-transparent rounded-full flex items-center text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 hover:bg-gray-300 p-2">
              <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="z-10 origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="py-2">{MenuItems}</div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default memo(Dropdown);
