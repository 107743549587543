import classnames from "classnames";
import React, { useState } from "react";
import { match as Match } from "react-router-dom";
import { getTranslation } from "../../../../../utils/translation";
import { getImageUrl } from "../../../../../utils/picture";
import IntlMessages from "../../../../../utils/messages";
import { FormattedPlural } from "react-intl";
import ConfirmModal from "../../../../../utils/modal/confirm";
import { getCypressTestId } from "../../../../../utils/config";
import { canDeleteService } from "../../../../../utils/types";
import { EnumServiceType } from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import { Service } from "../../../../../lib/ground-aws-graphql-core/models/Service";
import Dropdown from "../../../../../components/Tailwind/Dropdown";
import { GroundAuthContextStore } from "../../../../../lib/ground-aws-cognito-auth-core";
import images from "../../../../../images";

type ServiceProps = {
  match: Match<{ cid: string }>;
  service: Service;
  serviceType: EnumServiceType;
  onEditService: (e, service: Service) => void;
  onClickService: (e, service: Service) => void;
  fetchData: () => void;
};

const ServiceComponent = ({
  match,
  service,
  serviceType,
  onEditService,
  onClickService,
  fetchData,
}: ServiceProps): JSX.Element => {
  const picture =
    service && service.pictures && service.pictures.length > 0
      ? service.pictures[0]
      : null;
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteService = GroundGraphqlContextStore.useStoreActions(
    actions => actions.service.deleteService
  );

  const [loading, setLoading] = useState(false);

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const handleDelete = id => {
    setLoading(true);
    deleteService({
      id,
      centerServicesId: match.params.cid,
      markForDelete: true,
    })
      .then(() => {
        fetchData();
      })
      .finally(() => setLoading(false));
  };

  // keep only products with provider not markForDelete
  const products = service.products?.items?.filter(
    p => !p.markForDelete && !p.provider?.markForDelete
  );

  const numberOfProduct: number =
    products && products.length > 0 ? products.length : 0;

  const classNameEnabled = enabled =>
    classnames(
      "absolute bottom-0 right-0 block h-2 w-2 rounded-full text-white shadow-solid",
      {
        "bg-red-400": !enabled,
        "bg-green-400": enabled,
      }
    );

  const values = [
    {
      id: "edit_service",
      label: "general.edit",
      icon: images.edit,
      onClick: e => onEditService(e, service),
    },
  ];

  if (canDeleteService(me)) {
    values.push({
      id: "delete_service",
      label: "general.delete",
      icon: images.deleteIcon,
      onClick: () => setShowDeleteModal(!showDeleteModal),
    });
  }

  return loading ? (
    <div className="loading" />
  ) : (
    <div className="bg-gray-100 py-4 px-8 flex flex-row items-center">
      <div onClick={e => onClickService(e, service)}>
        <span className="inline-block relative">
          <img
            src={getImageUrl(null, picture, 50, 50)}
            alt={getTranslation(service.name)}
          />
          <span className={classNameEnabled(service.enabled)} />
        </span>
      </div>
      <div
        data-cy={`service-name-${service.id}`}
        data-testid={getCypressTestId(service)}
        className="text-gray-900 text-14px flex-1 pl-4"
        onClick={e => onClickService(e, service)}
      >
        {getTranslation(service.name)}
      </div>
      <div
        className="text-12px text-ground-blue-100"
        data-cy={`service-total-products-${service.id}`}
        onClick={e => onClickService(e, service)}
      >
        {numberOfProduct}{" "}
        <FormattedPlural
          value={numberOfProduct}
          one={<IntlMessages id={`page.list.services.${serviceType}.one`} />}
          other={<IntlMessages id={`page.list.services.${serviceType}.many`} />}
        />
      </div>
      <div className="pl-4">
        <Dropdown values={values} dataCy={`service-dropdown-${service.id}`} />
        <ConfirmModal
          item={service}
          isOpen={showDeleteModal}
          toggle={() => setShowDeleteModal(!showDeleteModal)}
          onRequestClose={() => setShowDeleteModal(!showDeleteModal)}
          handleConfirm={() => handleDelete(service.id)}
          content={<IntlMessages id="page.service.delete.service" />}
        />
      </div>
    </div>
  );
};

export default ServiceComponent;
