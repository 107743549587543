import React from "react";
import IntlMessages from "../../../../utils/messages";
import { GlobalOrder } from "../../../../lib/ground-aws-graphql-core/models/GlobalOrder";
import { displayDayDDMMYYYY_HHMM } from "../../../../utils/config";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";

interface Props {
  globalOrder: GlobalOrder;
}

const HeaderGlobalOrder = (props: Props): JSX.Element => {
  const { globalOrder } = props;

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  return (
    <div className="py-3 sm:py-5 border-b border-gray-200 px-2 sm:px-6 flex flex-col ">
      <div className="flex flex-col sm:flex-row justify-between sm:items-center">
        <div className="mt-1">
          <div className="text-17px text-neutral-900 leading-5">
            <IntlMessages
              id="page.order.number"
              values={{ orderNumber: globalOrder.ormOrder }}
            />
          </div>
          <div className="text-14px text-ground-gray-100 leading-relaxed">
            {displayDayDDMMYYYY_HHMM(globalOrder.timeplaced, centerTimezone)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderGlobalOrder;
