import React from "react";
import classnames from "classnames";
import { getImageUrl } from "../../../utils/picture";
import { getTranslation } from "../../../utils/translation";
import IntlMessages from "../../../utils/messages";
import { Product } from "../../../lib/ground-aws-graphql-core/models/Product";

interface Props {
  product: Product;
  onRemoveSingleProduct: (o: Product) => void;
}

const SingleProduct = (props: Props): JSX.Element => {
  const { product, onRemoveSingleProduct } = props;
  const picture = product && product.pictures ? product.pictures[0] : null;

  const className = classnames(
    "mt-2 sm:mt-0 sm:col-span-1 rounded-10 border h-full opacity-1 bg-white border-ground-gray-200"
  );

  const classNameEnabled = () => {
    const { provider } = product;

    return classnames(
      "absolute bottom-0 right-0 block h-2 w-2 rounded-full text-white shadow-solid",
      {
        "bg-red-400": !product.enabled && provider.enabled,
        "bg-green-400": product.enabled && provider.enabled,
        "bg-black-400": !product.enabled && !provider.enabled,
        "bg-purple-400": product.enabled && !provider.enabled,
      }
    );
  };

  return (
    <div className={className} key={product.id}>
      <div className="relative flex px-2 py-2">
        <span className="inline-block relative">
          <img
            src={getImageUrl(null, picture, 50, 50)}
            alt={getTranslation(product.name)}
          />
          <span className={classNameEnabled()} />
        </span>
        <div className="flex flex-col ml-2 justify-around">
          <span className="text-14px text-ground-gray-100">
            {getTranslation(product.name)}
          </span>
          <span
            className="text-10px text-ground-gray-400 cursor-pointer"
            onClick={() => onRemoveSingleProduct(product)}
          >
            <IntlMessages id="general.delete" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
