import React from "react";
import classnames from "classnames";
import Button from "../../../../components/Tailwind/Button";
import IntlMessages from "../../../../utils/messages";

interface Props {
  index: number;
  onAddPriceUnitVariation: (e: any) => void;
  alternate?: boolean;
}

const AddPriceUnitVariation = (props: Props): JSX.Element => {
  const newProps = {
    ...props,
    alternate: props.alternate != undefined ? props.alternate : true,
  };

  const { alternate, index, onAddPriceUnitVariation } = newProps;

  const className = (id: number, value: string) =>
    classnames(value, {
      "bg-ground-white-200": alternate && id % 2 === 0,
      "rounded-b-10": true,
    });

  const containerClassName = classnames("mt-1 sm:mt-0 sm:col-span-1");

  return (
    <div
      key="price_unit_variation"
      className={className(
        index,
        "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:py-2 px-8"
      )}
    >
      <div className={containerClassName}>
        <label className="block text-14px font-medium leading-5 text-ground-gray-100 sm:mt-px sm:pt-2">
          <IntlMessages id="page.product.price.unit" />
        </label>
      </div>
      <div className="mt-1 sm:mt-0 sm:col-span-2 sm:col-start-2">
        <Button
          id="btn-add-price-unit-variation"
          name="btn-add-price-unit-variation"
          type="button"
          item={null}
          outline
          onClick={onAddPriceUnitVariation}
        >
          <span className="text-center">
            <IntlMessages id="page.product.price.unit.add.variation" />
          </span>
        </Button>
      </div>
    </div>
  );
};

export default AddPriceUnitVariation;
