import { Action, action } from "easy-peasy";

// Interface declaration
export interface AuthenticationModel {
  token: string | null;
  refreshToken: string | null;
  setTokens: Action<
    AuthenticationModel,
    { token: string; refreshToken: string }
  >;
}

const initialState = {
  token: localStorage.getItem("token"),
  refreshToken: localStorage.getItem("refreshToken"),
};

export const authenticationModel: AuthenticationModel = {
  ...initialState,
  setTokens: action(
    (state, payload: { token: string; refreshToken: string }) => {
      state.token = payload.token;
      state.refreshToken = payload.refreshToken;
      localStorage.setItem("token", payload.token);
      localStorage.setItem("refreshToken", payload.refreshToken);
    }
  ),
};
