import React from "react";
import StarRatings from "react-star-ratings";
import IntlMessages from "../../../../utils/messages";

interface Props {
  ratingValue: number;
}

const CardRating = (props: Props): JSX.Element => {
  const { ratingValue } = props;

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-30px">
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 className="text-16px leading-6 font-medium text-gray-900">
          <IntlMessages id="page.center.rating.title" />
        </h3>
        <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
          <IntlMessages id="page.center.rating.subtitle" />
        </p>
      </div>
      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3>
          <span className="font-bold">{ratingValue}/5</span>
        </h3>
        <StarRatings
          rating={ratingValue}
          numberOfStars={5}
          starDimension={"20px"}
          starRatedColor={"#87d068"}
          starEmptyColor={"grey"}
        />
      </div>
    </div>
  );
};

export default CardRating;
