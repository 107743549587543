import { ErrorGraphQL } from "../../services/coreUtils";
import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel } from "..";
import { Operator } from "../Operator";
import {
  EnumAttributeType,
  SearchableAttributeKeyFilterInput,
  EnumAttributeTypology,
  UpdateAttributeKeyInput as UpdateAttributeKey,
  EnumBackOfficeUserRole,
} from "../../api/graphql/types";
import { Category } from "../Category";

export interface AttributeKey {
  id: string;
  attributeKeyCategoryId: string;
  category: Category;
  operator?: Operator;
  type: EnumAttributeType;
  system: boolean;
  required?: boolean;
  typology: EnumAttributeTypology;
  name: string;
  description?: string | null;
  enabled: boolean;
  markForDelete: boolean;
  metadata: string | null;
  roles?: [EnumBackOfficeUserRole];
}

export interface ModelAttributeKeyConnection {
  items: AttributeKey[];
  total: number;
  gql?: string | null;
}

export interface SearchAttributeKeysOpts {
  filter: SearchableAttributeKeyFilterInput;
  limit?: number | null;
  from?: number | null;
  gql?: string | null;
}

// Interface declaration
export interface AttributeKeyModel {
  attributeKeys: ModelAttributeKeyConnection;
  attributeKey: AttributeKey | null;

  setAttributeKey: Action<AttributeKeyModel, AttributeKey | null>;
  replaceAttributeKey: Action<AttributeKeyModel, AttributeKey>;
  updateAttributeKey: Thunk<AttributeKeyModel, UpdateAttributeKey, Injections>;
  setAttributeKeys: Action<
    AttributeKeyModel,
    { data: ModelAttributeKeyConnection }
  >;
  searchAllAttributeKeys: Thunk<
    AttributeKeyModel,
    SearchAttributeKeysOpts,
    Injections,
    StoreModel
  >;
}

export const attributeKeyModel: AttributeKeyModel = {
  attributeKeys: { items: [], total: 0 },
  attributeKey: null,

  setAttributeKey: action((state, payload) => {
    state.attributeKey = payload;
  }),
  replaceAttributeKey: action((state, payload) => {
    state.attributeKey = payload;
    if (state.attributeKeys.items) {
      const index = state.attributeKeys.items!.findIndex(
        e => e.id === payload.id
      );
      if (index >= 0) state.attributeKeys.items!.splice(index, 1, payload);
    }
  }),
  setAttributeKeys: action(
    (state, payload: { data: ModelAttributeKeyConnection }) => {
      const { data } = payload;
      state.attributeKeys = data;
    }
  ),

  updateAttributeKey: thunk(async (actions, payload, { injections }) => {
    const { attributeKeyService } = injections;
    const response = await attributeKeyService.updateAttributeKey(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.updateAttributeKey;
    actions.setAttributeKey(item);
    actions.replaceAttributeKey(item);

    return item;
  }),

  searchAllAttributeKeys: thunk(async (actions, payload, { injections }) => {
    const { attributeKeyService } = injections;
    const response = await attributeKeyService.searchAllAttributeKeys(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const data = response.data.searchAllAttributeKeys;
    actions.setAttributeKeys({ data });

    return data;
  }),
};
