/*
 *
 * Option Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import {
  CreateOptionMutation,
  DeleteOptionMutation,
} from "../../api/graphql/types";

import { listProductOptions as listProductOptionsQuery } from "../../api/graphql/queries";

import {
  createOption as createOptionMutation,
  deleteOption as deleteOptionMutation,
} from "../../api/graphql/mutations";

import { fetchPolicy } from "../../api/client";

export const listProductOptions = payload => {
  const request = payload.gql ? payload.gql : listProductOptionsQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: payload,
    fetchPolicy,
  }) as {
    data: any;
  };
};

export const createOption = payload =>
  ApolloClient.mutate({
    mutation: gql(createOptionMutation),
    variables: { input: payload },
  }) as {
    data: CreateOptionMutation;
  };

export const deleteOption = payload =>
  ApolloClient.mutate({
    mutation: gql(deleteOptionMutation),
    variables: { input: payload },
  }) as {
    data: DeleteOptionMutation;
  };
