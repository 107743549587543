import React from "react";
import classnames from "classnames";
import Transition from "../Transition";
import { getTranslation } from "../../utils/translation";
import { getCypressTestId } from "../../utils/config";
import IntlMessages from "../../utils/messages";
import { Center } from "../../lib/ground-aws-graphql-core/models/Center";

interface Props {
  open: boolean;
  items: Center[] | null | undefined;
  onChange: (e: string) => void;
  item: Center | null | undefined;
  className?: string;
  showAllCenters?: boolean;
}

const CenterDropDown = (props: Props): JSX.Element => {
  const value =
    "block px-4 py-2 text-sm leading-5 hover:bg-gray-100 focus:outline-none focus:bg-gray-100";
  const custom = props.className ? props.className : "";

  const className = (id: string) =>
    classnames(value, custom, {
      "text-ground-green-100": id === props.item?.id,
      "text-gray-700 hover:text-gray-900": id !== props.item?.id,
    });

  props.items?.sort((c1, c2) =>
    c1.name?.toLowerCase() < c2.name?.toLowerCase() ? -1 : 1
  );

  return (
    <div className="origin-top-left absolute text-left z-50 focus:outline-none">
      <Transition
        show={props.open}
        enter="transition ease-out duration-150"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="mt-2 w-full rounded-md shadow-lg max-h-96 overflow-x-auto overflow-y-auto">
          <div className="rounded-md bg-white shadow-xs">
            {props.showAllCenters && (
              <a
                id="display-all-centers"
                data-cy="display-all-centers"
                key="display-all-centers"
                onClick={() => props.onChange("")}
              >
                <IntlMessages id="dropdown.all.centers" />
              </a>
            )}
            {props.items?.map(item => {
              return (
                <a
                  id={item.id}
                  data-cy="center_selection"
                  data-testid={getCypressTestId(item)}
                  key={item.id}
                  onClick={() => props.onChange(item.id)}
                  className={className(item.id)}
                >
                  {getTranslation(item.name)}
                </a>
              );
            })}
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default CenterDropDown;
