import classNames from "classnames";
import React, { useState, useContext } from "react";
import { IntlShape, injectIntl } from "react-intl";
import {
  components,
  DropdownIndicatorProps,
  SingleValueProps,
} from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { ThemeContext } from "../../../../../containers/App";
import images from "../../../../../images";
import { EnumFilterType } from "../../../../../utils/filter";
import { ALL_FILTER, IFilterField } from "../../../../Table";
import { ILoadOptionsResponse } from "../../../../../routes/management/global-orders/global-orders-list/table";

export interface Additional {
  page: number;
}

interface AsyncFilterSelectProps {
  intl: IntlShape;
  filters: {
    title?: string;
    value: string;
    type: EnumFilterType;
    onChange: (type: string, e: any) => void;
    onInputChange?: (type: string, e: any) => void;
    async?: boolean;
    disabled?: boolean;
    dependsOn?: {
      field: string;
      operator: string;
    }[];
  }[];
  selectedFilters?: IFilterField<any>[];
  loadOptions: {
    [key: string]: (
      searchQuery: any,
      _loadedOptions: any,
      additional: Additional
    ) => Promise<ILoadOptionsResponse>;
  };
  className?: string;
  isSearchable?: boolean;
  displayTitle?: boolean;
}

const selectStyles = () => {
  const theme = useContext(ThemeContext);
  const { colors: themeColors, boxShadow } = theme;

  return {
    control: (base, state) => ({
      ...base,
      backgroundColor: state.isDisabled ? themeColors.gray[300] : "white",
      cursor: "pointer",
      borderRadius: 1,
      minHeight: 30,
      minWidth: 200,
      borderWidth: 0,
      borderColor: state.isDisabled
        ? themeColors.gray[200]
        : state.isFocused
        ? themeColors.ground["gray-300"]
        : themeColors.ground["gray-400"],
      boxShadow: "transparent",
      ":hover": {
        borderColor: themeColors.ground["gray-300"],
      },
    }),
    valueContainer: base => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: (base, state) => {
      return {
        ...base,
        backgroundColor: "white",
        cursor: "pointer",
        color: state.isSelected
          ? themeColors.ground["blue-100"]
          : themeColors.neutral[900],
        fontSize: 14,
        lineHeight: 1.25,
        fontFamily: "OpenSans-Regular",
        ":hover": {
          backgroundColor: themeColors.neutral[300],
        },
      };
    },
    singleValue: (base, state) => ({
      ...base,
      color: state.isDisabled
        ? themeColors.primary[400]
        : themeColors.ground["blue-100"],
      fontSize: 14,
      lineHeight: 1.25,
      marginLeft: 4,
      fontFamily: "OpenSans-Regular",
    }),
    menu: base => ({
      ...base,
      borderRadius: 8,
      borderColor: themeColors.ground["gray-250"],
      boxShadow: boxShadow["ground-1"],
      width: "max-content",
      minWidth: "100%",
    }),
    // Used for scaling the input to the right size of the placeholder
    placeholder: base => ({
      ...base,
      position: "static",
      transform: "none",
    }),
    clearIndicator: base => ({
      ...base,
      padding: 0,
    }),
  };
};

const CustomDropdownIndicator = (dropdownProps: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...dropdownProps}>
      <img src={images.chevronDown} alt="chevron-down" className="mr-2" />
    </components.DropdownIndicator>
  );
};

interface CustomSingleValueProps extends SingleValueProps {
  children: React.ReactNode;
}

const AsyncFilterSelect = (props: AsyncFilterSelectProps): JSX.Element => {
  const {
    filters,
    selectedFilters,
    intl,
    loadOptions,
    isSearchable,
    className,
  } = props;
  let { displayTitle } = props;

  const selectedFilterTypes = selectedFilters?.map(s => s.type);

  const { value, onChange, onInputChange, dependsOn } = filters[0];

  if (dependsOn && dependsOn?.length && selectedFilterTypes?.length) {
    const dependingFilter = dependsOn.filter(
      d =>
        d.operator === "filter_value" && selectedFilterTypes?.includes(d.field)
    );
    if (dependingFilter.length) {
      dependingFilter.forEach(d => {
        const f = selectedFilters?.find(s => s.type === d.field);
        if (f?.value.id !== ALL_FILTER) {
          filters[0].disabled = false;
        }
      });
    }
  }

  const { disabled } = filters[0];

  const selectedFilter = selectedFilters?.find(s => s.type === value);
  const currentValue = selectedFilter?.value;

  const [selectValue, setSelectValue] = useState(currentValue);
  const [filter, setFilter] = useState<string>(value);

  const CustomSingleValue = ({
    children,
    ...singleValueProps
  }: CustomSingleValueProps) => (
    <components.SingleValue {...singleValueProps}>
      {children}
    </components.SingleValue>
  );

  if (displayTitle === undefined) displayTitle = true;

  return (
    <div
      className={classNames(
        "h-8 flex items-center border border-ground-gray-400 rounded-4",
        className
      )}
    >
      {displayTitle && (
        <select
          id="filter-async-select-field"
          name="filter-async-select-field"
          className="form-select h-full py-0 pl-4 bg-transparent text-ground-black-100 text-14px leading-5 border-t-0 border-b-0 border-l-0 border-r border-ground-gray-400 rounded-none shadow-none"
          onChange={e => setFilter(e.target.value)}
        >
          {filters.map(e => (
            <option key={e.value} id={e.value} value={e.value}>
              {intl.formatMessage({ id: `general.filter.${e.value}` })}
            </option>
          ))}
        </select>
      )}

      <AsyncPaginate
        name="filter-async-select-input"
        id="filter-async-select-input"
        styles={selectStyles()}
        className="w-full"
        value={selectValue}
        loadOptions={loadOptions[`${filter}`]}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        debounceTimeout={1000}
        onChange={e => {
          setSelectValue(e);

          return onChange(filter, e);
        }}
        onInputChange={v => {
          return onInputChange && onInputChange(filter, v);
        }}
        // cacheUniqs={[ selectValue ]}
        isSearchable={isSearchable}
        placeholder={intl.formatMessage({
          id: `general.filter.placeholder.${filter}`,
        })}
        isDisabled={disabled}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: CustomDropdownIndicator,
          SingleValue: CustomSingleValue,
        }}
        additional={{
          page: 1,
        }}
      />
    </div>
  );
};

export default injectIntl(AsyncFilterSelect);
