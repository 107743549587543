import _ from "lodash";
import { toast } from "react-toastify";
import React, { useEffect, useState, useRef } from "react";
import { IntlShape } from "react-intl";
import { v4 as uuidv4 } from "uuid";
import { FieldAttributes } from "formik";
import { match as Match, Redirect } from "react-router-dom";
import CardAttributes from "../../../../components/GroundBlocks/BlockCard/CardAttributes";
import CardHour from "../../../../components/GroundBlocks/BlockCard/CardHour";
import Block from "../../../../components/Tailwind/Block";
import Header from "../../../../components/Tailwind/Block/Header";
import HeaderOption from "../../../../components/Tailwind/Block/HeaderOption";
import Button from "../../../../components/Tailwind/Button";
import history from "../../../../history";
import contextStore from "../../../../redux/store";
import CardProductInformation from "./informations";
import {
  AttributeItem,
  getAttributes,
  getRequiredAttributes,
  ProductAttributeKeys,
} from "../../../../utils/attribute";
import {
  getCypressTestId,
  getDifferenceInMilliseconds,
} from "../../../../utils/config";
import {
  cloneCenterClosedDays,
  cloneCenterDaySlots,
  cloneCenterHoursRange,
  ClosedDayItem,
  DaySlotItem,
  ExceptionalOpeningHourItem,
  getCenterClosedDays,
  getCenterDaySlots,
  getCenterExceptionalOpeningHours,
  HoursRangeItem,
} from "../../../../utils/dayslot";
import { getProductGql } from "../../../../utils/gql/gql";
import {
  EnumPaths,
  getServicesPathByTypology,
} from "../../../../utils/navigation";
import {
  getPriceUnitElements,
  getPriceUnitElementsByType,
  getPriceUnitsByVariation,
  PriceUnitElement,
  PriceUnitItem,
  PriceUnitItemVariation,
} from "../../../../utils/price-unit";
import {
  ActionTypes,
  canManageCategories,
  EnumAction,
  getAsOptionTypologies,
  getBookableTypologies,
  getCategoryOptions,
  getInCatalogTypologies,
  getProductTypologyItemsForm,
  getProviderItemsForm,
  Image,
  isPrestation,
} from "../../../../utils/types";
import ModalCreateUpdateCategory from "../../category/create-update";
import ModalCreatePriceUnitVariation from "../price-unit-variation/modal";
import Attribute from "../attribute";
import ListOptions from "../options";
import IntlMessages from "../../../../utils/messages";
import {
  EnumAttributeType,
  EnumBackOfficeUserRole,
  EnumCategoryType,
  EnumCurrency,
  EnumPermissionEntity,
  EnumProductType,
  EnumProductTypology,
  EnumServiceType,
  InvalidParams,
  SearchableCategorySortableFields,
  SearchableSortDirection,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { PriceUnitVariation } from "../../../../lib/ground-aws-graphql-core/models/PriceUnitVariation";
import { Category } from "../../../../lib/ground-aws-graphql-core/models/Category";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import { Product } from "../../../../lib/ground-aws-graphql-core/models/Product";
import { AttributeKey } from "../../../../lib/ground-aws-graphql-core/models/AttributeKey";
import { PriceUnit } from "../../../../lib/ground-aws-graphql-core/models/PriceUnit";
import { GroundAuthContextStore } from "../../../../lib/ground-aws-cognito-auth-core";
import CardPrivatizations from "../privatizations";
import { AdditionalFieldAttributes } from "../../../../components/Form";
import FormSwitch from "../../../../components/Form/FormSwitch";
import overrideClasses from "../../../../utils/overrideClasses";
import { InvalidParamsFormatter } from "../../../../utils/messages/errors";
import { getEmptyTranslations, getLocale } from "../../../../lang";
import CardClosedDays from "../../../../components/GroundBlocks/BlockCard/CardClosedDays";
import ConfirmModal from "../../../../utils/modal/confirm";

interface Props {
  serviceType: EnumServiceType;
  match: Match<{ cid: string; id: string; pid: string }>;
  intl: IntlShape;
  edition: boolean;
}

const DEFAULT_VAT = 20.0;
const DEFAULT_CAPACITY = 1;
const DEFAULT_QUANTITY = 1;
const DEFAULT_FLEXIBLE = false;
const DEFAULT_CHECK_STOCK = true;

const DEFAULT_LIMIT = 100;

const ProductForm = (props: Props): JSX.Element => {
  const { match, intl, serviceType, edition } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [enabled, setEnabled] = useState(edition ? true : false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [productTypologyToChange, setProductTypologyToChange] =
    useState<EnumProductTypology | null>(null);

  const [productTypology, setProductTypology] = useState<
    EnumProductTypology | undefined
  >();

  const [daySlots, setDaySlots] = useState([] as DaySlotItem[]);

  const [exceptionalOpeningHours, setExceptionalOpeningHours] = useState<
    ExceptionalOpeningHourItem[]
  >([]);

  const [closedDays, setClosedDays] = useState([] as ClosedDayItem[]);
  const [hoursRanges, setHoursRanges] = useState([] as HoursRangeItem[]);
  const [overrideDaySlots, setOverrideDaySlots] = useState(false);
  const [daySlotLoaded, setDaySlotLoaded] = useState(false);
  const [overrideClosedDays, setOverrideClosedDays] = useState(false);

  const [fieldsToUpdate, setFieldsToUpdate] = useState(
    [] as { field: any; value: any }[]
  );

  const [filteredAttributeKeys, setFilteredAttributeKeys] = useState<
    AttributeKey[]
  >([]);

  const [isAttributesLoaded, setIsAttributesLoaded] = useState(false);
  const [isFilteredAttributeKeysLoaded, setIsFilteredAttributeKeysLoaded] =
    useState(false);
  const [attributes, setAttributes] = useState<AttributeItem[]>([]);
  const [pictures, setPictures] = useState<Image[]>([]);
  const [priceUnitsVariation, setPriceUnitsVariation] =
    useState<PriceUnitItemVariation | null>(null);

  const [options, setOptions] = useState<
    { id: string; option: Product; action: ActionTypes }[]
  >([]);

  const [singleProducts, setSingleProducts] = useState<
    { id: string; singleProduct: Product; action: ActionTypes }[]
  >([]);

  const [showPanel, setShowPanel] = useState(false);

  const [category, setCategory] = useState<Category | null | undefined>(null);
  const [modalCategoryOpen, setModalCategoryOpen] = useState(false);
  const [action, setAction] = useState<EnumAction>();

  const [priceUnitVariation, setPriceUnitVariation] = useState(
    null as PriceUnitVariation | null | undefined
  );

  const [modalPriceUnitVariationOpen, setModalPriceUnitVariationOpen] =
    useState(false);

  const [attributeKeys, setAttributeKeys] = useState<AttributeKey[] | null>(
    null
  );

  const searchAllAttributeKeys = GroundGraphqlContextStore.useStoreActions(
    actions => actions.attributeKey.searchAllAttributeKeys
  );

  const getService = GroundGraphqlContextStore.useStoreActions(
    actions => actions.service.getService
  );

  const getProduct = GroundGraphqlContextStore.useStoreActions(
    actions => actions.product.getProduct
  );

  const setProduct = GroundGraphqlContextStore.useStoreActions(
    actions => actions.product.setProduct
  );

  const listProductOptions = GroundGraphqlContextStore.useStoreActions(
    actions => actions.option.listProductOptions
  );

  const searchDaySlots = GroundGraphqlContextStore.useStoreActions(
    actions => actions.daySlot.searchDaySlots
  );

  const product = GroundGraphqlContextStore.useStoreState(
    state => state.product.product
  );

  const center = GroundGraphqlContextStore.useStoreState(s => s.center.center);

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const searchAllProviders = GroundGraphqlContextStore.useStoreActions(
    actions => actions.provider.searchAllProviders
  );

  const searchAllPriceUnitVariations =
    GroundGraphqlContextStore.useStoreActions(
      actions => actions.priceUnitVariation.searchAllPriceUnitVariations
    );

  const priceVariations = GroundGraphqlContextStore.useStoreState(
    state => state.priceUnitVariation.priceUnitVariations.items
  );

  const categories = GroundGraphqlContextStore.useStoreState(
    state => state.category.categories.items
  );

  const searchAllCategories = GroundGraphqlContextStore.useStoreActions(
    actions => actions.category.searchAllCategories
  );

  const providers = GroundGraphqlContextStore.useStoreState(
    state => state.provider.providers.items
  );

  // sort providers by name
  providers?.sort((p1, p2) =>
    p1.name!.toLowerCase() < p2.name!.toLowerCase() ? -1 : 1
  );

  const service = GroundGraphqlContextStore.useStoreState(
    state => state.service.service
  );

  const createProduct = contextStore.useStoreActions(
    actions => actions.product.createProduct
  );

  const updateProduct = contextStore.useStoreActions(
    actions => actions.product.updateProduct
  );

  const createProductAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.product.createProduct
  );

  const updateProductAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.product.updateProduct
  );

  const createOption = GroundGraphqlContextStore.useStoreActions(
    actions => actions.option.createOption
  );

  const deleteOption = GroundGraphqlContextStore.useStoreActions(
    actions => actions.option.deleteOption
  );

  const createSingleProduct = GroundGraphqlContextStore.useStoreActions(
    actions => actions.singleProduct.createSingleProduct
  );

  const deleteSingleProduct = GroundGraphqlContextStore.useStoreActions(
    actions => actions.singleProduct.deleteSingleProduct
  );

  const privatizations = contextStore.useStoreState(
    s => s.privatization.privatizations.items
  );

  const listProductPrivatizations = contextStore.useStoreActions(
    a => a.privatization.listProductPrivatizations
  );

  const isSpace = serviceType === EnumServiceType.SPACE;

  const bookableTypologies = getBookableTypologies();

  const bookable =
    (productTypology && bookableTypologies.includes(productTypology)) ||
    isSpace;

  const prestation = isPrestation(productTypology);

  const redirect = edition && product?.markForDelete;

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  useEffect(() => {
    fetchData();

    // Clean the product from the state when we unmount this component
    return () => {
      setProduct(null);
    };
  }, []);

  useEffect(() => {
    const currentCategory = category
      ? categories?.find(c => c.id === category.id)
      : null;
    if (currentCategory) {
      setCategory(currentCategory);
    }
  }, [categories]);

  // FIXME: Remove this when we refactor the second part of the form
  useEffect(() => {
    // Reset the showPanel otherwise the panel won't open again when modifying elements outside of the initial form
    if (showPanel) setShowPanel(false);
  }, [showPanel]);

  const priceUnitAllowed = (el: PriceUnitItem | PriceUnitElement): boolean => {
    const values = Object.values(
      getPriceUnitElementsByType(intl, serviceType, productTypology)
    );

    const element = el.item ?? el;

    const pUnit = values.find(e => e.unit === element.unit);

    return !!pUnit;
  };

  const handleChangeAttribute = (newAttributes: AttributeItem[]) => {
    setAttributes([...newAttributes]);
    handleShowSavePanel();
  };

  const fetchData = async () => {
    setLoading(true);

    const promises = [
      searchAllAttributeKeys({
        limit: DEFAULT_LIMIT,
        filter: {
          type: { eq: EnumAttributeType.PRODUCT },
          enabled: { eq: true },
        },
      }),
      searchAllCategories({
        filter: {
          type: {
            eq: isSpace
              ? EnumCategoryType.SPACE_PRODUCT
              : EnumCategoryType.SERVICE_PRODUCT,
          },
          categoryCenterId: {
            eq: match.params.cid,
          },
        },
        sort: {
          field: SearchableCategorySortableFields.createdAt,
          direction: SearchableSortDirection.desc,
        },
        locale: currentAppLocale.backend_locale,
      }),
      searchAllProviders({
        limit: DEFAULT_LIMIT,
      }),
      searchAllPriceUnitVariations({
        limit: DEFAULT_LIMIT,
        filter: {
          enabled: { eq: true },
        },
      }),
    ];

    if (!isSpace && match.params.id) {
      promises.push(getService({ id: match.params.id }));
    }
    if (edition) {
      promises.push(getProduct({ id: match.params.pid, gql: getProductGql }));
      promises.push(listProductPrivatizations({ productId: match.params.pid }));
    }

    try {
      const [attrKeys] = await Promise.all(promises);

      setAttributeKeys(attrKeys.items);
    } catch (error) {
      setIsAttributesLoaded(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (me && attributeKeys) {
      const filtered = attributeKeys.filter(
        ak =>
          !ak.roles ||
          (ak.roles &&
            ak.roles.length > 0 &&
            ak.roles.includes(me.role as EnumBackOfficeUserRole))
      );
      setFilteredAttributeKeys(filtered);
      setIsFilteredAttributeKeysLoaded(true);

      if (!edition) setIsAttributesLoaded(true);
    }
  }, [attributeKeys, me]);

  useEffect(() => {
    // Throws an error at the update if we already have the attributes
    if (
      product &&
      me &&
      isFilteredAttributeKeysLoaded &&
      attributes.length === 0
    ) {
      const attr = getAttributes(
        product,
        EnumAttributeType.PRODUCT,
        filteredAttributeKeys
      );

      setAttributes([...attr]);
      setIsAttributesLoaded(true);
    }
  }, [product, isFilteredAttributeKeysLoaded, me]);

  useEffect(() => {
    if (product) {
      setCategory(product.category);
      setProductTypology(product.typology);

      // set price units
      const pusVariation = getPriceUnitsByVariation(product, priceVariations);

      listProductOptions({
        productId: product.id,
      }).then(optionsData => {
        const opts = optionsData?.items?.reduce(
          (acc: { id: string; option: Product; action: ActionTypes }[], el) => {
            const markForDelete =
              el.product.provider?.markForDelete || el.product.markForDelete;
            // options which provider is deleted are set to DELETE, or if option is deleted
            acc.push({
              id: el.id,
              option: el.product,
              action: markForDelete
                ? ActionTypes.TO_DELETE
                : ActionTypes.TO_KEEP,
            });

            return acc;
          },
          []
        );

        if (opts) {
          setOptions(opts);
        }
      });

      setPriceUnitsVariation(pusVariation);

      setEnabled(product.enabled as boolean);

      const prods = product.products?.items?.reduce(
        (
          acc: { id: string; singleProduct: Product; action: ActionTypes }[],
          el
        ) => {
          const markForDelete =
            el.product.provider?.markForDelete || el.product.markForDelete;
          // single products which provider is deleted are set to DELETE, or if single product is deleted
          acc.push({
            id: el.id,
            singleProduct: el.product,
            action: markForDelete ? ActionTypes.TO_DELETE : ActionTypes.TO_KEEP,
          });

          return acc;
        },
        []
      );
      if (prods) {
        setSingleProducts(prods);
      }

      const images = product.pictures?.reduce((acc: Image[], el) => {
        if (el) {
          acc.push({
            picture: el,
            source: false,
            action: ActionTypes.TO_KEEP,
          });
        }

        return acc;
      }, []);

      if (images) {
        setPictures(images);
      }
    }
  }, [product, priceVariations]);

  useEffect(() => {
    if (center && product) {
      searchDaySlots({
        filter: { productDaySlotsId: { eq: product.id } },
      }).then(daySlotsData => {
        const productDaySlots = daySlotsData?.items;
        const centerDaySlots = center.daySlots?.items;

        if (productDaySlots?.length) {
          setOverrideDaySlots(true);
          const days = getCenterDaySlots(intl, productDaySlots);
          setDaySlots(days);
        } else if (centerDaySlots?.length) {
          const days = cloneCenterDaySlots(intl, centerDaySlots);
          setDaySlots(days);
          const hours = cloneCenterHoursRange(days);
          setHoursRanges(hours);
        }
        setDaySlotLoaded(true);
      });

      const centerClosedDays = center.closedDays?.items?.sort((a, b) =>
        getDifferenceInMilliseconds(a.start, b.start)
      );
      const productClosedDays = product.closedDays?.items?.sort((a, b) =>
        getDifferenceInMilliseconds(a.start, b.start)
      );

      if (
        productClosedDays?.length &&
        !_.isEqual(productClosedDays, centerClosedDays)
      ) {
        const closed = getCenterClosedDays(productClosedDays);
        setClosedDays(closed);
        setOverrideClosedDays(true);
      } else if (centerClosedDays?.length) {
        const closed = cloneCenterClosedDays(centerClosedDays);
        setClosedDays(closed);
      }

      if (product.exceptionalOpeningHours?.items) {
        const exceptionalHours = getCenterExceptionalOpeningHours(
          product.exceptionalOpeningHours.items
        );
        setExceptionalOpeningHours(exceptionalHours);
      }
    }
  }, [center, product]);

  const handleChangeHours = (hoursRange: HoursRangeItem) => {
    const index = _.findIndex(
      hoursRanges,
      o => o.item.id === hoursRange.item.id
    );

    const array = hoursRanges;
    if (index >= 0) {
      if (!daySlots?.length) {
        /** first time always "to_add" instead of "to_update" */
        if (hoursRange.action === ActionTypes.TO_UPDATE)
          hoursRange.action = ActionTypes.TO_ADD;
      }

      const currentHoursRange = hoursRanges[index];
      // Impossible condition, we can't go from TO_ADD to TO_KEEP
      // This means we actually want to delete the hoursRange locally
      if (
        currentHoursRange.action === ActionTypes.TO_ADD &&
        hoursRange.action === ActionTypes.TO_KEEP
      )
        array.splice(index, 1);
      else array.splice(index, 1, hoursRange);

      setHoursRanges(array);
    } else {
      setHoursRanges([...hoursRanges, hoursRange]);
    }

    setOverrideDaySlots(true);
    handleShowSavePanel();
  };

  const handleChangeExceptionalOpeningHours = (
    ...exceptionalOpeningHourItems: ExceptionalOpeningHourItem[]
  ) => {
    let array = exceptionalOpeningHours;
    for (const exceptionalHour of exceptionalOpeningHourItems) {
      const index = exceptionalOpeningHours.findIndex(
        e => e.item.id === exceptionalHour.item.id
      );
      if (index >= 0) array.splice(index, 1, exceptionalHour);
      else array = [...array, exceptionalHour];
    }

    setExceptionalOpeningHours(array);
    handleShowSavePanel();
  };

  const handleChangeClosedDays = (closedDay: ClosedDayItem) => {
    // find closed day using start and end
    const index = _.findIndex(
      closedDays,
      a =>
        a.item.start === closedDay.item.start &&
        a.item.end === closedDay.item.end
    );
    if (index >= 0) {
      // found
      const array = [...closedDays];
      array.splice(index, 1, closedDay);
      setClosedDays(array);
    } else {
      // not found, we add it
      setClosedDays([...closedDays, closedDay]);
    }
    setOverrideClosedDays(true);
    handleShowSavePanel();
  };

  const saveDatas = (formValues?, setErrors?) => {
    // delete wrong price units
    if (priceUnitsVariation) {
      const keys = Object.keys(priceUnitsVariation);
      keys.forEach((key: string) => {
        const priceUnitItems = priceUnitsVariation[key];
        priceUnitItems.forEach((el: PriceUnitItem) => {
          if (!priceUnitAllowed(el)) {
            el.action = ActionTypes.TO_DELETE;
          }
        });
      });
    }

    setLoading(true);

    const name = _.find(fieldsToUpdate, ["field", "name"]);
    const description = _.find(fieldsToUpdate, ["field", "description"]);
    const type = _.find(fieldsToUpdate, ["field", "type"]);
    const sku = _.find(fieldsToUpdate, ["field", "sku"]);
    const productProviderId = _.find(fieldsToUpdate, [
      "field",
      "productProviderId",
    ]);
    const capacity = _.find(fieldsToUpdate, ["field", "capacity"]);
    const vat = _.find(fieldsToUpdate, ["field", "vat"]);
    const stock = _.find(fieldsToUpdate, ["field", "stock"]);
    const flexible = _.find(fieldsToUpdate, ["field", "flexible"]);
    const checkStock = _.find(fieldsToUpdate, ["field", "checkStock"]);
    const productCategoryId = category?.id;

    let values = formValues;

    if (!formValues) {
      values = {
        name: name ? name.value : product?.name,
        type: type
          ? type.value || EnumProductType.SIMPLE
          : product?.type || EnumProductType.SIMPLE,
        typology: productTypology,
        sku: sku ? sku.value : product?.sku,
        productProviderId: productProviderId
          ? productProviderId.value
          : product?.provider?.id,
        capacity: Number(
          capacity?.value || product?.capacity || DEFAULT_CAPACITY
        ),
        vat: vat
          ? vat.value
          : product && product?.vat
          ? product?.vat * 100
          : DEFAULT_VAT,
        stock: bookable
          ? DEFAULT_QUANTITY
          : Number(stock?.value || product?.stock || DEFAULT_QUANTITY),
        flexible: flexible
          ? flexible.value
          : product?.flexible !== undefined && product?.flexible !== null
          ? product?.flexible
          : DEFAULT_FLEXIBLE,
        checkStock: checkStock
          ? checkStock.value
          : product?.checkStock !== undefined && product?.checkStock !== null
          ? product?.checkStock
          : DEFAULT_CHECK_STOCK,
        productCategoryId: productCategoryId ?? product?.category?.id,
      };
      const descr = description?.value || product?.description;
      if (descr) {
        values = { ...values, description: descr };
      }
    } else {
      // The field was hidden in the last form so it couldn't be changed and was always SIMPLE
      values.type = EnumProductType.SIMPLE;

      if (values.stock !== DEFAULT_QUANTITY && bookable)
        values.stock = DEFAULT_QUANTITY;

      values.typology = productTypology;
      values.productCategoryId = productCategoryId ?? product?.category?.id;
    }

    if (edition) {
      updateProduct({
        product,
        service: !isSpace ? service : null,
        values,
        pictures,
        priceUnitsVariation,
        attributeKeys: filteredAttributeKeys || null,
        attributes,
        options,
        singleProducts,
        hoursRanges: overrideDaySlots && bookable ? hoursRanges : undefined,
        daySlots: overrideDaySlots && bookable ? daySlots : undefined,
        closedDays: overrideClosedDays && bookable ? closedDays : undefined,
        exceptionalOpeningHours: bookable ? exceptionalOpeningHours : undefined,
        center: {
          id: match.params.cid,
          attributes: center?.attributes,
          daySlots: center?.daySlots,
          closedDays: center?.closedDays,
          operator: center?.operator,
        },
        enabled,
        callbacks: {
          createOption,
          deleteOption,
          createSingleProduct,
          deleteSingleProduct,
          updateProduct: updateProductAction,
          createProduct: createProductAction,
        },
      })
        .then(() => {
          const centerId = match.params.cid;
          let path = `/${EnumPaths.ROOT}/${
            EnumPaths.CENTERS
          }/${centerId}/${getServicesPathByTypology(serviceType)}`;
          if (!isSpace && match.params.id) {
            path += `/${match.params.id}`;
          }
          history.push(path);

          toast(
            intl.formatMessage({
              id: `page.product.update.product.${serviceType}.success`,
            }),
            { type: "success" }
          );
        })
        .catch(err => {
          const inValidParams: InvalidParams =
            err.graphQLErrors[0]?.invalid_params;
          if (inValidParams && setErrors) {
            setErrors(InvalidParamsFormatter(inValidParams, intl));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createProduct({
        product: null,
        service: !isSpace ? service : null,
        values,
        pictures,
        priceUnitsVariation,
        attributeKeys: filteredAttributeKeys || null,
        attributes,
        options,
        singleProducts,
        center: {
          id: match.params.cid,
          attributes: center?.attributes,
          daySlots: center?.daySlots,
          closedDays: center?.closedDays,
          operator: center?.operator,
        },
        enabled,
        callbacks: {
          createOption,
          deleteOption,
          createSingleProduct,
          deleteSingleProduct,
          updateProduct: updateProductAction,
          createProduct: createProductAction,
        },
      })
        .then(() => {
          const centerId = match.params.cid;
          let path = `/${EnumPaths.ROOT}/${
            EnumPaths.CENTERS
          }/${centerId}/${getServicesPathByTypology(serviceType)}`;
          if (!isSpace && match.params.id) {
            path += `/${match.params.id}`;
          }
          history.push(path);

          toast(
            intl.formatMessage({
              id: `page.product.create.product.${serviceType}.success`,
            }),
            { type: "success" }
          );
        })
        .catch(err => {
          const inValidParams: InvalidParams =
            err.graphQLErrors[0]?.invalid_params;
          if (inValidParams && setErrors) {
            setErrors(InvalidParamsFormatter(inValidParams, intl));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleAddProductPriceUnit = (variation: PriceUnitVariation) => {
    let priceUnitItems = priceUnitsVariation
      ? priceUnitsVariation[variation.id]
      : [];

    if (!priceUnitItems) {
      priceUnitItems = [];
    }

    // current product price units
    const items = priceUnitItems.reduce((acc: PriceUnit[], item) => {
      if (item.action !== ActionTypes.TO_DELETE) {
        acc.push(item.item);
      }

      return acc;
    }, []);

    const emptyBuyableDescription = JSON.stringify(getEmptyTranslations());

    // exclude
    const puElements = Object.values(getPriceUnitElements(intl)).reduce(
      (acc: PriceUnitItem[], el) => {
        if (canAddUnit(items, el.unit) && priceUnitAllowed(el)) {
          const element: PriceUnitItem = {
            item: {
              id: uuidv4(),
              unit: el.unit,
              price: el.price,
              increment: el.increment,
              variation,
              flexible: el?.flexible,
              currency: getFirstCurrency(items, el.unit),
              buyableUnit: el?.buyableUnit,
              buyableDescription:
                el?.buyableDescription || emptyBuyableDescription,
              minApprovalMinutes: el?.minApprovalMinutes,
              minCancelMinutes: el?.minCancelMinutes,
            },
            edit: true,
            label: el.label,
            action: ActionTypes.TO_ADD,
          };
          acc.push(element);
        }

        return acc;
      },
      []
    );

    // Lock others
    priceUnitItems.forEach(el => {
      if (el.action !== ActionTypes.TO_DELETE) {
        el.edit = false;
      }
    });

    // push first available item
    if (puElements && puElements.length > 0) {
      priceUnitItems.push(puElements[0]);
    }

    const nPriceUnitsVariation = { ...priceUnitsVariation };
    nPriceUnitsVariation[variation.id] = priceUnitItems;

    setPriceUnitsVariation(nPriceUnitsVariation);

    // show save panel
    handleShowSavePanel();
  };

  const canAddUnit = (puItems: PriceUnit[], unit: string): boolean => {
    const items = puItems.filter(e => e.unit === unit);

    if (items.length === 0) {
      return true;
    }

    let addUnit = false;
    Object.keys(EnumCurrency).forEach(c => {
      const currency = hasCurrency(items, c);

      if (!currency) {
        addUnit = true;
      }
    });

    return addUnit;
  };

  const hasCurrency = (items: PriceUnit[], currency: string): boolean => {
    const item = items.find(e => e.currency === currency);

    return !!item;
  };

  const getFirstCurrency = (
    pItems: PriceUnit[],
    unit: string
  ): EnumCurrency => {
    const items = pItems.filter(e => e.unit === unit);

    let firstCurrency;
    Object.keys(EnumCurrency).forEach(c => {
      const currency = hasCurrency(items, c);

      if (!currency && !firstCurrency) {
        firstCurrency = c;
      }
    });

    return firstCurrency || EnumCurrency.EUR;
  };

  const handleUpdateProductPriceUnit = (
    priceUnit: PriceUnit,
    variation: PriceUnitVariation
  ) => {
    if (priceUnitsVariation) {
      const priceUnitItems = priceUnitsVariation[variation.id];

      priceUnitItems.forEach(el => {
        if (el.item.id === priceUnit.id) {
          // change element to no editable
          el.edit = !el.edit;
          if (el.action === ActionTypes.TO_ADD) {
            // nothing to do
          } else if (
            el.action === ActionTypes.TO_KEEP ||
            el.action === ActionTypes.TO_UPDATE
          ) {
            el.action = ActionTypes.TO_UPDATE;
          }
        }
      });

      const nPriceUnitsVariation = { ...priceUnitsVariation };
      nPriceUnitsVariation[variation.id] = priceUnitItems;
      setPriceUnitsVariation(nPriceUnitsVariation);

      // show save panel
      handleShowSavePanel();
    }
  };

  const handleDeleteProductPriceUnit = (
    priceUnit: PriceUnit,
    variation: PriceUnitVariation
  ) => {
    if (priceUnitsVariation) {
      const priceUnitItems = priceUnitsVariation[variation.id];

      const el = priceUnitItems.find(e => e.item.id === priceUnit.id);

      const pus = priceUnitItems.filter(e => e.item.id !== priceUnit.id);

      if (el) {
        if (
          el.action === ActionTypes.TO_KEEP ||
          el.action === ActionTypes.TO_UPDATE
        ) {
          priceUnitItems.forEach(pu => {
            if (pu.item.id === priceUnit.id) {
              pu.action = ActionTypes.TO_DELETE;
              pu.edit = true;
              pus.push(pu);
            }
          });
        }
      }

      const nPriceUnitsVariation = { ...priceUnitsVariation };
      nPriceUnitsVariation[variation.id] = pus;
      setPriceUnitsVariation(nPriceUnitsVariation);

      // show save panel
      handleShowSavePanel();
    }
  };

  const handleAddPicture = (picture: string | ArrayBuffer, file: File) => {
    const items = [...pictures];
    items.push({
      picture,
      source: true,
      file,
      action: ActionTypes.TO_ADD,
    });

    setPictures(items);

    // show save panel
    handleShowSavePanel();
  };

  const handleRemovePicture = (el: Image) => {
    const items = pictures.filter(e => e.picture !== el.picture);
    if (el.source) {
      setPictures(items);
    } else {
      const images = [...items];
      images.push({
        ...el,
        action: ActionTypes.TO_DELETE,
      });
      setPictures(images);
    }
    // show save panel
    handleShowSavePanel();
  };

  const handleAddProduct = (o: Product) => {
    const products = Object.assign([], singleProducts) as {
      id: string;
      singleProduct: Product;
      action: ActionTypes;
    }[];

    const results = products.filter(el => el.singleProduct.id !== o.id);
    const result = products.find(el => el.singleProduct.id === o.id);

    if (result && result.action === ActionTypes.TO_DELETE) {
      // if singleProduct is marked to delete, and we try to add it, we will pass it to keep
      const el = { ...result };
      el.action = ActionTypes.TO_KEEP;
      results.push(el);

      setSingleProducts(results);
    } else {
      products.push({
        id: "",
        singleProduct: o,
        action: ActionTypes.TO_ADD,
      });
      setSingleProducts(products);
    }

    // show save panel
    handleShowSavePanel();
  };

  const handleRemoveProduct = (o: Product) => {
    const products = Object.assign([], singleProducts) as {
      id: string;
      singleProduct: Product;
      action: ActionTypes;
    }[];

    const results = products.filter(el => el.singleProduct.id !== o.id);
    const result = products.find(el => el.singleProduct.id === o.id);

    if (result && result.action === ActionTypes.TO_KEEP) {
      // if singe product exists on product (action == to_keep), we pass it to delete
      const el = { ...result };
      el.action = ActionTypes.TO_DELETE;
      results.push(el);
    }

    setSingleProducts(results);

    // show save panel
    handleShowSavePanel();
  };

  const handleAddOption = (o: Product) => {
    const opts = Object.assign([], options) as {
      id: string;
      option: Product;
      action: ActionTypes;
    }[];

    const results = opts.filter(el => el.option.id !== o.id);
    const result = opts.find(el => el.option.id === o.id);

    if (result && result.action === ActionTypes.TO_DELETE) {
      // if option is marked to delete, and we try to add it, we will pass it to keep
      const el = { ...result };
      el.action = ActionTypes.TO_KEEP;
      results.push(el);

      setOptions(results);
    } else {
      opts.push({
        id: "",
        option: o,
        action: ActionTypes.TO_ADD,
      });
      setOptions(opts);
    }

    handleShowSavePanel();
  };

  const handleRemoveOption = (o: Product) => {
    const opts = Object.assign([], options) as {
      id: string;
      option: Product;
      action: ActionTypes;
    }[];

    const results = opts.filter(el => el.option.id !== o.id);
    const result = opts.find(el => el.option.id === o.id);

    if (result && result.action === ActionTypes.TO_KEEP) {
      // if option exists on product (action == to_keep), we pass it to delete
      const el = { ...result };
      el.action = ActionTypes.TO_DELETE;
      results.push(el);
    }

    setOptions(results);

    // show save panel
    handleShowSavePanel();
  };

  const handleShowSavePanel = () => {
    setShowPanel(true);
  };

  const handleChangeTypology = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (isSpace || !productTypology) {
      setProductTypology(e.target.value as EnumProductTypology);
      handleShowSavePanel();
    } else {
      setProductTypologyToChange(e.target.value as EnumProductTypology);
      setIsModalOpen(true);
    }
  };

  const handleChangeCategory = e => {
    const catId = e.target.value;

    const currentCategory = catId
      ? categories?.find(c => c.id === catId)
      : null;

    if (currentCategory) setCategory(currentCategory);

    handleShowSavePanel();
  };

  const handleSavePanelForm = (values, { setErrors }) => {
    if (attributesFormRef.current) {
      attributesFormRef.current.validateForm().then(data => {
        if (Object.keys(data).length === 0) {
          saveDatas(values, setErrors);
        } else {
          attributesFormRef.current.showErrors(data);
        }
      });
    } else {
      saveDatas(values, setErrors);
    }

    setFieldsToUpdate([]);
  };

  const handleAddPriceUnitVariation = () => {
    setPriceUnitVariation(null);
    setModalPriceUnitVariationOpen(!modalPriceUnitVariationOpen);
    handleShowSavePanel();
  };

  const handleUpdatePriceUnitVariation = (variation: PriceUnitVariation) => {
    setPriceUnitVariation(variation);
    setModalPriceUnitVariationOpen(true);
    handleShowSavePanel();
  };

  const productTypologyItemsForm = getProductTypologyItemsForm(
    serviceType,
    intl
  );

  const cleanedCategories: [Category] | null =
    categories && categories.length > 0
      ? (categories.filter(c => c.level !== 0) as [Category])
      : null;
  const categoryItemsForm = getCategoryOptions(cleanedCategories);

  const providerItemsForm = getProviderItemsForm(intl, providers);

  const authorizedToManageCategories = canManageCategories(me);

  const checkStockInitialValue =
    product?.checkStock !== undefined && product?.checkStock !== null
      ? product?.checkStock
      : DEFAULT_CHECK_STOCK;

  const flexibleInitialValue =
    product?.flexible !== undefined && product?.flexible !== null
      ? product?.flexible
      : DEFAULT_FLEXIBLE;

  const defaultFormFields: FieldAttributes<AdditionalFieldAttributes>[] = [
    {
      name: "typology",
      label: "general.type",
      placeholder: "general.type",
      initialValue: productTypology,
      value: productTypology,
      required: true,
      as: "select",
      options: productTypologyItemsForm,
      onChange: handleChangeTypology,
    },
    {
      name: "name",
      placeholder: "page.product.name",
      label: "page.product.name",
      initialValue: product?.name,
      required: true,
      translatable: true,
    },
    {
      name: "productCategoryId",
      label: "general.category",
      placeholder: "general.category",
      initialValue: category?.id,
      as: "select",
      options: categoryItemsForm,
      hidden: serviceType !== EnumServiceType.SPACE,
      onChange: handleChangeCategory,
      value: category?.id,
      children: !authorizedToManageCategories ? undefined : (
        <div className="mt-4">
          <button
            id="btn-modify-category"
            name="btn-modify-category"
            data-cy="btn-modify-category"
            data-testid={getCypressTestId(category)}
            type="button"
            onClick={() => {
              setAction(EnumAction.UPDATE);
              setModalCategoryOpen(true);
            }}
            className="inline-flex items-center border border-transparent text-12px leading-5 font-medium bg-transparent text-ground-gray-300 hover:text-ground-blue-100 focus:outline-none active:text-ground-gray-300 transition ease-in-out duration-150"
          >
            <span>
              <IntlMessages id="general.edit.category" />
            </span>
          </button>
        </div>
      ),
      thirdColComponent: !authorizedToManageCategories ? undefined : (
        <div>
          <Button
            id="btn-add-category"
            name="btn-add-category"
            data-cy="btn-add-category"
            item={null}
            type="button"
            outline
            onClick={() => {
              setAction(EnumAction.CREATE);
              setModalCategoryOpen(true);
            }}
          >
            <IntlMessages id="general.add.CATEGORY" />
          </Button>
        </div>
      ),
    },
    {
      name: "sku",
      label: "page.product.sku",
      placeholder: "page.product.sku",
      initialValue: product?.sku,
      required: true,
    },
    {
      name: "productProviderId",
      label: "general.provider",
      placeholder: "general.provider",
      initialValue: product?.provider?.id,
      required: true,
      as: "select",
      options: providerItemsForm,
    },
    {
      name: "description",
      label: "general.description",
      placeholder: "general.description",
      initialValue: product?.description,
      as: "textarea",
      translatable: true,
    },
    {
      name: "stock",
      type: "number",
      label: "page.product.quantity",
      placeholder: "page.product.quantity",
      required: true,
      initialValue: product?.stock ?? DEFAULT_QUANTITY,
      min: 0,
      validate: stockValue => {
        if (stockValue >= 0) return "";

        return "page.product.quantity.more.than.zero";
      },
      hidden: bookable || prestation,
    },
    {
      id: "checkStock",
      name: "checkStock",
      label: "page.product.check.stock",
      placeholder: "page.product.check.stock",
      initialValue: checkStockInitialValue,
      component: props => (
        <FormSwitch
          {...props}
          description="page.product.check.stock.description"
        />
      ),
      hidden: bookable || prestation,
    },
    {
      name: "capacity",
      type: "number",
      label: "page.product.capacity",
      placeholder: "page.product.capacity",
      initialValue: product?.capacity || DEFAULT_CAPACITY,
      required: true,
      min: 1,
      hidden: !bookable,
    },
    {
      name: "vat",
      type: "number",
      label: "page.product.vat",
      placeholder: "page.product.vat",
      initialValue: (product?.vat && product.vat * 100) || DEFAULT_VAT,
      required: true,
      step: 0.1,
      min: 0,
      max: 100,
    },
    {
      id: "flexible",
      name: "flexible",
      label: "general.flexible",
      placeholder: "general.flexible",
      initialValue: flexibleInitialValue,
      component: formikProps => (
        <FormSwitch
          {...formikProps}
          description="page.product.flexible.description"
        />
      ),
      hidden: !bookable,
    },
  ];

  const labels = {
    creation: `page.product.${serviceType}.create.title`,
    edition: `page.product.${serviceType}.edit.title`,
  };

  const attributesFormRef = useRef<any>();

  const requiredAttributes = getRequiredAttributes(
    filteredAttributeKeys,
    attributes
  );

  const handleValidateCleanModal = () => {
    if (productTypologyToChange) {
      setProductTypology(productTypologyToChange);

      // When changing typology, we clean options and depending products
      setOptions([]);
      setSingleProducts([]);

      handleShowSavePanel();
    }

    setIsModalOpen(false);
  };

  const productAsOptionsTypologies = getAsOptionTypologies();
  const productInCatalogTypologies = getInCatalogTypologies();

  return edition && redirect ? (
    <Redirect
      to={{
        pathname: `/${EnumPaths.ERROR}`,
        state: { message: "general.redirectMessage" },
      }}
    />
  ) : (edition && (!product || productTypology === null)) ||
    !isAttributesLoaded ? (
    <div className="loading" />
  ) : (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={overrideClasses({ loading })} />

      {/* Hide when loading */}
      <div className={overrideClasses("px-8 pb-32", { hidden: loading })}>
        <Block>
          <Header
            item={product}
            title={edition ? labels.edition : labels.creation}
            entity={EnumPermissionEntity.PRODUCT}
            serviceType={serviceType}
            checked={enabled}
            onChange={e => {
              setEnabled(e);
              // show save panel
              handleShowSavePanel();
            }}
            className="border-b border-gray-200"
          >
            {serviceType === EnumServiceType.SERVICE && (
              <>
                <Attribute
                  key={`attribute_list_${productTypology || ""}`}
                  name="option"
                  attributes={attributes}
                  attributeKeys={filteredAttributeKeys?.filter(
                    a =>
                      (a.name === ProductAttributeKeys.AS_OPTION &&
                        productTypology &&
                        productAsOptionsTypologies.includes(productTypology)) ||
                      (a.name === ProductAttributeKeys.IN_CATALOG &&
                        productTypology &&
                        productInCatalogTypologies.includes(productTypology))
                  )}
                  onChange={handleChangeAttribute}
                />
              </>
            )}
          </Header>
          <ModalCreateUpdateCategory
            {...props}
            isOpen={modalCategoryOpen}
            parent={false}
            categories={categories}
            toggle={() => {
              setModalCategoryOpen(!modalCategoryOpen);
            }}
            onRequestClose={() => {
              setModalCategoryOpen(!modalCategoryOpen);
            }}
            action={action}
            type={
              isSpace
                ? EnumCategoryType.SPACE_PRODUCT
                : EnumCategoryType.SERVICE_PRODUCT
            }
            category={action === EnumAction.UPDATE ? category ?? null : null}
            centerId={match.params.cid}
          />
          <ModalCreatePriceUnitVariation
            {...props}
            isOpen={modalPriceUnitVariationOpen}
            toggle={() => {
              setModalPriceUnitVariationOpen(!modalPriceUnitVariationOpen);
            }}
            onRequestClose={() =>
              setModalPriceUnitVariationOpen(!modalPriceUnitVariationOpen)
            }
            priceUnitVariation={priceUnitVariation}
          />

          <CardProductInformation
            item={product}
            productTypology={productTypology}
            serviceType={serviceType}
            onAddSingleProduct={handleAddProduct}
            onRemoveSingleProduct={handleRemoveProduct}
            singleProducts={singleProducts}
            me={me}
            priceVariations={priceVariations}
            priceUnitsVariation={priceUnitsVariation}
            onAddPriceUnit={handleAddProductPriceUnit}
            onUpdatePriceUnit={handleUpdateProductPriceUnit}
            onDeletePriceUnit={handleDeleteProductPriceUnit}
            onAddPriceUnitVariation={handleAddPriceUnitVariation}
            onUpdatePriceUnitVariation={handleUpdatePriceUnitVariation}
            pictures={pictures}
            onAddImage={handleAddPicture}
            onRemoveImage={handleRemovePicture}
            defaultFormFields={defaultFormFields}
            showPanel={showPanel}
            submitForm={handleSavePanelForm}
          />
        </Block>

        {isSpace && edition && (
          <>
            <CardPrivatizations
              privatizations={privatizations}
              title="general.privatization"
            />
            <Block>
              <HeaderOption
                item={product}
                title="page.product.options.title"
                onAddOption={handleAddOption}
                onRemoveOption={handleRemoveOption}
                items={options}
              />
              <ListOptions
                items={options}
                onRemoveOption={handleRemoveOption}
              />
            </Block>
          </>
        )}

        {center && bookable && edition && daySlotLoaded && (
          <>
            <CardHour
              item={product}
              daySlots={daySlots}
              exceptionalOpeningHours={exceptionalOpeningHours}
              editDaySlots={overrideDaySlots}
              onChangeHours={handleChangeHours}
              onChangeExceptionalOpeningHours={
                handleChangeExceptionalOpeningHours
              }
            />
            <CardClosedDays
              item={product}
              closedDays={closedDays}
              onChangeClosedDays={handleChangeClosedDays}
              editClosedDays={overrideClosedDays}
            />
          </>
        )}

        <CardAttributes
          {...props}
          item={product}
          title="general.param.attributes"
          attributes={attributes.concat(requiredAttributes)}
          attributeKeys={filteredAttributeKeys}
          onChange={handleChangeAttribute}
          onDelete={handleChangeAttribute}
          type={EnumAttributeType.PRODUCT}
          ref={attributesFormRef}
          zeroMessage="page.product.attributes.empty"
        />

        <ConfirmModal
          isOpen={isModalOpen}
          toggle={() => setIsModalOpen(!isModalOpen)}
          onRequestClose={() => setIsModalOpen(!isModalOpen)}
          handleConfirm={handleValidateCleanModal}
          content={<IntlMessages id="page.product.modal.clean.form" />}
        />
      </div>
    </>
  );
};

export default ProductForm;
