import { IntlShape } from "react-intl";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import AppLocale from "../../lang";
import { ActionTypes } from "../types";
import { ClosedDay } from "../../lib/ground-aws-graphql-core/models/ClosedDay";
import { HoursRange } from "../../lib/ground-aws-graphql-core/models/HoursRange";
import { DaySlot } from "../../lib/ground-aws-graphql-core/models/DaySlot";
import { EnumHoursRangeName } from "../../lib/ground-aws-graphql-core/api/graphql/types";
import { getHoursRangeName } from "../preprocessing";
import { ExceptionalOpeningHour } from "../../lib/ground-aws-graphql-core/models/Center";
import { DATE_FORMAT_HOUR, afternoon, isBefore, morning } from "../config";
import { IMultiLanguage } from "../../lib/ground-aws-graphql-core/models/Api";

export const generateDaySlots = (
  intl: IntlShape
): {
  id: string;
  name: string;
  day: IMultiLanguage;
  hoursRange: {
    items: any[] | null;
    total: number | null;
  };
}[] => {
  const monday = {};
  const tuesday = {};
  const wednesday = {};
  const thursday = {};
  const friday = {};
  const saturday = {};
  const sunday = {};

  for (const [, value] of Object.entries(AppLocale)) {
    monday[`${value.locale}`] = intl.formatMessage({
      id: `general.day.${value.locale}.MONDAY`,
    });
    tuesday[`${value.locale}`] = intl.formatMessage({
      id: `general.day.${value.locale}.TUESDAY`,
    });
    wednesday[`${value.locale}`] = intl.formatMessage({
      id: `general.day.${value.locale}.WEDNESDAY`,
    });
    thursday[`${value.locale}`] = intl.formatMessage({
      id: `general.day.${value.locale}.THURSDAY`,
    });
    friday[`${value.locale}`] = intl.formatMessage({
      id: `general.day.${value.locale}.FRIDAY`,
    });
    saturday[`${value.locale}`] = intl.formatMessage({
      id: `general.day.${value.locale}.SATURDAY`,
    });
    sunday[`${value.locale}`] = intl.formatMessage({
      id: `general.day.${value.locale}.SUNDAY`,
    });
  }

  return [
    {
      id: uuidv4(),
      name: "MONDAY",
      day: monday,
      hoursRange: { items: null, total: null },
    },
    {
      id: uuidv4(),
      name: "TUESDAY",
      day: tuesday,
      hoursRange: { items: null, total: null },
    },
    {
      id: uuidv4(),
      name: "WEDNESDAY",
      day: wednesday,
      hoursRange: { items: null, total: null },
    },
    {
      id: uuidv4(),
      name: "THURSDAY",
      day: thursday,
      hoursRange: { items: null, total: null },
    },
    {
      id: uuidv4(),
      name: "FRIDAY",
      day: friday,
      hoursRange: { items: null, total: null },
    },
    {
      id: uuidv4(),
      name: "SATURDAY",
      day: saturday,
      hoursRange: { items: null, total: null },
    },
    {
      id: uuidv4(),
      name: "SUNDAY",
      day: sunday,
      hoursRange: { items: null, total: null },
    },
  ];
};

export const generateHoursRange = (
  isMorning = false
): {
  id: string;
  start: string;
  end: string;
  name: EnumHoursRangeName;
} => {
  // by default morning is 08:00 => 12:00
  // by default afternoon is 14:00 => 19:00
  return {
    id: uuidv4(),
    start: isMorning ? morning.start : afternoon.start,
    end: isMorning ? morning.end : afternoon.end,
    name: isMorning ? EnumHoursRangeName.MORNING : EnumHoursRangeName.AFTERNOON,
  };
};

export const generateExceptionalOpeningHours = (
  isMorning: boolean,
  startAt: string,
  endAt: string,
  maxId: number
): ExceptionalOpeningHour => {
  // by default morning is 08:00 => 12:00
  // by default afternoon is 14:00 => 19:00
  // The maxId is used to have a unique id for every exceptional opening hours
  return {
    id: maxId + 1,
    start: isMorning ? morning.start : afternoon.start,
    end: isMorning ? morning.end : afternoon.end,
    startAt,
    endAt,
    enabled: true,
    name: isMorning ? EnumHoursRangeName.MORNING : EnumHoursRangeName.AFTERNOON,
  };
};

export const createClosedDay = (start: Date, end: Date): ClosedDayItem => {
  const item = {
    // id: uuidv4(),
    start: start.toISOString(),
    end: end.toISOString(),
    markForDelete: false,
  };

  return {
    item,
    action: ActionTypes.TO_ADD,
  };
};

export interface DaySlotItem {
  item: DaySlot;
  action: ActionTypes;
}

export interface ExceptionalOpeningHourItem {
  item: ExceptionalOpeningHour;
  isGenerated: boolean;
  action: ActionTypes;
}

export interface HoursRangeItem {
  item: HoursRange;
  daySlot: DaySlot;
  isGenerated: boolean;
  action: ActionTypes;
}

export interface ClosedDayItem {
  item: ClosedDay;
  action: ActionTypes;
}

export const getCenterDaySlots = (
  intl: IntlShape,
  daySlots: DaySlot[]
): DaySlotItem[] => {
  const defaultDaySlots = generateDaySlots(intl);
  const values: DaySlotItem[] = [];
  defaultDaySlots.forEach(ds => {
    let slot = daySlots?.find(e => e.name === ds.name);
    if (!slot) {
      daySlots?.forEach(el => {
        const day = JSON.parse(el.day);
        const days = Object.values(day);
        if (days && days.length > 0 && days.includes(ds.name)) {
          const newslot = { ...el, name: ds.name, day: ds.day };
          slot = newslot;
        }
      });
    }
    if (slot) {
      // slot found with name
      const element: DaySlotItem = {
        item: slot,
        action: ActionTypes.TO_KEEP,
      };
      values.push(element);
    } else {
      // new slot to add
      const element: DaySlotItem = {
        item: ds,
        action: ActionTypes.TO_ADD,
      };
      values.push(element);
    }
  });

  return values;
};

export const cloneCenterDaySlots = (
  intl: IntlShape,
  daySlots: DaySlot[]
): DaySlotItem[] => {
  const centerDaySlots = getCenterDaySlots(intl, daySlots);
  const defaultDaySlots = generateDaySlots(intl);

  const values: DaySlotItem[] = [];
  defaultDaySlots.forEach((ds: DaySlot) => {
    const slot = centerDaySlots.find(e => e.item.name === ds.name);
    if (slot?.item.hoursRange?.items?.length) {
      ds.hoursRange.items = slot.item.hoursRange.items.map(item => ({
        ...item,
        id: uuidv4(),
        createdAt: null,
      }));
    }

    const element: DaySlotItem = {
      item: ds,
      action: ActionTypes.TO_ADD,
    };
    values.push(element);
  });

  return values;
};

export const cloneCenterHoursRange = (
  daySlotItems: DaySlotItem[]
): HoursRangeItem[] => {
  const values: HoursRangeItem[] = daySlotItems.reduce(
    (acc: HoursRangeItem[], daySlotItem) => {
      daySlotItem.item.hoursRange?.items?.forEach(item =>
        acc.push({
          item: {
            id: item.id,
            start: item.start,
            end: item.end,
            name: item.name,
          },
          action: ActionTypes.TO_ADD,
          isGenerated: true,
          daySlot: daySlotItem.item,
        })
      );

      return acc;
    },
    []
  );

  return values;
};

export const getCenterExceptionalOpeningHours = (
  exceptionalOpeningHours: ExceptionalOpeningHour[]
): ExceptionalOpeningHourItem[] =>
  exceptionalOpeningHours.map(exceptionalHour => ({
    item: exceptionalHour,
    isGenerated: false,
    action: ActionTypes.TO_KEEP,
  }));

export const getCenterClosedDays = (closedDays: ClosedDay[]): ClosedDayItem[] =>
  Object.values(closedDays).map(el => ({
    item: el,
    action: ActionTypes.TO_KEEP,
  }));

export const cloneCenterClosedDays = (
  closedDays: ClosedDay[]
): ClosedDayItem[] => {
  const results = closedDays.reduce((acc: ClosedDayItem[], el) => {
    if (el.start && el.end) {
      const closedDay = createClosedDay(new Date(el.start), new Date(el.end));
      acc.push(closedDay);
    }

    return acc;
  }, []);

  return results;
};

export const getSlotHoursRange = (daySlot: DaySlot): HoursRangeItem[] => {
  const defaultHoursRange = [
    generateHoursRange(true),
    generateHoursRange(false),
  ];

  const ranges: HoursRange[] = daySlot?.hoursRange?.items || [];

  // no hours range for day slot
  if (ranges.length === 0) {
    const morningHours: HoursRangeItem = {
      item: defaultHoursRange[0],
      daySlot,
      isGenerated: true,
      action: ActionTypes.TO_ADD,
    };

    const afternoonHours: HoursRangeItem = {
      item: defaultHoursRange[1],
      daySlot,
      isGenerated: true,
      action: ActionTypes.TO_ADD,
    };

    return [morningHours, afternoonHours];
  }

  const hours = ranges
    .sort((hoursRange1, hoursRange2) => {
      if (hoursRange1 && hoursRange2) {
        const start1 = dayjs(hoursRange1.start, DATE_FORMAT_HOUR);
        const start2 = dayjs(hoursRange2.start, DATE_FORMAT_HOUR);

        return isBefore(start1, start2) ? -1 : 1;
      }

      return 0;
    })
    .slice(0, 2);

  let morningRange;
  let afternoonRange;

  if (hours?.length > 1) {
    const hasMorning = hours.find(h => h.name === EnumHoursRangeName.MORNING);
    const hasAfternoon = hours.find(
      h => h.name === EnumHoursRangeName.AFTERNOON
    );
    if (hasMorning && hasAfternoon) {
      morningRange = hasMorning;
      afternoonRange = hasAfternoon;
    } else {
      [morningRange, afternoonRange] = hours;
    }
  } else {
    const range = hours[0];
    // only one range
    const name = getHoursRangeName(range.start, range.end);
    if (name === EnumHoursRangeName.MORNING) {
      morningRange = range;
    }
    if (name === EnumHoursRangeName.AFTERNOON) {
      afternoonRange = range;
    }
  }

  const morningHours: HoursRangeItem = {
    item: morningRange ?? defaultHoursRange[0],
    daySlot,
    isGenerated: !morningRange,
    action: morningRange ? ActionTypes.TO_KEEP : ActionTypes.TO_ADD,
  };

  const afternoonHours: HoursRangeItem = {
    item: afternoonRange ?? defaultHoursRange[1],
    daySlot,
    isGenerated: !afternoonRange,
    action: afternoonRange ? ActionTypes.TO_KEEP : ActionTypes.TO_ADD,
  };

  return [morningHours, afternoonHours];
};

export const getExceptionalOpeningHours = (
  exceptionalHours: ExceptionalOpeningHourItem[],
  maxId: number
): ExceptionalOpeningHourItem[] => {
  const rangeStartDate = exceptionalHours[0].item.startAt;
  const rangeEndDate = exceptionalHours[0].item.endAt;

  const defaultExceptionalHoursRange = [
    generateExceptionalOpeningHours(
      true,
      rangeStartDate,
      rangeEndDate,
      maxId + 1
    ),
    generateExceptionalOpeningHours(
      false,
      rangeStartDate,
      rangeEndDate,
      maxId + 2
    ),
  ];

  let morningRange: ExceptionalOpeningHourItem | null = null;
  let afternoonRange: ExceptionalOpeningHourItem | null = null;

  if (exceptionalHours?.length > 1) {
    const hasMorning = exceptionalHours.find(
      h => h.item.name === EnumHoursRangeName.MORNING
    );
    const hasAfternoon = exceptionalHours.find(
      h => h.item.name === EnumHoursRangeName.AFTERNOON
    );
    if (hasMorning && hasAfternoon) {
      morningRange = hasMorning;
      afternoonRange = hasAfternoon;
    } else {
      [morningRange, afternoonRange] = exceptionalHours;
    }
  } else {
    const range = exceptionalHours[0];
    // only one range
    if (range.item.name === EnumHoursRangeName.MORNING) {
      morningRange = range;
    }
    if (range.item.name === EnumHoursRangeName.AFTERNOON) {
      afternoonRange = range;
    }
  }

  const exceptionalMorning: ExceptionalOpeningHourItem = {
    item: morningRange?.item ?? defaultExceptionalHoursRange[0],
    isGenerated: morningRange ? morningRange.isGenerated : true,
    // action is set to TO_DELETE if the exceptional hour doesn't exist
    action: morningRange?.action ?? ActionTypes.TO_DELETE,
  };

  const exceptionalAfternoon: ExceptionalOpeningHourItem = {
    item: afternoonRange?.item ?? defaultExceptionalHoursRange[1],
    isGenerated: afternoonRange ? afternoonRange.isGenerated : true,
    // action is set to TO_DELETE if the exceptional hour doesn't exist
    action: afternoonRange?.action ?? ActionTypes.TO_DELETE,
  };

  return [exceptionalMorning, exceptionalAfternoon];
};

export const getDayName = (
  intl: IntlShape,
  day: string | undefined
): string => {
  const label = intl.formatMessage({ id: `page.center.day.${day}` });

  return label;
};
