import React from "react";
import classnames from "classnames";
import { Item } from "../../../utils/types";
import { getTranslation } from "../../../utils/translation";
import { getCypressTestId } from "../../../utils/config";
import IntlMessages from "../../../utils/messages";
import { injectIntl, IntlShape } from "react-intl";

interface Props {
  intl: IntlShape;
  item: { id: string } | null | undefined;
  id: string;
  name: string;
  items: Array<Item> | undefined;
  value?: string;
  placeholder?: string;
  values?: string[];
  label: string;
  index: number;
  last?: boolean;
  required?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  children?: React.ReactNode;
  className?: string;
  onChange: (e) => void;
  onBlur?: (e) => void;
  errors?: any;
  touched?: any;
  grid?: number;
  action?: React.ReactNode;
  translatable?: boolean;
  useIntl: boolean;
  description?: string;
  alternate?: boolean;
}

const Select = (props: Props): JSX.Element => {
  const {
    id,
    item,
    placeholder,
    onChange,
    index,
    label,
    useIntl,
    required,
    intl,
    name,
    grid,
    className,
    last,
    errors,
    touched,
    multiple,
    values,
    value,
    children,
    action,
    disabled,
    items,
    alternate,
  } = props;

  const clazz = grid ? `sm:grid-cols-${grid}` : "sm:grid-cols-3";

  const alter = alternate != undefined ? alternate : true;

  const containerClassName = id =>
    classnames(
      "sm:grid sm:gap-4 sm:items-start sm:py-2 sm:px-8 px-2 py-2 " + clazz,
      className,
      {
        "bg-ground-white-200": alter && id % 2 === 0,
        "rounded-b-10": last,
      }
    );

  const invalid = errors && touched ? errors[name] && touched[name] : false;

  const val = multiple ? values : value;

  const formClassName = classnames(
    "block form-select w-full transition duration-150 ease-in-out sm:text-14px placeholder-ground-gray-100",
    {
      "border-red-300 text-red-500 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red":
        invalid,
      "text-ground-black-100 placeholder-ground-black-100": !invalid,
    }
  );

  const labelClassName = classnames(
    "block text-14px font-medium leading-5 text-ground-gray-100 sm:mt-px sm:pt-2 ",
    {
      "sm:col-span-2": grid == 4,
    }
  );

  const clazz2 = grid === 4 ? "sm:col-span-2" : "sm:col-span-1";

  return (
    <div className={containerClassName(index)}>
      <label htmlFor={name} className={labelClassName}>
        {useIntl && <IntlMessages id={label} />}
        {!useIntl && `${label}`} {required && "*"}
      </label>
      <div className={`mt-1 sm:mt-0 max-w-xs ${clazz2}`}>
        <div className="rounded-md shadow-sm">
          <select
            className={formClassName}
            multiple={multiple}
            id={id}
            data-cy={name}
            data-testid={getCypressTestId(item)}
            name={name}
            value={val}
            onChange={onChange}
            placeholder={
              useIntl && placeholder
                ? intl.formatMessage({ id: placeholder })
                : placeholder
            }
            disabled={disabled}
          >
            <option key="0" value="">
              {intl.formatMessage({ id: "general.select" })}
            </option>
            {items?.map((i, index) => {
              if (i && i.id) {
                return (
                  <option key={index + 1} value={i.id}>
                    {getTranslation(i.name)}
                  </option>
                );
              }
            })}
          </select>
        </div>
        {children && val && <div>{children[1]}</div>}
        {invalid && (
          <p className="mt-2 text-11px text-red-600" id={`${name}-error`}>
            {errors[name]}
          </p>
        )}
      </div>
      {children && (
        <div className="mt-1 sm:mt-0 sm:col-span-1">{children[0]}</div>
      )}
      {action && <div className="my-auto">{action}</div>}
    </div>
  );
};

export default injectIntl(Select);
