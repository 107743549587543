import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { injectIntl, IntlShape } from "react-intl";
import contextStore from "../../../redux/store";
import { getMenuClasses } from "../../../utils/menu";
import history from "../../../history";
import { EnumPaths } from "../../../utils/navigation";
import PageSubheader from "../../../components/PageSubheader";
import Table from "../../../components/Table";
import { Enterprise } from "../../../redux/models/Enterprise";
import Dropdown from "../../../components/Tailwind/Dropdown";
import IntlMessages from "../../../utils/messages";
import ConfirmModal from "../../../utils/modal/confirm";
import images from "../../../images";

interface Props {
  intl: IntlShape;
}

const ListEnterprises = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [enterpriseDelete, setEnterpriseDelete] = useState(
    undefined as unknown as Enterprise
  );

  const { intl } = props;

  const setContainerClassnames = contextStore.useStoreActions(
    actions => actions.menu.setContainerClassnames
  );

  const menuHiddenBreakpoint = contextStore.useStoreState(
    state => state.menu.menuHiddenBreakpoint
  );

  const subHiddenBreakpoint = contextStore.useStoreState(
    state => state.menu.subHiddenBreakpoint
  );

  const listEnterprises = contextStore.useStoreActions(
    actions => actions.enterprise.listEnterprises
  );

  const enterprises = contextStore.useStoreState(
    state => state.enterprise.enterprises.items
  );

  const total = contextStore.useStoreState(
    state => state.enterprise.enterprises.total
  );

  const setEnterprise = contextStore.useStoreActions(
    actions => actions.enterprise.setEnterprise
  );

  const deleteEnterprise = contextStore.useStoreActions(
    actions => actions.enterprise.deleteEnterprise
  );

  useEffect(() => {
    fetchAllEnterprises();

    const nextClasses = getMenuClasses(
      "menu-hidden",
      menuHiddenBreakpoint,
      subHiddenBreakpoint
    );
    setContainerClassnames({ strCurrentClasses: nextClasses.join(" ") });
  }, []);

  const fetchAllEnterprises = (loader = true) => {
    if (loader) {
      setLoading(true);
    }
    listEnterprises(null).finally(() => {
      if (loader) setLoading(false);
    });
  };

  const handleAddNew = () => {
    setEnterprise(null);
    history.push(
      `/${EnumPaths.ROOT}/${EnumPaths.USERS}/${EnumPaths.ENTERPRISE}/${EnumPaths.CREATION_MODE}`
    );
  };

  const tableHead = [
    "page.list.enterprises.table.head.name",
    "navbar.menu.users",
    "general.actions",
  ];

  const handleDelete = (enterprise: Enterprise): void => {
    setEnterpriseDelete(enterprise);
    setIsModalOpen(true);
  };

  const handleModalValidate = (enterprise: Enterprise) => {
    setLoading(true);

    deleteEnterprise({ id: enterprise.id })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.enterprise.delete.success",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.enterprise.delete.error",
          }),
          { type: "error" }
        );
      })
      .finally(() => {
        setLoading(false);
        setIsModalOpen(false);
      });
  };

  const tableBody = enterprises?.map((enterprise: Enterprise) => {
    const { id, name, totalUsers } = enterprise;

    const editEnterpriseLink = `/${EnumPaths.ROOT}/${EnumPaths.USERS}/${EnumPaths.ENTERPRISE}/${id}/${EnumPaths.EDIT_MODE}`;

    const dropdownValues = [
      {
        id: "edit",
        label: "general.edit",
        icon: images.edit,
        link: editEnterpriseLink,
      },
      {
        id: "delete",
        label: "general.delete",
        icon: images.deleteIcon,
        onClick: () => handleDelete(enterprise),
      },
    ];

    return {
      rowElements: [
        {
          element: <span data-cy={`enterprise-name-${id}`}>{name}</span>,
          onCellClick: () => history.push(editEnterpriseLink),
        },
        {
          element: totalUsers > 0 ? totalUsers : "",
          onCellClick: () => history.push(editEnterpriseLink),
        },
        {
          element: (
            <Dropdown
              values={dropdownValues}
              dataCy={`enterprise-dropdown-${id}`}
            />
          ),
        },
      ],
    };
  });

  return (
    <div>
      <Helmet>
        <title>
          {intl.formatMessage({ id: "page.list.enterprises.document.title" })}
        </title>
      </Helmet>

      <PageSubheader
        title="page.list.enterprises.title"
        nbOfResults={total}
        buttonRightTitle="page.list.enterprises.add.enterprise"
        buttonRightAction={handleAddNew}
        buttonRightId="btn-add-enterprise"
      />

      <Table
        head={tableHead}
        body={tableBody}
        noDataText="page.list.enterprises.empty"
        className="px-8"
        loading={loading}
        setLoading={setLoading}
      />
      <ConfirmModal
        item={enterpriseDelete}
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(!isModalOpen)}
        onRequestClose={() => setIsModalOpen(!isModalOpen)}
        handleConfirm={() => handleModalValidate(enterpriseDelete)}
        content={<IntlMessages id="page.enterprise.delete.title" />}
      />
    </div>
  );
};

export default injectIntl(ListEnterprises);
