import React from "react";
import classnames from "classnames";
import { getImageUrl } from "../../../utils/picture";
import Avatar from "../Avatar";
import IntlMessages from "../../../utils/messages";
import { Authorization } from "../../../lib/ground-aws-graphql-core/models/Authorization";
import { EnumPermissionEntity } from "../../../lib/ground-aws-graphql-core/api/graphql/types";

interface Props {
  authorization: Authorization;
  onRemoveAuthorization: (a: Authorization) => void;
}

const Admin = (props: Props): JSX.Element => {
  const className = classnames(
    "mt-2 sm:mt-0 sm:col-span-1 rounded-10 border h-full opacity-1 bg-white border-ground-gray-200"
  );

  const { authorization } = props;
  const { user } = authorization;

  return (
    <div className={className} key={authorization.id}>
      <div className="relative flex px-2 py-2">
        <Avatar
          picture={
            user?.picture
              ? getImageUrl(EnumPermissionEntity.BACK_OFFICE_USER, user.picture)
              : null
          }
          notification
          enabled={user.enabled || false}
        />
        <div className="flex flex-col ml-2 justify-around">
          <span className="text-14px text-ground-gray-100">
            {user.lastname} {user.firstname}
          </span>
          <span className="text-12px text-ground-gray-100">{user.email}</span>
          <span
            className="text-10px text-ground-gray-400 cursor-pointer z-10"
            onClick={() => props.onRemoveAuthorization(authorization)}
          >
            <IntlMessages id="general.delete" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Admin;
