import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import {
  getRefundReason,
  isFree,
  isInvoicePayment,
  isRefundRequestedOrRefunded,
} from "../../../../../../utils/order";
import IntlMessages from "../../../../../../utils/messages";
import { Order } from "../../../../../../lib/ground-aws-graphql-core/models/Order";
import { OrderItem } from "../../../../../../lib/ground-aws-graphql-core/models/OrderItem";
import { EnumOrderStatus } from "../../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import FilterSelect from "../../../../../../components/Tailwind/Filters/select";
import {
  EnumFilterFields,
  EnumFilterType,
} from "../../../../../../utils/filter";
import { displayDayDDMMYYYY_HHMM } from "../../../../../../utils/config";
import { GroundGraphqlContextStore } from "../../../../../../lib/ground-aws-graphql-core";
import { IOperator } from "../../../../../../lib/ground-aws-cognito-auth-core/models/Authentication";
import { GlobalOrder } from "../../../../../../lib/ground-aws-graphql-core/models/GlobalOrder";

interface Status {
  label: string;
  nextStatus: NextStatus[];
}

export interface NextStatus {
  value: EnumOrderStatus;
  label: string;
  disabled?: boolean;
}

export const getStatusLabel = (intl: IntlShape, status: string): string => {
  switch (status) {
    case "awaiting_validation":
    case "confirmed":
      return intl.formatMessage({ id: `page.order.${status}` });
    case EnumOrderStatus.AWAITING_PAYMENT:
    case EnumOrderStatus.PAID:
    case EnumOrderStatus.PROCESSING:
    case EnumOrderStatus.COMPLETED:
    case EnumOrderStatus.CANCELED:
    case EnumOrderStatus.REFUND_REQUESTED:
    case EnumOrderStatus.REFUNDED:
      return intl.formatMessage({ id: `page.list.orders.status.${status}` });
    default:
      return intl.formatMessage({ id: "general.all" });
  }
};

export const getStatusOptions = (
  intl: IntlShape,
  status: EnumOrderStatus | null | undefined,
  setPaidLabelToConfirmed: boolean,
  canRefund: boolean,
  canPay: boolean
): Status => {
  switch (status) {
    case EnumOrderStatus.AWAITING_PAYMENT:
      return {
        label: getStatusLabel(intl, EnumOrderStatus.AWAITING_PAYMENT),
        nextStatus: [
          {
            value: EnumOrderStatus.AWAITING_PAYMENT,
            label: setPaidLabelToConfirmed
              ? getStatusLabel(intl, "awaiting_validation")
              : getStatusLabel(intl, EnumOrderStatus.AWAITING_PAYMENT),
          },
          ...(canPay
            ? [
                {
                  value: EnumOrderStatus.PAID,
                  label: setPaidLabelToConfirmed
                    ? getStatusLabel(intl, "confirmed")
                    : getStatusLabel(intl, EnumOrderStatus.PAID),
                },
              ]
            : []),
          {
            value: EnumOrderStatus.CANCELED,
            label: getStatusLabel(intl, EnumOrderStatus.CANCELED),
          },
        ],
      };
    case EnumOrderStatus.PAID:
      return {
        label: getStatusLabel(intl, EnumOrderStatus.PAID),
        nextStatus: [
          {
            value: EnumOrderStatus.PAID,
            label: setPaidLabelToConfirmed
              ? getStatusLabel(intl, "confirmed")
              : getStatusLabel(intl, EnumOrderStatus.PAID),
          },
          {
            value: EnumOrderStatus.PROCESSING,
            label: getStatusLabel(intl, EnumOrderStatus.PROCESSING),
          },
          {
            value: EnumOrderStatus.COMPLETED,
            label: getStatusLabel(intl, EnumOrderStatus.COMPLETED),
          },
          {
            value: EnumOrderStatus.CANCELED,
            label: getStatusLabel(intl, EnumOrderStatus.CANCELED),
          },
          ...(canRefund
            ? [
                {
                  value: EnumOrderStatus.REFUNDED,
                  label: getStatusLabel(intl, EnumOrderStatus.REFUNDED),
                },
              ]
            : []),
        ],
      };
    case EnumOrderStatus.PROCESSING:
      return {
        label: getStatusLabel(intl, EnumOrderStatus.PROCESSING),
        nextStatus: [
          {
            value: EnumOrderStatus.PROCESSING,
            label: getStatusLabel(intl, EnumOrderStatus.PROCESSING),
          },
          {
            value: EnumOrderStatus.COMPLETED,
            label: getStatusLabel(intl, EnumOrderStatus.COMPLETED),
          },
          {
            value: EnumOrderStatus.CANCELED,
            label: getStatusLabel(intl, EnumOrderStatus.CANCELED),
          },
          ...(canRefund
            ? [
                {
                  value: EnumOrderStatus.REFUNDED,
                  label: getStatusLabel(intl, EnumOrderStatus.REFUNDED),
                },
              ]
            : []),
        ],
      };
    case EnumOrderStatus.COMPLETED:
      return {
        label: getStatusLabel(intl, EnumOrderStatus.COMPLETED),
        nextStatus: [
          {
            value: EnumOrderStatus.COMPLETED,
            label: getStatusLabel(intl, EnumOrderStatus.COMPLETED),
          },
          {
            value: EnumOrderStatus.CANCELED,
            label: getStatusLabel(intl, EnumOrderStatus.CANCELED),
          },
          ...(canRefund
            ? [
                {
                  value: EnumOrderStatus.REFUNDED,
                  label: getStatusLabel(intl, EnumOrderStatus.REFUNDED),
                },
              ]
            : []),
        ],
      };
    case EnumOrderStatus.CANCELED:
      return {
        label: getStatusLabel(intl, EnumOrderStatus.CANCELED),
        nextStatus: [
          {
            value: EnumOrderStatus.CANCELED,
            label: getStatusLabel(intl, EnumOrderStatus.CANCELED),
          },
          ...(canRefund
            ? [
                {
                  value: EnumOrderStatus.REFUNDED,
                  label: getStatusLabel(intl, EnumOrderStatus.REFUNDED),
                },
              ]
            : []),
        ],
      };
    case EnumOrderStatus.REFUND_REQUESTED:
      return {
        label: getStatusLabel(intl, EnumOrderStatus.REFUND_REQUESTED),
        nextStatus: [
          {
            value: EnumOrderStatus.REFUND_REQUESTED,
            label: getStatusLabel(intl, EnumOrderStatus.REFUND_REQUESTED),
          },
        ],
      };
    case EnumOrderStatus.REFUNDED:
      return {
        label: getStatusLabel(intl, EnumOrderStatus.REFUNDED),
        nextStatus: [
          {
            value: EnumOrderStatus.REFUNDED,
            label: getStatusLabel(intl, EnumOrderStatus.REFUNDED),
          },
        ],
      };
    default:
      return {
        label: status || "",
        nextStatus: [],
      };
  }
};

export const getStatus = (
  intl: IntlShape,
  status: EnumOrderStatus | null | undefined, // current status
  setPaidLabelToConfirmed: boolean,
  canRefund: boolean,
  order: Order,
  orderItem?: OrderItem
): Status => {
  let canPay = true;
  if (orderItem && orderItem.status === EnumOrderStatus.AWAITING_PAYMENT) {
    const orderItems = order.orderItems?.items;
    canPay = orderItems?.length === 1;
  }

  // status options
  const { label, nextStatus } = getStatusOptions(
    intl,
    status,
    setPaidLabelToConfirmed,
    canRefund,
    canPay
  );

  return {
    label,
    nextStatus: nextStatus.map(o => ({
      ...o,
      disabled: isFilterSelectDisabled(
        { label, nextStatus },
        order,
        orderItem,
        o
      ),
    })),
  };
};

export const getCurrentStatus = (
  intl: IntlShape,
  order: Order,
  orderItem: OrderItem | undefined,
  status: EnumOrderStatus | null | undefined,
  setPaidLabelToConfirmed: boolean,
  canRefund: boolean
): NextStatus | undefined => {
  const value: Status = getStatus(
    intl,
    status,
    setPaidLabelToConfirmed,
    canRefund,
    order,
    orderItem
  );

  const { nextStatus } = value;
  const result = nextStatus.find(e => e.value === status);

  return result;
};

export const getOptionsStatus = (
  intl: IntlShape,
  paymentExternalized: boolean,
  preOrdering: boolean,
  status: EnumOrderStatus | null | undefined,
  order: Order,
  orderItem?: OrderItem
): {
  current: NextStatus | undefined;
  options: Status;
} => {
  const free = isFree(order, orderItem);
  const invoicePayment = isInvoicePayment(order, paymentExternalized);

  const canRefund = !(preOrdering && paymentExternalized);
  const setPaidLabelToConfirmed = free || invoicePayment;

  const current = getCurrentStatus(
    intl,
    order,
    orderItem,
    status,
    setPaidLabelToConfirmed,
    canRefund
  );

  const options = getStatus(
    intl,
    status,
    setPaidLabelToConfirmed,
    canRefund,
    order,
    orderItem
  );

  if (free || invoicePayment) {
    const nextStatus = options.nextStatus.reduce(
      (acc: NextStatus[], el: NextStatus) => {
        if (!isRefundRequestedOrRefunded(el.value)) {
          acc.push(el);
        }

        return acc;
      },
      []
    );

    options.nextStatus = nextStatus;
  }

  return { current, options };
};

export const isFilterSelectDisabled = (
  options: Status,
  order: Order,
  orderItem?: OrderItem,
  option?: NextStatus
): boolean => {
  const { nextStatus } = options;
  const othersStatus = nextStatus.length - 1 > 0;

  const orderItems = order.orderItems?.items;
  const oneOrderItemInOrder = orderItems?.length === 1;
  const allOrderItemsStatus = [...new Set(orderItems.map(i => i.status))];

  const orderItemsHasDistinctStatus = allOrderItemsStatus.length > 1;

  if (orderItem) {
    // drop down order item
    const orderItemInAwaitPayment =
      orderItem.status === EnumOrderStatus.AWAITING_PAYMENT;

    if (orderItemInAwaitPayment) {
      const paidOption = option?.value === EnumOrderStatus.PAID;

      const enable1 = paidOption && oneOrderItemInOrder;
      const enable2 = !paidOption;

      // disabled swith if order has others order items
      return !(enable1 || enable2);
    } else {
      if (oneOrderItemInOrder) {
        // drop down order item (same as order)
        return orderItemsHasDistinctStatus ? true : !othersStatus;
      } else {
        return !othersStatus;
      }
    }
  } else {
    // drop down order
    return orderItemsHasDistinctStatus ? true : !othersStatus;
  }
};

interface Props {
  operator: IOperator | null | undefined;
  globalOrder: GlobalOrder;
  order: Order;
  status: EnumOrderStatus | null | undefined;
  onChangeOrderStatus: (e) => void;
  displayRefundReason: boolean;
  intl: IntlShape;
  orderItem?: OrderItem;
  dropdownTitle?: string;
  className?: string;
}

const DropDownStatus = (props: Props) => {
  const {
    operator,
    globalOrder,
    order,
    status,
    onChangeOrderStatus,
    displayRefundReason,
    intl,
    orderItem,
    className,
  } = props;

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const { current, options } = getOptionsStatus(
    intl,
    operator?.payment_externalized || false,
    operator?.pre_ordering || false,
    status,
    order,
    orderItem
  );

  const disabled = options.nextStatus.every(s => s.disabled === true);

  console.log({
    "disabled::": disabled,
    ", current::": current,
    ": options:": options,
  });

  const refund = orderItem?.refund;

  const itemId = orderItem ? orderItem.id : order.id;
  const itemName = orderItem
    ? "order-items-status-selector"
    : "order-status-selector";

  const field = {
    value: EnumFilterFields[EnumFilterFields.STATUS],
    type: EnumFilterType.SELECT,
    async: false,
    options: options.nextStatus.map(o => ({
      ...o,
      value: `${o.value}`,
    })),
  };

  return (
    <div className={`flex flex-col ${className} w-full`} key={itemId}>
      <div id={itemId} data-cy={itemName} data-testid={itemId}>
        <FilterSelect
          selectedValue={current?.value}
          defaultValue={false}
          disabled={disabled}
          className="w-48"
          fields={[field]}
          onChange={(_type, target) => {
            return onChangeOrderStatus(target);
          }}
        />
      </div>
      {current &&
        displayRefundReason &&
        refund &&
        isRefundRequestedOrRefunded(current.value) && (
          <div className="flex flex-col justify-end w-full mt-2">
            <span className="text-12px text-ground-gray-100">
              <IntlMessages id="page.order.refunded.at" />{" "}
              {displayDayDDMMYYYY_HHMM(refund.date, centerTimezone)}
            </span>
            <span className="text-12px text-ground-gray-100">
              <IntlMessages id="general.reason" />:{" "}
              {getRefundReason(intl, refund.reason)?.label}
            </span>
          </div>
        )}
    </div>
  );
};

export default injectIntl(DropDownStatus);
