import axiosBackend from "../../../../../axiosBackend";
import {
  AddOptionsOpts,
  CreateCartOpts,
  DeleteCartItemOpts,
  DeleteCartOpts,
  FinalizeCartOpts,
  GetCartOpts,
  UpdateCartItemOpts,
} from "../../../models/Api/Cart";

/**
 *
 * @param {GetCartOpts} payload
 * @returns
 */
export const getCart = async (payload: GetCartOpts) => {
  return axiosBackend.get(`/carts/${payload.id}`).catch(error => {
    return error.response;
  });
};

/**
 *
 * @param {DeleteCartOpts} payload
 * @returns
 */
export const deleteCart = async (payload: DeleteCartOpts) => {
  return axiosBackend.delete(`/carts/${payload.id}`).catch(error => {
    return error.response;
  });
};

/**
 *
 * @param {CreateCartOpts} payload
 * @returns
 */
export const createCart = async (payload: CreateCartOpts) => {
  return axiosBackend.post("/admin/carts", payload).catch(error => {
    return error.response;
  });
};

/**
 *
 * @param {AddOptionsOpts} payload
 * @returns
 */
export const addOptions = async (payload: AddOptionsOpts) => {
  const { cartId, itemId, ...rest } = payload;

  return axiosBackend
    .post(`/carts/${cartId}/items/${itemId}/options`, rest)
    .catch(error => {
      return error.response;
    });
};

/**
 *
 * @param {UpdateCartItemOpts} payload
 * @returns
 */
export const updateCartItem = async (payload: UpdateCartItemOpts) => {
  const { cartId, cartItemId, ...rest } = payload;

  return axiosBackend
    .put(`/carts/${cartId}/items/${cartItemId}`, rest)
    .catch(error => {
      return error.response;
    });
};

/**
 *
 * @param {DeleteCartItemOpts} payload
 * @returns
 */
export const deleteCartItem = async (payload: DeleteCartItemOpts) => {
  const { cartId, cartItemId } = payload;

  return axiosBackend
    .delete(`/carts/${cartId}/items/${cartItemId}`)
    .catch(error => {
      return error.response;
    });
};

/**
 *
 * @param {FinalizeCartOpts} payload
 * @returns
 */
export const finalizeCart = async (payload: FinalizeCartOpts) => {
  const { cartId, ...rest } = payload;

  return axiosBackend.post(`/carts/${cartId}/finalize`, rest).catch(error => {
    return error.response;
  });
};
