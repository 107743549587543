import React from "react";

interface Props {
  id: string;
  title: string;
  deleteCondition?: boolean;
  handleDelete?: () => void;
}

const Tag = (props: Props): JSX.Element => {
  const { id, title, deleteCondition, handleDelete } = props;

  return (
    <span className="inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium leading-5 bg-gray-100 text-gray-900 mr-2">
      {title}
      {deleteCondition && handleDelete && (
        <button
          id={id}
          name="btn-delete-tag"
          data-cy="btn-delete-tag"
          type="button"
          className="flex-shrink-0 -mr-0.5 ml-1.5 inline-flex text-gray-700 focus:outline-none focus:text-gray-900"
          onClick={handleDelete}
        >
          <svg
            style={{ height: "0.6rem", width: "0.6rem" }}
            stroke="currentColor"
            fill="none"
            viewBox="0 0 8 8"
          >
            <path
              strokeLinecap="round"
              strokeWidth="0.7"
              d="M1 1l6 6m0-6L1 7"
            />
          </svg>
        </button>
      )}
    </span>
  );
};

export default Tag;
