import React, { useState, useEffect } from "react";
import { match as Match, withRouter } from "react-router-dom";
import { FormattedPlural } from "react-intl";
import compose from "recompose/compose";
import ReactModal from "react-modal";
import classnames from "classnames";
import {
  ActionTypes,
  getAsOptionTypologiesFilter,
  isConsumable,
} from "../../../../../utils/types";
import IntlMessages from "../../../../../utils/messages";
import Button from "../../../../../components/Tailwind/Button";
import { EnumUnit } from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import { Product } from "../../../../../lib/ground-aws-graphql-core/models/Product";
import Table from "../../../../../components/Table";
import { getImageUrl } from "../../../../../utils/picture";
import { getTranslation } from "../../../../../utils/translation";
import Tag from "../../../../../components/Tag";
import { getFormattedPriceUnit } from "../../../../../utils/price-unit";

interface Props {
  match: Match<{ cid: string }>;
  title: string;
  description: string;
  isOpen: boolean;
  onRequestClose: (e: any) => void;
  onAddOption: (o: Product) => void;
  onRemoveOption: (o: Product) => void;
  items: { id: string; option: Product; action: ActionTypes }[];
}

const LIMIT = 20;

const ModalAddOption = (props: Props) => {
  const [loading, setLoading] = useState(false);

  const {
    isOpen,
    onRequestClose,
    onAddOption,
    onRemoveOption,
    title,
    description,
    items,
    match,
  } = props;

  const searchProducts = GroundGraphqlContextStore.useStoreActions(
    actions => actions.product.searchProducts
  );

  const options = GroundGraphqlContextStore.useStoreState(
    state => state.product.products.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.product.products.total
  );

  // options not to delete
  const elements = items.filter(i => i.action !== ActionTypes.TO_DELETE);

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  const handleClick = option => {
    // find option in elements
    const item = items.find(
      i => i.option.id === option.id && i.action !== ActionTypes.TO_DELETE
    );
    if (!item) {
      // add option
      onAddOption(option);
    }
  };

  const fetchData = (pageIndex = 0, loader = true) => {
    if (loader) {
      setLoading(true);
    }

    // consumables, service spaces and prestation can be options
    searchProducts({
      buildingId: match.params.cid,
      filter: {
        isOption: { eq: true },
        or: getAsOptionTypologiesFilter(),
      },
      from: pageIndex * LIMIT,
      limit: LIMIT,
    }).finally(() => setLoading(false));
  };

  const thead = [
    "general.service",
    "general.category",
    "page.product.quantity",
    "general.amount.ht",
  ];

  const classNameEnabled = (option, provider) =>
    classnames(
      "absolute bottom-0 right-0 block h-2 w-2 rounded-full text-white shadow-solid",
      {
        "bg-red-400": !option.enabled && provider?.enabled,
        "bg-green-400": option.enabled && provider?.enabled,
        "bg-black-400": !option.enabled && !provider?.enabled,
        "bg-purple-400": option.enabled && !provider?.enabled,
      }
    );

  const tbody = options?.map(option => {
    const { pictures, name, provider, sku, service } = option;
    const picture = pictures?.length ? pictures[0] : null;
    const priceUnits = option.priceUnits?.items?.filter(
      e => e.unit === EnumUnit.UNIT
    );

    return {
      rowElements: [
        {
          element: (
            <div className="flex">
              <span className="inline-block relative">
                <img
                  src={getImageUrl(null, picture, 50, 50)}
                  alt={getTranslation(name)}
                />
                <span className={classNameEnabled(option, provider)} />
              </span>
              <div className="flex flex-col ml-3 justify-around">
                <span className="text-14px text-ground-black-100">
                  {getTranslation(name)}
                </span>
                <span className="text-14px text-ground-gray-100">#{sku}</span>
              </div>
            </div>
          ),
          onCellClick: () => handleClick(option),
        },
        {
          element: <span>{getTranslation(service?.category?.name)}</span>,
          onCellClick: () => handleClick(option),
        },
        {
          element: (
            <span>{isConsumable(option.typology) ? option.stock : "-"}</span>
          ),
          onCellClick: () => handleClick(option),
        },
        {
          element: (
            <div className="flex flex-col items-end">
              {priceUnits?.map(p => {
                return (
                  <span
                    key={p.id}
                    className="px-2 inline-flex text-14px text-ground-gray-100"
                  >
                    <IntlMessages
                      id="page.product.modal.option.price"
                      values={{
                        variation: getTranslation(p.variation.name),
                        price: getFormattedPriceUnit(p),
                      }}
                    />
                  </span>
                );
              })}
            </div>
          ),
          onCellClick: () => handleClick(option),
        },
      ],
    };
  });

  return (
    <ReactModal
      ariaHideApp={false}
      style={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={onRequestClose}
    >
      {loading && <div className="loading" />}
      {!loading && (
        <div className="py-6">
          <div className="pb-6">
            <h3 className="text-16px text-ground-black-100">
              <IntlMessages id={title} />
            </h3>
            {description && (
              <p className="mt-1 text-13px text-ground-gray-100">
                <IntlMessages id={description} />
              </p>
            )}
          </div>
          <Table
            head={thead}
            body={tbody}
            onChange={({ pageIndex }) => {
              fetchData(pageIndex, false);
            }}
            loading={loading}
            paginationLimit={LIMIT}
            paginationTotal={total}
            noDataText="page.product.modal.list.options.empty"
          />
          <div className="flex py-2 px-8">
            <span>
              <IntlMessages
                id="page.product.modal.options.selection.total"
                values={{ total: elements.length }}
              />{" "}
              <FormattedPlural
                value={elements.length}
                one={
                  <IntlMessages id="page.product.modal.options.selection.one" />
                }
                other={
                  <IntlMessages id="page.product.modal.options.selection.many" />
                }
              />
            </span>
          </div>
          {elements.length > 0 && (
            <div className="px-8">
              {elements.map((item, index) => (
                <Tag
                  key={`${item.option.id}_${index}`}
                  id={item.option.id}
                  title={getTranslation(item.option.name)}
                  deleteCondition
                  handleDelete={() => onRemoveOption(item.option)}
                />
              ))}
            </div>
          )}
          <div className="flex ml-6 py-6">
            <Button
              id="btn-add-option"
              name="btn-add-option"
              item={null}
              type="button"
              onClick={e => onRequestClose(e)}
            >
              <span className="text-center">
                <IntlMessages id="general.validate.selection" />
              </span>
            </Button>
          </div>
        </div>
      )}
    </ReactModal>
  );
};

const enhance = compose(withRouter);

export default enhance(ModalAddOption);
