import React from "react";
import IntlMessages from "../../../utils/messages";
import images from "../../../images";
import cls from "classnames";

interface ChildProps {
  text: string;
  index: number;
  isActive?: boolean;
  counter?: number | undefined;
  onClick?: (e) => void;
  icon?: string;
  newIcon?: string;
}

const ChildNavItem = (props: ChildProps): JSX.Element => {
  const { text, index, isActive, counter, onClick, icon, newIcon } = props;

  const theClassName = cls(
    "pl-7 py-2 pr-10 cursor-pointer group flex items-center font-semibold leading-5 text-gray-900 text-13px focus:outline-none focus:bg-gray-100 hover:text-gray-900 hover:bg-gray-200 transition ease-in-out duration-150",
    {
      "text-gray-500 bg-gradient-to-r from-ground-gradiant-100 border-r-4 border-ground-blue-100":
        isActive,
    }
  );

  return (
    <a
      id={text}
      data-cy={text}
      data-testid={index}
      onClick={onClick}
      className={theClassName}
    >
      {icon && <img src={images[icon]} alt="icon sidebar" />}
      <span className="truncate px-7">
        <IntlMessages id={text} />
        {counter && counter > 0 && (
          <span className="ml-4 inline-block py-0.5 px-3 text-xs text-white leading-4 rounded-full bg-red-500 transition ease-in-out duration-150">
            {counter}
          </span>
        )}
      </span>
      {newIcon && <img src={images[newIcon]} alt="" />}
    </a>
  );
};

export default ChildNavItem;
