import React, { useRef } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { getLocale } from "../../../../lang";
import contextStore from "../../../../redux/store";
import IntlMessages from "../../../../utils/messages";
import { endOfDay, getTodayInCenterTimezone } from "../../../../utils/config";
import { ZonedDatePicker } from "../../../../components/ZonedDatePicker";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";

export interface DeliverySchedule {
  date: Date;
  delivery: DeliveryScheduleOptions;
}

interface Props {
  deliverySchedule: DeliverySchedule;
  onChange: (deliverySchedule: DeliverySchedule) => void;
  intl: IntlShape;
}

export enum DeliveryScheduleOptions {
  IMMEDIATELY = "IMMEDIATELY",
  SPECIFIC_TIME = "SPECIFIC_TIME",
}

const DeliveryScheduleComponent = (props: Props): JSX.Element => {
  const { deliverySchedule, onChange } = props;

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const { current: choice } = useRef<DeliveryScheduleOptions>(
    deliverySchedule.delivery
  );

  const onChoiceChanged = (
    _e: React.ChangeEvent<HTMLInputElement>,
    value: DeliveryScheduleOptions
  ) => {
    onChange({
      ...deliverySchedule,
      delivery: value,
      date: getTodayInCenterTimezone(centerTimezone),
    });
  };

  const handleChangeDate = (date: Date) => {
    onChange({
      ...deliverySchedule,
      date,
    });
  };

  const renderDeliveryScheduleChoice = (
    onInputChange: (
      e: React.ChangeEvent<HTMLInputElement>,
      value: DeliveryScheduleOptions
    ) => void
  ) => (
    <div className="flex items-center py-2 justify-evenly">
      <label className="flex items-center mb-0" htmlFor="f_immediately_choice">
        <input
          type="radio"
          id="f_immediately_choice"
          name="f_immediately_choice"
          value={DeliveryScheduleOptions.IMMEDIATELY}
          className="mr-3"
          onChange={e => onInputChange(e, DeliveryScheduleOptions.IMMEDIATELY)}
          checked={choice === DeliveryScheduleOptions.IMMEDIATELY}
        />
        <IntlMessages id="page.notification.delivery.immediately" />
      </label>

      <label
        className="flex items-center mb-0"
        htmlFor="f_specific_time_choice"
      >
        <input
          type="radio"
          id="f_specific_time_choice"
          name="f_specific_time_choice"
          value={DeliveryScheduleOptions.SPECIFIC_TIME}
          className="mr-3"
          onChange={e =>
            onInputChange(e, DeliveryScheduleOptions.SPECIFIC_TIME)
          }
          checked={choice === DeliveryScheduleOptions.SPECIFIC_TIME}
        />
        <IntlMessages id="page.notification.delivery.specific.time" />
      </label>
    </div>
  );

  const { locale } = contextStore.useStoreState(({ settings }) => settings);
  const currentAppLocale = getLocale(locale);

  const now = getTodayInCenterTimezone(centerTimezone);
  const maxTime = endOfDay(now);

  const renderSpecificTime = () => {
    return (
      <div>
        <ZonedDatePicker
          selected={deliverySchedule.date}
          onChange={handleChangeDate}
          locale={currentAppLocale.date}
          showTimeSelect
          dateFormat="Pp"
          timeIntervals={1}
          startDate={now}
          minDate={now}
          minTime={now}
          maxTime={maxTime}
        />
      </div>
    );
  };

  return (
    <div className="grid gap-2" key={`${choice}`}>
      {renderDeliveryScheduleChoice(onChoiceChanged)}
      {choice === DeliveryScheduleOptions.SPECIFIC_TIME && renderSpecificTime()}
    </div>
  );
};

export default injectIntl(DeliveryScheduleComponent);
