import React from "react";
import classnames from "classnames";
import { ButtonProps } from "../index";
import { getCypressTestId } from "../../../../utils/config";

// @deprecated : remove this and rework ./index

const Button = (props: ButtonProps): JSX.Element => {
  const padding = props.small ? "px-2 py-1" : "px-4 py-2";
  const className = classnames(
    "inline-flex items-center " +
      padding +
      " leading-5 font-medium rounded-5 focus:outline-none transition ease-in-out duration-150",
    {
      "border border-ground-blue-100": props.outline,
      "border-transparent text-white bg-gray-200 cursor-default":
        props.disabled,
    },
    props.className
  );

  return (
    <button
      id={props.id}
      data-cy={props.name}
      data-testid={getCypressTestId(props.item)}
      name={props.name}
      type={props.type}
      onClick={props.onClick}
      className={className}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export default Button;
