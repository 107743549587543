import axios from "axios";
import history from "./history";
import { Token as token } from "./lib/ground-aws-graphql-core/store/provider";
import { EnumPaths } from "./utils/navigation";
import { asyncLocalStorage } from "./utils/token";

const axiosBackend = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
  withCredentials: true,
  headers: { "Content-Type": "application/json", Accept: "application/json" },
});

const signOut = () => {
  asyncLocalStorage.clear().then(() => {
    history.push(`/${EnumPaths.SIGNIN}`);
  });
};

// Request interceptors for API calls
// Needed because Safari doesn't allow third-party cookies
axiosBackend.interceptors.request.use(config => {
  const configWithAuthorization = { ...config };

  if (token) configWithAuthorization.headers.Authorization = `Bearer ${token}`;

  return configWithAuthorization;
});

// Response interceptors for API calls
axiosBackend.interceptors.response.use(undefined, error => {
  if (
    error.response?.status === 401 &&
    (error.response?.data?.error?.error_code === "RefreshTokenException" ||
      error.response?.data?.error_code === "UnauthorizedException")
  ) {
    signOut();
  }

  return Promise.reject(error);
});

export default axiosBackend;
