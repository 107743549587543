import React, { forwardRef } from "react";
import images from "../../../../images";

interface Props {
  value?: string;
  onClick?: (e: any) => void;
}

const DatePickerInput = ({ value, onClick }: Props): JSX.Element => (
  <button
    className="bg-transparent text-ground-blue-100 text-14px leading-5 flex items-center"
    onClick={onClick}
    type="button"
  >
    <span className="px-2">{value}</span>
    <img alt="down" src={images.chevronDown} />
  </button>
);

export default forwardRef(DatePickerInput);
