/*
 *
 * Unavailability Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import { searchUnavailabilities as searchUnavailabilitiesQuery } from "../../api/graphql/queries";

import { fetchPolicy } from "../../api/client";
import { SearchUnavailabilitiesOpts } from "../../models/Unavailability";

const DEFAULT_LIMIT = 100;

export const searchUnavailabilities = (payload: SearchUnavailabilitiesOpts) => {
  const request = payload.gql ? payload.gql : searchUnavailabilitiesQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: {
      filter: payload.filter,
      sort: payload.sort,
      limit: payload?.limit || DEFAULT_LIMIT,
      from: payload?.from,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};
