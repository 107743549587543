import React from "react";
import classnames from "classnames";
import { getCypressTestId } from "../../../utils/config";
import IntlMessages from "../../../utils/messages";
import { injectIntl, IntlShape } from "react-intl";
import contextStore from "../../../redux/store";
import { getSupportedLocales } from "../../../lang";
import { useTranslatableField } from "../../../utils/locale";
import LocaleSwitcher from "../../LocaleSwitcher";

interface Props {
  intl: IntlShape;
  item: { id: string } | null | undefined;
  id: string;
  name: string;
  rows: number;
  value: string;
  label: string;
  placeholder?: string;
  description?: string;
  index: number;
  last?: boolean;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  children?: React.ReactNode;
  onChange: (e) => void;
  onBlur?: (e) => void;
  errors?: any;
  touched?: any;
  grid?: number;
  translatable?: boolean;
  useIntl: boolean;
  alternate?: boolean;
}

const TextArea = (props: Props): JSX.Element => {
  const {
    id,
    name,
    translatable,
    onChange,
    value,
    grid,
    last,
    errors,
    touched,
    useIntl,
    required,
    placeholder,
    disabled,
    description,
    index,
    intl,
    item,
    label,
    rows,
    alternate,
  } = props;

  const locale = contextStore.useStoreState(state => state.settings.locale);

  const alter = alternate != undefined ? alternate : true;

  const {
    value: val,
    displayedValue,
    currentLocale,
    setCurrentLocale,
    onChange: onChg,
  } = useTranslatableField(!!translatable, value, onChange, locale);

  const className = grid ? `sm:grid-cols-${grid}` : "sm:grid-cols-3";

  const containerClassName = id =>
    classnames(
      "sm:grid sm:gap-4 sm:items-start sm:py-2 sm:px-8 px-2 py-2 " + className,
      {
        "bg-ground-white-200": alter && id % 2 === 0,
        "rounded-b-10": last,
      }
    );

  const invalid = errors[name] && touched[name];

  const formClassName = classnames(
    "form-textarea block w-full transition duration-150 ease-in-out sm:text-14px sm:leading-5 placeholder-ground-gray-100",
    {
      "border-red-300 text-red-500 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red":
        invalid,
      "text-ground-black-100 placeholder-ground-black-100": !invalid,
    }
  );

  return (
    <div className={containerClassName(index)}>
      <label
        htmlFor={name}
        className="block text-14px font-medium leading-5 text-ground-gray-100 sm:mt-px sm:pt-2 "
      >
        {useIntl && <IntlMessages id={label} />}
        {!useIntl && `${label}`} {required && "*"}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="max-w-lg flex rounded-md shadow-sm">
          <textarea
            className={formClassName}
            id={id}
            data-cy={name}
            data-testid={getCypressTestId(item)}
            name={name}
            rows={rows}
            placeholder={
              useIntl
                ? intl.formatMessage({ id: `${placeholder}` })
                : placeholder
            }
            value={displayedValue}
            disabled={disabled}
            onChange={onChg}
          />
        </div>
        {translatable && (
          <LocaleSwitcher
            currentLocale={currentLocale}
            setCurrentLocale={setCurrentLocale}
            locales={getSupportedLocales()}
            value={val}
          />
        )}
        {description && (
          <p className="mt-2 text-14px text-gray-500">{description}</p>
        )}
        {invalid && (
          <p className="mt-2 text-11px text-red-600" id={`${name}-error`}>
            {errors[name]}
          </p>
        )}
      </div>
    </div>
  );
};

export default injectIntl(TextArea);
