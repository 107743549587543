import React, { ReactNode } from "react";
import Transition from "../../Transition";
import ButtonPrimary from "../Button/Primary";
import ButtonSecondary from "../Button/Secondary";

type ModalProps = {
  isOpen: boolean;
  title?: string | ReactNode;
  text?: string | ReactNode;
  buttonValidate: string | ReactNode;
  buttonCancel: string | ReactNode;
  icon?: ReactNode;
  hasOverlay?: boolean;
  onClickValidate: () => void;
  onClickCancel: () => void;
};

const Modal = ({
  isOpen,
  title,
  text,
  buttonValidate,
  buttonCancel,
  icon,
  hasOverlay = false,
  onClickValidate,
  onClickCancel,
}: ModalProps): JSX.Element => {
  return (
    <div className="fixed bottom-0 inset-x-0 px-4 pb-4 sm:flex sm:items-center sm:justify-center">
      {hasOverlay && (
        <Transition
          show={isOpen}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-white opacity-75"></div>
          </div>
        </Transition>
      )}
      <Transition
        show={isOpen}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          className="bg-ground-black-100 rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {(icon || title) && (
            <div className="bg-ground-black-100 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              {icon && (
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    {icon}
                  </div>
                </div>
              )}
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                {title && (
                  <h3
                    className="text-lg leading-6 font-medium text-white"
                    id="modal-headline"
                  >
                    {title}
                  </h3>
                )}
              </div>
            </div>
          )}
          <div className="bg-gray-50 px-4 py-6 sm:px-6 sm:flex sm:flex-row sm:items-center">
            {text && (
              <p className="text-sm leading-5 text-white text-14px sm:flex-1">
                {text}
              </p>
            )}
            <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
              <ButtonPrimary
                id="btn-modal-validate"
                name="btn-modal-validate"
                item={null}
                type="button"
                onClick={onClickValidate}
              >
                {buttonValidate}
              </ButtonPrimary>
            </span>
            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
              <ButtonSecondary
                id="btn-modal-cancel"
                name="btn-modal-cancel"
                item={null}
                type="button"
                onClick={onClickCancel}
              >
                {buttonCancel}
              </ButtonSecondary>
            </span>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default Modal;
