import { AxiosResponse } from "axios";
import axiosBackend from "../../../../../axiosBackend";
import {
  CreateWebhookOpts,
  UpdateWebhooksOpts,
  Webhook,
} from "../../../models/Api/Webhooks";
import {
  IDeleteResponse,
  IGetResponse,
  IListResponse,
} from "../../../models/Api";

export const getWebhook = async (
  id: string
): Promise<AxiosResponse<IGetResponse<Webhook>>> => {
  return axiosBackend.get(`/webhooks/${id}`);
};

export const listWebhooks = async (
  offset: number,
  limit: number
): Promise<AxiosResponse<IListResponse<Webhook>>> => {
  const params = { offset, limit };

  return axiosBackend.get(`/webhooks`, { params });
};

export const createWebhook = async (
  payload: CreateWebhookOpts
): Promise<AxiosResponse<IGetResponse<Webhook>>> => {
  // create a webhook
  return axiosBackend.post("/webhooks", payload);
};

export const updateWebhook = async ({
  id,
  ...payload
}: UpdateWebhooksOpts): Promise<AxiosResponse<IGetResponse<Webhook>>> => {
  return axiosBackend.put(`/webhooks/${id}`, payload);
};

export const deleteWebhook = async (
  id: number
): Promise<AxiosResponse<IDeleteResponse<Webhook>>> => {
  return axiosBackend.delete(`/webhooks/${id}`);
};
