import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import {
  listAssetReportings as listAssetReportingsQuery,
  listOrderReportings as listOrderReportingsQuery,
  listIncidentReportings as listIncidentReportingsQuery,
  listReportingsUser as listReportingsUserQuery,
  listReportingsGlobal as listReportingsGlobalQuery,
  listReportingsSales as listReportingsSalesQuery,
  listReportingsIncident as listReportingsIncidentQuery,
  listReportingsIncidentStatus as listReportingsIncidentStatusQuery,
  listReportingsIncidentType as listReportingsIncidentTypeQuery,
  listReportingsIncidentReason as listReportingsIncidentReasonQuery,
  listReportingsBookings as listReportingsBookingsQuery,
} from "../../api/graphql/queries";
import { fetchPolicy } from "../../api/client";
import {
  ListAssetReportingsOpts,
  ListReportingsGlobalOpts,
  ListIncidentReportingsOpts,
  ListOrderReportingsOpts,
  ListReportingsUserOpts,
  ListReportingsIncidentOpts,
  ListReportingsIncidentReasonOpts,
  ListReportingsSalesOpts,
  ListReportingsBookingsOpts,
} from "../../models/Reporting";

export const listAssetReportings = (payload: ListAssetReportingsOpts) => {
  return ApolloClient.query({
    query: gql(listAssetReportingsQuery),
    variables: {
      filter: payload.filter,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};

export const listOrderReportings = (payload: ListOrderReportingsOpts) => {
  return ApolloClient.query({
    query: gql(listOrderReportingsQuery),
    variables: {
      filter: payload.filter,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};

export const listIncidentReportings = (payload: ListIncidentReportingsOpts) => {
  return ApolloClient.query({
    query: gql(listIncidentReportingsQuery),
    variables: {
      filter: payload.filter,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};

export const listReportingsUser = (payload: ListReportingsUserOpts) => {
  return ApolloClient.query({
    query: gql(listReportingsUserQuery),
    variables: {
      filter: payload.filter,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};

export const listReportingsGlobal = (payload: ListReportingsGlobalOpts) => {
  return ApolloClient.query({
    query: gql(listReportingsGlobalQuery),
    variables: {
      filter: payload.filter,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};

export const listReportingsSales = (payload: ListReportingsSalesOpts) => {
  return ApolloClient.query({
    query: gql(listReportingsSalesQuery),
    variables: {
      filter: payload.filter,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};

export const listReportingsIncident = (payload: ListReportingsIncidentOpts) => {
  return ApolloClient.query({
    query: gql(listReportingsIncidentQuery),
    variables: {
      filter: payload.filter,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};

export const listReportingsIncidentStatus = () => {
  return ApolloClient.query({
    query: gql(listReportingsIncidentStatusQuery),
    fetchPolicy,
  }) as {
    data: any;
  };
};

export const listReportingsIncidentType = () => {
  return ApolloClient.query({
    query: gql(listReportingsIncidentTypeQuery),
    fetchPolicy,
  }) as {
    data: any;
  };
};

export const listReportingsIncidentReason = (
  payload: ListReportingsIncidentReasonOpts
) => {
  return ApolloClient.query({
    query: gql(listReportingsIncidentReasonQuery),
    variables: {
      filter: payload.filter,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};

export const listReportingsBookings = (payload: ListReportingsBookingsOpts) => {
  return ApolloClient.query({
    query: gql(listReportingsBookingsQuery),
    variables: {
      filter: payload.filter,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};
