import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { match as Match } from "react-router-dom";
import { injectIntl, IntlShape } from "react-intl";
import compose from "recompose/compose";
import ReactModal from "react-modal";
import Button from "../../../../../components/Tailwind/Button";
import IntlMessages from "../../../../../utils/messages";
import { ActionTypes } from "../../../../../utils/types";
import {
  isMaxGroupedProductsReached,
  getMaxGroupedProducts,
} from "../../../../../utils/config";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import Table from "../../../../../components/Table";
import { EnumProductTypology } from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Product } from "../../../../../lib/ground-aws-graphql-core/models/Product";
import { getDefaultImage, getImageUrl } from "../../../../../utils/picture";
import { getTranslation } from "../../../../../utils/translation";
import {
  getFormattedPriceUnit,
  getUnitLabel,
} from "../../../../../utils/price-unit";
import Tag from "../../../../../components/Tag";

interface Props {
  intl: IntlShape;
  match: Match<{ cid: string; pid: string }>;
  typology: EnumProductTypology | null | undefined;
  title: string;
  isOpen: boolean;
  onRequestClose: (e) => void;
  onAddSingleProduct: (o: Product) => void;
  onRemoveSingleProduct: (o: Product) => void;
  items: { id: string; singleProduct: Product; action: ActionTypes }[];
}

const LIMIT = 10;

const ModalAddSingleProduct = (props: Props) => {
  const {
    isOpen,
    items,
    onRequestClose,
    intl,
    title,
    match,
    typology,
    onAddSingleProduct,
    onRemoveSingleProduct,
  } = props;
  const [loading, setLoading] = useState(false);

  // single products not to delete
  const elements = items.filter(i => i.action !== ActionTypes.TO_DELETE);

  const searchSpaces = GroundGraphqlContextStore.useStoreActions(
    actions => actions.product.searchSpaces
  );

  const products = GroundGraphqlContextStore.useStoreState(
    state => state.product.products.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.product.products.total
  );

  const fetchSpaces = (pageIndex = 0, loader = true) => {
    if (loader) {
      setLoading(true);
    }
    const filter = {
      productCenterId: {
        eq: match.params.cid,
      },
      id: {
        ne: match.params.pid,
      },
      or: [{ typology: { eq: typology } }],
    };

    searchSpaces({
      limit: LIMIT,
      filter,
      from: pageIndex * LIMIT,
    }).finally(() => {
      if (loader) {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchSpaces();
  }, [isOpen]);

  const handleClick = product => {
    // find single product
    const item = items.find(
      i =>
        i.singleProduct?.id === product.id && i.action !== ActionTypes.TO_DELETE
    );
    const unchecked = !item;
    const disabled = unchecked && isMaxGroupedProductsReached(elements);
    if (!disabled) {
      if (!item) {
        // add single product
        onAddSingleProduct(product);
      }
    } else {
      const message = intl.formatMessage(
        { id: "page.product.max.products.reached" },
        { total: getMaxGroupedProducts() }
      );
      toast(message, {
        type: "error",
      });
    }
  };

  const tableHead = [
    "page.list.spaces.table.head.space",
    "page.list.spaces.table.head.type",
    "general.capacity",
    "general.provider",
    "general.amount.ht",
  ];

  const tableBody = products?.map((product: Product) => {
    const { name, sku, enabled, priceUnits, provider, capacity, pictures } =
      product;
    const productName = getTranslation(name);
    const picture = pictures ? pictures[0] : null;
    const productElements = [
      {
        element: (
          <div className="flex flew-row items-center">
            <div className="w-24">
              <span className="inline-block relative">
                <img
                  alt={productName}
                  src={
                    picture
                      ? getImageUrl(null, picture, 50, 50)
                      : getDefaultImage(null)
                  }
                  style={picture ? undefined : { width: 50, height: 50 }}
                  className="list-thumbnail responsive border-0"
                />
                <span
                  className={`absolute bottom-0 right-0 block h-2 w-2 rounded-full text-white shadow-solid ${
                    enabled ? "bg-green-400" : "bg-red-400"
                  }`}
                />
              </span>
            </div>

            <div className="pl-2">
              <span className="block">{productName}</span>
              <span className="block">#{sku}</span>
            </div>
          </div>
        ),
        onCellClick: () => handleClick(product),
      },
      {
        element: intl.formatMessage({
          id: `page.product.typology.${typology}`,
        }),
        onCellClick: () => handleClick(product),
      },
      {
        element: intl.formatMessage(
          { id: "general.capacity.label" },
          { capacity }
        ),
        onCellClick: () => handleClick(product),
      },
      {
        element: getTranslation(provider?.name),
        onCellClick: () => handleClick(product),
      },
      {
        element: (
          <div className="flex flex-col">
            {priceUnits?.items?.map(p => {
              return (
                <span
                  key={p.id}
                  className="px-2 inline-flex text-14px text-ground-gray-100"
                >
                  {getUnitLabel(intl, p.unit)} : {getFormattedPriceUnit(p)}{" "}
                  <IntlMessages id="general.ht" />
                </span>
              );
            })}
          </div>
        ),
        onCellClick: () => handleClick(product),
      },
    ];

    return {
      rowElements: productElements,
    };
  });

  return (
    <ReactModal
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={onRequestClose}
    >
      {loading && <div className="loading" />}
      {!loading && (
        <div className="py-6">
          <div className="flex justify-between items-center px-8">
            <div>
              <h3 className="text-16px text-ground-black-100">
                <IntlMessages id={title} />{" "}
                <span className="text-ground-gray-100">
                  <IntlMessages
                    id="general.max"
                    values={{ total: getMaxGroupedProducts() }}
                  />
                </span>
              </h3>
            </div>
          </div>
          <Table
            head={tableHead}
            body={tableBody}
            noDataText="page.product.single.products.empty"
            onChange={({ pageIndex }) => fetchSpaces(pageIndex, false)}
            paginationLimit={LIMIT}
            paginationTotal={total}
            className="px-8"
          />
          <div className="flex py-2 px-8">
            <span>
              <IntlMessages
                id="page.product.single.products.selection.total"
                values={{ total: elements.length }}
              />
            </span>
          </div>
          {elements.length > 0 && (
            <div className="px-8">
              {elements.map((item, index) => (
                <Tag
                  key={`${item.singleProduct.id}_${index}`}
                  id={item.singleProduct.id}
                  title={getTranslation(item.singleProduct.name)}
                  deleteCondition
                  handleDelete={() => onRemoveSingleProduct(item.singleProduct)}
                />
              ))}
            </div>
          )}
          <div className="flex py-6 px-8">
            <Button
              id="btn-close-single-product-modal"
              name="btn-close-single-product-modal"
              type="button"
              item={null}
              onClick={e => {
                onRequestClose(e);
              }}
            >
              <span className="text-center">
                <IntlMessages id="general.validate.selection" />
              </span>
            </Button>
          </div>
        </div>
      )}
    </ReactModal>
  );
};

const enhance = compose(injectIntl, withRouter);
export default enhance(ModalAddSingleProduct);
