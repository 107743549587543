export const ErrorGraphQL = response => {
  const res = {
    error: {
      message: response["errors"].message,
      errorType: response["errors"].errorType,
    },
  };

  return res;
};
