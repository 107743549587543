import React, { useState } from "react";
import classnames from "classnames";
import { match as Match } from "react-router-dom";
import { toast } from "react-toastify";
import { FormattedPlural, IntlShape, injectIntl } from "react-intl";
import { getTranslation } from "../../../../../utils/translation";
import ServiceComponent from "../service";
import IntlMessages from "../../../../../utils/messages";
import images from "../../../../../images";
import ModalComponent from "../../../../../components/Tailwind/Modal";
import { getCypressTestId } from "../../../../../utils/config";
import {
  canDeleteService,
  canEditCategory,
  canManageCategories,
} from "../../../../../utils/types";
import { EnumServiceType } from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Category } from "../../../../../lib/ground-aws-graphql-core/models/Category";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import { Service } from "../../../../../lib/ground-aws-graphql-core/models/Service";
import { Product } from "../../../../../lib/ground-aws-graphql-core/models/Product";
import Dropdown from "../../../../../components/Tailwind/Dropdown";
import { GroundAuthContextStore } from "../../../../../lib/ground-aws-cognito-auth-core";

type Props = {
  intl: IntlShape;
  match: Match<{ cid: string }>;
  category: Category;
  services: Service[];
  serviceType: EnumServiceType;
  onEditService: (e, service: Service) => void;
  onClickService: (e, service: Service) => void;
  onAddService: (categoryId) => void;
  onEditCategory: (category) => void;
  onDuplicateCategory: (category) => void;
  fetchData: () => void;
};

const CategoryComponent = ({
  intl,
  match,
  category,
  services,
  serviceType,
  onEditService,
  onClickService,
  onEditCategory,
  onDuplicateCategory,
  onAddService,
  fetchData,
}: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleIsOpenToggler = () => setIsOpen(!isOpen);

  const deleteCategory = GroundGraphqlContextStore.useStoreActions(
    actions => actions.category.deleteCategory
  );

  const handleDelete = () => {
    setIsModalOpen(true);
  };

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const textIsOpen = isOpen ? "general.close" : "general.open";

  const togglerContainer = isOpen ? "block" : "hidden";

  const containerServices = classnames(
    togglerContainer,
    "divide-y divide-ground-gray-200"
  );

  const numberOfService = services.length || 0;

  const products = services.reduce((acc: Product[], el: Service) => {
    if (el && el.products) {
      const p = el.products.items?.filter(i => !i.provider?.markForDelete);
      if (p && p.length > 0) {
        acc.push(...p);
      }
    }

    return acc;
  }, []);

  const numberOfProducts = products ? products.length : 0;

  const imageOpenOrClose = isOpen ? images.closeBlue : images.addBlue;
  const altOpenOrClose = isOpen ? "-" : "+";

  const handleModalValidate = () => {
    deleteCategory({ id: category.id, markForDelete: true })
      .then(() => {
        const message = intl.formatMessage({
          id: "page.list.categories.delete.category.success",
        });
        toast(message, { type: "success" });
      })
      .catch(() => {
        const message = intl.formatMessage({
          id: "page.list.categories.delete.category.error",
        });
        toast(message, { type: "error" });
        setIsModalOpen(false);
      });
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const categoryName = getTranslation(category.name);

  const modalText = (
    <IntlMessages
      id="general.delete.category"
      values={{ name: categoryName }}
    />
  );
  const modalButtonValidate = <IntlMessages id="general.delete" />;
  const modalButtonCancel = <IntlMessages id="general.cancel" />;

  const classNameEnabled = enabled =>
    classnames("block h-2 w-2 rounded-full text-white shadow-solid", {
      "bg-red-400": !enabled,
      "bg-green-400": enabled,
    });

  const values = [
    {
      id: "add_service",
      label: "page.list.services.create.service",
      icon: images.addIcon,
      onClick: () => onAddService(category),
    },
  ];

  const authorizedToManageCategories = canManageCategories(me);
  if (authorizedToManageCategories) {
    values.push({
      id: "duplicate_category",
      label: "general.duplicate",
      icon: images.duplicate,
      onClick: () => onDuplicateCategory(category),
    });
  }

  const authorizedToEditCategory = canEditCategory(me, category?.center);
  if (authorizedToEditCategory) {
    values.splice(0, 0, {
      id: "edit_category",
      label: "general.edit",
      icon: images.edit,
      onClick: () => onEditCategory(category),
    });
  }

  if (canDeleteService(me)) {
    values.push({
      id: "delete_service",
      label: "general.delete",
      icon: images.deleteIcon,
      onClick: handleDelete,
    });
  }

  return (
    <div className="shadow-ground-2 border border-ground-gray-200 border-solid mb-8">
      <div className="flex flex-row items-center py-4 px-8">
        <div className="flex flex-col items-center">
          <span className="text-ground-gray-100 text-13px">
            <IntlMessages id="general.position" />
          </span>
          <div className="border border-ground-gray-200 border-solid rounded-5 py-2 px-4 text-14px text-gray-900 text-center">
            {category.position}
          </div>
        </div>
        <div className="pl-4 flex-1">
          <div className="flex items-center">
            <span className={classNameEnabled(category.enabled)} />
            <button
              data-cy={`category-name-${category.id}`}
              data-testid={getCypressTestId(category)}
              className={`block text-gray-900 text-16px font-semibold ml-2 ${
                authorizedToEditCategory ? "cursor-pointer" : "cursor-default"
              }`}
              onClick={
                authorizedToEditCategory
                  ? () => onEditCategory(category)
                  : undefined
              }
              type="button"
            >
              {categoryName}
            </button>
          </div>
          <button
            data-cy={`total-services-toggle-${category.id}`}
            data-testid={getCypressTestId(category)}
            className="block text-gray-600 text-13px cursor-pointer"
            onClick={handleIsOpenToggler}
            type="button"
          >
            {numberOfService}{" "}
            <FormattedPlural
              value={numberOfService}
              one={<IntlMessages id="page.list.services.service.one" />}
              other={<IntlMessages id="page.list.services.service.many" />}
            />{" "}
            - {numberOfProducts}{" "}
            <FormattedPlural
              value={numberOfProducts}
              one={
                <IntlMessages
                  id={`page.list.services.product.${serviceType}.one`}
                />
              }
              other={
                <IntlMessages
                  id={`page.list.services.product.${serviceType}.many`}
                />
              }
            />
          </button>
        </div>
        {category?.parent?.name && (
          <div className="pl-4 flex-1 align-middle items-end text-ground-gray-100">
            <span>{getTranslation(category.parent.name)}</span>
          </div>
        )}
        <div>
          <button
            data-cy={`category-toggle-services-${category.id}`}
            data-testid={getCypressTestId(category)}
            className="flex flex-row items-center text-12px text-ground-blue-100 cursor-pointer"
            onClick={handleIsOpenToggler}
            type="button"
          >
            <img src={imageOpenOrClose} alt={altOpenOrClose} />
            <IntlMessages id={textIsOpen} />
          </button>
        </div>
        <div className="pl-4">
          <Dropdown
            values={values}
            dataCy={`category-dropdown-${category.id}`}
          />
        </div>
      </div>
      <div className={containerServices}>
        {services.map(service => (
          <ServiceComponent
            match={match}
            key={service.id}
            service={service}
            serviceType={serviceType}
            onEditService={onEditService}
            onClickService={onClickService}
            fetchData={fetchData}
          />
        ))}
      </div>
      <ModalComponent
        isOpen={isModalOpen}
        text={modalText}
        buttonValidate={modalButtonValidate}
        buttonCancel={modalButtonCancel}
        onClickValidate={handleModalValidate}
        onClickCancel={handleModalCancel}
      />
    </div>
  );
};

export default injectIntl(CategoryComponent);
