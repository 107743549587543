import { AxiosResponse } from "axios";
import axiosBackend from "../../../../../axiosBackend";
import { IListResponse } from "../../../models/Api";
import {
  DeleteDocumentOpts,
  Folder,
  ListDocumentsOpts,
  UploadDocumentOpts,
} from "../../../models/Api/Documents";

export const listFolders = async (
  payload: ListDocumentsOpts
): Promise<AxiosResponse<IListResponse<Folder>>> => {
  return axiosBackend.get(
    `/files?name[eq]=${payload.folderName}&building_id[eq]=${payload.buildingId}`
  );
};

export const deleteFile = async (
  payload: DeleteDocumentOpts
): Promise<AxiosResponse<IListResponse<Folder>>> => {
  return axiosBackend.delete(`/file`, {
    data: payload,
  });
};

export const uploadFile = async (
  payload: UploadDocumentOpts
): Promise<AxiosResponse<IListResponse<string>>> => {
  // create new FormData object
  const data = new FormData();

  data.append("building_id", payload.buildingId);
  data.append("file", payload.file);
  data.append("folder", payload.folder);

  return axiosBackend.post("/file", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
