import React from "react";
import GoogleMapReact from "google-map-react";
import images from "../../images";

interface Props {
  location: {
    lat: number;
    lng: number;
  };
  zoom: number;
}

const SimpleMap = (props: Props): JSX.Element | null => {
  const { location, zoom } = props;
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API;

  if (!apiKey) {
    return null;
  }

  const renderMarkers = (map: any, maps: any) => {
    const marker = new maps.Marker({
      position: location,
      zoom: zoom,
      draggable: true,
      map,
      icon: {
        url: images.mapPin,
      },
    });

    return marker;
  };

  return (
    <GoogleMapReact
      options={{ scrollwheel: false }}
      bootstrapURLKeys={{ key: apiKey }}
      defaultCenter={location}
      defaultZoom={zoom}
      yesIWantToUseGoogleMapApiInternals={true}
      onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
    ></GoogleMapReact>
  );
};

export default SimpleMap;
