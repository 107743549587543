import { ErrorGraphQL } from "./../../services/coreUtils";
import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel } from "../";
import { Operator } from "../Operator";

import {
  CreateOptionInput as CreateOption,
  DeleteOptionInput as DeleteOption,
} from "../../api/graphql/types";
import { Product } from "../Product";
import { Center } from "../Center";

export interface Option {
  operator: Operator;
  center: Center;
  id: string;
  productOptionsId?: string;
  product: Product;
}

interface ListProductOptionsOpts {
  productId: string;
  gql?: string | null;
}

export interface ModelOptionConnection {
  items: [Option] | null;
  total?: number | null;
}

// Interface declaration
export interface OptionModel {
  options: ModelOptionConnection;
  hasMoreData: boolean;

  option: Option | null;
  setOptions: Action<OptionModel, ModelOptionConnection>;
  setOption: Action<OptionModel, Option | null>;
  addOption: Action<OptionModel, Option>;
  removeOption: Action<OptionModel, Option>;
  listProductOptions: Thunk<
    OptionModel,
    ListProductOptionsOpts,
    Injections,
    StoreModel
  >;
  createOption: Thunk<OptionModel, CreateOption, Injections>;
  deleteOption: Thunk<OptionModel, DeleteOption, Injections>;
}

export const optionModel: OptionModel = {
  options: { items: null, total: null },
  hasMoreData: false,
  option: null,

  setOptions: action((state, payload: ModelOptionConnection) => {
    state.options = payload;
    state.hasMoreData = false;
  }),

  setOption: action((state, payload) => {
    state.option = payload;
  }),

  addOption: action((state, payload) => {
    if (state.options.items) {
      state.options.items.push(payload);
    } else {
      state.options["items"] = [payload];
    }
  }),

  removeOption: action((state, payload) => {
    if (state.options.items) {
      const index = state.options.items!.findIndex(e => e.id === payload.id);
      state.options.items!.splice(index, 1);
    }
  }),

  listProductOptions: thunk(async (actions, payload, { injections }) => {
    const { optionService } = injections;
    const response = await optionService.listProductOptions(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const data = response.data.listProductOptions as ModelOptionConnection;
    actions.setOptions(data);

    return data;
  }),

  createOption: thunk(async (actions, payload, { injections }) => {
    const { optionService } = injections;
    const response = await optionService.createOption(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.createOption;
    actions.setOption(item);
    actions.addOption(item);

    return item;
  }),

  deleteOption: thunk(async (actions, payload, { injections }) => {
    const { optionService } = injections;
    const response = await optionService.deleteOption(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.deleteOption;
    actions.setOption(item);
    actions.removeOption(item);

    return item;
  }),
};
