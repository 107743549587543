import React from "react";
import { Helmet } from "react-helmet";
import { match as Match } from "react-router-dom";
import { injectIntl, IntlShape } from "react-intl";
import { withOneColLayout } from "../../../utils";
import UserForm from "../form";
import PageSubheader from "../../../components/PageSubheader";

interface Props {
  administrator: boolean;
  match: Match<{ id: string }>;
  intl: IntlShape;
  backURL: string;
}

const UserUpdate = (props: Props) => {
  const { administrator, intl, backURL } = props;

  const el = administrator ? "administrator" : "user";

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: `page.${el}.update.document.title` })}
        </title>
      </Helmet>

      <PageSubheader
        goBackEnabled
        goBackId={`btn-back-${el}-update`}
        goBackURL={backURL}
      />

      <UserForm {...props} edition />
    </>
  );
};

export default withOneColLayout(injectIntl(UserUpdate));
