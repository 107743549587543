import React from "react";
import { useIntl } from "react-intl";
import FilterSelect from "../select";
import { EnumPermissionEntity } from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import IntlMessages from "../../../../utils/messages";
import { EnumFilterType, getFilterFields } from "../../../../utils/filter";
import { TableChangeParams } from "../../../Table/types";
import {
  AsyncFiltersSelect,
  FiltersInput,
  FilterSwitch,
} from "../../../Tailwind/Filters";
import FilterDates from "../../../Tailwind/Filters/date";
import { IFilterField } from "../../../Table";
import { ILoadOptionsResponse } from "../../../../routes/management/global-orders/global-orders-list/table";
import { formatISODateFn } from "../../../../utils/config";

type Props = {
  title?: string;
  permissionEntity: EnumPermissionEntity | undefined;
  onChange: (type: string, filters: IFilterField<string | boolean>[]) => void;
  changeParams: TableChangeParams;
  loadOptions?: {
    [key: string]: (
      searchQuery: any,
      _loadedOptions: any,
      { page }: { page: number }
    ) => Promise<ILoadOptionsResponse>;
  };
};

const FilterBlock = (props: Props): JSX.Element => {
  const { changeParams, title, permissionEntity, onChange, loadOptions } =
    props;

  const intl = useIntl();

  const { values: filterFields, multiple } = getFilterFields(
    intl,
    permissionEntity
  );
  // filter date
  const dateFilters = filterFields.filter(v => v.type === EnumFilterType.DATE);

  return (
    <div className="border rounded-lg border-primary-200 shadow-ground-1 mb-4">
      <div className="px-8 py-4 bg-neutral-200 rounded-t-lg text-17px leading-5 text-ground-black-100">
        {title && <IntlMessages id={title} />}
      </div>

      <div className="px-8 py-4 flex gap-5">
        {filterFields.map((field, index) => (
          <div
            key={`filter_${index}`}
            className="flex flex-col justify-between"
          >
            <div className="text-xs font-semibold mb-2">
              {field.title && <IntlMessages id={field.title} />}
            </div>

            <div className="flex flex-1 items-center" key={index}>
              {field.type === EnumFilterType.DATE && (
                <FilterDates
                  fields={[field]}
                  filters={dateFilters}
                  selectedFilters={changeParams.filters}
                  displayTitle={false}
                  onChange={(type, target: Date) => {
                    const types = [field].map(el => el.value);
                    const filters: IFilterField<string>[] = [
                      ...changeParams.filters,
                    ].filter(e => !types.includes(e.type));
                    filters.push({
                      type,
                      value: target ? formatISODateFn(target) : null,
                      current: field,
                    });

                    return onChange(type, filters);
                  }}
                />
              )}

              {field.type === EnumFilterType.SELECT && !field.async && (
                <FilterSelect
                  fields={[field]}
                  selectedFilters={changeParams.filters}
                  displayTitle={false}
                  onChange={(type, target) => {
                    const filters = [...changeParams.filters];
                    const selectedFilter = filters.find(e => e.type === type);
                    const selectedFilterIndex = filters.findIndex(
                      e => e.type === type
                    );

                    if (~selectedFilterIndex && selectedFilter)
                      filters.splice(selectedFilterIndex, 1, {
                        ...selectedFilter,
                        value: target,
                      });
                    else filters.push({ type, value: target });

                    return onChange(type, filters);
                  }}
                />
              )}

              {field.type === EnumFilterType.SELECT &&
                field.async &&
                loadOptions && (
                  <AsyncFiltersSelect
                    fields={[field]}
                    selectedFilters={changeParams.filters}
                    displayTitle={false}
                    multiple={
                      multiple
                        ? multiple[`${EnumFilterType[EnumFilterType.SELECT]}`]
                        : false
                    }
                    onSelectChange={(type, selectTarget) => {
                      const filters: IFilterField<string>[] = [
                        ...changeParams.filters,
                      ].filter(e => e.type !== type);
                      filters.push({ type, value: selectTarget });

                      return onChange(type, filters);
                    }}
                    loadOptions={loadOptions}
                  />
                )}

              {field.type === EnumFilterType.INPUT && (
                <FiltersInput
                  fields={[field]}
                  displayTitle={false}
                  selectedFilters={changeParams.filters}
                  onInputChange={(type, target) => {
                    const types = [field].map(el => el.value);
                    const filters: IFilterField<string>[] = [
                      ...changeParams.filters,
                    ].filter(e => !types.includes(e.type));
                    filters.push({ type, value: target });

                    return onChange(type, filters);
                  }}
                />
              )}

              {field.type === EnumFilterType.SWITCH && (
                <FilterSwitch
                  field={field}
                  selectedFilters={changeParams.filters}
                  onChange={(type, target) => {
                    const filters: IFilterField<boolean>[] = [
                      ...changeParams.filters,
                    ].filter(e => e.type !== type);
                    filters.push({ type, value: target });

                    return onChange(type, filters);
                  }}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterBlock;
