/*
 *
 * Account Model
 *
 */
import { Thunk, thunk } from "easy-peasy";
import { Injections } from "../../../store";
import { StoreModel } from "../..";

//export type Locale = "fr_FR" | "en_GB" | "es_ES";

// Interface declaration
export interface AccountModel {
  forgotPasswordAdminUser: Thunk<
    AccountModel,
    { id: string },
    Injections,
    StoreModel
  >;
  forgotPasswordUser: Thunk<
    AccountModel,
    { id: string },
    Injections,
    StoreModel
  >;
}

export const accountModel: AccountModel = {
  forgotPasswordAdminUser: thunk(async (_actions, payload, { injections }) => {
    const { accountApiService } = injections;

    return accountApiService.forgotPasswordAdminUser(payload.id);
  }),
  forgotPasswordUser: thunk(async (_actions, payload, { injections }) => {
    const { accountApiService } = injections;

    return accountApiService.forgotPasswordUser(payload.id);
  }),
};
