/*
 *
 * News Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import {
  GetNewsQuery,
  CreateNewsMutation,
  UpdateNewsMutation,
} from "../../api/graphql/types";

import {
  getNews as getNewsQuery,
  searchNews as searchNewsQuery,
} from "../../api/graphql/queries";

import {
  createNews as createNewsMutation,
  updateNews as updateNewsMutation,
} from "../../api/graphql/mutations";

import { fetchPolicy } from "../../api/client";
import { SearchNewsOpts } from "./../../models/News";

const DEFAULT_LIMIT = 100;

export const getNews = payload => {
  const request = payload.gql ? payload.gql : getNewsQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: { id: payload.id },
    fetchPolicy,
  }) as {
    data: GetNewsQuery;
  };
};

export const createNews = payload =>
  ApolloClient.mutate({
    mutation: gql(createNewsMutation),
    variables: { input: payload },
  }) as {
    data: CreateNewsMutation;
  };

export const updateNews = payload =>
  ApolloClient.mutate({
    mutation: gql(updateNewsMutation),
    variables: { input: payload },
  }) as {
    data: UpdateNewsMutation;
  };

export const searchNews = (payload: SearchNewsOpts) => {
  return ApolloClient.query({
    query: gql(searchNewsQuery),
    variables: {
      filter: payload.filter,
      locale: payload.locale,
      limit: payload?.limit || DEFAULT_LIMIT,
      from: payload?.from,
      sort: payload?.sort,
    },
    fetchPolicy,
  }) as {
    data: any;
  };
};
