import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import { $enum } from "ts-enum-util";
import { EnumAttributeType } from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import { ALL_FILTER } from "../../Table";

interface Props {
  intl: IntlShape;
  type?: EnumAttributeType;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const AttributeKeyType = (props: Props): JSX.Element => {
  const { intl, type, onChange } = props;

  const types = $enum(EnumAttributeType).getKeys();

  return (
    <div className="h-8 my-4 inline-block border border-ground-gray-400 rounded-4">
      <select
        id="attribute-key-type"
        name="attribute-key-type"
        className="form-select h-full py-0 bg-transparent text-ground-black-100 text-14px leading-5 border-none rounded-none shadow-none"
        onChange={onChange}
        defaultValue={type}
      >
        <option value={ALL_FILTER}>
          {intl.formatMessage({ id: "general.all" })}
        </option>
        {types.map(d => (
          <option key={d} value={d}>
            {intl.formatMessage({
              id: `page.attr.key.type.${d}`,
            })}
          </option>
        ))}
      </select>
    </div>
  );
};

export default injectIntl(AttributeKeyType);
