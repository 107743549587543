import React from "react";
import classNames from "classnames";
import { IntlShape, injectIntl } from "react-intl";
import images from "../../../../images";

interface Props {
  intl: IntlShape;
  label: string;
  total?: number;
  rate?: number;
  variation?: number;
  selected: boolean;
}

const GlobalCard = (props: Props): JSX.Element => {
  const { label, total, rate, selected, variation } = props;
  const className = `flex items-center border border-ground-gray-200 rounded-5 justify-center`;

  let percent = 0;
  if (rate) {
    percent = rate * 100;
  }

  const classNameRate = classNames(
    "flex items-center text-ground-blue-700 border rounded-3 px-2",
    {
      "text-ground-blue-700 border-ground-blue-600 bg-ground-blue-500":
        percent > 0,
      "text-ground-orange-300 border-ground-orange-200 bg-ground-orange-100":
        percent < 0,
      "text-ground-green-400 border-ground-green-300 bg-ground-green-200":
        percent === 0,
    }
  );

  return (
    <div className="mt-1 sm:mt-0 sm:col-span-1">
      <div
        className={className}
        style={{
          boxShadow: selected ? "0px 7px 10px #3063D526" : "none",
        }}
      >
        <div className="flex flex-col items-center justify-center py-2">
          <span className="text-ground-gray-100 text-l mt-2 text-center">
            {label}
          </span>
          <div className="flex mt-2 items-center">
            <span className="text-center text-xl mx-5">
              {total}
              {" -"}
            </span>
            {total !== undefined && (
              <div className={classNameRate}>
                <img
                  src={
                    percent > 0
                      ? images.trendingUp
                      : percent < 0
                      ? images.trendingDown
                      : images.trendingFlat
                  }
                  style={{ width: 25, height: 25 }}
                  className="list-thumbnail responsive border-0"
                />
                <span className="ml-1">{percent.toFixed(2)} %</span>
                {variation !== 0 && (
                  <span className="text-l mx-2">(+{variation})</span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(GlobalCard);
