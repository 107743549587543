import React, { createContext } from "react";
import { Redirect, Route, Switch, match as Match } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { IntlProvider } from "react-intl";
import { Location } from "history";
import dayjs from "dayjs";
import { NOTIFICATION_DISPLAY_TIME, defaultStartPath } from "../constants";
import { getLocale } from "../lang";
import MainRoute from "../routes";
import SignIn from "../routes/authentication/signin";
import ChangePassword from "../routes/authentication/change-password";
import ForgotPassword from "../routes/authentication/forgot-password";
import ValidatePassword from "../routes/authentication/validate-password";
import Error from "../routes/error";
import PrivateRoute from "./private";
import contextStore from "../redux/store";
import { EnumPaths } from "../utils/navigation";
import { GroundGraphqlProvider } from "../lib/ground-aws-graphql-core";
import theme from "../theme.js";

interface Props {
  location: Location;
  match: Match;
}

export const ThemeContext = createContext(theme);

const App = (props: Props): JSX.Element => {
  const { location, match } = props;

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const token = contextStore.useStoreState(state => state.authentication.token);

  if (
    location.pathname === "/" ||
    location.pathname === `/${EnumPaths.ROOT}` ||
    location.pathname === `/${EnumPaths.ROOT}/`
  ) {
    return <Redirect to={defaultStartPath} />;
  }

  const currentAppLocale = getLocale(locale);
  dayjs.locale(currentAppLocale.language);

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ThemeContext.Provider value={theme}>
        <GroundGraphqlProvider jwtToken={token || ""}>
          <ToastContainer
            position="top-center"
            autoClose={NOTIFICATION_DISPLAY_TIME}
            closeButton={false}
            hideProgressBar={false}
            newestOnTop={true}
            icon={false}
            draggable
            theme="colored"
          />
          <Switch>
            <PrivateRoute
              path={`${match.url}${EnumPaths.ROOT}`}
              component={MainRoute}
            />

            <Route path={`/${EnumPaths.SIGNIN}`} component={SignIn} />
            <Route
              path={`/${EnumPaths.CHANGE_PASSWORD}`}
              component={ChangePassword}
            />
            <Route
              path={`/${EnumPaths.FORGOT_PASSWORD}`}
              component={ForgotPassword}
            />
            <Route
              path={`/${EnumPaths.VALIDATE_PASSWORD}`}
              component={ValidatePassword}
            />
            <Route path={`/${EnumPaths.ERROR}`} component={Error} />
            <Redirect to={`/${EnumPaths.ERROR}`} />
          </Switch>
        </GroundGraphqlProvider>
      </ThemeContext.Provider>
    </IntlProvider>
  );
};

export default App;
