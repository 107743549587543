import React, { useEffect, useState } from "react";
import Holidays from "date-holidays";
import HolidaySlot from "../../CardHour/Holidays/HolidaySlot";
import {
  addDuration,
  getTodayInCenterTimezone,
} from "../../../../../utils/config";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";

interface Props {
  country: string;
}

const HolidaysComponent = (props: Props): JSX.Element => {
  const { country } = props;
  const [days, setDays] = useState<Holidays.Holiday[]>([]);

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  useEffect(() => {
    const hd = new Holidays(country, "", "", { types: ["public"] });

    const today = getTodayInCenterTimezone(centerTimezone, true);

    // get holidays from current and next year
    const nextYear = addDuration(today, 1, "year");
    const hds = [...hd.getHolidays(), ...hd.getHolidays(nextYear)];
    setDays(hds);
  }, [country]);

  return (
    <tbody className="bg-white">
      {days.map((day, index) => {
        return (
          <tr className="border border-gray-200" key={index}>
            <HolidaySlot holiday={day} />
          </tr>
        );
      })}
    </tbody>
  );
};

export default HolidaysComponent;
