import { action, Action, Thunk, thunk } from "easy-peasy";
import { Injections } from "../../../store";
import { StoreModel } from "../..";
import { IListResponse } from "..";

export interface CustomFile {
  key: string;
  name: string;
  extension: string;
  url: string;
}
export interface Folder {
  key: string;
  level: number;
  name: string;
  folders: Folder[];
  files: CustomFile[];
}

export interface ListDocumentsOpts {
  folderName: string;
  buildingId: string;
}

export interface DeleteDocumentOpts {
  key: string;
}

export interface UploadDocumentOpts {
  buildingId: string;
  file: File;
  folder: string;
}

export interface DocumentsModel {
  folders: {
    items: Folder[];
    total: number;
    has_more: boolean;
  };
  setFolders: Action<
    DocumentsModel,
    { items: Folder[]; total: number; has_more: boolean }
  >;
  listFolders: Thunk<DocumentsModel, ListDocumentsOpts, Injections, StoreModel>;
  deleteFile: Thunk<DocumentsModel, DeleteDocumentOpts, Injections, StoreModel>;
  uploadFile: Thunk<DocumentsModel, UploadDocumentOpts, Injections, StoreModel>;
}

export const documentsModel: DocumentsModel = {
  folders: { items: [], total: 0, has_more: false },
  setFolders: action((state, payload) => {
    state.folders = payload;
  }),
  listFolders: thunk(
    async (actions, payload: ListDocumentsOpts, { injections }) => {
      const { documentsService } = injections;
      const response = await documentsService.listFolders(payload);
      if (response.status === 200 && response.data.success) {
        const res: IListResponse<Folder> = response.data;
        actions.setFolders(res.data);
      }
    }
  ),
  deleteFile: thunk(
    async (actions, payload: DeleteDocumentOpts, { injections }) => {
      const { documentsService } = injections;
      const response = await documentsService.deleteFile(payload);
      if (response.status === 200 && response.data.success) {
        //
      }
    }
  ),
  uploadFile: thunk(
    async (actions, payload: UploadDocumentOpts, { injections }) => {
      const { documentsService } = injections;
      const response = await documentsService.uploadFile(payload);
      if (response.status === 201 && response.data.success) {
        //
      }
    }
  ),
};
