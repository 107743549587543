import { Action, Thunk, action, thunk } from "easy-peasy";
import { ErrorGraphQL } from "../../services/coreUtils";
import { Injections } from "../../store";
import { StoreModel, GqlQuery } from "..";
import {
  EnumBackOfficeUserRole,
  EnumLocale,
  SearchableBackOfficeUserFilterInput,
} from "../../api/graphql/types";
import { Operator } from "../Operator";
import { UserStatusType } from "../../../../utils/user";

export interface BackOfficeUser {
  id: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  username?: string;
  phone?: string | null;
  status?: UserStatusType | null;
  role?: EnumBackOfficeUserRole;
  picture?: string | null;
  enabled?: boolean;
  markForDelete?: boolean;
  userPoolId?: string;
  identityPoolId?: string;
  tier?: string;
  clientId?: string;
  locale?: string;
  operator?: Operator;
  createdAt: string;
}
export interface ModelBackOfficeUserConnection {
  items: BackOfficeUser[];
  total: number;
}

export interface SearchBackOfficeUsersOpts {
  filter: SearchableBackOfficeUserFilterInput;
  limit?: number;
  gql?: string;
  from?: number;
}

export interface CreateAdminUserOpts {
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  locale?: EnumLocale;
}

export interface UpdateAdminUserOpts {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  role?: string;
  locale?: EnumLocale;
}

// Interface declaration
export interface BackOfficeUserModel {
  backOfficeUsers: ModelBackOfficeUserConnection;
  backOfficeUser: BackOfficeUser | null;
  exportedAdministrators: ModelBackOfficeUserConnection;

  setBackOfficeUsers: Action<
    BackOfficeUserModel,
    { data: ModelBackOfficeUserConnection }
  >;
  setBackOfficeUser: Action<BackOfficeUserModel, BackOfficeUser | null>;
  getBackOfficeUser: Thunk<BackOfficeUserModel, GqlQuery, Injections>;
  removeBackOfficeUser: Action<BackOfficeUserModel, { id: string }>;
  setExportedAdministrators: Action<
    BackOfficeUserModel,
    { data: ModelBackOfficeUserConnection }
  >;

  searchBackOfficeUsers: Thunk<
    BackOfficeUserModel,
    SearchBackOfficeUsersOpts,
    Injections,
    StoreModel
  >;
  exportAdministrators: Thunk<
    BackOfficeUserModel,
    SearchBackOfficeUsersOpts,
    Injections,
    StoreModel
  >;
  createAdminUser: Thunk<
    BackOfficeUserModel,
    CreateAdminUserOpts,
    Injections,
    StoreModel
  >;
  updateAdminUser: Thunk<
    BackOfficeUserModel,
    UpdateAdminUserOpts,
    Injections,
    StoreModel
  >;
  deleteAdminUser: Thunk<
    BackOfficeUserModel,
    { id: string },
    Injections,
    StoreModel
  >;
}

export const backOfficeUserModel: BackOfficeUserModel = {
  backOfficeUsers: { items: [], total: 0 },
  backOfficeUser: null,
  exportedAdministrators: { items: [], total: 0 },

  setBackOfficeUsers: action((state, payload) => {
    const { data } = payload;
    state.backOfficeUsers = data;
  }),

  setBackOfficeUser: action((state, payload) => {
    state.backOfficeUser = payload;
  }),

  removeBackOfficeUser: action((state, payload) => {
    state.backOfficeUsers.items = state.backOfficeUsers.items.filter(
      u => u.id !== payload.id
    );
    state.backOfficeUsers.total = state.backOfficeUsers.total - 1;
  }),

  getBackOfficeUser: thunk(async (actions, payload, { injections }) => {
    const { backOfficeUserService } = injections;
    const response = await backOfficeUserService.getBackOfficeUser(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    actions.setBackOfficeUser(response.data.getBackOfficeUser);

    return response.data.getBackOfficeUser;
  }),

  setExportedAdministrators: action((state, payload) => {
    const { data } = payload;
    state.exportedAdministrators = data;
  }),

  searchBackOfficeUsers: thunk(async (actions, payload, { injections }) => {
    const { backOfficeUserService } = injections;
    const response = await backOfficeUserService.searchBackOfficeUsers(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const data = response.data.searchBackOfficeUsers;
    actions.setBackOfficeUsers({ data });

    return data;
  }),

  exportAdministrators: thunk(async (actions, payload, { injections }) => {
    const { backOfficeUserService } = injections;
    const response = await backOfficeUserService.exportAdministrators(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.exportAdministrators) {
      const data: ModelBackOfficeUserConnection =
        response.data.exportAdministrators;
      actions.setExportedAdministrators({ data });

      return data;
    }
  }),

  createAdminUser: thunk(async (_actions, payload, { injections }) => {
    const { backOfficeUserService } = injections;

    return backOfficeUserService.createAdminUser(payload);
  }),

  updateAdminUser: thunk(async (_actions, payload, { injections }) => {
    const { backOfficeUserService } = injections;

    return backOfficeUserService.updateAdminUser(payload);
  }),

  deleteAdminUser: thunk(async (_actions, payload, { injections }) => {
    const { backOfficeUserService } = injections;

    const response = await backOfficeUserService.deleteAdminUser(payload.id);

    if (response.status === 200) {
      _actions.setBackOfficeUser(null);
      _actions.removeBackOfficeUser(payload);
    }

    return response;
  }),
};
