import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import classNames from "classnames";
import _ from "lodash";
import Switch from "rc-switch";
import IntlMessages from "../../../../utils/messages";
import { getCypressTestId } from "../../../../utils/config";
import {
  EnumPermissionEntity,
  EnumServiceType,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";

interface Props {
  intl: IntlShape;
  item: { id: string | number } | null | undefined;
  title: string;
  disabled?: boolean;
  titleParams?: any;
  entity?: EnumPermissionEntity;
  serviceType?: EnumServiceType;
  description?: string;
  checked?: boolean;
  onChange?: (e) => void;
  children?: React.ReactNode;
  className?: string;
}

const getTitle = (
  intl: IntlShape,
  entity: EnumPermissionEntity,
  serviceType: EnumServiceType = EnumServiceType.SERVICE
) => {
  let title = intl.formatMessage({ id: `header.${entity}` });
  if (
    entity === EnumPermissionEntity.PRODUCT &&
    serviceType === EnumServiceType.SPACE
  ) {
    title = intl.formatMessage({ id: `header.${serviceType}` });
  }

  return title;
};

const Header = (props: Props) => {
  const {
    title,
    titleParams,
    description,
    children,
    onChange,
    intl,
    entity,
    serviceType,
    checked,
    item,
    className,
    disabled,
  } = props;

  return (
    <div
      className={classNames(
        "py-3 sm:py-5 px-2 sm:px-6 flex justify-between sm:items-center",
        className
      )}
    >
      <div>
        <h3 className="text-16px text-ground-black-100">
          {intl.formatMessage({ id: title }, titleParams)}
        </h3>
        {description && (
          <p className="mt-1 text-13px text-ground-gray-100">
            <IntlMessages id={description} />
          </p>
        )}
      </div>
      {onChange && entity && (
        <div className="flex items-center">
          <div className="flex pr-10">{children}</div>
          <span className="hidden sm:block text-12px text-ground-gray-100 px-2">
            {_.capitalize(getTitle(intl, entity, serviceType))}{" "}
            {checked && <IntlMessages id="general.enabled" />}{" "}
            {!checked && <IntlMessages id="general.disabled" />}
          </span>
          <Switch
            disabled={disabled || false}
            id={`switch_${entity.toLowerCase()}_status`}
            data-cy={`switch_${entity.toLowerCase()}_status`}
            data-testid={getCypressTestId(item)}
            checked={checked}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};

export default injectIntl(Header);
