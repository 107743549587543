import React from "react";
import IntlMessages from "../../../utils/messages";

interface Props {
  id: string;
  name: string;
  label: string;
  outline?: boolean;
  onClick?: (e) => void;
  disabled?: boolean;
}

const LinkButton = (props: Props): JSX.Element => {
  return (
    <button
      id={props.id}
      name={props.name}
      data-cy={props.id}
      type="button"
      onClick={props.onClick}
      disabled={props.disabled}
      className="inline-flex items-center border border-transparent text-12px leading-5 font-medium bg-transparent
        text-ground-gray-300 hover:text-ground-blue-100 focus:outline-none active:text-ground-gray-300 transition ease-in-out duration-150"
    >
      <span>
        <IntlMessages id={props.label} />
      </span>
    </button>
  );
};

export default LinkButton;
