import React from "react";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import EventForm from "../form";
import { withTwoColLayout } from "../../../../utils";
import PageSubheader from "../../../../components/PageSubheader";

interface Props {
  handleClose: () => void;
  match: Match<{ cid: string; id: string }>;
  intl: IntlShape;
  backURL: string;
}

const EventUpdate = (props: Props) => {
  const { intl, backURL } = props;

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: "page.event.update.document.title" })}
        </title>
      </Helmet>

      <PageSubheader
        goBackEnabled
        goBackId="btn-back-event-update"
        goBackURL={backURL}
      />

      <EventForm {...props} edition />
    </>
  );
};

export default withTwoColLayout(injectIntl(EventUpdate));
