import { gql } from "apollo-boost";
import {
  createEnterprise as createEnterpriseMutation,
  updateEnterprise as updateEnterpriseMutation,
  deleteEnterprise as deleteEnterpriseMutation,
} from "../../../lib/ground-aws-graphql-core/api/graphql/mutations";
import {
  listEnterprises as listEnterprisesQuery,
  getEnterprise as getEnterpriseQuery,
} from "../../../lib/ground-aws-graphql-core/api/graphql/queries";
import { ApolloClient } from "../../../lib/ground-aws-graphql-core/store/provider";

export const getEnterprise = ({ id }) =>
  ApolloClient.query({
    query: gql(getEnterpriseQuery),
    variables: { id },
  });

export const createEnterprise = input =>
  ApolloClient.mutate({
    mutation: gql(createEnterpriseMutation),
    variables: { input },
  });

export const updateEnterprise = input =>
  ApolloClient.mutate({
    mutation: gql(updateEnterpriseMutation),
    variables: { input },
  });

export const listEnterprises = () =>
  ApolloClient.query({
    query: gql(listEnterprisesQuery),
  });

export const deleteEnterprise = input =>
  ApolloClient.mutate({
    mutation: gql(deleteEnterpriseMutation),
    variables: { input },
  });
