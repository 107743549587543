import React, { forwardRef } from "react";
import GroundFormik from "../../../Tailwind/Form";
import IntlMessages from "../../../../utils/messages";
import { Center } from "../../../../lib/ground-aws-graphql-core/models/Center";
import ActivationModule from "../../../ActivationModule";

interface Props {
  item: Center;
  title: string;
  subTitle?: string;
  content?: any;
  additional?: any;
  onChange: (e) => void;
}

const CardInformation = (props: Props, ref): JSX.Element => {
  const { title, subTitle, item, additional, content, onChange } = props;

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg py-30px">
      <div className="flex justify-between items-center px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 className="text-16px leading-6 font-medium text-gray-900">
          <IntlMessages id={title} />
        </h3>
        {subTitle && (
          <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
            <IntlMessages id={subTitle} />
          </p>
        )}
        {!additional && <ActivationModule center={item} />}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className={`col-span-1 md:col-span-${additional ? 1 : 2}`}>
          <GroundFormik
            item={item}
            values={content}
            onChange={onChange}
            ref={ref}
          />
        </div>
        {additional && (
          <div className="col-span-1 h-36 md:h-full">{additional}</div>
        )}
      </div>
    </div>
  );
};

export default forwardRef(CardInformation);
