import React, { useEffect, useState } from "react";
import { $enum } from "ts-enum-util";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import overrideClasses from "../../../../../utils/overrideClasses";
import { FilterCalendar } from "../../../../../components/Tailwind/Filters";
import {
  EnumCalendarType,
  EnumReportingType,
} from "../../../../../lib/ground-aws-graphql-core/models/Product";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import contextStore from "../../../../../redux/store";
import { getLocale } from "../../../../../lang";
import {
  EnumCurrency,
  EnumProductTypology,
} from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import PageSubheader from "../../../../../components/PageSubheader";
import RealTimeSalesCardList from "./sales-card-list";
import { getFormattedPrice } from "../../../../../utils/price-unit";
import {
  getTodayInCenterTimezone,
  getCurrentWeekInCenterTimezone,
  getWeekInCenterTimezone,
  getPreviousWeek,
  getNextWeek,
  eachUnitOfInterval,
  transformDateForQuery,
  displayDayLabel,
} from "../../../../../utils/config";

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
  backURL: string;
}

const RealTimeSalesDashboard = (props: Props): JSX.Element => {
  const { intl, match, backURL } = props;
  const [loading, setLoading] = useState(false);

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const now = getTodayInCenterTimezone(centerTimezone);
  const { startWeek, endWeek } = getCurrentWeekInCenterTimezone(centerTimezone);
  const [startDate, setStartDate] = useState(startWeek);
  const [endDate, setEndDate] = useState(endWeek);
  const [date, setDate] = useState(now);

  const listOrderReportings = GroundGraphqlContextStore.useStoreActions(
    actions => actions.reporting.listOrderReportings
  );

  const orderReportings = GroundGraphqlContextStore.useStoreState(
    state => state.reporting.orderReportings.items
  );

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  const days = eachUnitOfInterval(startDate, endDate, "day");

  const orderTypologies = [
    ...new Set(
      orderReportings.reduce((acc: EnumProductTypology[], el) => {
        const typos = el.data.map(r => r.typology);

        return acc.concat(typos);
      }, [])
    ),
  ];

  const dataByTypology = orderTypologies.map(typology => {
    // reportings for typology
    const reportingsForTypology = orderReportings
      .map(r => r.data.find(r => r.typology === typology))
      .filter(item => !!item);

    const dataSaleRate = reportingsForTypology.map(item =>
      item ? item.indicators.sale_rate * 100 : 0
    );

    const dataCancellationRate = reportingsForTypology.map(item =>
      item ? item.indicators.cancellation_rate * 100 : 0
    );

    const dataRefundRate = reportingsForTypology.map(item =>
      item ? item.indicators.refund_rate * 100 : 0
    );

    const dataSales = reportingsForTypology.map(item =>
      item ? item.indicators.total_sales : 0
    );

    const dataCancellations = reportingsForTypology.map(item =>
      item ? item.indicators.total_cancellations : 0
    );

    const dataRefunds = reportingsForTypology.map(item =>
      item ? item.indicators.total_refunds : 0
    );

    const data = [
      {
        type: EnumReportingType.SALES,
        data: {
          totals: dataSales.map(d => Number(d.toFixed(2))),
          rates: dataSaleRate.map(d => Number(d.toFixed(2))),
        },
      },
      {
        type: EnumReportingType.CANCELLATION,
        data: {
          totals: dataCancellations.map(d => Number(d.toFixed(2))),
          rates: dataCancellationRate.map(d => Number(d.toFixed(2))),
        },
      },
      {
        type: EnumReportingType.REFUND,
        data: {
          totals: dataRefunds.map(d => Number(d.toFixed(2))),
          rates: dataRefundRate.map(d => Number(d.toFixed(2))),
        },
      },
    ];

    return {
      typology,
      data,
    };
  });

  const weekTotals = orderReportings.reduce(
    (acc, el) => {
      acc.sales = acc.sales + el.indicators.total_sales;
      acc.cancellations = acc.cancellations + el.indicators.total_cancellations;
      acc.refunds = acc.refunds + el.indicators.total_refunds;

      return acc;
    },
    {
      sales: 0,
      cancellations: 0,
      refunds: 0,
    }
  );

  useEffect(() => {
    fetchData();
  }, [startDate]);

  const fetchData = () => {
    setLoading(true);
    Promise.all([
      listOrderReportings({
        filter: {
          buildingId: match.params.cid,
          date: transformDateForQuery(startDate, centerTimezone),
          type: EnumCalendarType.WEEK,
        },
      }),
    ]).finally(() => setLoading(false));
  };

  const onChangeDate = (d: Date) => {
    setDate(d);
    const { startWeek, endWeek } = getWeekInCenterTimezone(d, centerTimezone);
    setStartDate(startWeek);
    setEndDate(endWeek);
  };

  const onPreviousWeek = () => {
    const { startWeek, endWeek } = getPreviousWeek({
      start: startDate,
      end: endDate,
    });

    setStartDate(startWeek);
    setEndDate(endWeek);
    setDate(startWeek);
  };

  const onNextWeek = () => {
    const { startWeek, endWeek } = getNextWeek({
      start: startDate,
      end: endDate,
    });
    setStartDate(startWeek);
    setEndDate(endWeek);
    setDate(startWeek);
  };

  const types = $enum(EnumReportingType).getValues();

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        tools: {
          download: true,
          pan: false,
          reset: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: days.map(d =>
        displayDayLabel(d, currentAppLocale, centerTimezone)
      ),
    },
    colors: [
      "#309bff",
      "#29be95",
      "#f05e5e",
      "#ffe0a4",
      "#928cb8",
      "#d2e5eb",
      "#d417b9",
    ],
    yaxis: {
      title: {
        text: intl.formatMessage({
          id: `page.dashboards.chart.amount.currency`,
        }),
      },
      min: 0,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return getFormattedPrice(val, EnumCurrency.EUR)?.toString();
        },
      },
    },
  };

  const typologies = $enum(EnumProductTypology).getValues();

  const getReportingTypeData = (type: EnumReportingType) => {
    const results = typologies.map(typology => {
      const dataForTypology = dataByTypology.find(
        d => d.typology === typology
      )?.data;

      const dataForType = dataForTypology?.find(d => d.type === type);
      if (!dataForType) {
        return {
          name: typology,
          data: {
            totals: [0],
            rates: [0],
          },
        };
      }

      return {
        name: typology,
        data: {
          totals: dataForType.data.totals,
          rates: dataForType.data.rates,
        },
      };
    });

    return results;
  };

  const getTitle = (type: EnumReportingType) => {
    return `${intl.formatMessage({
      id: `page.dashboards.chart.week.${type.toLowerCase()}.breakdown.title`,
    })}`;
  };

  const getTitleRate = (type: EnumReportingType) => {
    return `${intl.formatMessage({
      id: `page.dashboards.chart.week.${type.toLowerCase()}.rate.breakdown.title`,
    })}`;
  };

  const renderReportingType = (type: EnumReportingType) => {
    const data = getReportingTypeData(type);
    const series0 = data.map(r => {
      const totals = r.data.totals;

      return {
        name: `${intl.formatMessage({
          id: `page.product.typology.${r.name}`,
        })}`,
        data: totals,
      };
    });

    const series1 = data.map(r => {
      const rates = r.data.rates;

      return {
        name: `${intl.formatMessage({
          id: `page.product.typology.${r.name}`,
        })}`,
        data: rates,
      };
    });

    return (
      <div className="m-10">
        <ReactApexChart
          options={{
            ...options,
            title: {
              text: getTitle(type),
              align: "left",
            },
          }}
          series={series0}
          type="line"
          height={350}
        />
        <ReactApexChart
          options={{
            ...options,
            title: {
              text: getTitleRate(type),
              align: "left",
            },
            yaxis: {
              title: {
                text: intl.formatMessage({
                  id: `general.percent`,
                }),
              },
              min: 0,
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val.toFixed(2).toString();
                },
              },
            },
          }}
          series={series1}
          type="bar"
          height={350}
        />
      </div>
    );
  };

  return (
    <>
      <div className={overrideClasses({ loading })} />

      <div className={overrideClasses({ hidden: loading })}>
        <Helmet>
          <title>
            {intl.formatMessage({ id: "page.dashboards.document.title" })}
          </title>
        </Helmet>

        <PageSubheader
          goBackEnabled
          goBackId={`btn-back-occupancy-reporting`}
          goBackURL={backURL}
        />

        <div className="flex items-center gap-4 px-8">
          <FilterCalendar
            date={date}
            locale={currentAppLocale}
            onChangeDate={onChangeDate}
            onPrevious={onPreviousWeek}
            onNext={onNextWeek}
          />
        </div>

        <div className="mx-8">
          <RealTimeSalesCardList {...props} totals={weekTotals} />
        </div>
        <div id="chart_sales">
          {types.map(type => renderReportingType(type))}
        </div>
      </div>
    </>
  );
};

export default injectIntl(RealTimeSalesDashboard);
