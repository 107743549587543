import { Thunk, thunk } from "easy-peasy";
import { Injections } from "../../../store";
import { StoreModel } from "../..";

export interface RefundOpts {
  orderId: string;
  orderItemId?: string;
  reason?: string;
}

export interface CreateRefundExternalizedOpts {
  globalOrderId: string;
  orderId: string;
  orderItemId?: string;
  reason?: string;
}

export interface RetryRefundExternalizedOpts {
  id: number;
}

export interface CreateConnectedAccountOpts {
  id: string;
}

export interface CreateAccountLinksOpts {
  id: string;
  refreshUrl: string;
  returnUrl: string;
}

export interface RefundModel {
  createRefund: Thunk<RefundModel, RefundOpts, Injections, StoreModel>;
  createRefundExternalized: Thunk<
    RefundModel,
    CreateRefundExternalizedOpts,
    Injections,
    StoreModel
  >;
  retryRefundExternalized: Thunk<
    RefundModel,
    RetryRefundExternalizedOpts,
    Injections,
    StoreModel
  >;
  createConnectedAccount: Thunk<
    RefundModel,
    CreateConnectedAccountOpts,
    Injections,
    StoreModel
  >;
  createAccountLinks: Thunk<
    RefundModel,
    CreateAccountLinksOpts,
    Injections,
    StoreModel
  >;
}

export const refundModel: RefundModel = {
  createRefund: thunk(async (_actions, payload, { injections }) => {
    const { refundApiService } = injections;

    return refundApiService.createRefund(payload);
  }),

  createRefundExternalized: thunk(async (_actions, payload, { injections }) => {
    const { refundApiService } = injections;

    return refundApiService.createRefundExternalized(payload);
  }),

  retryRefundExternalized: thunk(async (_actions, payload, { injections }) => {
    const { refundApiService } = injections;

    return refundApiService.retryRefundExternalized(payload);
  }),

  createConnectedAccount: thunk(async (_actions, payload, { injections }) => {
    const { refundApiService } = injections;

    return refundApiService.createConnectedAccount(payload);
  }),

  createAccountLinks: thunk(async (_actions, payload, { injections }) => {
    const { refundApiService } = injections;

    return refundApiService.createAccountLinks(payload);
  }),
};
