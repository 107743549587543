import { action, Action, Thunk, thunk } from "easy-peasy";
import { Injections } from "../../../store";
import { StoreModel } from "../..";
import { IListResponse } from "../../../services/Api/PaymentMethods";
import { IMultiLanguage } from "..";

export interface PaymentMethod {
  id: number;
  enabled: boolean;
  excluded_from_bo: boolean;
  code: string;
  label: string;
  created_at: string;
  updated_at: string;
}

export interface ListUserPaymentMethodsOpts {
  userId: string;
  excludedFromBackOffice?: boolean;
}

export interface ListPaymentMethodsOpts {
  limit?: number;
  offset?: number;
  excludedFromBackOffice?: boolean;
}

export interface CreatePaymentMethodOpts {
  code: string;
  label: IMultiLanguage;
}

export interface GetPaymentMethodOpts {
  id: string;
}

export interface DeletePaymentMethodOpts {
  id: number;
}

export interface UpdatePaymentMethodOpts {
  id: number;
  code?: string;
  label?: IMultiLanguage;
  enabled?: boolean;
}

export interface PaymentMethodsModel {
  userPaymentMethods: {
    items: PaymentMethod[];
    total: number;
    has_more: boolean;
  };
  setUserPaymentMethods: Action<
    PaymentMethodsModel,
    { items: PaymentMethod[]; total: number; has_more: boolean }
  >;
  paymentMethods: {
    items: PaymentMethod[];
    total: number;
    has_more: boolean;
  };
  paymentMethod: PaymentMethod | null;
  setPaymentMethods: Action<
    PaymentMethodsModel,
    { items: PaymentMethod[]; total: number; has_more: boolean }
  >;
  setPaymentMethod: Action<PaymentMethodsModel, PaymentMethod | null>;
  removePaymentMethod: Action<PaymentMethodsModel, DeletePaymentMethodOpts>;
  listUserPaymentMethods: Thunk<
    PaymentMethodsModel,
    ListUserPaymentMethodsOpts,
    Injections,
    StoreModel
  >;
  listPaymentMethods: Thunk<
    PaymentMethodsModel,
    ListPaymentMethodsOpts,
    Injections,
    StoreModel
  >;
  createPaymentMethod: Thunk<
    PaymentMethodsModel,
    CreatePaymentMethodOpts,
    Injections,
    StoreModel
  >;
  getPaymentMethod: Thunk<
    PaymentMethodsModel,
    GetPaymentMethodOpts,
    Injections,
    StoreModel
  >;
  deletePaymentMethod: Thunk<
    PaymentMethodsModel,
    DeletePaymentMethodOpts,
    Injections,
    StoreModel
  >;
  updatePaymentMethod: Thunk<
    PaymentMethodsModel,
    UpdatePaymentMethodOpts,
    Injections,
    StoreModel
  >;
}

export const paymentMethodsModel: PaymentMethodsModel = {
  userPaymentMethods: { items: [], total: 0, has_more: false },
  setUserPaymentMethods: action((state, payload) => {
    state.userPaymentMethods = payload;
  }),
  paymentMethods: { items: [], total: 0, has_more: false },
  paymentMethod: null,
  setPaymentMethods: action((state, payload) => {
    state.paymentMethods = payload;
  }),
  setPaymentMethod: action((state, payload) => {
    state.paymentMethod = payload;
  }),
  removePaymentMethod: action((state, payload) => {
    state.paymentMethods.items = state.paymentMethods.items.filter(
      p => p.id !== payload.id
    );
    state.paymentMethods.total = state.paymentMethods.total - 1;
  }),
  listUserPaymentMethods: thunk(
    async (actions, payload: ListUserPaymentMethodsOpts, { injections }) => {
      const { paymentMethodsService } = injections;
      const response = await paymentMethodsService.listUserPaymentMethods(
        payload
      );
      if (response.status === 200 && response.data.success) {
        const res: IListResponse<PaymentMethod> = response.data;
        actions.setUserPaymentMethods(res.data);
      }
    }
  ),
  listPaymentMethods: thunk(
    async (actions, payload: ListPaymentMethodsOpts, { injections }) => {
      const { paymentMethodsService } = injections;
      const response = await paymentMethodsService.listPaymentMethods(payload);
      if (response.status === 200 && response.data.success) {
        const res: IListResponse<PaymentMethod> = response.data;
        actions.setPaymentMethods(res.data);
      }
    }
  ),
  createPaymentMethod: thunk(
    async (actions, payload: CreatePaymentMethodOpts, { injections }) => {
      const { paymentMethodsService } = injections;
      const response = await paymentMethodsService.createPaymentMethod(payload);
      if (response.status === 201 && response.data.success) {
        actions.setPaymentMethod(response.data.data);
      }
    }
  ),
  getPaymentMethod: thunk(
    async (actions, payload: GetPaymentMethodOpts, { injections }) => {
      const { paymentMethodsService } = injections;
      const response = await paymentMethodsService.getPaymentMethod(payload.id);
      if (response.status === 200 && response.data.success) {
        actions.setPaymentMethod(response.data.data);
      }
    }
  ),
  deletePaymentMethod: thunk(
    async (actions, payload: DeletePaymentMethodOpts, { injections }) => {
      const { paymentMethodsService } = injections;
      const response = await paymentMethodsService.deletePaymentMethod(
        payload.id
      );
      if (response.status === 200 && response.data.success) {
        actions.setPaymentMethod(null);
        actions.removePaymentMethod(payload);
      }
    }
  ),
  updatePaymentMethod: thunk(
    async (
      actions,
      payload: UpdatePaymentMethodOpts,
      { injections, getState }
    ) => {
      const { paymentMethodsService } = injections;

      const response = await paymentMethodsService.updatePaymentMethod(payload);

      if (response.status === 200 && response.data.success) {
        const updatedPaymentMethod = response.data.data;
        actions.setPaymentMethod(updatedPaymentMethod);

        const state = getState();
        const paymentMethodIndex = state.paymentMethods.items.findIndex(
          p => p.id === updatedPaymentMethod.id
        );

        if (paymentMethodIndex !== -1) {
          const newPaymentMethodsItems = [...state.paymentMethods.items];
          newPaymentMethodsItems.splice(
            paymentMethodIndex,
            1,
            updatedPaymentMethod
          );

          actions.setPaymentMethods({
            ...state.paymentMethods,
            items: newPaymentMethodsItems,
          });
        }
      }

      return response.data;
    }
  ),
};
