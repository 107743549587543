import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Location } from "history";
import { injectIntl, IntlShape } from "react-intl";
import { toast } from "react-toastify";
import { TableChangeParams } from "../../../components/Table/types";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import PageSubheader from "../../../components/PageSubheader";
import { EnumPaths } from "../../../utils/navigation";
import history from "../../../history";
import Table from "../../../components/Table";
import Dropdown, {
  DropdownValues,
} from "../../../components/Tailwind/Dropdown";
import images from "../../../images";
import Badge from "../../../components/Tailwind/Badge";
import { PaymentMethod } from "../../../lib/ground-aws-graphql-core/models/Api/PaymentMethods";
import { getTranslation } from "../../../utils/translation";
import ConfirmModal from "../../../utils/modal/confirm";
import IntlMessages from "../../../utils/messages";
import { displayDayDDMMYYYY_HHMM } from "../../../utils/config";

interface Props {
  intl: IntlShape;
  location: Location<{
    pageIndex: number;
    limit: number;
    type: string;
  }>;
}

const DEFAULT_LIMIT = 20;

const ListPaymentMethods = (props: Props): JSX.Element => {
  const { intl, location } = props;
  const [loading, setLoading] = useState(false);

  const [showDeletePaymentMethodModal, setShowDeletePaymentMethodModal] =
    useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState<number>();
  const [initialParams, setInitialParams] = useState<TableChangeParams | null>(
    location?.state
  );

  const listPaymentMethods = GroundGraphqlContextStore.useStoreActions(
    actions => actions.paymentMethods.listPaymentMethods
  );

  const paymentMethods = GroundGraphqlContextStore.useStoreState(
    state => state.paymentMethods.paymentMethods.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.paymentMethods.paymentMethods.total
  );

  const setPaymentMethod = GroundGraphqlContextStore.useStoreActions(
    actions => actions.paymentMethods.setPaymentMethod
  );

  const deletePaymentMethod = GroundGraphqlContextStore.useStoreActions(
    actions => actions.paymentMethods.deletePaymentMethod
  );

  useEffect(() => {
    if (location?.state) {
      fetchData(location?.state?.pageIndex, true, location?.state?.limit);
    } else fetchData();
  }, []);

  const fetchData = (pageIndex = 0, loader = true, limit = DEFAULT_LIMIT) => {
    if (loader) setLoading(true);

    listPaymentMethods({
      offset: pageIndex * limit,
      limit,
    }).finally(() => setLoading(false));
  };

  const addNewPaymentMethod = () => {
    history.push(
      `/${EnumPaths.ROOT}/${EnumPaths.PAYMENT_METHODS}/${EnumPaths.CREATION_MODE}`
    );
  };

  const handleOnChange = (changeParams: TableChangeParams) => {
    const { pageIndex, limit } = changeParams;

    setInitialParams(changeParams);

    fetchData(pageIndex, false, limit);
  };

  const handleEditPaymentMethod = (id: number) => {
    const editLink = `/${EnumPaths.ROOT}/${EnumPaths.PAYMENT_METHODS}/${id}/${EnumPaths.EDIT_MODE}`;
    setPaymentMethod(null);
    history.push(editLink);
  };

  const handleDelete = () => {
    setLoading(true);
    setShowDeletePaymentMethodModal(false);
    deletePaymentMethod({ id: paymentMethodId! })
      .then(() => {
        toast(
          intl.formatMessage({
            id: "page.payment.method.delete.success",
          }),
          { type: "success" }
        );
      })
      .catch(() => {
        toast(
          intl.formatMessage({
            id: "page.payment.method.delete.error",
          }),
          {
            type: "error",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tableHead = [
    "page.payment.method.label",
    "page.payment.method.code",
    "page.payment.method.enabled.in.bo",
    "general.status",
    "page.payment.method.created_at",
    "page.payment.method.updated_at",
    "general.actions",
  ];

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const tableBody = paymentMethods?.map((paymentMethod: PaymentMethod) => {
    const {
      id,
      code,
      label,
      enabled,
      excluded_from_bo,
      created_at,
      updated_at,
    } = paymentMethod;
    const editLink = `/${EnumPaths.ROOT}/${EnumPaths.PAYMENT_METHODS}/${id}/${EnumPaths.EDIT_MODE}`;

    const dropdownValues: DropdownValues[] = [
      {
        id: "edit_payment_method",
        label: "page.list.payment.methods.edit",
        icon: images.edit,
        link: editLink,
      },
      {
        id: "delete",
        label: "general.delete",
        icon: images.deleteIcon,
        onClick: () => {
          setShowDeletePaymentMethodModal(!showDeletePaymentMethodModal);
          setPaymentMethodId(id);
        },
      },
    ];

    const paymentMethodElements = [
      {
        element: getTranslation(label),
        onCellClick: () => handleEditPaymentMethod(id),
      },
      {
        element: code,
        onCellClick: () => handleEditPaymentMethod(id),
      },
      {
        element: !excluded_from_bo ? (
          <Badge label="general.yes" bgClassName="bg-ground-green-100" />
        ) : (
          <Badge label="general.no" bgClassName="bg-red-500" />
        ),
        onCellClick: () => handleEditPaymentMethod(id),
      },
      {
        element: enabled ? (
          <Badge label="general.actif" bgClassName="bg-ground-green-100" />
        ) : (
          <Badge label="general.inactif" bgClassName="bg-red-500" />
        ),
        onCellClick: () => handleEditPaymentMethod(id),
      },
      {
        element: displayDayDDMMYYYY_HHMM(created_at, centerTimezone),
        onCellClick: () => handleEditPaymentMethod(id),
      },
      {
        element: displayDayDDMMYYYY_HHMM(updated_at, centerTimezone),
        onCellClick: () => handleEditPaymentMethod(id),
      },
      {
        element: (
          <Dropdown
            values={dropdownValues}
            dataCy={`payment-method-dropdown-${id}`}
          />
        ),
      },
    ];

    return {
      rowElements: paymentMethodElements,
    };
  });

  return (
    <div>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: `page.list.payment.methods.document.title`,
          })}
        </title>
      </Helmet>

      <PageSubheader
        title="page.list.payment.methods.title"
        nbOfResults={total}
        buttonRightTitle="page.list.payment.methods.create.payment.method"
        buttonRightAction={addNewPaymentMethod}
        buttonRightId="btn-create-payment-method"
      />

      <Table
        initialParams={initialParams}
        head={tableHead}
        body={tableBody}
        noDataText="page.list.payment.methods.empty"
        onChange={handleOnChange}
        paginationLimit={DEFAULT_LIMIT}
        paginationTotal={total}
        loading={loading}
        setLoading={setLoading}
        className="px-8"
      />

      <ConfirmModal
        isOpen={showDeletePaymentMethodModal}
        onRequestClose={() =>
          setShowDeletePaymentMethodModal(!showDeletePaymentMethodModal)
        }
        toggle={() =>
          setShowDeletePaymentMethodModal(!showDeletePaymentMethodModal)
        }
        handleConfirm={handleDelete}
        content={<IntlMessages id="page.list.payment.methods.delete" />}
      />
    </div>
  );
};

export default injectIntl(ListPaymentMethods);
