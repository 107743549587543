import React, { useState } from "react";
import classnames from "classnames";
import IntlMessages from "../../../utils/messages";

interface Props {
  name: string;
  label: string;
  index: number;
  last?: boolean;
  value: boolean;
  onChange: (checked: boolean, e) => any;
  useIntl: boolean;
  alternate?: boolean;
}

const Checkbox = (props: Props): JSX.Element => {
  const { alternate, name, index, useIntl, label, value, last, onChange } =
    props;
  const [checked, setChecked] = useState(value);

  const containerClassName = id =>
    classnames(
      "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:py-2 sm:px-8 px-2 py-2",
      {
        "bg-ground-white-200": (alternate || true) && id % 2 === 0,
        "rounded-b-10": last,
      }
    );

  return (
    <>
      <div className={containerClassName(index)}>
        <div className="mt-1 sm:mt-0 sm:col-span-2 sm:col-start-2 h-8 flex items-center">
          <input
            name={name}
            type="checkbox"
            className="form-checkbox h-4 w-4 text-ground-green-100 transition duration-150 ease-in-out"
            checked={checked}
            onChange={e => {
              setChecked(e.target.checked);
              onChange(e.target.checked, e);
            }}
          />
          <span className="ml-2">
            {useIntl && <IntlMessages id={label} />}
            {!useIntl && `${label}`}
          </span>
        </div>
      </div>
    </>
  );
};

export default Checkbox;
