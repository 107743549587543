import React from "react";
import classnames from "classnames";
import ReactCountryFlag from "react-country-flag";

interface Props {
  value: string | null | undefined | Record<string, string>;
  setCurrentLocale: (locale: string) => void;
  currentLocale: string;
  locales: string[];
}

const LocaleSwitcher = (props: Props): JSX.Element => {
  const { setCurrentLocale, locales, value, currentLocale } = props;

  return (
    <div className="flex flex-row items-center gap-1">
      {locales.map(locale => (
        <button
          key={locale}
          onClick={() => setCurrentLocale(locale)}
          className={classnames("text-black whitespace-no-wrap", {
            "text-opacity-25": !value?.[locale],
            "text-lg": currentLocale === locale,
          })}
          title={locale}
          type="button"
        >
          <ReactCountryFlag svg countryCode={locale.split("-")[1]} />
        </button>
      ))}
    </div>
  );
};

export default LocaleSwitcher;
