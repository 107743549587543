import React from "react";

interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props): JSX.Element => {
  return (
    <main className="oneColumn bg-white pt-20">
      <div className="dashboard-wrapper">{children}</div>
    </main>
  );
};

export default Layout;
