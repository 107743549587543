import React, { useState, useEffect } from "react";
import { Location } from "history";
import { toast } from "react-toastify";
import { match as Match } from "react-router-dom";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import StarRatings from "react-star-ratings";
import Switch, { SwitchChangeEventHandler } from "rc-switch";
import IntlMessages from "../../../../utils/messages";
import PageSubheader from "../../../../components/PageSubheader";
import Block from "../../../../components/Tailwind/Block";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import { withTwoColLayout } from "../../../../utils";
import { getTranslation } from "../../../../utils/translation";
import IncidentCommentsTable from "../incident-comments-list/table";
import DropdownIncidentsStatus from "../../../../components/Tailwind/DropdownIncidentsStatus";
import ConfirmModal from "../../../../utils/modal/confirm";
import { getLocale } from "../../../../lang/index";
import overrideClasses from "../../../../utils/overrideClasses";
import {
  displayDayDDMMYYYY_HHMM,
  getCypressTestId,
} from "../../../../utils/config";
import LinkButton from "../../../../components/Tailwind/LinkButton";

interface Props {
  match: Match<{ cid: string; id: string }>;
  intl: IntlShape;
  backURL: string;
  location: Location;
}

const DEFAULT_LIMIT = 20;

const Incident = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [issuerName, setIssuerName] = useState("");
  const [issuerNameError, setIssuerNameError] = useState(false);
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState(false);
  const [priority, setPriority] = useState<boolean>();

  const { intl, match, backURL, location } = props;

  const getIncident = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.getIncident
  );
  const incident = GroundGraphqlContextStore.useStoreState(
    state => state.incidents.incident
  );
  const setIncident = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.setIncident
  );
  const listMedia = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.listMedia
  );
  const listIncidentComments = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.listIncidentComments
  );
  const incidentComments = GroundGraphqlContextStore.useStoreState(
    state => state.incidents.incidentComments
  );
  const total = GroundGraphqlContextStore.useStoreState(
    state => state.incidents.incidentComments.total
  );
  const pictures = GroundGraphqlContextStore.useStoreState(
    state => state.incidents.pictures
  );
  const listIncidentStatus = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.listIncidentStatus
  );
  const incidentsStatus = GroundGraphqlContextStore.useStoreState(
    state => state.incidents.incidentsStatus.items
  );
  const commentIncident = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.commentIncident
  );
  const updateIncident = GroundGraphqlContextStore.useStoreActions(
    actions => actions.incidents.updateIncident
  );
  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const fetchIncidentComments = (offset: number) => {
    setLoading(true);
    listIncidentComments({
      incidentId: match.params.id,
      offset,
      limit: DEFAULT_LIMIT,
    }).finally(() => setLoading(false));
    listIncidentStatus(null);
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getIncident({ id: match.params.id }),
      listMedia({ id: match.params.id }),
      fetchIncidentComments(0),
    ]).finally(() => setLoading(false));

    return () => {
      setIncident(null);
    };
  }, []);

  const onChangePriority: SwitchChangeEventHandler = checked => {
    setPriority(checked);

    if (incident) {
      updateIncident({
        id: incident.id,
        high_priority: checked,
      })
        .then(() => {
          toast(
            intl.formatMessage({
              id: "page.incidents.change.priority.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          toast(
            intl.formatMessage({
              id: "page.incidents.change.priority.error",
            }),
            {
              type: "error",
            }
          );
        });
    }
  };

  const askCommentConfirmation = () => {
    let hasFormErrors = false;
    if (!issuerName && !issuerNameError) {
      setIssuerNameError(true);
      hasFormErrors = true;
    }

    if (!comment && !commentError) {
      setCommentError(true);
      hasFormErrors = true;
    }

    if (hasFormErrors) return;

    setIsConfirmModalOpen(true);
  };

  const confirmCommentIncident = () => {
    if (incident && issuerName && comment) {
      const locale = localStorage.getItem("locale");
      const currentAppLocale = getLocale(locale);

      commentIncident({
        id: incident.id,
        issuer: issuerName,
        comment: { [currentAppLocale.locale]: comment },
      })
        .then(() => {
          toast(
            intl.formatMessage({
              id: "page.incidents.add.comment.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          toast(
            intl.formatMessage({
              id: "page.incidents.add.comment.error",
            }),
            {
              type: "error",
            }
          );
        });

      setIsConfirmModalOpen(false);
      setIsCommentModalOpen(false);
      setIssuerName("");
      setComment("");
    }
  };

  const CommentModalContent = (
    <div className="space-y-6 w-full">
      <div className="flex flex-col">
        <label
          htmlFor="issuer-name"
          className="text-neutral-900 font-semibold text-sm"
        >
          <IntlMessages id="page.incidents.add.comment.issuer.name" />
        </label>
        <input
          name="issuer-name"
          id="issuer-name"
          className={overrideClasses(
            "max-w-xs bg-gray-150 placeholder-ground-gray-100 text-ground-black-100 text-xs leading-5 outline-none px-4 py-2 rounded",
            {
              "text-red-500 placeholder-red-300 border-red-300 focus:border-red-300 focus:shadow-outline-red":
                issuerNameError,
            }
          )}
          value={issuerName}
          placeholder={intl.formatMessage({
            id: "page.incidents.add.comment.issuer.name.placeholder",
          })}
          onChange={e => {
            setIssuerName(e.target.value);
            if (issuerNameError) setIssuerNameError(false);
          }}
        />
        {issuerNameError && (
          <p className="mt-1 text-11px text-red-600" id="issuer-name-error">
            <IntlMessages id="general.required.field" />
          </p>
        )}
      </div>

      <div className="flex flex-col w-full">
        <span className="text-ground-gray-100 font-normal text-xs">
          <IntlMessages id="page.incidents.add.comment.max.char" />
        </span>
        <textarea
          name="incident-comment"
          id="incident-comment"
          className={overrideClasses(
            "bg-gray-150 placeholder-ground-gray-100 text-ground-black-100 text-xs leading-5 outline-none px-4 py-2 rounded w-full",
            {
              "text-red-500 placeholder-red-300 border-red-300 focus:border-red-300 focus:shadow-outline-red":
                commentError,
            }
          )}
          value={comment}
          placeholder={intl.formatMessage({
            id: "page.incidents.add.comment.placeholder",
          })}
          rows={5}
          onChange={e => {
            setComment(e.target.value);
            if (commentError) setCommentError(false);
          }}
        />
        {commentError && (
          <p className="mt-1 text-11px text-red-600" id="comment-error">
            <IntlMessages id="general.required.field" />
          </p>
        )}
      </div>
    </div>
  );

  return !incident ? (
    <div className="loading" />
  ) : (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={overrideClasses({ loading })} />

      {/* Hide when loading */}
      <>
        <Helmet>
          <title>
            {intl.formatMessage({
              id: "page.detail.incidents.document.title",
            })}
          </title>
        </Helmet>

        <PageSubheader
          goBackEnabled
          goBackId="btn-back-incident"
          goBackURL={backURL}
          location={location}
          title="page.detail.incidents.title"
        />

        <div className={overrideClasses("px-8 pb-32", { hidden: loading })}>
          <Block>
            <div className="py-3 sm:py-5 border-b border-gray-200 px-2 sm:px-6 flex flex-col">
              <div className="flex flex-col sm:flex-row justify-between sm:items-center">
                <div className="mt-1">
                  <div className="text-17px text-neutral-900 leading-5">
                    <IntlMessages
                      id="page.detail.incident.number"
                      values={{ ref: incident?.ref }}
                    />
                  </div>
                  {incident?.created_at && (
                    <div className="text-14px text-ground-gray-100 leading-relaxed">
                      {displayDayDDMMYYYY_HHMM(
                        incident.created_at,
                        centerTimezone
                      )}
                    </div>
                  )}
                </div>

                <div className="flex flex-1 sm:w-1/6 justify-end">
                  {incident && incident.status && (
                    <div className="flex flex-row items-center space-x-2">
                      <DropdownIncidentsStatus
                        incident={incident}
                        incidentsStatus={incidentsStatus}
                        intl={intl}
                        className="items-start"
                      />

                      <LinkButton
                        id="btn-add-incident-comment"
                        name="btn-add-incident-comment"
                        label="page.incidents.add.comment"
                        onClick={() =>
                          setIsCommentModalOpen(!isCommentModalOpen)
                        }
                      />

                      {incident.rating && (
                        <div className="text-neutral-900 font-semibold ml-auto">
                          <IntlMessages id="page.detail.client.rating" />
                          <StarRatings
                            rating={Math.floor(incident?.rating)}
                            starRatedColor="rgba(234, 198, 3, 0.5)"
                            numberOfStars={5}
                            name="rating"
                            starDimension="15px"
                            starSpacing="0px"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="py-3 bg-gray-100 px-2 sm:px-6 flex flex-col">
              <div className="flex flex-col sm:flex-row justify-between sm:items-start">
                <div className="flex flex-col text-14px leading-relaxed">
                  <p className="text-neutral-900 font-semibold">
                    <IntlMessages id="page.detail.incident.type" />
                  </p>
                  <p className=" text-ground-gray-100">
                    {getTranslation(JSON.stringify(incident?.type.label))}
                  </p>
                </div>

                <div className="flex flex-col text-14px leading-relaxed">
                  <p className="text-neutral-900 font-semibold">
                    <IntlMessages id="general.client" />
                  </p>
                  <p className="text-ground-gray-100">
                    {incident?.user?.first_name} {incident?.user?.last_name}
                  </p>
                  <p className="text-ground-blue-100">
                    {incident?.user?.email}
                  </p>
                </div>

                <div className="flex text-14px leading-relaxed space-x-2">
                  <p className="text-neutral-900 font-semibold">
                    <IntlMessages id="page.detail.incident.priority" />
                  </p>
                  <Switch
                    id="switch_incident_priority"
                    data-cy="switch_incident_priority"
                    data-testid={getCypressTestId({
                      id: incident.id.toString(),
                    })}
                    checked={priority ?? !!incident.priority}
                    onChange={onChangePriority}
                  />
                </div>
              </div>
            </div>

            <div className="py-3 bg-gray-100 px-2 sm:px-6 flex flex-col">
              <div className="flex flex-col sm:flex-row justify-between sm:items-start lg:w-1/3 sm:w-full">
                <div className="flex flex-col text-14px leading-relaxed">
                  <p className="text-neutral-900 font-semibold">
                    <IntlMessages id="page.detail.incident.reason" />
                  </p>
                  <p className=" text-ground-gray-100">
                    {getTranslation(JSON.stringify(incident?.reason.label))}
                  </p>
                </div>
              </div>
            </div>

            <div className="pt-3 pb-10 bg-gray-100 px-2 sm:px-6 flex flex-col">
              <div className="flex flex-col sm:flex-row justify-between sm:items-start lg:w-1/3 sm:w-full">
                <div className="flex flex-col text-14px leading-relaxed">
                  <p className="text-neutral-900 font-semibold">
                    <IntlMessages id="page.detail.incident.description" />
                  </p>
                  <p className=" text-ground-gray-100">
                    {getTranslation(JSON.stringify(incident?.description))}
                  </p>
                </div>
              </div>
            </div>

            {incident?.location && (
              <div className="pt-3 pb-10 bg-gray-100 px-2 sm:px-6 flex flex-col">
                <div className="flex flex-col sm:flex-row justify-between sm:items-start lg:w-1/3 sm:w-full">
                  <div className="flex flex-col text-14px leading-relaxed">
                    <p className="text-neutral-900 font-semibold">
                      <IntlMessages id="page.detail.incident.location" />
                    </p>
                    <p className="text-ground-gray-100">
                      {getTranslation(incident?.location)}
                    </p>
                  </div>
                </div>
              </div>
            )}

            {pictures &&
              pictures.map((picture, index) => (
                <div
                  key={index}
                  className="pb-10 bg-gray-100 px-2 sm:px-6 flex flex-col"
                >
                  <div className="flex flex-col sm:flex-row justify-between sm:items-start lg:w-1/3 sm:w-full">
                    <div className="flex flex-col text-14px leading-relaxed">
                      <img src={picture} alt="" />
                    </div>
                  </div>
                </div>
              ))}
          </Block>

          <IncidentCommentsTable
            incidentComments={incidentComments.items}
            total={total}
            onChange={({ pageIndex }) =>
              fetchIncidentComments(pageIndex * DEFAULT_LIMIT)
            }
          />
        </div>

        <ConfirmModal
          isOpen={isCommentModalOpen}
          toggle={() => setIsCommentModalOpen(!isCommentModalOpen)}
          onRequestClose={() => setIsCommentModalOpen(!isCommentModalOpen)}
          handleConfirm={askCommentConfirmation}
          description={CommentModalContent}
        />

        <ConfirmModal
          isOpen={isConfirmModalOpen}
          toggle={() => setIsConfirmModalOpen(!isConfirmModalOpen)}
          onRequestClose={() => setIsConfirmModalOpen(!isConfirmModalOpen)}
          handleConfirm={confirmCommentIncident}
          content={<IntlMessages id="page.incidents.add.comment.confirm" />}
        />
      </>
    </>
  );
};

export default withTwoColLayout(injectIntl(Incident));
