import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Location } from "history";
import { match as Match } from "react-router-dom";
import { IntlShape, useIntl } from "react-intl";
import { withTwoColLayout } from "../../../../utils";
import Table from "../../../../components/Table";
import { TableChangeParams } from "../../../../components/Table/types";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import { Visitor } from "../../../../lib/ground-aws-graphql-core/models/Api/Visits";
import { displayDayDDMMYYYY_HHMM } from "../../../../utils/config";
import PageSubheader from "../../../../components/PageSubheader";

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
  backURL: string;
  location: Location<{
    pageIndex: number;
    limit: number;
    type: string;
  }>;
}

const DEFAULT_LIMIT = 20;

const ListVisitors = (props: Props) => {
  const { match, backURL, location } = props;
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [initialParams, setInitialParams] = useState<TableChangeParams | null>(
    location?.state
  );

  const listVisitors = GroundGraphqlContextStore.useStoreActions(
    actions => actions.visits.listVisitors
  );

  const visitors = GroundGraphqlContextStore.useStoreState(
    state => state.visits.visitors.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.visits.visitors.total
  );

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  useEffect(() => {
    if (location?.state) {
      fetchData(location?.state?.pageIndex, true, location?.state?.limit);
    } else fetchData();
  }, []);

  const fetchData = (pageIndex = 0, loader = true, limit = DEFAULT_LIMIT) => {
    if (loader) setLoading(true);

    listVisitors({
      buildingId: match.params.cid,
      offset: pageIndex * limit,
      limit,
    }).finally(() => setLoading(false));
  };

  const tableHead = [
    "general.visitor",
    "general.email",
    "general.company",
    "general.check.in",
  ];

  const tableBody = visitors?.map((visitor: Visitor) => {
    const { last_name, first_name, company_name, checked_in, email } = visitor;

    const visitorElements = [
      { element: `${first_name} ${last_name}` },
      { element: email },
      { element: company_name },
      { element: displayDayDDMMYYYY_HHMM(checked_in, centerTimezone) },
    ];

    return {
      rowElements: visitorElements,
    };
  });

  const handleOnChange = (changeParams: TableChangeParams) => {
    const { pageIndex, limit } = changeParams;

    setInitialParams(changeParams);

    fetchData(pageIndex, false, limit);
  };

  return (
    <div>
      <Helmet>
        <title>
          {intl.formatMessage({ id: "page.list.visitors.document.title" })}
        </title>
      </Helmet>

      <PageSubheader
        goBackEnabled
        goBackId="btn-back-visit"
        goBackURL={backURL}
        location={location}
        title="page.list.visitors.title"
        nbOfResults={total}
      />

      <Table
        initialParams={initialParams}
        head={tableHead}
        body={tableBody}
        noDataText="page.list.visitors.empty"
        onChange={handleOnChange}
        paginationTotal={total}
        paginationLimit={DEFAULT_LIMIT}
        loading={loading}
        setLoading={setLoading}
        className="px-8 py-8"
      />
    </div>
  );
};

export default withTwoColLayout(ListVisitors);
