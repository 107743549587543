import { ErrorGraphQL } from "./../../services/coreUtils";
import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel, GqlQuery } from "../";
import { ModelParticipationConnection } from "./../Participation/index";
import { EventType } from "./../EventType/index";
import { BackOfficeUser } from "./../BackOfficeUser/index";
import { User } from "../User";
import { Operator } from "../Operator";

import {
  CreateEventInput as CreateEvent,
  UpdateEventInput as UpdateEvent,
  BaseDeleteInput as DeleteEvent,
  SearchableEventFilterInput,
  SearchableEventSortInput,
  EnumLocale,
} from "../../api/graphql/types";

export interface Event {
  id: string;
  title: any;
  description: any;
  author: BackOfficeUser;
  picture?: string | null;
  start: string;
  end: string;
  publicationDate: string;
  unpublicationDate: string;
  type: EventType;
  likes?: ModelLikeConnection | null;
  participations?: ModelParticipationConnection | null;
  enabled: boolean;
  markForDelete: boolean;
}

export interface Like {
  operator?: Operator;
  id: string;
  user?: User;
}

export interface ModelLikeConnection {
  items: [Like] | null;
  total?: number | null;
}

export interface SearchEventsOpts {
  filter: SearchableEventFilterInput;
  locale?: EnumLocale;
  sort?: SearchableEventSortInput;
  from?: number;
  limit?: number;
  gql?: string | null;
}

export interface SearchEventsOutput {
  items: [Event] | null;
  total?: number | null;
}

// Interface declaration
export interface EventModel {
  events: SearchEventsOutput;
  event: Event | null;
  setEvents: Action<EventModel, SearchEventsOutput>;
  setEvent: Action<EventModel, Event | null>;
  addEvent: Action<EventModel, Event>;
  removeEvent: Action<EventModel, Event>;
  replaceEvent: Action<EventModel, Event>;
  getEvent: Thunk<EventModel, GqlQuery, Injections>;
  createEvent: Thunk<EventModel, CreateEvent, Injections>;
  updateEvent: Thunk<EventModel, UpdateEvent, Injections>;
  deleteEvent: Thunk<EventModel, DeleteEvent, Injections>;
  searchEvents: Thunk<EventModel, SearchEventsOpts, Injections, StoreModel>;
}

export const eventModel: EventModel = {
  events: { items: null, total: null },
  event: null,
  setEvents: action((state, data) => {
    state.events = data;
  }),

  setEvent: action((state, payload) => {
    state.event = payload;
  }),

  addEvent: action((state, payload) => {
    if (state.events.items) {
      state.events.items.push(payload);
    } else {
      state.events["items"] = [payload];
    }
  }),

  removeEvent: action((state, payload) => {
    if (state.events.items) {
      const index = state.events.items!.findIndex(e => e.id === payload.id);
      state.events.items!.splice(index, 1);
    }
  }),

  replaceEvent: action((state, payload) => {
    state.event = payload;
    if (state.events.items) {
      const index = state.events.items!.findIndex(e => e.id === payload.id);
      if (index >= 0) state.events.items!.splice(index, 1, payload);
    }
  }),

  getEvent: thunk(async (actions, payload, { injections }) => {
    const { eventService } = injections;
    const response = await eventService.getEvent(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    actions.setEvent(response.data.getEvent);

    return response.data.getEvent;
  }),

  createEvent: thunk(async (actions, payload, { injections }) => {
    const { eventService } = injections;
    const response = await eventService.createEvent(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.createEvent;
    actions.setEvent(item);
    actions.addEvent(item);

    return item;
  }),

  updateEvent: thunk(async (actions, payload, { injections }) => {
    const { eventService } = injections;
    const response = await eventService.updateEvent(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    const item = response.data.updateEvent;
    actions.setEvent(item);
    actions.replaceEvent(item);

    return item;
  }),

  deleteEvent: thunk(async (_actions, payload, { injections }) => {
    const { eventService } = injections;
    const response = await eventService.deleteEvent(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    return true;
  }),

  searchEvents: thunk(async (actions, payload, { injections }) => {
    const { eventService } = injections;
    const response = await eventService.searchEvents(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.searchEvents) {
      const data = response.data.searchEvents;
      actions.setEvents(data);

      return data;
    }

    return null;
  }),
};
