import React, { useState } from "react";
import { match as Match } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../../../../components/Tailwind/Block/Header";
import GroundFormik from "../../../../../components/Tailwind/Form";
import { EnumAction, FormValue } from "../../../../../utils/types";
import Footer from "../../../../../components/Tailwind/Block/Footer";
import { injectIntl, IntlShape } from "react-intl";
import {
  CreateCenterTypeInput,
  EnumPermissionEntity,
  UpdateCenterTypeInput,
} from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import { CenterType } from "../../../../../lib/ground-aws-graphql-core/models/CenterType";

interface Props {
  isOpen: boolean;
  toggle: (e) => void;
  type: CenterType | null | undefined;
  action: string | null;
  match: Match;
  intl: IntlShape;
}

const CenterTypeForm = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(true);

  const { type, toggle, action } = props;

  const createTypeAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.centerType.createCenterType
  );

  const updateCenterTypeAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.centerType.updateCenterType
  );

  const createType = (values, markForDelete = false) => {
    const { action } = props;

    // no errors
    if (action === EnumAction.CREATE) {
      const payload: CreateCenterTypeInput = {
        name: values.name,
        type: values.type,
        enabled,
        markForDelete,
      };

      setLoading(true);
      createTypeAction(payload)
        .then(() => {
          toggle(action);
          toast(
            props.intl.formatMessage({
              id: "general.create.type.building",
            }),
            { type: "success" }
          );
          setLoading(false);
        })
        .catch(() => {
          toggle(action);
          toast(
            props.intl.formatMessage({
              id: "general.create.type.building.error",
            }),
            {
              type: "error",
            }
          );
          setLoading(false);
        });
    } else {
      // update center type
      const payload: UpdateCenterTypeInput = {
        id: type!.id!,
        name: values.name,
        type: values.type,
        enabled,
        markForDelete,
      };

      setLoading(true);
      updateCenterTypeAction(payload)
        .then(() => {
          toggle(action);
          toast(
            props.intl.formatMessage({
              id: "general.create.type.building",
            }),
            { type: "success" }
          );
          setLoading(false);
        })
        .catch(() => {
          toggle(action);
          toast(
            props.intl.formatMessage({
              id: "general.create.type.building.error",
            }),
            {
              type: "error",
            }
          );
          setLoading(false);
        });
    }
  };

  const handleSubmit = values => {
    createType(values);
  };

  const values: FormValue[] = [
    {
      id: "name",
      name: "name",
      type: "text",
      label: "page.center.type.id",
      placeholder: "page.center.type.id",
      required: true,
      value: type?.name,
      useIntl: true,
    },
    {
      id: "type",
      name: "type",
      type: "text",
      label: "page.center.type.name",
      placeholder: "page.center.type.name",
      required: true,
      value: type?.type,
      translatable: true,
      useIntl: true,
    },
  ];

  return loading ? (
    <div className="loading" />
  ) : (
    <>
      <Header
        item={type}
        title={
          props.action === EnumAction.CREATE
            ? "page.center.create.center.type"
            : "page.center.update.center.type"
        }
        entity={EnumPermissionEntity.CENTER_TYPE}
        checked={enabled}
        onChange={e => {
          setEnabled(e);
        }}
        className="border-b border-gray-200"
      />
      <GroundFormik item={type} values={values} onSubmit={handleSubmit}>
        <Footer
          item={type}
          index={values.length + 1}
          labels={["general.cancel", "general.delete"]}
          onCancel={() => toggle(action)}
        />
      </GroundFormik>
    </>
  );
};

export default injectIntl(CenterTypeForm);
