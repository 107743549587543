import { ActionTypes, getAttributeValueByAttributeKey } from "../types";
import {
  HoursRangeItem,
  ClosedDayItem,
  DaySlotItem,
  ExceptionalOpeningHourItem,
} from "../dayslot";
import { AttributeItem } from "../attribute";
import { PriceUnitItemVariation } from "../price-unit";
import {
  AttributesItem,
  ClosedDaysItem,
  DaySlotsItem,
} from "../../lib/ground-aws-graphql-core/utils/preprocessing";
import {
  EnumCurrency,
  EnumHoursRangeName,
  EnumUnit,
} from "../../lib/ground-aws-graphql-core/api/graphql/types";
import { HoursRange } from "../../lib/ground-aws-graphql-core/models/HoursRange";
import { AuthorizationItem } from "../../lib/ground-aws-graphql-core/models/Authorization";
import {
  DATE_FORMAT_HOUR,
  formatDate,
  getHour,
  isBefore,
  isRangeInMorningOrAfternoon,
} from "../config";

const keepActions = [
  ActionTypes.TO_ADD,
  ActionTypes.TO_KEEP,
  ActionTypes.TO_UPDATE,
];

export const convertDaySlotsItems = (
  dayslots: DaySlotItem[],
  modifiedHoursRanges: HoursRangeItem[]
): DaySlotsItem[] => {
  // Get hours range from daySlots return by center loading
  const originalHoursRanges: HoursRangeItem[] = dayslots
    .filter(({ action }) => keepActions.includes(action))
    .reduce((acc, day) => {
      if (!day.item.hoursRange?.items) return acc;

      const ranges = day.item.hoursRange.items.map(
        (hour: HoursRange): HoursRangeItem => {
          return {
            action: ActionTypes.TO_KEEP,
            daySlot: day.item,
            item: hour,
            isGenerated: false,
          };
        }
      );

      return [...acc, ...ranges];
    }, [] as HoursRangeItem[]);

  // Merge updated hours range in form with original hours range
  const mergedHours = Object.values(
    [...originalHoursRanges, ...modifiedHoursRanges].reduce(
      (acc: HoursRangeItem[], range) => {
        acc[range.item.id] = range;

        return acc;
      },
      []
    )
  );

  // Format hours ranges for triggers

  const result: DaySlotsItem[] = Object.values(
    mergedHours
      .filter(({ action }) => keepActions.includes(action))
      .reduce((slots, hoursRange) => {
        const day = hoursRange.daySlot.name;
        const { start, end, id } = hoursRange.item;
        if (!day || !start || !end) return slots;

        const daySlot = slots[day] || {
          day,
          id: hoursRange.daySlot.id,
          hoursRange: [],
        };
        const name = getHoursRangeName(start, end);

        return {
          ...slots,
          [day]: {
            ...daySlot,
            hoursRange: [...daySlot.hoursRange, { id, start, end, name }],
          },
        };
      }, {})
  );

  const daySlotsWithHoursRanges = result.reduce((acc: DaySlotsItem[], el) => {
    const hours = el.hoursRange
      .sort((hoursRange1, hoursRange2) => {
        if (hoursRange1 && hoursRange2) {
          const start1 = formatDate(hoursRange1.start, DATE_FORMAT_HOUR);
          const start2 = formatDate(hoursRange2.start, DATE_FORMAT_HOUR);

          return isBefore(start1, start2) ? -1 : 1;
        }

        return 0;
      })
      .slice(0, 2)
      .map((i, index) => ({
        ...i,
        name:
          el.hoursRange.length > 1
            ? index === 0
              ? EnumHoursRangeName.MORNING
              : EnumHoursRangeName.AFTERNOON
            : getHoursRangeName(i.start, i.end),
      }));
    const clone = { ...el, hoursRange: hours };
    acc.push(clone);

    return acc;
  }, []);

  return daySlotsWithHoursRanges;
};

export const getHoursRangeName = (
  sHour: string,
  eHour: string
): EnumHoursRangeName => {
  const sH = getHour(sHour);
  const eH = getHour(eHour);

  const startDate = sH?.date;
  const endDate = eH?.date;

  let morning = false;
  let afternoon = false;
  if (startDate && endDate) {
    const r = isRangeInMorningOrAfternoon({
      start: startDate.toISOString(),
      end: endDate.toISOString(),
    });

    morning = r.morning;
    afternoon = r.afternoon;
  }

  // console.log(
  //   `[${sHour} - ${eHour}] => morning:` + morning,
  //   ", afternoon:" + afternoon
  // );

  if (afternoon && !morning) {
    return EnumHoursRangeName.AFTERNOON;
  }

  return EnumHoursRangeName.MORNING;
};

export const convertExceptionalOpeningHoursItems = (
  exceptionalOpeningHours: ExceptionalOpeningHourItem[]
) =>
  exceptionalOpeningHours
    .map(
      ({ item, action }) =>
        keepActions.includes(action) &&
        item.startAt &&
        item.endAt &&
        item.start &&
        item.end && {
          startAt: item.startAt,
          endAt: item.endAt,
          start: item.start,
          end: item.end,
          enabled: item.enabled,
          name: item.name,
          ...(item.id && action !== ActionTypes.TO_ADD && { id: item.id }),
        }
    )
    .filter(i => !!i);

export const convertClosedDaysItems = (
  closedDays: ClosedDayItem[]
): ClosedDaysItem[] =>
  closedDays
    .map(
      ({ item, action }) =>
        keepActions.includes(action) &&
        item.start &&
        item.end && {
          start: item.start,
          end: item.end,
          ...(item.id && action !== ActionTypes.TO_ADD && { id: item.id }),
        }
    )
    .filter((i): i is ClosedDaysItem => !!i);

export const convertAttributeItems = (
  attributes: AttributeItem[]
): AttributesItem[] =>
  attributes
    .map(({ item, actionValue }) => {
      if (!keepActions.includes(actionValue)) return undefined;
      if (!item.key) return undefined;
      const { attributeValue } = getAttributeValueByAttributeKey(
        item.key,
        item
      );
      if (!attributeValue) return undefined;

      const result: AttributesItem = {
        value: attributeValue,
        key: {
          id: item.key?.id,
          name: item.key?.name,
        },
        ...(item.id && actionValue !== ActionTypes.TO_ADD && { id: item.id }),
      };

      return result;
    })
    .filter((i): i is AttributesItem => !!i);

export const convertAuthorizations = (
  authorizations: AuthorizationItem[]
): {
  id?: string | undefined;
  backOfficeUserId: string;
}[] =>
  authorizations
    .map(({ authorization, action }) => {
      if (!keepActions.includes(action)) return undefined;

      const result = {
        backOfficeUserId: authorization.user.id,
        ...(authorization.id &&
          action !== ActionTypes.TO_ADD && { id: authorization.id }),
      };

      return result;
    })
    .filter(
      (
        i
      ): i is {
        id?: string | undefined;
        backOfficeUserId: string;
      } => !!i
    );

interface PriceUnitItem {
  id?: string;
  unit: EnumUnit;
  currency: EnumCurrency | undefined;
  price: number;
  variation: { id: string; key: string };
  minApprovalMinutes?: number | null;
  minCancelMinutes?: number | null;
}

export const convertPriceUnitsVariations = (
  priceUnitsVariations: PriceUnitItemVariation
): PriceUnitItem[] =>
  Object.values(priceUnitsVariations)
    .flat()
    .map(item => {
      if (!keepActions.includes(item.action)) return undefined;
      const {
        id,
        price,
        unit,
        currency,
        variation,
        minApprovalMinutes,
        minCancelMinutes,
      } = item.item;
      const variationId = variation.id;
      const variationKey = variation.key;
      const result: PriceUnitItem = {
        price,
        unit,
        currency,
        minApprovalMinutes,
        minCancelMinutes,
        variation: { id: variationId, key: variationKey },
        ...(id && item.action !== ActionTypes.TO_ADD && { id }),
      };

      return result;
    })
    .filter((i): i is PriceUnitItem => !!i);
