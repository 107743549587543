/*
 *
 * Participation Model
 *
 */

import { Action, Thunk, action, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel } from "../";
import {
  EnumParticipation,
  SearchableParticipationSortInput,
} from "./../../api/graphql/types";
import { User } from "../User";
import { SearchableParticipationFilterInput } from "../../api/graphql/types";
import { ErrorGraphQL } from "../../services/coreUtils";

export interface Participation {
  id: string;
  value: EnumParticipation;
  user?: User;
}

export interface ModelParticipationConnection {
  items: [Participation] | null;
  total?: number | null;
}

export interface SearchParticipationsOpts {
  filter: SearchableParticipationFilterInput;
  sort?: SearchableParticipationSortInput;
  limit?: number | null;
  from?: number | null;
}

// Interface declaration
export interface ParticipationModel {
  participations: ModelParticipationConnection;
  setParticipations: Action<ParticipationModel, ModelParticipationConnection>;
  searchParticipations: Thunk<
    ParticipationModel,
    SearchParticipationsOpts,
    Injections,
    StoreModel
  >;
}

export const participationModel: ParticipationModel = {
  participations: { items: null, total: null },

  setParticipations: action((state, data: ModelParticipationConnection) => {
    state.participations = { items: data.items, total: data.total };
  }),

  searchParticipations: thunk(async (actions, payload, { injections }) => {
    const { participationService } = injections;
    const response = await participationService.searchParticipations(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);
    if (response.data.searchParticipations) {
      const data = response.data.searchParticipations;
      actions.setParticipations(data);

      return data;
    }

    return null;
  }),
};
