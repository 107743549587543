import React, { useState, useEffect } from "react";
import { IntlShape } from "react-intl";
import { toast } from "react-toastify";
import { match as Match } from "react-router-dom";
import { Location } from "history";
import { FieldAttributes } from "formik";
import history from "../../../../history";
import {
  EnumPaths,
  getServicesPathByTypology,
} from "../../../../utils/navigation";
import Header from "../../../../components/Tailwind/Block/Header";
import Block from "../../../../components/Tailwind/Block";
import ImageGallery from "../../../../components/Tailwind/ImageGallery";
import {
  Image,
  ActionTypes,
  getCategoryOptions,
  EnumAction,
  canManageCategories,
} from "../../../../utils/types";
import contextStore from "../../../../redux/store";
import Footer from "../../../../components/Tailwind/Block/Footer";
import ModalCreateUpdateCategory from "../../category/create-update";
import Button from "../../../../components/Tailwind/Button";
import { getCypressTestId } from "../../../../utils/config";
import IntlMessages from "../../../../utils/messages";
import {
  EnumCategoryType,
  EnumPermissionEntity,
  EnumServiceType,
  SearchableCategorySortableFields,
  SearchableSortDirection,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Category } from "../../../../lib/ground-aws-graphql-core/models/Category";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import { GroundAuthContextStore } from "../../../../lib/ground-aws-cognito-auth-core";
import overrideClasses from "../../../../utils/overrideClasses";
import DefaultForm, {
  AdditionalFieldAttributes,
} from "../../../../components/Form";
import FormQuill from "../../../../components/Form/FormQuill";
import { getLocale } from "../../../../lang";
import FormSwitch from "../../../../components/Form/FormSwitch";

interface Props {
  handleClose?: () => void;
  serviceType: EnumServiceType;
  match: Match<{ cid: string; id: string }>;
  intl: IntlShape;
  edition: boolean;
  location: Location<{
    category: Category;
  }>;
}

const ServiceForm = (props: Props): JSX.Element => {
  const { location, intl, edition, serviceType, match, handleClose } = props;

  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<Category | null | undefined>(null);
  const [pictures, setPictures] = useState<Image[]>([]);
  const [enabled, setEnabled] = useState(edition ? true : false);
  const [modalCategoryOpen, setModalCategoryOpen] = useState(false);
  const [action, setAction] = useState<EnumAction>();

  const categoryAssociated = location.state?.category;

  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const getService = GroundGraphqlContextStore.useStoreActions(
    actions => actions.service.getService
  );

  const setService = GroundGraphqlContextStore.useStoreActions(
    actions => actions.service.setService
  );

  const updateService = contextStore.useStoreActions(
    actions => actions.service.updateService
  );

  const createService = contextStore.useStoreActions(
    actions => actions.service.createService
  );

  const updateServiceAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.service.updateService
  );

  const createServiceAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.service.createService
  );

  const searchAllCategories = GroundGraphqlContextStore.useStoreActions(
    actions => actions.category.searchAllCategories
  );

  const service = GroundGraphqlContextStore.useStoreState(
    state => state.service.service
  );

  const center = GroundGraphqlContextStore.useStoreState(
    state => state.center.center
  );

  const categories = GroundGraphqlContextStore.useStoreState(
    state => state.category.categories.items
  );

  const locale = contextStore.useStoreState(state => state.settings.locale);
  const currentAppLocale = getLocale(locale);

  const handleSubmit = values => {
    setLoading(true);

    if (edition) {
      updateService({
        service,
        values: {
          ...values,
          serviceCategoryId: category?.id,
          type: service?.type || serviceType,
        },
        pictures,
        center: {
          id: match.params.cid,
          operator: center?.operator,
        },
        enabled,
        callbacks: {
          updateService: updateServiceAction,
          createService: createServiceAction,
        },
      })
        .then(() => {
          const centerId = match.params.cid;
          history.push(
            `/${EnumPaths.ROOT}/${
              EnumPaths.CENTERS
            }/${centerId}/${getServicesPathByTypology(serviceType)}`
          );
          toast(
            intl.formatMessage({
              id: "page.service.update.service.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          toast(
            intl.formatMessage({
              id: "page.service.update.service.error",
            }),
            {
              type: "error",
            }
          );
        })
        .finally(() => setLoading(false));
    } else {
      createService({
        service: null,
        values: {
          ...values,
          serviceCategoryId: category?.id,
          type: service?.type || serviceType,
        },
        pictures,
        center: {
          id: match.params.cid,
          operator: center?.operator,
        },
        enabled,
        callbacks: {
          updateService: updateServiceAction,
          createService: createServiceAction,
        },
      })
        .then(() => {
          const centerId = match.params.cid;
          history.push(
            `/${EnumPaths.ROOT}/${
              EnumPaths.CENTERS
            }/${centerId}/${getServicesPathByTypology(serviceType)}`
          );
          toast(
            intl.formatMessage({
              id: "page.service.create.service.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          toast(
            intl.formatMessage({
              id: "page.service.create.service.success",
            }),
            {
              type: "error",
            }
          );
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    setLoading(true);

    const promises = [
      searchAllCategories({
        filter: {
          type: {
            eq:
              serviceType === EnumServiceType.SPACE
                ? EnumCategoryType.SPACE
                : EnumCategoryType.SERVICE,
          },
          categoryCenterId: {
            eq: match.params.cid,
          },
        },
        sort: {
          field: SearchableCategorySortableFields.createdAt,
          direction: SearchableSortDirection.desc,
        },
        locale: currentAppLocale.backend_locale,
      }),
    ];

    if (match.params.id) {
      promises.push(getService({ id: match.params.id }));
    }

    Promise.all(promises).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    // Clean the service from the state when we unmount this component
    return () => {
      setService(null);
    };
  }, []);

  useEffect(() => {
    if (service) {
      const servicePictures = service.pictures?.reduce((acc: Image[], el) => {
        if (el) {
          acc.push({ picture: el, source: false, action: ActionTypes.TO_KEEP });
        }

        return acc;
      }, []);

      setCategory(service.category);
      if (servicePictures) {
        setPictures(servicePictures);
      }

      setEnabled(service.enabled as boolean);
    } else if (categoryAssociated) setCategory(categoryAssociated);
    else setCategory(null);
  }, [service]);

  const handleAddPicture = (picture: string | ArrayBuffer, file: File) => {
    const items = [...pictures];
    items.push({
      picture,
      source: true,
      file,
      action: ActionTypes.TO_ADD,
    });

    setPictures(items);
  };

  const handleRemovePicture = (el: Image) => {
    const items = pictures.filter(e => e.picture !== el.picture);
    if (el.source) {
      setPictures(items);
    } else {
      const picturesToDelete = [...items];
      picturesToDelete.push({
        ...el,
        action: ActionTypes.TO_DELETE,
      });
      setPictures(picturesToDelete);
    }
  };

  const handleChangeCategory = e => {
    const catId = e.target.value;

    const currentCategory = catId
      ? categories?.find(c => c.id === catId)
      : null;

    if (currentCategory) setCategory(currentCategory);
  };

  const cleanedCategories: [Category] | null =
    categories && categories.length > 0
      ? (categories.filter(c => c.level !== 0) as [Category])
      : null;
  const categoryItems = getCategoryOptions(cleanedCategories);

  const formFields: FieldAttributes<AdditionalFieldAttributes>[] = [
    {
      name: "name",
      label: "page.service.name",
      placeholder: "page.service.name",
      initialValue: service?.name,
      required: true,
      translatable: true,
    },
    {
      name: "serviceCategoryId",
      label: "general.category",
      placeholder: "general.category",
      initialValue: service?.category?.id || categoryAssociated?.id,
      as: "select",
      required: true,
      options: categoryItems,
      onChange: handleChangeCategory,
      value: category?.id,
      children:
        !!category?.center || canManageCategories(me) ? (
          <div className="mt-4">
            <button
              id="btn-modify-service-category"
              name="btn-modify-service-category"
              data-cy="btn-modify-service-category"
              data-testid={getCypressTestId(category)}
              type="button"
              onClick={() => {
                setAction(EnumAction.UPDATE);
                setModalCategoryOpen(true);
              }}
              className="inline-flex items-center border border-transparent text-12px leading-5 font-medium bg-transparent text-ground-gray-300 hover:text-ground-blue-100 focus:outline-none active:text-ground-gray-300 transition ease-in-out duration-150"
            >
              <span>
                <IntlMessages id="general.edit.category" />
              </span>
            </button>
          </div>
        ) : undefined,
      thirdColComponent: (
        <div>
          <Button
            id="btn-add-service-category"
            name="btn-add-service-category"
            data-cy="btn-add-service-category"
            item={null}
            type="button"
            outline
            onClick={() => {
              setAction(EnumAction.CREATE);
              setModalCategoryOpen(true);
            }}
          >
            <IntlMessages id="general.add.CATEGORY" />
          </Button>
        </div>
      ),
    },
    {
      name: "description",
      label: "general.description",
      placeholder: "general.description",
      initialValue: service?.description,
      translatable: true,
      component: formikProps => <FormQuill {...formikProps} />,
    },
    {
      id: "buyable",
      name: "buyable",
      label: "page.service.buyable",
      placeholder: "page.service.buyable",
      initialValue: service ? service?.buyable : true,
      component: props => (
        <FormSwitch {...props} description="page.service.buyable.description" />
      ),
    },
  ];

  const labels = {
    creation: "page.list.services.create.service",
    edition: "page.list.services.update.service",
  };

  return edition && !service ? (
    <div className="loading" />
  ) : (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={overrideClasses({ loading })} />

      {/* Hide when loading */}
      <div className={overrideClasses("px-8", { hidden: loading })}>
        <Block>
          <Header
            item={service}
            title={edition ? labels.edition : labels.creation}
            entity={EnumPermissionEntity.SERVICE}
            checked={enabled}
            onChange={e => setEnabled(e)}
            className="border-b border-gray-200"
          />

          <ModalCreateUpdateCategory
            {...props}
            isOpen={modalCategoryOpen}
            parent={false}
            toggle={() => setModalCategoryOpen(!modalCategoryOpen)}
            action={action}
            type={
              serviceType === EnumServiceType.SPACE
                ? EnumCategoryType.SPACE
                : EnumCategoryType.SERVICE
            }
            category={action === EnumAction.UPDATE ? category : null}
            categories={categories}
            centerId={match.params.cid}
          />

          <DefaultForm fields={formFields} onSubmit={handleSubmit} disablePanel>
            <ImageGallery
              entity={service}
              label="general.image.gallery.photos"
              index={formFields.length}
              max={1}
              images={pictures}
              onAddImage={handleAddPicture}
              onRemoveImage={handleRemovePicture}
            />
            <Footer
              item={service}
              index={formFields.length + 1}
              labels={["general.cancel", "general.delete"]}
              onCancel={handleClose}
            />
          </DefaultForm>
        </Block>
      </div>
    </>
  );
};

export default ServiceForm;
