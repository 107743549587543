import { OperatorConfig } from "..";

// INT
const sourcesConfig: OperatorConfig = {
  backoffice: {
    display: {
      "header.parameters": false,
      "feature.enterprise": false,
      "sidebar.menu.building.documents": true,
      "sidebar.menu.management.calendar": false,
      "sidebar.menu.management.bookings": false,
      "sidebar.menu.management.visits": false,
      "sidebar.menu.catalog.workspaces": false,
      "feature.dashboards.ga": false,
      "feature.dashboards.sales": false,
      "feature.dashboards.occupancy": false,
    },
  },
};

export default sourcesConfig;
