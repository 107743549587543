import { AxiosResponse } from "axios";
import axiosBackend from "../../../../../axiosBackend";
import { RefundRequest } from "../../../models/Api/RefundRequests";

export interface IListResponse<T> {
  data: {
    items: [T];
    total: number;
    has_more: boolean;
    next_url: string | null;
  };
  success: boolean;
}

export const listRefundRequests = async (
  globalOrderId: string
): Promise<AxiosResponse<IListResponse<RefundRequest>>> => {
  return axiosBackend.get(`/global_orders/${globalOrderId}/refund_requests`);
};
