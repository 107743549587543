import React, { useState, useCallback, memo } from "react";
import images from "../../../../images";
import IntlMessages from "../../../../utils/messages";

interface Props {
  label?: string;
  icon?: any;
  iconOver?: any;
  onClick?: () => void;
  dataCy?: string;
  dataTestid?: string | number | undefined;
  height?: string;
}

const Add = ({
  label,
  icon,
  iconOver,
  onClick,
  height,
  dataCy,
  dataTestid,
}: Props): JSX.Element => {
  const [picto, setPicto] = useState(icon || images.add);

  const onMouseOver = useCallback(() => {
    setPicto(iconOver || images.addBlue);
  }, []);

  const onMouseOut = useCallback(() => {
    setPicto(icon || images.add);
  }, []);

  return (
    <div
      data-cy={dataCy}
      data-testid={dataTestid}
      className="flex items-center cursor-pointer hover:text-ground-blue-100 text-ground-gray-100"
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      {...(onClick && { onClick })}
    >
      <img src={picto} alt="add button" height={height} width={height} />
      {label && (
        <span className="hidden sm:inline-block pr-2 align-middle">
          <IntlMessages id={label} />
        </span>
      )}
    </div>
  );
};

export default memo(Add);
