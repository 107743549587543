import { ErrorGraphQL } from "./../../services/coreUtils";
import { Action, Thunk, action, thunk } from "easy-peasy";
import _ from "lodash";
import { Injections } from "../../store";
import { StoreModel, GqlQuery } from "../";
import { Operator } from "../Operator";

import {
  CreateProviderInput as CreateProvider,
  UpdateProviderInput as UpdateProvider,
  BaseDeleteInput as DeleteProvider,
  SearchableProviderFilterInput,
  SearchableProviderSortableFields,
  EnumLocale,
  SearchProvidersSortInput,
} from "../../api/graphql/types";
import { ModelAttributeConnection } from "../Attribute";

export interface Provider {
  operator?: Operator;
  id: string;
  name?: string;
  description?: string;
  address?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  phone?: string | null;
  email?: string | null;
  pictures?: Array<string | null> | null;
  attributes?: ModelAttributeConnection | null;
  authorized?: boolean | null;
  enabled?: boolean;
  markForDelete?: boolean;
  receivingEmails?: boolean;
}

export interface ModelProviderConnection {
  items: Provider[];
  total: number;
}

export interface SearchProvidersOpts {
  filter?: SearchableProviderFilterInput;
  limit?: number;
  locale?: EnumLocale;
  from?: number;
  sort?: SearchProvidersSortInput;
}

// Interface declaration
export interface ProviderModel {
  providers: ModelProviderConnection;
  provider: Provider | null;

  setProviders: Action<ProviderModel, { data: ModelProviderConnection }>;

  setProvider: Action<ProviderModel, Provider | null>;
  addProvider: Action<ProviderModel, Provider>;
  replaceProvider: Action<ProviderModel, Provider>;

  getProvider: Thunk<ProviderModel, GqlQuery, Injections>;
  createProvider: Thunk<ProviderModel, CreateProvider, Injections>;
  updateProvider: Thunk<ProviderModel, UpdateProvider, Injections>;
  deleteProvider: Thunk<ProviderModel, DeleteProvider, Injections>;
  searchProviders: Thunk<
    ProviderModel,
    SearchProvidersOpts,
    Injections,
    StoreModel
  >;
  searchAllProviders: Thunk<
    ProviderModel,
    SearchProvidersOpts,
    Injections,
    StoreModel
  >;
}

export const providerModel: ProviderModel = {
  providers: { items: [], total: 0 },
  provider: null,

  setProviders: action((state, payload: { data: ModelProviderConnection }) => {
    const { data } = payload;
    state.providers = data;
  }),

  setProvider: action((state, payload) => {
    state.provider = payload;
  }),

  addProvider: action((state, payload) => {
    if (state.providers.items) {
      state.providers.items.push(payload);
    } else {
      state.providers["items"] = [payload];
    }
  }),

  replaceProvider: action((state, payload) => {
    state.provider = payload;
    if (state.providers.items) {
      const index = state.providers.items!.findIndex(e => e.id === payload.id);
      if (index >= 0) state.providers.items!.splice(index, 1, payload);
    }
  }),

  getProvider: thunk(async (actions, payload, { injections }) => {
    const { providerService } = injections;
    const response = await providerService.getProvider(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    actions.setProvider(response.data.getProvider);

    return response.data.getProvider;
  }),

  createProvider: thunk(async (actions, payload, { injections }) => {
    const { providerService } = injections;
    const response = await providerService.createProvider(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const item = response.data.createProvider;
    actions.setProvider(item);
    actions.addProvider(item);

    return item;
  }),

  updateProvider: thunk(async (actions, payload, { injections }) => {
    const { providerService } = injections;
    const response = await providerService.updateProvider(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    const item = response.data.updateProvider;
    actions.setProvider(item);
    actions.replaceProvider(item);

    return item;
  }),

  deleteProvider: thunk(async (_actions, payload, { injections }) => {
    const { providerService } = injections;
    const response = await providerService.deleteProvider(payload);
    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    return true;
  }),

  searchProviders: thunk(async (actions, payload, { injections }) => {
    const { providerService } = injections;
    const response = await providerService.searchProviders(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    if (response.data.searchProviders) {
      const data = response.data.searchProviders;
      actions.setProviders({ data: data });

      return data;
    } else {
      return null;
    }
  }),

  searchAllProviders: thunk(async (actions, payload, { injections }) => {
    const { providerService } = injections;
    const response = await providerService.searchAllProviders(payload);

    const errors = Object.prototype.hasOwnProperty.call(response, "errors");
    if (errors) return ErrorGraphQL(response);

    if (response.data.searchAllProviders) {
      const data = response.data.searchAllProviders;
      actions.setProviders({ data: data });

      return data;
    } else {
      return null;
    }
  }),
};
