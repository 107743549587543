import React from "react";
import { withRouter } from "react-router-dom";
import "./style.css";
import { GroundAuthContextStore } from "../../lib/ground-aws-cognito-auth-core";
import { isOperatorAdmin } from "../../utils/types";

const TopBar = () => {
  const me = GroundAuthContextStore.useStoreState(
    state => state.authentication.me
  );

  const operatorAdmin = isOperatorAdmin(me);

  return (
    <div className="bg-white h-20 flex items-center shadow-ground-10">
      {operatorAdmin && (
        <>
          <div className="sm:h-20 flex items-center w-10/12 justify-between sm:px-10" />
        </>
      )}
    </div>
  );
};

export default withRouter(TopBar);
