import React from "react";
import Add from "../Button/Add";
import { Folder } from "../../../lib/ground-aws-graphql-core/models/Api/Documents";

interface Props {
  folder: Folder;
  label?: string;
  onAddFile: (file: File, folder: Folder) => void;
}

const FileInput = (props: Props): JSX.Element => {
  const { folder, label, onAddFile } = props;

  let inputElement: HTMLInputElement | null;

  const handleClick = () => {
    inputElement?.click();
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files: File[] = Array.from(e.target.files);

      files.forEach(file => {
        const reader = new FileReader();
        reader.onload = event => {
          if (event && event.target && event.target.result) {
            onAddFile(file, folder);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };

  return (
    <button
      className="flex items-center justify-center"
      onClick={handleClick}
      type="button"
    >
      <Add label={label} />

      <input
        id="file"
        type="file"
        accept=".jpg, .jpeg, .png, .pdf, .docx, .doc"
        className="hidden"
        onChange={handleChangeFile}
        ref={input => {
          inputElement = input;
        }}
      />
    </button>
  );
};

export default FileInput;
