import React, { useState, useEffect } from "react";
import { AttributeItem } from "../../../../../utils/attribute";
import TextInput from "../../../../../components/Tailwind/TextInput";
import NumberInput from "../../../../../components/Tailwind/NumberInput";
import Select from "../../../../../components/Tailwind/Select";
import { getTranslation } from "../../../../../utils/translation";
import {
  getAttributeValueByAttributeKey,
  getInputTypeByTypology,
} from "../../../../../utils/types";
import { getCypressTestId } from "../../../../../utils/config";
import { AttributeKey } from "../../../../../lib/ground-aws-graphql-core/models/AttributeKey";
import { EnumAttributeTypology } from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";

interface Props {
  attributeKey: AttributeKey;
  onChange: (e, key: AttributeKey) => void;
  attribute?: AttributeItem;
  index: number;
}

const CenterAttribute = (props: Props): JSX.Element => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (props.attribute) {
      if (props.attributeKey.typology === EnumAttributeTypology.BOOLEAN) {
        const check = props.attribute?.item.booleanValue
          ? props.attribute?.item.booleanValue
          : false;
        setChecked(check);
      }
    }
  }, [props.attribute]);

  const handleCheck = e => {
    setChecked(e.target.checked);
    props.onChange(e, props.attributeKey);
  };

  const getAttrInputProps = () => {
    const attributeKey = props.attributeKey;
    // let attributeValue = ""

    let items = [];
    if (
      attributeKey.metadata &&
      attributeKey.typology === EnumAttributeTypology.SELECT
    ) {
      const metadata = JSON.parse(attributeKey.metadata);
      const content = metadata["content"];
      if (content) {
        const { translatable, values } = content;
        const isArray = Array.isArray(values);
        if (isArray) {
          const results = values?.reduce(
            (
              acc: { id: string; name: string; default: boolean }[],
              el: { id: string; value: string; default: boolean }
            ) => {
              let val = el.value;
              if (translatable) {
                val = getTranslation(el.value);
              }
              acc.push({
                id: el.id,
                name: val,
                default: el.default ? el.default : false,
              });

              return acc;
            },
            []
          );
          items = results;
        }
      }
    }

    let attributeValue = "";
    if (props.attribute) {
      attributeValue = getAttributeValueByAttributeKey(
        attributeKey,
        props.attribute.item
      ).attributeValue;
    }

    let stepOverride;
    if (
      attributeKey.typology === EnumAttributeTypology.NUMBER ||
      EnumAttributeTypology.PERCENT
    ) {
      stepOverride = 0.01;
    }

    return {
      item: { id: attributeKey.id },
      key: `${attributeKey.name}_${props.index}`,
      id: attributeKey.id,
      value: attributeValue,
      items: items,
      name: attributeKey.name,
      system: attributeKey.system,
      required: attributeKey.required,
      label:
        attributeKey.description && getTranslation(attributeKey.description)
          ? getTranslation(attributeKey.description)
          : attributeKey.name,
      index: props.index,
      onChange: e => {
        props.onChange(e, attributeKey);
      },
      className: "w-1/2",
      type: getInputTypeByTypology(attributeKey),
      errors: "null",
      touched: "null",
      grid: 4,
      step: stepOverride,
      useIntl: false,
    };
  };

  return (
    <>
      {props.attributeKey.typology === EnumAttributeTypology.BOOLEAN && (
        <div className="flex items-center w-2/6">
          <div>
            <input
              id={props.attributeKey.id}
              data-cy="checkbox-attribute"
              data-testid={getCypressTestId(props.attributeKey)}
              key={`${props.attributeKey.name}_${props.index}`}
              name={props.attributeKey.name}
              disabled={props.attributeKey.system}
              type="checkbox"
              className="form-checkbox h-4 w-4 text-ground-green-100 transition duration-150 ease-in-out"
              checked={checked}
              onChange={handleCheck}
            />
          </div>
          <div className="flex items-center pl-2 m-1 text-14px">
            <label
              htmlFor={props.attributeKey.name}
              className="mb-0 text-ground-gray-100"
            >
              {props.attributeKey.description
                ? getTranslation(props.attributeKey.description)
                : props.attributeKey.name}
            </label>
          </div>
        </div>
      )}

      {(props.attributeKey.typology === EnumAttributeTypology.STRING ||
        props.attributeKey.typology === EnumAttributeTypology.URL) && (
        <TextInput {...getAttrInputProps()} />
      )}
      {props.attributeKey.typology === EnumAttributeTypology.JSON && (
        <TextInput {...getAttrInputProps()} translatable />
      )}
      {props.attributeKey.typology === EnumAttributeTypology.SELECT && (
        <Select
          {...getAttrInputProps()}
          //  translatable
        />
      )}
      {(props.attributeKey.typology === EnumAttributeTypology.NUMBER ||
        props.attributeKey.typology === EnumAttributeTypology.PERCENT) && (
        <NumberInput
          {...getAttrInputProps()}
          value={
            props.attribute?.item.numberValue
              ? props.attribute?.item.numberValue
              : 0
          }
          max={
            props.attributeKey.typology === EnumAttributeTypology.PERCENT
              ? 100
              : undefined
          }
        />
      )}
    </>
  );
};

export default CenterAttribute;
