import React, {
  useState,
  useEffect,
  useCallback,
  Fragment,
  forwardRef,
} from "react";
import _ from "lodash";
import { useFormikContext } from "formik";
import { useIntl } from "react-intl";
import { EnumUnit } from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import contextStore from "../../../redux/store";
import { getLocale } from "../../../lang";
import IntlMessages from "../../../utils/messages";
import overrideClasses from "../../../utils/overrideClasses";
import {
  DATE_FORMAT_HOUR,
  DATE_PICKER_TIME_STEP,
  addDuration,
  eachUnitOfInterval,
  isMorningOrAfternoon,
  subDuration,
} from "../../../utils/config";
import { OpeningClosingHour } from "../../../lib/ground-aws-graphql-core/models/Product";
import { EnumHalfDay } from "../../../routes/management/booking/book/create";
import { ZonedDatePicker } from "../../ZonedDatePicker";

export interface BookingDates {
  start: Date | null;
  end: Date | null;
}

export interface TimeSlotOption {
  unit: string;
  options: string[];
}

interface Props {
  timeSlotsOptions: TimeSlotOption[];
  onChange: (e: any) => void;
  date: Date;
  minTime: Date;
  maxTime: Date;
  openingHours: OpeningClosingHour[] | null | undefined;
  closingHours: OpeningClosingHour[] | null | undefined;
  selectedSlot: string;
  value: BookingDates;
}

interface InputDataCyProps {
  value?: any;
  onClick?: any;
  dataCy: string;
}

const FormTime = (props: Props): JSX.Element => {
  const {
    timeSlotsOptions,
    onChange,
    date,
    minTime,
    maxTime,
    openingHours,
    closingHours,
    selectedSlot,
    value: fieldValue,
  } = props;

  const [bookingDates, setBookingDates] = useState<BookingDates>({
    start: null,
    end: null,
  });

  const intl = useIntl();
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const { locale } = contextStore.useStoreState(({ settings }) => settings);
  const currentAppLocale = getLocale(locale);

  const onChangeTime = async (time: BookingDates) => {
    const { start, end } = time;

    if (start) {
      start.setSeconds(0);
      start.setMilliseconds(0);
    }
    if (end) {
      end.setSeconds(0);
      end.setMilliseconds(0);
    }

    await Promise.all([
      setFieldValue("time.start", start),
      setFieldValue("time.end", end),
    ]);
    setFieldTouched("time", true);
  };

  useEffect(() => {
    if (
      fieldValue.start &&
      fieldValue.end &&
      fieldValue.start !== bookingDates.start &&
      fieldValue.end !== bookingDates.end
    ) {
      setBookingDates(fieldValue);
    }
  }, [fieldValue]);

  useEffect(() => {
    if (bookingDates.start && bookingDates.end) {
      onChangeTime(bookingDates);
    }
  }, [bookingDates]);

  const changeHour = (time: Date | null, type: "start" | "end"): void => {
    setBookingDates({
      ...bookingDates,
      [type]: time,
    });
  };

  // hack disable input
  // https://github.com/Hacker0x01/react-datepicker/issues/942#issuecomment-424939438
  const handleDateChangeRaw = e => {
    e.preventDefault();
  };

  const renderInputSlot = (
    slot: string, // DAY, morning, afternoon
    inputLabel: string,
    onInputChange: (e) => void,
    className?: string
  ) => (
    <>
      <input
        type="radio"
        id={slot}
        name="slot"
        value={slot}
        className="mr-3"
        onChange={onInputChange}
        checked={selectedSlot === slot}
        data-cy={slot}
      />
      <label className={overrideClasses("mb-0", className)} htmlFor={slot}>
        {intl.formatMessage({ id: inputLabel })}
      </label>
    </>
  );

  const renderHourSlot = (slotChoiceId, divClassName) => {
    const checkHourSlot = () => {
      const hourInput = document.getElementById(slotChoiceId);
      if (hourInput) {
        (hourInput as HTMLInputElement).checked = true;

        // Throw mocked event with only the value that we will be using
        onChange({ target: { value: slotChoiceId } });
      }
    };

    // Every 15 minutes
    const dayBreakHours = _.flatMap(
      closingHours?.map(c => eachUnitOfInterval(c.start, c.end, "minute", 15))
    );

    // Remove last slot from start hours and first slot from end hours
    const dayBreakHoursStart = [...dayBreakHours];
    dayBreakHoursStart.pop();

    const dayBreakHoursEnd = [...dayBreakHours];
    dayBreakHoursEnd.shift();

    const InputWithDataCy = forwardRef(
      ({ value, onClick, dataCy }: InputDataCyProps, ref) => (
        <button
          className="w-15 h-8 border border-gray-200 bg-white"
          onClick={onClick}
          ref={ref}
          data-cy={dataCy}
          type="button"
        >
          {value}
        </button>
      )
    );

    return (
      <div className={divClassName} key={slotChoiceId}>
        {renderInputSlot(
          slotChoiceId,
          "page.booking.create.hour.slot",
          e => onChange(e),
          "mr-2"
        )}

        <div>
          <ZonedDatePicker
            selected={bookingDates.start}
            onChange={time => changeHour(time, "start")}
            onChangeRaw={handleDateChangeRaw}
            onCalendarOpen={checkHourSlot}
            openToDate={date}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={DATE_PICKER_TIME_STEP}
            timeCaption={intl.formatMessage({ id: "general.hour" })}
            dateFormat={DATE_FORMAT_HOUR}
            minTime={minTime}
            maxTime={subDuration(maxTime, DATE_PICKER_TIME_STEP, "minute")}
            excludeTimes={dayBreakHoursStart}
            locale={currentAppLocale.date}
            customInput={<InputWithDataCy dataCy="bookingStart" />}
          />
        </div>

        <span className="mx-2">
          <IntlMessages id="general.at" />
        </span>

        <div>
          <ZonedDatePicker
            selected={bookingDates.end}
            onChange={time => changeHour(time, "end")}
            onChangeRaw={handleDateChangeRaw}
            onCalendarOpen={checkHourSlot}
            openToDate={date}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={DATE_PICKER_TIME_STEP}
            timeCaption={intl.formatMessage({ id: "general.hour" })}
            dateFormat={DATE_FORMAT_HOUR}
            minTime={addDuration(minTime, DATE_PICKER_TIME_STEP, "minute")}
            maxTime={maxTime}
            excludeTimes={dayBreakHoursEnd}
            locale={currentAppLocale.date}
            customInput={<InputWithDataCy dataCy="bookingEnd" />}
          />
        </div>
      </div>
    );
  };

  const renderSlotChoices = useCallback(
    (slotChoice: TimeSlotOption) => {
      const divClassName = "flex items-center";

      // Used for checking if the center is opened for the corresponding half-day
      const { morning, afternoon } = isMorningOrAfternoon(openingHours);
      const morningAvailable = morning;
      const afternoonAvailable = afternoon;

      const onChangeHalfDay = (e, halfDaySelected: EnumHalfDay) => {
        onChange(e);
        if (bookingDates.start || bookingDates.end)
          setBookingDates({ start: null, end: null });

        if (openingHours) {
          if (halfDaySelected === EnumHalfDay.MORNING) {
            onChangeTime({
              start: minTime,
              end: new Date(openingHours[0].end),
            });
          } else {
            onChangeTime({
              start:
                openingHours.length > 1
                  ? new Date(openingHours[1].start)
                  : new Date(openingHours[0].start),
              end: maxTime,
            });
          }
        }
      };

      switch (slotChoice.unit) {
        case EnumUnit.HALF_HOUR:
        case EnumUnit.HOUR:
          return renderHourSlot(slotChoice.unit, divClassName);

        case EnumUnit.HALF_DAY:
          return (
            <Fragment key={slotChoice.unit}>
              {morningAvailable && (
                <div className={divClassName}>
                  {renderInputSlot(EnumHalfDay.MORNING, "general.morning", e =>
                    onChangeHalfDay(e, EnumHalfDay.MORNING)
                  )}
                </div>
              )}

              {afternoonAvailable && (
                <div className={divClassName}>
                  {renderInputSlot(
                    EnumHalfDay.AFTERNOON,
                    "general.afternoon",
                    e => onChangeHalfDay(e, EnumHalfDay.AFTERNOON)
                  )}
                </div>
              )}
            </Fragment>
          );

        case EnumUnit.DAY:
          return (
            <div className={divClassName} key={slotChoice.unit}>
              {renderInputSlot(
                slotChoice.unit,
                `page.booking.create.booking.slot.${slotChoice.unit.toLowerCase()}`,
                e => {
                  onChange(e);
                  if (bookingDates.start || bookingDates.end)
                    setBookingDates({ start: null, end: null });

                  if (minTime && maxTime) {
                    onChangeTime({
                      start: minTime,
                      end: maxTime,
                    });
                  }
                }
              )}
            </div>
          );
        default:
          return null;
      }
    },
    [timeSlotsOptions, bookingDates]
  );

  return (
    <div className="grid gap-2">{timeSlotsOptions?.map(renderSlotChoices)}</div>
  );
};

export default FormTime;
