import { Promise } from "bluebird";
import { ActionTypes } from "../../../../utils/types";
import {
  CreateOptionInput,
  DeleteOptionInput,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { Product } from "../../../../lib/ground-aws-graphql-core/models/Product";
import { Operator } from "../../../../lib/ground-aws-graphql-core/models/Operator";

export const getOptions = (
  options: { id: string; option: Product; action: ActionTypes }[],
  product: Product | null,
  center: { id: string; operator: Operator | null | undefined }
): {
  optionsToCreate: CreateOptionInput[];
  optionsToDelete: DeleteOptionInput[];
} => {
  const optionsToCreate: CreateOptionInput[] = [];
  const optionsToDelete: DeleteOptionInput[] = [];

  if (product) {
    options.forEach(el => {
      if (el.action === ActionTypes.TO_ADD) {
        const params: CreateOptionInput = {
          productOptionsId: product.id,
          optionCenterId: center.id,
          optionProductId: el.option.id,
        };
        optionsToCreate.push(params);
      }
      if (el.action === ActionTypes.TO_DELETE) {
        const params: DeleteOptionInput = {
          id: el.id,
        };
        optionsToDelete.push(params);
      }
    });
  }

  return {
    optionsToCreate: optionsToCreate,
    optionsToDelete: optionsToDelete,
  };
};

export function createOptionsPromise(
  options: CreateOptionInput[],
  callbacks: {
    createOption: any;
  }
): Promise<any> {
  return new Promise((resolve): void => {
    createOptions(options, callbacks, (_error, data) => {
      resolve(data);
    });
  });
}

type Callback = (error?: Error | null, data?: { success: boolean }) => void;

function createOptions(
  options: CreateOptionInput[],
  callbacks: {
    createOption: any;
  },
  callback: Callback
) {
  if (options && options.length > 0) {
    Promise.mapSeries(options, params => {
      return callbacks.createOption(params);
    })
      .then(() => {
        callback(null, { success: true });
      })
      .catch(() => {
        console.error("OPTIONS CREATION FAILED !");
        callback(null, { success: false });
      });
  } else {
    callback(null, { success: true });
  }
}

export function deleteOptionsPromise(
  options: DeleteOptionInput[],
  callbacks: {
    deleteOption: any;
  }
): Promise<any> {
  return new Promise((resolve): void => {
    deleteOptions(options, callbacks, (_error, data) => {
      resolve(data);
    });
  });
}

function deleteOptions(
  options: DeleteOptionInput[],
  callbacks: {
    deleteOption: any;
  },
  callback: Callback
) {
  if (options && options.length > 0) {
    Promise.mapSeries(options, params => {
      return callbacks.deleteOption(params);
    })
      .then(() => {
        callback(null, { success: true });
      })
      .catch(() => {
        console.error("OPTIONS DELETE FAILED !");
        callback(null, { success: false });
      });
  } else {
    callback(null, { success: true });
  }
}
