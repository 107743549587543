import React, { useCallback, useState } from "react";
import classnames from "classnames";
import { injectIntl, IntlShape } from "react-intl";
import { getCypressTestId } from "../../../utils/config";
import IntlMessages from "../../../utils/messages";
import { getSupportedLocales } from "../../../lang";
import contextStore from "../../../redux/store";
import { useTranslatableField } from "../../../utils/locale";
import LocaleSwitcher from "../../LocaleSwitcher";
import images from "../../../images";

interface Props {
  intl: IntlShape;
  item: { id: string } | null | undefined;
  id: string;
  name: string;
  type: string;
  value: string | null | undefined;
  placeholder?: string | null | undefined;
  label: string;
  index: number;
  last?: boolean;
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  children?: React.ReactNode;
  rows?: React.ReactNode;
  onChange: (e) => void;
  onBlur?: (e) => void;
  errors: any;
  touched: any;
  grid?: number;
  className?: string;
  action?: React.ReactNode;
  translatable?: boolean;
  autoComplete?: string;
  onlyInput?: boolean;
  useIntl: boolean;
  description?: string;
  alternate?: boolean;
  width?: string;
}

const TextInput = (props: Props): JSX.Element => {
  const {
    index,
    required,
    label,
    children,
    rows,
    id,
    item,
    onBlur,
    onlyInput,
    autoComplete,
    useIntl,
    intl,
    type,
    grid,
    translatable,
    value,
    onChange,
    name,
    className,
    last,
    errors,
    touched,
    disabled,
    width,
    action,
    placeholder,
    alternate,
  } = props;
  const [fieldType, setFieldType] = useState(type);

  const alter = alternate != undefined ? alternate : true;

  const clazz = grid ? `sm:grid-cols-${grid}` : "sm:grid-cols-3";

  const locale = contextStore.useStoreState(state => state.settings.locale);

  const {
    value: val,
    displayedValue,
    currentLocale,
    setCurrentLocale,
    onChange: onChg,
  } = useTranslatableField(!!translatable, value, onChange, locale);

  const locales = getSupportedLocales();

  const containerClassName = id =>
    name === "nocs"
      ? classnames("sm:grid sm:items-start")
      : classnames(
          "sm:grid sm:gap-4 sm:items-start sm:py-2 sm:px-8 px-2 py-2 " + clazz,
          className,
          {
            "bg-ground-white-200": alter && id % 2 === 0,
            "rounded-b-10": last,
          }
        );

  const invalid = errors[name] && touched[name];

  const formClassName = classnames(
    "rounded-md shadow-sm form-input block w-full transition duration-150 ease-in-out sm:text-14px sm:leading-5 placeholder-ground-gray-100",
    {
      "border-red-300 text-red-500 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red":
        invalid,
      "text-ground-black-100 placeholder-ground-black-100": !invalid,
      "bg-ground-white-200": disabled,
    }
  );

  const labelClassName = classnames(
    "block text-14px font-medium leading-5 text-ground-gray-100 sm:mt-px sm:pt-2 ",
    {
      "sm:col-span-2": grid === 4,
    }
  );

  const inputClassName = classnames(`mt-1 sm:mt-0 ${width ? width : ""}`, {
    "sm:col-span-2": !action,
  });

  const changeShowHidePassword = useCallback(() => {
    setFieldType(prevType => (prevType === "password" ? "text" : "password"));
  }, []);

  const newPlaceHolder =
    placeholder &&
    (useIntl ? intl.formatMessage({ id: `${placeholder}` }) : placeholder);

  return onlyInput ? (
    <>
      <input
        className={formClassName}
        placeholder={newPlaceHolder || ""}
        id={id}
        data-cy={name}
        data-testid={getCypressTestId(item)}
        name={name}
        type={type}
        value={displayedValue}
        disabled={disabled}
        onChange={onChg}
        onBlur={onBlur}
        autoComplete={autoComplete}
      />
      {translatable && (
        <LocaleSwitcher
          currentLocale={currentLocale}
          setCurrentLocale={setCurrentLocale}
          locales={locales}
          value={val}
        />
      )}
    </>
  ) : (
    <div className={containerClassName(index)}>
      {label && (
        <label htmlFor={name} className={labelClassName}>
          {useIntl && <IntlMessages id={label} />}
          {!useIntl && `${label}`} {required && "*"}
        </label>
      )}
      <div className={inputClassName}>
        <div className="relative flex items-center justify-between">
          <input
            className={formClassName}
            placeholder={newPlaceHolder || ""}
            id={id}
            data-cy={name}
            data-testid={getCypressTestId(item)}
            name={name}
            type={fieldType}
            value={displayedValue}
            disabled={disabled}
            onChange={onChg}
            onBlur={onBlur}
            autoComplete={autoComplete}
          />
          {type === "password" && (
            <div
              className="absolute cursor-pointer inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
              onClick={changeShowHidePassword}
            >
              {fieldType === "password" ? (
                <img alt="eye blocked" src={images.eyeBlocked} />
              ) : (
                <img alt="eye" src={images.eye} />
              )}
            </div>
          )}
          {invalid && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <svg
                className="h-5 w-5 text-red-500"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}
          {children && <>{children[0]}</>}
        </div>
        <div>
          {translatable && (
            <LocaleSwitcher
              data-cy="locale-switcher"
              currentLocale={currentLocale}
              setCurrentLocale={setCurrentLocale}
              locales={getSupportedLocales()}
              value={val}
            />
          )}
        </div>
        {invalid && (
          <p className="mt-2 text-11px text-red-600" id={`${name}-error`}>
            {errors[name]}
          </p>
        )}
        {rows && <div className="mt-1 sm:mt-0 sm:col-span-1">{rows[0]}</div>}
      </div>
      {action && <div className="my-auto">{action}</div>}
    </div>
  );
};

export default injectIntl(TextInput);
