import React, { useState } from "react";
import { match as Match } from "react-router-dom";
import { toast } from "react-toastify";
import { injectIntl, IntlShape } from "react-intl";
import IntlMessages from "../../../../../utils/messages";
import { EnumServiceType } from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { GroundGraphqlContextStore } from "../../../../../lib/ground-aws-graphql-core";
import Table, { ALL_FILTER } from "../../../../../components/Table";
import ConfirmModal from "../../../../../utils/modal/confirm";
import { Product } from "../../../../../lib/ground-aws-graphql-core/models/Product";
import { getDefaultImage, getImageUrl } from "../../../../../utils/picture";
import { getTranslation } from "../../../../../utils/translation";
import Dropdown from "../../../../../components/Tailwind/Dropdown";
import images from "../../../../../images";
import {
  EnumPaths,
  getServicesPathByTypology,
  navTo,
} from "../../../../../utils/navigation";
import history from "../../../../../history";
import { TableChangeParams } from "../../../../../components/Table/types";
import Badge from "../../../../../components/Tailwind/Badge";
import { displayDayDDMMYYYY } from "../../../../../utils/config";

type Props = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  serviceType: EnumServiceType;
  onChange: (params: TableChangeParams) => void;
  intl: IntlShape;
  match: Match<{ cid: string; id: string }>;
};

const ProductsTable = (props: Props): JSX.Element => {
  const { loading, setLoading, serviceType, onChange, intl, match } = props;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [productToDelete, setProductToDelete] = useState<Product | null>(null);
  const [filterAttribute] = useState<string | number>(ALL_FILTER);

  // list products of searchProducts
  const items = GroundGraphqlContextStore.useStoreState(
    state => state.product.products.items
  );

  const deleteProductAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.product.deleteProduct
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.product.products.total
  );

  const center = GroundGraphqlContextStore.useStoreState(s => s.center.center);

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  // FIXME: Filter by provider that aren't marked for delete should be backend side (if possible)
  let filteredProducts = items?.filter(p => !p.provider?.markForDelete);

  // FIXME: Filter by attributes should be backend side (if possible)
  if (filterAttribute !== ALL_FILTER) {
    filteredProducts = filteredProducts?.filter(p =>
      p.attributes?.items?.find(
        e => e.key?.name === filterAttribute && e.booleanValue
      )
    );
  }

  const handleEditProduct = (productId: string) => {
    const { cid, id } = match.params;

    let path = `${getServicesPathByTypology(serviceType)}/${
      EnumPaths.PRODUCTS
    }/${productId}/${EnumPaths.EDIT_MODE}`;

    if (serviceType === EnumServiceType.SERVICE && id) {
      path = `${getServicesPathByTypology(serviceType)}/${id}/${
        EnumPaths.PRODUCTS
      }/${productId}/${EnumPaths.EDIT_MODE}`;
    }

    history.push(navTo(path, cid));
  };

  const handleDelete = () => {
    if (center?.operator?.id && productToDelete) {
      setLoading(true);
      setShowDeleteModal(false);
      deleteProductAction({
        product: {
          id: productToDelete.id,
        },
      })
        .then(() => {
          const { cid, id } = match.params;
          const url =
            serviceType === EnumServiceType.SERVICE
              ? `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${cid}/${EnumPaths.SERVICES}/${id}`
              : `/${EnumPaths.ROOT}/${EnumPaths.CENTERS}/${cid}/${EnumPaths.SPACES}`;
          history.push(url);
          toast(
            intl.formatMessage({
              id: `page.product.delete.product.${serviceType}.success`,
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          toast(
            intl.formatMessage({
              id: `page.product.delete.product.${serviceType}.error`,
            }),
            {
              type: "error",
            }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const productsTableHead = [
    `page.list.products.table.head.product.${serviceType}`,
    "general.category",
    "general.actions",
  ];

  if (serviceType === EnumServiceType.SERVICE) {
    productsTableHead.splice(2, 0, "general.provider");
  }

  if (serviceType === EnumServiceType.SPACE) {
    productsTableHead.splice(
      2,
      0,
      "general.flexible",
      "general.capacity",
      "general.privatization.by"
    );
  }

  const productsTableBody = filteredProducts?.map(product => {
    const {
      name,
      pictures,
      enabled,
      sku,
      flexible,
      capacity,
      privatizations,
      provider,
      isOption,
      inCatalog,
    } = product;

    const productName = getTranslation(name);
    const picture = pictures && pictures.length > 0 ? pictures[0] : null;

    const dropdownValues = [
      {
        id: "edit_space",
        label: "general.edit",
        icon: images.edit,
        onClick: () => handleEditProduct(product.id),
      },
      {
        id: "delete_space",
        label: "general.delete",
        icon: images.deleteIcon,
        onClick: () => {
          setShowDeleteModal(!showDeleteModal);
          setProductToDelete(product);
        },
      },
    ];

    const productElements = [
      {
        element: (
          <div className="flex flew-row items-center">
            <div>
              <span className="inline-block relative">
                <img
                  alt={productName}
                  src={getImageUrl(null, picture)}
                  className="list-thumbnail responsive border-0 rounded-4 w-12 h-8 object-contain"
                />
                <span
                  className={`absolute bottom-0 right-0 block h-2 w-2 rounded-full text-white shadow-solid ${
                    enabled ? "bg-green-400" : "bg-red-400"
                  }`}
                />
              </span>
            </div>

            <div className="pl-2">
              <span
                className="block leading-4"
                data-cy={`product-name-${product.id}`}
              >
                {productName}
              </span>
              <span className="block text-ground-gray-100">#{sku}</span>
              {(isOption || inCatalog) && (
                <span className="block text-ground-gray-100">
                  {isOption && (
                    <Badge
                      label="page.product.as_option"
                      bgClassName="bg-ground-blue-200 mr-1"
                    />
                  )}
                  {inCatalog && (
                    <Badge
                      label="page.product.in_catalog"
                      bgClassName="bg-ground-blue-200"
                    />
                  )}
                </span>
              )}
            </div>
          </div>
        ),
        onCellClick: () => handleEditProduct(product.id),
      },
      {
        element: getTranslation(product.category?.name),
        onCellClick: () => handleEditProduct(product.id),
      },
      {
        element: (
          <Dropdown
            values={dropdownValues}
            dataCy={`product-dropdown-${product.id}`}
          />
        ),
      },
    ];

    if (serviceType === EnumServiceType.SERVICE) {
      productElements.splice(2, 0, {
        element: getTranslation(provider?.name),
        onCellClick: () => handleEditProduct(product.id),
      });
    }

    if (serviceType === EnumServiceType.SPACE) {
      productElements.splice(
        2,
        0,
        {
          element: (
            <div className="flex justify-center">
              {flexible && <img src={images.checked} alt="flexible" />}
            </div>
          ),
          onCellClick: () => handleEditProduct(product.id),
        },
        {
          element: (
            <IntlMessages id="general.capacity.label" values={{ capacity }} />
          ),
          onCellClick: () => handleEditProduct(product.id),
        },
        {
          element: (
            <>
              {privatizations && privatizations.length > 0 && (
                <>
                  <span>{privatizations[0].enterprise.name}</span>
                  <span className="text-gray-600 text-xs ">
                    {` > ${displayDayDDMMYYYY(
                      privatizations[0].end,
                      centerTimezone
                    )}`}
                  </span>
                </>
              )}
            </>
          ),
          onCellClick: () => handleEditProduct(product.id),
        }
      );
    }

    return {
      rowElements: productElements,
    };
  });

  return (
    <>
      <Table
        loading={loading}
        setLoading={setLoading}
        head={productsTableHead}
        body={productsTableBody}
        noDataText={`page.list.products.${serviceType}.empty`}
        // FIXME: Use only total when we can filter by attribute
        paginationTotal={
          filterAttribute === ALL_FILTER ? total : filteredProducts?.length
        }
        // permissionEntity={
        //   serviceType && serviceType === EnumServiceType.SERVICE
        //     ? EnumPermissionEntity.SERVICE
        //     : EnumPermissionEntity.SPACE
        // }
        onChange={onChange}
      />
      <ConfirmModal
        isOpen={showDeleteModal}
        toggle={() => setShowDeleteModal(!showDeleteModal)}
        onRequestClose={() => setShowDeleteModal(!showDeleteModal)}
        handleConfirm={handleDelete}
        content={
          <IntlMessages id={`page.product.delete.product.${serviceType}`} />
        }
      />
    </>
  );
};

export default injectIntl(ProductsTable);
