import { gql } from "apollo-boost";
import {
  addPrivatization as addPrivatizationMutation,
  updatePrivatization as updatePrivatizationMutation,
  deletePrivatization as deletePrivatizationMutation,
} from "../../../lib/ground-aws-graphql-core/api/graphql/mutations";
import {
  getPrivatization as getPrivatizationQuery,
  listEnterprisePrivatizations as listEnterprisePrivatizationsQuery,
  listProductPrivatizations as listProductPrivatizationsQuery,
} from "../../../lib/ground-aws-graphql-core/api/graphql/queries";
import { ApolloClient } from "../../../lib/ground-aws-graphql-core/store/provider";
import { fetchPolicy } from "../../../lib/ground-aws-graphql-core/api/client";

export const getPrivatization = ({ id }) =>
  ApolloClient.query({
    query: gql(getPrivatizationQuery),
    variables: { id },
  });

export const addPrivatization = input =>
  ApolloClient.mutate({
    mutation: gql(addPrivatizationMutation),
    variables: { input },
  });

export const updatePrivatization = input =>
  ApolloClient.mutate({
    mutation: gql(updatePrivatizationMutation),
    variables: { input },
  });

export const deletePrivatization = input =>
  ApolloClient.mutate({
    mutation: gql(deletePrivatizationMutation),
    variables: { input },
  });

export const listEnterprisePrivatizations = variables =>
  ApolloClient.query({
    query: gql(listEnterprisePrivatizationsQuery),
    variables,
    fetchPolicy,
  });

export const listProductPrivatizations = variables =>
  ApolloClient.query({
    query: gql(listProductPrivatizationsQuery),
    variables,
    fetchPolicy,
  });
