/*
 *
 * Attribute Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import {
  CreateAttributeMutation,
  UpdateAttributeMutation,
  DeleteAttributeMutation,
} from "../../api/graphql/types";

import {
  createAttribute as createAttributeMutation,
  updateAttribute as updateAttributeMutation,
  deleteAttribute as deleteAttributeMutation,
} from "../../api/graphql/mutations";

export const createAttribute = payload =>
  ApolloClient.mutate({
    mutation: gql(createAttributeMutation),
    variables: { input: payload },
  }) as {
    data: CreateAttributeMutation;
  };

export const updateAttribute = payload =>
  ApolloClient.mutate({
    mutation: gql(updateAttributeMutation),
    variables: { input: payload },
  }) as {
    data: UpdateAttributeMutation;
  };

export const deleteAttribute = payload =>
  ApolloClient.mutate({
    mutation: gql(deleteAttributeMutation),
    variables: { input: payload },
  }) as {
    data: DeleteAttributeMutation;
  };
