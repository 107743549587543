import React, { useState, useEffect } from "react";
import { match as Match } from "react-router-dom";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import ProductsTable from "./table";
import { EnumServiceType } from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import PageSubheader from "../../../../components/PageSubheader";
import {
  EnumPaths,
  getServicesPathByTypology,
  getSpecificIdFromUrl,
  navTo,
} from "../../../../utils/navigation";
import history from "../../../../history";
import {
  getServiceTypologiesFilter,
  getSpaceTypologiesFilter,
} from "../../../../utils/types";

interface Props {
  serviceType: EnumServiceType;
  match: Match<{ cid: string; id: string }>;
  intl: IntlShape;
}

const LIMIT = 20;

const ListProducts = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  const { match, intl, serviceType } = props;

  const searchProducts = GroundGraphqlContextStore.useStoreActions(
    actions => actions.product.searchProducts
  );

  const searchWorkSpaces = GroundGraphqlContextStore.useStoreActions(
    actions => actions.product.searchWorkSpaces
  );

  const total = GroundGraphqlContextStore.useStoreState(
    s => s.product.products.total
  );

  const setProduct = GroundGraphqlContextStore.useStoreActions(
    actions => actions.product.setProduct
  );

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (pageIndex = 0, loader = true) => {
    if (loader) {
      setLoading(true);
    }
    setPage(pageIndex);
    const { id, cid } = match.params;
    if (serviceType === EnumServiceType.SERVICE && id) {
      searchProducts({
        buildingId: cid,
        limit: LIMIT,
        filter: {
          or: getServiceTypologiesFilter(),
          serviceId: { eq: id },
        },
        from: pageIndex * LIMIT,
      }).finally(() => setLoading(false));
    } else {
      searchWorkSpaces({
        limit: LIMIT,
        filter: {
          productCenterId: { eq: cid },
          or: getSpaceTypologiesFilter(),
        },
        from: pageIndex * LIMIT,
      }).finally(() => setLoading(false));
    }
  };

  const handleAddNewProduct = () => {
    const centerId = getSpecificIdFromUrl(
      history.location.pathname,
      "centers/"
    );

    if (centerId) {
      setProduct(null);
      let path = `${getServicesPathByTypology(serviceType)}/${
        EnumPaths.PRODUCTS
      }/${EnumPaths.CREATION_MODE}`;

      const serviceId = getSpecificIdFromUrl(
        history.location.pathname,
        "services/"
      );

      if (serviceType === EnumServiceType.SERVICE && serviceId) {
        path = `${getServicesPathByTypology(serviceType)}/${serviceId}/${
          EnumPaths.PRODUCTS
        }/${EnumPaths.CREATION_MODE}`;
      }
      history.push(navTo(path, centerId));
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: `page.list.products.${serviceType}.document.title`,
          })}
        </title>
      </Helmet>

      <PageSubheader
        title={`page.list.${serviceType}.title`}
        nbOfResults={total}
        buttonRightTitle={`page.list.products.create.product.${serviceType}`}
        buttonRightAction={() => handleAddNewProduct()}
        buttonRightId={`btn-add-product-${serviceType.toLowerCase()}`}
      />

      <div className=" px-8">
        <ProductsTable
          serviceType={serviceType}
          onChange={({ pageIndex }) => fetchData(pageIndex, false)}
          loading={loading}
          setLoading={setLoading}
          match={match}
        />
      </div>
    </>
  );
};

export default injectIntl(ListProducts);
