import React, { useEffect, useState } from "react";
import { IntlShape } from "react-intl";
import { FieldAttributes } from "formik";
import { toast } from "react-toastify";
import { match as Match } from "react-router-dom";
import Block from "../../../../components/Tailwind/Block";
import Header from "../../../../components/Tailwind/Block/Header";
import DefaultForm, {
  AdditionalFieldAttributes,
} from "../../../../components/Form";
import overrideClasses from "../../../../utils/overrideClasses";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import history from "../../../../history";
import { EnumPaths } from "../../../../utils/navigation";

interface Props {
  match?: Match<{ id: string }>;
  intl: IntlShape;
  edition: boolean;
}

const AppClientForm = (props: Props): JSX.Element => {
  const { intl, match, edition } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const getAppClient = GroundGraphqlContextStore.useStoreActions(
    actions => actions.appClients.getAppClient
  );

  const createAppClient = GroundGraphqlContextStore.useStoreActions(
    actions => actions.appClients.createAppClient
  );

  const updateAppClient = GroundGraphqlContextStore.useStoreActions(
    actions => actions.appClients.updateAppClient
  );

  const setAppClient = GroundGraphqlContextStore.useStoreActions(
    actions => actions.appClients.setAppClient
  );

  const appClient = GroundGraphqlContextStore.useStoreState(
    state => state.appClients.appClient
  );

  const formFields: FieldAttributes<AdditionalFieldAttributes>[] = [
    {
      name: "name",
      label: "page.app.client.name",
      placeholder: "page.app.client.name.placeholder",
      initialValue: appClient?.name,
      required: true,
    },
    {
      name: "client_id",
      label: "page.app.client.client_id",
      placeholder: "page.app.client.client_id.placeholder",
      initialValue: appClient?.client_id,
      disabled: true,
      hidden: true,
    },
    {
      name: "secret_id",
      label: "page.app.client.client_secret",
      placeholder: "page.app.client.client_secret.placeholder",
      initialValue: appClient?.client_secret,
      disabled: true,
      hidden: true,
    },
  ];

  const fetchData = () => {
    setLoading(true);
    if (match?.params.id) {
      getAppClient({ id: match?.params.id }).finally(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    // Clean the event from the state when we unmount this component
    return () => {
      setAppClient(null);
    };
  }, []);

  const handleSubmit = (values: any) => {
    setLoading(true);
    const { name } = values;
    if (edition && appClient) {
      updateAppClient({
        id: appClient.id,
        name,
      })
        .then(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.APP_CLIENTS}`);
          toast(
            intl.formatMessage({
              id: "page.app.client.update.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.APP_CLIENTS}`);
          toast(
            intl.formatMessage({
              id: "page.app.client.update.error",
            }),
            {
              type: "error",
            }
          );
        });
    } else {
      createAppClient({
        name,
      })
        .then(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.APP_CLIENTS}`);
          toast(intl.formatMessage({ id: "page.app.client.create.success" }), {
            type: "success",
          });
        })
        .catch(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.APP_CLIENTS}`);
          toast(intl.formatMessage({ id: "page.app.client.create.error" }), {
            type: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return loading ? (
    <div className="loading" />
  ) : (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={overrideClasses({ loading })} />

      {/* Hide when loading */}
      <div className={overrideClasses("px-8 pb-32", { hidden: loading })}>
        <Block>
          <Header
            item={appClient}
            title={
              edition
                ? "page.list.app.clients.update.app.client"
                : "page.list.app.clients.create.app.client"
            }
            className="border-b border-gray-200"
          />

          <DefaultForm
            fields={formFields}
            onSubmit={handleSubmit}
            showPanel={false}
          />
        </Block>
      </div>
    </>
  );
};

export default AppClientForm;
