import React, { useState } from "react";
import { match as Match } from "react-router-dom";
import { IntlShape } from "react-intl";
import { toast } from "react-toastify";
import Header from "../../../../components/Tailwind/Block/Header";
import GroundFormik from "../../../../components/Tailwind/Form";
import { EnumAction, FormValue } from "../../../../utils/types";
import Footer from "../../../../components/Tailwind/Block/Footer";
import {
  CreateEventTypeInput,
  EnumPermissionEntity,
  UpdateEventTypeInput,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { EventType } from "../../../../lib/ground-aws-graphql-core/models/EventType";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";

interface Props {
  toggle: (e) => void;
  eventType: EventType | null | undefined;
  action: string | null;
  match: Match<{ cid: string; id: string }>;
  intl: IntlShape;
}

const EventTypeForm = (props: Props): JSX.Element => {
  const { toggle, action, eventType, intl, match } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(true);

  const createEventTypeAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.eventType.createEventType
  );

  const updateEventTypeAction = GroundGraphqlContextStore.useStoreActions(
    actions => actions.eventType.updateEventType
  );

  const createUpdateEventType = values => {
    // no errors
    if (action === EnumAction.UPDATE && eventType) {
      setLoading(true);
      const payload: UpdateEventTypeInput = {
        id: eventType.id,
        name: values.name,
        type: values.type,
        enabled,
      };
      updateEventTypeAction(payload)
        .then(() => {
          setLoading(false);
          toggle(action);
          toast(
            intl.formatMessage({
              id: "page.event.update.event.type.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          setLoading(false);
          toggle(action);
          toast(
            intl.formatMessage({
              id: "page.event.update.event.type.error",
            }),
            {
              type: "error",
            }
          );
        });
    } else {
      const payload: CreateEventTypeInput = {
        name: values.name,
        type: values.type,
        enabled,
        centerEventTypesId: match.params.cid,
      };
      setLoading(true);
      createEventTypeAction(payload)
        .then(() => {
          setLoading(false);
          toggle(action);
          toast(
            intl.formatMessage({
              id: "page.event.create.event.type.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          setLoading(false);
          toggle(action);
          toast(
            intl.formatMessage({
              id: "page.event.create.event.type.error",
            }),
            {
              type: "error",
            }
          );
        });
    }
  };

  const handleSubmit = values => {
    createUpdateEventType(values);
  };

  const values: FormValue[] = [
    {
      id: "name",
      name: "name",
      type: "text",
      label: "page.event.type.name",
      placeholder: "page.event.type.name",
      required: true,
      value: eventType?.name,
      useIntl: true,
    },
    {
      id: "type",
      name: "type",
      type: "text",
      label: "general.type",
      placeholder: "general.type",
      required: true,
      value: eventType?.type,
      translatable: true,
      useIntl: true,
    },
  ];

  return loading ? (
    <div className="loading" />
  ) : (
    <>
      <Header
        item={eventType}
        title={
          action === EnumAction.UPDATE
            ? "page.list.events.update.event.type"
            : "page.list.events.create.event.type"
        }
        entity={EnumPermissionEntity.EVENT_TYPE}
        checked={enabled}
        onChange={e => {
          setEnabled(e);
        }}
        className="border-b border-gray-200"
      />
      <GroundFormik item={eventType} values={values} onSubmit={handleSubmit}>
        <Footer
          item={eventType}
          index={values.length + 1}
          labels={["general.cancel", "general.delete"]}
          onCancel={() => props.toggle(props.action)}
        />
      </GroundFormik>
    </>
  );
};

export default EventTypeForm;
