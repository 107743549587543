import { Thunk, thunk } from "easy-peasy";
import { Injections } from "../../store";
import { StoreModel } from "..";
import { Image } from "../../../utils/types";
import { Event } from "../../../lib/ground-aws-graphql-core/models/Event";

export interface EventOpts {
  event: Event | null;
  values: any;
  pictures?: Image[];
  center: {
    id: string;
  };
  me?: any;
  start: Date;
  end: Date;
  publicationDate: Date;
  unpublicationDate: Date;
  enabled: boolean;
  callbacks: {
    updateEvent: any;
    createEvent: any;
  };
}

// Interface declaration
export interface EventModel {
  createEvent: Thunk<EventModel, EventOpts, Injections, StoreModel>;
  updateEvent: Thunk<EventModel, EventOpts, Injections, StoreModel>;
}

export const eventModel: EventModel = {
  createEvent: thunk(async (_actions, payload, { injections }) => {
    const { eventService } = injections;
    const response = await eventService.createEvent(payload);

    return response;
  }),
  updateEvent: thunk(async (_actions, payload, { injections }) => {
    const { eventService } = injections;
    const response = await eventService.updateEvent(payload);

    return response;
  }),
};
