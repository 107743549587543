/*
 *
 * ClosedDay Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import { CreateClosedDayMutation } from "../../api/graphql/types";

import { createClosedDay as createClosedDayMutation } from "../../api/graphql/mutations";

export const createClosedDay = payload =>
  ApolloClient.mutate({
    mutation: gql(createClosedDayMutation),
    variables: { input: payload },
  }) as {
    data: CreateClosedDayMutation;
  };
