/*
 *
 * EventType Service
 *
 */
import { gql } from "apollo-boost";
import { ApolloClient } from "../../store/provider";
import {
  ListEventTypesQuery,
  CreateEventTypeMutation,
  UpdateEventTypeMutation,
} from "../../api/graphql/types";
import { listEventTypes as listEventTypesQuery } from "../../api/graphql/queries";
import {
  createEventType as createEventTypeMutation,
  updateEventType as updateEventTypeMutation,
} from "../../api/graphql/mutations";
import { fetchPolicy } from "../../api/client";

export const listEventTypes = payload => {
  const request = payload.gql ? payload.gql : listEventTypesQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: {
      filter: payload?.filter,
    },
    fetchPolicy,
  }) as {
    data: ListEventTypesQuery;
  };
};

export const createEventType = payload =>
  ApolloClient.mutate({
    mutation: gql(createEventTypeMutation),
    variables: { input: payload },
  }) as {
    data: CreateEventTypeMutation;
  };

export const updateEventType = payload =>
  ApolloClient.mutate({
    mutation: gql(updateEventTypeMutation),
    variables: { input: payload },
  }) as {
    data: UpdateEventTypeMutation;
  };
