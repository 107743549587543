import React from "react";
import { Helmet } from "react-helmet";
import { injectIntl, IntlShape } from "react-intl";
import { match as Match } from "react-router-dom";
import PageSubheader from "../../../../components/PageSubheader";
import { withTwoColLayout } from "../../../../utils";
import VisitForm from "../form";

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
  backURL: string;
}

const VisitCreate = (props: Props) => {
  const { intl, backURL } = props;

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: "page.visit.create.document.title" })}
        </title>
      </Helmet>

      <PageSubheader
        goBackEnabled
        goBackId="btn-back-visit-create"
        goBackURL={backURL}
      />

      <VisitForm {...props} />
    </>
  );
};

export default withTwoColLayout(injectIntl(VisitCreate));
