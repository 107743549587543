import React from "react";
import OneColLayout from "../containers/Layout/OneColumn";
import TwoColLayout from "../containers/Layout/TwoColumns";
import CustomUsersTwoColumns from "../containers/Layout/CustomUsersTwoColums";
import CustomParametersTwoColumns from "../containers/Layout/CustomParametersTwoColums";
import CustomIndicatorsTwoColumns from "../containers/Layout/CustomIndicatorsTwoColums";
import CustomDevelopersTwoColumns from "../containers/Layout/CustomDevelopersTwoColumns";

interface Props {
  loading: boolean;
}

export const withOneColLayout = (Component: any) => {
  return (props: Props) => {
    return (
      <OneColLayout>
        <Component {...props} />
      </OneColLayout>
    );
  };
};

export const withTwoColLayout =
  (Component: any, subheader?: JSX.Element) => (props: Props) => {
    return (
      <TwoColLayout subheader={subheader}>
        <Component {...props} />
      </TwoColLayout>
    );
  };

export const customUsersTwoColLayout = (Component: any) => {
  return (props: Props) => {
    return (
      <CustomUsersTwoColumns>
        <Component {...props} />
      </CustomUsersTwoColumns>
    );
  };
};

export const customParametersTwoColLayout = (Component: any) => {
  return (props: Props) => {
    return (
      <CustomParametersTwoColumns>
        <Component {...props} />
      </CustomParametersTwoColumns>
    );
  };
};

export const customDevelopersTwoColLayout = (Component: any) => {
  return (props: Props) => {
    return (
      <CustomDevelopersTwoColumns>
        <Component {...props} />
      </CustomDevelopersTwoColumns>
    );
  };
};

export const customIndicatorsTwoColLayout = (Component: any) => {
  return (props: Props) => {
    return (
      <CustomIndicatorsTwoColumns>
        <Component {...props} />
      </CustomIndicatorsTwoColumns>
    );
  };
};
