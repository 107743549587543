import React, { useEffect, useState } from "react";
import { injectIntl, IntlShape } from "react-intl";
import { Helmet } from "react-helmet";
import { EnumServiceType } from "../../../lib/ground-aws-graphql-core/api/graphql/types";
import { GroundGraphqlContextStore } from "../../../lib/ground-aws-graphql-core";
import IntlMessages from "../../../utils/messages";
import PageSubheader from "../../../components/PageSubheader";
import { getTranslation } from "../../../utils/translation";
import { EnumPaths } from "../../../utils/navigation";
import { getDefaultImage, getImageUrl } from "../../../utils/picture";
import Dropdown from "../../../components/Tailwind/Dropdown";
import Table from "../../../components/Table";
import history from "../../../history";
import images from "../../../images";
import { getBookableTypologiesFilter } from "../../../utils/types";

interface Props {
  intl: IntlShape;
}

const LIMIT = 20;

const ListSpaces = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const { intl } = props;

  const searchSpaces = GroundGraphqlContextStore.useStoreActions(
    actions => actions.product.searchSpaces
  );

  const products = GroundGraphqlContextStore.useStoreState(
    state => state.product.products.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    state => state.product.products.total
  );

  const fetchSpaces = (pageIndex = 0) => {
    // setLoading is handled automatically in the Table component
    const filter = {
      or: getBookableTypologiesFilter(),
    };
    searchSpaces({
      limit: LIMIT,
      filter,
      from: pageIndex * LIMIT,
    }).finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchSpaces();
  }, []);

  const spacesTableHead = [
    "header.SPACE",
    "general.building",
    "general.type",
    "general.capacity",
    "general.status",
    "general.actions",
  ];

  const spacesTableBody = products?.map(product => {
    const { id, pictures, name, enabled, sku, center, typology, capacity } =
      product;

    const picture =
      product && pictures && pictures.length > 0 ? pictures[0] : null;

    const productName = getTranslation(name);

    const privatizeLink = `/${EnumPaths.ROOT}/${EnumPaths.USERS}/${EnumPaths.SPACES}/${id}/${EnumPaths.CREATION_MODE}`;

    const dropdownValues = [
      {
        id: "privatize_space",
        label: "general.privatize",
        icon: images.padlock,
        link: privatizeLink,
      },
    ];

    return {
      rowElements: [
        {
          element: (
            <div className="flex flew-row items-center">
              <div className="w-24">
                <span className="inline-block relative">
                  <img
                    alt={productName}
                    src={
                      picture
                        ? getImageUrl(null, picture, 50, 50)
                        : getDefaultImage(null)
                    }
                    style={picture ? undefined : { width: 50, height: 50 }}
                    className="list-thumbnail responsive border-0"
                  />
                  <span
                    className={`absolute bottom-0 right-0 block h-2 w-2 rounded-full text-white shadow-solid ${
                      enabled ? "bg-green-400" : "bg-red-400"
                    }`}
                  />
                </span>
              </div>

              <div className="pl-2">
                <span
                  className="block"
                  data-cy={`privatization-product-name-${id}`}
                >
                  {productName}
                </span>
                <span className="block">#{sku}</span>
              </div>
            </div>
          ),
          onCellClick: () => history.push(privatizeLink),
        },
        {
          element: getTranslation(center?.name),
          onCellClick: () => history.push(privatizeLink),
        },
        {
          element: intl.formatMessage({
            id: `page.product.typology.${typology}`,
          }),
          onCellClick: () => history.push(privatizeLink),
        },
        {
          element: intl.formatMessage(
            { id: "general.capacity.label" },
            { capacity }
          ),
          onCellClick: () => history.push(privatizeLink),
        },
        {
          element: (
            <IntlMessages
              id={`page.list.users.table.account.${
                enabled ? "active" : "inactive"
              }`}
            />
          ),
          onCellClick: () => history.push(privatizeLink),
        },
        {
          element: (
            <Dropdown
              values={dropdownValues}
              dataCy={`privatization-dropdown-${id}`}
            />
          ),
        },
      ],
    };
  });

  return (
    <div>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: "page.list.spaces.document.title",
          })}
        </title>
      </Helmet>

      <PageSubheader title="page.list.users.spaces.title" nbOfResults={total} />

      <Table
        head={spacesTableHead}
        body={spacesTableBody}
        noDataText={`page.list.products.${EnumServiceType.SPACE}.empty`}
        onChange={({ pageIndex }) => fetchSpaces(pageIndex)}
        paginationTotal={total}
        paginationLimit={LIMIT}
        loading={loading}
        setLoading={setLoading}
        className="px-8"
      />
    </div>
  );
};

export default injectIntl(ListSpaces);
