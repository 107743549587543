import { Thunk, thunk } from "easy-peasy";
import { StoreModel } from "../..";
import { Injections } from "../../../store";

export interface GetOperatorAttributeValueOpts {
  operatorId: string;
  attributeKeyName: string;
}

export interface AttributeValueModel {
  getOperatorAttributeValue: Thunk<
    AttributeValueModel,
    GetOperatorAttributeValueOpts,
    Injections,
    StoreModel
  >;
}

export const attributeValueModel: AttributeValueModel = {
  getOperatorAttributeValue: thunk(
    async (_actions, payload, { injections }) => {
      const { attributeValueService } = injections;

      return attributeValueService.getOperatorAttributeValue(payload);
    }
  ),
};
