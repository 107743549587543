import React, { FunctionComponent } from "react";
import classnames from "classnames";
import IntlMessages from "../../../utils/messages";

interface Props {
  label?: string;
  className?: string;
  bgClassName?: string;
  textClassName?: string;
  dataCy?: string;
  children?: React.ReactNode;
}

const Badge: FunctionComponent<Props> = ({
  label,
  className,
  bgClassName,
  textClassName,
  dataCy,
  children,
}) => {
  const bgClassNames = bgClassName || "bg-red-500";
  const textClassNames = textClassName || "text-white";
  const classNames = classnames(className, bgClassNames, textClassNames, {
    "ml-auto inline-block py-0.5 px-3 text-xs leading-4 rounded-full transition ease-in-out duration-150":
      true,
  });

  const message = label ? <IntlMessages id={label} /> : children;

  return (
    <span className={classNames} data-cy={dataCy}>
      {message}
    </span>
  );
};

export default Badge;
