import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { match as Match, useLocation } from "react-router-dom";
import { injectIntl, IntlShape } from "react-intl";
import "./calendar.scss";
import { EnumFilterFields } from "../../../../utils/filter";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import {
  EnumAvailabilityStatus,
  EnumUnavailabilityType,
  SearchableSortDirection,
  SearchableUnavailabilitySortableFields,
} from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import BookingsTable from "./table";
import { IFilterField } from "../../../../components/Table";
import PageSubheader from "../../../../components/PageSubheader";
import {
  getTodayInCenterTimezone,
  parseISODateFn,
} from "../../../../utils/config";

interface Props {
  match: Match<{ cid: string }>;
  intl: IntlShape;
}

const LIMIT = 20;

const ListBookings = (props: Props) => {
  const { match, intl } = props;

  const location = useLocation<{ date: string }>();
  const { state } = location;

  const centerTimezone = GroundGraphqlContextStore.useStoreState(
    state => state.center.centerTimezone
  );

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const date = state?.date
    ? new Date(state.date)
    : getTodayInCenterTimezone(centerTimezone, true);
  const [selectedDate, setSelectedDate] = useState<Date>(date);

  const searchUnavailabilities = GroundGraphqlContextStore.useStoreActions(
    actions => actions.unavailability.searchUnavailabilities
  );

  const bookings = GroundGraphqlContextStore.useStoreState(
    s => s.unavailability.unavailabilitys.items
  );

  const total = GroundGraphqlContextStore.useStoreState(
    s => s.unavailability.unavailabilitys.total
  );

  const center = GroundGraphqlContextStore.useStoreState(s => s.center.center);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (refresh) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  const fetchData = (
    pageIndex = 0,
    loader = true,
    filters?: IFilterField<any>[]
  ) => {
    if (loader) {
      setLoading(true);
    }

    let date: Date = selectedDate;

    if (filters?.length) {
      filters.forEach(f => {
        if (f.type === EnumFilterFields[EnumFilterFields.START]) {
          if (f.value) {
            date = parseISODateFn(f.value);
            date.setHours(0, 0, 0, 0);
          }
        }
      });
    }

    const filter = {
      unavailabilityCenterId: { eq: match.params.cid },
      status: { eq: EnumAvailabilityStatus.COMPLETED },
      type: { eq: EnumUnavailabilityType.PARENT },
      date: { eq: date.toISOString() },
    };

    const promises = [
      searchUnavailabilities({
        limit: LIMIT,
        filter,
        sort: {
          field: SearchableUnavailabilitySortableFields.start,
          direction: SearchableSortDirection.desc,
        },
        from: pageIndex * LIMIT,
      }),
    ];

    Promise.all(promises).finally(() => setLoading(false));
  };

  //FIXME:
  if (!center) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: "page.list.bookings.document.title",
          })}
        </title>
      </Helmet>

      <PageSubheader title="page.list.bookings.title" nbOfResults={total} />

      <div className="px-8">
        <BookingsTable
          center={center}
          bookings={bookings}
          date={selectedDate}
          total={total}
          onChange={({ pageIndex, filters }) => {
            fetchData(pageIndex, false, filters);
            if (filters?.length) {
              filters.forEach(f => {
                if (f.type === EnumFilterFields[EnumFilterFields.START]) {
                  if (f.value) {
                    const date = parseISODateFn(f.value);
                    if (date) {
                      setSelectedDate(date);
                    }
                  }
                }
              });
            }
          }}
          setRefresh={setRefresh}
          loading={loading}
          setLoading={setLoading}
          limit={LIMIT}
          match={match}
        />
      </div>
    </>
  );
};

export default injectIntl(ListBookings);
