import React from "react";
import { FieldAttributes } from "formik";
import HeaderSingleProduct from "../../../../../components/Tailwind/Block/HeaderSingleProduct";
import ImageGallery from "../../../../../components/Tailwind/ImageGallery";
import ListPriceUnitsVariation from "../../../../../components/Tailwind/PriceUnit/Variation";
import {
  ActionTypes,
  canManagePriceUnitVariations,
  Image,
  isDependableTypology,
} from "../../../../../utils/types";
import AddPriceUnitVariation from "../../price-unit-variation";
import ListSingleProducts from "../../single-products";
import { PriceUnitItemVariation } from "../../../../../utils/price-unit";
import {
  EnumProductTypology,
  EnumServiceType,
} from "../../../../../lib/ground-aws-graphql-core/api/graphql/types";
import { PriceUnitVariation } from "../../../../../lib/ground-aws-graphql-core/models/PriceUnitVariation";
import { PriceUnit } from "../../../../../lib/ground-aws-graphql-core/models/PriceUnit";
import { Product } from "../../../../../lib/ground-aws-graphql-core/models/Product";
import { UserCognito } from "../../../../../lib/ground-aws-cognito-auth-core/models/Authentication";
import DefaultForm, {
  AdditionalFieldAttributes,
} from "../../../../../components/Form";

interface Props {
  item: Product | null;
  productTypology: EnumProductTypology | null | undefined;
  serviceType: EnumServiceType;
  onAddSingleProduct: (o: Product) => void;
  onRemoveSingleProduct: (o: Product) => void;
  singleProducts: {
    id: string;
    singleProduct: Product;
    action: ActionTypes;
  }[];
  priceVariations: PriceUnitVariation[];
  priceUnitsVariation: PriceUnitItemVariation | null;
  onAddPriceUnit: (variation: PriceUnitVariation) => void;
  onUpdatePriceUnit: (
    priceUnit: PriceUnit,
    variation: PriceUnitVariation
  ) => void;
  onDeletePriceUnit: (
    priceUnit: PriceUnit,
    variation: PriceUnitVariation
  ) => void;
  onAddPriceUnitVariation: () => void;
  onUpdatePriceUnitVariation: (variation: PriceUnitVariation) => void;
  me: UserCognito | null;
  pictures: Image[];
  onAddImage: (result: string | ArrayBuffer, file: File) => void;
  onRemoveImage: (el: Image) => void;
  defaultFormFields: FieldAttributes<AdditionalFieldAttributes>[];
  showPanel: boolean;
  submitForm: (values: any[], actions: any) => void;
}

const CardProductInformation = (props: Props): JSX.Element => {
  const {
    defaultFormFields,
    showPanel,
    submitForm,
    item,
    productTypology,
    serviceType,
    onAddSingleProduct,
    onRemoveSingleProduct,
    singleProducts,
    priceVariations,
    priceUnitsVariation,
    onAddPriceUnit,
    onUpdatePriceUnit,
    onDeletePriceUnit,
    onAddPriceUnitVariation,
    onUpdatePriceUnitVariation,
    me,
    pictures,
    onAddImage,
    onRemoveImage,
  } = props;

  const totalPriceUnitVariations = priceVariations ? priceVariations.length : 0;

  const isDependable = isDependableTypology(productTypology);

  return (
    <div>
      <DefaultForm
        fields={defaultFormFields}
        onSubmit={submitForm}
        showPanel={showPanel}
      >
        {isDependable && (
          <>
            <HeaderSingleProduct
              index={defaultFormFields.length}
              typology={productTypology}
              serviceType={serviceType}
              title={`page.product.${
                productTypology === EnumProductTypology.SERVICED_SPACE
                  ? "services"
                  : "products"
              }.title`}
              onAddSingleProduct={onAddSingleProduct}
              onRemoveSingleProduct={onRemoveSingleProduct}
              items={singleProducts}
            />
            <ListSingleProducts
              index={defaultFormFields.length}
              items={singleProducts}
              onRemoveSingleProduct={onRemoveSingleProduct}
            />
          </>
        )}

        {canManagePriceUnitVariations(me) && (
          <AddPriceUnitVariation
            index={defaultFormFields.length + 1}
            onAddPriceUnitVariation={onAddPriceUnitVariation}
          />
        )}

        {priceVariations && priceVariations.length > 0 && (
          <ListPriceUnitsVariation
            variations={priceVariations}
            priceUnitItemsVariation={priceUnitsVariation}
            index={defaultFormFields.length + 1}
            onAddPriceUnit={onAddPriceUnit}
            onUpdatePriceUnit={onUpdatePriceUnit}
            onDeletePriceUnit={onDeletePriceUnit}
            onUpdatePriceUnitVariation={onUpdatePriceUnitVariation}
            serviceType={serviceType}
            productTypology={productTypology}
          />
        )}

        <ImageGallery
          entity={item}
          label="general.image.gallery.photos"
          index={defaultFormFields.length + totalPriceUnitVariations + 2}
          max={5}
          images={pictures}
          onAddImage={onAddImage}
          onRemoveImage={onRemoveImage}
        />
      </DefaultForm>
    </div>
  );
};

export default CardProductInformation;
