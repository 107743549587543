import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";

declare global {
  interface Window {
    gtag: any;
  }
}

export default <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>
) => {
  const trackPage = (page: string) => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
      window.gtag("config", process.env.REACT_APP_GOOGLE_ANALYTICS, {
        page_path: page,
      });
    }
  };

  return (props: P) => {
    useEffect(() => {
      trackPage(props.location.pathname);
    }, [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };
};
