/*
 *
 * BackOfficeUser Service
 *
 */
import { gql } from "apollo-boost";
import { AxiosResponse } from "axios";
import { ApolloClient } from "../../store/provider";
import {
  GetBackOfficeUserQuery,
  SearchBackOfficeUsersQuery,
} from "../../api/graphql/types";

import {
  getBackOfficeUser as getBackOfficeUserQuery,
  searchBackOfficeUsers as searchBackOfficeUsersQuery,
  exportAdministrators as exportAdministratorsQuery,
} from "../../api/graphql/queries";
import {
  CreateAdminUserOpts,
  SearchBackOfficeUsersOpts,
  UpdateAdminUserOpts,
} from "./../../models/BackOfficeUser";
import axiosBackend from "../../../../axiosBackend";

const DEFAULT_LIMIT = 100;

export const getBackOfficeUser = payload => {
  const request = payload.gql ? payload.gql : getBackOfficeUserQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: { id: payload.id },
  }) as {
    data: GetBackOfficeUserQuery;
  };
};

export const searchBackOfficeUsers = (payload: SearchBackOfficeUsersOpts) => {
  const request = payload?.gql || searchBackOfficeUsersQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: {
      filter: payload.filter,
      limit: payload?.limit || DEFAULT_LIMIT,
      from: payload?.from,
    },
  }) as {
    data: SearchBackOfficeUsersQuery;
  };
};

export const exportAdministrators = (payload: SearchBackOfficeUsersOpts) => {
  const request = payload.gql ? payload.gql : exportAdministratorsQuery;

  return ApolloClient.query({
    query: gql(request),
    variables: {
      filter: payload.filter,
      limit: payload.limit,
      from: payload.from,
    },
  }) as {
    data: any;
  };
};

/**
 * Create admin user
 *
 * @param {CreateAdminUserOpts} payload
 * @return - A promise
 */
export const createAdminUser = async (
  payload: CreateAdminUserOpts
): Promise<AxiosResponse> => {
  return axiosBackend.post("/backoffice_users", payload);
};

/**
 * Update admin user
 *
 * @param {UpdateAdminUserOpts} payload
 * @return - A promise
 */
export const updateAdminUser = async (
  payload: UpdateAdminUserOpts
): Promise<AxiosResponse> => {
  return axiosBackend.put(`/backoffice_users/${payload.id}`, payload);
};

/**
 * delete admin user
 *
 * @param id
 * @returns
 */
export const deleteAdminUser = async (id: string): Promise<AxiosResponse> => {
  return axiosBackend.delete(`/backoffice_users/${id}`);
};
