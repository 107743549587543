import React, { useEffect, useState } from "react";
import { IntlShape } from "react-intl";
import { FieldAttributes } from "formik";
import { toast } from "react-toastify";
import { match as Match } from "react-router-dom";
import Block from "../../../../components/Tailwind/Block";
import Header from "../../../../components/Tailwind/Block/Header";
import { EnumPermissionEntity } from "../../../../lib/ground-aws-graphql-core/api/graphql/types";
import DefaultForm, {
  AdditionalFieldAttributes,
} from "../../../../components/Form";
import overrideClasses from "../../../../utils/overrideClasses";
import { GroundGraphqlContextStore } from "../../../../lib/ground-aws-graphql-core";
import { EnumPaths } from "../../../../utils/navigation";
import history from "../../../../history";
import FormSwitch from "../../../../components/Form/FormSwitch";
import { isString } from "../../../../utils/types";

interface Props {
  match?: Match<{ id: string }>;
  intl: IntlShape;
  edition: boolean;
}

const PaymentMethodForm = (props: Props): JSX.Element => {
  const { intl, match, edition } = props;
  const [enabled, setEnabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [togglePanel, setTogglePanel] = useState(false);

  const getPaymentMethod = GroundGraphqlContextStore.useStoreActions(
    actions => actions.paymentMethods.getPaymentMethod
  );

  const createPaymentMethod = GroundGraphqlContextStore.useStoreActions(
    actions => actions.paymentMethods.createPaymentMethod
  );

  const updatePaymentMethod = GroundGraphqlContextStore.useStoreActions(
    actions => actions.paymentMethods.updatePaymentMethod
  );

  const setPaymentMethod = GroundGraphqlContextStore.useStoreActions(
    actions => actions.paymentMethods.setPaymentMethod
  );

  const paymentMethod = GroundGraphqlContextStore.useStoreState(
    state => state.paymentMethods.paymentMethod
  );

  const formFields: FieldAttributes<AdditionalFieldAttributes>[] = [
    {
      name: "code",
      label: "page.payment.method.code",
      placeholder: "page.payment.method.code.placeholder",
      initialValue: paymentMethod?.code,
      required: true,
    },
    {
      name: "label",
      label: "page.payment.method.label",
      placeholder: "page.payment.method.label.placeholder",
      initialValue: paymentMethod?.label,
      required: true,
      translatable: true,
    },
    {
      name: "enabled_in_bo",
      label: "page.payment.method.enabled.in.bo",
      initialValue: paymentMethod ? !paymentMethod?.excluded_from_bo : true,
      component: props => (
        <FormSwitch
          {...props}
          description="page.payment.method.enabled.in.bo.description"
        />
      ),
    },
  ];

  const fetchData = () => {
    setLoading(true);
    if (match?.params.id) {
      getPaymentMethod({ id: match?.params.id }).finally(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    // Clean the event from the state when we unmount this component
    return () => {
      setPaymentMethod(null);
    };
  }, []);

  useEffect(() => {
    if (paymentMethod) setEnabled(paymentMethod.enabled);
  }, [paymentMethod]);

  const handleShowSavePanel = () => {
    setTogglePanel(true);
  };

  const handleSubmit = (values: any) => {
    setLoading(true);
    const { label, enabled_in_bo, ...others } = values;
    if (edition && paymentMethod) {
      updatePaymentMethod({
        ...others,
        excluded_from_bo: !enabled_in_bo,
        label: isString(label) ? JSON.parse(label) : label,
        id: paymentMethod.id,
        enabled: enabled,
      })
        .then(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.PAYMENT_METHODS}`);
          toast(
            intl.formatMessage({
              id: "page.payment.method.update.success",
            }),
            { type: "success" }
          );
        })
        .catch(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.PAYMENT_METHODS}`);
          toast(
            intl.formatMessage({
              id: "page.payment.method.update.error",
            }),
            {
              type: "error",
            }
          );
        });
    } else {
      createPaymentMethod({
        ...others,
        excluded_from_bo: !enabled_in_bo,
        label: isString(label) ? JSON.parse(label) : label,
        enabled,
      })
        .then(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.PAYMENT_METHODS}`);
          toast(
            intl.formatMessage({ id: "page.payment.method.create.success" }),
            { type: "success" }
          );
        })
        .catch(() => {
          history.push(`/${EnumPaths.ROOT}/${EnumPaths.PAYMENT_METHODS}`);
          toast(
            intl.formatMessage({ id: "page.payment.method.create.error" }),
            { type: "error" }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return loading ? (
    <div className="loading" />
  ) : (
    <>
      {/**
       * When we were loading the form was initialized again and we would lose all data
       * Show loading here in order to not lose the fields data after a loading
       */}
      <div className={overrideClasses({ loading })} />

      {/* Hide when loading */}
      <div className={overrideClasses("px-8 pb-32", { hidden: loading })}>
        <Block>
          <Header
            item={paymentMethod}
            checked={enabled}
            title={
              edition
                ? "page.list.payment.methods.update.payment.method"
                : "page.list.payment.methods.create.payment.method"
            }
            entity={EnumPermissionEntity.PAYMENT_METHOD}
            onChange={e => {
              setEnabled(e);

              // show save panel
              handleShowSavePanel();
            }}
            className="border-b border-gray-200"
          />

          <DefaultForm
            fields={formFields}
            onSubmit={handleSubmit}
            showPanel={togglePanel}
          />
        </Block>
      </div>
    </>
  );
};

export default PaymentMethodForm;
